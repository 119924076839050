import {
  Betalingsgruppe,
  BetalingsgruppeMapper
} from "../../../api/interfaces/Inntauing";
import { PrislisteViewModel } from "../../../api/interfaces/Prisliste";
import { formaterTomStrengVedNumber0 } from "../../../utils/formatUtils";
import { PrislisteDetaljerPrislinje } from "./Prislister";

interface PrislisteDetaljerProps {
  prisliste?: PrislisteViewModel;
}

export const PrislisteDetaljer = (props: PrislisteDetaljerProps) => {
  const { prisliste } = props;

  if (!prisliste) return null;

  return (
    <div>
      <div className="mt-5">
        <p>Opprettet av: {prisliste.opprettetAv}</p>
      </div>
      <div />

      <div className="grid grid-cols-3 max-w-3xl">
        <div />
        <div className="text-end">Pris</div>
        <div className="text-end">Varenummer</div>
        <PrislisteDetaljerPrislinje
          dataTestid="pris-admingebyr-inntauing"
          label="Administrasjonsgebyr"
          belop={prisliste.administrasjonsgebyr.pris}
          varenummer={formaterTomStrengVedNumber0(
            prisliste.administrasjonsgebyr.varenummer
          )}
        />
        <PrislisteDetaljerPrislinje
          dataTestid="pris-admingebyr-flytting"
          label="Administrasjonsgebyr flytting"
          belop={prisliste.administrasjonsgebyrFlytting.pris}
          varenummer="Ikke relevant"
        />
        <PrislisteDetaljerPrislinje
          dataTestid="pris-dognleie"
          label="Døgnleie"
          belop={prisliste.dognleie.pris}
          varenummer={formaterTomStrengVedNumber0(
            prisliste.dognleie.varenummer
          )}
        />
        <p className="col-span-3 mb-5">Inntauingskostnad</p>
        {prisliste.inntauingskostnader.map(i => (
          <PrislisteDetaljerPrislinje
            key={i.gebyrtype}
            label={BetalingsgruppeMapper[i.gebyrtype].navn}
            belop={i.pris}
            varenummer={
              i.gebyrtype === Betalingsgruppe.FlyttingGategrunn
                ? "Ikke relevant"
                : formaterTomStrengVedNumber0(i.varenummer)
            }
            innrykk
          />
        ))}
      </div>
    </div>
  );
};
