import { ReactComponent as Send } from "../../assets/icons/send.svg";
import { usePostNotat } from "../../hooks/useNotat";
import { useForm } from "react-hook-form";
import { notatInputSchema } from "./NotatInputSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useToast } from "../common/toast/ToastProvider";
import { OpprettNotatInputModel } from "../../api/interfaces/Notat";

type NotatInputProps = {
  inntauingsId: string;
};

const NotatInput = (props: NotatInputProps) => {
  const { inntauingsId } = props;

  const initialValues = {
    notat: ""
  };

  const postNotat = usePostNotat(inntauingsId);
  const { addToast } = useToast();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(notatInputSchema),
    defaultValues: initialValues,
    shouldUseNativeValidation: true
  });

  const sendData = (notat: any) => {
    const data: OpprettNotatInputModel = {
      kommentar: notat.notat
    };

    postNotat.mutate(data, {
      onSuccess: async () => {
        addToast("Notat lagret");
        reset();
      },
      onError: () => addToast("Notatet ble ikke lagret", "error")
    });
  };

  useEffect(() => {
    const preventValidationBubble = (event: Event) => event.preventDefault();
    document.addEventListener("invalid", preventValidationBubble, true);
    return () =>
      document.removeEventListener("invalid", preventValidationBubble, true);
  }, []);

  return (
    <>
      <form
        className="ods-form grid grid-cols-10"
        onSubmit={handleSubmit(sendData)}
      >
        <div className="ods-form-group col-span-9 text-left">
          <label htmlFor="notat" className="ods-form-label osg-sans-medium">
            Skriv notat
          </label>
          <textarea
            id="notat"
            {...register("notat")}
            className="ods-form-input"
          />
          <div className="ods-form-validation ods-form-validation--error">
            {errors.notat?.message}
          </div>
        </div>

        <button
          title="Send inn notat"
          id="send-notat-button"
          type="submit"
          className="btn btn--flat h-1 w-12 px-2 py-9 -mt-0.5 col-span-1"
        >
          <Send className="fill-transparent stroke-darkblue hover:stroke-hoverblue" />
        </button>
      </form>
    </>
  );
};

export default NotatInput;
