import {
  InntauingViewModel,
  KjoretoyInputModel
} from "../../api/interfaces/Inntauing";

export interface FelterRedigerEierOgKjoretoyinfo {
  nasjonalitet?: string;
  registreringsnummer?: string;
  rammenummer?: string;

  type?: string;
  merke?: string;
  modell?: string;
  registrertAar?: number;
  totalvekt?: number;
  farge?: string;
  leaser?: boolean;

  juridiskEier?: FelterRedigerEierOgKjoretoyinfoEier;
  leasingtaker?: FelterRedigerEierOgKjoretoyinfoEier;
}

export interface FelterRedigerEierOgKjoretoyinfoEier {
  idNummer?: string;
  navn?: string;
  gateadresse?: string;
  poststed?: string;
  postnummer?: string;
  telefonnummer?: string;
  bedrift?: boolean;
}

export const eierfelterErTomme = (
  eier?: FelterRedigerEierOgKjoretoyinfoEier
): boolean =>
  !eier ||
  (erFeltTom(eier.gateadresse) &&
    erFeltTom(eier.idNummer) &&
    erFeltTom(eier.navn) &&
    erFeltTom(eier.postnummer) &&
    erFeltTom(eier.poststed) &&
    erFeltTom(eier.telefonnummer));

const erFeltTom = (felt: string | undefined) => felt === "" || !felt;

export const getDefaultValues = (
  initialData: InntauingViewModel
): FelterRedigerEierOgKjoretoyinfo => {
  const kjoretoy = initialData.kjoretoy;
  const juridiskEier = kjoretoy?.juridiskEier;
  const leasingtaker = kjoretoy?.leasingtaker;

  return {
    nasjonalitet: kjoretoy?.nasjonalitet,
    registreringsnummer: kjoretoy?.registreringsnummer,
    rammenummer: kjoretoy?.rammenummer,
    type: kjoretoy?.kjoretoystype.kode.toUpperCase(),
    merke: kjoretoy?.merke,
    modell: kjoretoy?.modell,
    registrertAar:
      kjoretoy?.registrertAar === 0 || !kjoretoy
        ? undefined
        : kjoretoy?.registrertAar,
    totalvekt:
      kjoretoy?.totalvekt === 0 || !kjoretoy ? undefined : kjoretoy.totalvekt,
    farge: kjoretoy?.farge,
    leaser: !!leasingtaker,
    juridiskEier: {
      idNummer: juridiskEier?.idNummer,
      navn: juridiskEier?.navn,
      gateadresse: juridiskEier?.gateadresse,
      poststed: juridiskEier?.poststed,
      postnummer: juridiskEier?.postnummer,
      telefonnummer: juridiskEier?.telefonnummer,
      bedrift: !(juridiskEier?.erPerson ?? true)
    },
    leasingtaker: {
      idNummer: leasingtaker?.idNummer,
      navn: leasingtaker?.navn,
      gateadresse: leasingtaker?.gateadresse,
      poststed: leasingtaker?.poststed,
      postnummer: leasingtaker?.postnummer,
      telefonnummer: leasingtaker?.telefonnummer,
      bedrift: !(leasingtaker?.erPerson ?? true)
    }
  };
};

export const felterRedigerEierOgKjoretoyinfoToPutKjoretoyDto = (
  data: FelterRedigerEierOgKjoretoyinfo,
  initialData: InntauingViewModel
): KjoretoyInputModel => {
  const juridiskEier = eierfelterErTomme(data.juridiskEier)
    ? undefined
    : {
        erPerson: !data.juridiskEier?.bedrift,
        idNummer:
          data.juridiskEier?.idNummer && data.juridiskEier?.idNummer.length > 0
            ? data.juridiskEier?.idNummer
            : undefined,
        telefonnummer: data.juridiskEier?.telefonnummer,
        navn: data.juridiskEier?.navn,
        gateadresse: data.juridiskEier?.gateadresse,
        postnummer: data.juridiskEier?.postnummer,
        poststed: data.juridiskEier?.poststed
      };
  const leasingtaker = data.leasingtaker
    ? {
        erPerson: !data.leasingtaker?.bedrift,
        idNummer:
          data.leasingtaker?.idNummer && data.leasingtaker?.idNummer.length > 0
            ? data.leasingtaker?.idNummer
            : undefined,
        telefonnummer: data.leasingtaker?.telefonnummer,
        navn: data.leasingtaker?.navn,
        gateadresse: data.leasingtaker?.gateadresse,
        postnummer: data.leasingtaker?.postnummer,
        poststed: data.leasingtaker?.poststed
      }
    : undefined;
  const kjoretoystype = {
    navn: data.type ? data.type : initialData.kjoretoy.kjoretoystype.navn,
    kode: data.type ? data.type : initialData.kjoretoy.kjoretoystype.kode
  };
  const kjoretoy = initialData.kjoretoy;

  return {
    ...kjoretoy,
    farge: data.farge ?? kjoretoy?.farge,
    merke: data.merke ?? kjoretoy?.merke,
    modell: data.modell ?? kjoretoy?.modell,
    nasjonalitet: data.nasjonalitet ?? kjoretoy?.nasjonalitet,
    rammenummer: data.rammenummer ?? kjoretoy?.rammenummer,
    registreringsnummer:
      data.registreringsnummer ?? kjoretoy?.registreringsnummer,
    registrertAar: data.registrertAar ?? undefined,
    totalvekt: data.totalvekt ?? undefined,
    kjoretoystype: kjoretoystype,
    juridiskEier: juridiskEier,
    leasingtaker: leasingtaker
  };
};
