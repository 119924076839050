import { Controller, useFormContext } from "react-hook-form";
import { FlaggType, FlaggtypeMapper } from "../../api/interfaces/Flagg";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { formaterOppramsing } from "../../utils/formatUtils";
import { AlertBox } from "../common/form/AlertBox";
import { Felter } from "./utleveringUtils";

interface VarselHandlingsloggProps {
  inntauing: InntauingViewModel;
}

export const VarselHandlingslogg = (props: VarselHandlingsloggProps) => {
  const { inntauing } = props;

  const {
    control,
    formState: { errors },
    setValue
  } = useFormContext<Felter>();

  if (
    inntauing.flagg === undefined ||
    inntauing.flagg.filter(
      flagg => !flagg.fjernet && flagg.type === FlaggType.Varsel
    ).length === 0
  ) {
    setValue("lestHandlingslogg", true);
    return null;
  }

  return (
    <div className="mb-5">
      <AlertBox
        closable={false}
        width="large"
        alertLevel="error"
        heading="Inntauingen er flagget"
        paragraph={
          <div className="flex flex-col gap-5">
            <p>{`Inntauingen er flagget med ${formaterOppramsing(
              inntauing.flagg
                .filter(flagg => !flagg.fjernet)
                .map(flagg =>
                  FlaggtypeMapper[flagg.type].statusnavn.toLowerCase()
                )
            )}. Det er viktig at du leser handlingsloggen før utlevering.`}</p>
            <div className="ods-form-group">
              <label htmlFor="bekreft-lest-handlingslogg">
                <div className="flex gap-4">
                  <Controller
                    control={control}
                    name="lestHandlingslogg"
                    render={({ field }) => (
                      <input
                        id="bekreft-lest-handlingslogg"
                        data-testid="bekreft-lest-handlingslogg"
                        type="checkbox"
                        className="ods-form-check-input"
                        checked={field.value}
                        onChange={() => field.onChange(!field.value)}
                        autoFocus={errors.lestHandlingslogg !== undefined}
                      />
                    )}
                  />
                  <div>
                    Bekreft at du har lest handlingsloggen for å kunne utlevere
                  </div>
                </div>
              </label>
            </div>
          </div>
        }
      />
    </div>
  );
};
