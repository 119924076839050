import { BrevViewModel } from "../../api/interfaces/Inntauing";
import { Tooltip } from "../common/tooltip/Tooltip";
import {
  greenSquare,
  graySquare,
  redSquare,
  isLessOrEq15Min
} from "./brevsporingUtils";

interface FysiskBrevProps {
  brevutsending: BrevViewModel;
  headerText?: string;
}

export const FysiskBrev = (props: FysiskBrevProps) => {
  const { brevutsending, headerText } = props;

  let brevstatus: JSX.Element[] = [];

  if (brevutsending.forespurtTidspunkt) {
    brevstatus.push(
      <Tooltip
        content={`Forespurt ${new Date(
          brevutsending.forespurtTidspunkt ?? ""
        ).toLocaleDateString()} ${new Date(
          brevutsending.forespurtTidspunkt ?? ""
        ).toLocaleTimeString()}`}
        key={`fysiskt-brev-f-green-tooltip-${brevutsending.id}`}
      >
        <div
          className={greenSquare}
          key={`fysiskt-brev-f-green-${brevutsending.id}`}
        >
          F
        </div>
      </Tooltip>
    );
    if (brevutsending.arkivertTidspunkt) {
      brevstatus.push(
        <Tooltip
          content={`Arkivert ${new Date(
            brevutsending.arkivertTidspunkt ?? ""
          ).toLocaleDateString()} ${new Date(
            brevutsending.arkivertTidspunkt ?? ""
          ).toLocaleTimeString()}`}
          key={`fysiskt-brev-a-green-tooltip-${brevutsending.id}`}
        >
          <div
            className={greenSquare}
            key={`fysiskt-brev-a-green-${brevutsending.id}`}
          >
            A
          </div>
        </Tooltip>
      );
    } else if (isLessOrEq15Min(brevutsending.forespurtTidspunkt)) {
      brevstatus.push(
        <Tooltip
          content="Arkiverer"
          key={`fysiskt-brev-a-gray-tooltip-${brevutsending.id}`}
        >
          <div
            className={graySquare}
            key={`fysiskt-brev-a-gray-${brevutsending.id}`}
          >
            A
          </div>
        </Tooltip>
      );
    } else {
      brevstatus.push(
        <Tooltip
          content="Arkivering feilet"
          key={`fysiskt-brev-a-red-tooltip-${brevutsending.id}`}
        >
          <div
            className={redSquare}
            key={`fysiskt-brev-a-red-${brevutsending.id}`}
          >
            A
          </div>
        </Tooltip>
      );
    }

    brevstatus.push(
      <div
        className="w-8 h-8"
        key={`fysiskt-brev-s-gray-${brevutsending.id}`}
      ></div>
    );
    brevstatus.push(
      <div
        className="w-8 h-8"
        key={`fysiskt-brev-l-gray-${brevutsending.id}`}
      ></div>
    );
  }

  return (
    <div key={`fysisk-status-"${brevutsending.id}`}>
      <h3 className="text-lg font-medium mt-4 mb-2">
        {headerText ? headerText : "Fysisk varselbrev"}
      </h3>
      <div className="mt-2 mb-4 flex gap-4 px-[0.625rem]">{brevstatus}</div>
    </div>
  );
};
