import { apiClient } from "../client";
import { KjoretoyInputModel, KjoretoyViewModel } from "../interfaces/Inntauing";
import { config } from "./Config";

const client = apiClient();

export const getKjoretoysinfo = (regnr: string) =>
  client.get<KjoretoyViewModel>(`kjoretoyregister/${regnr}`, config());

export const putKjoretoy = (id: string, kjoretoy: KjoretoyInputModel) =>
  client.put<KjoretoyInputModel, {}>(`kjoretoy/${id}`, kjoretoy, config());
