import { apiClient } from "../client";
import { NasjonskodeViewModel } from "../interfaces/Grunndata";
import { config } from "./Config";

const client = apiClient();

export const getLokasjoner = () =>
  client.get<string[]>("grunndata/lokasjoner", config());

export const getFarger = () =>
  client.get<string[]>("grunndata/farger", config());

export const getMerker = () =>
  client.get<string[]>("grunndata/merker", config());

export const getNasjonskoder = () =>
  client.get<NasjonskodeViewModel[]>("grunndata/nasjonskoder", config());

export const getOvertredelser = () =>
  client.get<string[]>(
    "grunndata/overtredelser?inkluderInaktive=false",
    config()
  );
