import { AxiosResponse } from "axios";
import { apiClient } from "../client";
import {
  AvstemmingInputModel,
  AvstemmingViewModel,
  KlarTilAvstemmingViewModel
} from "../interfaces/Avstemming";
import { config } from "./Config";

const client = apiClient();

export const getUavstemte = (tidspunktOpptelling: Date) =>
  client.get<KlarTilAvstemmingViewModel>(
    `avstemming/uavstemt?til=${tidspunktOpptelling.toISOString()}`,
    config()
  );

export const getAvstemminger = (datoFra: Date, datoTil: Date) =>
  client.get<AvstemmingViewModel[]>(
    `avstemming?fra=${datoFra.toISOString()}&til=${datoTil.toISOString()}`,
    config()
  );

export const postAvstemming = (avstemming: AvstemmingInputModel) =>
  client.post<AvstemmingInputModel, AxiosResponse<KlarTilAvstemmingViewModel>>(
    "avstemming/avstem",
    avstemming,
    config()
  );
