import { useNasjonskoder } from "../../hooks/useGrunndata";
import {
  KeyValueKortInnhold,
  KeyValuePair
} from "../common/card/KeyValueKortInnhold";

interface KjoretoysinformasjonProps {
  nasjonskode: string;
  regnr: string;
  rammenr: string;
  kjoretoystype: string;
  modell: string;
  merke: string;
  farge: string;
  totalvekt: string;
  registrert: string;
  // registreringsstatus: string;
}

export const Kjoretoysinformasjon = (props: KjoretoysinformasjonProps) => {
  const {
    nasjonskode,
    regnr,
    rammenr,
    kjoretoystype,
    modell,
    merke,
    farge,
    totalvekt,
    registrert
    // registreringsstatus
  } = props;

  const { data: nasjonskoder } = useNasjonskoder();

  let keyValuePairs: KeyValuePair[] = [
    {
      key: "Nasjon",
      value: [nasjonskoder?.find(n => n.kode === nasjonskode)?.navn ?? ""]
    },
    { key: "Regnr", value: [regnr], dataTestid: "kjoretoyinformasjon-regnr" },
    {
      key: "Rammenr",
      value: [rammenr],
      dataTestid: "kjoretoyinformasjon-rammenr"
    },
    {
      key: "Kjøretøystype",
      value: [kjoretoystype],
      dataTestid: "kjoretoyinformasjon-type"
    },
    {
      key: "Modell",
      value: [modell],
      dataTestid: "kjoretoyinformasjon-modell"
    },
    { key: "Merke", value: [merke], dataTestid: "kjoretoyinformasjon-merke" },
    { key: "Farge", value: [farge], dataTestid: "kjoretoyinformasjon-farge" },
    {
      key: "Totalvekt",
      value: [totalvekt],
      dataTestid: "kjoretoyinformasjon-totalvekt"
    },
    { key: "Registrert", value: [registrert] }
    // { key: "Registreringsstatus", value: [registreringsstatus] } Denne er i skissene, men vi har ikke info. Legg til siden
  ];

  return <KeyValueKortInnhold keyValuePair={keyValuePairs} keyWidth="w-40" />;
};
