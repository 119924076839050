import { useState } from "react";
import { SmsViewModel } from "../../api/interfaces/Inntauing";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { greenSquare } from "./brevsporingUtils";
import { Tooltip } from "../common/tooltip/Tooltip";

interface TekstmeldingProps {
  tekstmelding: SmsViewModel;
}

export const Tekstmelding = (props: TekstmeldingProps) => {
  const { tekstmelding } = props;

  const [showTekstmelding, setShowTekstmelding] = useState<boolean>(false);
  return (
    <div key={`sms-status-"${tekstmelding.id}`}>
      <h3 className="text-lg font-medium mt-4 mb-2">SMS</h3>
      <div className="flex gap-4 px-[0.625rem]">
        <Tooltip
          content={`Sendt ${new Date(
            tekstmelding.sendingstidspunkt ?? ""
          ).toLocaleDateString()} ${new Date(
            tekstmelding.sendingstidspunkt ?? ""
          ).toLocaleTimeString()}`}
          key={`tekstmelding-brev-s-green-tooltip-${tekstmelding.id}`}
        >
          <div
            className={greenSquare}
            key={`tekstmelding-brev-s-green-${tekstmelding.id}`}
          >
            S
          </div>
        </Tooltip>
        <div>
          {new Date(tekstmelding.sendingstidspunkt ?? "").toLocaleDateString()}
        </div>
        <div></div>
        <div></div>
      </div>
      <div className="mt-2 mb-4">
        <button
          className="btn btn--small btn--flat btn--icon-right text-xs"
          type="button"
          onClick={() => setShowTekstmelding(!showTekstmelding)}
        >
          <ArrowDown
            className={`fill-current inline-flex 
              ${
                showTekstmelding
                  ? "animate-accordion-arrow-open"
                  : "animate-accordion-arrow-close"
              }`}
          />
          <span>Åpne opp meldingen</span>
        </button>
        {showTekstmelding && (
          <div className="px-[0.625rem]">
            <div className="bg-darkblue text-white p-[0.625rem]">
              &#171;{tekstmelding.tekst}&#187;
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
