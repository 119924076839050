import Linn from "../../assets/images/Linn.png";
import Vemund from "../../assets/images/Vemund.png";
import David from "../../assets/images/David.png";
import Ola from "../../assets/images/Ola.png";
import Marte from "../../assets/images/Marte.png";

import Trong from "../../assets/images/Trong.png";
import Martin from "../../assets/images/Martin.png";
import Marianne from "../../assets/images/Marianne.png";
import Markus from "../../assets/images/Markus.png";
import Petter from "../../assets/images/Petter.png";

export const HallOfFame = () => {
  return (
    <div className="absolute left-0 top-0 pt-[8rem] tabletBig:pt-[5rem] my-auto flex flex-col w-full h-fit min-h-full bg-rusticyellow gap-y-10">
      <h1 className="text-5xl w-fit mx-auto">Æresgalleri</h1>
      <div className="flex flex-row flex-wrap w-fit mx-auto gap-5">
        <Image src={Linn} name="Linn" />
        <Image src={Vemund} name="Vemund" />
        <Image src={David} name="David" />
        <Image src={Ola} name="Ola" />
        <Image src={Marte} name="Marte" />
      </div>
      <div className="flex flex-row flex-wrap w-fit mx-auto gap-5">
        <Image src={Trong} name="Trong" />
        <Image src={Martin} name="Martin" />
        <Image src={Marianne} name="Marianne" />
        <Image src={Markus} name="Markus" />
        <Image src={Petter} name="Petter" />
      </div>
      <div className="mx-auto mb-10">
        <a href="/" className="btn btn--primary">
          Tilbake til forsiden
        </a>
      </div>
    </div>
  );
};

const Image = (props: { src: string; name: string }) => {
  return <img src={props.src} alt={props.name} className="w-52 h-64" />;
};
