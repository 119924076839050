import { AxiosResponse } from "axios";
import { apiClient } from "../client";
import {
  BetalingInputModel,
  BetalingViewModel,
  BetaltIleggelseViewModel
} from "../interfaces/Betaling";
import { config, configPDF } from "./Config";

const client = apiClient();

export const postBetaling = (
  inntauingId: string,
  betaling: BetalingInputModel
) =>
  client.post<BetalingViewModel>(
    `betaling/betal/${inntauingId}`,
    betaling,
    config()
  );

export const getKvitteringPDF = (betalingsnummer: string) =>
  client.get(`betaling/${betalingsnummer}/pdf`, configPDF());

export const getBetalteileggelser = (fraDato: Date, tilDato: Date) =>
  client.get<BetaltIleggelseViewModel[]>(
    `betaling/betalteIleggelser?fra=${fraDato.toISOString()}&til=${tilDato.toISOString()}`,
    config()
  );

export const getPersonligeBetalinger = (dato: Date) =>
  client.get<BetalingViewModel[]>(
    `betaling/betalinger/personlige?dato=${dato.toISOString()}`,
    config()
  );

export const putFjernIleggelse = (betalingsnummer: number) =>
  client.put<AxiosResponse>(
    `betaling/${betalingsnummer}/fjernileggelse`,
    undefined,
    config()
  );

export const putLeggTilIleggelse = (betalingsnummer: number) =>
  client.put<AxiosResponse>(
    `betaling/${betalingsnummer}/leggtilileggelse`,
    undefined,
    config()
  );
