import { Kort } from "../common/card/Kort";
import { HeadingView } from "../common/typography/HeadingView";
import { ReactComponent as Personbil } from "../../assets/icons/personbil.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { ReactComponent as Print } from "../../assets/icons/print.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { Toggle } from "../common/form/Toggle";
import { useCallback, useRef, useState } from "react";
import { checkDateBiggerThanOrEqual } from "../../utils/compareUtils";
import { useInntauing } from "../../hooks/useInntauinger";
import { CustomError } from "../common/typography/CustomError";
import { AttestskjemaUtskrift } from "./AttestskjemaUtskrift";
import { AlertBox } from "../common/form/AlertBox";
import DatePicker from "../common/form/DatePicker";
import { RequiredLabel } from "../common/form/RequiredLabel";
import { useReactToPrint } from "react-to-print";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../utils/globalConstants";
import { HjemmelType, HjemmelTypeMapper } from "../../api/interfaces/Inntauing";

export interface AttestskjemaData {
  salgsdato?: Date;
  erPerson: boolean;
  idNummer?: string;
  navn?: string;
  gateadresse?: string;
  postnummer?: string;
  poststed?: string;
}

export const Attestskjema = () => {
  const { id } = useParams();

  const [skjemadata, setSkjemadata] = useState<AttestskjemaData>({
    erPerson: true,
    salgsdato: new Date()
  });

  const navigate = useNavigate();

  const { data, isLoading, isError, error } = useInntauing(id ?? "");

  const componentPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    pageStyle: "m-5"
  });

  const feilVedSalgsdato = (): boolean => {
    if (
      !(
        skjemadata.salgsdato &&
        data?.auksjon?.sendtTilAuksjon &&
        checkDateBiggerThanOrEqual(
          skjemadata.salgsdato,
          new Date(data.auksjon.sendtTilAuksjon.toString())
        )
      )
    )
      return true;

    return false;
  };

  const handleSalgsdato = useCallback(
    (dato: Date | null) =>
      setSkjemadata({ ...skjemadata, salgsdato: dato ?? new Date() }),
    [skjemadata]
  );

  const handleIdNummer = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSkjemadata({
      ...skjemadata,
      idNummer:
        e.currentTarget.value.length === 0 ? undefined : e.currentTarget.value
    });

  const handleNavn = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSkjemadata({
      ...skjemadata,
      navn:
        e.currentTarget.value.length === 0 ? undefined : e.currentTarget.value
    });

  const handleGateadresse = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSkjemadata({
      ...skjemadata,
      gateadresse:
        e.currentTarget.value.length === 0 ? undefined : e.currentTarget.value
    });

  const handlePostnummer = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSkjemadata({
      ...skjemadata,
      postnummer:
        e.currentTarget.value.length === 0 ? undefined : e.currentTarget.value
    });

  const handlePoststed = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSkjemadata({
      ...skjemadata,
      poststed:
        e.currentTarget.value.length === 0 ? undefined : e.currentTarget.value
    });

  const handleErPerson = useCallback(
    () =>
      setSkjemadata({
        ...skjemadata,
        erPerson: !skjemadata.erPerson
      }),
    [skjemadata]
  );

  return (
    <div className="flex flex-col mx-5 mb-10">
      <HeadingView>Generer auksjonsattest</HeadingView>
      <Kort
        headerText="Auksjonsattest"
        headerIcon={<Personbil className="fill-darkblue" />}
        fullWidth
        innhold={
          <>
            <div className="ods-form-group w-52">
              <label className="ods-form-label flex flex-row flex-wrap">
                Salgsdato
                <RequiredLabel />
              </label>
              <div className="flex relative items-center justify-end focus-within:fill-hoverblue">
                <DatePicker
                  dateFormat={["dd.MM.yyyy", "d.MM.yyyy", "ddMMyyyy"]}
                  onChange={handleSalgsdato}
                  selected={skjemadata.salgsdato}
                  customInput={
                    <input
                      data-testid="datepicker-salgsdato"
                      className="ods-form-input"
                    />
                  }
                />
                <CalendarIcon className="pointer-events-none absolute mr-2 h-5 w-5" />
              </div>
            </div>
            <>
              <h3 className="font-semibold">Ny juridisk eier:</h3>
              <div className="my-5">
                <Toggle
                  leftText="Person"
                  rightText="Bedrift"
                  value={!skjemadata.erPerson}
                  toggleFunction={handleErPerson}
                />
              </div>
              <div className="ods-form-group">
                <label className="ods-form-label">
                  {skjemadata.erPerson ? "Personnummer" : "Org.nummer"}
                </label>
                <input
                  className="ods-form-input max-w-[15rem]"
                  value={skjemadata.idNummer}
                  onChange={handleIdNummer}
                />
              </div>
              <div className="ods-form-group">
                <label className="ods-form-label">
                  {skjemadata.erPerson ? "Navn" : "Bedrift"}
                </label>
                <input
                  className="ods-form-input max-w-[25rem]"
                  value={skjemadata.navn}
                  onChange={handleNavn}
                />
              </div>
              <div className="ods-form-group">
                <label className="ods-form-label">Gateadresse</label>
                <input
                  className="ods-form-input max-w-[25rem]"
                  value={skjemadata.gateadresse}
                  onChange={handleGateadresse}
                />
              </div>
              <div className="flex flex-row flex-wrap">
                <div className="ods-form-group mr-5">
                  <label className="ods-form-label">Postnummer</label>
                  <input
                    className="ods-form-input max-w-[7rem]"
                    value={skjemadata.postnummer}
                    onChange={handlePostnummer}
                  />
                </div>
                <div className="ods-form-group">
                  <label className="ods-form-label">Poststed</label>
                  <input
                    className="ods-form-input"
                    value={skjemadata.poststed}
                    onChange={handlePoststed}
                  />
                </div>
              </div>
            </>

            {(skjemadata.idNummer?.replaceAll(/\s/g, "")?.length ?? 0) !== 0 &&
              (skjemadata.idNummer?.replaceAll(/\s/g, "")?.length ?? 0) !==
                (skjemadata.erPerson ? 11 : 9) && (
                <div className="mb-5 w-fit">
                  <AlertBox
                    alertLevel="warning"
                    heading={
                      <h3>
                        {skjemadata.erPerson
                          ? "Feil lengde på personnummer"
                          : "Feil lengde på org.nummer"}
                      </h3>
                    }
                    paragraph={
                      <>
                        {skjemadata.erPerson
                          ? `Personnummer er skrevet med ${
                              skjemadata.idNummer?.replaceAll(/\s/g, "")
                                .length ?? 0
                            } siffer, men skal være 11 siffer`
                          : `Org.nummer er skrevet med ${
                              skjemadata.idNummer?.replaceAll(/\s/g, "")
                                .length ?? 0
                            } siffer, men skal være 9 siffer`}
                      </>
                    }
                  />
                </div>
              )}

            {feilVedSalgsdato() && (
              <div className="mb-5 w-fit">
                <AlertBox
                  alertLevel="error"
                  heading={
                    <h3>Salgsdato er satt på et for tidlig tidspunkt</h3>
                  }
                  paragraph={
                    <div>{`Salgsdato (${skjemadata.salgsdato?.toLocaleDateString()}) kan ikke være på et tidligere tidspunkt enn tidspunktet kjøretøyet ble sendt til auksjon (${new Date(
                      data?.auksjon?.sendtTilAuksjon?.toString() ?? ""
                    ).toLocaleDateString()})`}</div>
                  }
                />
              </div>
            )}

            {isLoading && <LoadingSpinner />}
            {isError && <CustomError error={(error as Error).message} />}
            <div className="flex flex-row flex-wrap">
              <button
                className="btn btn--flat btn--icon-left mr-5"
                onClick={() => navigate(ROUTES.Auksjon)}
              >
                <ArrowDown className="fill-current inline-flex rotate-[90deg]" />
                <span>Avbryt</span>
              </button>
              <button
                className="btn btn--primary-confirm btn--icon-left mr-5"
                onClick={handlePrint}
                disabled={isLoading || isError}
              >
                <Print className="fill-current" />
                <span>Skriv ut</span>
              </button>
              <button
                className="btn btn--primary btn--icon-right"
                onClick={() => navigate(ROUTES.Auksjon)}
              >
                <ArrowDown className="fill-current inline-flex rotate-[-90deg]" />
                <span>Ferdig</span>
              </button>
            </div>
          </>
        }
      />
      {data && (
        <div className="hidden">
          <div className="text-xs" ref={componentPrintRef}>
            <AttestskjemaUtskrift
              inntauing={data}
              skjemadata={skjemadata}
              hjemmel={
                HjemmelTypeMapper[
                  data?.inntauingsgrunnlag?.hjemmel ?? HjemmelType.Ukjent
                ].navn
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};
