import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { HeadingUtskrift } from "../common/typography/HeadingUtskrift";

interface TakseringTabellUtskriftProps {
  inntauinger: InntauingViewModel[];
}

export const TakseringTabellUtskrift = (
  props: TakseringTabellUtskriftProps
) => {
  const { inntauinger } = props;

  return (
    <>
      <HeadingUtskrift>Kjøretøy klare for taksering</HeadingUtskrift>
      <table className="tbl">
        <thead>
          <tr>
            <th>Rad</th>
            <th>Nasjonskode</th>
            <th>Regnr</th>
            <th>Journalnummer</th>
            <th>Type</th>
            <th>Merke</th>
            <th>Modell</th>
            <th>Reg.år</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {inntauinger
            .sort((a, b) =>
              (a.kjoretoyleveranse?.plassering ?? "")
                .split("/")[0]
                .localeCompare(
                  (b.kjoretoyleveranse?.plassering ?? "").split("/")[0],
                  undefined,
                  { numeric: true }
                )
            )
            .map(i => (
              <tr key={i.id}>
                <td>{i.kjoretoyleveranse?.plassering}</td>
                <td>{i.kjoretoy.nasjonalitet}</td>
                <td>{i.kjoretoy.registreringsnummer}</td>
                <td>{i.kjoretoyleveranse?.journalnummer ?? ""}</td>
                <td>{i.kjoretoy.kjoretoystype.kode}</td>
                <td>{i.kjoretoy.merke}</td>
                <td>{i.kjoretoy.modell}</td>
                <td>{i.kjoretoy.registrertAar}</td>
                <td>
                  <input type="checkbox" className="ods-form-check-input" />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};
