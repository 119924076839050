import { StatuskodeType } from "./Inntauing";

export enum BrevtypeFilterType {
  MeldingOmInntauetKjoretoy = 1,
  MeldingOmFlyttetKjoretoy = 2,
  Avhendingsbrev = 4,
  BetalingskravFlytting = 6,
  IngenBrevsending = 99
}

export const BrevtypeFilterNavn: Record<BrevtypeFilterType, string> = {
  [BrevtypeFilterType.MeldingOmInntauetKjoretoy]:
    "Melding om inntauet kjøretøy",
  [BrevtypeFilterType.MeldingOmFlyttetKjoretoy]: "Melding om flyttet kjøretøy",
  [BrevtypeFilterType.Avhendingsbrev]: "Avhendingsbrev",
  [BrevtypeFilterType.BetalingskravFlytting]: "Betalingskrav for flytting",
  [BrevtypeFilterType.IngenBrevsending]: "Ingen brevsending"
};

export interface InntauingQueryParametersViewModel {
  sok?: string;
  from?: Date;
  to?: Date;
  statuser?: StatuskodeType[];
  erVarslet?: boolean;
  inntauetFra?: string;
  flyttetTil?: string;
  erAktiv?: boolean;
  sakErLukket?: boolean;
  vrakObjekt?: boolean;
  takstObjekt?: boolean;
}
