import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { formaterNullableBelop } from "../../utils/formatUtils";
import { KeyValuePair } from "../common/card/KeyValueKortInnhold";
import { getFlyttetTilLokasjon } from "../inntauingsinformasjon/inntauingsinformasjonUtils";

export const keyValuePairsStatusHentet = (inntauing: InntauingViewModel) => {
  const keyValuePairs: KeyValuePair[] = [
    {
      key: "Radnr",
      value: [inntauing.kjoretoyleveranse?.plassering ?? ""]
    },
    {
      key: "Ordrenr",
      value: [
        inntauing.kjoretoyleveranse
          ? inntauing.kjoretoyleveranse.ordrenummer?.toString() ?? ""
          : ""
      ]
    },
    {
      key: "Journalnr",
      value: [
        inntauing.kjoretoyleveranse
          ? inntauing.kjoretoyleveranse.journalnummer?.toString() ?? ""
          : ""
      ]
    },
    {
      key: "Ileggelsesnr",
      value: [inntauing.ileggelse?.ileggelsesnummer?.toString() ?? ""]
    }
  ];

  return keyValuePairs;
};

export const keyValuePairsStatusFremmote = (inntauing: InntauingViewModel) => {
  const keyValuePairs: KeyValuePair[] = [
    {
      key: "Ileggelsesnr",
      value: [inntauing.ileggelse?.ileggelsesnummer?.toString() ?? ""]
    },
    {
      key: "Ordrenr",
      value: [
        inntauing.kjoretoyleveranse
          ? inntauing.kjoretoyleveranse.ordrenummer?.toString() ?? ""
          : ""
      ]
    }
  ];

  return keyValuePairs;
};

export const keyValueParisStatusFlyttet = (inntauing: InntauingViewModel) => {
  const keyValuePairs: KeyValuePair[] = [
    {
      key: "Ileggelsesnr",
      value: [inntauing.ileggelse?.ileggelsesnummer?.toString() ?? ""]
    },
    {
      key: "Ordrenr",
      value: [
        inntauing.kjoretoyleveranse
          ? inntauing.kjoretoyleveranse.ordrenummer?.toString() ?? ""
          : ""
      ]
    },
    {
      key: "Flyttet til",
      value: getFlyttetTilLokasjon(inntauing.kjoretoyleveranse)
    }
  ];

  return keyValuePairs;
};

export const keyValuePairsStatusVraket = (inntauing: InntauingViewModel) => {
  const vraking = inntauing.vraking;

  const totalsumVrak =
    (vraking?.inntektSkrapsalg ?? 0) + (vraking?.vrakpant ?? 0);

  const keyValuePairs: KeyValuePair[] = [
    {
      key: "Totalsum av vrak",
      value: [formaterNullableBelop(totalsumVrak)]
    },
    {
      key: "Vrakpant",
      isKeyChild: true,
      value: [formaterNullableBelop(vraking?.vrakpant)]
    },
    {
      key: "Inntekt skrapsalg",
      isKeyChild: true,
      value: [formaterNullableBelop(vraking?.inntektSkrapsalg)]
    },
    {
      key: "Ileggelsesnr",
      value: [inntauing.ileggelse?.ileggelsesnummer?.toString() ?? ""]
    },
    {
      key: "Radnr",
      value: [inntauing.kjoretoyleveranse?.plassering ?? ""]
    },
    {
      key: "Ordrenr",
      value: [
        inntauing.kjoretoyleveranse
          ? inntauing.kjoretoyleveranse.ordrenummer?.toString() ?? ""
          : ""
      ]
    },
    {
      key: "Journalnr",
      value: [
        inntauing.kjoretoyleveranse
          ? inntauing.kjoretoyleveranse.journalnummer?.toString() ?? ""
          : ""
      ]
    }
  ];

  return keyValuePairs;
};

export const keyValuePairsStatusAuksjonert = (
  inntauing: InntauingViewModel
) => {
  const auksjon = inntauing.auksjon;

  const keyValuePairs: KeyValuePair[] = [
    {
      key: "Auksjonspris",
      value: [formaterNullableBelop(auksjon?.salgsinntekt)]
    },
    {
      key: "Ileggelsesnr",
      value: [inntauing.ileggelse?.ileggelsesnummer?.toString() ?? ""]
    },
    {
      key: "Radnr",
      value: [inntauing.kjoretoyleveranse?.plassering ?? ""]
    },
    {
      key: "Ordrenr",
      value: [
        inntauing.kjoretoyleveranse
          ? inntauing.kjoretoyleveranse.ordrenummer?.toString() ?? ""
          : ""
      ]
    },
    {
      key: "Journalnr",
      value: [
        inntauing.kjoretoyleveranse
          ? inntauing.kjoretoyleveranse.journalnummer?.toString() ?? ""
          : ""
      ]
    }
  ];

  return keyValuePairs;
};
