import { endOfDay, startOfMonth } from "date-fns";
import { useAvstemminger } from "../../../hooks/useAvstemming";
import { EkstrainfoKort } from "../../common/card/EkstrainfoKort";
import { AlertBox } from "../../common/form/AlertBox";
import { CustomError } from "../../common/typography/CustomError";
import { HeadingView } from "../../common/typography/HeadingView";
import { ArkivAvstemmingDetaljvisning } from "./ArkivAvstemmingDetaljvisning";
import { ArkivAvstemmingTabell } from "./ArkivAvstemmingTabell";
import { LoadingSpinner } from "../../common/loading/LoadingSpinner";
import { FraTilDato } from "../../common/form/FraTilDato";
import { formaterNullableBelop } from "../../../utils/formatUtils";
import { useStateParams } from "../../../hooks/useStateParams";
import { useSearchParams } from "react-router-dom";
import { AvstemmingViewModel } from "../../../api/interfaces/Avstemming";

export const ArkivAvstemming = () => {
  const foersteDagIMaaneden = startOfMonth(new Date());
  const nesteMidnatt = endOfDay(new Date());

  const [fraDato, setFraDato] = useStateParams(
    foersteDagIMaaneden,
    "fra",
    s => s.toISOString(),
    s => new Date(s)
  );
  const [tilDato, setTilDato] = useStateParams(
    nesteMidnatt,
    "til",
    s => s.toISOString(),
    s => new Date(s)
  );

  const [searchParams] = useSearchParams();
  const kassedato = searchParams.get("kassedato");
  const sesjonsnummer = searchParams.get("sesjonsnummer");

  const { data, isLoading, isError, error } = useAvstemminger(fraDato, tilDato);

  const valgtAvstemming = getAvstemming(
    data ?? [],
    kassedato ?? "",
    sesjonsnummer ?? ""
  );

  if (valgtAvstemming)
    return <ArkivAvstemmingDetaljvisning avstemming={valgtAvstemming} />;

  return (
    <>
      <HeadingView>Gjennomførte kasseavstemminger</HeadingView>
      <FraTilDato
        fraDato={fraDato}
        tilDato={tilDato}
        handleChangeFraDato={setFraDato}
        handleChangeTilDato={setTilDato}
      />
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      {data && (
        <>
          {sesjonsnummer && new Date(Date.parse(kassedato ?? "")) && (
            <AlertBox
              alertLevel="warning"
              heading="Fant ingen avstemming med angitt sesjonsnummer eller dato for avstemming"
            />
          )}
          {data.length === 0 ? (
            <div className="mt-5">
              <AlertBox
                alertLevel="warning"
                heading="Fant ingen avstemminger for angitt periode"
              />
            </div>
          ) : (
            <>
              <div className="flex flex-row flex-wrap">
                <EkstrainfoKort
                  tittel="Kasse/Agresso"
                  verdi={formaterNullableBelop(
                    data
                      .flatMap(i => i.kassesum)
                      .reduce((prev, curr) => prev + curr, 0)
                  )}
                />
                <EkstrainfoKort
                  tittel="Inntauingsystem"
                  verdi={formaterNullableBelop(
                    data
                      .flatMap(i => i.registrertSum)
                      .reduce((prev, curr) => prev + curr, 0)
                  )}
                />
                <EkstrainfoKort
                  tittel="Differanse"
                  verdi={formaterNullableBelop(
                    data
                      .flatMap(i => i.avviksum)
                      .reduce((prev, curr) => prev + curr, 0)
                  )}
                />
              </div>
              <ArkivAvstemmingTabell avstemminger={data} />
            </>
          )}
        </>
      )}
    </>
  );
};

const getAvstemming = (
  avstemminger: AvstemmingViewModel[],
  kassedato: string,
  sesjonsnummer: string
): AvstemmingViewModel | undefined => {
  if (avstemminger.length === 0) return undefined;
  let filtrerteAvstemminger: AvstemmingViewModel[] = [];
  if (Date.parse(kassedato))
    filtrerteAvstemminger = avstemminger.filter(
      a => new Date(a.kasseDato).getTime() === Date.parse(kassedato)
    );
  if (filtrerteAvstemminger.length > 0)
    filtrerteAvstemminger = filtrerteAvstemminger.filter(a =>
      a.sesjonsnummer.split(", ").some(s => s === sesjonsnummer)
    );
  return filtrerteAvstemminger[0];
};
