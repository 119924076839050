interface RequiredLabelProps {
  notRequired?: boolean;
}

export const RequiredLabel = (props: RequiredLabelProps) => {
  const { notRequired } = props;

  return (
    <div className="bg-sky-100 px-1 mx-2 text-[0.8em] my-auto rounded">
      {notRequired ? "Valgfritt" : "Påkrevd"}
    </div>
  );
};
