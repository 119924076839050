import { HeadingView } from "../common/typography/HeadingView";
import { BrevsporingTabell } from "./BrevsporingTabell";

export const Brevsending = () => {
  return (
    <div className="mx-5 mb-16 flex flex-col justify-start">
      {/* <HeadingView>Brevsending</HeadingView>*/}
      <HeadingView>Siste brevsporinger</HeadingView>
      <BrevsporingTabell />
    </div>
  );
};
