import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Toggle } from "../common/form/Toggle";
import DatePicker from "../common/form/DatePicker";
import { useRedigerInntauingsgrunnlag } from "../../hooks/useRedigerDetaljer";
import { AlertBox } from "../common/form/AlertBox";
import { SearchDropdown } from "../common/form/SearchDropdown";
import { handleNumberOrEmptyValueInput } from "../../utils/keyboardUtils";
import {
  HjemmelType,
  HjemmelTypeMapper,
  InntauingViewModel
} from "../../api/interfaces/Inntauing";
import { mapEnumToNumberValues } from "../../utils/objectMapping";

interface Felter {
  betjent: string;
  tidspunkt: Date;
  gatenavn: string;
  gatenummer: string;
  kommentar: string;
  inntauer: string;
  hjemmel: number;
  overtredelse: string;
  skader: string;
  kontaktetEier: boolean;
  eierkontakt: string;
  utenskilt: boolean;
}

interface PocketControlRedigeringProps {
  inntauing: InntauingViewModel;
  gater: string[];
  overtredelser: string[];
  avbrytRedigering: () => void;
}

export const PocketControlRedigering = (
  props: PocketControlRedigeringProps
) => {
  const { inntauing, gater, overtredelser, avbrytRedigering } = props;

  const initialData: Felter = {
    betjent: inntauing.inntauingsgrunnlag.betjentId ?? "",
    tidspunkt: inntauing.inntauingsgrunnlag.bestillingstidspunkt,
    gatenavn: inntauing.inntauingsgrunnlag.lokasjon.gatenavn ?? "",
    gatenummer:
      inntauing.inntauingsgrunnlag.lokasjon.gatenummer?.toString() ?? "",
    kommentar: inntauing.inntauingsgrunnlag.kommentar ?? "",
    inntauer: inntauing.inntauingsgrunnlag.inntauingsfirma ?? "",
    hjemmel: inntauing.inntauingsgrunnlag.hjemmel,
    overtredelse: inntauing.inntauingsgrunnlag.overtredelse ?? "",
    skader: inntauing.inntauingsgrunnlag.skadenotat ?? "",
    kontaktetEier: !!inntauing.inntauingsgrunnlag.eierkontakt,
    eierkontakt: inntauing.inntauingsgrunnlag.eierkontakt ?? "",
    utenskilt: inntauing.inntauingsgrunnlag.utenSkilter
  };

  const putInntauingsgrunnlag = useRedigerInntauingsgrunnlag();

  const {
    formState: { errors },
    control,
    handleSubmit,
    register
  } = useForm<Felter>({
    mode: "onSubmit",
    defaultValues: initialData,
    shouldUseNativeValidation: true
  });

  const kontaktetEier = useWatch({
    control,
    name: "kontaktetEier"
  });

  const sendSkjema = (data: Felter) => {
    if (Object.entries(errors).length === 0) {
      putInntauingsgrunnlag.mutate(
        {
          inntauingsId: inntauing.id,
          inntauingsgrunnlag: {
            bestillingstidspunkt: data.tidspunkt,
            lokasjon: {
              ...inntauing.inntauingsgrunnlag.lokasjon,
              gatenavn: data.gatenavn,
              gatenummer: parseInt(data.gatenummer)
            },
            betjentId: data.betjent,
            eierkontakt: data.kontaktetEier ? data.eierkontakt : undefined,
            inntauingsfirma: data.inntauer,
            kommentar: data.kommentar,
            overtredelse: data.overtredelse,
            skadenotat: data.skader,
            hjemmel: Number(data.hjemmel),
            utenSkilter: data.utenskilt
          }
        },
        {
          onSuccess: () => {
            avbrytRedigering();
          }
        }
      );
    }
  };

  return (
    <form className="flex flex-col" onSubmit={handleSubmit(sendSkjema)}>
      <div className="grid grid-cols-2 gap-y-1 mt-2 mb-10">
        <label htmlFor="betjent" className="osg-sans-bold">
          Betjent:
        </label>
        <input
          id="betjent"
          className="ods-form-input"
          {...register("betjent")}
        />
        <label htmlFor="tidspunkt" className="osg-sans-bold">
          Tidspunkt:
        </label>
        <Controller
          name="tidspunkt"
          control={control}
          render={({ field, fieldState }) => (
            <div className="react-datepicker-wrapper">
              <div className="flex relative items-center justify-end focus-within:fill-hoverblue">
                <DatePicker
                  id={field.name}
                  dateFormat="dd.MM.yyyy, HH:mm:ss"
                  showTimeInput
                  onChange={field.onChange}
                  selected={new Date(field.value)}
                  customInput={
                    <input
                      className={`ods-form-input ${
                        fieldState.error &&
                        "border-errorred focus:border-errorred"
                      }`}
                    />
                  }
                />
                <CalendarIcon className="pointer-events-none absolute mr-2 h-5 w-5" />
              </div>
            </div>
          )}
        />
        <label htmlFor="gatenavn" className="osg-sans-bold">
          Gatenavn:
        </label>
        <Controller
          name="gatenavn"
          control={control}
          render={({ field }) => (
            <div>
              <SearchDropdown
                id={field.name}
                options={gater}
                onchange={field.onChange}
                value={field.value}
                classnameDropdown="right-16"
              />
            </div>
          )}
        />
        <label htmlFor="gatenummer" className="osg-sans-bold">
          Gatenummer:
        </label>
        <input
          id="gatenummer"
          className="ods-form-input"
          {...register("gatenummer")}
          onKeyPress={handleNumberOrEmptyValueInput}
        />
        <label htmlFor="kommentar" className="osg-sans-bold">
          Kommentar:
        </label>
        <textarea
          id="kommentar"
          className="ods-form-textarea"
          {...register("kommentar")}
        />
        <label htmlFor="inntauer" className="osg-sans-bold">
          Inntauer:
        </label>
        <input
          id="inntauer"
          className="ods-form-input"
          {...register("inntauer")}
        />
        <label htmlFor="hjemmel" className="osg-sans-bold">
          Hjemmel:
        </label>
        <Controller
          name="hjemmel"
          control={control}
          render={({ field }) => (
            <select
              className="ods-form-input ods-select"
              id={field.name}
              value={field.value}
              onChange={field.onChange}
            >
              <option value={-1}></option>
              {mapEnumToNumberValues(HjemmelType).map(hjemmel => (
                <option key={hjemmel} value={hjemmel as HjemmelType}>
                  {HjemmelTypeMapper[hjemmel as HjemmelType].navn}
                </option>
              ))}
            </select>
          )}
        />
        <label htmlFor="overtredelse" className="osg-sans-bold">
          Overtredelse:
        </label>
        <Controller
          name="overtredelse"
          control={control}
          render={({ field }) => (
            <select
              className="ods-form-input ods-select"
              id={field.name}
              value={field.value}
              onChange={field.onChange}
            >
              <option value=""></option>
              {overtredelser.map(overtredelse => (
                <option key={overtredelse} value={overtredelse}>
                  {overtredelse}
                </option>
              ))}
            </select>
          )}
        />
        <label htmlFor="skader" className="osg-sans-bold">
          Skader:
        </label>
        <textarea
          id="skader"
          className="ods-form-textarea"
          {...register("skader")}
        />
        <label htmlFor="kontaktetEier" className="osg-sans-bold h-12">
          Kontaktet eier:
        </label>
        <Controller
          name="kontaktetEier"
          control={control}
          render={({ field }) => (
            <Toggle
              id={field.name}
              leftText="Nei"
              rightText="Ja"
              value={field.value}
              toggleFunction={() => field.onChange(!field.value)}
            />
          )}
        />
        {kontaktetEier && (
          <>
            <label htmlFor="eierkontakt" className="osg-sans-bold">
              Eierkontakt:
            </label>
            <textarea
              id="eierkontakt"
              className="ods-form-textarea"
              {...register("eierkontakt")}
            />
          </>
        )}

        <label className="osg-sans-bold" htmlFor="utenskilt">
          Uten Skilter
        </label>
        <Controller
          name="utenskilt"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Toggle
              id="utenskilt"
              leftText="Nei"
              rightText="Ja"
              value={field.value}
              toggleFunction={() => field.onChange(!field.value)}
              {...register("utenskilt", { value: field.value })}
            />
          )}
        />
      </div>
      {putInntauingsgrunnlag.isError && (
        <div className="mb-5">
          <AlertBox
            alertLevel="error"
            heading={(putInntauingsgrunnlag.error as Error)?.message ?? ""}
          />
        </div>
      )}

      <div className="flex flex-row mb-2">
        <button
          className="btn btn--flat btn--icon-left mr-5"
          onClick={avbrytRedigering}
        >
          <Close className="fill-current w-5" />
          <span>Avbryt</span>
        </button>
        <button
          data-testid="rediger-gebyrinfo-bekreftknapp"
          type="submit"
          className="btn btn--primary btn--icon-right"
          disabled={Object.entries(errors).length > 0}
        >
          <ArrowDown className="fill-current w-5 inline-flex rotate-[-90deg]" />
          <span>Bekreft</span>
        </button>
      </div>
    </form>
  );
};
