import { useEffect, useRef, useState } from "react";
import {
  InntauingViewModel,
  PrisForInntauingViewModel
} from "../../api/interfaces/Inntauing";
import QRCode from "qrcode";
import { AlertBox } from "../common/form/AlertBox";
import {
  beregnAntallDogn,
  Felter,
  mapBetalingsmetode
} from "./utleveringUtils";
import { useFormContext, useWatch } from "react-hook-form";
import { formaterQrKode } from "./utleveringQrKodeUtils";
import { Betalingstype } from "../../api/interfaces/Betaling";

// TODO: Beregne riktig antall døgn (per i dag forenkla i frontend)

interface RegistrerUtleveringQrKodeProps {
  inntauing: InntauingViewModel;
  kostnader: PrisForInntauingViewModel;
}

export const RegistrerUtleveringQrKode = (
  props: RegistrerUtleveringQrKodeProps
) => {
  const { inntauing, kostnader } = props;

  const [error, setError] = useState<any>();

  const qrkodeRef = useRef<any>();

  const { control } = useFormContext<Felter>();

  const tidspunkt = useWatch({
    control,
    name: "tidspunkt"
  });

  const henterNavn = useWatch({
    control,
    name: "navn"
  });

  const betalIleggelse = useWatch({
    control,
    name: "betalIleggelse"
  });

  const betalingsmetode = useWatch({
    control,
    name: "betalingsmetode"
  });

  const justerDognleie = useWatch({
    control,
    name: "justerDognleie"
  });

  const tidPaaTomt =
    new Date(tidspunkt ?? new Date()).getTime() -
    new Date(
      inntauing.kjoretoyleveranse?.leveringstidspunkt ?? new Date()
    ).getTime();
  const dognPaTomt = beregnAntallDogn(tidPaaTomt);
  const kjennemerke =
    inntauing.kjoretoy.registreringsnummer ??
    (inntauing.kjoretoyleveranse?.journalnummer
      ? inntauing.kjoretoyleveranse.journalnummer.toString()
      : "ukjent");
  const betalInntauingskostnader =
    mapBetalingsmetode(betalingsmetode) !== Betalingstype.Frafalt;
  const ileggelsesnummer = inntauing.ileggelse?.ileggelsesnummer;

  useEffect(() => {
    QRCode.toCanvas(
      qrkodeRef.current,
      formaterQrKode(
        kjennemerke,
        henterNavn,
        dognPaTomt,
        kostnader,
        ileggelsesnummer,
        betalIleggelse,
        betalInntauingskostnader,
        justerDognleie ||
          tidspunkt?.toDateString() !== new Date().toDateString() ||
          inntauing.betalinger.length > 0
      ),
      e => setError(e)
    );
  }, [
    betalIleggelse,
    betalInntauingskostnader,
    dognPaTomt,
    henterNavn,
    ileggelsesnummer,
    inntauing,
    justerDognleie,
    kjennemerke,
    kostnader,
    tidspunkt
  ]);

  if (error) {
    return (
      <AlertBox
        alertLevel="error"
        heading={`Kunne ikke generere QR-kode:\n\n${error.toString()}`}
      />
    );
  }

  return (
    <div>
      <canvas
        ref={qrkodeRef}
        hidden={
          mapBetalingsmetode(betalingsmetode) === Betalingstype.Faktura ||
          (mapBetalingsmetode(betalingsmetode) === Betalingstype.Frafalt &&
            !justerDognleie)
        }
      />
    </div>
  );
};
