import { useState } from "react";
import Table, { Rad } from "../common/table/Table";
import { CustomError } from "../common/typography/CustomError";
import { EierOgKjoretoysinformasjon } from "../eierOgKjoretoysinformasjon/EierOgKjoretoysinformasjon";
import { Ileggelsesinformasjon } from "../ileggelsesinformasjon/Ileggelsesinformasjon";
import Notat from "../handlingslogg/Handlingslogg";
import { BREAKPOINTS } from "../../styles/sizeVariables.js";
import {
  useInntauinger,
  useInntauingerBeholdingskontroll
} from "../../hooks/useInntauinger";
import { PocketControl } from "../pocketControl/PocketControl";
import { Inntauingsinformasjon } from "../inntauingsinformasjon/Inntauingsinformasjon";
import { Handlingsknapp } from "../common/handlingsknapp/Handlingsknapp";
import { StatusMedFarger } from "../common/card/StatusMedFarger";
import { HeadingView } from "../common/typography/HeadingView";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { RedigerEierOgKjoretoyinfo } from "../redigerEierOgKjoretoyinfo/RedigerEierOgKjoretoyinfo";
import { download_table_as_csv } from "../../utils/csvTableGenerator";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { BeholdningskontrollTabell } from "./BeholdningskontrollTabell";
import {
  InntauingViewModel,
  StatuskodeMapper
} from "../../api/interfaces/Inntauing";
import { ChecklistItem, useChecklist } from "../../hooks/useChecklist";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import {
  LastNed,
  MenyElementKnapp,
  MenyElementLenke
} from "./InntauingerTabellLastNedKnapper";
import { isSaksbehandler, isUtvikler } from "../../utils/userRolesUtils";
import { ROUTES } from "../../utils/globalConstants";
import { Filter } from "../../api/interfaces/Filter";
import { isEkspeditor } from "../../utils/userRolesUtils";
import { isInnsyn } from "../../utils/userRolesUtils";
import { InntauingFilter } from "../filter/InntauingFilter";
import { InntauingFilterKnapp } from "../filter/InntauingFilterKnapp";
import { InntauingChipFilter } from "../filter/InntauingChipFilter";
import { Betaling } from "../betaling/Betaling";
import { Brevsporing } from "../brevsporing/Brevsporing";
import { FlaggType } from "../../api/interfaces/Flagg";
import { ReactComponent as FlaggRod } from "../../assets/icons/alert-error-rod.svg";
import { ReactComponent as FlaggGul } from "../../assets/icons/alert-error-gul.svg";
import { ReactComponent as FlaggBlaa } from "../../assets/icons/alert-error-blaa.svg";
import { KolonneNasjonskode } from "../common/table/KolonneNasjonskode";
import { Tilgang } from "../common/tilgang/Tilgang";

const initSortKey = "bestillingsTidspunkt";
const initSortDirection = "desc";

export interface InntauingerTabellProps {
  setVisHandling: (inntauing?: InntauingViewModel, handling?: string) => void;
}

const isTablet =
  window.innerWidth < parseInt(BREAKPOINTS.tabletBig.replace("px", ""));

export const InntauingerTabell = (props: InntauingerTabellProps) => {
  const { setVisHandling } = props;

  const [visMeny, setVisMeny] = useState<string>();
  const [inntauingToEdit, setInntauingToEdit] = useState<string>();
  const [sortKey, setSortKey] = useState<string>(initSortKey);
  const [sortDirection, setSortDirection] = useState<"desc" | "asc">(
    initSortDirection
  );
  const [visMenyNedlasting, setVisMenyNedlasting] = useState<boolean>(false);
  const [visFilterIDom, setVisFilterIDom] = useState<boolean>(false);
  const [filter, setFilter] = useState<Filter>({
    betalingsmetoder: [],
    brevtyper: [],
    datoFra: null,
    datoTil: null,
    flaggTyper: [],
    flyttetTil: "",
    inntauetFra: "",
    sok: "",
    statuskoder: [],
    kjoretoyNasjoner: []
  });

  const ref = useOnClickOutside<HTMLDivElement>(() => {
    setVisMenyNedlasting(false);
  });

  const {
    data,
    isLoading,
    error,
    isError,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useInntauinger(sortKey, sortDirection, filter);

  const {
    data: beholdingskontrollData,
    isLoading: isLoadingBeholdingskontroll,
    error: errorBeholdingskontroll,
    isError: isErrorBeholdingskontroll
  } = useInntauingerBeholdingskontroll(sortKey, sortDirection, filter);

  const { checklist, toggleItem, checkAll, clearAll } = useChecklist(
    data?.pages
      .flatMap(x => x.data)
      .map<ChecklistItem>(i => {
        return {
          id: i.id,
          checked: false
        };
      }) ?? []
  );

  const onSorterClick = (
    kolonneId: string | undefined,
    retning: "desc" | "asc"
  ) => {
    setSortKey(kolonneId ?? "");
    setSortDirection(retning);
  };

  const handleLukkRedigering = () => setInntauingToEdit(undefined);

  const handleLastNedBeholdningsliste = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setVisMenyNedlasting(false);
    return download_table_as_csv(
      "beholdningskontroll",
      `Beholdningskontroll_for_${
        filter.datoFra
          ? new Date(filter.datoFra).toLocaleDateString().replaceAll(".", "-")
          : "ingen_fra_dato"
      }_til_${
        filter.datoTil
          ? new Date(filter.datoTil).toLocaleDateString()
          : new Date().toLocaleDateString()
      }`
    );
  };

  if (isError || isErrorBeholdingskontroll)
    return (
      <CustomError
        error={((error ?? errorBeholdingskontroll) as Error).message}
      />
    );

  const header: Rad = {
    id: "header_aktive_inntauinger",
    kolonner: isTablet
      ? [
          {
            id: "id",
            verdi: <span>ID</span>,
            isSortable: false
          },
          {
            id: "merke",
            verdi: <span>Merke</span>
          },
          {
            id: "nasjonskode",
            verdi: <span>Nasjon</span>
          },
          {
            id: "kjoretoystype",
            verdi: <span>Type</span>
          },
          {
            id: "flagg",
            verdi: <span>Flagg</span>
          },
          {
            id: "radnummer",
            verdi: <span>Rad</span>
          },
          { isSortable: false }
        ]
      : [
          {
            id: "bestillingsTidspunkt",
            verdi: <span>Innt. dato</span>
          },
          {
            id: "id",
            verdi: <span>ID</span>,
            isSortable: false
          },
          {
            id: "merke",
            verdi: <span>Merke</span>
          },
          {
            id: "modell",
            verdi: <span>Modell</span>
          },
          {
            id: "nasjonskode",
            verdi: <span>Nasjon</span>
          },
          {
            id: "kjoretoystype",
            verdi: <span>Type</span>
          },
          {
            id: "status",
            verdi: <span>Status</span>
          },
          {
            id: "flagg",
            verdi: <span>Flagg</span>
          },
          {
            id: "radnummer",
            verdi: <span>Rad</span>
          },
          { isSortable: false }
        ]
  };

  const getFlaggs = (inntauing: InntauingViewModel) => {
    let showVarselFlagg: boolean = false;
    let varselOpprettetAv: string = "";
    let varselOpprettet: Date = new Date();

    let showKlageFlagg: boolean = false;
    let klageOpprettetAv: string = "";
    let klageOpprettet: Date = new Date();

    let showVilkaarFlagg: boolean = false;
    let vilkaarOpprettetAv: string = "";
    let vilkaarOpprettet: Date = new Date();

    let showUnderArbeidFlagg: boolean = false;
    let underArbeidOpprettetAv: string = "";
    let underArbeidOpprettet: Date = new Date();

    let showMottakskontrollFlagg: boolean = false;
    let mottakskontrollOpprettetAv: string = "";
    let mottakskontrollOpprettet: Date = new Date();

    inntauing.flagg?.forEach(f => {
      switch (f.type) {
        case FlaggType.Varsel:
          if (!f.fjernet) {
            showVarselFlagg = true;
            varselOpprettetAv = f.opprettetAv;
            varselOpprettet = f.opprettet;
          }
          break;
        case FlaggType.Klage:
          if (!f.fjernet) {
            showKlageFlagg = true;
            klageOpprettetAv = f.opprettetAv;
            klageOpprettet = f.opprettet;
          }
          break;
        case FlaggType.Vilkaar:
          if (!f.fjernet) {
            showVilkaarFlagg = true;
            vilkaarOpprettetAv = f.opprettetAv;
            vilkaarOpprettet = f.opprettet;
          }
          break;
        case FlaggType.UnderArbeid:
          if (!f.fjernet) {
            showUnderArbeidFlagg = true;
            underArbeidOpprettetAv = f.opprettetAv;
            underArbeidOpprettet = f.opprettet;
          }
          break;
        case FlaggType.Mottakskontroll:
          if (!f.fjernet) {
            showMottakskontrollFlagg = true;
            mottakskontrollOpprettetAv = f.opprettetAv;
            mottakskontrollOpprettet = f.opprettet;
          }
          break;
        default:
          break;
      }
    });

    return (
      <div className="flex gap-0.5">
        {showVarselFlagg && (
          <FlaggRod
            data-testid={`varsel-flagg-${inntauing.kjoretoy.registreringsnummer}`}
            title={`Varsel, ${varselOpprettetAv}, ${new Date(
              varselOpprettet
            ).toLocaleString()}`}
          />
        )}
        {showKlageFlagg && (
          <FlaggGul
            title={`Klage, ${klageOpprettetAv}, ${new Date(
              klageOpprettet
            ).toLocaleString()}`}
          />
        )}
        {showUnderArbeidFlagg && (
          <FlaggGul
            title={`Under arbeid, ${underArbeidOpprettetAv}, ${new Date(
              underArbeidOpprettet
            ).toLocaleString()}`}
          />
        )}
        {showVilkaarFlagg && (
          <FlaggGul
            title={`Vilkårsparkering, ${vilkaarOpprettetAv}, ${new Date(
              vilkaarOpprettet
            ).toLocaleString()}`}
          />
        )}
        {showMottakskontrollFlagg && (
          <FlaggBlaa
            title={`Mottakskontroll, ${mottakskontrollOpprettetAv}, ${new Date(
              mottakskontrollOpprettet
            ).toLocaleString()}`}
          />
        )}
      </div>
    );
  };

  const rader: Rad[] =
    data?.pages
      .flatMap(x => x.data)
      .map<Rad>((i, index) => ({
        id: i.id,
        checked: checklist[index]?.checked,
        toggleCheckbox: () => toggleItem(i.id),
        cssClassDetaljer: "bg-gray-100",
        detaljer:
          inntauingToEdit !== i.id
            ? [
                <div
                  key={i.id}
                  className="grid laptop:grid-cols-4 grid-cols-12 gap-2"
                >
                  <div className="bg-white laptop:col-span-4 col-span-6 shadow-md">
                    <EierOgKjoretoysinformasjon
                      inntauingsId={i.id}
                      kjoretoy={i.kjoretoy}
                      hasKjoretoyleveranse={!!i.kjoretoyleveranse}
                      setInntauingToEdit={setInntauingToEdit}
                      isEditable={true}
                    />
                  </div>
                  <div className="col-span-3 laptop:col-span-2 shadow-md">
                    <Inntauingsinformasjon inntauing={i} isEditable={true} />
                  </div>
                  <div className="col-span-3 laptop:col-span-2 shadow-md">
                    <PocketControl inntauing={i} />
                  </div>
                  <div className="col-span-3 laptop:col-span-2 grid grid-rows-2 gap-2">
                    <div className="row-span-1 shadow-md">
                      <Betaling inntauing={i} />
                    </div>
                    <div className="row-span-1 shadow-md">
                      <Ileggelsesinformasjon inntauing={i} />
                    </div>
                  </div>
                  <div className="col-span-3 laptop:col-span-2">
                    <Brevsporing inntauing={i} />
                  </div>
                  <div className="col-span-6 laptop:col-span-4 shadow-md">
                    <Notat inntauingsId={i.id} />
                  </div>
                </div>
              ]
            : [
                <div key={i.id} className="bg-white w-full">
                  <RedigerEierOgKjoretoyinfo
                    inntauing={i}
                    handleLukkRedigering={handleLukkRedigering}
                  />
                </div>
              ],
        kolonner: isTablet
          ? [
              {
                verdi: i.kjoretoyleveranse?.journalnummer
                  ? "#" + i.kjoretoyleveranse?.journalnummer
                  : i.kjoretoy.registreringsnummer
              },
              {
                verdi: i.kjoretoy.merke
              },
              {
                verdi: (
                  <KolonneNasjonskode nasjonskode={i.kjoretoy.nasjonalitet} />
                )
              },
              {
                verdi: i.kjoretoy.kjoretoystype.kode,
                tittel: i.kjoretoy.kjoretoystype.navn
              },
              {
                verdi: i.flagg && i.flagg.length > 0 && getFlaggs(i),
                tittel: "Dra musepekeren over flaggene for mer info"
              },
              {
                verdi: i.kjoretoyleveranse?.plassering
              },
              {
                verdi: (
                  <Handlingsknapp
                    inntauing={i}
                    inntauingsstatus={i.status}
                    visMeny={visMeny}
                    setVisMeny={setVisMeny}
                    visHandling={setVisHandling}
                  />
                ),
                tittel: "Handlingsknapp"
              }
            ]
          : [
              {
                verdi: new Date(
                  i.inntauingsgrunnlag.bestillingstidspunkt.toString() ?? ""
                ).toLocaleDateString()
              },
              {
                verdi: i.kjoretoyleveranse?.journalnummer
                  ? "#" + i.kjoretoyleveranse?.journalnummer
                  : i.kjoretoy.registreringsnummer
              },
              {
                verdi: i.kjoretoy.merke
              },
              {
                verdi: i.kjoretoy.modell
              },
              {
                verdi: (
                  <KolonneNasjonskode nasjonskode={i.kjoretoy.nasjonalitet} />
                )
              },
              {
                verdi: i.kjoretoy.kjoretoystype.kode,
                tittel: i.kjoretoy.kjoretoystype.navn
              },
              {
                verdi: <StatusMedFarger statuskode={i.status} />,
                tittel: StatuskodeMapper[i.status].navn
              },
              {
                verdi: i.flagg && i.flagg.length > 0 && getFlaggs(i),
                tittel: "Dra musepekeren over flaggene for mer info"
              },
              {
                verdi: i.kjoretoyleveranse?.plassering
              },
              {
                verdi: (
                  <Handlingsknapp
                    inntauing={i}
                    inntauingsstatus={i.status}
                    visMeny={visMeny}
                    setVisMeny={setVisMeny}
                    visHandling={setVisHandling}
                  />
                ),
                tittel: "Handlingsknapp"
              }
            ]
      })) ?? [];

  const getTableOrLoadingElement = () => {
    if (isLoading || isLoadingBeholdingskontroll) return <LoadingSpinner />;
    else {
      return (
        <>
          <div className="oslo-inntauing-liste">
            <Table
              header={header}
              rader={rader}
              checked={checklist.length > 0 && checklist.every(i => i.checked)}
              checkAll={checkAll}
              clearAll={clearAll}
              showCheckboxes={isSaksbehandler() || isUtvikler() || isInnsyn()}
              onSorterClick={onSorterClick}
              onAccordionRowClick={() => setInntauingToEdit(undefined)}
              sortKolonne={sortKey && initSortKey}
              sortRetning={sortDirection && initSortDirection}
              onLastInnFlereClick={fetchNextPage}
              disableLastInnFlere={!hasNextPage || isFetchingNextPage}
            />
          </div>
          <div className="hidden">
            <BeholdningskontrollTabell
              inntauinger={beholdingskontrollData?.pages.flatMap(x => x.data)}
            />
          </div>
        </>
      );
    }
  };

  const antall = data?.pages.find(el => el.totalCount)?.totalCount ?? 0;

  return (
    <>
      <div className="flex flex-row justify-between">
        <HeadingView>{`Aktive Inntauinger - ${antall} stk`}</HeadingView>
        <div className="flex flex-row mt-5 space-x-4">
          <Tilgang harTilgang={isSaksbehandler() || isInnsyn() || isUtvikler()}>
            <LastNed
              visMeny={visMenyNedlasting}
              setVisMeny={setVisMenyNedlasting}
            />
            {visMenyNedlasting && (
              <div
                ref={ref}
                className="absolute flex flex-col z-50 right-10 top-[8.5rem] border shadow-2xl"
              >
                <ul>
                  <Tilgang harTilgang={isSaksbehandler() || isUtvikler()}>
                    <li>
                      <MenyElementKnapp
                        tekst="Beholdningsliste"
                        ikon={<Download className="fill-current" />}
                        onClick={handleLastNedBeholdningsliste}
                      />
                    </li>
                  </Tilgang>
                  <Tilgang harTilgang={isSaksbehandler() || isUtvikler()}>
                    <li>
                      <MenyElementLenke
                        tekst="Kunngjøringsliste"
                        href={
                          ROUTES.Kunngjoringsliste +
                          "?inntauingsIder=" +
                          checklist
                            .filter(c => c.checked)
                            .map(c => c.id)
                            .toString()
                        }
                      />
                    </li>
                  </Tilgang>
                  <Tilgang harTilgang={isSaksbehandler() || isUtvikler()}>
                    <li>
                      <MenyElementLenke
                        tekst="Postliste for rekommanderte brev"
                        href={
                          ROUTES.Postliste_for_rekommanderte_brev +
                          "?inntauingsIder=" +
                          checklist
                            .filter(c => c.checked)
                            .map(c => c.id)
                            .toString()
                        }
                      />
                    </li>
                  </Tilgang>
                  <Tilgang
                    harTilgang={isSaksbehandler() || isInnsyn() || isUtvikler()}
                  >
                    <li>
                      <MenyElementLenke
                        tekst="Last ned inntauinger"
                        href={
                          ROUTES.LastNedInntauinger +
                          "?inntauingsIder=" +
                          checklist
                            .filter(c => c.checked)
                            .map(c => c.id)
                            .toString()
                        }
                      />
                    </li>
                  </Tilgang>
                </ul>
              </div>
            )}
          </Tilgang>
          <Tilgang
            harTilgang={
              isSaksbehandler() || isEkspeditor() || isInnsyn() || isUtvikler()
            }
          >
            <InntauingFilterKnapp
              visFilterIDom={visFilterIDom}
              toggleVisFilterIDomCallback={setVisFilterIDom}
            />
          </Tilgang>
        </div>
      </div>
      <section className="mb-4">
        <InntauingFilter
          visFilter={{
            sok: true,
            datoFra: true,
            datoTil: true,
            inntauetFra: true,
            brevtyper: true,
            flagg: true,
            statuskoder: true,
            kjoretoyNasjoner: true,
            kjoretoystyper: true
          }}
          setFilter={setFilter}
          visning="aktive"
          setVisFilterIDom={setVisFilterIDom}
          visFilterIDom={visFilterIDom}
        />
        <InntauingChipFilter
          setFilter={setFilter}
          visChipFilterIDom={!visFilterIDom}
        />
      </section>
      {getTableOrLoadingElement()}
    </>
  );
};
