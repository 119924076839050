import { useMutation, useQueryClient } from "react-query";
import { putInntauingsGrunnlag } from "../api/apiServices/InntauingService";
import { InntauingsGrunnlagViewModel } from "../api/interfaces/Inntauing";
import { CACHE_KEYS } from "../utils/globalConstants";

export const useRedigerInntauingsgrunnlag = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: {
      inntauingsId: string;
      inntauingsgrunnlag: InntauingsGrunnlagViewModel;
    }) =>
      putInntauingsGrunnlag(
        variables.inntauingsId,
        variables.inntauingsgrunnlag
      ),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(CACHE_KEYS.Aktive_inntauinger);
      }
    }
  );
};
