import { AlertBox } from "../form/AlertBox";

export const CustomError = (props: { error: any }) => {
  const isText = typeof props.error === "string";

  if (!props.error) return null;

  return (
    <div className="mt-5">
      <AlertBox
        alertLevel="error"
        heading={isText ? props.error : JSON.stringify(props.error)}
      />
    </div>
  );
};
