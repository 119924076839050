import { Kort } from "../common/card/Kort";
import {
  BrevViewModel,
  BrevType,
  InntauingViewModel,
  SmsViewModel,
  PostkasseType
} from "../../api/interfaces/Inntauing";
import { ReactComponent as BrevsporingSvg } from "../../assets/icons/brevsporing.svg";
import { DigitaltBrev } from "./DigitaltBrev";
import { Tekstmelding } from "./Tekstmelding";
import { FysiskBrev } from "./FysiskBrev";
import { IngenBrevsporing } from "./IngenBrevsporing";

const kortContent = (
  brevutsendinger: BrevViewModel[],
  tekstmeldinger: SmsViewModel[],
  inntauingsId: string
): JSX.Element | undefined => {
  let kortContentArray = brevutsendinger.map(b => {
    switch (b.brevtype) {
      case BrevType.MeldingOmInntauetKjoretoy:
      case BrevType.MeldingOmFlyttetKjoretoy:
        return b.postkasse === PostkasseType.Fysisk ? (
          <FysiskBrev key={`fysisk-brev-${b.id}`} brevutsending={b} />
        ) : (
          <DigitaltBrev key={`digitalt-brev-${b.id}`} brevutsending={b} />
        );
      case BrevType.Avhendingsbrev:
        return (
          <FysiskBrev
            key={`fysisk-brev-${b.id}`}
            brevutsending={b}
            headerText="Rekommandert brev"
          />
        );
      case BrevType.BetalingskravFlytting:
        return (
          <DigitaltBrev
            key={`digitalt-brev-${b.id}`}
            brevutsending={b}
            headerText="Betalingskrav"
          />
        );
      default:
        return null;
    }
  });

  kortContentArray = [
    ...kortContentArray,
    ...tekstmeldinger.map(tm => {
      return <Tekstmelding key={tm.id} tekstmelding={tm} />;
    })
  ];

  const result = kortContentArray.filter(kc => kc !== null);

  if (result.length) {
    return <>{result}</>;
  }

  return <IngenBrevsporing inntauingsId={inntauingsId} />;
};

interface BrevsporingProps {
  inntauing: InntauingViewModel;
}

export const Brevsporing = (props: BrevsporingProps) => {
  const { inntauing } = props;

  if (!inntauing) return null;

  return (
    <Kort
      headerText="Sporing"
      headerIcon={<BrevsporingSvg className="fill-darkblue" />}
      innhold={kortContent(
        inntauing.brevutsendinger,
        inntauing.tekstmeldinger,
        inntauing.id
      )}
    />
  );
};
