import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from "react-query";
import {
  deleteTilVrak,
  getArkiverteVrak,
  getSendtTilVrak,
  getTilVrak,
  putAngreSendtTilVraking,
  putBekreftVraking,
  putKlargjorVrak,
  putKunngjorVrak,
  putSendTilVraking
} from "../api/apiServices/VrakService";
import { CACHE_KEYS, REFRESH_INTERVAL } from "../utils/globalConstants";

export const useOpprettVrak = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsId: string }) =>
      putKlargjorVrak(variables.inntauingsId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Til_vrak]);
        return await queryClient.invalidateQueries([
          CACHE_KEYS.Aktive_inntauinger
        ]);
      }
    }
  );
};

export const useAngreOpprettVrak = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsIder: string[] }) =>
      deleteTilVrak(variables.inntauingsIder),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Aktive_inntauinger]);
        return await queryClient.invalidateQueries([CACHE_KEYS.Til_vrak]);
      }
    }
  );
};

export const useKunngjorVrak = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsIder: string[] }) =>
      putKunngjorVrak(variables.inntauingsIder),
    {
      onSuccess: async () => {
        return await Promise.all([
          queryClient.invalidateQueries([
            CACHE_KEYS.Aktive_inntauinger_beholdingskontroll
          ]),
          queryClient.invalidateQueries([CACHE_KEYS.Til_vrak])
        ]);
      }
    }
  );
};

export const useTilVrak = () =>
  useQuery([CACHE_KEYS.Til_vrak], () => getTilVrak().then(r => r.data), {
    refetchInterval: REFRESH_INTERVAL
  });

export const useSendtTilVrak = () =>
  useQuery(
    [CACHE_KEYS.Sendt_til_vrak],
    () => getSendtTilVrak().then(r => r.data),
    {
      refetchInterval: REFRESH_INTERVAL
    }
  );

export const useArkiverteVrak = (fraDato?: Date, tilDato?: Date) =>
  useInfiniteQuery(
    [CACHE_KEYS.Arkiverte_vrak, fraDato, tilDato],
    ({ pageParam = 0 }) =>
      getArkiverteVrak(pageParam, 1000, fraDato, tilDato).then(r => r.data),
    {
      getNextPageParam: lastPage => lastPage.nextCursor ?? undefined,
      refetchInterval: REFRESH_INTERVAL
    }
  );

export const useSendTilVraking = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsIder: string[] }) =>
      putSendTilVraking(variables.inntauingsIder),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Aktive_inntauinger]);
        await queryClient.invalidateQueries([CACHE_KEYS.Avsluttet_inntauinger]);
        await queryClient.invalidateQueries([CACHE_KEYS.Sendt_til_vrak]);
        return await queryClient.invalidateQueries([CACHE_KEYS.Til_vrak]);
      }
    }
  );
};

export const useBekreftVraking = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsId: string; registrerVraking: FormData }) =>
      putBekreftVraking(variables.inntauingsId, variables.registrerVraking),
    {
      onSuccess: async () => {
        return await Promise.all([
          queryClient.invalidateQueries([CACHE_KEYS.Sendt_til_vrak]),
          queryClient.invalidateQueries([CACHE_KEYS.Arkiverte_vrak]),
          queryClient.invalidateQueries([CACHE_KEYS.Aktive_inntauinger]),
          queryClient.invalidateQueries([CACHE_KEYS.Avsluttet_inntauinger])
        ]);
      }
    }
  );
};

export const useAngreSendtTilVraking = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsId: string }) =>
      putAngreSendtTilVraking(variables.inntauingsId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Aktive_inntauinger]);
        return await queryClient.invalidateQueries([CACHE_KEYS.Til_auksjon]);
      }
    }
  );
};
