interface KortProps {
  headerText: string;
  headerIcon?: JSX.Element;
  innhold?: JSX.Element;
  fullWidth?: boolean;
  height?: string
}

export const Kort = (props: KortProps) => {
  const { headerText, headerIcon, innhold, fullWidth, height = props.height ? props.height : "h-full" } = props;

  return (
    <div className={`text-left bg-white rounded-sm w-full ${height}`}>
      <div className="flex flex-row min-w-full w-fit py-2 px-4 mb-2 bg-rusticyellow osg-sans-bold items-center">
        {headerIcon && <div className="mr-3">{headerIcon}</div>}
        <h2 className="align-middle">{headerText}</h2>
      </div>
      <div className={`${fullWidth ? "" : "px-4"}`}>{innhold}</div>
    </div>
  );
};
