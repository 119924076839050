import { Kort } from "../common/card/Kort";
import NotatInput from "./NotatInput";
import HandlingsloggList from "./HandlingsloggList";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { useGetHandlingslogg } from "../../hooks/useHandlingslogg";
import { isInnsyn } from "../../utils/userRolesUtils";

type HandlingsloggProps = {
  inntauingsId: string;
};

const Handlingslogg = (props: HandlingsloggProps) => {
  const { inntauingsId } = props;

  const { data: handlingslogg } = useGetHandlingslogg(inntauingsId);

  return (
    <Kort
      headerText="Handlingslogg"
      headerIcon={<User className="fill-darkblue" />}
      innhold={
        <div className="pb-4">
          {!isInnsyn() && <NotatInput inntauingsId={inntauingsId} />}

          <HandlingsloggList handlingslogg={handlingslogg} />
        </div>
      }
    />
  );
};

export default Handlingslogg;
