import {
  useMutation,
  useQuery,
  useQueryClient
} from "react-query";
import {
  getAvhendingsbrevPDF,
  getVarselbrevPDF,
  postDigitaltVarselbrev,
  postFysiskAvhendingsbrev,
  postFysiskVarselbrev,
  postGenererAvhendingsbrev,
  postGenererVarselbrev
} from "../api/apiServices/BrevService";
import { BrevforesporselInputModel } from "../api/interfaces/Brev";
import {
  CACHE_KEYS,
  REFRESH_INTERVAL
} from "../utils/globalConstants";

const sendteBrevNumberOfRefetch = 4;

export const usePostGenererVarselbrev = () => {
  return useMutation((variables: { brevliste: BrevforesporselInputModel[] }) =>
    postGenererVarselbrev(variables.brevliste).then(r => r.data)
  );
};

export const usePostDigitaltVarselbrev = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { brevliste: BrevforesporselInputModel[] }) =>
      postDigitaltVarselbrev(variables.brevliste).then(r => r.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEYS.Klare_brev);
        for (let i = 0; i < sendteBrevNumberOfRefetch; i++) {
          setTimeout(() => {
            queryClient.invalidateQueries(CACHE_KEYS.Brevsporinger);
          }, i * REFRESH_INTERVAL);
        }
      }
    }
  );
};

export const usePostFysiskVarselbrev = () => {
  return useMutation((variables: { brevliste: BrevforesporselInputModel[] }) =>
    postFysiskVarselbrev(variables.brevliste).then(r => r.data)
  );
};

export const usePostGenererAvhendingsbrev = () => {
  return useMutation((variables: { brevliste: BrevforesporselInputModel[] }) =>
    postGenererAvhendingsbrev(variables.brevliste).then(r => r.data)
  );
};

export const usePostFysiskAvhendingsbrev = () => {
  return useMutation((variables: { brevliste: BrevforesporselInputModel[] }) =>
    postFysiskAvhendingsbrev(variables.brevliste).then(r => r.data)
  );
};

export const useBrevPDF = (
  brevId: string,
  brevtype: "varselbrev" | "avhendingsbrev"
) =>
  useQuery(
    [CACHE_KEYS.Brev_pdf, brevId],
    brevtype === "varselbrev"
      ? () =>
          getVarselbrevPDF(brevId).then(r =>
            window.URL.createObjectURL(
              new Blob([r.data], { type: "application/pdf" })
            )
          )
      : () =>
          getAvhendingsbrevPDF(brevId).then(r =>
            window.URL.createObjectURL(
              new Blob([r.data], { type: "application/pdf" })
            )
          ),
    {
      retry: 15,
      retryDelay: 2000
    }
  );
