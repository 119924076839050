import React, { useEffect } from "react";

import { ReactComponent as AlertError } from "../../../assets/icons/toast-error.svg";
import { ReactComponent as AlertSuccess } from "../../../assets/icons/toast-success.svg";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";

import { ToastDefinition, useToast } from "./ToastProvider";

const Toast = ({ toast }: { toast: ToastDefinition }): JSX.Element => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(toast.id);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [toast, removeToast]);

  return (
    <div
      key={toast.id}
      className={`mt-3 mr-5 ml-auto flex w-full max-w-lg justify-between border-l-2 p-3 shadow-2xl ${
        toast.state === "success"
          ? "border-[#43f8b6] bg-[#C7FDE9] "
          : "border-[#FF8274] bg-[#FFDFDC]"
      }`}
      id={toast.id.toString()}
    >
      <div className="flex my-auto gap-x-1" data-testid="toast">
        <div>
          {toast.state === "success" ? (
            <AlertSuccess className="h-6" />
          ) : (
            <AlertError className="h-6" />
          )}
        </div>
        <div>{toast.content}</div>
      </div>
      <button data-testid="close-toast" onClick={() => removeToast(toast.id)}>
        <Close className="fill-inherit w-5" />
      </button>
    </div>
  );
};

export default Toast;
