import React, { memo } from "react";

export interface FileListProps {
  files: File[];
  setFiles: (files: File[]) => void;
}

export const FileList = memo(
  (props: React.PropsWithChildren<FileListProps>) => (
    <ul>
      {props.files.map((file: File) => (
        <li
          key={`${file.name}_${file.lastModified}`}
          className="flex flex-row flex-wrap justify-between text-darkblue max-w-[27rem]"
        >
          <div>
            <span>{file.name}</span>{" "}
            <span>({Math.round(file.size / 1000)}kb)</span>
          </div>
          <div>
            <button
              className="flex flex-row underline underline-offset-2 m-[-0.1rem]"
              onClick={() => {
                const temp = props.files.filter(
                  i =>
                    i.name !== file.name || i.lastModified !== file.lastModified
                );

                props.setFiles(temp);
              }}
            >
              Slett
            </button>
          </div>
        </li>
      ))}
    </ul>
  )
);
