import { endOfDay, addYears } from "date-fns";
import { useStateParams } from "../../../hooks/useStateParams";
import { HeadingView } from "../../common/typography/HeadingView";
import { FraTilDato } from "../../common/form/FraTilDato";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { ReactComponent as Print } from "../../../assets/icons/print.svg";
import { HeadingTopic } from "../../common/typography/HeadingTopic";
import { useVedtak } from "../../../hooks/useKlage";
import { LoadingSpinner } from "../../common/loading/LoadingSpinner";
import { CustomError } from "../../common/typography/CustomError";
import { AlertBox } from "../../common/form/AlertBox";
import { KlagevedtakType } from "../../../api/interfaces/Klage";
import { HeadingUtskrift } from "../../common/typography/HeadingUtskrift";
import { TilbakebetalingerTabell } from "./TilbakebetalingerTabell";

export const Tilbakebetalinger = () => {
  const etArBakat = addYears(new Date(), -1);
  const iDag = endOfDay(new Date());

  const [fraDato, setFraDato] = useStateParams(
    etArBakat,
    "fraVedtaksdato",
    s => s.toISOString(),
    s => new Date(s)
  );
  const [tilDato, setTilDato] = useStateParams(
    iDag,
    "tilVedtaksdato",
    s => s.toISOString(),
    s => new Date(s)
  );

  const { data, isLoading, isError, error } = useVedtak(
    true,
    undefined,
    [KlagevedtakType.DelvisMedhold, KlagevedtakType.Medhold],
    fraDato,
    tilDato
  );

  const componentPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    pageStyle: "m-5"
  });

  return (
    <>
      <HeadingView>Tilbakebetalinger</HeadingView>
      <FraTilDato
        actionButtons={
          <>
            <button
              type="button"
              className="btn btn--primary-confirm btn--icon-left mb-5 mt-auto"
              onClick={handlePrint}
            >
              <Print className="fill-current" />
              <span>Skriv ut</span>
            </button>
          </>
        }
        fraDato={fraDato}
        fraDatoLabel="Fra vedtaksdato"
        tilDato={tilDato}
        tilDatoLabel="Til vedtaksdato"
        handleChangeFraDato={setFraDato}
        handleChangeTilDato={setTilDato}
      />
      <HeadingTopic>
        Registrerte tilbakebetalinger for gitt periode
      </HeadingTopic>
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      {data && (
        <>
          {[...data.klagevedtakFlyttinger, ...data.klagevedtakInntauinger]
            .length === 0 ? (
            <div className="mt-5">
              <AlertBox
                alertLevel="warning"
                heading="Fant ingen vedtak med medhold eller delvis medhold for gitt periode"
              />
            </div>
          ) : (
            <>
              <div className="mt-5">
                <div className="font-semibold w-fit h-fit">{`Inntauing til inntauingsanlegg: ${data.klagevedtakInntauinger.length}`}</div>
                <TilbakebetalingerTabell
                  id="inntauing-til-inntauingsanlegg"
                  klagevedtak={data.klagevedtakInntauinger}
                  queryCashkey={{
                    vedtakstyper: [
                      KlagevedtakType.DelvisMedhold,
                      KlagevedtakType.Medhold
                    ],
                    vedtakstidspunktFra: fraDato,
                    vedtakstidspunktTil: tilDato
                  }}
                />
                <div className="font-semibold w-fit h-fit">{`Flyttet på gategrunn: ${data.klagevedtakFlyttinger.length}`}</div>
                <TilbakebetalingerTabell
                  id="flyttet-pa-gategrunn"
                  klagevedtak={data.klagevedtakFlyttinger}
                  queryCashkey={{
                    vedtakstyper: [
                      KlagevedtakType.DelvisMedhold,
                      KlagevedtakType.Medhold
                    ],
                    vedtakstidspunktFra: fraDato,
                    vedtakstidspunktTil: tilDato
                  }}
                />
              </div>
              <div className="hidden">
                <div className="text-xs" ref={componentPrintRef}>
                  <HeadingUtskrift>{`Tilbakebetalinger ${fraDato.toLocaleDateString()} - ${tilDato.toLocaleDateString()}`}</HeadingUtskrift>
                  <div className="font-semibold w-fit h-fit">{`Inntauing til inntauingsanlegg: ${data.klagevedtakInntauinger.length}`}</div>
                  <TilbakebetalingerTabell
                    id="inntauing-til-inntauingsanlegg-print"
                    klagevedtak={data.klagevedtakInntauinger}
                    queryCashkey={{
                      vedtakstyper: [
                        KlagevedtakType.DelvisMedhold,
                        KlagevedtakType.Medhold
                      ],
                      vedtakstidspunktFra: fraDato,
                      vedtakstidspunktTil: tilDato
                    }}
                  />
                  <div className="font-semibold w-fit h-fit">{`Flyttet på gategrunn: ${data.klagevedtakFlyttinger.length}`}</div>
                  <TilbakebetalingerTabell
                    id="flyttet-pa-gategrunn-print"
                    klagevedtak={data.klagevedtakFlyttinger}
                    queryCashkey={{
                      vedtakstyper: [
                        KlagevedtakType.DelvisMedhold,
                        KlagevedtakType.Medhold
                      ],
                      vedtakstidspunktFra: fraDato,
                      vedtakstidspunktTil: tilDato
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
