import { ReactComponent as Illustration } from "../assets/icons/login.svg";
import { ReactComponent as NyttVindu } from "../assets/icons/nyttvindu.svg";
import { useAuth } from "react-oidc-context";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { handleTrykkEnter } from "../utils/keyboardUtils";

interface LocationState {
  from: {
    pathname: string;
  };
}

export const LoginScreen = () => {
  const [search, setSearch] = useState("");

  const auth = useAuth();
  const location = useLocation();

  const locationState = location.state as LocationState;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(e.currentTarget.value);

  const handleSignin = useCallback(
    () => auth.signinRedirect({ state: locationState }),
    [auth, locationState]
  );

  return (
    <div className="absolute left-0 top-0 pt-[4.5rem] tabletBig:pt-[3rem] overflow-hidden flex flex-row w-full h-full bg-rusticyellow">
      <Illustration className="fill-transparent tabletBig:hidden h-full w-auto bg-[conic-gradient(#266963_90deg,_#034B45_90deg_270deg,_#266963_270deg_0deg)]" />
      <div className="flex flex-col text-left justify-center items-center mx-auto">
        <div className="mb-5 mx-36 tabletBig:mx-20 text-4xl text-center w-[50%]">
          Velkommen til saksbehandlingssiden for inntauinger i Oslo kommune
        </div>
        <button
          className="btn btn--primary"
          data-testid="login-button"
          onClick={handleSignin}
        >
          Logg inn
        </button>

        <div className="mt-5 mx-36 tabletBig:mx-20 text-xl border-dashed border-2 border-darkblue px-5 pt-4 max-w-sm bg-rusticyellow">
          <div className="ods-form-group">
            <label className="ods-form-label">
              Er du innbygger i Oslo kommune og lurer på om bilen er tauet inn?
            </label>
            <div className="ods-form-help">
              Søk registreringsnummer/chassisnummer
            </div>
            <div className="flex flex-row focus-within:fill-hoverblue fill-darkblue">
              <input
                value={search}
                onChange={handleSearch}
                onKeyDown={e =>
                  handleTrykkEnter(e, () =>
                    window.open(
                      `https://register-bil-api.api.oslo.kommune.no/?searchInput=${search}`,
                      "blank",
                      "noreferrer"
                    )
                  )
                }
                className="ods-form-input"
              />
              <a
                href={`https://register-bil-api.api.oslo.kommune.no/?searchInput=${search}`}
                rel="noopener noreferrer"
                target="_blank"
                role="button"
                className="ml-[2px] bg-white active:bg-darkblue hover:fill-white hover:bg-hoverblue p-2 rounded-full"
              >
                <NyttVindu className="fill-current" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
