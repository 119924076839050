import { AlleInntauingerTabell } from "./AlleInntauingerTabell";

export const AlleInntauinger = () => {
  return (
    <div className="flex flex-row h-full">
      <div className="mr-10 oslo-inntauing-visning ml-[3rem]">
        <AlleInntauingerTabell />
      </div>
    </div>
  );
};
