import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { RequiredLabel } from "../common/form/RequiredLabel";
import { Toggle } from "../common/form/Toggle";
import { yupResolver } from "@hookform/resolvers/yup";
import { manuelleBrevMottakerinfoSchema } from "./manuelleBrevMottakerinfoSchema";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { ReactComponent as Refresh } from "../../assets/icons/arrow-circle.svg";
import { ReactComponent as Print } from "../../assets/icons/print.svg";
import { finnEier } from "../../utils/objectMapping";
import { useToast } from "../common/toast/ToastProvider";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import {
  usePostFysiskVarselbrev,
  usePostGenererVarselbrev
} from "../../hooks/useBrev";
import { BrevforesporselMottakerInputModel } from "../../api/interfaces/Brev";
import { FeilmeldingerSkjema } from "../common/form/FeilmeldingerSkjema";

interface ManuelleBrevFysiskVarselbrevProps {
  handleOppdaterBrevId: (id: string) => void;
  handleAvbrytBrevsending: () => void;
  inntauing?: InntauingViewModel;
  pdfLoading: boolean;
  pdfError: boolean;
}

export const ManuelleBrevFysiskVarselbrev = (
  props: ManuelleBrevFysiskVarselbrevProps
) => {
  const {
    handleOppdaterBrevId,
    handleAvbrytBrevsending,
    inntauing,
    pdfLoading,
    pdfError
  } = props;

  const initialValues: BrevforesporselMottakerInputModel = {
    navn: "",
    gateadresse: "",
    postnummer: "",
    poststed: ""
  };

  const [felterGenerertPdf, setFelterGenerertPdf] = useState(initialValues);

  // const [erRekommandertBrev, setErRekommadertBrev] = useState(false);

  const [brevErGenerert, setBrevErGenerert] = useState(false);
  const [brevErArkivert, setBrevErArkivert] = useState(false);

  const postGenererVarselbrev = usePostGenererVarselbrev();
  const postFysiskVarselbrev = usePostFysiskVarselbrev();

  const { addToast } = useToast();

  // const handleToggleRekommandertBrev = () => {
  //   setErRekommadertBrev(!erRekommandertBrev);
  // };

  const {
    formState: { errors },
    control,
    register,
    setValue,
    handleSubmit
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(manuelleBrevMottakerinfoSchema),
    reValidateMode: "onChange",
    defaultValues: initialValues,
    shouldFocusError: true,
    shouldUseNativeValidation: true
  });

  const currForm = useWatch({ control });

  const handleGenererPdf = (data: BrevforesporselMottakerInputModel) => {
    const formIsValid = Object.values(data).every(
      (value: string) => value !== ""
    );

    setFelterGenerertPdf(prev => ({
      ...prev,
      ...data
    }));

    if (formIsValid) {
      postGenererVarselbrev.mutate(
        {
          brevliste: [{ inntauingsId: inntauing?.id ?? "", mottaker: data }]
        },
        {
          onSuccess: response => {
            if (response.length > 0) {
              handleOppdaterBrevId(response[0].brevId);
              setBrevErGenerert(true);
            }
          }
        }
      );
    }
  };

  const eier = finnEier(
    inntauing?.kjoretoy.juridiskEier,
    inntauing?.kjoretoy.medeier,
    inntauing?.kjoretoy.leasingtaker
  );

  const handleEierErMottaker = (annenMottaker: boolean) => {
    setBrevErGenerert(false);

    if (!annenMottaker) {
      setValue("navn", eier?.navn ?? "", { shouldValidate: true });
      setValue("gateadresse", eier?.gateadresse ?? "", {
        shouldValidate: true
      });
      setValue("postnummer", eier?.postnummer ?? "", { shouldValidate: true });
      setValue("poststed", eier?.poststed ?? "", { shouldValidate: true });
    } else {
      setValue("navn", "", {
        shouldValidate: Object.entries(errors).length > 0
      });
      setValue("gateadresse", "", {
        shouldValidate: Object.entries(errors).length > 0
      });
      setValue("postnummer", "", {
        shouldValidate: Object.entries(errors).length > 0
      });
      setValue("poststed", "", {
        shouldValidate: Object.entries(errors).length > 0
      });
    }
  };

  const handleArkiver = () => {
    const formIsValid =
      JSON.stringify(currForm) === JSON.stringify(felterGenerertPdf);

    if (formIsValid && brevErGenerert) {
      postFysiskVarselbrev.mutate(
        {
          brevliste: [
            { inntauingsId: inntauing?.id ?? "", mottaker: felterGenerertPdf }
          ]
        },
        {
          onSuccess: response => {
            if (response.length > 0) {
              setBrevErArkivert(true);
              addToast("Fysisk brev er sendt!");
            } else {
              addToast("Feil ved sending av fysisk brev!", "error");
            }
          },
          onError: () => {
            addToast("Feil ved sending av fysisk brev!", "error");
          }
        }
      );
    }
  };

  return (
    <>
      <p className="text-2xl my-8">
        Legg inn informasjon om mottaker av brevet
      </p>
      <Toggle
        testid="toggle-mottaker-brevsending"
        leftText="Annen mottaker"
        rightText="Eier er mottaker"
        disabled={!eier}
        toggleFunction={e => handleEierErMottaker(e)}
      />

      <form
        className="ods-form mt-10 mr-10"
        onSubmit={handleSubmit(handleGenererPdf)}
      >
        <div className="ods-form-group ">
          <label htmlFor="navn" className="ods-form-label flex flex-wrap">
            Mottakerens navn
            <RequiredLabel />
          </label>
          <div id="navnHelp" className="ods-form-help">
            Fornavn Etternavn
          </div>
          <input
            id="navn"
            className="ods-form-input"
            {...register("navn")}
            autoComplete="off"
          />
        </div>

        <div className="ods-form-group">
          <label
            htmlFor="gateadresse"
            className="ods-form-label flex flex-wrap"
          >
            Mottakers gateadresse
            <RequiredLabel />
          </label>
          <div id="gateadresseHelp" className="ods-form-help">
            Gatenavn Gatenummer
          </div>
          <input
            id="gateadresse"
            className="ods-form-input"
            {...register("gateadresse")}
            autoComplete="off"
          />
        </div>

        <div className="grid grid-cols-[200px_1fr] gap-5">
          <div className="ods-form-group">
            <label
              htmlFor="postnummer"
              className="ods-form-label flex flex-wrap"
            >
              Postnummer
              <RequiredLabel />
            </label>
            <input
              id="postnummer"
              className="ods-form-input"
              {...register("postnummer")}
              autoComplete="off"
            />
          </div>

          <div className="ods-form-group">
            <label htmlFor="poststed" className="ods-form-label flex flex-wrap">
              Poststed
              <RequiredLabel />
            </label>
            <input
              id="poststed"
              className="ods-form-input"
              {...register("poststed")}
              autoComplete="off"
            />
          </div>
        </div>
        <FeilmeldingerSkjema className="mb-5 w-fit" errors={errors} />
        {/* <div className="my-10">
          <p className="mb-5 text-2xl">Send som rekommandert</p>
          <Toggle
            leftText="Ikke rekommandert"
            rightText="Rekommandert brev"
            toggleFunction={handleToggleRekommandertBrev}
          />
        </div> */}

        <div className="flex flex-wrap flex-row gap-5">
          <button
            type="button"
            className="btn btn--flat btn--icon-left"
            onClick={handleAvbrytBrevsending}
          >
            <ArrowDown className="fill-current inline-flex rotate-[90deg]" />
            <span>Avbryt</span>
          </button>

          <button
            data-testid="generer-fysisk-varselbrev"
            className="btn btn--secondary btn--icon-left"
            disabled={
              pdfLoading ||
              Object.values(errors).length > 0 ||
              JSON.stringify(currForm) === JSON.stringify(felterGenerertPdf)
            }
          >
            <Refresh className="fill-current inline-flex" />
            <span>Generer brev</span>
          </button>

          <button
            data-testid="brevsending-fysisk-send"
            className="btn btn--primary btn--icon-left"
            type="button"
            onClick={handleArkiver}
            disabled={
              pdfError ||
              pdfLoading ||
              brevErArkivert ||
              !brevErGenerert ||
              Object.values(errors).length > 0 ||
              JSON.stringify(currForm) !== JSON.stringify(felterGenerertPdf)
            }
          >
            <Print className="fill-current inline-flex" />
            <span>Arkiver brev</span>
          </button>
        </div>
      </form>
    </>
  );
};
