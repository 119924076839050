import { useState } from "react";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { usePostDigitaltVarselbrev } from "../../hooks/useBrev";
import { useKansellerBetalingskrav } from "../../hooks/useInntauinger";
import { RequiredLabel } from "../common/form/RequiredLabel";
import { useToast } from "../common/toast/ToastProvider";
import { HeadingView } from "../common/typography/HeadingView";

interface KansellerBetalingskravProps {
  inntauing: InntauingViewModel;
  close: () => void;
}

export const KansellerBetalingskrav = (props: KansellerBetalingskravProps) => {
  const { inntauing, close } = props;

  const [kommentar, setKommentar] = useState<string>("");
  const [sendVarselbrev, setSendVarselbrev] = useState<boolean | undefined>(
    undefined
  );

  const putKansellerBetalingskrav = useKansellerBetalingskrav();
  const postPostDigitaltVarselbrev = usePostDigitaltVarselbrev();

  const { addToast } = useToast();

  const handleKansellerBetalingskrav = () => {
    if (sendVarselbrev) {
      handleKansellerBetalingskravMedBrev();
    } else {
      handleKansellerBetalingskravUtenBrev();
    }
  };

  const handleKansellerBetalingskravMedBrev = () => {
    putKansellerBetalingskrav.mutate(
      {
        inntauingsId: inntauing.id,
        aarsak: kommentar
      },
      {
        onSuccess: () => {
          postPostDigitaltVarselbrev.mutate(
            {
              brevliste: [
                {
                  inntauingsId: inntauing?.id ?? ""
                }
              ]
            },
            {
              onSuccess: () => {
                addToast(`Betalingskrav ble kansellert og eier er varslet`);
                close();
              },
              onError: () => {
                addToast(
                  `Betalingskrav ble kansellert, men eier ble ikke varslet`,
                  "error"
                );
              }
            }
          );
        },
        onError: () => {
          addToast(`Betalingskrav ble ikke kansellert`, "error");
        }
      }
    );
  };

  const handleKansellerBetalingskravUtenBrev = () => {
    putKansellerBetalingskrav.mutate(
      {
        inntauingsId: inntauing.id,
        aarsak: kommentar
      },
      {
        onSuccess: () => {
          addToast(`Betalingskrav ble kansellert uten varsling av eier`);
          close();
        },
        onError: () => {
          addToast(`Betalingskrav ble ikke kansellert`, "error");
        }
      }
    );
  };

  const handleBokstaverEllerTab = (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (!/[a-zA-ZåæøÅÆØ\s.,?!]/g.test(e.key) && e.key !== "Tab")
      e.preventDefault();
  };

  return (
    <>
      <HeadingView>Kanseller betalingskrav</HeadingView>
      <>
        <div className="ods-form-group">
          <label htmlFor="kommentar" className="ods-form-label flex flex-row">
            Kanseller betalingskrav
            <RequiredLabel />
          </label>
          <div id="kommentarHelp" className="ods-form-help">
            Forklar hvorfor betalingskravet skal kanselleres
          </div>
          <textarea
            data-testid="kanseller-betalingskrav-kommentar"
            className="ods-form-textarea w-96"
            id="kommentar"
            value={kommentar}
            onChange={e => setKommentar(e.currentTarget.value)}
            autoComplete="off"
            autoFocus
            maxLength={250}
            onKeyDown={handleBokstaverEllerTab}
          />
          <small>{`${kommentar.length}/250 bokstaver`}</small>
        </div>
        <div>
          <label
            htmlFor="send-varselbrev-radio-group"
            className="ods-form-label flex flex-row"
          >
            Send varselbrev nå
            <RequiredLabel />
          </label>
          <div id="send-varselbrev-radio-group">
            <div className="ods-form-group">
              <input
                checked={sendVarselbrev === true}
                onChange={() => setSendVarselbrev(true)}
                data-testid="kanseller-betalingskrav-varselbrev-ja"
                type="radio"
                className="ods-form-check-input"
                id="send-varselbrev-ja"
                name="velg-send-varselbrev-nå-group"
              />
              <label className="ods-form-label" htmlFor="digitalt">
                Ja
              </label>
            </div>
            <div id="send-varselbrev-radio-group" className="ods-form-group">
              <input
                checked={sendVarselbrev === false}
                onChange={() => setSendVarselbrev(false)}
                data-testid="kanseller-betalingskrav-varselbrev-nei"
                type="radio"
                className="ods-form-check-input"
                id="send-varselbrev-nei"
                name="velg-send-varselbrev-nå-group"
              />
              <label className="ods-form-label" htmlFor="fysisk">
                Nei
              </label>
            </div>
          </div>
        </div>
        <div className="flex flex-row flex-wrap gap-5">
          <button className="btn btn--secondary" onClick={close}>
            Avbryt
          </button>
          <button
            data-testid="kanseller-betalingskrav-bekreft"
            className="btn btn--primary"
            disabled={kommentar.length === 0 || sendVarselbrev === undefined}
            onClick={handleKansellerBetalingskrav}
          >
            Bekreft
          </button>
        </div>
      </>
    </>
  );
};
