import { useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { HeadingView } from "../common/typography/HeadingView";
import { AlertBox } from "../common/form/AlertBox";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { ReactComponent as Print } from "../../assets/icons/print.svg";
import { ListeTilNedlasting } from "./ListeTilNedlasting";
import { InntauingUtskrift } from "./InntauingUtskrift";
import { ROUTES } from "../../utils/globalConstants";

export const LastNedInntauinger = () => {
  const [search] = useSearchParams();
  const inntauingsIder =
    search
      .get("inntauingsIder")
      ?.split(",")
      .filter(id => id) ?? [];
  const navigate = useNavigate();

  const componentPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    pageStyle: "m-5"
  });

  if (inntauingsIder.length === 0) {
    return (
      <div className="flex flex-col mx-5 mb-10">
        <HeadingView>Last ned Inntauinger</HeadingView>
        <AlertBox
          alertLevel="warning"
          heading="Ingen inntauinger valgt"
          paragraph={
            <p>
              Du må velge minst én inntauing ved å krysse av i Aktive
              inntauinger for å kunne laste ned inntauinger. Du må også sørge
              for at minst én ID kommer med i adressen til nettsiden.
            </p>
          }
        />
        <div className="flex flex-row flex-wrap gap-5 mt-10">
          <button
            className="btn btn--flat btn--icon-left"
            onClick={() => navigate(ROUTES.Aktive_inntauinger)}
          >
            <ArrowDown className="fill-current inline-flex rotate-[90deg]" />
            <span>Tilbake</span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col mx-5 mb-10">
      <HeadingView>Last ned Inntauinger</HeadingView>
      <ListeTilNedlasting inntauingsIder={inntauingsIder} />
      <div className="flex flex-row flex-wrap gap-5 mt-10">
        <button
          className="btn btn--flat btn--icon-left"
          onClick={() => navigate(ROUTES.Aktive_inntauinger)}
        >
          <ArrowDown className="fill-current inline-flex rotate-[90deg]" />
          <span>Tilbake</span>
        </button>
        <button
          className="btn btn--primary-confirm btn--icon-left"
          onClick={handlePrint}
        >
          <Print className="fill-current" />
          <span>Skriv ut</span>
        </button>
      </div>
      <div className="hidden">
        <div
          className="text-xs break-after-page grayscale [&_*]:border-none [&_*]:shadow-none [&_*]:bg-transparent"
          ref={componentPrintRef}
        >
          {inntauingsIder.map(id => (
            <InntauingUtskrift key={id} inntauingsId={id} />
          ))}
        </div>
      </div>
    </div>
  );
};
