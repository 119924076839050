import { useMutation, useQuery, useQueryClient } from "react-query";
import { CACHE_KEYS } from "../utils/globalConstants";
import { KlagevedtakType } from "../api/interfaces/Klage";
import { getVedtak, putVedtak } from "../api/apiServices/KlageService";
import { endOfDay } from "date-fns";

export const useVedtak = (
  filtrer0Sum: boolean,
  erTilbakebetalt?: boolean,
  vedtakstyper?: KlagevedtakType[],
  vedtakstidspunktFra?: Date,
  vedtakstidspunktTil?: Date
) =>
  useQuery(
    [CACHE_KEYS.Vedtak, vedtakstyper, vedtakstidspunktFra, vedtakstidspunktTil],
    () =>
      getVedtak(
        vedtakstyper,
        vedtakstidspunktFra,
        vedtakstidspunktTil,
        filtrer0Sum,
        erTilbakebetalt
      ).then(r => r.data)
  );

export const useVedtakMedGrunnlagForTilbakebetaling = () => {
  const fra = new Date(0);
  const til = endOfDay(new Date());
  const vedtakstyper = [KlagevedtakType.DelvisMedhold, KlagevedtakType.Medhold];
  const erTilbakebetalt = undefined;
  const filtrer0Sum = true;

  return useQuery([CACHE_KEYS.Vedtak, vedtakstyper, fra, til], () =>
    getVedtak(vedtakstyper, fra, til, filtrer0Sum, erTilbakebetalt).then(
      r => r.data
    )
  );
};

export const usePutVedtak = (
  klagevedtakId: string,
  queryCashkey: {
    vedtakstyper?: KlagevedtakType[];
    vedtakstidspunktFra?: Date;
    vedtakstidspunktTil?: Date;
  }
) => {
  const queryClient = useQueryClient();

  return useMutation(() => putVedtak(klagevedtakId), {
    onSuccess: () => {
      return queryClient.invalidateQueries([
        CACHE_KEYS.Vedtak,
        queryCashkey.vedtakstyper,
        queryCashkey.vedtakstidspunktFra,
        queryCashkey.vedtakstidspunktTil
      ]);
    }
  });
};
