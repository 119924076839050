import { apiClient } from "../client";
import { TotalFaktureringsgrunnlagViewModel } from "../interfaces/Kranbilselskap";
import { config } from "./Config";

const client = apiClient();

export const getFaktureringsgrunnlag = (datoFra: Date, datoTil: Date) =>
  client.get<TotalFaktureringsgrunnlagViewModel>(
    `kranbilselskap/faktureringsgrunnlag?fra=${datoFra.toISOString()}&til=${datoTil.toISOString()}`,
    config()
  );
