import { useMutation, useQueryClient } from "react-query";
import { putGjenopprettBetalingskrav } from "../api/apiServices/BetalingskravService";
import { CACHE_KEYS } from "../utils/globalConstants";

export const useGjenopprettBetalingskrav = () => {
    const queryClient = useQueryClient();
  
    return useMutation(
      (variables: { betalingskravId: string }) =>
        putGjenopprettBetalingskrav(variables.betalingskravId),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(CACHE_KEYS.Flyttet_pa_gategrunn);
          return await queryClient.invalidateQueries(
            CACHE_KEYS.Flyttet_pa_gategrunn
          );
        }
      }
    );
  };