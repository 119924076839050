import * as yup from "yup";

export const kasseavstemmingSchema = yup.object().shape({
    avvikskommentar: yup.string().required("Skriv inn årsak til differanse"),
    zRapporter: yup.array().of(
      yup.object().shape({
        zNummer: yup.string(),
        sesjonsnummer: yup
          .string()
          .required("Fyll inn sesjonsnr fra bax-kvittering for Z-rapport ")
      })
    )
  });