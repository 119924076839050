import { apiClient } from "../client";
import { config } from "./Config";

const client = apiClient();

export const putIleggelse = (inntauingId: string, ileggelse: number) =>
  client.put<number>(`ileggelse/${inntauingId}/ileggelsesnummer`, {nummer: ileggelse}, config());

export const deleteIleggelse = (inntauingId: string) =>
  client.delete(`ileggelse/${inntauingId}`, config());
