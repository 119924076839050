import { formaterNullableBelop } from "../../utils/formatUtils";

interface BetalingsspesifikasjonLinjeProps {
  navn: string;
  title?: string;
  belop?: number | string;
  lineThrough?: boolean;
  understrek?: "ingen" | "enkel" | "dobbel";
  graaUt?: boolean;
  ekstrainfo?: string;
}

export const BetalingsspesifikasjonLinje = (
  props: BetalingsspesifikasjonLinjeProps
) => {
  const {
    navn,
    title,
    belop,
    lineThrough,
    understrek = "enkel",
    graaUt,
    ekstrainfo
  } = props;

  return (
    <div
      className={`grid grid-cols-3 py-2 ${graaUt && "text-gray-400"} ${
        understrek === "enkel" && "border-solid border-b-2"
      } ${
        understrek === "dobbel" && "border-double border-b-[6px]"
      } border-gray-300`}
    >
      <dd
        className={`col-span-2 ${lineThrough && "line-through text-errorred"}`}
      >
        {navn}
      </dd>
      <dt
        title={title}
        className={`text-right ${lineThrough && "line-through text-errorred"}`}
      >
        {typeof belop === "number" || typeof belop === "undefined"
          ? formaterNullableBelop(belop)
          : belop}
      </dt>
      {ekstrainfo && <div className="text-xs">{ekstrainfo}</div>}
    </div>
  );
};
