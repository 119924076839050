import { apiClient } from "../client";
import {
  KlagevedtakSokReponseViewModel,
  KlagevedtakType
} from "../interfaces/Klage";
import { config } from "./Config";
import { queryBool, queryDate, queryNumberArray } from "./serviceUtils";

const client = apiClient();

export const getVedtak = (
  vedtakstyper?: KlagevedtakType[],
  vedtakstidspunktFra?: Date,
  vedtakstidspunktTil?: Date,
  filtrer0Sum?: boolean,
  erTilbakebetalt?: boolean
) =>
  client.get<KlagevedtakSokReponseViewModel>(
    "klage/vedtak?" +
      queryNumberArray("vedtakstyper", vedtakstyper) +
      queryBool("filtrer0Sum", filtrer0Sum) +
      queryBool("erTilbakebetalt", erTilbakebetalt) +
      queryDate("vedtakstidspunktFra", vedtakstidspunktFra) +
      queryDate("vedtakstidspunktTil", vedtakstidspunktTil),
    config()
  );

export const putVedtak = (klagevedtakId: string) =>
  client.put(
    `klage/vedtak/${klagevedtakId}/tilbakebetalt`,
    undefined,
    config()
  );
