import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { HeadingView } from "../common/typography/HeadingView";
import { EierOgKjoretoysinformasjon } from "../eierOgKjoretoysinformasjon/EierOgKjoretoysinformasjon";
import { PocketControl } from "../pocketControl/PocketControl";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registrerFlyttetPaaGategrunnSchema } from "./registrerFlyttetPaaGategrunnSchema";
import { AlertBox } from "../common/form/AlertBox";
import { RequiredLabel } from "../common/form/RequiredLabel";
import { useOpprettKjoretoyleveranse } from "../../hooks/useKjoretoyleveranse";
import { HeadingTopic } from "../common/typography/HeadingTopic";
import { useToast } from "../common/toast/ToastProvider";
import { handleNumberOrEmptyValueInput } from "../../utils/keyboardUtils";
import {
  BREVTYPER_SELECT,
  Felter,
  getInitialValues,
  mapToPostFlytting
} from "./registrerFlyttetPaaGategrunnUtils";
import {
  usePostDigitaltVarselbrev,
  usePostFysiskVarselbrev
} from "../../hooks/useBrev";
import { useInntauing } from "../../hooks/useInntauinger";
import { FeilmeldingerSkjema } from "../common/form/FeilmeldingerSkjema";

interface RegistrerFlyttetPaaGategrunnProps {
  inntauing: InntauingViewModel;
  close: () => void;
}

export const RegistrerFlyttetPaaGategrunn = (
  props: RegistrerFlyttetPaaGategrunnProps
) => {
  const { inntauing, close } = props;

  const postLeveranse = useOpprettKjoretoyleveranse();
  const { data, isLoading } = useInntauing(inntauing.id);
  const postDigitaltVarselbrev = usePostDigitaltVarselbrev();
  const postFysiskVarselbrev = usePostFysiskVarselbrev();
  const { addToast } = useToast();

  const initialValues: Felter = getInitialValues(inntauing.kjoretoyleveranse);

  const {
    formState: { errors },
    control,
    register,
    trigger,
    handleSubmit
  } = useForm<Felter>({
    mode: "onSubmit",
    resolver: yupResolver(registrerFlyttetPaaGategrunnSchema),
    defaultValues: initialValues,
    shouldFocusError: true,
    shouldUseNativeValidation: true
  });

  const brevtype = useWatch({
    control: control,
    name: "brevtype"
  });

  const registrerKjoretoysleveranse = (data: Felter) => {
    postLeveranse.mutate(mapToPostFlytting(data, inntauing), {
      onSuccess: () => {
        if (data.brevtype !== BREVTYPER_SELECT["Ikke send brev"]) {
          if (data.brevtype === 1) {
            postDigitaltVarselbrev.mutate(
              {
                brevliste: [{ inntauingsId: inntauing.id }]
              },
              {
                onSuccess: response => {
                  if (response.length === 1) {
                    addToast(
                      "Flytting på gategrunn er registrert og brev er sendt digitalt"
                    );
                  } else {
                    addToast(
                      "Flytting på gategrunn er registrert, men sending av brev feilet",
                      "error"
                    );
                  }
                }
              }
            );
          } else {
            postFysiskVarselbrev.mutate(
              {
                brevliste: [{ inntauingsId: inntauing.id }]
              },
              {
                onSuccess: response => {
                  if (response.length === 1) {
                    addToast(
                      "Flytting på gategrunn er registrert og brev behandles manuelt"
                    );
                  } else {
                    addToast(
                      "Flytting på gategrunn er registrert, men sending av brev feilet",
                      "error"
                    );
                  }
                }
              }
            );
          }
        }
        close();
      },
      onError: response => {
        addToast(
          "Registrering av flytting på gategrunn ga følgende feilmelding:\n\n" +
            JSON.stringify(response),
          "error"
        );
      }
    });
  };

  return (
    <>
      <HeadingView
        data-testid="tittel-registrer-flyttet"
        className="pl-8 w-fit"
      >
        Registrer flytting på gategrunn
      </HeadingView>
      <form
        data-testid="skjema-registrer-flyttet"
        className="pl-8 w-fit"
        onSubmit={handleSubmit(registrerKjoretoysleveranse)}
      >
        <div className="flex flex-row">
          <div className="ods-form-group w-48 mr-5">
            <label
              htmlFor="ordrenummer"
              className="ods-form-label flex flex-wrap"
            >
              Ordrenr
              <RequiredLabel />
            </label>
            <div id="ordrenummerHelp" className="ods-form-help">
              Oppgi ordrenummer fra RedGo.
            </div>
            <input
              id="ordrenummer"
              className="ods-form-input"
              {...register("ordrenummer")}
              autoFocus
              autoComplete="off"
            />
          </div>
        </div>
        <HeadingTopic>Flyttet til:</HeadingTopic>
        <p>Oppgi ny plassering av kjøretøy.</p>
        <div className="flex flex-row flex-wrap mt-3 gap-x-5">
          <div className="ods-form-group w-72">
            <label
              htmlFor="flyttetTilGatenavn"
              className="ods-form-label flex flex-wrap"
            >
              Gatenavn
              <RequiredLabel />
            </label>
            <input
              id="flyttetTilGatenavn"
              className="ods-form-input"
              {...register("flyttetTilGatenavn")}
              autoComplete="off"
            />
          </div>
          <div className="ods-form-group w-40">
            <label
              htmlFor="flyttetTilGatenummer"
              className="ods-form-label flex"
            >
              Gatenummer
              <RequiredLabel notRequired />
            </label>
            <input
              id="flyttetTilGatenummer"
              className="ods-form-input"
              {...register("flyttetTilGatenummer")}
              onKeyDown={handleNumberOrEmptyValueInput}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="flex flex-row flex-wrap gap-x-5">
          <div className="ods-form-group w-32">
            <label htmlFor="flyttetTilOppgang" className="ods-form-label flex">
              Oppgang
              <RequiredLabel notRequired />
            </label>
            <input
              id="flyttetTilOppgang"
              className="ods-form-input"
              {...register("flyttetTilOppgang")}
              autoComplete="off"
            />
          </div>
          <div className="ods-form-group w-80">
            <label htmlFor="flyttetTilDetaljer" className="ods-form-label flex">
              Detaljer
              <RequiredLabel notRequired />
            </label>
            <input
              id="flyttetTilDetaljer"
              className="ods-form-input"
              {...register("flyttetTilDetaljer")}
              autoComplete="off"
            />
          </div>
        </div>
        <HeadingTopic>Brevutsendelse:</HeadingTopic>
        {data?.kanVarsles !== true && !isLoading && (
          <div className="mt-3">
            <AlertBox
              alertLevel="warning"
              heading={<h3>Mangler info for å kunne sende brev</h3>}
            />
          </div>
        )}
        {isLoading && (
          <div className="mt-3">
            <AlertBox
              alertLevel="warning"
              heading={<h3>Sjekker om brevsending er mulig...</h3>}
            />
          </div>
        )}
        <div className="mt-3">
          <AlertBox
            alertLevel="warning"
            heading={
              <h3>Betalingskrav vil genereres automatisk etter registrering</h3>
            }
          />
        </div>
        <div className="flex flex-wrap mt-3 gap-x-5">
          <div className="ods-form-group w-48">
            <label htmlFor="brevtype" className="ods-form-label flex flex-wrap">
              Brevtype
            </label>
            <div id="brevtypeHelp" className="ods-form-help">
              Oppgi brevtype ved innsending.
            </div>
            <Controller
              name="brevtype"
              control={control}
              render={({ field: { value } }) => (
                <select
                  id="brevtype"
                  disabled
                  className="ods-select ods-form-input"
                  value={value}
                  {...register("brevtype", {
                    onChange: async () => {
                      if (Object.entries(errors).length) {
                        await trigger("kommentar");
                      }
                    }
                  })}
                >
                  {Object.entries(BREVTYPER_SELECT).map(b => (
                    <option
                      key={b[0]}
                      value={b[1]}
                      defaultChecked={
                        b[1] === BREVTYPER_SELECT["Ikke send brev"]
                      }
                    >
                      {b[0]}
                    </option>
                  ))}
                </select>
              )}
            />
          </div>
          <div className="ods-form-group w-48">
            <label
              htmlFor="kommentar"
              className="ods-form-label flex flex-wrap"
            >
              Kommentar
              {brevtype >= 2 && <RequiredLabel />}
            </label>
            <div id="kommentarHelp" className="ods-form-help">
              Legg til kommentar på handlingen.
            </div>
            <input
              id="kommentar"
              disabled
              className="ods-form-input"
              {...register("kommentar")}
              autoComplete="off"
            />
          </div>
        </div>
        <FeilmeldingerSkjema className="mb-5 w-fit" errors={errors} />
        {postLeveranse.isError && (
          <div className="mb-5 w-fit">
            <AlertBox
              alertLevel="error"
              heading="Feil skjedde ved registrering"
              paragraph={
                <>
                  <div>{(postLeveranse.error as Error).message}</div>
                </>
              }
            />
          </div>
        )}
        <div className="flex flex-row">
          <div className="mr-5">
            <button
              className="btn btn--flat btn--icon-left"
              onClick={() => close()}
              type="button"
            >
              <ArrowDown className="fill-current rotate-[90deg]" />
              <span>Tilbake</span>
            </button>
          </div>
          <button type="reset" className="btn btn--secondary mr-5">
            Nullstill
          </button>
          <button
            data-testid="button-registrer-flyttet"
            className="btn btn--primary btn--icon-right"
            disabled={Object.entries(errors).some(e => e !== undefined)}
            type="submit"
          >
            <ArrowDown className="fill-current rotate-[-90deg]" />
            <span>
              {brevtype <= 0
                ? "Bekreft registrering"
                : "Bekreft registrering og send brev"}
            </span>
          </button>
        </div>
      </form>
      <div className="p-4 mt-5 grid tabletBig:grid-cols-1 grid-cols-2 gap-2">
        <div>
          <EierOgKjoretoysinformasjon
            inntauingsId={inntauing.id}
            kjoretoy={inntauing.kjoretoy}
          />
        </div>
        <div>
          <PocketControl inntauing={inntauing} />
        </div>
      </div>
    </>
  );
};
