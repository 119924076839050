import { useInntauing } from "../../hooks/useInntauinger";
import { AlertBox } from "../common/form/AlertBox";
import { useParams } from "react-router-dom";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { RegistrerUtlevering } from "./RegistrerUtlevering";
import { HeadingView } from "../common/typography/HeadingView";
import { HeadingTopic } from "../common/typography/HeadingTopic";
import Handlingslogg from "../handlingslogg/Handlingslogg";
import { EierOgKjoretoysinformasjon } from "../eierOgKjoretoysinformasjon/EierOgKjoretoysinformasjon";
import { PocketControl } from "../pocketControl/PocketControl";
import { Inntauingsinformasjon } from "../inntauingsinformasjon/Inntauingsinformasjon";
import { Ileggelsesinformasjon } from "../ileggelsesinformasjon/Ileggelsesinformasjon";

export const Utlevering = () => {
  const { id }: any = useParams();

  const {
    data: inntauing,
    isLoading: isLoadingInntauing,
    isError: isErrorInntauing,
    error: errorInntauing,
    refetch: refetchInntauing
  } = useInntauing(id);

  if (isLoadingInntauing) return <LoadingSpinner />;

  if (isErrorInntauing)
    return (
      <div className="ml-5 mt-10">
        <AlertBox
          alertLevel="error"
          heading="Feil ved lasting av betalingsside"
          paragraph={
            <div className="flex flex-col">
              {isErrorInntauing && (errorInntauing as Error).message}
            </div>
          }
        />
      </div>
    );

  return (
    <div className="ml-10 mb-16">
      {inntauing && (
        <>
          <HeadingView>{`Registrer utlevering av ${
            inntauing.kjoretoy.registreringsnummer ??
            inntauing.kjoretoyleveranse?.journalnummer ??
            ""
          }`}</HeadingView>
          <div className="flex flex-row flex-wrap justify-between mt-[-1.5rem]">
            <div className="mb-10">
              <RegistrerUtlevering
                inntauing={inntauing}
                refetchInntauing={refetchInntauing}
              />
            </div>
            <div className="flex flex-col bg-gray-100 p-5 w-fit">
              <HeadingTopic className="mb-5">
                Informasjon om kjøretøyet
              </HeadingTopic>
              <div className="grid gap-10 grid-rows-4 grid-cols-2 w-fit">
                <div className="col-span-2">
                  <Handlingslogg inntauingsId={inntauing.id} />
                </div>
                <div className="col-span-2">
                  <EierOgKjoretoysinformasjon
                    inntauingsId={inntauing.id}
                    kjoretoy={inntauing.kjoretoy}
                  />
                </div>
                <div className="col-span-2">
                  <PocketControl inntauing={inntauing} />
                </div>
                <div className="row-span-1">
                  <Inntauingsinformasjon
                    inntauing={inntauing}
                    isEditable={false}
                  />
                </div>
                <div className="row-span-1">
                  <Ileggelsesinformasjon inntauing={inntauing} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
