export const queryDate = (key: string, date?: Date | null) =>
  date ? `&${key}=${date.toISOString()}` : "";

export const queryString = (key: string, val?: string) =>
  val ? `&${key}=${val}` : "";

export const queryBool = (key: string, val?: boolean) =>
  val ? `&${key}=${val}` : "";

export const queryNumberArray = (key: string, list: number[] | undefined) =>
  list?.map(i => `&${key}=${i}`).join("") ?? "";

export const queryStringArray = (key: string, list: string[] | undefined) =>
  list?.map(i => `&${key}=${i}`).join("") ?? "";
