import { endOfDay, startOfDay, sub } from "date-fns";
import { useCallback } from "react";
import { useArkiverteAuksjoner } from "../../hooks/useAuksjon";
import { AlertBox } from "../common/form/AlertBox";
import { CustomError } from "../common/typography/CustomError";
import { HeadingView } from "../common/typography/HeadingView";
import { ArkiverteAuksjonerTabell } from "./ArkiverteAuksjonerTabell";
import { EkstrainfoKort } from "../common/card/EkstrainfoKort";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { FraTilDato } from "../common/form/FraTilDato";
import { formaterNullableBelop } from "../../utils/formatUtils";
import { useStateParams } from "../../hooks/useStateParams";

export const ArkiverteAuksjoner = () => {
  const syvDagerSiden = startOfDay(sub(new Date(), { days: 7 }));
  const iDag = endOfDay(new Date());

  const [fraDato, setFraDato] = useStateParams(
    syvDagerSiden,
    "fra",
    s => s.toISOString(),
    s => new Date(s)
  );
  const [tilDato, setTilDato] = useStateParams(
    iDag,
    "til",
    s => s.toISOString(),
    s => new Date(s)
  );

  const {
    data,
    isLoading,
    error,
    isError,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useArkiverteAuksjoner(fraDato, tilDato);

  const handleNextPage = useCallback(() => fetchNextPage(), [fetchNextPage]);

  const auksjoner = data?.pages.flatMap(x => x.data);
  const antall = auksjoner?.length ?? 0;
  const salgsinntekt =
    auksjoner
      ?.map(i => i.auksjon?.salgsinntekt ?? 0)
      .reduce((prev, curr) => prev + curr, 0) ?? 0;

  return (
    <div className="mb-16">
      <HeadingView>Gjennomførte auksjoner</HeadingView>
      <FraTilDato
        fraDato={fraDato}
        tilDato={tilDato}
        handleChangeFraDato={setFraDato}
        handleChangeTilDato={setTilDato}
      />
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      {antall === 0 ? (
        <div className="mb-5">
          <AlertBox
            alertLevel="warning"
            heading="Finner ingen auksjoner for denne perioden"
          />
        </div>
      ) : (
        <>
          <div className="flex flex-row flex-wrap mb-5">
            <EkstrainfoKort
              tittel="Inntekt salg"
              verdi={formaterNullableBelop(salgsinntekt)}
            />
          </div>
          <ArkiverteAuksjonerTabell auksjonsliste={auksjoner} />
        </>
      )}
      {hasNextPage && (
        <div className="flex w-full justify-center mt-3">
          <button
            className="btn btn--flat btn--small"
            disabled={!hasNextPage || isFetchingNextPage}
            onClick={handleNextPage}
          >
            {isFetchingNextPage ? "Henter flere" : "Last inn flere"}
          </button>
        </div>
      )}
    </div>
  );
};
