import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AvhendingsbrevForesporselResponseViewModel } from "../../api/interfaces/Brev";
import {
  useBrevPDF,
  usePostFysiskAvhendingsbrev,
  usePostGenererAvhendingsbrev
} from "../../hooks/useBrev";
import PDFMerger from "pdf-merger-js/browser";
import { useReactToPrint } from "react-to-print";
import { HeadingView } from "../common/typography/HeadingView";
import { ReactComponent as Print } from "../../assets/icons/print.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { ReactComponent as Uferdig } from "../../assets/icons/uferdig.svg";
import { ReactComponent as Ferdig } from "../../assets/icons/ferdig.svg";
import { ReactComponent as Feil } from "../../assets/icons/feil.svg";
import { useToast } from "../common/toast/ToastProvider";
import { useInntauing } from "../../hooks/useInntauinger";
import { finnEier } from "../../utils/objectMapping";
import { AlertBox } from "../common/form/AlertBox";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { HeadingUtskrift } from "../common/typography/HeadingUtskrift";
import { ROUTES } from "../../utils/globalConstants";

export const PostlisteForRekommanderteBrev = () => {
  const [search] = useSearchParams();
  const inntauingsIder =
    search
      .get("inntauingsIder")
      ?.split(",")
      .filter(id => id) ?? [];
  const navigate = useNavigate();

  const [erArkivert, setErArkivert] = useState<boolean>(false);
  const [forespurte, setForespurte] = useState<
    AvhendingsbrevForesporselResponseViewModel[]
  >([]);
  const [pdfer, setPdfer] = useState<{ [key: string]: string }>({});
  const [antallGenererte, setAntallGenererte] = useState<number>(0);

  const postGenererAvhendingsbrev = usePostGenererAvhendingsbrev();
  const postFysiskAvhendingsbrev = usePostFysiskAvhendingsbrev();

  const componentPrintRef = useRef(null);
  const handleSkrivUtListe = useReactToPrint({
    content: () => componentPrintRef.current,
    pageStyle: "m-5"
  });

  const { addToast } = useToast();

  useEffect(() => {
    postGenererAvhendingsbrev.mutate(
      {
        brevliste: inntauingsIder.map(id => {
          return {
            inntauingsId: id
          };
        })
      },
      {
        onSuccess: response => {
          setForespurte(response);
          setPdfer(
            response.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.inntauingsId + "REKBREV"]: "",
                [curr.inntauingsId + "VARSELBREV"]: ""
              };
            }, {})
          );
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSkrivUtBrev = async () => {
    const merger = new PDFMerger();

    const pdferAsList = Object.values(pdfer);

    for (const pdf of pdferAsList) {
      await merger.add(pdf);
    }

    await merger.save(`Postliste_bulk_brev_${new Date().toLocaleDateString()}`);
  };

  const handleArkiverAvhendingsbrev = () => {
    postFysiskAvhendingsbrev.mutate(
      {
        brevliste: forespurte.map(f => {
          return {
            inntauingsId: f.inntauingsId
          };
        })
      },
      {
        onSuccess: response => {
          if (response.length === antallGenererte) {
            addToast("Alle rekommanderte brev ble arkivert");
            setErArkivert(true);
          } else if (response.length > 0) {
            addToast(
              `${response.length} av ${antallGenererte} rekommanderte brev ble arkivert`
            );
            setErArkivert(true);
          } else if (antallGenererte > 0 && response.length === 0) {
            addToast("Ingen av de rekommanderte brevene ble arkivert", "error");
          }
        },
        onError: error => {
          addToast((error as Error).message, "error");
        }
      }
    );
  };

  if (inntauingsIder.length === 0) {
    return (
      <div className="flex flex-col mx-5 mb-10">
        <HeadingView>Postliste for rekommanderte brev</HeadingView>
        <AlertBox
          alertLevel="warning"
          heading="Ingen inntauinger valgt"
          paragraph={
            <p>
              Du må velge minst én inntauing ved å krysse av i Aktive
              inntauinger for å kunne opprette postliste. Du må også sørge for
              at minst én ID kommer med i adressen til nettsiden.
            </p>
          }
        />
        <div className="flex flex-row flex-wrap mt-10 gap-5">
          <button
            className="btn btn--flat btn--icon-left"
            onClick={() => navigate(ROUTES.Aktive_inntauinger)}
          >
            <ArrowDown className="fill-current inline-flex rotate-[90deg]" />
            <span>Tilbake</span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-5 mb-10">
      <HeadingView>Postliste for rekommanderte brev</HeadingView>
      {postGenererAvhendingsbrev.isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <table className="tbl">
            <thead>
              <tr>
                <th>Status</th>
                <th>Eiernavn</th>
                <th>Gateadresse</th>
                <th title="Postnummer">Postnr</th>
                <th>Poststed</th>
                <th title="Rekvirert nummer">Rekvirert nr.</th>
              </tr>
            </thead>
            <tbody>
              {forespurte.map(f => (
                <PostlisteRad
                  key={f.inntauingsId}
                  forespurt={f}
                  leggTilPdf={(key, pdf) => {
                    setPdfer(prev => {
                      prev[key] = pdf;
                      return prev;
                    });
                    if (key.includes("REKBREV")) {
                      setAntallGenererte(prev => prev + 1);
                    }
                  }}
                />
              ))}
            </tbody>
          </table>
          {erArkivert && (
            <div className="mt-5">
              <AlertBox
                alertLevel="success"
                heading="Alle rekommanderte brev er arkivert"
                paragraph={
                  <p>
                    Husk å hent ut alle PDF-er du trenger før du lukker fanen.
                  </p>
                }
              />
            </div>
          )}
          <div className="flex flex-row flex-wrap mt-10 gap-5">
            <button
              className="btn btn--flat btn--icon-left"
              onClick={() => navigate(ROUTES.Aktive_inntauinger)}
            >
              <ArrowDown className="fill-current inline-flex rotate-[90deg]" />
              <span>Tilbake</span>
            </button>
            <button
              className="btn btn--primary-confirm btn--icon-left"
              onClick={handleSkrivUtListe}
              disabled={
                antallGenererte !== forespurte.length ||
                postGenererAvhendingsbrev.isLoading
              }
            >
              <Print className="fill-current" />
              <span>Skriv ut postliste</span>
            </button>
            <button
              className="btn btn--primary-confirm btn--icon-left"
              onClick={handleSkrivUtBrev}
              disabled={
                antallGenererte !== forespurte.length ||
                postGenererAvhendingsbrev.isLoading
              }
            >
              <Download className="fill-current" />
              <span>Last ned alle brev</span>
            </button>
            <button
              className="btn btn--primary btn--icon-right"
              onClick={handleArkiverAvhendingsbrev}
              disabled={
                antallGenererte !== forespurte.length ||
                erArkivert ||
                postGenererAvhendingsbrev.isLoading
              }
            >
              <ArrowDown className="fill-current inline-flex rotate-[-90deg]" />
              <span>Arkiver rekommanderte brev</span>
            </button>
          </div>
        </>
      )}
      <div className="hidden">
        <div ref={componentPrintRef}>
          <HeadingUtskrift>Postliste for rekommanderte brev</HeadingUtskrift>
          <table className="tbl">
            <thead>
              <tr>
                <th>Eiernavn</th>
                <th>Gateadresse</th>
                <th title="Postnummer">Postnr</th>
                <th>Poststed</th>
                <th title="Rekvirert nummer">Rekvirert nr.</th>
              </tr>
            </thead>
            <tbody>
              {forespurte.map(f => (
                <PostlisteUtskriftRad
                  key={"Utskrift_postliste_" + f.inntauingsId}
                  forespurt={f}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

interface PostlisteUtskriftRadProps {
  forespurt: AvhendingsbrevForesporselResponseViewModel;
}

const PostlisteUtskriftRad = (props: PostlisteUtskriftRadProps) => {
  const { forespurt } = props;

  const { data: inntauing } = useInntauing(forespurt.inntauingsId);

  const eier = finnEier(
    inntauing?.kjoretoy.juridiskEier,
    inntauing?.kjoretoy.medeier,
    inntauing?.kjoretoy.leasingtaker
  );

  return (
    <tr>
      <td>{eier?.navn ?? ""}</td>
      <td>{eier?.gateadresse ?? ""}</td>
      <td>{eier?.postnummer ?? ""}</td>
      <td>{eier?.poststed ?? ""}</td>
      <td />
    </tr>
  );
};

interface PostlisteRadProps {
  forespurt: AvhendingsbrevForesporselResponseViewModel;
  leggTilPdf: (key: string, pdf: string) => void;
}

const PostlisteRad = (props: PostlisteRadProps) => {
  const { forespurt, leggTilPdf } = props;

  const [harLagtTilPdf, setHarLagtTilPdf] = useState<boolean>(false);

  const { data: avhendingsbrev, isError: isErrorAvhendingsbrev } = useBrevPDF(
    forespurt.avhendingsbrevId,
    "avhendingsbrev"
  );
  const { data: varselbrev, isError: isErrorVarselbrev } = useBrevPDF(
    forespurt.varselbrevId ?? "",
    "varselbrev"
  );
  const { data: inntauing } = useInntauing(forespurt.inntauingsId);

  useEffect(() => {
    if (avhendingsbrev && varselbrev && !harLagtTilPdf) {
      leggTilPdf(forespurt.inntauingsId + "REKBREV", avhendingsbrev);
      leggTilPdf(forespurt.inntauingsId + "VARSELBREV", varselbrev);
      setHarLagtTilPdf(true);
    }
  }, [
    avhendingsbrev,
    varselbrev,
    harLagtTilPdf,
    leggTilPdf,
    forespurt.inntauingsId
  ]);

  const eier = finnEier(
    inntauing?.kjoretoy.juridiskEier,
    inntauing?.kjoretoy.medeier,
    inntauing?.kjoretoy.leasingtaker
  );

  return (
    <tr>
      <td>
        {visStatusIkon(
          tellDefinerte(avhendingsbrev, varselbrev) === 2,
          isErrorAvhendingsbrev || isErrorVarselbrev
        )}
      </td>
      <td>{eier?.navn ?? ""}</td>
      <td>{eier?.gateadresse ?? ""}</td>
      <td>{eier?.postnummer ?? ""}</td>
      <td>{eier?.poststed ?? ""}</td>
      <td />
    </tr>
  );
};

const visStatusIkon = (erFerdig: boolean, erFeil: boolean) => {
  if (erFeil) {
    return <Feil />;
  }

  if (erFerdig) {
    return <Ferdig />;
  }

  return <Uferdig className="animate-spin" />;
};

const tellDefinerte = (brev1?: string, brev2?: string) => {
  let antall = 0;
  antall += brev1 ? 1 : 0;
  antall += brev2 ? 1 : 0;
  return antall;
};
