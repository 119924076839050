import React from "react";
import { AvstemmingForEkspeditorViewModel } from "../../../api/interfaces/Avstemming";
import { formaterNullableBelop } from "../../../utils/formatUtils";
import { HeadingTopic } from "../../common/typography/HeadingTopic";
import { Handlingsknapp } from "./Handlingsknapp";

interface BetalingerPerEkspeditorProps {
  ekspeditorBetalinger?: AvstemmingForEkspeditorViewModel[];
}

export const BetalingerPerEkspeditor = (
  props: BetalingerPerEkspeditorProps
) => {
  const { ekspeditorBetalinger } = props;

  if (!ekspeditorBetalinger) return null;

  return (
    <>
      <HeadingTopic>Betalinger per ekspeditør</HeadingTopic>
      <table className="tbl max-w-6xl mb-10 mt-5">
        <thead>
          <tr>
            <th title="Betalingsnummer">Betalingsnr</th>
            <th>ID</th>
            <th>Dato</th>
            <th>Bym-ID</th>
            <th>Opprinnelig beløp</th>
            <th>Betalt</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {ekspeditorBetalinger.map(ekspeditorBetaling => (
            <React.Fragment key={ekspeditorBetaling.bymId}>
              {ekspeditorBetaling.betalinger.map(betaling => (
                <tr
                  key={betaling.betalingsnummer}
                  data-testid={`kasseavstemming-ekspeditorbetaling-${betaling.kjennemerke}`}
                >
                  <td>{betaling.betalingsnummer}</td>
                  <td>{betaling.kjennemerke}</td>
                  <td>
                    {new Date(betaling.betalingstidspunkt).toLocaleString()}
                  </td>
                  <td>{betaling.utfortAv}</td>
                  <td>
                    {formaterNullableBelop(
                      betaling.dognleierabatt + betaling.totalsum
                    )}
                  </td>
                  <td>{formaterNullableBelop(betaling.totalsum)}</td>
                  <td>
                    <Handlingsknapp
                      betalingsnummer={betaling.betalingsnummer}
                      registreringsnummer={betaling.kjennemerke}
                      erIleggelseBetalt={
                        betaling.betalteGebyrer.ileggelsesgebyr > 0
                      }
                    />
                  </td>
                </tr>
              ))}
              <tr className="tbl-row-border">
                <td colSpan={4} />
                <td>{`Sum ${ekspeditorBetaling.bymId} (${ekspeditorBetaling.betalinger.length} stk):`}</td>
                <td>{formaterNullableBelop(ekspeditorBetaling.totalsum)}</td>
                <td />
              </tr>
              <tr className="h-4" />
            </React.Fragment>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4} />
            <td>{`Totalsum (${
              ekspeditorBetalinger.flatMap(i => i.betalinger).length
            } stk):`}</td>
            <td>
              {formaterNullableBelop(
                ekspeditorBetalinger.reduce(
                  (prev, curr) => prev + curr.totalsum,
                  0
                )
              )}
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};
