import { Role } from "./utils/userRolesUtils";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from "react-router-dom";
import { RedirectRoute } from "./routes/RedirectRoute";
import { PublicRoutes } from "./routes/PublicRoutes";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Inntauinger } from "./components/aktiveInntauinger/Inntauinger";
import { RegistrerInntauing } from "./components/registrerInntauing/RegistrerInntauing";
import { AvsluttetInntauinger } from "./components/avsluttetInntauinger/AvsluttetInntauinger";
import { Siste24t } from "./components/siste24t/Siste24t";
import { useEffect } from "react";
import { Brevsending } from "./components/brevsending/Brevsending";
import { LoginScreen } from "./auth/LoginScreen";
import { FlyttetPaGategrunn } from "./components/flyttetPaGategrunn/FlyttetPaGategrunn";
import { Utlevering } from "./components/utlevering/Utlevering";
import { Vraking } from "./components/vraking/Vraking";
import { Taksering } from "./components/taksering/Taksering";

import { ROUTES } from "./utils/globalConstants";
import { Auksjon } from "./components/auksjon/Auksjon";
import { Oppgjor } from "./components/oppgjør/Oppgjor";
import ToastProvider from "./components/common/toast/ToastProvider";
import { Regnskap } from "./components/oppgjør/Regnskap";
import { Attestskjema } from "./components/auksjon/Attestskjema";
import { RegistrerAuksjonert } from "./components/auksjon/RegistrerAuksjonert";
import { ManuelleBrev } from "./components/manuelleBrev/ManuelleBrev";
import { Innstillinger } from "./components/innstillinger/Innstillinger";
import { NyPrisliste } from "./components/innstillinger/prislister/NyPrisliste";
import { PrivateRoutes } from "./routes/PrivateRoutes";
import { Layout } from "./components/layout/Layout";
import { Kvittering } from "./components/utlevering/Kvittering";
import { ScrollToTop } from "./components/scrollToTop/ScrollToTop";
import { InitialRoute } from "./routes/InitialRoute";
import { Kunngjoringsliste } from "./components/kunngjoringsliste/Kunngjoringsliste";
import { PostlisteForRekommanderteBrev } from "./components/postlisteForRekommanderteBrev/PostlisteForRekommanderteBrev";
import { HallOfFame } from "./components/hallOfFame/HallOfFame";
import { AlleInntauinger } from "./components/alleInntauinger/AlleInntauinger";
import { LastNedInntauinger } from "./components/lastnedinntauinger/LastNedInntauinger";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } }
});

export const logoutChannel = new BroadcastChannel("logout");

function App() {
  useEffect(() => {
    const preventValidationBubble = (event: Event) => event.preventDefault();
    document.addEventListener("invalid", preventValidationBubble, true);
    return () =>
      document.removeEventListener("invalid", preventValidationBubble, true);
  }, []);

  logoutChannel.onmessage = () => {
    window.location.reload();
  };

  return (
    <ToastProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <ScrollToTop />
          <Layout>
            <Routes>
              <Route
                element={
                  <PrivateRoutes
                    hasAccess={[
                      Role.Saksbehandler,
                      Role.Ekspeditor,
                      Role.Innsyn,
                      Role.Utvikler
                    ]}
                  />
                }
              >
                <Route
                  path={ROUTES.Alle_inntauinger}
                  element={<AlleInntauinger />}
                />
                <Route
                  path={ROUTES.Aktive_inntauinger}
                  element={<Inntauinger />}
                />
                <Route
                  path={ROUTES.Inntauinger_siste_24t}
                  element={<Siste24t />}
                />
                <Route
                  path={ROUTES.Flyttet_pa_gategrunn}
                  element={<FlyttetPaGategrunn />}
                />
                <Route
                  path={ROUTES.Avsluttede_inntauinger}
                  element={<AvsluttetInntauinger />}
                />
                <Route
                  path={ROUTES.Oppgjor + "/:tabId"}
                  element={<Oppgjor />}
                />
              </Route>

              <Route
                element={
                  <PrivateRoutes
                    hasAccess={[
                      Role.Saksbehandler,
                      Role.Regnskap,
                      Role.Utvikler
                    ]}
                  />
                }
              >
                <Route
                  path={ROUTES.Regnskap + "/:tabId"}
                  element={<Regnskap />}
                />
              </Route>

              <Route
                element={
                  <PrivateRoutes
                    hasAccess={[Role.Saksbehandler, Role.Utvikler]}
                  />
                }
              >
                <Route path={ROUTES.Brevutsendelse} element={<Brevsending />} />
                <Route path={ROUTES.Ny_prisliste} element={<NyPrisliste />} />
                <Route
                  path={ROUTES.Vraking + "/:tabId"}
                  element={<Vraking />}
                />
                <Route
                  path={ROUTES.Auksjon + "/:tabId"}
                  element={<Auksjon />}
                />
                <Route
                  path={ROUTES.Taksering + "/:tabId"}
                  element={<Taksering />}
                />
                <Route
                  path={ROUTES.Generer_attest + "/:id"}
                  element={<Attestskjema />}
                />
                <Route
                  path={ROUTES.Registrer_auksjonert + "/:id"}
                  element={<RegistrerAuksjonert />}
                />
              </Route>

              <Route
                element={
                  <PrivateRoutes
                    hasAccess={[
                      Role.Saksbehandler,
                      Role.Ekspeditor,
                      Role.Utvikler
                    ]}
                  />
                }
              >
                <Route
                  path={ROUTES.Manuell_registrering}
                  element={<RegistrerInntauing />}
                />
                <Route
                  path={ROUTES.Innstillinger + "/:tabId"}
                  element={<Innstillinger />}
                />
                <Route
                  path={ROUTES.Utlevering_av_inntauing + "/:id"}
                  element={<Utlevering />}
                />
                <Route
                  path={ROUTES.Kunngjoringsliste}
                  element={<Kunngjoringsliste />}
                />
                <Route
                  path={ROUTES.Postliste_for_rekommanderte_brev}
                  element={<PostlisteForRekommanderteBrev />}
                />
                <Route
                  path={
                    ROUTES.Utlevering_av_inntauing +
                    "/:id/kvittering/:betalingsnummer"
                  }
                  element={<Kvittering />}
                />
                <Route
                  path={ROUTES.ManuellBrevutsendelse + "/:id"}
                  element={<ManuelleBrev />}
                />
              </Route>

              <Route
                element={
                  <PrivateRoutes
                    hasAccess={[Role.Saksbehandler, Role.Utvikler, Role.Innsyn]}
                  />
                }
              >
                <Route
                  path={ROUTES.LastNedInntauinger}
                  element={<LastNedInntauinger />}
                />
              </Route>

              <Route
                element={
                  <PrivateRoutes
                    hasAccess={[
                      Role.Saksbehandler,
                      Role.Ekspeditor,
                      Role.Innsyn,
                      Role.Regnskap,
                      Role.Utvikler
                    ]}
                  />
                }
              >
                <Route path="/" element={<InitialRoute />} />
                <Route path={ROUTES.Redirect} element={<RedirectRoute />} />
              </Route>

              <Route element={<PublicRoutes />}>
                <Route path="login" element={<LoginScreen />} />
                <Route path="logout" element={<Navigate to="login" />} />
                <Route path="*" element={<Navigate to="login" />} />
              </Route>
              <Route path="hall-of-fame" element={<HallOfFame />} />
            </Routes>
          </Layout>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ToastProvider>
  );
}

export default App;
