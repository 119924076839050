import { AxiosResponse } from "axios";
import { apiClient } from "../client";
import {
  AvhendingsbrevForesporselResponseViewModel,
  BrevforesporselInputModel,
  VarselbrevForesporselResponseViewModel
} from "../interfaces/Brev";
import { config, configPDF } from "./Config";

const client = apiClient();

export const postGenererVarselbrev = (brevliste: BrevforesporselInputModel[]) =>
  client.post<
    string[],
    AxiosResponse<VarselbrevForesporselResponseViewModel[]>
  >(`brev/varselbrev/generer`, brevliste, config());

export const postDigitaltVarselbrev = (
  brevliste: BrevforesporselInputModel[]
) => client.post<string[]>(`brev/varselbrev/digitalt`, brevliste, config());

export const postFysiskVarselbrev = (brevliste: BrevforesporselInputModel[]) =>
  client.post<string[]>(`brev/varselbrev/fysisk`, brevliste, config());

export const postGenererAvhendingsbrev = (
  brevliste: BrevforesporselInputModel[]
) =>
  client.post<
    string[],
    AxiosResponse<AvhendingsbrevForesporselResponseViewModel[]>
  >(`brev/avhendingsbrev/generer`, brevliste, config());

export const postFysiskAvhendingsbrev = (
  brevliste: BrevforesporselInputModel[]
) => client.post<string[]>(`brev/avhendingsbrev/fysisk`, brevliste, config());

export const getAvhendingsbrevPDF = (brevId: string) =>
  client.get(`brev/avhendingsbrev/${brevId}/pdf`, configPDF());

export const getVarselbrevPDF = (brevId: string) =>
  client.get(`brev/varselbrev/${brevId}/pdf`, configPDF());
