import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import DatePicker from "../../common/form/DatePicker";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import { FelterPrisliste } from "./NyPrisliste";
import { HeadingTopic } from "../../common/typography/HeadingTopic";
import React from "react";
import { AlertBox } from "../../common/form/AlertBox";
import { handleNumberOrEmptyValueInput } from "../../../utils/keyboardUtils";
import {
  antallFeilInntauingskostnader,
  antallFeilISkjema
} from "./prislisteUtils";
import {
  Betalingsgruppe,
  BetalingsgruppeMapper
} from "../../../api/interfaces/Inntauing";

export const NyPrislisteLeggTil = () => {
  const {
    formState: { errors },
    control,
    register
  } = useFormContext<FelterPrisliste>();

  const { fields } = useFieldArray<FelterPrisliste>({
    name: "inntauingskostnader",
    control
  });

  return (
    <>
      <div className="ods-form-group w-64">
        <label className="ods-form-label">Gyldig fra</label>
        <div className="ods-form-help">
          Fra og med dato prisliste skal være gyldig
        </div>
        <Controller
          name="gyldigFra"
          control={control}
          render={({ field, fieldState }) => (
            <div className="react-datepicker-wrapper">
              <div className="flex relative items-center justify-end focus-within:fill-hoverblue">
                <DatePicker
                  id={field.name}
                  name={field.name}
                  dateFormat="dd.MM.yyyy, HH:mm:ss"
                  showTimeInput
                  minDate={new Date()}
                  selected={field.value}
                  onChange={field.onChange}
                  autoComplete="off"
                  customInput={
                    <input
                      data-testid="datepicker-gyldig-fra"
                      className={`ods-form-input ${
                        fieldState.error &&
                        "border-errorred focus:border-errorred"
                      }`}
                    />
                  }
                />
                <CalendarIcon className="pointer-events-none absolute mr-2 h-5 w-5" />
              </div>
            </div>
          )}
        />
      </div>
      <HeadingTopic>Inntauing</HeadingTopic>
      <p className="text-sm mt-3 mb-5">
        Fyll inn priser hvor det skjer en endring.
      </p>
      <div className="grid grid-cols-3 gap-y-2">
        <div />
        <div>Pris</div>
        <div>Varenummer</div>
        <p className="my-5">Administrasjonsgebyr</p>
        <input
          id="administrasjonsgebyrPris"
          className={`ods-form-input w-52 ${
            errors.administrasjonsgebyr?.pris
              ? "border-errorred focus:border-errorred"
              : ""
          }`}
          {...register("administrasjonsgebyr.pris")}
          autoComplete="off"
        />
        <input
          id="administrasjonsgebyrVarenummer"
          className={`ods-form-input w-52 ${
            errors.administrasjonsgebyr?.varenummer
              ? "border-errorred focus:border-errorred"
              : ""
          }`}
          {...register("administrasjonsgebyr.varenummer")}
          autoComplete="off"
        />
        <p className="my-5">Administrasjonsgebyr flytting</p>
        <input
          id="administrasjonsgebyrFlyttingPris"
          className={`ods-form-input w-52 ${
            errors.administrasjonsgebyrFlytting?.pris
              ? "border-errorred focus:border-errorred"
              : ""
          }`}
          {...register("administrasjonsgebyrFlytting.pris")}
          autoComplete="off"
        />
        <p className="my-5">Ikke relevant</p>
        <p className="my-5">Døgnleie</p>
        <input
          id="dognleiePris"
          className={`ods-form-input w-52 ${
            errors.dognleie?.pris ? "border-errorred focus:border-errorred" : ""
          }`}
          {...register("dognleie.pris")}
          autoComplete="off"
        />
        <input
          id="dognleieVarenummer"
          className={`ods-form-input w-52 ${
            errors.dognleie?.varenummer
              ? "border-errorred focus:border-errorred"
              : ""
          }`}
          {...register("dognleie.varenummer")}
          autoComplete="off"
        />
        <p className="col-span-3 my-5">Inntauingskostnad</p>
        {fields.map((f, index) => (
          <React.Fragment key={f.id}>
            <label htmlFor={f.id} className="flex items-center ml-4">
              {BetalingsgruppeMapper[f.gebyrtype].navn + ":"}
            </label>
            <input
              id={f.id + "pris"}
              className={`ods-form-input w-52 ${
                errors.inntauingskostnader?.[index]?.pris
                  ? "border-errorred focus:border-errorred"
                  : ""
              }`}
              onKeyDown={handleNumberOrEmptyValueInput}
              {...register(`inntauingskostnader.${index}.pris` as const)}
              autoComplete="off"
            />
            {f.gebyrtype === Betalingsgruppe.FlyttingGategrunn ? (
              <p className="my-5">Ikke relevant</p>
            ) : (
              <input
                id={f.id + "varenummer"}
                className={`ods-form-input w-52 ${
                  errors.inntauingskostnader?.[index]?.varenummer
                    ? "border-errorred focus:border-errorred"
                    : ""
                }`}
                onKeyDown={handleNumberOrEmptyValueInput}
                {...register(
                  `inntauingskostnader.${index}.varenummer` as const
                )}
                autoComplete="off"
              />
            )}
          </React.Fragment>
        ))}
        {antallFeilISkjema(errors) > 0 && (
          <div className="col-span-2 mt-5">
            <AlertBox
              alertLevel="error"
              heading={<h3>{antallFeilISkjema(errors) + " feil i felter"}</h3>}
              paragraph={
                <>
                  {errors.gyldigFra && <p>{errors.gyldigFra.message}</p>}
                  {errors.administrasjonsgebyr?.pris && (
                    <p>{errors.administrasjonsgebyr.pris.message}</p>
                  )}
                  {errors.administrasjonsgebyr?.varenummer && (
                    <p>{errors.administrasjonsgebyr.varenummer.message}</p>
                  )}
                  {errors.administrasjonsgebyrFlytting?.pris && (
                    <p>{errors.administrasjonsgebyrFlytting.pris.message}</p>
                  )}
                  {errors.administrasjonsgebyrFlytting?.varenummer && (
                    <p>
                      {errors.administrasjonsgebyrFlytting.varenummer.message}
                    </p>
                  )}
                  {errors.dognleie?.pris && (
                    <p>{errors.dognleie.pris.message}</p>
                  )}
                  {errors.dognleie?.varenummer && (
                    <p>{errors.dognleie.varenummer.message}</p>
                  )}
                  {errors.inntauingskostnader && (
                    <p>{`Oppgi priser og varenummer for resterende ${antallFeilInntauingskostnader(
                      errors
                    )} inntauingskostnader`}</p>
                  )}
                </>
              }
            />
          </div>
        )}
      </div>
    </>
  );
};
