import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from "react-query";
import {
  deleteTaksering,
  getInntauingerFerdigTaksert,
  getInntauingerMedTaksering,
  postOpprettTaksering,
  putTaksering
} from "../api/apiServices/TakstService";
import { InntauingsIdViewModel } from "../api/interfaces/Inntauing";
import {
  CACHE_KEYS,
  REFRESH_INTERVAL,
  DEFAULT_PAGE_SIZE
} from "../utils/globalConstants";

const pageSize = DEFAULT_PAGE_SIZE;

export const useOpprettTaksering = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsIder: InntauingsIdViewModel[] }) =>
      postOpprettTaksering(variables.inntauingsIder),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Aktive_inntauinger]);
        return await queryClient.invalidateQueries([CACHE_KEYS.Takseringer]);
      }
    }
  );
};

export const useInntauingerMedTaksering = () =>
  useQuery([CACHE_KEYS.Takseringer], () =>
    getInntauingerMedTaksering().then(r => r.data)
  );

export const useInntauingerFerdigTaksert = (fraDato?: Date, tilDato?: Date) =>
  useInfiniteQuery(
    [CACHE_KEYS.Arkiverte_takseringer, fraDato, tilDato],
    ({ pageParam = 0 }) =>
      getInntauingerFerdigTaksert(pageParam, pageSize, fraDato, tilDato).then(
        r => r.data
      ),
    {
      getNextPageParam: lastPage => lastPage.nextCursor ?? undefined,
      refetchInterval: REFRESH_INTERVAL
    }
  );

export const usePutTaksasjon = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsId: string; registrerTaksasasjon: FormData }) =>
      putTaksering(variables.inntauingsId, variables.registrerTaksasasjon),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Arkiverte_takseringer]);
        return await queryClient.invalidateQueries([CACHE_KEYS.Takseringer]);
      }
    }
  );
};

export const useAngreOpprettTakst = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsIder: string[] }) =>
      deleteTaksering(variables.inntauingsIder),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Aktive_inntauinger]);
        return await queryClient.invalidateQueries([CACHE_KEYS.Takseringer]);
      }
    }
  );
};
