import { useFormContext } from "react-hook-form";
import { useNasjonskoder } from "../../hooks/useGrunndata";
import { RegistrerInntauingFelter } from "./registrerInntauingUtils";

export const RegistrerInntauingRegistreringsnummer = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<RegistrerInntauingFelter>();

  const { data: nasjonskoder } = useNasjonskoder();

  return (
    <div className="flex flex-wrap gap-x-5">
      <div className="ods-form-group w-64">
        <label className="ods-form-label" htmlFor="nasjonkode">
          Nasjon
        </label>
        <select
          id="nasjonkode"
          className={`ods-form-input ods-select ${
            errors.registreringsnummer?.nasjonkode
              ? "border-errorred focus:border-errorred"
              : ""
          }`}
          {...register("registreringsnummer.nasjonkode")}
        >
          <>
            <option value={undefined} />
            {nasjonskoder?.map(nasjon => (
              <option key={nasjon.kode} value={nasjon.kode}>
                {nasjon.navn}
              </option>
            ))}
          </>
        </select>
      </div>

      <div className="ods-form-group w-56">
        <label className="ods-form-label" htmlFor="registreringsnummer">
          Regnr
        </label>
        <input
          className={`ods-form-input ${
            errors.registreringsnummer?.registreringsnummer
              ? "border-errorred focus:border-errorred"
              : ""
          }`}
          id="registreringsnummer"
          {...register("registreringsnummer.registreringsnummer")}
          autoComplete="off"
        />
      </div>

      <div className="ods-form-group w-72">
        <label className="ods-form-label" htmlFor="rammenummer">
          Rammenr
        </label>
        <input
          id="rammenummer"
          className={`ods-form-input ${
            errors.registreringsnummer?.rammenummer
              ? "border-errorred focus:border-errorred"
              : ""
          }`}
          {...register("registreringsnummer.rammenummer")}
          autoComplete="off"
        />
      </div>
    </div>
  );
};
