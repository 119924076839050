import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { inntauingInputSchema } from "./inntauingInputSchema";
import { useRedigerIleggelse } from "../../hooks/useIleggelse";
import { useRedigerKjoretoyleveranse } from "../../hooks/useKjoretoyleveranse";
import {
  getFlyttetTilLokasjon,
} from "./inntauingsinformasjonUtils";
import {
  InntauingViewModel,
  KjoretoyleveranseViewModel
} from "../../api/interfaces/Inntauing";

interface RedigerInntauingsinformasjonProps {
  inntauing: InntauingViewModel;
  setEditMode: (edit: boolean) => void;
  isFlytting?: boolean;
}

export const RedigerInntauingsinformasjon = (
  props: RedigerInntauingsinformasjonProps
) => {
  const { inntauing, setEditMode, isFlytting } = props;

  const putKjoretoyleveranse = useRedigerKjoretoyleveranse();
  const putIleggelse = useRedigerIleggelse();

  const initialValues = {
    radnummer: inntauing.kjoretoyleveranse?.plassering ?? "",
    ordrenummer: inntauing.kjoretoyleveranse?.ordrenummer
      ? inntauing.kjoretoyleveranse.ordrenummer.toString()
      : "",
    journalnummer: inntauing.kjoretoyleveranse?.journalnummer
      ? inntauing.kjoretoyleveranse.journalnummer.toString()
      : "",
    ileggelsesnr: inntauing.ileggelse?.ileggelsesnummer?.toString() ?? ""
  };

  // TODO: Interface for skjema
  const handleClickRedigerButton = async (data: any) => {
    if (inntauing.kjoretoyleveranse) {
      let kjoretoyleveranseInfo: KjoretoyleveranseViewModel = {
        ...inntauing.kjoretoyleveranse,
        ordrenummer: parseInt(data.ordrenummer),
        journalnummer: parseInt(data.journalnummer),
        plassering: data.radnummer
      };

      putIleggelse.mutate(
        {
          inntauingId: inntauing.id,
          ileggelse: data.ileggelsesnr
        },
        {
          onSuccess: () => setEditMode(false)
        }
      );

      putKjoretoyleveranse.mutate(
        {
          inntauingId: inntauing.id,
          kjoretoyleveranse: kjoretoyleveranseInfo
        },
        {
          onSuccess: () => setEditMode(false)
        }
      );
    }
  };

  const { handleSubmit, register } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(inntauingInputSchema),
    defaultValues: initialValues,
    shouldUseNativeValidation: true
  });

  return (
    <form>
      <dl className="ods-dl pb-1 grid grid-cols-2">
        {!isFlytting && (
          <>
            <dt className="osg-sans-bold mb-4">Radnr:</dt>
            <input
              id="radnummer"
              className="ods-form-input mb-1"
              {...register("radnummer")}
            />
          </>
        )}

        <dt className="osg-sans-bold mb-4">Ordrenr:</dt>
        <input
          id="ordrenummer"
          className="ods-form-input mb-1"
          {...register("ordrenummer")}
        />

        {isFlytting && (
          <>
            <dt className="osg-sans-bold mb-4">Flyttet til:</dt>
            <dd>{getFlyttetTilLokasjon(inntauing.kjoretoyleveranse).join("\n")}</dd>
          </>
        )}

        {!isFlytting && (
          <>
            <dt className="osg-sans-bold mb-4">Journalnr:</dt>
            <input
              id="journalnummer"
              className="ods-form-input"
              {...register("journalnummer")}
            />
          </>
        )}

        <dt className="osg-sans-bold mb-4">Ileggelsesnr:</dt>
        <input
          id="ileggelsesnr"
          className="ods-form-input mb-1"
          {...register("ileggelsesnr")}
        />

        <dt className="osg-sans-bold mb-4">Brevutsendelse:</dt>
        <dd>Kommer...</dd>
      </dl>

      <div className="row-span-1 mt-5 py-2">
        <button
          className="btn btn--flat btn--icon-left mr-5"
          onClick={() => setEditMode(false)}
        >
          <Close className="fill-current w-5" />
          <span>Avbryt</span>
        </button>

        <button
          disabled={!inntauing.kjoretoyleveranse ? true : false}
          data-testid="rediger-inntauingsinformasjon-bekreft-button"
          className="btn btn--primary btn--icon-right"
          onClick={handleSubmit(handleClickRedigerButton)}
        >
          <ArrowDown className="fill-current w-5 inline-flex rotate-[-90deg]" />
          <span>Bekreft</span>
        </button>
      </div>
    </form>
  );
};
