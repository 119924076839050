import { addHours } from "date-fns";
import {
  Betalingstype,
  BetalingInputModel,
  BetalingViewModel,
  BetalteGebyrerInputModel
} from "../../api/interfaces/Betaling";
import {
  PrisForInntauingViewModel,
  NyHentingInputModel,
  InntauingViewModel,
  HjemmelType
} from "../../api/interfaces/Inntauing";

export interface Felter {
  // TODO: Bruk enum for betalingsmetode og map til navn i dropdown
  betalingsmetode: string;
  lestHandlingslogg: boolean;
  navn: string;
  fodselsdato?: Date;
  eierHenter?: boolean;
  tidspunkt?: Date;
  originalDognleie?: number;
  nyDognleie?: number | string;
  justerDognleie?: boolean;
  betalIleggelse?: boolean;
}

export const getInitialValues = (inntauing: InntauingViewModel): Felter => {
  return {
    tidspunkt: new Date(),
    betalingsmetode: setDefaultBetalingsmetode(inntauing),
    lestHandlingslogg: false,
    navn: "",
    eierHenter: false,
    justerDognleie: false,
    betalIleggelse: true,
    originalDognleie: inntauing.utestaaendeKostnader?.dognleiekostnad?.pris ?? 0
  };
};

const setDefaultBetalingsmetode = (inntauing: InntauingViewModel) => {
  if (inntauing.kanUtleveresUtenBetaling) return "Ingen betaling";
  if (
    inntauing.inntauingsgrunnlag?.hjemmel !== HjemmelType.Parkeringsforskriften
  )
    return "Kasse";
  if (inntauing.kanFaktureres) return "";
  return "Kasse";
};

export const getBetalingsmetoder = (inntauing: InntauingViewModel) => {
  let metoder = [];
  if (inntauing.kanUtleveresUtenBetaling) {
    metoder.push("Ingen betaling");
  }
  if (inntauing.kanFaktureres) {
    metoder.push("Faktura");
  }
  metoder.push("Kasse");
  return metoder;
};

export const beregnAntallDogn = (tidsspenn: number) => {
  return Math.floor(tidsspenn / (1000 * 3600 * 24));
};

export const beregnTotalsum = (
  skalFaktureres: boolean,
  justertDognleie?: boolean,
  utsettIleggelse?: boolean,
  gebyrer?: PrisForInntauingViewModel,
  nyDognleie?: number
) => {
  if (!gebyrer) return 0;

  let totalsum = Number(gebyrer.sum);

  if (skalFaktureres || utsettIleggelse) {
    totalsum -= Number(gebyrer?.ileggelsesgebyr?.pris ?? 0);
    totalsum -= Number(gebyrer?.ileggelsestilleggsgebyr?.pris ?? 0);
  }

  if (justertDognleie) {
    totalsum -= Number(gebyrer.dognleiekostnad?.pris ?? 0);
    totalsum += Number(nyDognleie ?? gebyrer.dognleiekostnad?.pris ?? 0);
  }

  return totalsum;
};

const ikkeBetalIleggelse = (utsett: boolean, betalingsmetode: string) =>
  utsett || mapBetalingsmetode(betalingsmetode) === Betalingstype.Faktura;

export const mapBetalingsmetode = (betalingsmetode: string) => {
  switch (betalingsmetode) {
    case "Kasse":
      return Betalingstype.Kasse;
    case "Faktura":
      return Betalingstype.Faktura;
    case "Ingen betaling":
      return Betalingstype.Frafalt;
    default:
      return Betalingstype.Ukjent;
  }
};

interface Hentet {
  inntauingId: string;
  hentet: NyHentingInputModel;
}

export const mapFelterToHentet = (
  inntauingsId: string,
  felter: Felter
): Hentet => {
  return {
    inntauingId: inntauingsId,
    hentet: {
      henter: {
        navn: felter.navn,
        fodselsdato: felter.fodselsdato
          ? addHours(felter.fodselsdato, 12)
          : new Date(0)
      },
      hentetTidspunkt: felter.tidspunkt
    }
  };
};

export const mapFelterToBetaling = (
  felter: Felter,
  kostnader: BetalteGebyrerInputModel
): { betaling: BetalingInputModel } => {
  return {
    betaling: {
      betalingsmiddel: mapBetalingsmetode(felter.betalingsmetode),
      betalingstidspunkt: felter.tidspunkt,
      betaler: {
        navn: felter.navn,
        fodselsdato: felter.fodselsdato
          ? addHours(felter.fodselsdato, 12)
          : undefined
      },
      betalteGebyrer: {
        administrasjonsgebyr: kostnader.administrasjonsgebyr,
        dognleie: Number(
          isNaN(Number(felter.nyDognleie))
            ? Number(kostnader.dognleie ?? 0)
            : Number(felter.nyDognleie)
        ),
        ileggelsesgebyr:
          ikkeBetalIleggelse(!felter.betalIleggelse, felter.betalingsmetode) ||
          kostnader.ileggelsesgebyr === 0
            ? undefined
            : kostnader.ileggelsesgebyr,
        ileggelsestilleggsgebyr:
          ikkeBetalIleggelse(!felter.betalIleggelse, felter.betalingsmetode) ||
          kostnader.ileggelsestilleggsgebyr === 0
            ? undefined
            : kostnader.ileggelsestilleggsgebyr,
        inntauingsgebyr: kostnader.inntauingsgebyr
      },
      dognleierabatt: Number(
        isNaN(Number(felter.nyDognleie))
          ? 0
          : kostnader.dognleie - Number(felter.nyDognleie)
      )
    }
  };
};

export const skrivUtKnappTekst = (isLoading: boolean, isError: boolean) => {
  if (isError) return "Feil på utskrift";
  if (isLoading) return "Klargjør utskrift";
  return "Skriv ut";
};

export const totalIleggelsessum = (
  ileggelsesgebyr: number,
  ileggelsestilleggsgebyr: number
) => ileggelsesgebyr + ileggelsestilleggsgebyr;

export const getBetalingsmetode = (
  betaling?: BetalingViewModel
): "Kasse" | "Faktura" | "Ingen betaling" => {
  if (!betaling || betaling.totalsum === 0) return "Ingen betaling";
  if (betaling.faktura) return "Faktura";
  return "Kasse";
};

export const nyDognleieSomBelop = (nyDognleie?: string | number) => {
  return parseInt((nyDognleie ?? "0").toString());
};
