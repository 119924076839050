import { HeadingTopic } from "../../common/typography/HeadingTopic";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right-blue-box.svg";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../utils/globalConstants";
import { subDays } from "date-fns";
import { AlertBox } from "../../common/form/AlertBox";
import { RegnskapKasseoppgjorViewModel } from "../../../api/interfaces/Regnskap";

interface KasseavstemmingerProps {
  oppgjor: RegnskapKasseoppgjorViewModel;
}

export const Kasseavstemminger = (props: KasseavstemmingerProps) => {
  const { oppgjor } = props;

  const navigate = useNavigate();

  return (
    <>
      <HeadingTopic>Kasseavstemminger</HeadingTopic>
      {oppgjor.dagsoppgjor.length === 0 ? (
        <div className="mt-2">
          <AlertBox
            alertLevel="warning"
            heading="Ingen kasseavstemminger for angitt periode"
          />
        </div>
      ) : (
        <table id="kasseinntekt" className="tbl mt-2 table-fixed">
          <thead>
            <tr>
              <th>Dato avstemt</th>
              <th title="Z-Rapport-nummer">Z-Rapport-nr</th>
              <th title="Sesjonsnummer">Sesjonsnr</th>
              <th className="text-right">Kontant</th>
              <th className="text-right">BankAxept</th>
              <th className="text-right">Kredittkort</th>
              <th className="text-right">Total</th>
              <th className="text-right">Kassedifferanse</th>
              <th className="sr-only">Knapp til detaljside</th>
            </tr>
          </thead>
          <tbody>
            {oppgjor.dagsoppgjor.map(d => (
              <tr key={d.sesjonsnummer}>
                <td>{new Date(d.dato).toLocaleDateString()}</td>
                <td>{d.zRapportNummer}</td>
                <td>{d.sesjonsnummer}</td>
                <td className="text-right">{d.kontant.toLocaleString()}</td>
                <td className="text-right">{d.bankAxept.toLocaleString()}</td>
                <td className="text-right">{d.kredittkort.toLocaleString()}</td>
                <td className="text-right">{d.total.toLocaleString()}</td>
                <td className="text-right">
                  {d.kassedifferanse.toLocaleString()}
                </td>
                <td>
                  <button
                    title="Vis detaljer for tilknyttet kasseavstemming"
                    className="btn btn--flat btn--icon-only focus:outline-2 ml-5"
                    onClick={() =>
                      navigate(
                        `${ROUTES.Regnskap}/kasseavstemminger?fra=${subDays(
                          new Date(d.dato),
                          1
                        )}&til=${new Date(d.dato)}&sesjonsnummer=${
                          d.sesjonsnummer
                        }&dato=${d.dato.toUTCString()}`
                      )
                    }
                  >
                    <ArrowRight className="fill-current" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};
