import { PrisForInntauingViewModel } from "../../api/interfaces/Inntauing";

export const formaterQrKode = (
  kjennemerke: string,
  henterNavn: string,
  antallDogn: number,
  kostnader: PrisForInntauingViewModel,
  ileggelsesnummer?: number,
  betalIleggelse?: boolean,
  betalInntauingskostnader?: boolean,
  justerDognleiePaaKasse?: boolean
) => {
  const sumIleggelse =
    Number(kostnader.ileggelsesgebyr?.pris ?? 0) +
    Number(kostnader.ileggelsestilleggsgebyr?.pris ?? 0);

  return (
    formaterQrKodeHenteinfo(kjennemerke, henterNavn) +
    formaterQrKodeInntauingskostnader(kostnader, betalInntauingskostnader) +
    formaterQrKodeDognleie(antallDogn, kostnader, justerDognleiePaaKasse) +
    formaterQrKodeIleggelse(sumIleggelse, betalIleggelse, ileggelsesnummer)
  );
};

const formaterQrKodeHenteinfo = (kjennemerke: string, henterNavn: string) =>
  `%%cInfo)${kjennemerke} hentet av ${henterNavn}`;

const formaterQrKodeInntauingskostnader = (
  kostnader: PrisForInntauingViewModel,
  betalInntauingskostnader?: boolean
) =>
  betalInntauingskostnader
    ? `${formaterQrKodeKunVarenummer(
        kostnader.inntauingskostnad?.varenummer
      )}${formaterQrKodeKunVarenummer(
        kostnader.administrasjonskostnad?.varenummer
      )}`
    : "";

const formaterQrKodeKunVarenummer = (varenummer?: number) => {
  return varenummer ? `<<${varenummer}` : "";
};

const formaterQrKodeDognleie = (
  antallDogn: number,
  kostnader: PrisForInntauingViewModel,
  justerDognleiePaaKasse?: boolean
) => {
  const formatertAntallDogn = justerDognleiePaaKasse ? 1 : antallDogn;
  const formatertVarenummer = justerDognleiePaaKasse
    ? 100010
    : kostnader.dognleiekostnad?.varenummer;

  return kostnader.dognleiekostnad
    ? `<<${formatertAntallDogn}|${formatertVarenummer}`
    : "";
};

const formaterQrKodeIleggelse = (
  sumIleggelse: number,
  betalIleggelse?: boolean,
  ileggelsesnummer?: number
) =>
  betalIleggelse && sumIleggelse > 0
    ? `<<${900000 + sumIleggelse}<<%tIleggelsesnr. ${
        ileggelsesnummer ?? "ukjent"
      }`
    : "";
