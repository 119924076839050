import { useFormContext, useWatch } from "react-hook-form";
import { PrisForInntauingViewModel } from "../../api/interfaces/Inntauing";
import { BetalingsspesifikasjonLinje } from "./BetalingsspesifikasjonLinje";
import { beregnTotalsum, Felter, nyDognleieSomBelop } from "./utleveringUtils";

interface RegistrerUtleveringTotalsumProps {
  kostnader: PrisForInntauingViewModel;
}

export const RegistrerUtleveringTotalsum = (
  props: RegistrerUtleveringTotalsumProps
) => {
  const { kostnader } = props;

  const { control } = useFormContext<Felter>();

  const betalingsmetode = useWatch({
    control,
    name: "betalingsmetode"
  });

  const justerDognleie = useWatch({
    control,
    name: "justerDognleie"
  });

  const nyDognleie = useWatch({
    control,
    name: "nyDognleie"
  });

  const betalIleggelse = useWatch({
    control,
    name: "betalIleggelse"
  });

  const totalsum = beregnTotalsum(
    betalingsmetode === "Faktura",
    justerDognleie,
    !betalIleggelse,
    kostnader,
    nyDognleie as number | undefined
  );

  return (
    <BetalingsspesifikasjonLinje
      navn="Totalsum"
      belop={
        betalingsmetode === "Ingen betaling"
          ? nyDognleieSomBelop(nyDognleie)
          : totalsum
      }
      understrek="dobbel"
    />
  );
};
