import { BrevViewModel, PostkasseType } from "../../api/interfaces/Inntauing";
import { Tooltip } from "../common/tooltip/Tooltip";
import {
  greenSquare,
  graySquare,
  redSquare,
  isLessOrEq15Min,
  isLessOrEq31Days
} from "./brevsporingUtils";

interface DigitaltBrevProps {
  brevutsending: BrevViewModel;
  headerText?: string;
}

export const DigitaltBrev = (props: DigitaltBrevProps) => {
  const { brevutsending, headerText } = props;

  let brevstatus: JSX.Element[] = [];

  let postkasse = "";
  switch (brevutsending.postkasse) {
    case PostkasseType.Altinn:
      postkasse = "Altinn";
      break;
    case PostkasseType.Digipost:
      postkasse = "Digipost";
      break;
    case PostkasseType.EBoks:
      postkasse = "EBoks";
      break;
    default:
      postkasse = "Ukjent";
      break;
  }

  if (brevutsending.forespurtTidspunkt) {
    brevstatus.push(
      <Tooltip
        content={`Forespurt ${new Date(
          brevutsending.forespurtTidspunkt ?? ""
        ).toLocaleDateString()} ${new Date(
          brevutsending.forespurtTidspunkt ?? ""
        ).toLocaleTimeString()}`}
        key={`digitalt-brev-f-green-${brevutsending.id}`}
      >
        <div className={greenSquare}>F</div>
      </Tooltip>
    );

    if (brevutsending.arkivertTidspunkt) {
      brevstatus.push(
        <Tooltip
          content={`Arkivert ${new Date(
            brevutsending.arkivertTidspunkt ?? ""
          ).toLocaleDateString()} ${new Date(
            brevutsending.arkivertTidspunkt ?? ""
          ).toLocaleTimeString()}`}
          key={`digitalt-brev-a-green-${brevutsending.id}`}
        >
          <div className={greenSquare}>A</div>
        </Tooltip>
      );
    } else if (isLessOrEq15Min(brevutsending.forespurtTidspunkt)) {
      brevstatus.push(
        <Tooltip
          content="Arkiverer"
          key={`digitalt-brev-a-gray-${brevutsending.id}`}
        >
          <div className={graySquare}>A</div>
        </Tooltip>
      );
    } else {
      brevstatus.push(
        <Tooltip
          content="Arkivering feilet"
          key={`digitalt-brev-a-red-${brevutsending.id}`}
        >
          <div className={redSquare}>A</div>
        </Tooltip>
      );
    }

    if (brevutsending.sendtTidspunkt) {
      brevstatus.push(
        <Tooltip
          content={`Sendt ${postkasse} ${new Date(
            brevutsending.sendtTidspunkt ?? ""
          ).toLocaleDateString()} ${new Date(
            brevutsending.sendtTidspunkt ?? ""
          ).toLocaleTimeString()}`}
          key={`digitalt-brev-s-green-${brevutsending.id}`}
        >
          <div className={greenSquare}>S</div>
        </Tooltip>
      );
    } else if (isLessOrEq15Min(brevutsending.forespurtTidspunkt)) {
      brevstatus.push(
        <Tooltip
          content="Sender"
          key={`digitalt-brev-s-gray-${brevutsending.id}`}
        >
          <div className={graySquare}>S</div>
        </Tooltip>
      );
    } else {
      brevstatus.push(
        <Tooltip
          content="Sending feilet"
          key={`digitalt-brev-s-red-${brevutsending.id}`}
        >
          <div className={redSquare}>S</div>
        </Tooltip>
      );
    }

    if (brevutsending.postkasse === PostkasseType.Altinn) {
      if (brevutsending.lestTidspunkt) {
        brevstatus.push(
          <Tooltip
            content={`Lest ${new Date(
              brevutsending.lestTidspunkt ?? ""
            ).toLocaleDateString()} ${new Date(
              brevutsending.lestTidspunkt ?? ""
            ).toLocaleTimeString()}`}
            key={`digitalt-brev-l-green-${brevutsending.id}`}
          >
            <div className={greenSquare}>L</div>
          </Tooltip>
        );
      } else if (isLessOrEq31Days(brevutsending.sendtTidspunkt)) {
        brevstatus.push(
          <Tooltip
            content="Venter på lesestatus"
            key={`digitalt-brev-l-gray-${brevutsending.id}`}
          >
            <div className={graySquare}>L</div>
          </Tooltip>
        );
      }
    } else {
      brevstatus.push(
        <div
          className="w-8 h-8"
          key={`digitalt-brev-l-red-${brevutsending.id}`}
        ></div>
      );
    }
  }

  return (
    <div key={`digitalt-status-"${brevutsending.id}`}>
      <h3 className="text-lg font-medium mt-4 mb-2">
        {headerText ? headerText : "Digitalt varselbrev"}
      </h3>
      <div className="mt-2 mb-4 flex gap-4 px-[0.625rem]">{brevstatus}</div>
    </div>
  );
};
