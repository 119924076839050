import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getHentedeInntauingerUtenBetalinger,
  postStatusHentet,
  putAvbrytHenting
} from "../api/apiServices/HentingService";
import { NyHentingInputModel } from "../api/interfaces/Inntauing";
import { CACHE_KEYS } from "../utils/globalConstants";

export const useHentedeInntauingerUtenBetalinger = (
  fraDato: Date,
  tilDato: Date
) =>
  useQuery(
    [CACHE_KEYS.HentedeInntauingerUtenBetalinger, fraDato, tilDato],
    () =>
      getHentedeInntauingerUtenBetalinger(fraDato, tilDato).then(r => r.data)
  );

export const useStatusHentet = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingId: string; hentet: NyHentingInputModel }) =>
      postStatusHentet(variables.inntauingId, variables.hentet),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Avsluttet_inntauinger]);
        await queryClient.invalidateQueries([CACHE_KEYS.Klare_brev]);
        return await queryClient.invalidateQueries([
          CACHE_KEYS.Aktive_inntauinger
        ]);
      }
    }
  );
};

export const useAvbrytHenting = (
  inntauingsId: string,
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(() => putAvbrytHenting(inntauingsId), {
    onSuccess: async () => {
      if (onSuccess) onSuccess();
      return Promise.all([
        await queryClient.invalidateQueries([CACHE_KEYS.Aktive_inntauinger]),
        await queryClient.invalidateQueries([CACHE_KEYS.Avsluttet_inntauinger])
      ]);
    }
  });
};
