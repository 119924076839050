import { useQuery } from "react-query";
import {
  getFarger,
  getLokasjoner,
  getMerker,
  getNasjonskoder,
  getOvertredelser
} from "../api/apiServices/GrunndataService";
import { CACHE_KEYS } from "../utils/globalConstants";

export const useLokasjoner = () =>
  useQuery([CACHE_KEYS.Lokasjoner], () =>
    getLokasjoner().then(r =>
      r.data.sort((a, b) => {
        const aName = a.toUpperCase();
        const bName = b.toUpperCase();
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      })
    )
  );

export const useFarger = () =>
  useQuery([CACHE_KEYS.Farger], () =>
    getFarger().then(r =>
      r.data.sort((a, b) => {
        const aName = a.toUpperCase();
        const bName = b.toUpperCase();
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      })
    )
  );

export const useMerker = () =>
  useQuery([CACHE_KEYS.Merker], () =>
    getMerker().then(r =>
      r.data.sort((a, b) => {
        const aName = a.toUpperCase();
        const bName = b.toUpperCase();
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      })
    )
  );

export const useNasjonskoder = () =>
  useQuery([CACHE_KEYS.Nasjonskoder], () =>
    getNasjonskoder().then(r =>
      r.data.sort((a, b) => {
        const aName = a.navn.toUpperCase();
        const bName = b.navn.toUpperCase();
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      })
    )
  );

export const useOvertredelser = () =>
  useQuery([CACHE_KEYS.Overtredelser], () =>
    getOvertredelser().then(r =>
      r.data.sort((a, b) => {
        const aName = a.toUpperCase();
        const bName = b.toUpperCase();
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      })
    )
  );
