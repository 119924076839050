import {
  InntauingViewModel,
  KjoretoyleveranseViewModel
} from "../../api/interfaces/Inntauing";

export const BREVTYPER_SELECT = {
  "Ikke send brev": 0,
  "Digitalt brev": 1,
  "Manuelt brev": 2
};

export const LEVERANSEMETODER = {
  Inntauet: {
    kode: 0,
    navn: "Inntauet"
  },
  Frammøte: {
    kode: 1,
    navn: "Fremmøte"
  },
  FlyttetGategrunn: {
    kode: 2,
    navn: "Flyttet på gategrunn"
  }
};

export interface Felter {
  ordrenummer?: string;
  flyttetTilGatenavn?: string;
  flyttetTilGatenummer?: string;
  flyttetTilOppgang?: string;
  flyttetTilDetaljer?: string;
  brevtype: number;
  kommentar?: string;
}

export const getInitialValues = (
  kjoretoyleveranse?: KjoretoyleveranseViewModel
) => {
  return {
    ordrenummer: kjoretoyleveranse?.ordrenummer
      ? kjoretoyleveranse.ordrenummer.toString()
      : undefined,
    flyttetTilGatenavn: kjoretoyleveranse?.flyttetTilGatenavn,
    flyttetTilGatenummer: kjoretoyleveranse?.flyttetTilGatenummer
      ? kjoretoyleveranse.flyttetTilGatenummer.toString()
      : undefined,
    flyttetTilOppgang: kjoretoyleveranse?.flyttetTilOppgang,
    flyttetTilDetaljer: kjoretoyleveranse?.flyttetTilDetaljer,
    brevtype: BREVTYPER_SELECT["Ikke send brev"]
  };
};

export const mapToPostFlytting = (
  data: Felter,
  inntauing: InntauingViewModel
) => {
  return {
    inntauingsId: inntauing.id,
    kjoretoyleveranse: {
      inntauingsfirma: inntauing.inntauingsgrunnlag.inntauingsfirma ?? "",
      leveringstidspunkt: new Date(),
      type: LEVERANSEMETODER.FlyttetGategrunn.kode,
      ordrenummer:
        data.ordrenummer && data.ordrenummer.length > 0
          ? parseInt(data.ordrenummer)
          : undefined,
      flyttetTilGatenavn: data.flyttetTilGatenavn,
      flyttetTilGatenummer:
        data.flyttetTilGatenummer && data.flyttetTilGatenummer.length > 0
          ? parseInt(data.flyttetTilGatenummer)
          : undefined,
      flyttetTilOppgang: data.flyttetTilOppgang,
      flyttetTilDetaljer: data.flyttetTilDetaljer
    }
  };
};
