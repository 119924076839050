import { useMutation, useQueryClient } from "react-query";
import {
  deleteIleggelse,
  putIleggelse
} from "../api/apiServices/IleggelseService";
import { CACHE_KEYS } from "../utils/globalConstants";

export const useRedigerIleggelse = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingId: string; ileggelse: number | undefined }) => {
      if (variables.ileggelse === undefined || variables.ileggelse === 0) {
        return deleteIleggelse(variables.inntauingId);
      }

      return putIleggelse(variables.inntauingId, variables.ileggelse);
    },
    {
      onSuccess: async () => {
        return await queryClient.invalidateQueries([
          CACHE_KEYS.Aktive_inntauinger
        ]);
      }
    }
  );
};
