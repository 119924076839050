import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { SendtTilVrakTabellRad } from "./SendtTilVrakTabellRad";

interface SendtTilVrakTabellprops {
  vrakliste?: InntauingViewModel[];
}

export const SendtTilVrakTabell = (props: SendtTilVrakTabellprops) => {
  const { vrakliste } = props;

  if (!vrakliste) return null;

  return (
    <>
      <table className="tbl">
        <thead>
          <tr>
            <th>Innt. dato</th>
            <th>Nasjon</th>
            <th>Regnr</th>
            <th>Journalnr</th>
            <th>Type</th>
            <th>Merke</th>
            <th>Opplastet dokument</th>
            <th>Vrakdato</th>
            <th>Vrakpant</th>
            <th colSpan={2}>Salg av bilvrak</th>
          </tr>
        </thead>
        <tbody>
          {vrakliste.map(i => (
            <SendtTilVrakTabellRad key={i.id} inntauing={i} />
          ))}
        </tbody>
      </table>
    </>
  );
};
