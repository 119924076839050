import { FieldError, FieldErrorsImpl } from "react-hook-form";
import { AlertBox } from "./AlertBox";

interface FeilmeldingerSkjemaProps {
  className?: string;
  errors: Partial<FieldErrorsImpl>;
}

export const FeilmeldingerSkjema = (props: FeilmeldingerSkjemaProps) => {
  const { className, errors } = props;

  return (
    <>
      {Object.values(errors).length > 0 && (
        <div className={className}>
          <AlertBox
            alertLevel="error"
            heading={
              <h3>{Object.entries(errors).length + " feil i felter"}</h3>
            }
            paragraph={
              <>
                {Object.values(errors).map(m => (
                  <div key={(m as FieldError).ref?.name}>
                    {(m as FieldError).message ?? ""}
                  </div>
                ))}
              </>
            }
          />
        </div>
      )}
    </>
  );
};
