import { useEffect, useState } from "react";

export interface ChecklistItem {
  id: string;
  checked: boolean;
}

export const useChecklist = (initialChecklist: ChecklistItem[]) => {
  const [checklist, setChecklist] = useState<ChecklistItem[]>([]);

  useEffect(() => {
    if (initialChecklist && initialChecklist.length !== checklist.length) {
      setChecklist(initialChecklist);
    }
  }, [checklist.length, initialChecklist]);

  const toggleItem = (id: string) => {
    const updatedItems = checklist.map(item => {
      if (item.id === id) {
        return {
          ...item,
          checked: !item.checked
        };
      }
      return item;
    });

    setChecklist(updatedItems);
  };

  const setAll = (checked: boolean) => {
    const updatedItems = checklist.map(item => {
      return {
        ...item,
        checked: checked
      };
    });

    setChecklist(updatedItems);
  };

  const checkAll = () => setAll(true);

  const clearAll = () => setAll(false);

  return {
    checklist,
    toggleItem,
    checkAll,
    clearAll
  };
};
