import { FieldErrorsImpl } from "react-hook-form";
import { PrislisteViewModel } from "../../../api/interfaces/Prisliste";
import { FelterPrisliste } from "./NyPrisliste";

export const SALG_AV_VRAK = 3500;

export const hentPrislisteForDato = (
  dato: Date,
  prislister?: PrislisteViewModel[]
) => {
  return prislister?.find(
    p =>
      new Date(p.gyldigFra).getTime() <= new Date(dato).getTime() &&
      (!p.gyldigTil ||
        new Date(p.gyldigTil).getTime() >= new Date(dato).getTime())
  );
};

export const antallFeilInntauingskostnader = (
  errors: FieldErrorsImpl<FelterPrisliste>
) => {
  return errors.inntauingskostnader
    ? Object.entries(errors.inntauingskostnader).reduce(
        (prev, curr) => prev + (!!curr[1] ? 1 : 0),
        0
      )
    : 0;
};

export const antallFeilISkjema = (errors: FieldErrorsImpl<FelterPrisliste>) => {
  return (
    (errors.gyldigFra ? 1 : 0) +
    (errors.administrasjonsgebyr?.pris ? 1 : 0) +
    (errors.administrasjonsgebyr?.varenummer ? 1 : 0) +
    (errors.administrasjonsgebyrFlytting?.pris ? 1 : 0) +
    (errors.administrasjonsgebyrFlytting?.varenummer ? 1 : 0) +
    (errors.dognleie?.pris ? 1 : 0) +
    (errors.dognleie?.varenummer ? 1 : 0) +
    antallFeilInntauingskostnader(errors)
  );
};
