import { useRef } from "react";
import { HeadingTopic } from "../../common/typography/HeadingTopic";
import { HeadingView } from "../../common/typography/HeadingView";
import { ReactComponent as Print } from "../../../assets/icons/print.svg";
import { KranbilordreTabell } from "./KranbilordreTabell";
import { useFaktureringsgrunnlag } from "../../../hooks/useKranbilselskap";
import { AlertBox } from "../../common/form/AlertBox";
import { CustomError } from "../../common/typography/CustomError";
import { endOfDay, previousMonday, previousSunday, startOfDay } from "date-fns";
import { useReactToPrint } from "react-to-print";
import { LoadingSpinner } from "../../common/loading/LoadingSpinner";
import { FraTilDato } from "../../common/form/FraTilDato";
import { formaterNullableBelop } from "../../../utils/formatUtils";
import { useStateParams } from "../../../hooks/useStateParams";
import { HeadingUtskrift } from "../../common/typography/HeadingUtskrift";

export const Kranbilordre = () => {
  const forrigeMandag = previousMonday(previousSunday(startOfDay(new Date())));
  const sistSondag = previousSunday(endOfDay(new Date()));

  const [fraDato, setFraDato] = useStateParams(
    forrigeMandag,
    "fra",
    s => s.toISOString(),
    s => new Date(s)
  );
  const [tilDato, setTilDato] = useStateParams(
    sistSondag,
    "til",
    s => s.toISOString(),
    s => new Date(s)
  );

  const componentPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    pageStyle: "m-5"
  });

  const { data, isLoading, isError, error } = useFaktureringsgrunnlag(
    fraDato,
    tilDato
  );

  return (
    <>
      <HeadingView>Kranbilordre</HeadingView>
      <FraTilDato
        actionButtons={
          <button
            type="button"
            className="btn btn--primary-confirm btn--icon-left mb-5 mt-auto"
            onClick={handlePrint}
          >
            <Print className="fill-current" />
            <span>Skriv ut</span>
          </button>
        }
        fraDato={fraDato}
        tilDato={tilDato}
        handleChangeFraDato={setFraDato}
        handleChangeTilDato={setTilDato}
      />
      <HeadingTopic>Registrerte oppdrag for gitt periode</HeadingTopic>
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      {data && (
        <>
          {data.antall === 0 ? (
            <div className="mt-5">
              <AlertBox
                alertLevel="warning"
                heading="Fant ingen oppdrag for den angitte perioden"
              />
            </div>
          ) : (
            <>
              <div className="mt-5">
                {data.kategorigrunnlag.map(i => (
                  <KranbilordreTabell
                    key={i.kategoriType}
                    kategorigrunnlag={i}
                  />
                ))}
                <div className="grid grid-cols-2 grid-rows-2 max-w-xs h-fit w-full mb-10">
                  <div className="font-semibold h-8 w-52 row-span-1 col-span-1">
                    Totalt antall:
                  </div>
                  <div className="h-8 w-52 row-span-1 col-span-1">
                    {data.antall + " stk"}
                  </div>
                  <div className="font-semibold h-8 w-52 row-span-1 col-span-1">
                    Totalsum:
                  </div>
                  <div className="h-8 w-52 row-span-1 col-span-1">
                    {formaterNullableBelop(data.totalbelop)}
                  </div>
                </div>
              </div>
              <div className="hidden">
                <div className="text-xs" ref={componentPrintRef}>
                  <HeadingUtskrift>{`Kranbilordre ${fraDato.toLocaleDateString()} - ${tilDato.toLocaleDateString()}`}</HeadingUtskrift>
                  {data.kategorigrunnlag.map(i => (
                    <KranbilordreTabell
                      key={i.kategoriType}
                      kategorigrunnlag={i}
                    />
                  ))}
                  <div className="grid grid-cols-2 grid-rows-2 max-w-xs h-fit w-full mb-10">
                    <div className="font-semibold h-8 w-52 row-span-1 col-span-1">
                      Totalt antall:
                    </div>
                    <div className="h-8 w-52 row-span-1 col-span-1">
                      {data.antall + " stk"}
                    </div>
                    <div className="font-semibold h-8 w-52 row-span-1 col-span-1">
                      Totalsum:
                    </div>
                    <div className="h-8 w-52 row-span-1 col-span-1">
                      {formaterNullableBelop(data.totalbelop)}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
