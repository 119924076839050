import { ReactComponent as Personbil } from "../../../assets/icons/personbil.svg";

interface HentFraSvvButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  isLoading: boolean;
}

export const HentFraSvvButton = (props: HentFraSvvButtonProps) => {
  const { isLoading, ...rest } = props;

  return (
    <div className="mb-5">
      <button {...rest} className="btn btn--secondary btn--icon-left">
        {isLoading ? (
          <Personbil className="fill-current animate-spin" />
        ) : (
          <Personbil className="fill-current" />
        )}
        <span>{rest.children}</span>
      </button>
    </div>
  );
};

export const getTextForSvvButton = (
  isInfoFetched: boolean,
  nasjonalitet: string,
  isNotFound: boolean,
  isLoading: boolean
) => {
  if (nasjonalitet !== "N") return "Ikke norsk kjøretøy";
  if (isNotFound) return "Finner ikke kjøretøy hos SVV";
  if (isInfoFetched) return "Fyll inn data fra SVV";
  if (isLoading) return "Klargjør data fra SVV";
  return "Fyll inn data fra SVV";
};
