import { Controller, useFormContext } from "react-hook-form";
import { handleNumberOrEmptyValueInput } from "../../utils/keyboardUtils";
import { Kort } from "../common/card/Kort";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { HentFraRegister } from "./HentFraRegister";
import { FelterRedigerEierOgKjoretoyinfo } from "./redigerEierOgKjoretoyinfoUtils";
import { ReactComponent as Personbil } from "../../assets/icons/personbil.svg";
import {
  Kjoretoystype,
  KjoretoystypeMapper
} from "../../api/interfaces/Inntauing";
import { mapEnumToNumberValues } from "../../utils/objectMapping";

interface RedigerStegKjoretoyinfoProps {
  merker?: string[];
  farger?: string[];
}

export const RedigerStegKjoretoyinfo = (
  props: RedigerStegKjoretoyinfoProps
) => {
  const { merker, farger } = props;

  const { control, register } =
    useFormContext<FelterRedigerEierOgKjoretoyinfo>();

  if (!merker || !farger) return <LoadingSpinner />;

  return (
    <Kort
      headerText="Kjøretøyinfo"
      headerIcon={<Personbil className="fill-darkblue" />}
      innhold={
        <div className="my-5">
          <HentFraRegister infotype="kjøretøy" />
          <div className="flex flex-row flex-wrap gap-x-5">
            <div className="ods-form-group">
              <label htmlFor="merke" className="ods-form-label">
                Merke
              </label>
              <Controller
                name="merke"
                control={control}
                render={({ field: { value } }) => (
                  <select
                    id="merke"
                    className="ods-select w-[24.3rem]"
                    value={merker.find(
                      m => m.toLowerCase() === value?.toLowerCase()
                    )}
                    {...register("merke")}
                  >
                    <>
                      <option value={undefined} />
                      {merker?.map(merke => (
                        <option key={merke} value={merke}>
                          {merke}
                        </option>
                      ))}
                    </>
                  </select>
                )}
              />
            </div>
            <div className="ods-form-group">
              <label htmlFor="modell" className="ods-form-label">
                Modell
              </label>
              <input
                autoComplete="off"
                id="modell"
                className="ods-form-input w-[25.25rem]"
                {...register("modell")}
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap gap-x-5">
            <div className="ods-form-group">
              <label htmlFor="type" className="ods-form-label">
                Kjøretøystype
              </label>
              <select
                id="type"
                className="ods-select w-52"
                {...register("type")}
              >
                <>
                  <option value={undefined} />
                  {mapEnumToNumberValues(Kjoretoystype).map(k => (
                    <option
                      key={k}
                      value={KjoretoystypeMapper[k as Kjoretoystype].kode}
                    >
                      {KjoretoystypeMapper[k as Kjoretoystype].navn}
                    </option>
                  ))}
                </>
              </select>
            </div>
            <div className="ods-form-group">
              <label htmlFor="registrertAar" className="ods-form-label">
                Registreringsår
              </label>
              <input
                autoComplete="off"
                id="registrertAar"
                className="ods-form-input w-40"
                onKeyDown={handleNumberOrEmptyValueInput}
                {...register("registrertAar")}
              />
            </div>
            <div className="ods-form-group">
              <label htmlFor="totalvekt" className="ods-form-label">
                Totalvekt
              </label>
              <input
                autoComplete="off"
                id="totalvekt"
                className="ods-form-input w-40"
                onKeyDown={handleNumberOrEmptyValueInput}
                {...register("totalvekt")}
              />
            </div>
            <div className="ods-form-group">
              <label htmlFor="farge" className="ods-form-label">
                Farge
              </label>
              <select
                id="farge"
                className="ods-select w-56"
                {...register("farge")}
              >
                <>
                  <option value={undefined} />
                  {farger?.map(farge => (
                    <option key={farge} value={farge}>
                      {farge}
                    </option>
                  ))}
                </>
              </select>
            </div>
          </div>
        </div>
      }
    />
  );
};
