import * as yup from "yup";
import { SchemaOf } from "yup";
import { RegistrerInntauingFelter } from "./registrerInntauingUtils";

export const registrerInntauingSchema: SchemaOf<RegistrerInntauingFelter> = yup
  .object()
  .shape({
    inntauingsinfo: yup.object().shape({
      ileggelsesnummer: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : Number(value)))
        .notRequired()
    }),
    bestillingsinfo: yup.object().shape({
      betjentId: yup
        .string()
        .required("Du må angi ID for betjent som bestilte inntauing"),
      bestillingstidspunkt: yup
        .date()
        .nullable()
        .transform((curr, orig) => (orig === "" ? undefined : curr))
        .required("Du må angi et tidspunkt for når inntauing ble bestilt"),
      inntauingssted: yup
        .string()
        .required("Du må velge gatenavn for inntauing"),
      gatenummer: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : Number(value)))
        .notRequired(),
      inntauingsfirma: yup
        .string()
        .required("Du må angi firma som utfører inntauing"),
      hjemmel: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : Number(value)))
        .required("Du må velge en hjemmel"),
      overtredelse: yup.string().required("Du må velge en overtredelse"),
      kommentar: yup.string().notRequired(),
      skader: yup.string().notRequired(),
      eierkontakt: yup.string().notRequired(),
      utenskilt: yup.boolean().required()
    }),
    registreringsnummer: yup.object().shape({
      nasjonkode: yup.string().required("Du må velge nasjonskode for kjøretøy"),
      registreringsnummer: yup.string().notRequired(),
      rammenummer: yup.string().notRequired()
    }),
    kjoretoyinfo: yup.object().shape({
      merke: yup.string().notRequired(),
      modell: yup.string().notRequired(),
      type: yup.string().required("Du må velge kjøretøytype"),
      farge: yup.string().notRequired(),
      totalvekt: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : Number(value)))
        .notRequired(),
      registrertAar: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : Number(value)))
        .notRequired()
    }),
    juridiskEier: yup
      .object()
      .shape({
        erBedrift: yup
          .boolean()
          .required("Du må angi om juridisk eier er person eller bedrift"),
        idNummer: yup
          .string()
          .nullable()
          .transform((curr, orig) => (orig ? curr : undefined))
          .when("erBedrift", {
            is: false,
            then: yup.string().when("idNummer", {
              is: (value: string) => value !== "" && value !== undefined,
              then: yup
                .string()
                .required(
                  "Du må angi fødselsnummer (11 tegn) for juridisk eier"
                )
                .length(
                  11,
                  "Du må angi fødselsnummer (11 tegn) for juridisk eier"
                )
            })
          })
          .when("erBedrift", {
            is: true,
            then: yup.string().when("idNummer", {
              is: (value: string) => value !== "" && value !== undefined,
              then: yup
                .string()
                .required(
                  "Du må angi organisasjonsnummer (9 tegn) for juridisk eier"
                )
                .length(
                  9,
                  "Du må angi organisasjonsnummer (9 tegn) for juridisk eier"
                )
            })
          }),
        navn: yup.string().notRequired(),
        telefonnummer: yup
          .string()
          .nullable()
          .transform((curr, orig) => (orig ? curr : undefined)),
        gateadresse: yup.string().notRequired(),
        postnummer: yup.string().notRequired(),
        poststed: yup.string().notRequired()
      })
      .default(null)
      .nullable(),
    medeier: yup
      .object()
      .shape({
        erBedrift: yup
          .boolean()
          .required("Du må angi om medeier er person eller bedrift"),
        idNummer: yup
          .string()
          .nullable()
          .transform((curr, orig) => (orig ? curr : undefined))
          .when("erBedrift", {
            is: false,
            then: yup.string().when("idNummer", {
              is: (value: string) => value !== "" && value !== undefined,
              then: yup
                .string()
                .required("Du må angi fødselsnummer (11 tegn) for medeier")
                .length(11, "Du må angi fødselsnummer (11 tegn) for medeier")
            })
          })
          .when("erBedrift", {
            is: true,
            then: yup.string().when("idNummer", {
              is: (value: string) => value !== "" && value !== undefined,
              then: yup
                .string()
                .required("Du må angi organisasjonsnummer (9 tegn) for medeier")
                .length(
                  9,
                  "Du må angi organisasjonsnummer (9 tegn) for medeier"
                )
            })
          }),
        navn: yup.string().notRequired(),
        telefonnummer: yup
          .string()
          .nullable()
          .transform((curr, orig) => (orig ? curr : undefined)),
        gateadresse: yup.string().notRequired(),
        postnummer: yup.string().notRequired(),
        poststed: yup.string().notRequired()
      })
      .default(null)
      .nullable(),
    leasingtaker: yup
      .object()
      .shape({
        erBedrift: yup
          .boolean()
          .required("Du må angi om leasingtaker er person eller bedrift"),
        idNummer: yup
          .string()
          .nullable()
          .transform((curr, orig) => (orig ? curr : undefined))
          .when("erBedrift", {
            is: false,
            then: yup.string().when("idNummer", {
              is: (value: string) => value !== "" && value !== undefined,
              then: yup
                .string()
                .required("Du må angi fødselsnummer (11 tegn) for leasingtaker")
                .length(
                  11,
                  "Du må angi fødselsnummer (11 tegn) for leasingtaker"
                )
            })
          })
          .when("erBedrift", {
            is: true,
            then: yup.string().when("idNummer", {
              is: (value: string) => value !== "" && value !== undefined,
              then: yup
                .string()
                .required(
                  "Du må angi organisasjonsnummer (9 tegn) for leasingtaker"
                )
                .length(
                  9,
                  "Du må angi organisasjonsnummer (9 tegn) for leasingtaker"
                )
            })
          }),
        navn: yup.string().notRequired(),
        telefonnummer: yup
          .string()
          .nullable()
          .transform((curr, orig) => (orig ? curr : undefined)),
        gateadresse: yup.string().notRequired(),
        postnummer: yup.string().notRequired(),
        poststed: yup.string().notRequired()
      })
      .default(null)
      .nullable()
  });
