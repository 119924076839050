import { useMutation, useQueryClient } from "react-query";

import {
  postOpprettFlagg,
  putFjernFlagg
} from "../api/apiServices/FlaggService";
import { CACHE_KEYS } from "../utils/globalConstants";
import {
  FlaggInputModel,
  FlaggType,
  FlaggtypeMapper
} from "../api/interfaces/Flagg";

export const useOpprettFlagg = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsId: string; flaggInputModel: FlaggInputModel }) =>
      postOpprettFlagg(variables.inntauingsId, variables.flaggInputModel),
    {
      onSuccess: async () => {
        return await queryClient.invalidateQueries([
          CACHE_KEYS.Aktive_inntauinger
        ]);
      }
    }
  );
};

export const useFjernFlagg = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsId: string; flaggId: string }) =>
      putFjernFlagg(variables.inntauingsId, variables.flaggId),
    {
      onSuccess: async () => {
        return await queryClient.invalidateQueries([
          CACHE_KEYS.Aktive_inntauinger
        ]);
      }
    }
  );
};

export const useFjernFlaggs = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (variables: {
      inntauingsId: string;
      flagg: { id: string; type: FlaggType }[];
    }) => {
      const okFlaggTyper: string[] = [];
      const feiletFlaggTyper: string[] = [];

      const calls = variables.flagg.map(f =>
        putFjernFlagg(variables.inntauingsId, f.id)
          .then(() => okFlaggTyper.push(FlaggtypeMapper[f.type].statusnavn))
          .catch(() =>
            feiletFlaggTyper.push(FlaggtypeMapper[f.type].statusnavn)
          )
      );

      try {
        await Promise.all(calls);
        await queryClient.invalidateQueries([CACHE_KEYS.Aktive_inntauinger]);

        return Promise.resolve({ okFlaggTyper, feiletFlaggTyper });
      } catch (_) {
        await queryClient.invalidateQueries([CACHE_KEYS.Aktive_inntauinger]);

        return Promise.reject({ okFlaggTyper, feiletFlaggTyper });
      }
    },
    {
      onSuccess: async statuserFlaggs => {
        return statuserFlaggs;
      },
      onError: async (statuserFlaggs: {
        okFlaggTyper: string[];
        feiletFlaggTyper: string[];
      }) => statuserFlaggs
    }
  );
};
