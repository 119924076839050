import { PktTabs } from "@oslokommune/punkt-react";
import { useParams } from "react-router-dom";

interface TabLayoutProps {
  tabs: { text: string; href: string; active?: boolean }[];
  content: JSX.Element[];
  hasAccess: boolean[];
}

export const TabLayout = (props: TabLayoutProps) => {
  const { tabs, content, hasAccess } = props;

  const { tabId } = useParams();

  return (
    <div>
      <PktTabs tabs={tabs.filter((_, index) => hasAccess[index])} arrowNav />
      <div className="mx-5">
        {
          content[
            tabs.findIndex(
              (tab, index) =>
                getTabIdFromHref(tab.href) === tabId && hasAccess[index]
            )
          ]
        }
      </div>
    </div>
  );
};

const getTabIdFromHref = (href: string) => {
  return href.split("/").pop();
};
