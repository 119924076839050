import { KeyValueKortInnhold } from "../common/card/KeyValueKortInnhold";
import { Kort } from "../common/card/Kort";
import { ReactComponent as Betaling } from "../../assets/icons/stolpemeny_faktura.svg";
import { formaterNullableBelop } from "../../utils/formatUtils";

interface BetalingsspesifikasjonProps {
  hentersNavn?: string;
  hentersFodselsdato?: Date;
  inntauingskostnad: number;
  administrasjonsgebyr: number;
  dognleie: number;
  totalsum: number;
}

export const Betalingsspesifikasjon = (props: BetalingsspesifikasjonProps) => {
  const {
    hentersNavn,
    hentersFodselsdato,
    inntauingskostnad,
    administrasjonsgebyr,
    dognleie,
    totalsum
  } = props;

  return (
    <Kort
      headerText="Betalingsspesifikasjon"
      headerIcon={<Betaling className="fill-darkblue" />}
      innhold={
        <KeyValueKortInnhold
          keyValuePair={[
            {
              key: "Avhenters navn",
              value: [hentersNavn ?? ""]
            },
            {
              key: "Avhenters f.dato",
              value: [
                hentersFodselsdato
                  ? new Date(hentersFodselsdato).toLocaleDateString()
                  : ""
              ]
            },
            {
              key: "Inntauingskostnad",
              value: [formaterNullableBelop(inntauingskostnad)]
            },
            {
              key: "Administrasjonsgebyr",
              value: [formaterNullableBelop(administrasjonsgebyr)]
            },
            {
              key: "Total døgnleie",
              value: [formaterNullableBelop(dognleie)]
            },
            {
              key: "Totalpris",
              value: [formaterNullableBelop(totalsum)]
            }
          ]}
          keyWidth="w-56"
        />
      }
    />
  );
};
