import {
  InntauingViewModel,
  TakseringViewModel
} from "../../api/interfaces/Inntauing";

const getSisteTakstdato = (
  takseringer?: TakseringViewModel[]
): Date | undefined => {
  if (!takseringer || takseringer.length === 0) {
    return undefined;
  }
  takseringer.sort(sorterTakseringer);
  return takseringer[0].tidspunkt;
};

export const sorterTakseringer = (
  a: TakseringViewModel,
  b: TakseringViewModel
) =>
  new Date(b.tidspunkt ?? 0).getTime() - new Date(a.tidspunkt ?? 0).getTime();

export const formaterSisteTakstDato = (takseringer?: TakseringViewModel[]) => {
  const sisteTakstDato = getSisteTakstdato(takseringer);

  return sisteTakstDato
    ? new Date(sisteTakstDato).toLocaleDateString()
    : "Mangler dato";
};

export const harVraking = (inntauing?: InntauingViewModel) =>
  !!inntauing?.vraking;
