import { useMutation, useQueryClient } from "react-query";
import {
  postKjoretoyleveranse,
  putAngreAnkomst,
  putAngreFlyttetPaaGategrunn,
  putAvbrytFremmote,
  putKjoretoyleveranse
} from "../api/apiServices/KjoretoyleveranseService";
import { KjoretoyleveranseViewModel } from "../api/interfaces/Inntauing";
import { CACHE_KEYS } from "../utils/globalConstants";

export const useRedigerKjoretoyleveranse = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: {
      inntauingId: string;
      kjoretoyleveranse: KjoretoyleveranseViewModel;
    }) =>
      putKjoretoyleveranse(variables.inntauingId, variables.kjoretoyleveranse),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(CACHE_KEYS.Aktive_inntauinger);
      }
    }
  );
};

export const useAvbrytFremmote = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingId: string }) =>
      putAvbrytFremmote(variables.inntauingId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CACHE_KEYS.Avsluttet_inntauinger);
        return await queryClient.invalidateQueries(
          CACHE_KEYS.Aktive_inntauinger
        );
      }
    }
  );
};

export const useAngreAnkomst = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingId: string }) =>
      putAngreAnkomst(variables.inntauingId),
    {
      onSuccess: async () => {
        return await queryClient.invalidateQueries(
          CACHE_KEYS.Aktive_inntauinger
        );
      }
    }
  );
};

export const useAngreFlyttetPaaGategrunn = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingId: string }) =>
      putAngreFlyttetPaaGategrunn(variables.inntauingId),
    {
      onSuccess: async () => {
        return Promise.all([
          await queryClient.invalidateQueries(CACHE_KEYS.Aktive_inntauinger),
          await queryClient.invalidateQueries(CACHE_KEYS.Flyttet_pa_gategrunn)
        ]);
      }
    }
  );
};

export const useOpprettKjoretoyleveranse = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: {
      inntauingsId: string;
      kjoretoyleveranse: KjoretoyleveranseViewModel;
    }) =>
      postKjoretoyleveranse(
        variables.inntauingsId,
        variables.kjoretoyleveranse
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CACHE_KEYS.Klare_brev]);
        return queryClient.invalidateQueries([CACHE_KEYS.Aktive_inntauinger]);
      }
    }
  );
};
