import { Navigate } from "react-router-dom";
import { getUser } from "../api/client";
import { ROUTES } from "../utils/globalConstants";
import { Role } from "../utils/userRolesUtils";

export const InitialRoute = () => {
  const userRoles = (getUser()?.profile.groups as string[]) ?? [];

  const initialRoute = userRoles.includes(Role.Regnskap)
    ? ROUTES.Regnskap + "/kasseinntekter"
    : ROUTES.Aktive_inntauinger;

  return <Navigate to={initialRoute} />;
};
