import { useTilVrak } from "../../hooks/useVrak";
import { HeadingView } from "../common/typography/HeadingView";
import { CustomError } from "../common/typography/CustomError";
import { TilVrakTabell } from "./TilVrakTabell";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";

export const TilVrak = () => {
  const { data, isLoading, isError, error } = useTilVrak();

  return (
    <>
      <HeadingView>Til vrak</HeadingView>
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      <TilVrakTabell vrakliste={data} />
    </>
  );
};
