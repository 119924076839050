export const HeadingUtskrift = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >
) => {
  return (
    <h2 {...props} className={`text-xl ${props.className}`}>
      {props.children ?? "Ingen overskrift"}
    </h2>
  );
};
