import { apiClient } from "../client";
import {
  PrislisteInputModel,
  PrislisteViewModel
} from "../interfaces/Prisliste";
import { config } from "./Config";

const client = apiClient();

export const getPrislister = () =>
  client.get<PrislisteViewModel[]>("pris/prislister", config());

export const postPrisliste = (prisliste: PrislisteInputModel) =>
  client.post<PrislisteInputModel>("pris/prisliste", prisliste, config());
