import React, { useState } from "react";

export interface Step {
  id: string;
  testid?: string;
  label: string;
  cancelStepButton?: () => JSX.Element;
  previousStepButton?: (
    props: React.ButtonHTMLAttributes<HTMLButtonElement>
  ) => JSX.Element;
  nextStepButton?: (
    props: React.ButtonHTMLAttributes<HTMLButtonElement>
  ) => JSX.Element;
  confirmStepButton?: () => JSX.Element;
  component: () => JSX.Element;
}

interface StepperLayoutProps {
  steps: Step[];
}

export const StepperLayout = (props: StepperLayoutProps) => {
  const { steps } = props;

  const [activeStep, setActiveStep] = useState<number>(0);

  return (
    <div className="flex flex-row w-full">
      <div className="flex flex-col w-32 mr-10">
        {steps.map((s, index) => (
          <React.Fragment key={s.id}>
            {index > 0 && (
              <div className="border-r-2 border-darkblue h-12 w-full ml-[-50%] my-3" />
            )}
            <button type="button" onClick={() => setActiveStep(index)}>
              <div
                className={`border-2 ${
                  activeStep === index
                    ? "bg-darkblue text-white"
                    : "bg-white text-darkblue"
                } border-darkblue rounded-full h-12 w-12 flex justify-center items-center text-2xl text-darkblue mx-auto`}
              >
                {index + 1}
              </div>
              <div
                className={`${
                  activeStep === index && "font-semibold"
                } flex justify-center mx-auto mt-2 w-fit text-xs`}
              >
                {s.label}
              </div>
            </button>
          </React.Fragment>
        ))}
      </div>
      {steps.map((s, index) => (
        <div
          key={s.id}
          className={`${
            index !== activeStep && "invisible h-0 w-0"
          } flex flex-col w-full max-w-4xl`}
        >
          {s.component()}
          <div className="flex flex-row gap-5 mt-10">
            {s.cancelStepButton && s.cancelStepButton()}
            {s.previousStepButton &&
              s.previousStepButton({
                onClick: () => setActiveStep(prev => prev - 1)
              })}
            {s.nextStepButton &&
              s.nextStepButton({
                onClick: () => setActiveStep(prev => prev + 1)
              })}
            {s.confirmStepButton && s.confirmStepButton()}
          </div>
        </div>
      ))}
    </div>
  );
};
