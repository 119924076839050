import * as yup from "yup";

export const registrerFlyttetPaaGategrunnSchema = yup.object({
  ordrenummer: yup
    .number()
    .typeError("Ordrenummer må være et tall")
    .required("Ordrenummer må oppgis ved ankomst"),
  flyttetTilGatenavn: yup.string().required("Gatenavn kjøretøy er flyttet til må oppgis"),
  flyttetTilGatenummer: yup.string(),
  flyttetTilOppgang: yup.string(),
  flyttetTilDetaljer: yup.string(),
  brevtype: yup.number(),
  kommentar: yup
    .string()
    .when(["brevtype"], brevtype => brevtype === 2 ? yup.string().required("Oppgi kommentar ved manuell brevutsendelse") : yup.string().notRequired())
})
