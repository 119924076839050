export const REFRESH_INTERVAL = 10000;

export const DEFAULT_PAGE_SIZE = 50;

export const CACHE_KEYS = {
  Alle_inntauinger: "alleinntauinger",
  Aktive_inntauinger: "inntauinger",
  Aktive_inntauinger_beholdingskontroll: "inntauingerbeholdingskontroll",
  Klare_brev: "klarebrev",
  Brevsporinger: "brevsporinger",
  Eier_med_fodselsnummer: "eiermedfodselsnummer",
  Kan_varsles: "kanvarsles",
  Avsluttet_inntauinger: "avsluttetinntauinger",
  Siste_24t: "siste24t",
  Flyttet_pa_gategrunn: "flyttetpagategrunn",
  Utlevering_inntauing: "utleveringinntauing",
  Notater: "notater",
  Handlingslogg: "handlingslogg",
  Oppgjor: "oppgjor",
  Til_auksjon: "tilauksjon",
  Sendt_til_auksjon: "sendttilauksjon",
  Arkiverte_auksjoner: "arkiverteauksjoner",
  Til_vrak: "tilvrak",
  Sendt_til_vrak: "sendttilvrak",
  Arkiverte_vrak: "arkivertevrak",
  Takseringer: "takseringer",
  Arkiverte_takseringer: "arkivertetakseringer",
  Kvittering_pdf: "kvitteringpdf",
  Avhendingsbrev_pdf: "avhendingsbrevpdf",
  Lokasjoner: "lokasjoner",
  Farger: "farger",
  Merker: "merker",
  Nasjonskoder: "nasjonskoder",
  Overtredelser: "overtredelser",
  Kjoretoysinformasjon: "kjoretoysinformasjon",
  Arkiverte_avstemminger: "arkiverteavstemminger",
  Avstemmingsgrunnlag: "avstemmingsgrunnlag",
  Faktureringsgrunnlag: "faktureringsgrunnlag",
  Betalte_ileggelser: "betalteileggelser",
  HentedeInntauingerUtenBetalinger: "hentedeinntauingerutenbetalinger",
  Gebyrer_til_betaling: "gebyrertilbetaling",
  Ikke_fakturerte: "ikkefakturerte",
  Feilet_fakturaer: "feiletfakturaer",
  Personlige_utleveringer: "personligeutleveringer",
  Inntektsrapport: "inntektsrapport",
  Inntekt_kasse: "inntektkasse",
  Inntekt_faktura: "inntektfaktura",
  Inntekt_flytting: "inntektflytting",
  Manuellebrev_pdf: "manuellebrevpdf",
  Brev_pdf: "brevpdf",
  Prislister: "prislister",
  Deaktiverte_inntauinger: "deaktiverteinntauinger",
  Vedtak: "vedtak"
};

export const ROUTES = {
  Alle_inntauinger: "/alleinntauinger",
  Aktive_inntauinger: "/inntauinger",
  Inntauinger_siste_24t: "/siste24",
  Flyttet_pa_gategrunn: "/flyttetpagategrunn",
  Avsluttede_inntauinger: "/avsluttet",
  Utlevering_av_inntauing: "/inntauinger/utlever",
  Kunngjoringsliste: "/inntauinger/kunngjoringsliste",
  Postliste_for_rekommanderte_brev:
    "/inntauinger/postlisteforrekommandertebrev",
  LastNedInntauinger: "/inntauinger/lastned",
  Brevutsendelse: "/brevsending",
  ManuellBrevutsendelse: "/manuellBrevutsendelse",
  Taksering: "/taksering",
  Vraking: "/vraking",
  Auksjon: "/auksjon",
  Generer_attest: "/auksjon/prosess/genererauksjonsattest",
  Registrer_auksjonert: "/auksjon/prosess/registrerauksjonert",
  Manuell_registrering: "/registrerny",
  Innstillinger: "/innstillinger",
  Ny_prisliste: "/innstillinger/prislister/nyprisliste",
  Oppgjor: "/oppgjor",
  Regnskap: "/regnskap",
  Login: "/login",
  Logout: "/logout",
  Redirect: "/redirect"
};

export const TEKST_SOK =
  "Her kan du søke på eiernavn, regnr, rammenr, arkivsaksnr, ileggelsesnr, journalnr og ordrenr.";
