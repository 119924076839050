import { AxiosRequestConfig } from "axios";
import { getUser } from "../client";

export interface Param {
  bym_id?: string;
  post_logout_redirect_uri?: string;
}

export const config = (): AxiosRequestConfig<any> | undefined => {
  const user = getUser();
  const accessToken = user?.access_token;

  if (!accessToken) return undefined;

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
};

export const configPDF = (): AxiosRequestConfig<any> | undefined => {
  const user = getUser();
  const accessToken = user?.access_token;

  if (!accessToken) return undefined;

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/pdf"
    },
    responseType: "arraybuffer"
  };
};
