import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { HeadingView } from "../common/typography/HeadingView";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registrerTillatUtleveringUtenBetalingSchema } from "./registrerTillatUtleveringUtenBetalingSchema";
import { RequiredLabel } from "../common/form/RequiredLabel";
import { useTillatUtleveringUtenBetaling } from "../../hooks/useInntauinger";
import { useToast } from "../common/toast/ToastProvider";
import { Felter } from "./tillatUtleveringUtenBetalingUtils";
import { FeilmeldingerSkjema } from "../common/form/FeilmeldingerSkjema";

interface TillatUtleveringUtenBetalingProps {
  inntauing: InntauingViewModel;
  close: () => void;
}

export const TillatUtleveringUtenBetaling = (
  props: TillatUtleveringUtenBetalingProps
) => {
  const { inntauing, close } = props;

  const { addToast } = useToast();
  const putTillatUtleveringUtenBetaling = useTillatUtleveringUtenBetaling(
    inntauing.id
  );

  const initialValues = {
    kommentar: ""
  };

  const {
    formState: { errors },
    register,
    handleSubmit
  } = useForm<Felter>({
    mode: "onSubmit",
    resolver: yupResolver(registrerTillatUtleveringUtenBetalingSchema),
    reValidateMode: "onChange",
    defaultValues: initialValues,
    shouldFocusError: true,
    shouldUseNativeValidation: true
  });

  const registrerTillatelse = (data: Felter) => {
    putTillatUtleveringUtenBetaling.mutate(
      { aarsak: data.kommentar },
      {
        onSuccess: () => {
          addToast("Tillatelse til utlevering uten betaling registrert");
          close();
        },
        onError: () => {
          addToast(
            "Feil ved registrering av tillatelse til utlevering uten betaling",
            "error"
          );
        }
      }
    );
  };

  return (
    <>
      <HeadingView className="pl-8 w-fit">
        Tillat utlevering uten betaling
      </HeadingView>
      <form className="pl-8 w-fit" onSubmit={handleSubmit(registrerTillatelse)}>
        <FeilmeldingerSkjema className="mb-5 w-fit" errors={errors} />
        <div className="ods-form-group w-96">
          <label htmlFor="kommentar" className="ods-form-label flex flex-wrap">
            Kommentar
            {<RequiredLabel />}
          </label>
          <div id="kommentarHelp" className="ods-form-help">
            Skriv begrunnelse for tillatelse
          </div>
          <textarea
            id="kommentar"
            className="ods-form-textarea"
            {...register("kommentar")}
            autoComplete="off"
            autoFocus
          />
        </div>
        <div className="flex flex-row flex-wrap gap-5">
          <button
            className="btn btn--flat btn--icon-left"
            onClick={close}
            type="button"
          >
            <ArrowDown className="fill-current rotate-[90deg]" />
            <span>Tilbake</span>
          </button>
          <button type="reset" className="btn btn--secondary">
            Nullstill
          </button>
          <button
            className="btn btn--primary btn--icon-right"
            disabled={Object.entries(errors).some(e => e !== undefined)}
            type="submit"
          >
            <ArrowDown className="fill-current rotate-[-90deg]" />
            <span>Bekreft tillatelse</span>
          </button>
        </div>
      </form>
    </>
  );
};
