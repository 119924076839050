import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from "react-query";

import {
  getAktiveInntauinger,
  getAvsluttetInntauinger,
  getFlyttetPaGategrunn,
  getInntauing,
  getInntauingerSiste24t,
  getInntauingUtlevering,
  getKostnaderVedHenting,
  postInntauing,
  putTillatFakturering,
  putDeaktiverInntauing,
  putKansellerBetalingskrav,
  putTillatUtleveringUtenBetaling,
  getFlyttingerMedFeiletBetalingskrav,
  putTillatUtleveringMedRedusertDognleie,
  getDeaktiverteInntauinger,
  getAlleInntauinger
} from "../api/apiServices/InntauingService";
import { Filter } from "../api/interfaces/Filter";
import { RegistrerInntauingInputModel } from "../api/interfaces/Inntauing";
import {
  CACHE_KEYS,
  REFRESH_INTERVAL,
  DEFAULT_PAGE_SIZE
} from "../utils/globalConstants";

const DEFAULT_PAGE_SIZE_BEHOLDNINGSKONTROLL = 500;

export const useInntauingerSiste24t = (
  sortKey: string,
  sortDirection: "desc" | "asc",
  filter: Filter,
  refetch?: boolean
) =>
  useInfiniteQuery(
    [CACHE_KEYS.Siste_24t, sortKey, sortDirection, { ...filter }],
    ({ pageParam = 0 }) =>
      getInntauingerSiste24t(
        pageParam,
        DEFAULT_PAGE_SIZE,
        sortKey,
        sortDirection === "desc",
        filter
      ).then(r => r.data),
    {
      enabled: refetch,
      getNextPageParam: lastPage => lastPage.nextCursor ?? undefined,
      refetchInterval: REFRESH_INTERVAL
    }
  );

export const useAvsluttetInntauinger = (
  sortKey: string,
  sortDirection: "desc" | "asc",
  filter: Filter,
  refetch?: boolean,
  customPageSize?: number
) =>
  useInfiniteQuery(
    [CACHE_KEYS.Avsluttet_inntauinger, sortKey, sortDirection, { ...filter }],
    ({ pageParam = 0 }) =>
      getAvsluttetInntauinger(
        pageParam,
        customPageSize ?? DEFAULT_PAGE_SIZE,
        sortKey,
        sortDirection === "desc",
        filter
      ).then(r => r.data),
    {
      enabled: refetch,
      getNextPageParam: lastPage => lastPage.nextCursor ?? undefined,
      refetchInterval: REFRESH_INTERVAL
    }
  );

export const useFlyttetPaGategrunn = (
  sortKey: string,
  sortDirection: "desc" | "asc",
  filter: Filter,
  refetch?: boolean,
  customPageSize?: number
) =>
  useInfiniteQuery(
    [CACHE_KEYS.Flyttet_pa_gategrunn, sortKey, sortDirection, { ...filter }],
    ({ pageParam = 0 }) =>
      getFlyttetPaGategrunn(
        pageParam,
        customPageSize ?? DEFAULT_PAGE_SIZE,
        sortKey,
        sortDirection === "desc",
        filter
      ).then(r => r.data),
    {
      enabled: refetch,
      getNextPageParam: lastPage => lastPage.nextCursor ?? undefined,
      refetchInterval: REFRESH_INTERVAL
    }
  );

export const useFlyttingerMedFeiledeBetalingskrav = () =>
  useQuery([CACHE_KEYS.Flyttet_pa_gategrunn], () =>
    getFlyttingerMedFeiletBetalingskrav().then(r => r.data)
  );

export const useInntauinger = (
  sortKey: string,
  sortDirection: "desc" | "asc",
  filter: Filter,
  refetch?: boolean,
  customPageSize?: number
) =>
  useInfiniteQuery(
    [CACHE_KEYS.Aktive_inntauinger, sortKey, sortDirection, { ...filter }],
    ({ pageParam = 0 }) =>
      getAktiveInntauinger(
        pageParam,
        customPageSize ?? DEFAULT_PAGE_SIZE,
        sortKey,
        sortDirection === "desc",
        filter
      ).then(r => r.data),
    {
      enabled: refetch,
      getNextPageParam: lastPage => lastPage.nextCursor ?? undefined,
      refetchInterval: REFRESH_INTERVAL
    }
  );

export const useAlleInntauinger = (
  sortKey: string,
  sortDirection: "desc" | "asc",
  filter: Filter,
  refetch?: boolean,
  customPageSize?: number
) =>
  useInfiniteQuery(
    [CACHE_KEYS.Alle_inntauinger, sortKey, sortDirection, { ...filter }],
    ({ pageParam = 0 }) =>
      getAlleInntauinger(
        pageParam,
        customPageSize ?? DEFAULT_PAGE_SIZE,
        sortKey,
        sortDirection === "desc",
        filter
      ).then(r => r.data),
    {
      enabled: refetch,
      getNextPageParam: lastPage => lastPage.nextCursor ?? undefined,
      refetchInterval: REFRESH_INTERVAL
    }
  );

export const useInntauingerBeholdingskontroll = (
  sortKey: string,
  sortDirection: "desc" | "asc",
  filter: Filter,
  refetch?: boolean,
  customPageSize?: number
) =>
  useInfiniteQuery(
    [
      CACHE_KEYS.Aktive_inntauinger_beholdingskontroll,
      sortKey,
      sortDirection,
      { ...filter }
    ],
    ({ pageParam = 0 }) =>
      getAktiveInntauinger(
        pageParam,
        customPageSize ?? DEFAULT_PAGE_SIZE_BEHOLDNINGSKONTROLL,
        sortKey,
        sortDirection === "desc",
        filter
      ).then(r => r.data),
    {
      enabled: refetch,
      getNextPageParam: lastPage => lastPage.nextCursor ?? undefined,
      refetchInterval: REFRESH_INTERVAL
    }
  );

export const useInntauingUtlevering = (sok: string) =>
  useQuery([CACHE_KEYS.Utlevering_inntauing], () =>
    getInntauingUtlevering(sok).then(r => r.data)
  );

export const useInntauing = (id: string, onSuccess?: () => void) =>
  useQuery(
    [CACHE_KEYS.Aktive_inntauinger, id],
    () => getInntauing(id).then(r => r.data),
    {
      onSuccess: () => {
        if (onSuccess) onSuccess();
      },
      enabled: id.length > 0
    }
  );

export const useRegistrerInntauing = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauing: RegistrerInntauingInputModel }) =>
      postInntauing(variables.inntauing),
    {
      onSuccess: async () => {
        if (onSuccess) onSuccess();
        return await queryClient.invalidateQueries([
          CACHE_KEYS.Aktive_inntauinger
        ]);
      }
    }
  );
};

export const useTillatFakturering = (inntauingsId: string) => {
  const queryClient = useQueryClient();

  return useMutation(() => putTillatFakturering(inntauingsId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([CACHE_KEYS.Handlingslogg]);
      return await queryClient.invalidateQueries([
        CACHE_KEYS.Aktive_inntauinger,
        inntauingsId
      ]);
    }
  });
};

export const useTillatUtleveringUtenBetaling = (inntauingsId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (values: { aarsak: string }) =>
      putTillatUtleveringUtenBetaling(inntauingsId, values.aarsak),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Handlingslogg]);
        return await queryClient.invalidateQueries([
          CACHE_KEYS.Aktive_inntauinger,
          inntauingsId
        ]);
      }
    }
  );
};

export const useTillatUtleveringMedRedusertDognleie = (
  inntauingsId: string
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (values: { aarsak: string }) =>
      putTillatUtleveringMedRedusertDognleie(inntauingsId, values.aarsak),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Handlingslogg]);
        return await queryClient.invalidateQueries([
          CACHE_KEYS.Aktive_inntauinger,
          inntauingsId
        ]);
      }
    }
  );
};

export const useDeaktiverteInntauinger = (fraDato: Date, tilDato: Date) =>
  useQuery([CACHE_KEYS.Deaktiverte_inntauinger, fraDato, tilDato], () =>
    getDeaktiverteInntauinger(fraDato, tilDato).then(r => r.data)
  );

export const useDeaktiverInntauing = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (values: { inntauingsId: string; aarsak: string }) =>
      putDeaktiverInntauing(values.inntauingsId, values.aarsak),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Handlingslogg]);
        await queryClient.invalidateQueries([
          CACHE_KEYS.Deaktiverte_inntauinger
        ]);
        return await queryClient.invalidateQueries([
          CACHE_KEYS.Aktive_inntauinger
        ]);
      }
    }
  );
};

export const useKansellerBetalingskrav = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (values: { inntauingsId: string; aarsak: string }) =>
      putKansellerBetalingskrav(values.inntauingsId, values.aarsak),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Handlingslogg]);
        return await queryClient.invalidateQueries([
          CACHE_KEYS.Flyttet_pa_gategrunn
        ]);
      }
    }
  );
};

export const useKostnaderVedHenting = (
  inntauingsId: string,
  hentettidspunkt?: Date,
  onSuccess?: (dognleie: number) => void
) =>
  useQuery(
    [CACHE_KEYS.Gebyrer_til_betaling, inntauingsId, hentettidspunkt],
    () =>
      getKostnaderVedHenting(inntauingsId, hentettidspunkt).then(r => r.data),
    {
      onSuccess: data => {
        onSuccess?.(data.dognleiekostnad?.pris ?? 0);
      }
    }
  );
