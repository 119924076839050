import { useFormContext } from "react-hook-form";
import { Kort } from "../common/card/Kort";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { FelterRedigerEierOgKjoretoyinfo } from "./redigerEierOgKjoretoyinfoUtils";
import { ReactComponent as Personbil } from "../../assets/icons/personbil.svg";
import { NasjonskodeViewModel } from "../../api/interfaces/Grunndata";

interface RedigerStegRegnummerProps {
  nasjonskoder?: NasjonskodeViewModel[];
}

export const RedigerStegRegnummer = (props: RedigerStegRegnummerProps) => {
  const { nasjonskoder } = props;

  const { register } = useFormContext<FelterRedigerEierOgKjoretoyinfo>();

  if (!nasjonskoder) return <LoadingSpinner />;

  return (
    <Kort
      headerText="Registreringsnummer"
      headerIcon={<Personbil className="fill-darkblue" />}
      innhold={
        <div className="flex flex-row flex-wrap gap-x-5 my-5">
          <div className="ods-form-group">
            <label htmlFor="nasjonalitet" className="ods-form-label">
              Nasjon
            </label>
            <select
              id="nasjonalitet"
              className="ods-select w-64"
              {...register("nasjonalitet")}
            >
              <>
                <option value={undefined} />
                {nasjonskoder?.map(nasjon => (
                  <option key={nasjon.kode} value={nasjon.kode}>
                    {nasjon.navn}
                  </option>
                ))}
              </>
            </select>
          </div>
          <div className="ods-form-group">
            <label htmlFor="registreringsnummer" className="ods-form-label">
              Regnr
            </label>
            <input
              autoComplete="off"
              id="registreringsnummer"
              className="ods-form-input w-56"
              {...register("registreringsnummer")}
            />
          </div>
          <div className="ods-form-group">
            <label htmlFor="rammenummer" className="ods-form-label">
              Rammenr
            </label>
            <input
              autoComplete="off"
              id="rammenummer"
              className="ods-form-input w-96"
              {...register("rammenummer")}
            />
          </div>
        </div>
      }
    />
  );
};
