import { useState } from "react";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { useOpprettFlagg } from "../../hooks/useFlagg";
import { useToast } from "../common/toast/ToastProvider";
import { HeadingView } from "../common/typography/HeadingView";
import {
  FlaggType,
  FlaggtypeMapper,
  FlaggtypeMapperType
} from "../../api/interfaces/Flagg";

import { ReactComponent as FlaggRod } from "../../assets/icons/alert-error-rod.svg";
import { ReactComponent as FlaggGul } from "../../assets/icons/alert-error-gul.svg";
import { ReactComponent as FlaggBlaa } from "../../assets/icons/alert-error-blaa.svg";
import { ReactComponent as FlaggDisabled } from "../../assets/icons/alert-error-disabled.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";

const flaggAlternativer: (FlaggtypeMapperType & {
  flaggikon: JSX.Element;
  flaggtype: FlaggType;
})[] = [
  {
    ...FlaggtypeMapper[FlaggType.Varsel],
    flaggtype: FlaggType.Varsel,
    flaggikon: <FlaggRod title={FlaggtypeMapper[FlaggType.Varsel].statusnavn} />
  },
  {
    ...FlaggtypeMapper[FlaggType.Klage],
    flaggtype: FlaggType.Klage,
    flaggikon: <FlaggGul title={FlaggtypeMapper[FlaggType.Klage].statusnavn} />
  },
  {
    ...FlaggtypeMapper[FlaggType.Vilkaar],
    flaggtype: FlaggType.Vilkaar,
    flaggikon: (
      <FlaggGul title={FlaggtypeMapper[FlaggType.Vilkaar].statusnavn} />
    )
  },
  {
    ...FlaggtypeMapper[FlaggType.UnderArbeid],
    flaggtype: FlaggType.UnderArbeid,
    flaggikon: (
      <FlaggGul title={FlaggtypeMapper[FlaggType.UnderArbeid].statusnavn} />
    )
  },
  {
    ...FlaggtypeMapper[FlaggType.Mottakskontroll],
    flaggtype: FlaggType.Mottakskontroll,
    flaggikon: (
      <FlaggBlaa
        title={FlaggtypeMapper[FlaggType.Mottakskontroll].statusnavn}
      />
    )
  }
];

interface OpprettFlaggProps {
  inntauing: InntauingViewModel;
  close: () => void;
}

export const OpprettFlagg = (props: OpprettFlaggProps) => {
  const { inntauing, close } = props;

  const [flaggType, setFlaggtype] = useState<FlaggType | undefined>(undefined);

  const postOpprettFlagg = useOpprettFlagg();
  const { addToast } = useToast();

  const handleOpprettFlagg = () => {
    postOpprettFlagg.mutate(
      {
        inntauingsId: inntauing.id,
        flaggInputModel: { type: flaggType ?? FlaggType.Ukjent, kommentar: "" }
      },
      {
        onSuccess: () => {
          addToast(
            `Flagg med flaggtype "${
              FlaggtypeMapper[flaggType ?? FlaggType.Ukjent].statusnavn
            }" opprettet`
          );
          close();
        },
        onError: () => {
          addToast(
            `Flagg med flaggtype "${
              FlaggtypeMapper[flaggType ?? FlaggType.Ukjent].statusnavn
            }" ble ikke opprettet`,
            "error"
          );
        }
      }
    );
  };

  return (
    <div className="pl-8">
      <HeadingView>Opprett flagg</HeadingView>
      <>
        <p className="text-2xl mb-4">Velg type flagg</p>
        {flaggAlternativer.map(flagg => {
          const disabled = inntauing.flagg?.some(
            f => f.type === flagg.flaggtype && !f.fjernet
          );
          return (
            <div
              key={`opprett-${flagg.navn}`}
              className="ods-form-group flex gap-4"
            >
              <input
                data-testid={`${flagg.navn.toLocaleLowerCase()}-radio`}
                type="radio"
                className="ods-form-check-input"
                id={flagg.navn}
                name="flagg-radio-group"
                disabled={disabled}
                onChange={() => setFlaggtype(flagg.flaggtype)}
              />
              <label className="ods-form-label flex gap-4" htmlFor={flagg.navn}>
                <div>
                  {disabled ? (
                    <FlaggDisabled
                      title={`${flagg.navn} er allerede opprettet`}
                    />
                  ) : (
                    flagg.flaggikon
                  )}
                </div>
                <div>{flagg.statusnavn}</div>
              </label>
            </div>
          );
        })}
        <div className="flex flex-row flex-wrap gap-5">
          <button className="btn btn--flat btn--icon-left" onClick={close}>
            <ArrowDown className="fill-current rotate-[90deg]" />
            <span>Tilbake</span>
          </button>
          <button
            data-testid="opprett-flagg-bekreft"
            className="btn btn--primary btn--icon-right"
            disabled={!flaggType}
            onClick={handleOpprettFlagg}
          >
            <ArrowDown className="fill-current rotate-[-90deg]" />
            <span>Bekreft</span>
          </button>
        </div>
      </>
    </div>
  );
};
