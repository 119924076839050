import { useCallback, useEffect, useRef, useState } from "react";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import {
  useAngreOpprettTakst,
  useInntauingerMedTaksering,
  usePutTaksasjon
} from "../../hooks/useTakst";
import { ReactComponent as PrintIcon } from "../../assets/icons/print.svg";
import { ReactComponent as Slett } from "../../assets/icons/slett.svg";
import { TakseringTabellUtskrift } from "./TakseringTabellUtskrift";
import { useReactToPrint } from "react-to-print";
import { useToast } from "../common/toast/ToastProvider";
import { TakseringTabellRad } from "./TakseringTabellRad";
import { useOpprettVrak } from "../../hooks/useVrak";
import { harVraking } from "./takseringUtils";
import { isSaksbehandler } from "../../utils/userRolesUtils";

interface TakseringTabellProps {
  takseringsdato: Date;
}

export interface Taksering {
  inntauingId: string;
  fil?: File | null;
}

export const TilTakstTabell = (props: TakseringTabellProps) => {
  const { takseringsdato: takseringstidspunkt } = props;

  const [takseringer, setTakseringer] = useState<Taksering[]>([]);
  const [ikkeTilVraking, setIkkeTilVraking] = useState<string[]>([]);
  const [aktivInntauing, setAktivInntauing] = useState("");
  const [selectedInntauinger, setSelectedInntauinger] = useState<
    InntauingViewModel[]
  >([]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const { data: inntauinger } = useInntauingerMedTaksering();
  const putTaksering = usePutTaksasjon();
  const deleteAngreTaksering = useAngreOpprettTakst();
  const putOpprettVrak = useOpprettVrak();
  const fileInput = useRef<(HTMLInputElement | null)[]>([]);
  const buttonRef = useRef<(HTMLButtonElement | null)[]>([]);
  const { addToast } = useToast();

  const componentPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    pageStyle: "m-5"
  });

  useEffect(() => {
    setIkkeTilVraking(inntauinger?.filter(i => i.vraking).map(i => i.id) ?? []);
  }, [inntauinger]);

  const selectFile = useCallback((id: string) => {
    setAktivInntauing(id);
    setTakseringer(prev =>
      prev.filter(t => {
        return t.inntauingId !== id;
      })
    );
    fileInput.current?.find(i => i?.id === id)?.click();
  }, []);

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const tempTaksering: Taksering = {
        inntauingId: aktivInntauing,
        fil: e.target.files?.item(0)
      };

      e.target.value = "";
      setTakseringer(prev => [...prev, tempTaksering]);
      setAktivInntauing("");
    },
    [aktivInntauing]
  );

  const handleRemoveFile = useCallback((id: string) => {
    setTakseringer(prev =>
      prev.filter(t => {
        return t.inntauingId !== id;
      })
    );
  }, []);

  const toggleInntauingerToPrint = useCallback(
    (inntauing: InntauingViewModel) => {
      const currInntauingerToPrint = [...selectedInntauinger];
      const index = currInntauingerToPrint.indexOf(inntauing);
      if (index === -1) currInntauingerToPrint.push(inntauing);
      else currInntauingerToPrint.splice(index, 1);

      setSelectedInntauinger(currInntauingerToPrint);

      if (currInntauingerToPrint.length !== inntauinger?.length && isCheckAll)
        setIsCheckAll(!isCheckAll);
      if (currInntauingerToPrint.length === inntauinger?.length && !isCheckAll)
        setIsCheckAll(!isCheckAll);
    },
    [inntauinger?.length, isCheckAll, selectedInntauinger]
  );

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setSelectedInntauinger(inntauinger ?? []);
    if (isCheckAll) setSelectedInntauinger([]);
  };

  const handleArkiver = useCallback(
    (e: any, id: string) => {
      e.preventDefault();
      let currFile = takseringer.find(t => t.inntauingId === id)?.fil;
      let formData = new FormData();

      if (currFile) {
        formData.append(
          "taksasjonstidspunkt",
          takseringstidspunkt.toISOString()
        );
        formData.append("fil", currFile);

        putTaksering.mutate(
          {
            inntauingsId: id,
            registrerTaksasasjon: formData
          },
          {
            onSuccess: () => {
              if (
                !ikkeTilVraking.includes(id) &&
                !harVraking(inntauinger?.find(i => i.id === id))
              ) {
                putOpprettVrak.mutate(
                  {
                    inntauingsId: id
                  },
                  {
                    onSuccess: () =>
                      addToast(
                        "Taksering med opprettelse av vrak ble registrert og arkivert i websak"
                      ),
                    onError: () =>
                      addToast(
                        "Taksering ble registrert og arkivert i websak, men kunne ikke opprette vrak",
                        "error"
                      )
                  }
                );
              } else {
                addToast("Taksering gjennomført og arkivert i websak");
              }
            },
            onError: () => {
              addToast("Kunne ikke registrere taksering", "error");
            }
          }
        );
      }
    },
    [
      addToast,
      ikkeTilVraking,
      inntauinger,
      putOpprettVrak,
      putTaksering,
      takseringer,
      takseringstidspunkt
    ]
  );

  const handleAngreTaksering = () => {
    deleteAngreTaksering.mutateAsync(
      {
        inntauingsIder: selectedInntauinger.map(i => i.id)
      },
      {
        onSuccess: response => {
          setSelectedInntauinger([]);
          if ((response.data.inntauingerIkkeFunnet?.length ?? 0) > 0) {
            let msg = `Angret taksering for ${
              response.data.inntauingerFunnet?.length ?? 0
            } kjøretøy, kunne ikke angre taksering for ${
              response.data.inntauingerIkkeFunnet?.length ?? 0
            } kjøretøy`;

            addToast(msg, "error");
          } else {
            let msg = `Angret taksering for ${
              response.data.inntauingerFunnet?.length ?? 0
            } kjøretøy`;
            addToast(msg);
          }
        },
        onError: () => {
          setSelectedInntauinger([]);
          addToast(
            "En feil skjedde ved angring av taksering av kjøretøy",
            "error"
          );
        }
      }
    );
  };

  const handleOpprettVrak = useCallback(
    (e: boolean, id: string) => {
      let temp = [...ikkeTilVraking];

      if (!e && !temp.includes(id)) temp.push(id);
      else temp = temp.filter(t => t !== id);

      setIkkeTilVraking(temp);
    },
    [ikkeTilVraking]
  );

  const stopReload = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>, id: string) => {
      stopReload(e);
      if (e.dataTransfer.files?.item(0)) {
        let tempTaksering: Taksering = {
          inntauingId: id,
          fil: e.dataTransfer.files?.item(0)
        };

        setTakseringer(prev => [...prev, tempTaksering]);
      } else {
        addToast("Kunne ikke laste opp dokument ved drag-n-drop", "error");
      }
    },
    [addToast]
  );

  const handleEnter = useCallback((id: string) => {
    let curr = buttonRef.current.find(b => b?.id === id);
    curr?.classList.add("bg-gray-200");
  }, []);

  const handleExit = useCallback((id: string) => {
    let curr = buttonRef.current.find(b => b?.id === id);
    curr?.classList.remove("bg-gray-200");
  }, []);

  return (
    <>
      <table className="tbl">
        <thead>
          <tr>
            <th>
              <input
                className="ods-form-check-input"
                type="checkbox"
                checked={isCheckAll}
                onChange={handleSelectAll}
              />
            </th>
            <th>Ankomsttidspunkt</th>
            <th>Rad</th>
            <th>Nasjonskode</th>
            <th>ID</th>
            <th>Type</th>
            <th>Merke</th>
            <th>Opplastet dokument</th>
            <th colSpan={2}>Opprett vrak</th>
          </tr>
        </thead>
        <tbody>
          {inntauinger?.map((inntauing, index) => {
            return (
              <TakseringTabellRad
                key={inntauing.id}
                inntauing={inntauing}
                selectedInntauinger={selectedInntauinger}
                index={index}
                takseringer={takseringer}
                takseringstidspunkt={takseringstidspunkt}
                buttonRef={buttonRef}
                fileInput={fileInput}
                arkiver={handleArkiver}
                handleDrop={handleDrop}
                handleEnter={handleEnter}
                handleExit={handleExit}
                changeFile={handleFileChange}
                opprettVrak={handleOpprettVrak}
                removeFile={handleRemoveFile}
                selectFile={selectFile}
                toggleInntauingerToPrint={toggleInntauingerToPrint}
              />
            );
          })}
        </tbody>
      </table>
      <div className="flex gap-4 mt-5 mb-16">
      {isSaksbehandler() && <button
          className="btn btn--flat btn--icon-left mr-3"
          disabled={selectedInntauinger.length === 0}
          onClick={handleAngreTaksering}
        >
          <Slett className="fill-current" />
          <span>Kanseller taksering</span>
        </button>
      }
        <button
          className="btn btn--primary-confirm btn--icon-left w-fit"
          onClick={handlePrint}
          disabled={selectedInntauinger.length === 0}
        >
          <PrintIcon className="fill-current" />
          <span>Skriv ut valgte</span>
        </button>
      </div>

      <div className="hidden">
        <div ref={componentPrintRef}>
          <TakseringTabellUtskrift inntauinger={selectedInntauinger} />
        </div>
      </div>
    </>
  );
};
