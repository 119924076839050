import {
  InntauingViewModel,
  StatuskodeMapper
} from "../../api/interfaces/Inntauing";

interface BeholdningskontrollTabellProps {
  inntauinger?: InntauingViewModel[];
}

export const BeholdningskontrollTabell = (
  props: BeholdningskontrollTabellProps
) => {
  const { inntauinger } = props;

  return (
    <table id="beholdningskontroll">
      <thead>
        <tr>
          <th>Inntauingsdato</th>
          <th>Regnr</th>
          <th>Journalnr</th>
          <th>Merke</th>
          <th>Modell</th>
          <th>Nasjonalitet</th>
          <th>Kjøretøystype</th>
          <th>Status</th>
          <th>Rad</th>
        </tr>
      </thead>
      <tbody>
        {(inntauinger ?? []).map(i => (
          <tr key={i.id}>
            <td>
              {new Date(
                i.inntauingsgrunnlag.bestillingstidspunkt
              ).toLocaleDateString()}
            </td>
            <td>{i.kjoretoy.registreringsnummer ?? ""}</td>
            <td>{i.kjoretoyleveranse?.journalnummer ?? ""}</td>
            <td>{i.kjoretoy.merke ?? ""}</td>
            <td>{i.kjoretoy.modell ?? ""}</td>
            <td>{i.kjoretoy.nasjonalitet ?? ""}</td>
            <td>{i.kjoretoy.kjoretoystype.kode ?? ""}</td>
            <td>{StatuskodeMapper[i.status].navn}</td>
            <td>{i.kjoretoyleveranse?.plassering ?? ""}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
