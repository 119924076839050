import { Kort } from "../common/card/Kort";
import { ReactComponent as InntauingIcon } from "../../assets/icons/inntauing.svg";
import { useFormContext } from "react-hook-form";
import { RegistrerInntauingFelter } from "./registrerInntauingUtils";

export const RegistrerInntauingInntauingInfo = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<RegistrerInntauingFelter>();

  return (
    <Kort
      headerText="Inntauingsinfo"
      headerIcon={<InntauingIcon className="fill-darkblue" />}
      fullWidth={true}
      innhold={
        <div className="flex flex-wrap">
          <div className="w-full flex flex-wrap">
            <div className="ods-form-group mr-5 w-40">
              <label className="ods-form-label" htmlFor="ileggelsesnummer">
                Ileggelsesnr.
              </label>
              <input
                className={`ods-form-input ${
                  errors.inntauingsinfo?.ileggelsesnummer
                    ? "border-errorred focus:border-errorred"
                    : ""
                }`}
                id="ileggelsesnummer"
                autoComplete="off"
                {...register("inntauingsinfo.ileggelsesnummer")}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};
