import { useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { handleNumberOrEmptyValueInput } from "../../utils/keyboardUtils";
import { Toggle } from "../common/form/Toggle";
import { hentFeilISkjema } from "./RegistrerInntauingFeilmelding";
import {
  EierId,
  EierIdErPerson,
  EierIdGateadresse,
  EierIdIdNummer,
  EierIdNavn,
  EierIdPostnummer,
  EierIdPoststed,
  EierIdTelefon,
  RegistrerInntauingFelter
} from "./registrerInntauingUtils";

interface RegistrerInntauingEierinfoProps {
  id: "juridiskEier" | "medeier" | "leasingtaker";
}

export const RegistrerInntauingEierinfo = (
  props: RegistrerInntauingEierinfoProps
) => {
  const { id } = props;

  const {
    control,
    register,
    setValue,
    formState: { errors }
  } = useFormContext<RegistrerInntauingFelter>();

  const eier = useWatch({
    control,
    name: id as EierId
  });

  useEffect(() => {
    setValue(`${id}.idNummer` as EierIdIdNummer, eier?.idNummer, {
      shouldValidate: hentFeilISkjema(errors).length > 0
    });
  }, [eier?.erBedrift, eier?.idNummer, errors, id, setValue]);

  const hentEierErrors = () => {
    switch (id) {
      case "juridiskEier":
        return errors.juridiskEier;
      case "medeier":
        return errors.medeier;
      case "leasingtaker":
        return errors.leasingtaker;
      default:
        return undefined;
    }
  };

  const eierErrors = hentEierErrors();

  return (
    <div className="grid grid-cols-4 gap-5 mb-8">
      <div className="col-span-3 mt-3">
        <label className="ods-form-label" htmlFor={`${id}ErBedrift`}>
          Er bedrift
        </label>
        <Controller
          name={(id + ".erBedrift") as EierIdErPerson}
          control={control}
          render={({ field }) => (
            <Toggle
              id={`${id}ErBedrift`}
              leftText="Nei"
              rightText="Ja"
              value={field.value}
              toggleFunction={() => field.onChange(!field.value)}
            />
          )}
        />
      </div>

      <div className="col-span-3">
        <label className="ods-form-label" htmlFor={`${id}IdNummer`}>
          {eier?.erBedrift ? "Organisasjonsnummer" : "Fødselsnummer"}
        </label>
        <input
          id={`${id}IdNummer`}
          className={`ods-form-input ${
            eierErrors?.idNummer ? "border-errorred focus:border-errorred" : ""
          }`}
          {...register((id + ".idNummer") as EierIdIdNummer)}
          onKeyDown={handleNumberOrEmptyValueInput}
          autoComplete="off"
        />
      </div>

      <div className="col-span-2">
        <label className="ods-form-label" htmlFor={`${id}Navn`}>
          Fullt navn
        </label>
        <input
          id={`${id}Navn`}
          className={`ods-form-input ${
            eierErrors?.navn ? "border-errorred focus:border-errorred" : ""
          }`}
          {...register((id + ".navn") as EierIdNavn)}
          autoComplete="off"
        />
      </div>

      <div className="col-span-2">
        <label className="ods-form-label" htmlFor={`${id}Telefonnummer`}>
          Telefon
        </label>
        <input
          id={`${id}Telefonnummer`}
          className={`ods-form-input ${
            eierErrors?.telefonnummer
              ? "border-errorred focus:border-errorred"
              : ""
          }`}
          {...register((id + ".telefonnummer") as EierIdTelefon)}
          autoComplete="off"
        />
      </div>

      <div className="col-span-2">
        <label className="ods-form-label" htmlFor={`${id}Gateadresse`}>
          Gateadresse
        </label>
        <input
          id={`${id}Gateadresse`}
          className={`ods-form-input ${
            eierErrors?.gateadresse
              ? "border-errorred focus:border-errorred"
              : ""
          }`}
          {...register((id + ".gateadresse") as EierIdGateadresse)}
          autoComplete="off"
        />
      </div>

      <div>
        <label className="ods-form-label" htmlFor={`${id}Postnummer`}>
          Postnummer
        </label>
        <input
          id={`${id}Postnummer`}
          className={`ods-form-input ${
            eierErrors?.postnummer
              ? "border-errorred focus:border-errorred"
              : ""
          }`}
          {...register((id + ".postnummer") as EierIdPostnummer)}
          autoComplete="off"
        />
      </div>

      <div>
        <label className="ods-form-label" htmlFor={`${id}Poststed`}>
          Poststed
        </label>
        <input
          id={`${id}Poststed`}
          className={`ods-form-input ${
            eierErrors?.poststed ? "border-errorred focus:border-errorred" : ""
          }`}
          {...register((id + ".poststed") as EierIdPoststed)}
          autoComplete="off"
        />
      </div>
    </div>
  );
};
