import { Kjoretoysinformasjon } from "./Kjoretoysinformasjon";
import { Eierinformasjon } from "./EierInformasjon";
import { Kort } from "../common/card/Kort";
import { ReactComponent as Personbil } from "../../assets/icons/personbil.svg";
import { isInnsyn, isUtvikler } from "../../utils/userRolesUtils";
import { AlertBox } from "../common/form/AlertBox";
import { KjoretoyViewModel } from "../../api/interfaces/Inntauing";

const formatPostnummerAndPoststed = (
  postnummer: string | null,
  poststed: string | null
) => {
  if (postnummer && poststed) return postnummer + " " + poststed;
  if (postnummer) return postnummer;
  if (poststed) return poststed;
  return "";
};

interface EierOgKjoretoysinformasjonProps {
  inntauingsId?: string;
  kjoretoy?: KjoretoyViewModel;
  setInntauingToEdit?: (inntauingsId: string) => void;
  isEditable?: boolean;
  hasKjoretoyleveranse?: boolean;
}

export const EierOgKjoretoysinformasjon = (
  props: EierOgKjoretoysinformasjonProps
) => {
  const {
    inntauingsId,
    kjoretoy,
    setInntauingToEdit,
    isEditable,
    hasKjoretoyleveranse
  } = props;

  if (!inntauingsId || !kjoretoy)
    return (
      <AlertBox
        alertLevel="error"
        heading="En feil har skjedd"
        paragraph={<p>Data mangler inntauingsobjekt</p>}
      />
    );

  const juridiskEier = kjoretoy.juridiskEier;
  const medeier = kjoretoy.medeier;
  const leasingtaker = kjoretoy.leasingtaker;
  const isBedrift = !(juridiskEier?.erPerson ?? true);
  const leasingtakerIsBedrift = !(leasingtaker?.erPerson ?? true);

  return (
    <Kort
      headerText="Kjøretøy-/eierinfo"
      headerIcon={<Personbil className="fill-darkblue" />}
      innhold={
        <div>
          <div className="desktop:flex desktop:gap-8">
            <Kjoretoysinformasjon
              nasjonskode={kjoretoy.nasjonalitet ?? ""}
              regnr={kjoretoy.registreringsnummer ?? ""}
              rammenr={kjoretoy.rammenummer ?? ""}
              kjoretoystype={kjoretoy.kjoretoystype.navn ?? ""}
              modell={kjoretoy.modell ?? ""}
              merke={kjoretoy.merke ?? ""}
              farge={kjoretoy.farge ?? ""}
              totalvekt={kjoretoy.totalvekt?.toString() ?? ""}
              registrert={kjoretoy.registrertAar?.toString() ?? ""}
            />

            <div className="laptop:mt-4"></div>

            <Eierinformasjon
              juridiskEier={
                isBedrift
                  ? (juridiskEier?.navn ?? "Mangler navn") + " (Bedrift)"
                  : juridiskEier?.navn ?? ""
              }
              isLeasingtaker={!!leasingtaker ? true : false}
              medeier={medeier?.navn ?? ""}
              leasingtaker={
                leasingtakerIsBedrift
                  ? (leasingtaker?.navn ?? "Mangler navn") + " (Bedrift)"
                  : leasingtaker?.navn ?? ""
              }
              telefon={
                !leasingtaker
                  ? juridiskEier?.telefonnummer ?? ""
                  : leasingtaker.telefonnummer ?? ""
              }
              adresse={
                !leasingtaker
                  ? juridiskEier?.gateadresse ?? ""
                  : leasingtaker.gateadresse ?? ""
              }
              postnrOgSted={
                !leasingtaker
                  ? formatPostnummerAndPoststed(
                      juridiskEier?.postnummer ?? "",
                      juridiskEier?.poststed ?? ""
                    )
                  : formatPostnummerAndPoststed(
                      leasingtaker?.postnummer ?? "",
                      leasingtaker?.poststed ?? ""
                    )
              }
            />
          </div>

          {isEditable && !isInnsyn() && !isUtvikler() && (
            <div className="flex justify-start mt-5 py-2">
              <button
                className="btn btn--secondary"
                data-testid="rediger-eierKjoretoyInformasjon-button"
                disabled={!hasKjoretoyleveranse}
                onClick={() => setInntauingToEdit?.(inntauingsId)}
              >
                Rediger
              </button>
            </div>
          )}
        </div>
      }
    />
  );
};
