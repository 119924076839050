import { useQuery } from "react-query";
import { CACHE_KEYS } from "../utils/globalConstants";
import {
  getFakturaRegnskap,
  getFlyttingRegnskap,
  getKasseRegnskap,
  getRegnskaprapport
} from "../api/apiServices/RegnskapService";

export const useRegnskaprapport = (fra: Date, til: Date) =>
  useQuery([CACHE_KEYS.Inntektsrapport, fra, til], () =>
    getRegnskaprapport(fra, til).then(r => r.data)
  );

export const useKasseRegnskap = (fra: Date, til: Date) =>
  useQuery([CACHE_KEYS.Inntekt_kasse, fra, til], () =>
    getKasseRegnskap(fra, til).then(r => r.data)
  );

export const useFakturaRegnskap = (fra: Date, til: Date) =>
  useQuery([CACHE_KEYS.Inntekt_faktura, fra, til], () =>
    getFakturaRegnskap(fra, til).then(r => r.data)
  );

export const useFlyttingRegnskap = (
  fra: Date,
  til: Date,
  filternokkel: string
) =>
  useQuery([CACHE_KEYS.Inntekt_flytting, fra, til, filternokkel], () =>
    getFlyttingRegnskap(fra, til, filternokkel).then(r => r.data)
  );
