import { getHostFromEnv } from "../../api/client";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { formaterNullableBelop } from "../../utils/formatUtils";
import { KolonneNasjonskodeTd } from "../common/table/KolonneNasjonskode";
import { formaterSisteTakstDato } from "../taksering/takseringUtils";

interface ArkiverteVrakTabellProps {
  vrakliste?: InntauingViewModel[];
}

export const ArkiverteVrakTabell = (props: ArkiverteVrakTabellProps) => {
  const { vrakliste } = props;

  if (!vrakliste) return null;

  return (
    <table className="tbl">
      <thead>
        <tr>
          <th>Nasjonskode</th>
          <th>ID</th>
          <th>Type</th>
          <th>Merke</th>
          <th>Siste takstdato</th>
          <th>Vrakdato</th>
          <th>Salgssum</th>
          <th>Vrakpant</th>
          <th>Websak</th>
        </tr>
      </thead>
      <tbody>
        {vrakliste.map(i => (
          <tr key={i.id}>
            <KolonneNasjonskodeTd nasjonskode={i.kjoretoy.nasjonalitet} />
            <td>
              {i.kjoretoyleveranse?.journalnummer
                ? "#" + i.kjoretoyleveranse?.journalnummer
                : i.kjoretoy.registreringsnummer}
            </td>
            <td>{i.kjoretoy.kjoretoystype.kode}</td>
            <td>{i.kjoretoy.merke}</td>
            <td>{formaterSisteTakstDato(i.takseringer)}</td>
            <td>
              {i.vraking?.vrakdato
                ? new Date(i.vraking.vrakdato.toString()).toLocaleDateString()
                : "Mangler dato"}
            </td>
            <td>{formaterNullableBelop(i.vraking?.inntektSkrapsalg)}</td>
            <td>{formaterNullableBelop(i.vraking?.vrakpant)}</td>
            <td className="underline">
              <a
                rel="noreferrer"
                target="_blank"
                href={`${getHostFromEnv("WEBSAK")}/${i.arkivReferanseId}`}
              >
                {i.arkivReferansenummer}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
