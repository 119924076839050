import {
  useFarger,
  useMerker,
  useNasjonskoder
} from "../../hooks/useGrunndata";
import { RedigerStegRegnummer } from "./RedigerStegRegnummer";
import { RedigerStegKjoretoyinfo } from "./RedigerStegKjoretoyinfo";
import { RedigerStegEierinfo } from "./RedigerStegEierinfo";

export const RedigerSteg = () => {
  const { data: farger } = useFarger();
  const { data: merker } = useMerker();
  const { data: nasjonskoder } = useNasjonskoder();

  return (
    <>
      <RedigerStegRegnummer nasjonskoder={nasjonskoder} />
      <RedigerStegKjoretoyinfo merker={merker} farger={farger} />
      <RedigerStegEierinfo />
    </>
  );
};
