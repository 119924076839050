import { useAuth } from "react-oidc-context";
import { logoutChannel } from "../../App";

import { ReactComponent as LoggUtIcon } from "../../assets/icons/logg-ut.svg";

export const LoggUtButton = () => {
  const auth = useAuth();

  const handleLoggut = async () => {
    await auth.removeUser();
    await auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
    logoutChannel.postMessage("logout");
  };

  return (
    <button
      type="button"
      data-testid="logout-button"
      className="btn btn--logg-ut--state-light btn--small btn--icon-right"
      onClick={handleLoggut}
    >
      <LoggUtIcon className="fill-current" />
      <span>Logg ut</span>
    </button>
  );
};
