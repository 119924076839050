interface UtleveringChipProps extends React.HTMLAttributes<HTMLDivElement> {}

export const UtleveringChip = (props: UtleveringChipProps) => {
  const { children, className } = props;

  return (
    <div
      {...props}
      className={`rounded-full px-4 py-1 bg-rusticyellow ${className}`}
    >
      {children}
    </div>
  );
};
