import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getBetalteileggelser,
  getKvitteringPDF,
  getPersonligeBetalinger,
  postBetaling,
  putFjernIleggelse,
  putLeggTilIleggelse
} from "../api/apiServices/BetalingService";
import { BetalingInputModel } from "../api/interfaces/Betaling";
import { CACHE_KEYS } from "../utils/globalConstants";

export const useBetaling = (inntauingId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (variables: { betaling: BetalingInputModel }) => {
      const r = await postBetaling(inntauingId, variables.betaling);
      return r.data;
    },
    {
      onSuccess: async () => {
        return Promise.all([
          await queryClient.invalidateQueries([CACHE_KEYS.Ikke_fakturerte]),
          await queryClient.invalidateQueries([CACHE_KEYS.Aktive_inntauinger]),
          await queryClient.invalidateQueries([
            CACHE_KEYS.Eier_med_fodselsnummer
          ])
        ]);
      }
    }
  );
};

export const useKvitteringPDF = (betalingsnummer: string) =>
  useQuery([CACHE_KEYS.Kvittering_pdf, betalingsnummer], () =>
    getKvitteringPDF(betalingsnummer).then(r =>
      window.URL.createObjectURL(
        new Blob([r.data], { type: "application/pdf" })
      )
    )
  );

export const useBetalteileggelser = (fraDato: Date, tilDato: Date) =>
  useQuery([CACHE_KEYS.Betalte_ileggelser, fraDato, tilDato], () =>
    getBetalteileggelser(fraDato, tilDato).then(r => r.data)
  );

export const usePersonligeBetalinger = (dato: Date) =>
  useQuery([CACHE_KEYS.Personlige_utleveringer, dato], () =>
    getPersonligeBetalinger(dato).then(r => r.data)
  );

export const useFjernIleggelse = (betalingsnummer: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => putFjernIleggelse(betalingsnummer).then(r => r.data),
    {
      onSuccess: async () => {
        return Promise.all([
          await queryClient.invalidateQueries([CACHE_KEYS.Avstemmingsgrunnlag]),
          await queryClient.invalidateQueries([CACHE_KEYS.Betalte_ileggelser])
        ]);
      }
    }
  );
};

export const useLeggTilIleggelse = (betalingsnummer: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => putLeggTilIleggelse(betalingsnummer).then(r => r.data),
    {
      onSuccess: async () => {
        return Promise.all([
          await queryClient.invalidateQueries([CACHE_KEYS.Avstemmingsgrunnlag]),
          await queryClient.invalidateQueries([CACHE_KEYS.Betalte_ileggelser])
        ]);
      }
    }
  );
};
