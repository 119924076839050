import { useNavigate, useParams } from "react-router";
import { useKvitteringPDF } from "../../hooks/useBetaling";
import { useInntauing } from "../../hooks/useInntauinger";
import { ROUTES } from "../../utils/globalConstants";
import { AlertBox } from "../common/form/AlertBox";
import { HeadingView } from "../common/typography/HeadingView";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { ReactComponent as Print } from "../../assets/icons/print.svg";
import { Kort } from "../common/card/Kort";
import {
  getBetalingsmetode,
  mapBetalingsmetode,
  skrivUtKnappTekst
} from "./utleveringUtils";
import { UtleveringChip } from "./UtleveringChip";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { usePrislister } from "../../hooks/usePris";
import { KvitteringOppsummering } from "./KvitteringOppsummering";
import { KvitteringBetalingsspesifikasjon } from "./KvitteringBetalingsspesifikasjon";
import {
  Betalingstype,
  BetalteGebyrerViewModel
} from "../../api/interfaces/Betaling";

export const Kvittering = () => {
  const { id, betalingsnummer } = useParams();
  const navigate = useNavigate();

  const {
    data: inntauing,
    isLoading: isLoadingInntauing,
    isError: isErrorInntauing,
    error: errorInntauing
  } = useInntauing(id ?? "");
  const {
    data: pdf,
    isLoading: isLoadingPdf,
    isError: isErrorPdf
  } = useKvitteringPDF(betalingsnummer?.toString() ?? "");
  const { data: prislister, isLoading: isloadingPrislister } = usePrislister();

  const handlePrintPDF = () => {
    window.open(pdf, "_blank");
  };

  const betaling = inntauing?.betalinger.find(
    b => b.betalingsnummer.toString() === betalingsnummer
  );

  const betalingsmetodeEnum = getBetalingsmetode(betaling);
  const betalingsmetode = mapBetalingsmetode(betalingsmetodeEnum);

  if (isLoadingInntauing || isloadingPrislister) return <LoadingSpinner />;

  if (isErrorInntauing)
    return (
      <div className="ml-5 mt-10">
        <AlertBox
          alertLevel="error"
          heading={(errorInntauing as Error).message}
        />
      </div>
    );

  return (
    <div className="mx-10 mb-16">
      <HeadingView>
        {`Registrert utlevering av ${betaling?.kjennemerke ?? "ukjent"}`}
      </HeadingView>
      <div className=" flex flex-col gap-y-5 max-w-xl">
        <div className="flex flex-row flex-wrap gap-5">
          <UtleveringChip>{`Radnummer: ${
            inntauing?.kjoretoyleveranse?.plassering ?? "ukjent"
          }`}</UtleveringChip>
        </div>
        <AlertBox
          alertLevel="success"
          heading={
            <h3 data-testid="betaling-registrert">
              {
                formaterTekst(betalingsmetode, betaling?.betalteGebyrer)
                  ?.heading
              }
            </h3>
          }
          paragraph={
            formaterTekst(betalingsmetode, betaling?.betalteGebyrer)?.paragraph
          }
        />
        <Kort
          headerText="Oppsummering"
          innhold={
            <KvitteringOppsummering inntauing={inntauing} betaling={betaling} />
          }
        />
        <Kort
          headerText="Betalingsspesifikasjon"
          innhold={
            <KvitteringBetalingsspesifikasjon
              inntauing={inntauing}
              betaling={betaling}
              betalingsmetode={betalingsmetodeEnum}
              prislister={prislister}
            />
          }
        />
        <div className="flex justify-start mt-5 gap-5">
          <button
            className="btn btn--primary-confirm btn--icon-left"
            onClick={handlePrintPDF}
            disabled={isLoadingPdf || isErrorPdf}
          >
            <Print className="fill-current" />
            <span>{skrivUtKnappTekst(isLoadingPdf, isErrorPdf)}</span>
          </button>
          <button
            className="btn btn--primary btn--icon-right"
            onClick={() => navigate(ROUTES.Aktive_inntauinger)}
          >
            <ArrowDown className="fill-current rotate-[-90deg]" />
            <span>Tilbake til hovedsiden</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const formaterTekst = (
  betalingsmetode: number,
  betalteGebyrer?: BetalteGebyrerViewModel
) => {
  if (betalingsmetode === Betalingstype.Faktura)
    return {
      heading: "Faktura er registrert",
      paragraph: (
        <p>
          Faktura og kvittering er blitt registrert. Inntauingen har fått status
          som hentet. Du kan nå gå tilbake til hovedsiden.
          <br />
          <br />
          Hvis eier/avhenter ønsker betalingsspesifikasjon så kan du skrive ut
          ved å trykke på Skriv ut-knappen.
        </p>
      )
    };
  if (betalingsmetode === Betalingstype.Frafalt)
    return {
      heading: "Ingen betaling er registrert",
      paragraph: (
        <p>
          Kvittering er blitt registrert for utlevering uten betaling.
          Inntauingen har fått status som hentet. Du kan nå gå tilbake til
          hovedsiden.
          <br />
          <br />
          Hvis eier/avhenter ønsker betalingsspesifikasjon så kan du skrive ut
          ved å trykke på Skriv ut-knappen.
        </p>
      )
    };
  if (betalingsmetode === Betalingstype.Kasse) {
    if (!!betalteGebyrer?.administrasjonsgebyr)
      return {
        heading: "Betaling er registrert",
        paragraph: (
          <p>
            Betaling og kvittering er blitt registrert. Inntauingen har fått
            status som hentet. Du kan nå gå tilbake til hovedsiden.
            <br />
            <br />
            Hvis eier/avhenter ønsker betalingsspesifikasjon så kan du skrive ut
            ved å trykke på Skriv ut-knappen.
          </p>
        )
      };
    return {
      heading: "Betaling av døgnleie er registrert",
      paragraph: (
        <p>
          Kvittering er blitt registrert for utlevering kun med betaling av
          døgnleie. Inntauingen har fått status som hentet. Du kan nå gå tilbake
          til hovedsiden.
          <br />
          <br />
          Hvis eier/avhenter ønsker betalingsspesifikasjon så kan du skrive ut
          ved å trykke på Skriv ut-knappen.
        </p>
      )
    };
  }
};
