import { ReactComponent as Betalingsikon } from "../../assets/icons/stolpemeny_faktura.svg";
import { Kort } from "../common/card/Kort";
import {
  KeyValueKortInnhold,
  KeyValuePair
} from "../common/card/KeyValueKortInnhold";
import {
  InntauingViewModel,
  StatuskodeType
} from "../../api/interfaces/Inntauing";
import { formaterNullableBelop } from "../../utils/formatUtils";
import { getBetalingsdata } from "./betalingUtils";

interface BetalingProps {
  inntauing: InntauingViewModel;
}

export const Betaling = (props: BetalingProps) => {
  const { inntauing } = props;

  const isFlytting = inntauing.status === StatuskodeType.FlyttetPaaGategrunn;

  if (!inntauing) return null;
  const betaling = getBetalingsdata(inntauing);

  const keyValuePairs: KeyValuePair[] = isFlytting
    ? [
        {
          key: "Flytting",
          value: [formaterNullableBelop(betaling.utestaendeFlyttekostnad)]
        }
      ]
    : [
        {
          key: "Ileggelse",
          value: [
            formaterNullableBelop(betaling.utestaendeKostnaderIleggelsesgebyr)
          ]
        },
        {
          key: "Inntauing",
          value: [formaterNullableBelop(betaling.utestaendeKostnadInntauing)]
        }
      ];

  const utstaendeKostnader = isFlytting
    ? formaterNullableBelop(betaling.utestaendeFlyttekostnad)
    : formaterNullableBelop(betaling.sumUtestaendeKostnader);

  const betalteKostnader = isFlytting
    ? formaterNullableBelop(betaling.betaltFlyttekostnad)
    : formaterNullableBelop(betaling.betalt);

  return (
    <Kort
      headerText="Betaling"
      headerIcon={<Betalingsikon className="fill-darkblue" />}
      innhold={
        <>
          <div className="grid grid-cols-2 gap-6 mb-4">
            <div className="bg-darkblue border-2 border-darkblue p-3">
              <div className="text-white">{utstaendeKostnader}</div>
              <div className="text-white text-xs">Utestående</div>
            </div>
            <div className="bg-white border-2 border-darkblue p-3">
              <div>{betalteKostnader}</div>
              <div className="text-xs">Betalt</div>
            </div>
          </div>
          <div className="pb-4">
            <span className="osg-sans-bold">Utestående beløp</span>
            <div className="border-t-2 border-b-2 pt-4">
              <KeyValueKortInnhold
                keyValuePair={keyValuePairs}
                keyWidth="w-40"
                valueContainerClassName="text-right w-20"
              />
            </div>
          </div>
        </>
      }
    />
  );
};
