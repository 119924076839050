import { useState } from "react";
import { KlagevedtakType } from "../../../api/interfaces/Klage";
import { usePutVedtak } from "../../../hooks/useKlage";
import { useToast } from "../../common/toast/ToastProvider";
import { PktIcon } from "@oslokommune/punkt-react";

interface TilbakebetalingerCheckboxProps {
  klagevedtakId: string;
  erTilbakebetalt: boolean;
  tilbakebetalingUtfortAv?: string;
  tilbakebetalingStartet?: Date;
  queryCashkey: {
    vedtakstyper?: KlagevedtakType[];
    vedtakstidspunktFra?: Date;
    vedtakstidspunktTil?: Date;
  };
}

export const TilbakebetalingerCheckbox = ({
  klagevedtakId,
  erTilbakebetalt,
  tilbakebetalingUtfortAv,
  tilbakebetalingStartet,
  queryCashkey
}: TilbakebetalingerCheckboxProps) => {
  const [checked, setChecked] = useState<boolean>(erTilbakebetalt);

  const putVedtak = usePutVedtak(klagevedtakId, queryCashkey);
  const { addToast } = useToast();

  const handleCheckboxChange = () => {
    setChecked(true);
    putVedtak.mutate(undefined, {
      onSuccess: async () => {
        addToast("Vedtak er lagret som betalt");
      },
      onError: () => {
        setChecked(false);
        addToast("Vedtak ble ikke lagret som betalt", "error");
      }
    });
  };

  const tilbakebetalingStartetString = tilbakebetalingStartet
    ? new Date(tilbakebetalingStartet).toLocaleString()
    : "";

  return (
    <button
      id={klagevedtakId}
      title={
        erTilbakebetalt
          ? `Registrert av ${tilbakebetalingUtfortAv}\n${tilbakebetalingStartetString}`
          : "Trykk for å registrer tilbakebetaling"
      }
      onClick={checked ? undefined : handleCheckboxChange}
      className={`h-7 w-7 flex align-middle justify-center rounded-full border-2 border-lime-900 ${
        checked ? "bg-lime-900" : "bg-transparent"
      }`}
    >
      {checked ? (
        <PktIcon
          name="check-medium"
          className="[&_*]:fill-white w-full h-full"
        />
      ) : null}
    </button>
  );
};
