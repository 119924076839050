import { ReactComponent as AlertError } from "../../../assets/icons/alert-error.svg";
import { ReactComponent as AlertWarning } from "../../../assets/icons/alert-warning.svg";
import { ReactComponent as AlertSuccess } from "../../../assets/icons/alert-success.svg";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import React, { useState } from "react";

type AlertLevel = "success" | "warning" | "error";

interface AlertBoxProps {
  alertLevel: AlertLevel;
  heading: React.ReactChild;
  paragraph?: React.ReactChild;
  closable?: boolean;
  width?: "small" | "medium" | "large";
}

interface AlertBoxSmallProps {
  alertLevel: AlertLevel;
  heading: React.ReactChild;
}

const getIkon = (alertLevel: AlertLevel) => {
  const className = "h-6 mr-2";

  switch (alertLevel) {
    case "success":
      return <AlertSuccess className={className} />;
    case "warning":
      return <AlertWarning className={className} />;
    case "error":
      return <AlertError className={className} />;
  }
};

const getColor = (alertLevel: AlertLevel) => {
  switch (alertLevel) {
    case "success":
      return "bg-green-100 text-green-700 border-green-700 fill-green-700";
    case "warning":
      return "bg-yellow-100 text-yellow-700 border-yellow-700 fill-yellow-700";
    case "error":
      return "bg-red-100 text-red-700 border-red-700 fill-red-700";
  }
};

const handleStopPropagation = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>
) => e.stopPropagation();

const getWidth = (width?: "small" | "medium" | "large") => {
  switch (width) {
    case "small":
      return "max-w-md";
    case "medium":
      return "max-w-xl";
    case "large":
      return "max-w-3xl";
    default:
      return "max-w-xl";
  }
};

export const AlertBox = (props: AlertBoxProps) => {
  const { alertLevel, heading, paragraph, closable = true, width } = props;

  const [open, setOpen] = useState<boolean>(true);

  const handleClose = () => setOpen(false);

  if (!open) return null;

  return (
    <div
      onClick={handleStopPropagation}
      className={`px-10 py-5 border-l-4 ${getColor(
        alertLevel
      )} flex flex-row justify-between ${getWidth(width)}`}
    >
      <div>
        <div className="flex flex-row font-semibold">
          {getIkon(alertLevel)}
          {heading}
        </div>
        {paragraph && <div className="mt-4">{paragraph}</div>}
      </div>
      {closable ? (
        <button className="flex justify-start" onClick={handleClose}>
          <Close className="h-3 w-3" />
        </button>
      ) : null}
    </div>
  );
};

export const AlertBoxSmall = (props: AlertBoxSmallProps) => {
  const { alertLevel, heading } = props;

  return (
    <div
      onClick={handleStopPropagation}
      className={`btn--small ${getColor(
        alertLevel
      )} flex flex-row justify-between max-w-md`}
    >
      <div className="flex flex-row font-semibold">
        {getIkon(alertLevel)}
        {heading}
      </div>
    </div>
  );
};
