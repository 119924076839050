import { useFieldArray, useForm } from "react-hook-form";
import { useAvstem } from "../../../hooks/useAvstemming";
import { handleNumberOrEmptyValueInput } from "../../../utils/keyboardUtils";
import { AlertBox } from "../../common/form/AlertBox";
import { RequiredLabel } from "../../common/form/RequiredLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "../../common/toast/ToastProvider";
import { useEffect } from "react";
import { Felter, getFieldsFromZRapporter } from "./kasseavstemmingUtils";
import { kasseavstemmingSchema } from "./kasseavstemmingSchema";
import { ZRapportViewModel } from "../../../api/interfaces/Avstemming";
import { isSaksbehandler } from "../../../utils/userRolesUtils";

interface KasseavstemmingSkjemaProps {
  zRapporter: ZRapportViewModel[];
  inkluderteBetalingsnummer: number[];
  avstemtTil: Date;
  erDifferanse: boolean;
}

export const KasseavstemmingSkjema = (props: KasseavstemmingSkjemaProps) => {
  const { zRapporter, inkluderteBetalingsnummer, avstemtTil, erDifferanse } =
    props;

  const { addToast } = useToast();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<Felter>({
    defaultValues: {
      zRapporter: getFieldsFromZRapporter(zRapporter),
      avvikskommentar: !erDifferanse ? "[Ingen differanse]" : ""
    },
    resolver: yupResolver(kasseavstemmingSchema),
    mode: "onSubmit",
    shouldFocusError: true,
    shouldUseNativeValidation: true
  });

  const { fields, replace } = useFieldArray({
    name: "zRapporter",
    control
  });

  const postAvstemming = useAvstem();

  useEffect(() => {
    replace(getFieldsFromZRapporter(zRapporter));
  }, [replace, zRapporter]);

  const onSubmit = (felter: Felter) => {
    postAvstemming.mutate(
      {
        avstemming: {
          avstemtTil,
          inkluderteBetalingsnummer,
          zRapporter: felter.zRapporter.map(i => {
            return {
              nummer: parseInt(i.zNummer),
              sesjonsnummer: parseInt(i.sesjonsnummer)
            };
          }),
          avvikskommentar: felter.avvikskommentar
        }
      },
      {
        onSuccess: () => {
          addToast("Kasseavstemming gjennomført");
        },
        onError: () => {
          addToast("Kunne ikke gjennomføre kasseavstemming", "error");
        }
      }
    );
  };

  const antallFeilSesjonsnumre = errors.zRapporter
    ? Object.entries(errors.zRapporter).reduce(
        (prev, curr) => prev + (!!curr[1] ? 1 : 0),
        0
      )
    : 0;

  const antallFeilISkjema =
    (errors.avvikskommentar ? 1 : 0) + antallFeilSesjonsnumre;

  return (
    <form className="ods-form" onSubmit={handleSubmit(onSubmit)}>
      {zRapporter.length === 0 && (
        <div className="mb-5">
          <AlertBox
            alertLevel="warning"
            heading="Finner ingen uavstemte kasseoppgjør for denne perioden"
          />
        </div>
      )}
      {antallFeilISkjema > 0 && (
        <div className="mb-5">
          <AlertBox
            alertLevel="error"
            heading={<h3>{antallFeilISkjema + " feil i felter"}</h3>}
            paragraph={
              <>
                {errors.avvikskommentar && (
                  <p>{errors.avvikskommentar.message}</p>
                )}
                {errors.zRapporter && (
                  <p>{`Fyll inn sesjonsnr for resterende ${antallFeilSesjonsnumre} felter`}</p>
                )}
              </>
            }
          />
        </div>
      )}
      {postAvstemming.isError && (
        <div className="mb-5">
          <AlertBox
            alertLevel="error"
            heading="Fullføring av avstemming mislyktes"
            paragraph={
              <>
                <div>{(postAvstemming.error as Error).message}</div>
              </>
            }
          />
        </div>
      )}
      <>
        {erDifferanse && (
          <div className="ods-form-group">
            <label
              htmlFor="kommentar"
              className="ods-form-label flex flex-wrap"
            >
              Kommentar til differanse
              <RequiredLabel />
            </label>
            <div className="ods-form-help">Skriv inn årsak til differansen</div>
            <textarea
              data-testid="kasseavstemming-kommentar-avvik"
              className="ods-form-textarea max-w-sm h-40"
              autoComplete="off"
              {...register("avvikskommentar")}
            />
          </div>
        )}
        {fields.map((item, index) => (
          <div
            key={item.id}
            id="zRapporter-group"
            className="flex flex-row flex-wrap gap-x-5"
          >
            <div className="ods-form-group">
              <label
                htmlFor={`zRapporter.${index}.zNummer`}
                className="ods-form-label"
              >
                Z-rapport nr
              </label>
              <div className="ods-form-help">Autoinnfylt fra kasse</div>
              <input
                className="ods-form-input"
                {...register(`zRapporter.${index}.zNummer` as const)}
                disabled
              />
            </div>
            <div className="ods-form-group">
              <label
                htmlFor={`zRapporter.${index}.sesjonsnummer`}
                className="ods-form-label flex flex-wrap"
              >
                Sesjonsnr
                <RequiredLabel />
              </label>
              <div className="ods-form-help">
                Fyll inn sesjonsnr fra bax-kvittering
              </div>
              <input
                data-testid={`kasseavstemming-sesjonsnummer-${index}`}
                {...register(`zRapporter.${index}.sesjonsnummer` as const)}
                className={`ods-form-input ${
                  errors.zRapporter?.[index]?.sesjonsnummer
                    ? "border-errorred focus:border-errorred"
                    : ""
                }`}
                onKeyDown={handleNumberOrEmptyValueInput}
                autoComplete="off"
              />
            </div>
          </div>
        ))}
      </>
      {isSaksbehandler() && <button
        data-testid="kasseavstemming-arkiver-avstemming"
        type="submit"
        className="btn btn--primary mb-16 mt-5"
      >
        Fullfør avstemming
      </button>}
    </form>
  );
};
