import { apiClient } from "../client";
import { KjoretoyleveranseViewModel } from "../interfaces/Inntauing";
import { config } from "./Config";

const client = apiClient();

export const putKjoretoyleveranse = (
  id: string,
  kjoretoyleveranse: KjoretoyleveranseViewModel
) =>
  client.put<KjoretoyleveranseViewModel>(
    `kjoretoyleveranse/${id}`,
    kjoretoyleveranse,
    config()
  );

export const putAvbrytFremmote = (id: string) =>
  client.put<KjoretoyleveranseViewModel>(
    `kjoretoyleveranse/${id}/avbrytfremmote`,
    undefined,
    config()
  );

export const putAngreAnkomst = (id: string) =>
  client.put<KjoretoyleveranseViewModel>(
    `kjoretoyleveranse/${id}/angreankomst`,
    undefined,
    config()
  );

export const putAngreFlyttetPaaGategrunn = (id: string) =>
  client.put<KjoretoyleveranseViewModel>(
    `kjoretoyleveranse/${id}/angreflyttetpaagategrunn`,
    undefined,
    config()
  );

export const postKjoretoyleveranse = (
  id: string,
  kjoretoyleveranse: KjoretoyleveranseViewModel
) =>
  client.post<KjoretoyleveranseViewModel>(
    `kjoretoyleveranse/${id}`,
    kjoretoyleveranse,
    config()
  );
