import { StatuskodeMapper } from "../../api/interfaces/Inntauing";
import { HandlingsloggEntryViewModel } from "../../api/interfaces/Notat";
import { toLocaleString } from "../../utils/dateUtils";

type HandlingsloggListProps = {
  handlingslogg?: HandlingsloggEntryViewModel[];
  disableMaxHeight?: boolean;
};

const HandlingsloggList = (props: HandlingsloggListProps) => {
  const { handlingslogg, disableMaxHeight } = props;

  if (handlingslogg?.length === 0) {
    return <div>Ingen kommentarer</div>;
  }

  return (
    <div className={`overflow-x-auto ${disableMaxHeight ? "" : "max-h-80"}`}>
      <table className="table-auto w-full px-4">
        <thead className="sticky top-0 bg-rusticyellow">
          <tr>
            <th className="px-1">Status</th>
            <th>Registrert</th>
            <th>Tidspunkt</th>
            <th className="w-5/12">Kommentar</th>
          </tr>
        </thead>
        <tbody>
          <>
            {handlingslogg?.map((h, i) => {
              return (
                <tr
                  key={i}
                  className={`${
                    h.kommentar ? "bg-white" : "bg-rusticbeige"
                  } h-12 border-b-2 border-rusticbeige`}
                >
                  <td className="px-1">
                    {h.kommentar ? "" : StatuskodeMapper[h.status].navn}
                  </td>
                  <td>{h.registrertAv && h.registrertAv.replace("bym", "")}</td>
                  <td>{toLocaleString(new Date(h.tidspunkt))}</td>
                  <td data-testid={`notat-tekst-${i}`}>{h.kommentar}</td>
                </tr>
              );
            })}
          </>
        </tbody>
      </table>
    </div>
  );
};

export default HandlingsloggList;
