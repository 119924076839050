import { endOfDay, previousMonday, previousSunday, startOfDay } from "date-fns";
import { useStateParams } from "../../../hooks/useStateParams";
import { useDeaktiverteInntauinger } from "../../../hooks/useInntauinger";
import { HeadingView } from "../../common/typography/HeadingView";
import { FraTilDato } from "../../common/form/FraTilDato";
import { HeadingTopic } from "../../common/typography/HeadingTopic";
import { LoadingSpinner } from "../../common/loading/LoadingSpinner";
import { CustomError } from "../../common/typography/CustomError";
import { AlertBox } from "../../common/form/AlertBox";
import { InntauingViewModel } from "../../../api/interfaces/Inntauing";
import { useGetHandlingslogg } from "../../../hooks/useHandlingslogg";
import { HandlingsloggEntryViewModel } from "../../../api/interfaces/Notat";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { ReactComponent as Print } from "../../../assets/icons/print.svg";
import { HeadingUtskrift } from "../../common/typography/HeadingUtskrift";

export const DeaktiverteInntauinger = () => {
  const forrigeMandag = previousMonday(previousSunday(startOfDay(new Date())));
  const sistSondag = previousSunday(endOfDay(new Date()));

  const [fraDato, setFraDato] = useStateParams(
    forrigeMandag,
    "fraBestillingsdato",
    s => s.toISOString(),
    s => new Date(s)
  );
  const [tilDato, setTilDato] = useStateParams(
    sistSondag,
    "tilBestillingsdato",
    s => s.toISOString(),
    s => new Date(s)
  );

  const { data, isLoading, isError, error } = useDeaktiverteInntauinger(
    fraDato,
    tilDato
  );

  const componentPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    pageStyle: "m-5"
  });

  return (
    <>
      <HeadingView>Deaktiverte inntauinger</HeadingView>
      <FraTilDato
        actionButtons={
          <button
            type="button"
            className="btn btn--primary-confirm btn--icon-left mb-5 mt-auto"
            onClick={handlePrint}
          >
            <Print className="fill-current" />
            <span>Skriv ut</span>
          </button>
        }
        fraDato={fraDato}
        fraDatoLabel="Fra bestillingsdato"
        tilDato={tilDato}
        tilDatoLabel="Til bestillingsdato"
        handleChangeFraDato={setFraDato}
        handleChangeTilDato={setTilDato}
      />
      <HeadingTopic>Deaktiverte inntauinger for gitt periode</HeadingTopic>
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      {data && (
        <>
          {data.length === 0 ? (
            <div className="mt-5">
              <AlertBox
                alertLevel="warning"
                heading="Fant ingen deaktiverte inntauinger for gitt periode"
              />
            </div>
          ) : (
            <>
              <DeaktiverteInntauingerTabell inntauinger={data} />
              <div className="hidden">
                <div className="text-xs" ref={componentPrintRef}>
                  <HeadingUtskrift>{`Deaktiverte inntauinger for ${fraDato.toLocaleDateString()} - ${tilDato.toLocaleDateString()}`}</HeadingUtskrift>
                  <DeaktiverteInntauingerTabell inntauinger={data} />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

interface DeaktiverteInntauingerTabellProps {
  inntauinger: InntauingViewModel[];
}

const DeaktiverteInntauingerTabell = (
  props: DeaktiverteInntauingerTabellProps
) => {
  return (
    <table className="tbl mt-3 mb-10">
      <thead>
        <tr>
          <th>Bestillingstidspunkt</th>
          <th title="Ileggelsesnummer">Ileggelsesnr</th>
          <th title="Registreringsnummer">Reg.nr</th>
          <th title="Journalnummer">Journalnr</th>
          <th title="Tidspunkt for deaktivering">Deaktivert tidspunkt</th>
          <th title="Bruker som deaktiverte">Deaktivert av</th>
          <th title="Årsak til deaktivering">Årsak</th>
        </tr>
      </thead>
      <tbody>
        {props.inntauinger.map(i => (
          <DeaktiverteInntauingerRad key={i.id} inntauing={i} />
        ))}
      </tbody>
    </table>
  );
};

interface DeaktiverteInntauingerRadProps {
  inntauing: InntauingViewModel;
}

const DeaktiverteInntauingerRad = (props: DeaktiverteInntauingerRadProps) => {
  const { inntauing } = props;

  const {
    data: handlingslogg,
    isLoading,
    isError
  } = useGetHandlingslogg(inntauing.id);

  const { deaktivertTidspunkt, deaktivertAv, deaktiveringBegrunnelse } =
    getDeaktiveringInfo(handlingslogg);

  if (isLoading || isError) return null;

  return (
    <tr>
      <td>
        {new Date(
          inntauing.inntauingsgrunnlag.bestillingstidspunkt
        ).toLocaleString()}
      </td>
      <td>{inntauing.ileggelse?.ileggelsesnummer}</td>
      <td>{inntauing.kjoretoy.registreringsnummer}</td>
      <td>
        {inntauing.kjoretoyleveranse?.journalnummer
          ? `#${inntauing.kjoretoyleveranse.journalnummer}`
          : ""}
      </td>
      <td>
        {deaktivertTidspunkt
          ? new Date(deaktivertTidspunkt).toLocaleString()
          : ""}
      </td>
      <td>{deaktivertAv}</td>
      <td>{deaktiveringBegrunnelse}</td>
    </tr>
  );
};

const getDeaktiveringInfo = (handlingslogg?: HandlingsloggEntryViewModel[]) => {
  const infoHandlingslogg = handlingslogg?.find(h =>
    h.kommentar.toLowerCase().includes("[inntauing deaktivert]")
  );

  return {
    deaktivertTidspunkt: infoHandlingslogg?.tidspunkt
      ? new Date(infoHandlingslogg.tidspunkt)
      : undefined,
    deaktivertAv: infoHandlingslogg?.registrertAv,
    deaktiveringBegrunnelse: infoHandlingslogg?.kommentar
      .toLowerCase()
      .replace("[inntauing deaktivert] ", "")
      .replaceAll('"', "")
  };
};
