import { useMutation, useQueryClient } from "react-query";
import { postNotat } from "../api/apiServices/NotatService";
import { OpprettNotatInputModel } from "../api/interfaces/Notat";
import { CACHE_KEYS } from "../utils/globalConstants";

export const usePostNotat = (inntauingsId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (notat: OpprettNotatInputModel) => postNotat(inntauingsId, notat),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([CACHE_KEYS.Handlingslogg]);
      }
    }
  );
};
