import { endOfMonth, startOfMonth } from "date-fns";
import { useFakturaRegnskap } from "../../../hooks/useRegnskap";
import { useStateParams } from "../../../hooks/useStateParams";
import { formaterNullableBelop } from "../../../utils/formatUtils";
import { FraTilDato } from "../../common/form/FraTilDato";
import { LoadingSpinner } from "../../common/loading/LoadingSpinner";
import { CustomError } from "../../common/typography/CustomError";
import { HeadingView } from "../../common/typography/HeadingView";
import { ReactComponent as Download } from "../../../assets/icons/download.svg";
import { download_table_as_csv } from "../../../utils/csvTableGenerator";
import { OversendteFakturaer } from "./OversendteFakturaer";

export const Fakturainntekter = () => {
  const iDag = new Date();

  const foersteDagIMaaneden = startOfMonth(
    new Date(iDag.getFullYear(), iDag.getMonth() - 1, iDag.getDate())
  );
  const nesteMidnatt = endOfMonth(
    new Date(iDag.getFullYear(), iDag.getMonth() - 1, iDag.getDate())
  );

  const [fraDato, setFraDato] = useStateParams(
    foersteDagIMaaneden,
    "fra",
    s => s.toISOString(),
    s => new Date(s)
  );
  const [tilDato, setTilDato] = useStateParams(
    nesteMidnatt,
    "til",
    s => s.toISOString(),
    s => new Date(s)
  );

  const { data, isLoading, isError, error } = useFakturaRegnskap(
    fraDato,
    tilDato
  );

  return (
    <>
      <HeadingView>Fakturainntekter</HeadingView>
      <FraTilDato
        actionButtons={
          <button
            type="button"
            className="btn btn--primary-confirm btn--icon-left mb-5 mt-auto"
            onClick={() =>
              download_table_as_csv(
                "fakturainntekt",
                `Fakturainntekt_${fraDato.toLocaleDateString()}_til_${tilDato.toLocaleDateString()}`
              )
            }
          >
            <Download className="fill-current" />
            <span>Last ned</span>
          </button>
        }
        fraDato={fraDato}
        tilDato={tilDato}
        handleChangeFraDato={setFraDato}
        handleChangeTilDato={setTilDato}
      />
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      {data && (
        <div className="flex flex-col gap-10 mb-16">
          <div className="flex flex-row flex-wrap gap-10 h-fit">
            <div className="border-rusticyellow border-2 px-3 py-2">
              <h2 className="font-semibold leading-8">Inntekter fra faktura</h2>
              <table id="fakturainntekt" className="w-96 text-left">
                <thead className="font-semibold border-b-2 border-gray-300">
                  <tr>
                    <th>Totalt</th>
                    <th />
                    <th className="text-right">
                      {formaterNullableBelop(data.inntekter.total)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>16237</td>
                    <td title="Administrasjonsgebyr">Admin.gebyr</td>
                    <td className="text-right">
                      {formaterNullableBelop(
                        data.inntekter.administrasjonsgebyr
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>16236</td>
                    <td>Døgnleie</td>
                    <td className="text-right">
                      {formaterNullableBelop(data.inntekter.dognleie)}
                    </td>
                  </tr>
                  <tr>
                    <td>16235</td>
                    <td>Inntauing</td>
                    <td className="text-right">
                      {formaterNullableBelop(data.inntekter.inntauingsgebyr)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <OversendteFakturaer fakturaer={data.fakturaer} />
        </div>
      )}
    </>
  );
};
