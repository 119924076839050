export const checkDateBiggerThanOrEqual = (a: Date, b: Date) => {
  if (a.getFullYear() > b.getFullYear()) return true;
  if (a.getMonth() > b.getMonth()) return true;
  if (a.getDate() >= b.getDate()) return true;
  return false;
};

export const deepEqual = (obj1: any, obj2: any): boolean => {
  if (
    obj1 === null ||
    obj2 === null ||
    typeof obj1 !== "object" ||
    typeof obj2 !== "object"
  ) {
    return obj1 === obj2;
  }
  if (obj1 instanceof Date || obj2 instanceof Date) { // check if either object is a Date
    return obj1 instanceof Date && obj2 instanceof Date && obj1.getTime() === obj2.getTime();
  }
  if (typeof obj1 === "object" && typeof obj2 === "object") {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (let prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false;
      }
    }
    return true;
  } else if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    for (let i = 0; i < obj1.length; i++) {
      if (!deepEqual(obj1[i], obj2[i])) {
        return false;
      }
    }
    return true;
  } else {
    return obj1 === obj2;
  }
};
