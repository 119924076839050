import { AvsluttetInntauingerTabell } from "./AvsluttetInntauingerTabell";

export const AvsluttetInntauinger = () => {
  return (
    <div className="flex flex-row h-full">
      <div className="mr-10 oslo-inntauing-visning ml-[3rem]">
        <AvsluttetInntauingerTabell />
      </div>
    </div>
  );
};
