import { useState } from "react";

interface ToggleProps {
  id?: string;
  value?: boolean;
  title?: string;
  leftText: string;
  rightText: string;
  disabled?: boolean;
  testid?: string;
  toggleFunction: (e: any) => void;
}

export const Toggle = (props: ToggleProps) => {
  const {
    id,
    value,
    testid,
    title,
    leftText,
    rightText,
    disabled,
    toggleFunction
  } = props;

  const [enabled, setEnabled] = useState(false);

  const handleToggleEnterKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (disabled) return;
    if (e.key === "Enter") {
      toggleFunction(enabled);
      setEnabled(!(value ?? enabled));
    }
  };

  const handleToggle = () => {
    if (disabled) return;
    toggleFunction(enabled);
    setEnabled(!(value ?? enabled));
  };

  return (
    <div className="flex">
      <label
        className={`inline-flex relative items-center mr-5 ${
          disabled ? "cursor-default" : "cursor-pointer"
        }`}
      >
        <span
          className={`mr-2 text-sm ${
            !(value ?? enabled) ? "font-semibold" : ""
          } text-gray-900 text-center`}
        >
          {leftText}
        </span>
        <input
          id={id}
          type="checkbox"
          className="sr-only peer"
          checked={value ?? enabled}
          disabled={disabled}
          readOnly
          tabIndex={-1}
        />
        <div
          data-testid={testid ?? ""}
          title={title}
          tabIndex={disabled ? -1 : 0}
          onKeyDown={handleToggleEnterKey}
          onClick={handleToggle}
          contentEditable={disabled ? "false" : undefined}
          className={`w-10 h-6 ${
            disabled
              ? "block bg-clip-padding relative bg-gray-200 after:bg-gray-500"
              : "ods-form-input bg-white border-darkblue after:bg-darkblue"
          } rounded-full peer-checked:after:translate-x-full after:absolute after:bottom-[0.185rem] after:left-[0.155rem] after:rounded-full after:h-4 after:w-4 after:transition-all`}
        ></div>
        <span
          className={`ml-2 text-sm ${
            value ?? enabled ? "font-semibold" : ""
          } text-gray-900 text-center`}
        >
          {rightText}
        </span>
      </label>
    </div>
  );
};
