import { useNasjonskoder } from "../../../hooks/useGrunndata";

interface KolonneNasjonskodeProps {
  nasjonskode: string;
}

export const KolonneNasjonskode = (props: KolonneNasjonskodeProps) => {
  const { nasjonskode } = props;

  const { data: nasjonskoder } = useNasjonskoder();

  return (
    <div
      className="flex h-full w-full items-center"
      title={
        nasjonskoder?.find(n => n.kode === nasjonskode)?.navn ?? nasjonskode
      }
    >
      {nasjonskode}
    </div>
  );
};

interface KolonneNasjonskodeTdProps {
  nasjonskode: string;
}

export const KolonneNasjonskodeTd = (props: KolonneNasjonskodeTdProps) => {
  const { nasjonskode } = props;

  const { data: nasjonskoder } = useNasjonskoder();

  return (
    <td
      title={
        nasjonskoder?.find(n => n.kode === nasjonskode)?.navn ?? nasjonskode
      }
    >
      {nasjonskode}
    </td>
  );
};
