import { Kort } from "../common/card/Kort";
import { ReactComponent as Inntauing } from "../../assets/icons/inntauing.svg";
import { KeyValueKortInnhold } from "../common/card/KeyValueKortInnhold";
import {
  keyValuePairsStatusAuksjonert,
  keyValuePairsStatusFremmote,
  keyValuePairsStatusHentet,
  keyValuePairsStatusVraket,
  keyValueParisStatusFlyttet
} from "./avsluttetInntauingerKeyValuePairsInntauingsInformasjon";
import {
  InntauingViewModel,
  StatuskodeType
} from "../../api/interfaces/Inntauing";

interface AvsluttetInntauingInntauingsInfoProps {
  inntauing: InntauingViewModel;
}

export const AvsluttetInntauingInntauingsInfo = (
  props: AvsluttetInntauingInntauingsInfoProps
) => {
  const { inntauing } = props;

  const getKeyValuePairsByStatus = () => {
    switch (inntauing.status) {
      case StatuskodeType.Hentet:
        return keyValuePairsStatusHentet(inntauing);
      case StatuskodeType.Fremmote:
        return keyValuePairsStatusFremmote(inntauing);
      case StatuskodeType.FlyttetPaaGategrunn:
        return keyValueParisStatusFlyttet(inntauing);
      case StatuskodeType.Vraket:
        return keyValuePairsStatusVraket(inntauing);
      case StatuskodeType.Auksjonert:
        return keyValuePairsStatusAuksjonert(inntauing);
      default:
        return [];
    }
  };

  return (
    <Kort
      headerText="Inntauingsinfo"
      headerIcon={<Inntauing className="fill-darkblue" />}
      innhold={
        <>
          <KeyValueKortInnhold
            keyValuePair={getKeyValuePairsByStatus()}
            keyWidth="w-40"
          />
          <div className="mt-5 py-2"></div>
        </>
      }
    />
  );
};
