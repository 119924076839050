import React from "react";
import Collapsible from "../collapsible/Collapsible";

export interface SelectArrayObject {
  id: number | number[] | string; // OBS When using number[]. Make sure no other Select buttons contains the same number.
  name: string;
}

interface SelectArrayProps {
  id: string;
  value?: number[] | string[];
  alternatives?: SelectArrayObject[];
  collapsible?: boolean;
  isLoading?: boolean;
  onChange: (values: number[] | string[]) => void;
}

export const SelectArray = (props: SelectArrayProps) => {
  const { id, collapsible } = props;

  if (collapsible) {
    return (
      <Collapsible id={id} title={<Title {...props} />}>
        <Alternatives {...props} />
      </Collapsible>
    );
  } else {
    return (
      <Alternatives {...props} />
    );
  }
  
};

const selectedButtonClassNames = "bg-hoverblue fill-white text-white border-hoverblue hover:bg-hoverblue hover:border-hoverblue focus:bg-hoverblue focus:border-hoverblue active:bg-hoverblue active:border-hoverblue disabled:bg-gray-100 disabled:border-gray-100 disabled:text-gray-400 disabled:fill-gray-400";

// OBS When using number[]. Make sure no other Select buttons contains the same number.
const Title = (props: SelectArrayProps) => {
  const { id, value, alternatives, isLoading, onChange } = props;

  const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      
      const tempAlternatives = alternatives ?? [];
      let alternativesIdsCount = 0;
      let flatAlternativesIds: number[] | string[] = [];
      for (let index = 0; index < tempAlternatives.length; index++) {
        const alternative = tempAlternatives[index];
        
        if (Array.isArray(alternative.id) && alternative.id.length > 0 && typeof alternative.id[0] === "number") {
          alternativesIdsCount += alternative.id.length;
          flatAlternativesIds = [...flatAlternativesIds as number[], ...alternative.id as number[]];
        } else if (typeof alternative.id === "number") {
          alternativesIdsCount += 1;
          flatAlternativesIds = [...flatAlternativesIds as number[], alternative.id];
        } else {
          alternativesIdsCount += 1;
          flatAlternativesIds = [...flatAlternativesIds as string[], alternative.id as string];
        }
      }

      onChange(
        value?.length === alternativesIdsCount
          ? []
          : flatAlternativesIds
      );
  }

  return (
    <>
      <div className="mb-2">
        <button
          type="button"
          className={`btn btn--small ${
            value?.length === alternatives?.length
              ? selectedButtonClassNames
              : "btn--secondary"
          }`}
          disabled={isLoading}
          onClick={handleClick}
        >
          {isLoading ? `Laster ${id}` : "Hent alle"}
        </button>
      </div>
    </>
  );
};

// OBS When using id as number[]. Make sure no other Select buttons contains the same number.
const Alternatives = (props: SelectArrayProps) => {
  const { id, value, alternatives, onChange } = props;

  const handleClick = (isSelected: boolean | undefined, altId: number | number[] | string) => {
    if (Array.isArray(altId)) {
      let tempValue = [...value ?? []];
      for (let index = 0; index < altId.length; index++) {
        const element = altId[index];
        if (isSelected) {
          tempValue = tempValue.filter(tv => tv !== element);
        } else {
          tempValue = [...(tempValue ?? []), element];
        }
      }
      onChange(tempValue as number[]);
    } else {
      if (typeof altId === "number") {
        onChange(
          isSelected
            ? (value as number[] ?? []).filter(v => v !== altId as number)
            : [...(value as number[] ?? []), altId as number]
        )
      } else if (typeof altId === "string") {
        onChange(
          isSelected
            ? (value as string[] ?? []).filter(v => v !== altId as string)
            : [...(value as string[] ?? []), altId as string]
        )
      }
    }
  };
  
  return (
    <div className="flex flex-row flex-wrap gap-2">
      {alternatives?.map(a => {
        let isSelected: boolean | undefined = false;
        if (Array.isArray(a.id)) {
          for (let index = 0; index < a.id.length; index++) {
            const element = a.id[index];
            if (value?.some(v => v === element)) {
              isSelected = true;
              continue;
            }
            isSelected = false;
            break;
          }
        } else {
          isSelected = value?.some(v => v === a.id);
        }

        return (
          <button
            key={`${id}${a.id}`}
            data-testid={`select-array-button-${id}-${a.id}`}
            id={`select-array-button-${id}-${a.id}`}
            type="button"
            onClick={() => handleClick(isSelected, a.id)}
            className={`btn btn--small ${
              isSelected ? selectedButtonClassNames : "btn--secondary"
            }`}
          >
            {a.name}
          </button>
        );
      })}
    </div>
  );
};
