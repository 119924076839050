import { useState } from "react";
import { InntauingerTabell } from "./InntauingerTabell";
import { RegistrerAnkommet } from "../registrerAnkommet/RegistrerAnkommet";
import { RegistrerFlyttetPaaGategrunn } from "../registrerFlyttetPaaGategrunn/RegistrerFlyttetPaaGategrunn";
import { HANDLINGER } from "../common/handlingsknapp/handlingsknappUtils";
import { OpprettFlagg } from "../opprettFlagg/OpprettFlagg";
import { FjernFlagg } from "../fjernFlagg/FjernFlagg";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { DeaktiverInntauing } from "../deaktiverInntauing/DeaktiverInntauing";
import { TillatUtleveringUtenBetaling } from "../tillatUtleveringUtenBetaling/TillatUtleveringUtenBetaling";
import { TillatUtleveringMedRedusertDognleie } from "../tillatUtleveringMedRedusertDognleie/TillatUtleveringMedRedusertDognleie";

export const Inntauinger = () => {
  const [visHandling, setVisHandling] = useState<{
    inntauing?: InntauingViewModel;
    handling?: string;
  }>({});

  const lukkHandling = () =>
    setVisHandling({ inntauing: undefined, handling: undefined });

  console.log("Inntauinger visHandling.handling: ", visHandling.handling);
  console.log(
    "Inntauinger visHandling.inntauing?.kjoretoyleveranse: ",
    visHandling.inntauing?.kjoretoyleveranse
  );

  if (visHandling.inntauing) {
    return (
      <div className="flex flex-col h-full my-5 mx-5 text-left">
        {visHandling.handling === HANDLINGER.Registrer_ankommet &&
          !visHandling.inntauing.kjoretoyleveranse && (
            <RegistrerAnkommet
              inntauing={visHandling.inntauing}
              close={lukkHandling}
            />
          )}
        {visHandling.handling === HANDLINGER.Registrer_flyttet_på_gategrunn &&
          !visHandling.inntauing.kjoretoyleveranse && (
            <RegistrerFlyttetPaaGategrunn
              inntauing={visHandling.inntauing}
              close={lukkHandling}
            />
          )}
        {visHandling.handling ===
          HANDLINGER.Tillat_utlevering_uten_betaling && (
          <TillatUtleveringUtenBetaling
            inntauing={visHandling.inntauing}
            close={lukkHandling}
          />
        )}
        {visHandling.handling ===
          HANDLINGER.Tillat_utlevering_med_redusert_dognleie && (
          <TillatUtleveringMedRedusertDognleie
            inntauing={visHandling.inntauing}
            close={lukkHandling}
          />
        )}
        {visHandling.handling === HANDLINGER.Opprett_flagg && (
          <OpprettFlagg
            inntauing={visHandling.inntauing}
            close={lukkHandling}
          />
        )}
        {visHandling.handling === HANDLINGER.Fjern_flagg &&
          visHandling.inntauing?.flagg &&
          visHandling.inntauing?.flagg?.filter(f => !f.fjernet).length > 0 && (
            <FjernFlagg
              inntauing={visHandling.inntauing}
              close={lukkHandling}
            />
          )}
        {visHandling.handling === HANDLINGER.Deaktiver_inntauing && (
          <DeaktiverInntauing
            inntauing={visHandling.inntauing}
            close={lukkHandling}
          />
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-row h-full">
      <div className="mr-10 oslo-inntauing-visning ml-[3rem]">
        <InntauingerTabell
          setVisHandling={(x, y) =>
            setVisHandling({ inntauing: x, handling: y })
          }
        />
      </div>
    </div>
  );
};
