import { useCallback } from "react";
import { DropZone } from "../common/form/dropzone/DropZone";
import { FileList } from "../common/form/dropzone/FileList";
import { RequiredLabel } from "../common/form/RequiredLabel";

interface FilOpplasterRegistrerAuksjonertSkjemaProps {
  feilPaaFiler?: boolean;
  filer: File[];
  setFiler: (filer: File[]) => void;
}

export const FilOpplasterRegistrerAuksjonertSkjema = (
  props: FilOpplasterRegistrerAuksjonertSkjemaProps
) => {
  const { feilPaaFiler, filer, setFiler } = props;

  const onFilesDrop = useCallback(
    (nyeFiler: File[]) => {
      setFiler([
        ...filer,
        ...nyeFiler.filter(
          i =>
            !filer.some(j => j.name === i.name) && i.type === "application/pdf"
        )
      ]);
    },
    [filer, setFiler]
  );

  return (
    <div className="my-5 ods-form-group">
      <label
        htmlFor="filopplaster"
        className="ods-form-label flex flex-row flex-wrap"
      >
        Velg relevante dokumenter
        <RequiredLabel />
      </label>
      <div className="ods-form-help">Kun PDF-filer tillatt</div>
      <div id="filopplaster">
        <DropZone
          dropZoneClassName={
            feilPaaFiler ? "border-errorred focus:border-errorred" : ""
          }
          onFilesDrop={onFilesDrop}
        />
        <div className="mt-2">
          <FileList files={filer} setFiles={setFiler} />
        </div>
      </div>
    </div>
  );
};
