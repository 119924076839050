import { getHostFromEnv } from "../../api/client";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { formaterNullableBelop } from "../../utils/formatUtils";
import { KolonneNasjonskodeTd } from "../common/table/KolonneNasjonskode";

interface ArkiverteAuksjonerTabellProps {
  auksjonsliste?: InntauingViewModel[];
}

export const ArkiverteAuksjonerTabell = (
  props: ArkiverteAuksjonerTabellProps
) => {
  const { auksjonsliste } = props;

  if (!auksjonsliste) return null;

  return (
    <table className="tbl">
      <thead>
        <tr>
          <th>Nasjonskode</th>
          <th>ID</th>
          <th>Type</th>
          <th>Merke</th>
          <th>Salgsdato</th>
          <th>Salgssum</th>
          <th>Websak</th>
        </tr>
      </thead>
      <tbody>
        {auksjonsliste.map(i => (
          <tr
            key={i.id}
            data-testid={`auksjon-arkiverte-rad-${i.kjoretoy.registreringsnummer}`}
          >
            <KolonneNasjonskodeTd nasjonskode={i.kjoretoy.nasjonalitet} />
            <td>
              {i.kjoretoyleveranse?.journalnummer
                ? "#" + i.kjoretoyleveranse?.journalnummer
                : i.kjoretoy.registreringsnummer}
            </td>
            <td>{i.kjoretoy.kjoretoystype.kode}</td>
            <td>{i.kjoretoy.merke}</td>
            <td>
              {i.auksjon?.salgsdato
                ? new Date(i.auksjon.salgsdato.toString()).toLocaleDateString()
                : "Mangler dato"}
            </td>
            <td>{formaterNullableBelop(i.auksjon?.salgsinntekt)}</td>
            <td className="underline">
              <a
                rel="noreferrer"
                target="_blank"
                href={`${getHostFromEnv("WEBSAK")}/${i.arkivReferanseId}`}
              >
                {i.arkivReferansenummer}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
