import React, { useEffect, useState, MouseEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { Filter } from "../../api/interfaces/Filter";

import { mapValueToName } from "../../utils/objectMapping";

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import {
  GetFilterAsQueryParams,
  GetQueryParamsAsFilter
} from "../../utils/filterUtils";

import {
  BetalingskravStatusFilterType,
  KJORETOY_NASJONSKODER_FILTRERING
} from "./InntauingFilter";
import {
  BrevtypeFilterNavn,
  BrevtypeFilterType
} from "../../api/interfaces/InntauingQueryParametersViewModel";
import {
  Kjoretoystype,
  KjoretoystypeMapper,
  StatuskodeMapper,
  StatuskodeType
} from "../../api/interfaces/Inntauing";
import { FlaggType, FlaggtypeMapper } from "../../api/interfaces/Flagg";
import {
  Betalingstype,
  BetalingstypeNavn
} from "../../api/interfaces/Betaling";

const chipButtonClassNames =
  "bg-hoverblue fill-white text-white border-hoverblue hover:bg-hoverblue hover:border-hoverblue focus:bg-hoverblue focus:border-hoverblue active:bg-hoverblue active:border-hoverblue disabled:bg-gray-100 disabled:border-gray-100 disabled:text-gray-400 disabled:fill-gray-400";

interface InntauingChipFilterProps {
  visChipFilterIDom: boolean;
  setFilter: (filter: Filter) => void;
}

export const InntauingChipFilter = React.memo(
  (props: InntauingChipFilterProps) => {
    const { visChipFilterIDom, setFilter } = props;

    const [searchParamsAsFilter, setSearchParamsAsFilter] = useState<Filter>(
      {}
    );

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
      setSearchParamsAsFilter(GetQueryParamsAsFilter(searchParams));
    }, [searchParams]);

    const onClickChipButton = (event: MouseEvent<HTMLButtonElement>) => {
      const {
        [event.currentTarget.name as keyof Filter]: _,
        ...newSearchParamsAsFilter
      } = searchParamsAsFilter;
      setSearchParams(GetFilterAsQueryParams(newSearchParamsAsFilter));
      setFilter(newSearchParamsAsFilter);
    };

    return (
      <div
        className={`flex flex-row flex-wrap gap-2${
          visChipFilterIDom ? "" : " hidden"
        }`}
      >
        {Object.keys(searchParamsAsFilter).map(k => {
          const value = searchParamsAsFilter[k as keyof Filter];
          if (value && (!Array.isArray(value) || value.length > 0)) {
            return (
              <button
                name={k}
                className={`btn btn--small btn--icon-right rounded-full ${chipButtonClassNames}`}
                onClick={onClickChipButton}
                key={`chip-filter-${k}`}
                data-testid={`chip-filter-${k}`}
              >
                <CloseIcon className="fill-current" />
                {getChipContent(k, value)}
              </button>
            );
          }
          return null;
        })}
      </div>
    );
  }
);

const getChipContent: any = (name: string, value: any) => {
  switch (name) {
    case "statuskoder":
      let chipValueStatuskoder = new Array<string>();
      for (let index = 0; index < value.length; index++) {
        const element = value[index];
        chipValueStatuskoder.push(
          StatuskodeMapper[element as StatuskodeType].navn
        );
      }

      return chipValueStatuskoder.toString().replaceAll(",", ", ");

    case "brevtyper":
      let chipValueBrevtyper = new Array<string>();
      for (let index = 0; index < value.length; index++) {
        const element = value[index];
        chipValueBrevtyper.push(
          BrevtypeFilterNavn[element as BrevtypeFilterType]
        );
      }

      return chipValueBrevtyper.toString().replaceAll(",", ", ");

    case "betalingsmetoder":
      let chipValueBetalingsmetoder = new Array<string>();
      for (let index = 0; index < value.length; index++) {
        const element = value[index];
        chipValueBetalingsmetoder.push(
          BetalingstypeNavn[element as Betalingstype]
        );
      }

      return chipValueBetalingsmetoder.toString().replaceAll(",", ", ");

    // TODO: Map Betalingskravstatus til navn ved bruk av record
    case "betalingskravStatuser":
      const propValueNumberArray = value as number[];
      let chipValueBetalingskravStatuser = new Array<string>();
      const muligeFiltreringerPaBetalingskravStatuser = Object.keys({
        ...BetalingskravStatusFilterType
      });

      for (
        let index = 0;
        index < muligeFiltreringerPaBetalingskravStatuser.length;
        index++
      ) {
        const betalingskravStatusKey =
          muligeFiltreringerPaBetalingskravStatuser[index];
        const betalingskravStatusValue =
          BetalingskravStatusFilterType[betalingskravStatusKey];

        let betalingskravStatusErValgt = false;
        if (Array.isArray(betalingskravStatusValue)) {
          betalingskravStatusErValgt = betalingskravStatusValue.every(element =>
            propValueNumberArray.includes(element)
          );
        } else {
          betalingskravStatusErValgt = propValueNumberArray.includes(
            betalingskravStatusValue
          );
        }

        if (betalingskravStatusErValgt) {
          chipValueBetalingskravStatuser.push(
            mapValueToName(
              BetalingskravStatusFilterType,
              betalingskravStatusValue
            )
          );
        } else {
          continue;
        }
      }

      return chipValueBetalingskravStatuser.toString().replaceAll(",", ", ");

    case "kjoretoyNasjoner":
      let chipValueKjoretoyNajonskoder = new Array<string>();
      for (let index = 0; index < value.length; index++) {
        const kjoretoyNasjonskode = value[index];

        chipValueKjoretoyNajonskoder.push(
          mapValueToName(KJORETOY_NASJONSKODER_FILTRERING, kjoretoyNasjonskode)
        );
      }

      return chipValueKjoretoyNajonskoder.toString().replaceAll(",", ", ");

    case "kjoretoystyper":
      let chipValueKjoretoystyper = new Array<string>();
      for (let index = 0; index < value.length; index++) {
        const kjoretoystype = value[index];

        chipValueKjoretoystyper.push(
          KjoretoystypeMapper[kjoretoystype as Kjoretoystype].navn
        );
      }

      return chipValueKjoretoystyper.toString().replaceAll(",", ", ");

    case "flaggTyper":
      let chipValueFlaggTyper = new Array<string>();
      for (let index = 0; index < value.length; index++) {
        const element = value[index];
        chipValueFlaggTyper.push(FlaggtypeMapper[element as FlaggType].navn);
      }

      return chipValueFlaggTyper.toString().replaceAll(",", ", ");

    case "datoFra":
      return value.toLocaleDateString();

    case "datoTil":
      return value.toLocaleDateString();

    default:
      return value;
  }
};
