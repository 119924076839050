import { useFormContext } from "react-hook-form";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { HeadingTopic } from "../common/typography/HeadingTopic";
import { FelterRedigerEierOgKjoretoyinfo } from "./redigerEierOgKjoretoyinfoUtils";

interface OppsummeringStegProps {
  inntauing: InntauingViewModel;
  feilmelding?: JSX.Element;
}

export const OppsummeringSteg = (props: OppsummeringStegProps) => {
  const { inntauing, feilmelding } = props;

  const { getValues } = useFormContext<FelterRedigerEierOgKjoretoyinfo>();

  const gammeltKjoretoy = inntauing.kjoretoy;
  const gammelEier = gammeltKjoretoy.juridiskEier;
  const gammelLeaser = gammeltKjoretoy.leasingtaker;
  const nyttKjoretoy = getValues();
  const nyEier = nyttKjoretoy.juridiskEier;
  const nyLeaser = nyttKjoretoy.leasingtaker;

  return (
    <>
      <div className="grid grid-cols-3 gap-y-3">
        <HeadingTopic className="col-span-3">Kjøretøy:</HeadingTopic>
        <OppsummeringStegLinje
          label="Nasjonalitet"
          gammelVerdi={gammeltKjoretoy.nasjonalitet}
          nyVerdi={nyttKjoretoy.nasjonalitet}
        />
        <OppsummeringStegLinje
          label="Registreringsnummer"
          gammelVerdi={gammeltKjoretoy.registreringsnummer}
          nyVerdi={nyttKjoretoy.registreringsnummer}
        />
        <OppsummeringStegLinje
          label="Rammenummer"
          gammelVerdi={gammeltKjoretoy.rammenummer}
          nyVerdi={nyttKjoretoy.rammenummer}
          dataTestid="kjoretoyinformasjon-rammenr"
        />
        <OppsummeringStegLinje
          label="Merke"
          gammelVerdi={gammeltKjoretoy.merke}
          nyVerdi={nyttKjoretoy.merke}
        />
        <OppsummeringStegLinje
          label="Modell"
          gammelVerdi={gammeltKjoretoy.modell}
          nyVerdi={nyttKjoretoy.modell}
          dataTestid="kjoretoyinformasjon-modell"
        />
        <OppsummeringStegLinje
          label="Kjøretøystype"
          gammelVerdi={gammeltKjoretoy.kjoretoystype.kode}
          nyVerdi={nyttKjoretoy.type}
        />
        <OppsummeringStegLinje
          label="Registreringsår"
          gammelVerdi={gammeltKjoretoy.registrertAar}
          nyVerdi={nyttKjoretoy.registrertAar}
        />
        <OppsummeringStegLinje
          label="Totalvekt"
          gammelVerdi={gammeltKjoretoy.totalvekt}
          nyVerdi={nyttKjoretoy.totalvekt}
          dataTestid="kjoretoyinformasjon-totalvekt"
        />
        <OppsummeringStegLinje
          label="Farge"
          gammelVerdi={gammeltKjoretoy.farge}
          nyVerdi={nyttKjoretoy.farge}
        />
        <HeadingTopic className="col-span-3">Juridisk eier:</HeadingTopic>
        <OppsummeringStegLinje
          label="ID-nummer"
          gammelVerdi={gammelEier?.idNummer}
          nyVerdi={nyEier?.idNummer}
        />
        <OppsummeringStegLinje
          label="Navn"
          gammelVerdi={gammelEier?.navn}
          nyVerdi={nyEier?.navn}
        />
        <OppsummeringStegLinje
          label="Telefonnummer"
          gammelVerdi={gammelEier?.telefonnummer}
          nyVerdi={nyEier?.telefonnummer}
        />
        <OppsummeringStegLinje
          label="Adresse"
          gammelVerdi={gammelEier?.gateadresse}
          nyVerdi={nyEier?.gateadresse}
        />
        <OppsummeringStegLinje
          label="Postnummer"
          gammelVerdi={gammelEier?.postnummer}
          nyVerdi={nyEier?.postnummer}
        />
        <OppsummeringStegLinje
          label="Poststed"
          gammelVerdi={gammelEier?.poststed}
          nyVerdi={nyEier?.poststed}
        />
        <OppsummeringStegLinje
          label="Leaset"
          gammelVerdi={gammeltKjoretoy?.leasingtaker ? "Ja" : "Nei"}
          nyVerdi={nyttKjoretoy?.leaser ? "Ja" : "Nei"}
        />
        <OppsummeringStegLinje
          label="Bedrift"
          gammelVerdi={gammelEier?.erPerson ? "Nei" : "Ja"}
          nyVerdi={nyEier?.bedrift ? "Ja" : "Nei"}
        />
        {(gammeltKjoretoy.leasingtaker || nyttKjoretoy.leaser) && (
          <>
            <HeadingTopic className="col-span-3">Leasingtaker:</HeadingTopic>
            <OppsummeringStegLinje
              label="ID-nummer"
              gammelVerdi={gammelLeaser?.idNummer}
              nyVerdi={nyLeaser?.idNummer}
            />
            <OppsummeringStegLinje
              label="Navn"
              gammelVerdi={gammelLeaser?.navn}
              nyVerdi={nyLeaser?.navn}
              dataTestid="leasingtaker-navn"
            />
            <OppsummeringStegLinje
              label="Telefonnummer"
              gammelVerdi={gammelLeaser?.telefonnummer}
              nyVerdi={nyLeaser?.telefonnummer}
              dataTestid="eier-telefon"
            />
            <OppsummeringStegLinje
              label="Adresse"
              gammelVerdi={gammelLeaser?.gateadresse}
              nyVerdi={nyLeaser?.gateadresse}
              dataTestid="eier-adresse"
            />
            <OppsummeringStegLinje
              label="Postnummer"
              gammelVerdi={gammelLeaser?.postnummer}
              nyVerdi={nyLeaser?.postnummer}
              dataTestid="eier-postnr"
            />
            <OppsummeringStegLinje
              label="Poststed"
              gammelVerdi={gammelLeaser?.poststed}
              nyVerdi={nyLeaser?.poststed}
              dataTestid="eier-poststed"
            />
            <OppsummeringStegLinje
              label="Bedrift"
              gammelVerdi={gammelLeaser?.erPerson ? "Nei" : "Ja"}
              nyVerdi={nyLeaser?.bedrift ? "Ja" : "Nei"}
            />
          </>
        )}
      </div>
      {feilmelding}
    </>
  );
};

interface OppsummeringStegLinjeProps {
  label: string;
  gammelVerdi?: string | number;
  nyVerdi?: string | number;
  dataTestid?: string;
}

const OppsummeringStegLinje = (props: OppsummeringStegLinjeProps) => {
  const { label, gammelVerdi, nyVerdi, dataTestid } = props;

  return (
    <>
      <div>{label}:</div>
      <div className="text-red-500 line-through">
        {gammelVerdi !== nyVerdi && gammelVerdi}
      </div>
      <div className="flex flex-row">
        {nyVerdi && nyVerdi.toString().length !== 0 && (
          <>
            <span data-testid={dataTestid}>{nyVerdi}</span>
            {!(gammelVerdi && gammelVerdi.toString().length !== 0) && (
              <span className="ml-1 italic">(ny verdi)</span>
            )}
          </>
        )}
      </div>
    </>
  );
};
