export enum FlaggType {
  Ukjent = 0,
  Varsel = 10,
  Klage = 20,
  Vilkaar = 30,
  UnderArbeid = 40,
  Mottakskontroll = 100
}

export type FlaggtypeMapperType = {
  order: number;
  statusnavn: string;
  navn: string;
};

export const FlaggtypeMapper: Record<FlaggType, FlaggtypeMapperType> = {
  [FlaggType.Varsel]: {
    order: 1,
    statusnavn: "Varsel",
    navn: "Varselflagg"
  },
  [FlaggType.Klage]: {
    order: 2,
    statusnavn: "Klage",
    navn: "Klageflagg"
  },
  [FlaggType.UnderArbeid]: {
    order: 3,
    statusnavn: "Under arbeid",
    navn: "Under arbeid-flagg"
  },
  [FlaggType.Vilkaar]: {
    order: 4,
    statusnavn: "Vilkår",
    navn: "Vilkårflagg"
  },
  [FlaggType.Mottakskontroll]: {
    order: 5,
    statusnavn: "Mottakskontroll",
    navn: "Mottakskontrollflagg"
  },
  [FlaggType.Ukjent]: {
    order: 6,
    statusnavn: "Ukjent",
    navn: "Ukjent"
  }
};

export interface FlaggViewModel {
  id: string;
  type: FlaggType;
  opprettet: Date;
  fjernet?: Date;
  opprettetAv: string;
}

export interface FlaggInputModel {
  type: FlaggType;
  kommentar: string;
}
