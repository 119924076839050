import React, { ReactNode, useCallback, useEffect, useState } from "react";
import "./Table.scss";
import { TableBodyRow } from "./TableBodyRow";
import { TableHeader } from "./TableHeader";

export interface Kolonne {
  id?: string;
  tittel?: string;
  verdi?: ReactNode;
  span?: number;
  truncate?: boolean;
  cssClass?: string;
  isSortable?: boolean;
}

export interface Rad {
  id: string;
  checked?: boolean;
  toggleCheckbox?: () => void;
  data?: any;
  kolonner: Kolonne[];
  detaljer?: ReactNode[];
  cssClassDetaljer?: string;
  cssClass?: string;
}

interface Props {
  header?: Omit<Rad, "id" | "data" | "onRadValgt">;
  rader: Rad[];
  checked?: boolean;
  checkAll?: () => void;
  clearAll?: () => void;
  sortKolonne?: string;
  sortRetning?: "asc" | "desc";
  disableLastInnFlere?: boolean;
  skjulLastInnFlere?: boolean;
  onLastInnFlereClick?: () => void;
  onSorterClick?: (
    kolonneId: string | undefined,
    retning: "asc" | "desc"
  ) => void;
  onAccordionRowClick?: (radId: string | undefined) => void;
  showCheckboxes?: boolean;
}

const Table = (props: Props) => {
  const {
    header,
    rader,
    checked,
    checkAll,
    clearAll,
    sortKolonne,
    sortRetning = "desc",
    disableLastInnFlere,
    skjulLastInnFlere,
    onLastInnFlereClick,
    onSorterClick,
    onAccordionRowClick,
    showCheckboxes
  } = props;

  const [sortDirection, setSortDirection] = useState(sortRetning);
  const [sortBy, setSortBy] = useState(sortKolonne);
  const [valgtRad, setValgtRad] = useState("");

  useEffect(() => {
    if (localStorage.getItem("sortBy")) {
      setSortBy(localStorage.getItem("sortBy") ?? "");
    }

    if (localStorage.getItem("sortDirection")) {
      setSortDirection(
        (localStorage.getItem("sortDirection") as "asc" | "desc") ?? "desc"
      );
    }
  }, [sortBy, sortDirection]);

  useEffect(() => {
    localStorage.removeItem("sortBy");
    localStorage.removeItem("sortDirection");
  }, []);

  const antallKolonner =
    (header && columnSpan(header.kolonner)) ||
    (rader.length > 0 && columnSpan(rader[0].kolonner)) ||
    0;

  const onHeaderClick = useCallback(
    (kolonneId?: string, isSortable?: boolean) => {
      if (!onSorterClick || !kolonneId || isSortable === false) return;

      let retning: "asc" | "desc" = "asc";

      if (sortBy !== kolonneId) {
        setSortBy(kolonneId);
      } else {
        retning = sortDirection && sortDirection !== "desc" ? "desc" : "asc";
      }

      setSortDirection(retning);
      onSorterClick(kolonneId, retning);
      setValgtRad("");
      localStorage.setItem("sortBy", kolonneId);
      localStorage.setItem("sortDirection", retning);
    },
    [onSorterClick, sortBy, sortDirection]
  );

  const onRadClick = useCallback(
    (radId: string | undefined) => {
      if (radId === valgtRad) setValgtRad("");
      else setValgtRad(radId ?? "");

      if (onAccordionRowClick) onAccordionRowClick(radId);
    },
    [onAccordionRowClick, valgtRad]
  );

  return (
    <table className="oslo-table">
      {header && (
        <thead
          className={`w-full pb-3 oslo-table-header-${antallKolonner + 1} ${
            header.cssClass ?? ""
          }`}
        >
          <tr>
            {rader.length > 0 && rader[0].detaljer && <td className="w-10" />}
            {showCheckboxes && clearAll && checkAll && (
              <th title="Velg alle inntauingene" className="w-10">
                <input
                  type="checkbox"
                  className="ods-form-check-input"
                  checked={checked}
                  onChange={e =>
                    e.currentTarget.checked ? checkAll() : clearAll()
                  }
                />
              </th>
            )}
            {header.kolonner.map(kolonne => (
              <TableHeader
                key={"table-header-" + kolonne.id}
                kolonne={kolonne}
                sortBy={sortBy}
                sortDirection={sortDirection}
                onHeaderClick={onHeaderClick}
                onSorterClick={onSorterClick}
              />
            ))}
            <th className="sr-only">Knapp for åpning av handlingsmeny</th>
          </tr>
        </thead>
      )}
      <tbody className="max-h-full h-full">
        {rader &&
          rader.map((rad, index) => {
            return (
              <React.Fragment key={rad.id}>
                <TableBodyRow
                  rad={rad}
                  radIndex={index}
                  valgtRad={valgtRad}
                  showCheckboxes={showCheckboxes}
                  antallKolonner={antallKolonner}
                  onRadClick={onRadClick}
                />
                {rad.id === valgtRad && (
                  <tr className={rad.cssClassDetaljer}>
                    <td className="px-2 pb-2" colSpan={rad.kolonner.length + 1}>
                      {rad.detaljer}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
      </tbody>
      {!skjulLastInnFlere && onLastInnFlereClick && (
        <tfoot>
          <tr>
            <td colSpan={header?.kolonner.length ?? 0 + 1} className="mt-2">
              <button
                tabIndex={0}
                className="btn btn--small btn--flat flex mx-auto"
                disabled={disableLastInnFlere}
                onClick={() => onLastInnFlereClick()}
              >
                <span>Last inn flere</span>
              </button>
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

function columnSpan(columns: Kolonne[]): number {
  return columns.reduce((span, k) => span + (k.span ?? 1), 0);
}

export default Table;
