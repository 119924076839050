import React, { useState, useEffect, useRef } from "react";
import { LoggUtButton } from "./LoggUtButton";

import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as NyttVindu } from "../../assets/icons/nyttvindu.svg";

import { COLORS } from "../../styles/colorVariables";

import { useOutsideEventListener } from "../../hooks/useOutsideEventListener";

const inntauingKpier =
  "https://bym-qlik.bymoslo.no/oslofelles/sense/app/578cff1f-eb84-4005-9d43-1d8f23379a6a/sheet/95903aa6-df91-4d4e-8a12-9ecb0068f6be/state/analysis";

export const MenuButton = () => {
  const [currentFilterIcon, setCurrentFilterIcon] = useState<
    React.SVGProps<SVGSVGElement>
  >(<MenuIcon className="fill-current" fill="white" stroke="white" />);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [menuButtonType, setMenuButtonType] = useState<string>("btn--primary");
  const [
    inntauingKpierNyttVinduIconColor,
    setInntauingKpierNyttVinduIconColor
  ] = useState<string>("white");
  const [inntauingNyttVinduIconColor, setInntauingNyttVinduIconColor] =
    useState<string>("white");
  const [
    aktiveInntauingerNyttVinduIconColor,
    setAktiveInntauingerNyttVinduIconColor
  ] = useState<string>("white");
  const [
    skiltforskrifterNyttVinduIconColor,
    setSkiltforskrifterNyttVinduIconColor
  ] = useState<string>("white");
  const [hgkNyttVinduIconColor, setHgkNyttVinduIconColor] =
    useState<string>("white");
  const [mwsNyttVinduIconColor, setMwsNyttVinduIconColor] =
    useState<string>("white");

  const menyDropdownContentContainerRef = useRef(null);

  useEffect(() => {
    return () => {};
  }, [menuOpen]);

  useEffect(() => {
    if (menuOpen) {
      setMenuButtonType("btn--primary");
      setCurrentFilterIcon(<CloseIcon className="fill-current" />);
    } else if (!menuOpen) {
      setMenuButtonType("btn--secondary");
      setCurrentFilterIcon(<MenuIcon className="fill-current" />);
    }
  }, [menuOpen]);

  useOutsideEventListener(menyDropdownContentContainerRef, () =>
    setMenuOpen(false)
  );

  const handleOnClickMenuButton = () => {
    setMenuOpen(!menuOpen);
  };

  const handleChangeNyttVinduIconColor = (
    event:
      | React.FocusEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLAnchorElement>,
    color: string
  ) => {
    const anchor = event.currentTarget;
    if (!anchor) {
      return;
    }

    switch (anchor.id) {
      case "inntauing-kpier-qlik-link":
        setInntauingKpierNyttVinduIconColor(color);
        break;
      case "inntauing-qlik-link":
        setInntauingNyttVinduIconColor(color);
        break;
      case "aktive-inntauinger-qlik-link":
        setAktiveInntauingerNyttVinduIconColor(color);
        break;
      case "skiltforskrifter-qlik-link":
        setSkiltforskrifterNyttVinduIconColor(color);
        break;
      case "hgk-link":
        setHgkNyttVinduIconColor(color);
        break;
      case "mws-link":
        setMwsNyttVinduIconColor(color);
        break;

      default:
        break;
    }
  };

  const handleCloseOnEsc = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      setMenuOpen(false);
      setInntauingKpierNyttVinduIconColor("white");
      setInntauingNyttVinduIconColor("white");
      setAktiveInntauingerNyttVinduIconColor("white");
      setSkiltforskrifterNyttVinduIconColor("white");
      setHgkNyttVinduIconColor("white");
      setMwsNyttVinduIconColor("white");
    }
  };

  return (
    <div className="relative" onKeyDown={handleCloseOnEsc}>
      <button
        type="button"
        data-testid="menu-button"
        className={`btn ${menuButtonType} btn--icon-right h-12`}
        onClick={handleOnClickMenuButton}
      >
        {currentFilterIcon}
        <span>Meny</span>
      </button>
      {menuOpen && (
        <div
          className="absolute right-0 bg-darkblue text-white pt-4 pr-16 pb-4 pl-16"
          ref={menyDropdownContentContainerRef}
        >
          <div className="flex flex-col gap-x-16 gap-y-8">
            <div>
              <h3 className="text-xl mb-2">Rapporter</h3>
              <div>
                <div className="mb-1">
                  <a
                    id="inntauing-kpier-qlik-link"
                    className="whitespace-nowrap osg-link osg-link--state-light"
                    href={inntauingKpier}
                    target="_blank"
                    rel="noopener noreferrer"
                    onFocus={e =>
                      handleChangeNyttVinduIconColor(
                        e,
                        COLORS["link-hoverblue"]
                      )
                    }
                    onBlur={e => handleChangeNyttVinduIconColor(e, "white")}
                    onMouseEnter={e =>
                      handleChangeNyttVinduIconColor(
                        e,
                        COLORS["link-hoverblue"]
                      )
                    }
                    onMouseLeave={e =>
                      handleChangeNyttVinduIconColor(e, "white")
                    }
                  >
                    Inntauing KPIer
                    <NyttVindu
                      className="inline-block fill-current w-3 h-3"
                      fill={inntauingKpierNyttVinduIconColor}
                      stroke={inntauingKpierNyttVinduIconColor}
                    />
                  </a>
                </div>
                <div className="mb-1">
                  <a
                    id="inntauing-qlik-link"
                    className="whitespace-nowrap osg-link osg-link--state-light"
                    href="https://bym-qlik.bymoslo.no/oslofelles/sense/app/926f0ea0-33cb-4f4c-8f8a-79caa3a62eb6/sheet/3a1a3771-dee2-4194-80ff-5a5fa03caa05/state/analysis"
                    target="_blank"
                    rel="noopener noreferrer"
                    onFocus={e =>
                      handleChangeNyttVinduIconColor(
                        e,
                        COLORS["link-hoverblue"]
                      )
                    }
                    onBlur={e => handleChangeNyttVinduIconColor(e, "white")}
                    onMouseEnter={e =>
                      handleChangeNyttVinduIconColor(
                        e,
                        COLORS["link-hoverblue"]
                      )
                    }
                    onMouseLeave={e =>
                      handleChangeNyttVinduIconColor(e, "white")
                    }
                  >
                    Inntauing
                    <NyttVindu
                      className="inline-block fill-current w-3 h-3"
                      fill={inntauingNyttVinduIconColor}
                      stroke={inntauingNyttVinduIconColor}
                    />
                  </a>
                </div>
                <div className="mb-1">
                  <a
                    id="aktive-inntauinger-qlik-link"
                    className="whitespace-nowrap osg-link osg-link--state-light"
                    href="https://bym-qlik.bymoslo.no/oslofelles/sense/app/aa129774-d6dd-492f-bb59-83bc73d7153f/sheet/8a6b22c0-8c7f-438f-92aa-d955ab4e0aa5/state/analysis"
                    target="_blank"
                    rel="noopener noreferrer"
                    onFocus={e =>
                      handleChangeNyttVinduIconColor(
                        e,
                        COLORS["link-hoverblue"]
                      )
                    }
                    onBlur={e => handleChangeNyttVinduIconColor(e, "white")}
                    onMouseEnter={e =>
                      handleChangeNyttVinduIconColor(
                        e,
                        COLORS["link-hoverblue"]
                      )
                    }
                    onMouseLeave={e =>
                      handleChangeNyttVinduIconColor(e, "white")
                    }
                  >
                    Aktive Inntauinger
                    <NyttVindu
                      className="inline-block fill-current w-3 h-3 ml-0.25"
                      fill={aktiveInntauingerNyttVinduIconColor}
                      stroke={aktiveInntauingerNyttVinduIconColor}
                    />
                  </a>
                </div>
                {/* https://bym-qlik.bymoslo.no/oslofelles/sense/app/53559c32-03c9-4670-8aa9-d7581543ad64 */}
                <div className="mb-1">
                  <a
                    id="skiltforskrifter-qlik-link"
                    className="whitespace-nowrap osg-link osg-link--state-light"
                    href="https://bym-qlik.bymoslo.no/oslofelles/sense/app/53559c32-03c9-4670-8aa9-d7581543ad64"
                    target="_blank"
                    rel="noopener noreferrer"
                    onFocus={e =>
                      handleChangeNyttVinduIconColor(
                        e,
                        COLORS["link-hoverblue"]
                      )
                    }
                    onBlur={e => handleChangeNyttVinduIconColor(e, "white")}
                    onMouseEnter={e =>
                      handleChangeNyttVinduIconColor(
                        e,
                        COLORS["link-hoverblue"]
                      )
                    }
                    onMouseLeave={e =>
                      handleChangeNyttVinduIconColor(e, "white")
                    }
                  >
                    Skiltforskrifter
                    <NyttVindu
                      className="inline-block fill-current w-3 h-3 ml-0.25"
                      fill={skiltforskrifterNyttVinduIconColor}
                      stroke={skiltforskrifterNyttVinduIconColor}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div>
              <h3 className="text-xl mb-2">Fagsystemer</h3>
              <div>
                <div className="mb-1">
                  <a
                    id="hgk-link"
                    className="whitespace-nowrap osg-link osg-link--state-light"
                    href="https://ileggelse-client-prod.bymoslo.no/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onFocus={e =>
                      handleChangeNyttVinduIconColor(
                        e,
                        COLORS["link-hoverblue"]
                      )
                    }
                    onBlur={e => handleChangeNyttVinduIconColor(e, "white")}
                    onMouseEnter={e =>
                      handleChangeNyttVinduIconColor(
                        e,
                        COLORS["link-hoverblue"]
                      )
                    }
                    onMouseLeave={e =>
                      handleChangeNyttVinduIconColor(e, "white")
                    }
                  >
                    HGK
                    <NyttVindu
                      className="inline-block fill-current w-3 h-3"
                      fill={hgkNyttVinduIconColor}
                      stroke={hgkNyttVinduIconColor}
                    />
                  </a>
                </div>
                <div className="mb-1">
                  <a
                    id="mws-link"
                    className="whitespace-nowrap osg-link osg-link--state-light"
                    href="http://pocketcontrol-hgk.oslofelles.oslo.kommune.no/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onFocus={e =>
                      handleChangeNyttVinduIconColor(
                        e,
                        COLORS["link-hoverblue"]
                      )
                    }
                    onBlur={e => handleChangeNyttVinduIconColor(e, "white")}
                    onMouseEnter={e =>
                      handleChangeNyttVinduIconColor(
                        e,
                        COLORS["link-hoverblue"]
                      )
                    }
                    onMouseLeave={e =>
                      handleChangeNyttVinduIconColor(e, "white")
                    }
                  >
                    Mobility Workspace (MWS)
                    <NyttVindu
                      className="inline-block fill-current w-3 h-3"
                      fill={mwsNyttVinduIconColor}
                      stroke={mwsNyttVinduIconColor}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div>
              <h3 className="text-xl mb-2">Brukerstøtte</h3>
              <div>
                <div className="mb-1">
                  <a
                    id="mws-link"
                    className="whitespace-nowrap osg-link osg-link--state-light"
                    href="https://saas.smax.no/saw/ess/offeringPage/9152634?TENANTID=583754967"
                    target="_blank"
                    rel="noopener noreferrer"
                    onFocus={e =>
                      handleChangeNyttVinduIconColor(
                        e,
                        COLORS["link-hoverblue"]
                      )
                    }
                    onBlur={e => handleChangeNyttVinduIconColor(e, "white")}
                    onMouseEnter={e =>
                      handleChangeNyttVinduIconColor(
                        e,
                        COLORS["link-hoverblue"]
                      )
                    }
                    onMouseLeave={e =>
                      handleChangeNyttVinduIconColor(e, "white")
                    }
                  >
                    Kompass - Meld inn feil eller endringsønsker
                    <NyttVindu
                      className="inline-block fill-current w-3 h-3"
                      fill={mwsNyttVinduIconColor}
                      stroke={mwsNyttVinduIconColor}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end pt-4">
            <LoggUtButton />
          </div>
        </div>
      )}
    </div>
  );
};
