import { AuksjonViewModel } from "./Auksjon";
import { BetalingViewModel } from "./Betaling";
import { BetalingskravViewModel } from "./Betalingskrav";
import { VrakingViewModel } from "./Vraking";
import { FlaggViewModel } from "./Flagg";

export enum Kjoretoystype {
  AnnetKjoretoy = 1,
  Buss = 2,
  Campingbil = 3,
  Campingvogn = 4,
  Container = 5,
  KombinertBil = 6,
  Lastebil = 7,
  Moped = 8,
  Motorsykkel = 9,
  Personbil = 10,
  Tilhenger = 11,
  Traktor = 12,
  Varebil = 13,
  LiteElektriskKjoretoy = 14,
  Ukjent = 15,
  ElSykkel = 16
}

export const KjoretoystypeMapper: Record<
  Kjoretoystype,
  { kode: string; navn: string; vrakpant?: number }
> = {
  [Kjoretoystype.AnnetKjoretoy]: {
    kode: "A",
    navn: "Annet kjøretøy"
  },
  [Kjoretoystype.Buss]: {
    kode: "B",
    navn: "Buss"
  },
  [Kjoretoystype.Campingbil]: {
    kode: "CA",
    navn: "Campingbil",
    vrakpant: 3000
  },
  [Kjoretoystype.Container]: {
    kode: "C",
    navn: "Container"
  },
  [Kjoretoystype.KombinertBil]: {
    kode: "KO",
    navn: "Kombinert bil",
    vrakpant: 3000
  },
  [Kjoretoystype.Lastebil]: {
    kode: "L",
    navn: "Lastebil",
    vrakpant: 5000
  },
  [Kjoretoystype.LiteElektriskKjoretoy]: {
    kode: "ES",
    navn: "Lite elektrisk kjøretøy"
  },
  [Kjoretoystype.Motorsykkel]: {
    kode: "MC",
    navn: "Motorsykkel",
    vrakpant: 500
  },
  [Kjoretoystype.Moped]: {
    kode: "MO",
    navn: "Moped",
    vrakpant: 500
  },
  [Kjoretoystype.Personbil]: {
    kode: "P",
    navn: "Personbil",
    vrakpant: 3000
  },
  [Kjoretoystype.Tilhenger]: {
    kode: "T",
    navn: "Tilhenger"
  },
  [Kjoretoystype.Traktor]: {
    kode: "TR",
    navn: "Traktor"
  },
  [Kjoretoystype.Campingvogn]: {
    kode: "K",
    navn: "Tilhenger/campingvogn",
    vrakpant: 3000
  },
  [Kjoretoystype.Varebil]: {
    kode: "V",
    navn: "Varebil",
    vrakpant: 3000
  },
  [Kjoretoystype.Ukjent]: {
    kode: "NN",
    navn: "Ukjent"
  },
  [Kjoretoystype.ElSykkel]: {
    kode: "EL",
    navn: "Elsykkel"
  }
};

export enum StatuskodeType {
  KranbilBestilt = 1,
  AnkommetTomt = 10,
  TilAuksjon = 13,
  TilVrak = 14,
  SendtTilAuksjon = 23,
  SendtTilVrak = 24,
  Hentet = 30,
  Fremmote = 31,
  FlyttetPaaGategrunn = 32,
  Auksjonert = 33,
  Vraket = 34
}

export const StatuskodeMapper: Record<
  StatuskodeType,
  { navn: string; farge: string }
> = {
  [StatuskodeType.KranbilBestilt]: {
    navn: "Kranbil bestilt",
    farge: "#ff8274"
  },
  [StatuskodeType.AnkommetTomt]: {
    navn: "Ankommet tomt",
    farge: "#f9c66b"
  },
  [StatuskodeType.TilAuksjon]: {
    navn: "Til auksjon",
    farge: "#f9c66b"
  },
  [StatuskodeType.TilVrak]: {
    navn: "Til vrak",
    farge: "#f9c66b"
  },
  [StatuskodeType.SendtTilAuksjon]: {
    navn: "Sendt til auksjon",
    farge: "#fae8c8"
  },
  [StatuskodeType.SendtTilVrak]: {
    navn: "Sendt til vrak",
    farge: "#fae8c8"
  },
  [StatuskodeType.Hentet]: {
    navn: "Hentet",
    farge: "#6fe9ff"
  },
  [StatuskodeType.Fremmote]: {
    navn: "Fremmøte",
    farge: "#ccf7ff"
  },
  [StatuskodeType.FlyttetPaaGategrunn]: {
    navn: "Flyttet på gategrunn",
    farge: "#ccffce"
  },
  [StatuskodeType.Auksjonert]: {
    navn: "Auksjonert",
    farge: "#6fe9ff"
  },
  [StatuskodeType.Vraket]: {
    navn: "Vraket",
    farge: "#6fe9ff"
  }
};

export enum BrevType {
  MeldingOmInntauetKjoretoy = 1,
  MeldingOmFlyttetKjoretoy = 2,
  Avhendingsbrev = 4,
  BetalingskravFlytting = 6
}

export const BrevTypeNavn: Record<BrevType, string> = {
  [BrevType.MeldingOmInntauetKjoretoy]: "Melding om inntauet kjøretøy",
  [BrevType.MeldingOmFlyttetKjoretoy]: "Melding om flyttet kjøretøy",
  [BrevType.Avhendingsbrev]: "Avhendingsbrev",
  [BrevType.BetalingskravFlytting]: "Betalingskrav for flytting"
};

export enum PostkasseType {
  Ukjent = 0,
  Altinn = 1,
  Digipost = 2,
  EBoks = 3,
  Fysisk = 4
}

export enum Betalingsgruppe {
  Container = 1,
  Campingvogn = 2,
  Elsparkesykkel = 3,
  MC = 4,
  TungtKjoretoy = 5,
  LettKjoretoy = 6,
  FlyttingGategrunn = 7,
  ElSykkel = 8,
  Ukjent = 99
}

export const BetalingsgruppeMapper: Record<Betalingsgruppe, { navn: string }> =
  {
    [Betalingsgruppe.Container]: {
      navn: "Container"
    },
    [Betalingsgruppe.Campingvogn]: {
      navn: "Tilhenger/campingvogn"
    },
    [Betalingsgruppe.Elsparkesykkel]: {
      navn: "Elsparkesykkel"
    },
    [Betalingsgruppe.MC]: {
      navn: "MC"
    },
    [Betalingsgruppe.TungtKjoretoy]: {
      navn: "Tungt kjøretøy"
    },
    [Betalingsgruppe.LettKjoretoy]: {
      navn: "Lett kjøretøy"
    },
    [Betalingsgruppe.FlyttingGategrunn]: {
      navn: "Flytting på gategrunn"
    },
    [Betalingsgruppe.ElSykkel]: {
      navn: "Elsykkel"
    },
    [Betalingsgruppe.Ukjent]: {
      navn: "Ukjent"
    }
  };

export enum Leveransetype {
  Inntauet = 0,
  Frammote = 1,
  FlyttetGategrunn = 2
}

export enum HjemmelType {
  VegtrafikklovenA = 1,
  Parkeringsforskriften = 2,
  VegtrafikklovenB = 3,
  VegtrafikklovenC = 4,
  ParkeringsforskriftenFjerdeLedd = 5,
  Veglova58Og57 = 6,
  Ukjent = 99
}

export const HjemmelTypeMapper: Record<
  HjemmelType,
  { navn: string; ileggelseType: string }
> = {
  [HjemmelType.VegtrafikklovenA]: {
    navn: "Vegtrafikkloven §37a",
    ileggelseType: "Parkeringsgebyr"
  },
  [HjemmelType.Parkeringsforskriften]: {
    navn: "Vilkårsparkering §38",
    ileggelseType: "Kontrollsanksjon"
  },
  [HjemmelType.VegtrafikklovenB]: {
    navn: "Vegtrafikkloven §37b",
    ileggelseType: "Parkeringsgebyr"
  },
  [HjemmelType.VegtrafikklovenC]: {
    navn: "Vegtrafikkloven §37c",
    ileggelseType: "Parkeringsgebyr"
  },
  [HjemmelType.ParkeringsforskriftenFjerdeLedd]: {
    navn: "Vilkårsparkering §38, fjerde ledd",
    ileggelseType: "Ukjent"
  },
  [HjemmelType.Veglova58Og57]: {
    navn: "Veglova §58 jf. §57",
    ileggelseType: "Ukjent"
  },
  [HjemmelType.Ukjent]: {
    navn: "Ukjent",
    ileggelseType: "Ukjent"
  }
};

export interface FlaggInputModel {
  kommentar: string;
}

export interface HenterViewModel {
  navn: string;
  fodselsdato: Date;
}

export interface HentingViewModel {
  ekspeditor: string;
  hentetidspunkt: Date;
  hentetAv: string;
  hentetAvFodselsdato: Date;
}

export interface HistorikkViewModel {
  status: StatuskodeType;
  tidspunkt: Date;
  betjent: string;
}

export interface IleggelsesnummerViewModel {
  nummer: number;
}

export interface IleggelseViewModel {
  ileggelsesId?: string;
  rekvivertAv?: string;
  ileggelsesnummer?: number;
  overtredelse?: string;
  gebyr?: number;
  tidspunkt?: Date;
  ileggelsestype?: number;
}

export interface InntauingSakViewModel {
  inntauingId: string;
  arkivId: string;
  saksnummer: string;
}

export interface InntauingsIdViewModel {
  id: string;
}

export interface EierViewModel {
  navn?: string;
  erPerson: boolean;
  idNummer?: string;
  telefonnummer?: string;
  gateadresse?: string;
  postnummer?: string;
  poststed?: string;
  fodselsdato?: Date;
}

export interface KjoretoystypeViewModel {
  navn: string;
  kode: string;
}

export interface KjoretoyViewModel {
  rammenummer?: string;
  registreringsnummer?: string;
  nasjonalitet: string;
  merke?: string;
  modell?: string;
  farge?: string;
  registrertAar?: number;
  totalvekt?: number;
  juridiskEier?: EierViewModel;
  medeier?: EierViewModel;
  leasingtaker?: EierViewModel;
  betalingsgruppe: Betalingsgruppe;
  kjoretoystype: KjoretoystypeViewModel;
}

export interface KjoretoyInputModel {
  rammenummer?: string;
  registreringsnummer?: string;
  nasjonalitet: string;
  merke?: string;
  modell?: string;
  farge?: string;
  registrertAar?: number;
  totalvekt?: number;
  juridiskEier?: EierViewModel;
  medeier?: EierViewModel;
  leasingtaker?: EierViewModel;
  kjoretoystype: KjoretoystypeViewModel;
}

export interface LokasjonViewModel {
  gatenavn: string;
  gatenummer?: number;
  oppgang?: string;
  detaljer?: string;
}

export interface InntauingsGrunnlagViewModel {
  bestillingstidspunkt: Date;
  hjemmel: HjemmelType;
  overtredelse: string;
  skadenotat?: string;
  betjentId: string;
  kommentar?: string;
  inntauingsfirma: string;
  eierkontakt?: string;
  lokasjon: LokasjonViewModel;
  utenSkilter: boolean;
}

export interface PrisForInntauingViewModel {
  administrasjonskostnad?: KostnadViewModel;
  inntauingskostnad?: KostnadViewModel;
  ileggelsesgebyr?: KostnadViewModel;
  ileggelsestilleggsgebyr?: KostnadViewModel;
  dognleiekostnad?: KostnadViewModel;
  sum: number;
}

export interface KostnadViewModel {
  pris: number;
  varenummer: number;
}

export interface BrevViewModel {
  id: string;
  brevtype: BrevType;
  mottakerNavn: string;
  mottakerAdresse: string;
  mottakerPostkode: string;
  mottakerPoststed: string;
  forespurtTidspunkt?: Date;
  sendtDigitaltTidspunkt?: Date;
  sendtFysiskTidspunkt?: Date;
  arkivertTidspunkt?: Date;
  sendtTidspunkt?: Date;
  lestTidspunkt?: Date;
  postkasse: PostkasseType;
}

export interface InntauingViewModel {
  id: string;
  arkivReferansenummer?: string;
  arkivReferanseId?: string;
  kanFaktureres: boolean;
  kanVarsles: boolean;
  kanUtleveresUtenBetaling: boolean;
  kanUtleveresMedRedusertDognleie: boolean;
  ileggelse?: IleggelseViewModel;
  kjoretoy: KjoretoyViewModel;
  inntauingsgrunnlag: InntauingsGrunnlagViewModel;
  kjoretoyleveranse?: KjoretoyleveranseViewModel;
  utestaaendeKostnader: PrisForInntauingViewModel;
  betalinger: BetalingViewModel[];
  brevutsendinger: BrevViewModel[];
  status: StatuskodeType;
  historikk: HistorikkViewModel[];
  henting?: HentingViewModel;
  takseringer: TakseringViewModel[];
  vraking?: VrakingViewModel;
  auksjon?: AuksjonViewModel;
  betalingskrav?: BetalingskravViewModel;
  flagg?: FlaggViewModel[];
  tekstmeldinger: SmsViewModel[];
}

export interface SmsViewModel {
  id: string;
  tekst: string;
  mottakerId: string;
  motakkerTelefonnummer?: string;
  sendingstidspunkt: Date;
  tilknyttetBrevId?: string;
}

export interface KjoretoyleveranseViewModel {
  type: Leveransetype;
  leveringstidspunkt: Date;
  ordrenummer?: number;
  inntauingsfirma?: string;
  journalnummer?: number;
  plassering?: string;
  flyttetTilGatenavn?: string;
  flyttetTilGatenummer?: number;
  flyttetTilOppgang?: string;
  flyttetTilDetaljer?: string;
  flyttenummer?: number;
  flytteKidnummer?: number;
}

export interface KommentarInputModel {
  tekst: string;
}

export interface KunngjoringInputModel {
  tidspunkt: Date;
}

export interface NyHentingInputModel {
  hentetTidspunkt?: Date;
  henter: HenterViewModel;
}

export interface PlasseringViewModel {
  rad: string;
}

export interface RegistrerInntauingInputModel {
  inntauingsgrunnlag: InntauingsGrunnlagViewModel;
  kjoretoy: KjoretoyInputModel;
  ileggelse?: IleggelseViewModel;
  leveranse?: KjoretoyleveranseViewModel;
  inntauingsId?: string;
  giantleapId?: string;
}

export interface RegistrerTakseringInputModel {
  taksasjonstidspunkt: Date;
  fil: File;
}

export interface TakseringViewModel {
  id: string;
  tidspunkt?: Date;
}
