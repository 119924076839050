interface EkstrainfoKortProps {
  tittel: string;
  verdi: string;
}

export const EkstrainfoKort = (props: EkstrainfoKortProps) => {
  const { tittel, verdi } = props;

  return (
    <div className="bg-rusticyellow rounded-2xl text-center min-w-[13rem] py-4 my-5 mr-10">
      <div className="text-md font-semibold">{tittel}</div>
      <div className="text-3xl font-bold pt-3">{verdi}</div>
    </div>
  );
};
