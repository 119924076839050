import { useState } from "react";
import { ReactComponent as Handling } from "../../../assets/icons/handling.svg";
import { ReactComponent as Check } from "../../../assets/icons/check-circle.svg";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import {
  useFjernIleggelse,
  useLeggTilIleggelse
} from "../../../hooks/useBetaling";
import { useToast } from "../../common/toast/ToastProvider";
import { isSaksbehandler } from "../../../utils/userRolesUtils";

const breddeMeny = 250;

interface HandlingsknappProps {
  betalingsnummer: number;
  registreringsnummer: string;
  erIleggelseBetalt: boolean;
}

export const Handlingsknapp = (props: HandlingsknappProps) => {
  const { betalingsnummer, registreringsnummer, erIleggelseBetalt } = props;

  const [visMeny, setVisMeny] = useState<number | undefined>();
  const [anchorPoint, setAnchorPoint] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0
  });

  const ref = useOnClickOutside<HTMLDivElement>(() => {
    if (setVisMeny && visMeny) setVisMeny(undefined);
  });
  const { addToast } = useToast();

  const putFjernIleggelse = useFjernIleggelse(betalingsnummer);
  const putLeggTilIleggelse = useLeggTilIleggelse(betalingsnummer);

  const handleVisMeny = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setAnchorPoint({ x: e.pageX, y: e.pageY });
    if (setVisMeny) setVisMeny(visMeny ? undefined : betalingsnummer);
  };

  const handleLeggTilIleggelse = () => {
    if (erIleggelseBetalt) {
      addToast("Ileggelse er allerede registrert betalt", "error");
      return;
    }
    putLeggTilIleggelse.mutateAsync(undefined, {
      onSuccess: () => {
        addToast("Ileggelse ble registrert betalt");
      },
      onError: () => {
        addToast("Betaling av ileggelse ble ikke registrert", "error");
      }
    });
  };

  const handleFjernIleggelse = () => {
    if (!erIleggelseBetalt) {
      addToast("Ileggelsen er ikke betalt ennå", "error");
      return;
    }
    putFjernIleggelse.mutateAsync(undefined, {
      onSuccess: () => {
        addToast("Betaling av ileggelse ble fjernet");
      },
      onError: () => {
        addToast("Betaling av ileggelse ble ikke fjernet", "error");
      }
    });
  };

  return (
    <>
      {isSaksbehandler() && <button
        data-testid={`handlingsknapp-${registreringsnummer}`}
        title="Korrigering av betaling av ileggelse"
        className="btn btn--flat btn--icon-only focus:outline-2"
        onClick={handleVisMeny}
      >
        <Handling className="fill-current" />
      </button>}
      {visMeny && visMeny === betalingsnummer && (
        <div ref={ref}>
          <ul
            data-testid={`handlingsmeny-${registreringsnummer}`}
            style={{
              top: anchorPoint.y,
              left: anchorPoint.x - breddeMeny,
              width: `${breddeMeny}px`
            }}
            className="absolute flex flex-col z-50 border text-left shadow-2xl"
          >
            <li>
              <button
                title="Betalt ileggelse"
                data-testid={`handlingsmeny-legg-til-betaling-av-ileggelse-${registreringsnummer}`}
                className={`bg-white hover:bg-gray-200 px-3 py-1 w-full text-left items-center flex flex-row w-[${breddeMeny.toString()}px]`}
                onClick={handleLeggTilIleggelse}
              >
                <div className="w-8 mr-1">
                  {erIleggelseBetalt && (
                    <Check className="h-8 fill-green-600" />
                  )}
                </div>
                Betalt ileggelse
              </button>
            </li>
            <li>
              <button
                title="Ikke betalt ileggelse"
                data-testid={`handlingsmeny-fjern-betaling-av-ileggelse-${registreringsnummer}`}
                className={`bg-white hover:bg-gray-200 px-3 py-1 w-full text-left items-center flex flex-row w-[${breddeMeny.toString()}px]`}
                onClick={handleFjernIleggelse}
              >
                <div className="w-8 mr-1">
                  {!erIleggelseBetalt && (
                    <Check className="h-8 fill-green-600" />
                  )}
                </div>
                Ikke betalt ileggelse
              </button>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};
