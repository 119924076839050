import { useFlyttingRegnskap } from "../../../hooks/useRegnskap";
import { download_table_as_csv } from "../../../utils/csvTableGenerator";
import { FraTilDato } from "../../common/form/FraTilDato";
import { LoadingSpinner } from "../../common/loading/LoadingSpinner";
import { CustomError } from "../../common/typography/CustomError";
import { HeadingView } from "../../common/typography/HeadingView";
import { ReactComponent as Download } from "../../../assets/icons/download.svg";
import { endOfMonth, startOfMonth } from "date-fns";
import { useStateParams } from "../../../hooks/useStateParams";
import { formaterNullableBelop } from "../../../utils/formatUtils";
import { SendteBetalingskrav } from "./SendteBetalingskrav";

import { ReactComponent as Print } from "../../../assets/icons/print.svg";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { HeadingUtskrift } from "../../common/typography/HeadingUtskrift";

export const Flytteinntekter = () => {
  const iDag = new Date();

  const foersteDagIMaaneden = startOfMonth(
    new Date(iDag.getFullYear(), iDag.getMonth() - 1, iDag.getDate())
  );
  const nesteMidnatt = endOfMonth(
    new Date(iDag.getFullYear(), iDag.getMonth() - 1, iDag.getDate())
  );

  const [filternokkel, setFilternokkel] = useStateParams(
    "opprettet",
    "filternokkel",
    s => s,
    s => s
  );

  const [fraDato, setFraDato] = useStateParams(
    foersteDagIMaaneden,
    "fra",
    s => s.toISOString(),
    s => new Date(s)
  );
  const [tilDato, setTilDato] = useStateParams(
    nesteMidnatt,
    "til",
    s => s.toISOString(),
    s => new Date(s)
  );

  const { data, isLoading, isError, error } = useFlyttingRegnskap(
    fraDato,
    tilDato,
    filternokkel
  );

  const componentPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    pageStyle: "m-5"
  });

  const getSortEtterName = (value: string) => {
    switch (value) {
      case "opprettet":
        return "Opprettet krav";
      case "generert":
        return "Generert krav";
      case "mottatt":
        return "Krav mottatt av Ine";
      case "betalt":
        return "Mottatt betaling";

      default:
        break;
    }
  };

  return (
    <>
      <HeadingView>Flytteinntekter</HeadingView>
      <div className="flex flex-row flex-wrap gap-5">
        <div className="ods-form-group w-52">
          <label className="ods-form-label">Sorter etter</label>
          <select
            className="ods-form-input ods-select"
            value={filternokkel}
            onChange={e => setFilternokkel(e.currentTarget.value)}
          >
            <option value="opprettet">{getSortEtterName("opprettet")}</option>
            <option value="generert">{getSortEtterName("generert")}</option>
            <option value="mottatt">{getSortEtterName("mottatt")}</option>
            <option value="betalt">{getSortEtterName("betalt")}</option>
          </select>
        </div>
        <FraTilDato
          actionButtons={
            <>
              <button
                type="button"
                className="btn btn--primary-confirm btn--icon-left mb-5 mt-auto"
                onClick={() =>
                  download_table_as_csv(
                    "flytteinntekt",
                    `Flytteinntekt_${fraDato.toLocaleDateString()}_til_${tilDato.toLocaleDateString()}`
                  )
                }
              >
                <Download className="fill-current" />
                <span>Last ned</span>
              </button>
              <button
                type="button"
                className="btn btn--primary-confirm btn--icon-left mb-5 mt-auto"
                onClick={handlePrint}
              >
                <Print className="fill-current" />
                <span>Skriv ut</span>
              </button>
            </>
          }
          fraDato={fraDato}
          tilDato={tilDato}
          handleChangeFraDato={setFraDato}
          handleChangeTilDato={setTilDato}
        />
      </div>
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      {data && (
        <>
          <div className="flex flex-col gap-10 mb-16">
            <div className="flex flex-row flex-wrap gap-10 h-fit">
              <div className="border-rusticyellow border-2 px-3 py-2">
                <h2 className="font-semibold leading-8">
                  {`Inntekter fra ${data.inntekter.antall} flyttinger`}
                </h2>
                <table id="fakturainntekt" className="w-96 text-left">
                  <thead className="font-semibold border-b-2 border-gray-300">
                    <tr>
                      <th>Totalt</th>
                      <th />
                      <th className="text-right">
                        {formaterNullableBelop(data.inntekter.total)}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>16235</td>
                      <td>Flytting</td>
                      <td className="text-right">
                        {formaterNullableBelop(data.inntekter.inntauingsgebyr)}
                      </td>
                    </tr>
                    <tr>
                      <td>16237</td>
                      <td title="Administrasjonsgebyr">Admin.gebyr</td>
                      <td className="text-right">
                        {formaterNullableBelop(
                          data.inntekter.administrasjonsgebyr
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <SendteBetalingskrav
              tableId="flytteinntekt"
              betalingskrav={data.betalingskrav ?? []}
            />
          </div>
          <div className="hidden">
            <div className="text-xs" ref={componentPrintRef}>
              <HeadingUtskrift>{`Inntekter fra ${
                data.inntekter.antall
              } flyttinger ${fraDato.toLocaleDateString()} - ${tilDato.toLocaleDateString()}, ${getSortEtterName(
                filternokkel
              )}`}</HeadingUtskrift>
              <table id="fakturainntekt" className="w-96 text-left">
                <thead className="font-semibold border-b-2 border-gray-300">
                  <tr>
                    <th>Totalt</th>
                    <th />
                    <th className="text-right">
                      {formaterNullableBelop(data.inntekter.total)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>16235</td>
                    <td>Flytting</td>
                    <td className="text-right">
                      {formaterNullableBelop(data.inntekter.inntauingsgebyr)}
                    </td>
                  </tr>
                  <tr>
                    <td>16237</td>
                    <td title="Administrasjonsgebyr">Admin.gebyr</td>
                    <td className="text-right">
                      {formaterNullableBelop(
                        data.inntekter.administrasjonsgebyr
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <SendteBetalingskrav
                tableId="flytteinntekt-print"
                betalingskrav={data.betalingskrav ?? []}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
