import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { ReactComponent as Refresh } from "../../assets/icons/arrow-circle.svg";
import { ReactComponent as Print } from "../../assets/icons/print.svg";
import { finnEier } from "../../utils/objectMapping";
import { useState } from "react";
import { useToast } from "../common/toast/ToastProvider";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import {
  usePostDigitaltVarselbrev,
  usePostGenererVarselbrev
} from "../../hooks/useBrev";

interface ManuelleBrevDigitaltVarselbrevProps {
  handleOppdaterBrevId: (id: string) => void;
  handleAvbrytBrevsending: () => void;
  inntauing?: InntauingViewModel;
  pdfLoading: boolean;
  pdfError: boolean;
}

export const ManuelleBrevDigitaltVarselbrev = (
  props: ManuelleBrevDigitaltVarselbrevProps
) => {
  const {
    handleOppdaterBrevId,
    handleAvbrytBrevsending,
    inntauing,
    pdfLoading,
    pdfError
  } = props;

  const [brevErGenerert, setBrevErGenerert] = useState(false);
  const [brevErSendt, setBrevErSendt] = useState(false);

  const postGenererVarselbrev = usePostGenererVarselbrev();
  const postDigitaltVarselbrev = usePostDigitaltVarselbrev();

  const { addToast } = useToast();

  const handleSendBrev = () => {
    if (brevErGenerert) {
      postDigitaltVarselbrev.mutate(
        {
          brevliste: [{ inntauingsId: inntauing?.id ?? "" }]
        },
        {
          onSuccess: response => {
            if (response.length > 0) {
              setBrevErSendt(true);
              addToast("Digitalt brev er sendt!");
            } else {
              addToast("Feil ved sending av digitalt brev!", "error");
            }
          },
          onError: () => {
            addToast("Feil ved sending av digitalt brev!", "error");
          }
        }
      );
    }
  };

  const handleGenererPdf = () => {
    const eier = finnEier(
      inntauing?.kjoretoy.juridiskEier,
      inntauing?.kjoretoy.medeier,
      inntauing?.kjoretoy.leasingtaker
    );
    if (inntauing) {
      postGenererVarselbrev.mutate(
        {
          brevliste: [
            {
              inntauingsId: inntauing?.id ?? "",
              mottaker: {
                navn: eier?.navn ?? "",
                gateadresse: eier?.gateadresse ?? "",
                postnummer: eier?.postnummer ?? "",
                poststed: eier?.poststed ?? ""
              }
            }
          ]
        },
        {
          onSuccess: response => {
            if (response.length > 0) {
              handleOppdaterBrevId(response[0].brevId);
              setBrevErGenerert(true);
            }
          }
        }
      );
    }
  };

  return (
    <div className="flex flex-wrap flex-row gap-5">
      <button
        className="btn btn--flat btn--icon-left"
        onClick={handleAvbrytBrevsending}
      >
        <ArrowDown className="fill-current inline-flex rotate-[90deg]" />
        <span>Avbryt</span>
      </button>

      <button
        data-testid="generer-digitalt-varselbrev"
        className="btn btn--secondary btn--icon-left"
        onClick={handleGenererPdf}
        disabled={pdfLoading}
      >
        <Refresh className="fill-current inline-flex" />
        <span>Generer brev</span>
      </button>

      <button
        data-testid="brevsending-digitalt-send"
        className="btn btn--primary btn--icon-left"
        onClick={handleSendBrev}
        disabled={pdfError || pdfLoading || brevErSendt || !brevErGenerert}
      >
        <Print className="fill-current inline-flex" />
        <span>Send brev</span>
      </button>
    </div>
  );
};
