import { useQuery } from "react-query";
import { getHandlingslogg } from "../api/apiServices/InntauingService";
import { CACHE_KEYS } from "../utils/globalConstants";

export const useGetHandlingslogg = (inntauingId: string) =>
  useQuery(
    [CACHE_KEYS.Handlingslogg, inntauingId],
    () => getHandlingslogg(inntauingId).then(r => r.data),
    {
      refetchOnWindowFocus: false
    }
  );
