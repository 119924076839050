import { apiClient } from "../client";
import { config } from "./Config";

const client = apiClient();

export const putGjenopprettBetalingskrav = (id: string) =>
  client.put(
    `betalingskrav/${id}/tilbakestilltilopprettet`,
    undefined,
    config()
  );