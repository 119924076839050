import { useArkiverteVrak } from "../../hooks/useVrak";
import { AlertBox } from "../common/form/AlertBox";
import { CustomError } from "../common/typography/CustomError";
import { HeadingView } from "../common/typography/HeadingView";
import { ReactComponent as Print } from "../../assets/icons/print.svg";
import { endOfDay, startOfDay, sub } from "date-fns";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { ArkiverteVrakTabellUtskrift } from "./ArkiverteVrakTabellUtskrift";
import { ArkiverteVrakTabell } from "./ArkiverteVrakTabell";
import { EkstrainfoKort } from "../common/card/EkstrainfoKort";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { FraTilDato } from "../common/form/FraTilDato";
import { formaterNullableBelop } from "../../utils/formatUtils";
import { useStateParams } from "../../hooks/useStateParams";
import { HeadingUtskrift } from "../common/typography/HeadingUtskrift";

export const ArkiverteVrak = () => {
  const syvDagerSiden = startOfDay(sub(new Date(), { days: 7 }));
  const iDag = endOfDay(new Date());

  const [fraDato, setFraDato] = useStateParams(
    syvDagerSiden,
    "fra",
    s => s.toISOString(),
    s => new Date(s)
  );
  const [tilDato, setTilDato] = useStateParams(
    iDag,
    "til",
    s => s.toISOString(),
    s => new Date(s)
  );

  const {
    data,
    isLoading,
    error,
    isError,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useArkiverteVrak(fraDato, tilDato);

  const componentPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    pageStyle: "m-5"
  });

  const vrakinger = data?.pages.flatMap(x => x.data);
  const antall = vrakinger?.length ?? 0;
  const inntektSkrapsalg =
    vrakinger
      ?.map(i => i.vraking?.inntektSkrapsalg ?? 0)
      .reduce((prev, curr) => prev + curr, 0) ?? 0;
  const vrakpant =
    vrakinger
      ?.map(i => i.vraking?.vrakpant ?? 0)
      .reduce((prev, curr) => prev + curr, 0) ?? 0;

  const handleNesteSide = () => fetchNextPage();

  return (
    <>
      <HeadingView>Gjennomførte vrakinger</HeadingView>
      <FraTilDato
        actionButtons={
          <button
            className="btn btn--primary-confirm btn--icon-left mb-5 mt-auto"
            onClick={handlePrint}
            disabled={isLoading || isError || antall === 0}
          >
            <Print className="fill-current" />
            <span>Skriv ut</span>
          </button>
        }
        fraDato={fraDato}
        tilDato={tilDato}
        handleChangeFraDato={setFraDato}
        handleChangeTilDato={setTilDato}
      />
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      {antall === 0 ? (
        <div className="mb-5">
          <AlertBox
            alertLevel="warning"
            heading="Finner ingen vrakinger for denne perioden"
          />
        </div>
      ) : (
        <>
          <div className="flex flex-row flex-wrap mb-5">
            <EkstrainfoKort
              tittel="Inntekt skrapsalg"
              verdi={formaterNullableBelop(inntektSkrapsalg)}
            />
            <EkstrainfoKort
              tittel="Vrakpant"
              verdi={formaterNullableBelop(vrakpant)}
            />
            <EkstrainfoKort
              tittel="Totalt"
              verdi={formaterNullableBelop(inntektSkrapsalg + vrakpant)}
            />
            <EkstrainfoKort tittel="Antall" verdi={antall.toString()} />
          </div>
          <ArkiverteVrakTabell vrakliste={vrakinger} />
        </>
      )}
      {hasNextPage && (
        <div className="flex w-full justify-center mt-3">
          <button
            className="btn btn--flat btn--small"
            disabled={!hasNextPage || isFetchingNextPage}
            onClick={handleNesteSide}
          >
            {isFetchingNextPage ? "Henter flere" : "Last inn flere"}
          </button>
        </div>
      )}
      <div className="hidden">
        <div ref={componentPrintRef}>
          <HeadingUtskrift>{`Liste over vrak ${fraDato.toLocaleDateString()} - ${tilDato.toLocaleDateString()}`}</HeadingUtskrift>
          <ArkiverteVrakTabellUtskrift vrakliste={vrakinger} />
        </div>
      </div>
    </>
  );
};
