import { endOfDay, startOfDay } from "date-fns";
import { Filter } from "../api/interfaces/Filter";

export const GetQueryParamsAsFilter = (query: URLSearchParams) => {
  const datoFra = query.get("datoFra") ?? "";
  const datoTil = query.get("datoTil") ?? "";
  const brevtyper = query.get("brevtyper") ?? "";
  const brevtyperJson =
    brevtyper === "" ? [] : (JSON.parse(brevtyper) as number[]);
  const betalingsmetoder = query.get("betalingsmetoder") ?? "";
  const betalingsmetoderJson =
    betalingsmetoder === "" ? [] : (JSON.parse(betalingsmetoder) as number[]);
  const statuskoder = query.get("statuskoder") ?? "";
  const statuskoderJson =
    statuskoder === "" ? [] : (JSON.parse(statuskoder) as number[]);
  const betalingskravStatuser = query.get("betalingskravStatuser") ?? "";
  const betalingskravStatuserJson =
    betalingskravStatuser === ""
      ? []
      : (JSON.parse(betalingskravStatuser) as number[]);
  const kjoretoyNasjoner = query.get("kjoretoyNasjoner") ?? "";
  const kjoretoyNasjonerJson =
    kjoretoyNasjoner === "" ? [] : (JSON.parse(kjoretoyNasjoner) as number[]);
  const kjoretoystyper = query.get("kjoretoystyper") ?? "";
  const kjoretoystyperJson =
    kjoretoystyper === "" ? [] : (JSON.parse(kjoretoystyper) as number[]);
  const flaggTyper = query.get("flaggTyper") ?? "";
  const flaggTyperJson =
    flaggTyper === "" ? [] : (JSON.parse(flaggTyper) as number[]);

  const filter: Filter = {
    sok: query.get("sok") ?? "",
    datoFra: datoFra !== "" ? new Date(datoFra) : null,
    datoTil: datoTil !== "" ? new Date(datoTil) : null,
    inntauetFra: query.get("inntauetFra") ?? "",
    flyttetTil: query.get("flyttetTil") ?? "",
    brevtyper: brevtyperJson,
    flaggTyper: flaggTyperJson,
    betalingsmetoder: betalingsmetoderJson,
    statuskoder: statuskoderJson,
    betalingskravStatuser: betalingskravStatuserJson,
    kjoretoyNasjoner: kjoretoyNasjonerJson,
    kjoretoystyper: kjoretoystyperJson
  };

  return filter;
};

export const GetFilterAsQueryParams = (filter: Filter) => {
  const datoFra = filter.datoFra ? startOfDay(filter.datoFra) : undefined;
  const datoTil = filter.datoTil ? endOfDay(filter.datoTil) : undefined;

  const params = {
    sok: filter.sok && filter.sok.length > 0 ? filter.sok : undefined,
    datoFra: datoFra?.toISOString() ?? undefined,
    datoTil: datoTil?.toISOString() ?? undefined,
    inntauetFra:
      filter.inntauetFra && filter.inntauetFra.length > 0
        ? filter.inntauetFra
        : undefined,
    flyttetTil:
      filter.flyttetTil && filter.flyttetTil.length > 0
        ? filter.flyttetTil
        : undefined,
    brevtyper:
      filter.brevtyper && filter.brevtyper.length > 0
        ? JSON.stringify(filter.brevtyper)
        : undefined,
    betalingsmetoder:
      filter.betalingsmetoder && filter.betalingsmetoder.length > 0
        ? JSON.stringify(filter.betalingsmetoder)
        : undefined,
    statuskoder:
      filter.statuskoder && filter.statuskoder.length > 0
        ? JSON.stringify(filter.statuskoder)
        : undefined,
    betalingskravStatuser:
      filter.betalingskravStatuser && filter.betalingskravStatuser.length > 0
        ? JSON.stringify(filter.betalingskravStatuser)
        : undefined,
    kjoretoyNasjoner:
      filter.kjoretoyNasjoner && filter.kjoretoyNasjoner.length > 0
        ? JSON.stringify(filter.kjoretoyNasjoner)
        : undefined,
    kjoretoystyper:
      filter.kjoretoystyper && filter.kjoretoystyper.length > 0
        ? JSON.stringify(filter.kjoretoystyper)
        : undefined,
    flaggTyper:
      filter.flaggTyper && filter.flaggTyper.length > 0
        ? JSON.stringify(filter.flaggTyper)
        : undefined
  };

  return new URLSearchParams(JSON.parse(JSON.stringify(params)));
};
