import {
  KeyValueKortInnhold,
  KeyValuePair
} from "../common/card/KeyValueKortInnhold";

interface EierinformasjonProps {
  juridiskEier: string;
  isLeasingtaker: boolean;
  medeier: string;
  leasingtaker: string;
  telefon: string;
  adresse: string;
  postnrOgSted: string;
}

export const Eierinformasjon = (props: EierinformasjonProps) => {
  const {
    juridiskEier,
    isLeasingtaker,
    medeier,
    leasingtaker,
    telefon,
    adresse,
    postnrOgSted
  } = props;

  let keyValuePairs: KeyValuePair[] = [
    { key: "Juridisk eier", value: [juridiskEier], dataTestid:"juridisk-eier-navn"},
    { key: `${isLeasingtaker ? "Leasingtaker" : "Medeier"}`, value: [isLeasingtaker? leasingtaker : medeier], dataTestid: "leasingtaker-navn"},
    { key: "Telefon", value: [telefon], dataTestid: "eier-telefon" },
    { key: "Gateadresse", value: [adresse], dataTestid: "eier-adresse" },
    { key: "Postnr/-sted", value: [postnrOgSted], dataTestid: "eier-postnrOgsted" },
  ];

  return <KeyValueKortInnhold keyValuePair={keyValuePairs} keyWidth="w-40" />;
};
