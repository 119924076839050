import * as yup from "yup";


export const inntauingfilterSchema = yup.object().shape({
    sok: yup.string(),
    datoFra: yup
      .date()
      .nullable(),
    datoTil: yup
      .date()
      .nullable(),
    inntauetFra: yup.string(),
    flyttetTil: yup.string(),
    brevtyper: yup
    .array()
    .of(
      yup.number()
    ),
    statuskoder: yup
      .array()
      .of(
        yup.number()
      )
  });