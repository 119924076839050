import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSvv } from "../../hooks/useSvv";
import {
  getTextForSvvButton,
  HentFraSvvButton
} from "../common/form/SvvButton";
import { CustomError } from "../common/typography/CustomError";
import { FelterRedigerEierOgKjoretoyinfo } from "./redigerEierOgKjoretoyinfoUtils";

interface HentFraRegisterProps {
  infotype: "kjøretøy" | "eier";
}

export const HentFraRegister = (props: HentFraRegisterProps) => {
  const { infotype } = props;

  const [isKjoretoyinfoFetched, setIsKjoretoyinfoFetched] =
    useState<boolean>(false);
  const [isEierinfoFetched, setIsEierinfoFetched] = useState<boolean>(false);
  const [latestUpdatedRegnrOrRammenr, setLatestUpdatedRegnrOrRammenr] =
    useState<string | undefined>("");

  const { control, setValue } =
    useFormContext<FelterRedigerEierOgKjoretoyinfo>();

  const nasjon = useWatch({
    control,
    name: "nasjonalitet"
  });

  const regnr = useWatch({
    control,
    name: "registreringsnummer"
  });

  const rammenr = useWatch({
    control,
    name: "rammenummer"
  });

  const { data, isLoading, isError, error, isIdle } = useSvv(
    latestUpdatedRegnrOrRammenr || ""
  );

  const handleHentInfo = () => {
    if (infotype === "kjøretøy") {
      setValue("registreringsnummer", data?.registreringsnummer);
      setValue("rammenummer", data?.rammenummer);
      setValue("type", data?.kjoretoystype.kode);
      setValue("merke", data?.merke);
      setValue("modell", data?.modell);
      setValue("registrertAar", data?.registrertAar);
      setValue("totalvekt", data?.totalvekt);
      setValue("farge", data?.farge);

      setIsKjoretoyinfoFetched(true);
    }

    if (infotype === "eier") {
      const juridiskEier = data?.juridiskEier;
      const leasingtaker = data?.leasingtaker;

      if (juridiskEier) {
        setValue("leaser", !!leasingtaker);
        setValue("juridiskEier.idNummer", juridiskEier.idNummer ?? "", {
          shouldValidate: true
        });
        setValue("juridiskEier.navn", juridiskEier.navn);
        setValue("juridiskEier.gateadresse", juridiskEier.gateadresse);
        setValue("juridiskEier.poststed", juridiskEier.poststed);
        setValue("juridiskEier.postnummer", juridiskEier.postnummer);
        setValue("juridiskEier.telefonnummer", juridiskEier.telefonnummer);
        setValue("juridiskEier.bedrift", !juridiskEier.erPerson);
        setIsEierinfoFetched(true);
      }

      if (leasingtaker) {
        setValue("leaser", true);
        setValue("leasingtaker.idNummer", leasingtaker.idNummer ?? "", {
          shouldValidate: true
        });
        setValue("leasingtaker.navn", leasingtaker.navn);
        setValue("leasingtaker.gateadresse", leasingtaker.gateadresse);
        setValue("leasingtaker.poststed", leasingtaker.poststed);
        setValue("leasingtaker.postnummer", leasingtaker.postnummer);
        setValue("leasingtaker.telefonnummer", leasingtaker.telefonnummer);
        setValue("leasingtaker.bedrift", !leasingtaker.erPerson);
        setIsEierinfoFetched(true);
      } else {
        setValue("leaser", false);
        setValue("leasingtaker", undefined);
        setIsEierinfoFetched(true);
      }
    }
  };

  useEffect(() => {
    setLatestUpdatedRegnrOrRammenr(regnr);
  }, [regnr]);

  useEffect(() => {
    setLatestUpdatedRegnrOrRammenr(rammenr);
  }, [rammenr]);

  useEffect(() => {
    setIsKjoretoyinfoFetched(false);
    setIsEierinfoFetched(false);
  }, [regnr, rammenr, nasjon]);

  return (
    <>
      <HentFraSvvButton
        type="button"
        onClick={handleHentInfo}
        isLoading={isLoading}
        disabled={
          isLoading ||
          (infotype === "kjøretøy"
            ? isKjoretoyinfoFetched
            : isEierinfoFetched) ||
          nasjon !== "N" ||
          isError ||
          isIdle
        }
      >
        {getTextForSvvButton(
          !!data,
          nasjon ?? "",
          (isError && (error as Error).message.includes("404")) || isIdle
            ? true
            : false,
          isLoading
        )}
      </HentFraSvvButton>
      {isError && !(error as Error).message.includes("404") && (
        <div className="my-5">
          <CustomError error={(error as Error).message} />
        </div>
      )}
    </>
  );
};
