import { BetalingskravStatusType } from "../../api/interfaces/Betalingskrav";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";

export const getBetalingsdata = (inntauing: InntauingViewModel) => {
  const sumFlyttekostnad =
    (inntauing.betalingskrav?.inntauingsgebyr ?? 0) +
    (inntauing.betalingskrav?.administrasjonsgebyr ?? 0);

  return {
    sumUtestaendeKostnader: inntauing.utestaaendeKostnader.sum,
    betalt: inntauing.betalinger.reduce(
      (prev, curr) => prev + curr.totalsum,
      0
    ),

    utestaendeKostnadInntauing:
      (inntauing.utestaaendeKostnader.inntauingskostnad?.pris ?? 0) +
      (inntauing.utestaaendeKostnader.dognleiekostnad?.pris ?? 0) +
      (inntauing.utestaaendeKostnader.administrasjonskostnad?.pris ?? 0),
    utestaendeKostnaderIleggelsesgebyr:
      (inntauing.utestaaendeKostnader.ileggelsesgebyr?.pris ?? 0) +
      (inntauing.utestaaendeKostnader.ileggelsestilleggsgebyr?.pris ?? 0),

    betaltFlyttekostnad:
      inntauing.betalingskrav?.status === BetalingskravStatusType.Betalt
        ? sumFlyttekostnad
        : 0,
    utestaendeFlyttekostnad:
      inntauing.betalingskrav?.status === BetalingskravStatusType.Betalt ||
      inntauing.betalingskrav?.status ===
        BetalingskravStatusType.SkalIkkeBetales
        ? 0
        : sumFlyttekostnad
  };
};
