import { AxiosResponse } from "axios";
import { apiClient } from "../client";
import {
  InntauingsIdViewModel,
  InntauingViewModel
} from "../interfaces/Inntauing";
import { PaginationViewModel } from "../interfaces/PaginationViewModel";
import { config } from "./Config";
import { queryDate } from "./serviceUtils";

const client = apiClient();

export const postOpprettTaksering = (inntauingIder: InntauingsIdViewModel[]) =>
  client.post("takst/klare", inntauingIder, config());

export const deleteTaksering = (inntauingsIder: string[]) =>
  client.delete<
    { id: string }[],
    AxiosResponse<{
      inntauingerIkkeFunnet: string[] | undefined;
      inntauingerFunnet: string[] | undefined;
    }>
  >("takst/klare", {
    ...config(),
    data: inntauingsIder.map(i => {
      return { id: i };
    })
  });

export const getInntauingerMedTaksering = () =>
  client.get<InntauingViewModel[]>("takst/klare", config());

export const getInntauingerFerdigTaksert = (
  cursor: number,
  pageSize: number,
  fraDato?: Date,
  tilDato?: Date
) =>
  client.get<PaginationViewModel<InntauingViewModel>>(
    `takst/taksert?page=${cursor}&pageSize=${pageSize}` +
      queryDate("fra", fraDato) +
      queryDate("til", tilDato),
    config()
  );

export const putTaksering = (inntauingId: string, taksering: FormData) =>
  client.put(`takst/taksert/${inntauingId}`, taksering, config());
