exports.COLORS = {
    "darkblue": "#2a2859",
    "hoverblue": "#1f42aa",
    "rusticyellow": "#f9c66b",
    "rusticbeige": "#F4DCB1",
    "confirmgreen": "#43f8b6",
    "errorred": "#ff8274",
    "link-hoverblue": "#6fe9ff",
    "successgreen": "#c7f6c9",
    "unknowngray": "#d9d9d9",
    "disabledlightgray": "#f2f2f2",
    "disabledgray": "#4d4d4d",
}