import { BetalingViewModel } from "../../api/interfaces/Betaling";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { finnEier } from "../../utils/objectMapping";
import { BetalingsspesifikasjonLinje } from "./BetalingsspesifikasjonLinje";

interface KvitteringOppsummeringProps {
  inntauing?: InntauingViewModel;
  betaling?: BetalingViewModel;
}

export const KvitteringOppsummering = (props: KvitteringOppsummeringProps) => {
  const { inntauing, betaling } = props;

  const eier = finnEier(
    inntauing?.kjoretoy.juridiskEier,
    inntauing?.kjoretoy.medeier,
    inntauing?.kjoretoy.leasingtaker
  );

  return (
    <>
      <BetalingsspesifikasjonLinje
        navn="Tidspunkt"
        belop={
          betaling?.betalingstidspunkt
            ? new Date(betaling.betalingstidspunkt).toLocaleString()
            : ""
        }
        understrek="ingen"
      />
      <BetalingsspesifikasjonLinje
        navn="Ekspeditør"
        belop={betaling?.utfortAv ?? ""}
        understrek="ingen"
      />
      <BetalingsspesifikasjonLinje
        navn="Vår ref"
        title="Vår referanse (betalingsnummer)"
        belop={(betaling?.betalingsnummer ?? "").toString()}
        understrek="ingen"
      />
      <BetalingsspesifikasjonLinje
        navn="Regnr"
        title="Registreringsnummer"
        belop={betaling?.kjennemerke ?? ""}
        understrek="ingen"
      />
      <BetalingsspesifikasjonLinje
        navn="Eiernavn"
        belop={eier?.navn ?? ""}
        understrek="ingen"
      />
      <BetalingsspesifikasjonLinje
        navn="Eier fødselsdato"
        belop={
          eier?.fodselsdato
            ? new Date(eier.fodselsdato).toLocaleDateString()
            : ""
        }
        understrek="ingen"
      />
      <BetalingsspesifikasjonLinje
        navn="Betalers navn"
        belop={betaling?.betaltAvNavn ?? ""}
        understrek="ingen"
      />
      <BetalingsspesifikasjonLinje
        navn="Betalers fødselsdato"
        belop={
          betaling?.betaltAvFodselsdato
            ? new Date(betaling.betaltAvFodselsdato).toLocaleDateString()
            : ""
        }
        understrek="ingen"
      />
    </>
  );
};
