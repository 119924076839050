import { Controller, useFormContext } from "react-hook-form";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { RequiredLabel } from "../common/form/RequiredLabel";
import { Felter, getBetalingsmetoder } from "./utleveringUtils";

interface RegistrerUtleveringBetalingsmetodeProps {
  inntauing: InntauingViewModel;
}

export const RegistrerUtleveringBetalingsmetode = (
  props: RegistrerUtleveringBetalingsmetodeProps
) => {
  const { inntauing } = props;

  const {
    control,
    formState: { errors },
    setValue
  } = useFormContext<Felter>();

  return (
    <div className="ods-form-group">
      <label htmlFor="betalingsmetode" className="ods-form-label flex flex-row">
        Betalingsmetode
        <RequiredLabel />
      </label>
      <Controller
        control={control}
        name="betalingsmetode"
        render={({ field }) => (
          <select
            {...field}
            data-testid="velg-betalingsmetode"
            id="betalingsmetode"
            className={`ods-form-input ods-select w-80 ${
              errors.betalingsmetode?.message
                ? "border-errorred focus:border-errorred"
                : ""
            }`}
            onChange={e => {
              field.onChange(e);
              setValue("justerDognleie", false, {
                shouldValidate: Object.entries(errors).length > 0
              });
              setValue("nyDognleie", undefined, {
                shouldValidate: Object.entries(errors).length > 0
              });
            }}
          >
            <option value="">Velg betalingsmetode</option>
            {getBetalingsmetoder(inntauing).map(betalingsmetode => (
              <option key={betalingsmetode} value={betalingsmetode}>
                {betalingsmetode}
              </option>
            ))}
          </select>
        )}
      />
    </div>
  );
};
