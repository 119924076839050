import { ReactComponent as Vedlegg } from "../../assets/icons/vedlegg.svg";
import { Kort } from "../common/card/Kort";
import { IleggelsesinformasjonLenker } from "./IleggelsesinformasjonLenker";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";

interface IleggelsesinformasjonProps {
  inntauing: InntauingViewModel;
  height?: string;
}

export const Ileggelsesinformasjon = (props: IleggelsesinformasjonProps) => {
  const { inntauing, height = props.height ?? "h-full" } = props;

  return (
    <Kort
      headerText="Lenker"
      headerIcon={<Vedlegg className="fill-darkblue" />}
      height={height}
      innhold={
        <IleggelsesinformasjonLenker
          ileggelsesId={inntauing?.ileggelse?.ileggelsesId ?? ""}
          arkivsaksId={inntauing?.arkivReferanseId ?? ""}
        />
      }
    />
  );
};
