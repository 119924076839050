import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getAvstemminger,
  getUavstemte,
  postAvstemming
} from "../api/apiServices/AvstemmingService";
import { AvstemmingInputModel } from "../api/interfaces/Avstemming";
import { CACHE_KEYS } from "../utils/globalConstants";

export const useAvstemmingsgrunnlag = (tidspunktOpptelling: Date) =>
  useQuery([CACHE_KEYS.Avstemmingsgrunnlag, tidspunktOpptelling], () =>
    getUavstemte(tidspunktOpptelling).then(r => r.data)
  );

export const useAvstemminger = (datoFra: Date, datoTil: Date) =>
  useQuery([CACHE_KEYS.Arkiverte_avstemminger, datoFra, datoTil], () =>
    getAvstemminger(datoFra, datoTil).then(r => r.data)
  );

export const useAvstem = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { avstemming: AvstemmingInputModel }) => {
      return postAvstemming(variables.avstemming).then(r => r.data);
    },
    {
      onSuccess: async () => {
        return Promise.all([
          await queryClient.invalidateQueries([CACHE_KEYS.Avstemmingsgrunnlag]),
          await queryClient.invalidateQueries([
            CACHE_KEYS.Arkiverte_avstemminger
          ])
        ]);
      }
    }
  );
};
