import { useFlyttingerMedFeiledeBetalingskrav } from "../../hooks/useInntauinger";
import { Notifikasjon } from "../common/notifikasjon/Notifikasjon";

export const FlyttingerNotifikasjon = () => {
  const { data } = useFlyttingerMedFeiledeBetalingskrav();

  const antall = data?.totalCount ?? 0;

  return (
    <>
      {antall > 0 && (
        <Notifikasjon
          title={`${antall} feilede betalingskrav`}
          lengde={antall.toString().length}
        >
          {antall}
        </Notifikasjon>
      )}
    </>
  );
};
