import { HeadingView } from "../../common/typography/HeadingView";
import React from "react";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import { useAvstemmingsgrunnlag } from "../../../hooks/useAvstemming";
import { CustomError } from "../../common/typography/CustomError";
import { RequiredLabel } from "../../common/form/RequiredLabel";
import DatePicker from "../../common/form/DatePicker";
import { startOfDay } from "date-fns";
import { BetalingerPerEkspeditor } from "./BetalingerPerEkspeditor";
import { OppbruttAvstemming } from "./OppbruttAvstemming";
import { KasseavstemmingSkjema } from "./KasseavstemmingSkjema";
import { AlertBox } from "../../common/form/AlertBox";
import { LoadingSpinner } from "../../common/loading/LoadingSpinner";
import { useStateParams } from "../../../hooks/useStateParams";

export const Kasseavstemming = () => {
  const [tidspunktOpptelling, setTidspunktOpptelling] = useStateParams(
    startOfDay(new Date()),
    "tidspunkt",
    s => s.toISOString(),
    s => new Date(s)
  );

  const { data, isLoading, isError, error } =
    useAvstemmingsgrunnlag(tidspunktOpptelling);

  const handleTidspunkt = (date: Date | null) =>
    setTidspunktOpptelling(date ?? new Date());

  return (
    <>
      <HeadingView>Kasseavstemming</HeadingView>
      <div className="ods-form-group mb-10">
        <label
          className="ods-form-label w-fit flex flex-wrap"
          htmlFor="tidspunktOpptelling"
        >
          Tidspunkt for opptelling
          <RequiredLabel />
        </label>
        <div className="flex relative items-center justify-start focus-within:fill-hoverblue">
          <DatePicker
            id="tidspunktOpptelling"
            dateFormat="dd.MM.yyyy, HH:mm:ss"
            showTimeInput
            onChange={handleTidspunkt}
            selected={tidspunktOpptelling}
            customInput={
              <input
                autoFocus
                autoComplete="off"
                className="ods-form-input w-64"
                data-testid="tidspunkt-opptelling-datepicker"
              />
            }
          />
          <CalendarIcon className="pointer-events-none absolute ml-[14.25rem] h-5 w-5" />
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      {data && (
        <>
          {data.ekspeditorBetalinger?.length === 0 ? (
            <div className="mb-10">
              <AlertBox
                alertLevel="warning"
                heading="Finner ingen uavstemte betalinger for denne perioden"
              />
            </div>
          ) : (
            <BetalingerPerEkspeditor
              ekspeditorBetalinger={data.ekspeditorBetalinger}
            />
          )}
          <OppbruttAvstemming
            inntektSystem={data.inntektSystem}
            inntektKasse={data.inntektKasseapparat}
            inntektDifferanse={data.inntektDifferanse}
          />
          <KasseavstemmingSkjema
            zRapporter={data.zRapporter ?? []}
            inkluderteBetalingsnummer={(data.ekspeditorBetalinger ?? [])
              .flatMap(x => x.betalinger)
              .map<number>(i => i.betalingsnummer)}
            avstemtTil={tidspunktOpptelling}
            erDifferanse={(data.inntektDifferanse?.total ?? 0) !== 0}
          />
        </>
      )}
    </>
  );
};
