import React from "react";

export const handleNumberOrEmptyValueInput = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  // Godtar kun tall eller tom verdi
  if (
    !/^(0|[1-9]\d*)/.test(e.key) &&
    e.key !== "" &&
    e.key !== "Backspace" &&
    e.key !== "Tab"
  )
    e.preventDefault();
};

export const handleTrykkEnter = (
  e: React.KeyboardEvent<any>,
  cb?: () => void,
  preventDefault?: boolean
) => {
  if (e.key === "Enter" && cb) {
    if (preventDefault) e.preventDefault();
    cb();
  }
};

export const handleTrykk = (
  e: React.KeyboardEvent<HTMLElement>,
  keyCallbackDict: {
    [key: string]: (e: React.KeyboardEvent<HTMLElement>) => void;
  }
) => {
  const callback = keyCallbackDict[e.key];
  if (callback != null) {
    callback(e);
  }
};
