import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/globalConstants";
import { isEkspeditor, isSaksbehandler } from "../../utils/userRolesUtils";

interface IngenBrevsporingProps {
  inntauingsId: string;
}

export const IngenBrevsporing = (props: IngenBrevsporingProps) => {
  const { inntauingsId } = props;

  const navigate = useNavigate();

  const sendBrev = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    navigate(`${ROUTES.ManuellBrevutsendelse}/${inntauingsId}`);
  };

  return (
    <div className="grid gap-y-2">
      <h3 className="text-lg font-semibold">Ingen brevsporing å vise</h3>
      <p className="text-lg">
        For å se brevsporinger må ett eller flere brev være sendt.
      </p>
      {(isSaksbehandler() || isEkspeditor()) && (
        <button
          title="Gå til valg for sending av brev"
          className="btn btn--secondary"
          onClick={sendBrev}
        >
          Send brev
        </button>
      )}
    </div>
  );
};
