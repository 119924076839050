import { EierOgKjoretoysinformasjon } from "../../eierOgKjoretoysinformasjon/EierOgKjoretoysinformasjon";
import Handlingslogg from "../../handlingslogg/Handlingslogg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-thin.svg";
import { useState } from "react";
import { AlertBoxSmall } from "../../common/form/AlertBox";
import {
  useGodkjennFaktura,
  useRegistrerManueltFakturert
} from "../../../hooks/useFaktura";
import {
  feilLengdePaaIdentifikator,
  feilPaaIdentifikator,
  feilPaaReferanse,
  Felter,
  feilLengdePaaReferanse,
  visFeilPaaMinstEttFelt,
  formaterTekstSubmitKnapp,
  formaterTittelIdentifikator
} from "./fakturagodkjennelseUtils";
import { Betalingsspesifikasjon } from "../../betalingsspesifikasjon/Betalingsspesifikasjon";
import { useToast } from "../../common/toast/ToastProvider";
import { formaterNullableBelop } from "../../../utils/formatUtils";
import { FakturagodkjennelseViewModel } from "../../../api/interfaces/Faktura";
import { Toggle } from "../../common/form/Toggle";
import { handleNumberOrEmptyValueInput } from "../../../utils/keyboardUtils";
import { isSaksbehandler } from "../../../utils/userRolesUtils";

interface FakturagodkjennelseRadProps {
  fakturagodkjenning: FakturagodkjennelseViewModel;
  valgtRad: string;
  velgRad: (radId: string) => void;
}

export const FakturagodkjennelseRad = (props: FakturagodkjennelseRadProps) => {
  const { fakturagodkjenning, valgtRad, velgRad } = props;

  const [felter, setFelter] = useState<Felter>({
    erAutomatiskFakturering: true,
    identifikator: "",
    referanse: ""
  });
  const [valideringAktiv, setValideringAktiv] = useState<boolean>(false);

  const putGodkjennFaktura = useGodkjennFaktura();
  const putRegistrerManueltFakturert = useRegistrerManueltFakturert();
  const { addToast } = useToast();

  const erKlarForInnsending = (): boolean => {
    if (
      feilLengdePaaIdentifikator(felter).visFeil ||
      feilLengdePaaReferanse(felter.referanse).visFeil
    ) {
      setValideringAktiv(true);
      return false;
    }

    if (!fakturagodkjenning.betaling.faktura) {
      addToast("Finner ingen faktura knyttet til betaling", "error");
      return false;
    }

    return true;
  };

  const godkjennFaktura = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    if (!erKlarForInnsending()) return;

    putGodkjennFaktura.mutate(
      {
        fakturanummer: fakturagodkjenning.betaling.faktura?.fakturanummer ?? -1,
        betaling: {
          mottakeridentifikator: felter.identifikator,
          mottakerReferanse: felter.referanse
        }
      },
      {
        onSuccess: () => {
          addToast("Faktura sendt til avdeling for regnskap");
        },
        onError: () => {
          addToast("Faktura ble ikke sendt til avdeling for regnskap", "error");
        }
      }
    );
  };

  const registrerManueltFakturert = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    if (!erKlarForInnsending()) return;

    putRegistrerManueltFakturert.mutate(
      {
        fakturanummer: fakturagodkjenning.betaling.faktura?.fakturanummer ?? -1,
        betaling: {
          mottakeridentifikator: felter.identifikator,
          mottakerReferanse: felter.referanse
        }
      },
      {
        onSuccess: () => {
          addToast("Manuell fakturering registrert");
        },
        onError: () => {
          addToast("Manuell fakturering ble ikke registrert", "error");
        }
      }
    );
  };

  const visRaddetaljer = () => {
    return (
      valgtRad ===
      (fakturagodkjenning.betaling.faktura?.fakturanummer.toString() ?? "")
    );
  };

  const radId = () => {
    return fakturagodkjenning.betaling.faktura?.fakturanummer.toString() ?? "";
  };

  const handleVelgRad = () => velgRad(visRaddetaljer() ? "" : radId());

  const handleVelgRadEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") handleVelgRad();
  };

  const handleIdentifikator = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFelter({ ...felter, identifikator: e.currentTarget.value });

  const handleReferanse = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFelter({ ...felter, referanse: e.currentTarget.value });

  return (
    <>
      <tr
        tabIndex={0}
        className={`w-full ${visRaddetaljer() ? "bg-gray-100" : ""} h-14`}
        onClick={handleVelgRad}
        onKeyDown={handleVelgRadEnterKey}
      >
        <td className="w-8 h-6 pl-2 pr-1">
          <ArrowDown
            className={`w-6 h-6 ${
              visRaddetaljer()
                ? "animate-accordion-arrow-open"
                : "animate-accordion-arrow-close"
            }`}
          />
        </td>
        <td>{fakturagodkjenning.betaling.betalingsnummer}</td>
        <td>{fakturagodkjenning.betaling.kjennemerke}</td>
        <td>{formaterNullableBelop(fakturagodkjenning.betaling.totalsum)}</td>
        <td className="pr-5 w-52 py-2">
          <div
            className="ods-form-group m-0"
            onClick={e => e.stopPropagation()}
          >
            <Toggle
              testid={`toggle-metode-fakturering-${fakturagodkjenning.kjoretoy.registreringsnummer}`}
              title="Velg om faktura skal sendes automatisk eller manuelt"
              leftText="Automatisk"
              rightText="Manuell"
              toggleFunction={e => {
                setValideringAktiv(false);
                setFelter(prev => ({ ...prev, erAutomatiskFakturering: e }));
              }}
            />
          </div>
        </td>
        <td className="pr-5 w-52 py-2">
          <div className="ods-form-group m-0">
            <input
              data-testid={`input-identifikator-${fakturagodkjenning.kjoretoy.registreringsnummer}`}
              title={
                feilPaaIdentifikator(valideringAktiv, felter).visFeil
                  ? feilPaaIdentifikator(valideringAktiv, felter).melding
                  : formaterTittelIdentifikator(felter.erAutomatiskFakturering)
              }
              className={`ods-form-input ${
                feilPaaIdentifikator(valideringAktiv, felter).visFeil &&
                "border-errorred focus:border-errorred"
              }`}
              value={felter.identifikator}
              onKeyDown={
                felter.erAutomatiskFakturering
                  ? e => handleNumberOrEmptyValueInput(e)
                  : undefined
              }
              onChange={handleIdentifikator}
              onClick={e => e.stopPropagation()}
            />
            {feilPaaIdentifikator(valideringAktiv, felter).visFeil && (
              <AlertBoxSmall
                alertLevel="error"
                heading={feilPaaIdentifikator(valideringAktiv, felter).melding}
              />
            )}
          </div>
        </td>
        <td className="pr-5 w-64 py-2">
          <div className="ods-form-group m-0">
            <input
              data-testid={`input-referanse-${fakturagodkjenning.kjoretoy.registreringsnummer}`}
              title={
                feilPaaReferanse(valideringAktiv, felter.referanse).visFeil
                  ? feilPaaReferanse(valideringAktiv, felter.referanse).melding
                  : "Skriv inn referanse fra mottaker på maks 25 tegn"
              }
              className={`ods-form-input ${
                feilPaaReferanse(valideringAktiv, felter.referanse).visFeil &&
                "border-errorred focus:border-errorred"
              }`}
              value={felter.referanse}
              onChange={handleReferanse}
              onClick={e => e.stopPropagation()}
            />
            {feilPaaReferanse(valideringAktiv, felter.referanse).visFeil && (
              <AlertBoxSmall
                alertLevel="error"
                heading={
                  feilPaaReferanse(valideringAktiv, felter.referanse).melding
                }
              />
            )}
          </div>
        </td>
        <td>
          {isSaksbehandler() && <button
            data-testid={`godkjenn-faktura-${fakturagodkjenning.kjoretoy.registreringsnummer}`}
            title={
              visFeilPaaMinstEttFelt(valideringAktiv, felter)
                ? "Feil på felter"
                : formaterTekstSubmitKnapp(felter.erAutomatiskFakturering)
            }
            className="btn btn--primary"
            onClick={
              felter.erAutomatiskFakturering
                ? e => godkjennFaktura(e)
                : e => registrerManueltFakturert(e)
            }
            disabled={visFeilPaaMinstEttFelt(valideringAktiv, felter)}
          >
            {formaterTekstSubmitKnapp(felter.erAutomatiskFakturering)}
          </button>}
        </td>
      </tr>
      {visRaddetaljer() && (
        <tr className="bg-gray-100">
          <td colSpan={7}>
            <div className="grid laptop:grid-cols-1 grid-cols-3 gap-2 m-3">
              <div className="laptop:col-span-1">
                <Betalingsspesifikasjon
                  hentersNavn={fakturagodkjenning.henter?.navn}
                  hentersFodselsdato={fakturagodkjenning.henter?.fodselsdato}
                  administrasjonsgebyr={
                    fakturagodkjenning.betaling.betalteGebyrer
                      .administrasjonsgebyr
                  }
                  inntauingskostnad={
                    fakturagodkjenning.betaling.betalteGebyrer.inntauingsgebyr
                  }
                  dognleie={fakturagodkjenning.betaling.betalteGebyrer.dognleie}
                  totalsum={fakturagodkjenning.betaling.totalsum}
                />
              </div>
              <div className="laptop:col-span-1 col-span-2">
                <EierOgKjoretoysinformasjon
                  inntauingsId={fakturagodkjenning.inntauingsId}
                  kjoretoy={fakturagodkjenning.kjoretoy}
                />
              </div>
              <div className="laptop:col-span-1 col-span-3">
                <Handlingslogg inntauingsId={fakturagodkjenning.inntauingsId} />
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
