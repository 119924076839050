import { ZRapportViewModel } from "../../../api/interfaces/Avstemming";

export interface Felter {
  avvikskommentar: string;
  zRapporter: { zNummer: string; sesjonsnummer: string }[];
}

export const getFieldsFromZRapporter = (zRapporter: ZRapportViewModel[]) => {
  if (zRapporter.length === 0) return [];

  return zRapporter.map(i => {
    return {
      zNummer: i.nummer.toString(),
      sesjonsnummer: ""
    };
  });
};
