interface NotifikasjonProps extends React.HTMLAttributes<HTMLDivElement> {
  lengde: number;
  stolpemeny?: boolean;
}

export const Notifikasjon = ({
  lengde,
  stolpemeny = true,
  ...rest
}: NotifikasjonProps) => {
  return (
    <div
      className={`${
        stolpemeny === true || stolpemeny === undefined ? "absolute" : ""
      } inline-block -top-[0.25rem] -right-3 h-fit w-fit rounded-xl bg-red-600 text-white text-xs items-center justify-center ${
        lengde === 1 ? "px-[0.4rem]" : "px-[0.2rem]"
      } pb-[0.05rem] pt-[0.15rem]`}
      {...rest}
    />
  );
};
