import { useInntauing } from "../../hooks/useInntauinger";

interface ListeTilNedlastingProps {
  inntauingsIder: string[];
}

export const ListeTilNedlasting = (props: ListeTilNedlastingProps) => {
  const { inntauingsIder } = props;

  return (
    <table className="tbl">
      <thead>
        <tr>
          <th>Regnr</th>
          <th>Merke</th>
          <th>Modell</th>
          <th>Regår</th>
          <th>Type</th>
          <th>Inntauet fra</th>
        </tr>
      </thead>
      <tbody>
        {inntauingsIder.map(id => (
          <ListeTilNedlastingRad key={id} inntauingsId={id} />
        ))}
      </tbody>
    </table>
  );
};

interface ListeTilNedlastingRadProps {
  inntauingsId: string;
}

const ListeTilNedlastingRad = (props: ListeTilNedlastingRadProps) => {
  const { inntauingsId } = props;

  const { data, isLoading } = useInntauing(inntauingsId);

  if (isLoading) {
    return <tr>Laster inntauing...</tr>;
  }

  return (
    <tr>
      <td>{data?.kjoretoy.registreringsnummer}</td>
      <td>{data?.kjoretoy.merke}</td>
      <td>{data?.kjoretoy.modell}</td>
      <td>{data?.kjoretoy.registrertAar}</td>
      <td>{data?.kjoretoy.kjoretoystype.kode}</td>
      <td>{data?.inntauingsgrunnlag.lokasjon.gatenavn}</td>
    </tr>
  );
};
