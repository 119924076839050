import { useNavigate } from "react-router-dom";
import { Step, StepperLayout } from "../../common/stepper/StepperLayout";
import { HeadingView } from "../../common/typography/HeadingView";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-thin.svg";
import { NyPrislisteLeggTil } from "./NyPrislisteLeggTil";
import { NyPrislisteOppsummering } from "./NyPrislisteOppsummering";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useOpprettPrisliste, usePrislister } from "../../../hooks/usePris";
import { AlertBox } from "../../common/form/AlertBox";
import { useToast } from "../../common/toast/ToastProvider";
import { ROUTES } from "../../../utils/globalConstants";
import { addDays } from "date-fns/esm";
import { nyPrislisteSchema } from "./nyPrislisteSchema";
import { LoadingSpinner } from "../../common/loading/LoadingSpinner";
import { CustomError } from "../../common/typography/CustomError";
import { antallFeilISkjema } from "./prislisteUtils";
import { Betalingsgruppe } from "../../../api/interfaces/Inntauing";

export interface FelterPrisliste {
  gyldigFra?: Date;
  administrasjonsgebyr: {
    pris: number;
    varenummer: number;
  };
  administrasjonsgebyrFlytting: {
    pris: number;
    varenummer: number;
  };
  dognleie: {
    pris: number;
    varenummer: number;
  };
  inntauingskostnader: {
    pris: number;
    gebyrtype: Betalingsgruppe;
    varenummer: number;
  }[];
}

export const NyPrisliste = () => {
  const navigate = useNavigate();

  const { data, isLoading, isError, error } = usePrislister();

  const methods = useForm<FelterPrisliste>({
    defaultValues: {
      gyldigFra: addDays(new Date(), 1),
      administrasjonsgebyr: data?.[0].administrasjonsgebyr,
      administrasjonsgebyrFlytting: data?.[0].administrasjonsgebyrFlytting,
      dognleie: data?.[0].dognleie,
      inntauingskostnader: data?.[0].inntauingskostnader ?? []
    },
    resolver: yupResolver(nyPrislisteSchema),
    mode: "all",
    reValidateMode: "onChange",
    shouldUseNativeValidation: true,
    shouldFocusError: true
  });

  const postPrisliste = useOpprettPrisliste();

  const { addToast } = useToast();

  const handleOpprettPrisliste = (data: FelterPrisliste) => {
    if (!data.gyldigFra) return;

    postPrisliste.mutate(
      {
        prisliste: {
          gyldigFra: data.gyldigFra,
          administrasjonsgebyr: data.administrasjonsgebyr,
          administrasjonsgebyrFlytting: data.administrasjonsgebyrFlytting,
          dognleie: data.dognleie,
          inntauingskostnader: data.inntauingskostnader
        }
      },
      {
        onSuccess: () => {
          addToast(
            `Ny prisliste ble opprettet og gjelder fra ${data.gyldigFra?.toLocaleDateString()}`
          );
          navigate(ROUTES.Innstillinger + "/prislister");
        },
        onError: () => {
          addToast("Ny prisliste ble ikke opprettet", "error");
        }
      }
    );
  };

  const steps: Step[] = [
    {
      id: "leggtil",
      label: "Legg til",
      component: () => NyPrislisteLeggTil(),
      cancelStepButton: AvbrytKnapp,
      nextStepButton:
        antallFeilISkjema(methods.formState.errors) > 0
          ? () =>
              OppsummeringKnapp({
                onClick: () => methods.trigger(),
                disabled: true
              })
          : OppsummeringKnapp
    },
    {
      id: "oppsummering",
      label: "Oppsummering",
      component: () =>
        NyPrislisteOppsummering({
          prisliste: data?.[0],
          feilmelding: Feilmelding({
            isError: postPrisliste.isError,
            errorMessage: postPrisliste.isError
              ? (postPrisliste.error as Error).message
              : ""
          })
        }),
      previousStepButton: TilbakeKnapp,
      confirmStepButton: BekreftKnapp
    }
  ];

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleOpprettPrisliste)}
        className="flex flex-col mx-10 mb-16"
      >
        <HeadingView>Legg til ny prisliste</HeadingView>
        {isLoading && <LoadingSpinner />}
        {isError && <CustomError error={(error as Error).message} />}
        {data && data.length > 0 && <StepperLayout steps={steps} />}
      </form>
    </FormProvider>
  );
};

interface FeilmeldingProps {
  isError: boolean;
  errorMessage: string;
}

const Feilmelding = (props: FeilmeldingProps) => {
  const { isError, errorMessage } = props;

  if (!isError) return undefined;

  return (
    <div className="mt-5">
      <AlertBox alertLevel="error" heading={errorMessage} />
    </div>
  );
};

const AvbrytKnapp = () => {
  const navigate = useNavigate();

  return (
    <button
      className="btn btn--flat btn--icon-left"
      type="button"
      onClick={() => navigate(-1)}
    >
      <ArrowDown className="fill-current inline-flex rotate-[90deg]" />
      <span>Avbryt</span>
    </button>
  );
};

const TilbakeKnapp = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button {...props} className="btn btn--flat btn--icon-left" type="button">
      <ArrowDown className="fill-current inline-flex rotate-[90deg]" />
      <span>Tilbake</span>
    </button>
  );
};

const OppsummeringKnapp = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  return (
    <button
      {...props}
      data-testid="oppsummering-knapp"
      className="btn btn--primary btn--icon-right"
      type="button"
    >
      <ArrowDown className="fill-current inline-flex rotate-[-90deg]" />
      <span>Oppsummering</span>
    </button>
  );
};

const BekreftKnapp = () => {
  return (
    <button
      data-testid="bekreft-knapp"
      className="btn btn--primary btn--icon-right"
      type="submit"
    >
      <ArrowDown className="fill-current inline-flex rotate-[-90deg]" />
      <span>Bekreft</span>
    </button>
  );
};
