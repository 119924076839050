import { useMutation, useQueryClient } from "react-query";
import { putKjoretoy } from "../api/apiServices/KjoretoyService";
import { KjoretoyInputModel } from "../api/interfaces/Inntauing";
import { CACHE_KEYS } from "../utils/globalConstants";

export const useRedigerKjoretoyMedEier = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { kjoretoy: KjoretoyInputModel }) =>
      putKjoretoy(id, variables.kjoretoy),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CACHE_KEYS.Eier_med_fodselsnummer);
        return await queryClient.invalidateQueries(
          CACHE_KEYS.Aktive_inntauinger
        );
      }
    }
  );
};
