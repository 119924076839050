import { useSendtTilVrak } from "../../hooks/useVrak";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { CustomError } from "../common/typography/CustomError";
import { HeadingView } from "../common/typography/HeadingView";
import { SendtTilVrakTabell } from "./SendtTilVrakTabell";

export const SendtTilVrak = () => {
  const { data, isLoading, isError, error } = useSendtTilVrak();

  return (
    <div>
      <HeadingView>Sendt til vrak</HeadingView>
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      <SendtTilVrakTabell vrakliste={data} />
    </div>
  );
};
