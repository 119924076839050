import { AxiosResponse } from "axios";
import { apiClient } from "../client";
import {
  InntauingsIdViewModel,
  InntauingViewModel
} from "../interfaces/Inntauing";
import { InntauingBulkRequestResponseModel } from "../interfaces/InntauingBulkRequestResponseModel";
import { PaginationViewModel } from "../interfaces/PaginationViewModel";
import { config } from "./Config";

const client = apiClient();

export const getTilAuksjon = () =>
  client.get<InntauingViewModel[]>("auksjon/klare", config());

export const getSendtTilAuksjon = () =>
  client.get<InntauingViewModel[]>("auksjon/sendt", config());

export const getArkiverteAuksjoner = (
  cursor: number,
  pageSize: number,
  fraDato: Date,
  tilDato: Date
) =>
  client.get<PaginationViewModel<InntauingViewModel>>(
    `auksjon/auksjonert?page=${cursor}&pageSize=${pageSize}&fra=${fraDato?.toISOString()}&til=${tilDato?.toISOString()}`,
    config()
  );

export const putKlargjorAuksjon = (inntauingsId: string) =>
  client.put("auksjon/klare", { id: inntauingsId }, config());

export const putSendTilAuksjon = (inntauingsIder: string[]) =>
  client.put<
    InntauingsIdViewModel[],
    AxiosResponse<InntauingBulkRequestResponseModel>
  >(
    "auksjon",
    inntauingsIder.map(i => {
      return { id: i };
    }),
    config()
  );

export const putAuksjonert = (inntauingsId: string, dokumenter: FormData) =>
  client.put(`auksjon/${inntauingsId}/auksjonert`, dokumenter, config());

export const putAngreSendtTilAuksjon = (inntauingsId: string) =>
  client.put(
    `auksjon/sendtTilAuksjon/${inntauingsId}/angre`,
    undefined,
    config()
  );

export const putKunngjorAuksjon = (inntauingsIder: string[]) =>
  client.put<
    InntauingsIdViewModel[],
    AxiosResponse<InntauingBulkRequestResponseModel>
  >(
    `auksjon/kunngjor?opprettetKunngjoringTidspunkt=${new Date().toISOString()}`,
    inntauingsIder.map(i => {
      return { id: i };
    }),
    config()
  );

export const deleteTilAuksjon = (inntauingsIder: string[]) =>
  client.delete<
    InntauingsIdViewModel[],
    AxiosResponse<InntauingBulkRequestResponseModel>
  >("auksjon", {
    ...config(),
    data: inntauingsIder.map(i => {
      return { id: i };
    })
  });
