import { BetalingskravStatusType } from "../../../api/interfaces/Betalingskrav";
import { RegnskapBetalingskravViewModel } from "../../../api/interfaces/Regnskap";
import { useInntauing } from "../../../hooks/useInntauinger";
import { formaterNullableBelop } from "../../../utils/formatUtils";
import { finnEier } from "../../../utils/objectMapping";
import { AlertBox } from "../../common/form/AlertBox";
import { HeadingTopic } from "../../common/typography/HeadingTopic";

interface SendteBetalingskravProps {
  tableId: string;
  betalingskrav: RegnskapBetalingskravViewModel[];
}

export const SendteBetalingskrav = (props: SendteBetalingskravProps) => {
  const { tableId, betalingskrav } = props;

  return (
    <>
      <HeadingTopic>Sendte betalingskrav</HeadingTopic>
      {betalingskrav.length === 0 ? (
        <div className="mt-2">
          <AlertBox
            alertLevel="warning"
            heading="Ingen sendte betalingskrav for angitt periode"
          />
        </div>
      ) : (
        <table id={tableId} className="tbl max-w-8xl mt-2">
          <thead>
            <tr>
              <th title="Flyttenummer">Flyttenr</th>
              <th title="Kidnummer">Kidnr</th>
              <th>Mottaker</th>
              <th title="Registreringsnummer">Regnr</th>
              <th className="text-right">Flyttekostnad</th>
              <th title="Administrasjonsgebyr" className="text-right">
                Admin.gebyr
              </th>
              <th title="Generert betalingskrav">Generert</th>
              <th title="Betalingskrav mottatt av Ine">Mottatt av Ine</th>
              <th>Mottatt betaling</th>
            </tr>
          </thead>
          <tbody>
            {betalingskrav.map(b => (
              <SendteBetalingskravRad key={b.inntauingsId} betalingskrav={b} />
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

interface SendteBetalingskravRadProps {
  betalingskrav: RegnskapBetalingskravViewModel;
}

const SendteBetalingskravRad = (props: SendteBetalingskravRadProps) => {
  const { betalingskrav } = props;

  const { data: inntauing } = useInntauing(
    betalingskrav.flyttenummer ? "" : betalingskrav.inntauingsId
  );

  const eier = finnEier(
    inntauing?.kjoretoy.juridiskEier,
    inntauing?.kjoretoy.medeier,
    inntauing?.kjoretoy.leasingtaker
  );

  return (
    <tr>
      <td>{betalingskrav.flyttenummer ?? ""}</td>
      <td>{betalingskrav.kidnummer ?? ""}</td>
      <td>{betalingskrav.mottaker ?? eier?.navn ?? ""}</td>
      <td>
        {betalingskrav.registreringsnummer ??
          inntauing?.kjoretoy.registreringsnummer ??
          ""}
      </td>
      <td className="text-right">
        {betalingskrav.flyttekostnad
          ? formaterNullableBelop(betalingskrav.flyttekostnad)
          : ""}
      </td>
      <td className="text-right">
        {betalingskrav.administrasjonsgebyr
          ? formaterNullableBelop(betalingskrav.administrasjonsgebyr)
          : ""}
      </td>
      {betalingskrav.status === BetalingskravStatusType.SkalIkkeBetales ? (
        <td className="text-errorred">Kansellert</td>
      ) : (
        <td>
          {betalingskrav.generert
            ? new Date(betalingskrav.generert).toLocaleString()
            : ""}
        </td>
      )}
      <td>
        {betalingskrav.mottattAvIne
          ? new Date(betalingskrav.mottattAvIne).toLocaleString()
          : ""}
      </td>
      <td>
        {betalingskrav.betalingMottatt
          ? new Date(betalingskrav.betalingMottatt).toLocaleString()
          : ""}
      </td>
    </tr>
  );
};
