import { getHostFromEnv } from "../../api/client";
import { ReactComponent as NyttVindu } from "../../assets/icons/nyttvindu.svg";

interface IleggelsesinformasjonLenkerProps {
  ileggelsesId: string;
  arkivsaksId: string;
}

export const IleggelsesinformasjonLenker = (
  props: IleggelsesinformasjonLenkerProps
) => {
  const { ileggelsesId, arkivsaksId } = props;

  const hgkLenke = `${getHostFromEnv(
    "HGK_CLIENT_ILEGGELSE"
  )}/inntauing/ileggelser/${ileggelsesId}`;
  const websakLenke = `${getHostFromEnv("WEBSAK")}/${arkivsaksId}`;

  return (
    <dl className="flex flex-col ods-dl">
      {ileggelsesId && (
        <dt className="osg-sans-bold mb-4">
          <a
            href={hgkLenke}
            rel="noopener noreferrer"
            target="_blank"
            role="button"
            className="btn btn--link btn--icon-right btn--small"
          >
            <NyttVindu className="w-10" />
            Åpne ileggelsen i HGK
          </a>
        </dt>
      )}
      {arkivsaksId ? (
        <dt className="osg-sans-bold mb-4">
          <a
            href={websakLenke}
            rel="noopener noreferrer"
            target="_blank"
            role="button"
            className="btn btn--link btn--icon-right btn--small"
          >
            <NyttVindu className="w-10" />
            Åpne i WebSak
          </a>
        </dt>
      ) : (
        <dt>Kan ikke åpne i WebSak, mangler arkivreferanse-ID.</dt>
      )}
      {/* <dt className="osg-sans-bold mb-4 underline">
        <a
          // Legg til ileggelses-ID fra backend
          href={mwsLenke}
          rel="noopener noreferrer"
          target="_blank"
          role="button"
          className="btn btn--link btn--icon-right btn--small"
        >
          Åpne i MWS
          <NyttVindu className="fill-current w-10" />
        </a>
      </dt> */}
    </dl>
  );
};
