export const greenSquare = "bg-successgreen text-2xl font-medium text-center text-darkblue w-8 h-8 rounded";
export const graySquare = "bg-unknowngray text-2xl font-medium text-center text-darkblue w-8 h-8 rounded";
export const redSquare = "bg-errorred text-2xl font-medium text-center text-darkblue w-8 h-8 rounded";

export const isLessOrEq15Min = (timeDate: Date | undefined): boolean => {
  if (!timeDate) {
    return false;
  }

  const timeDateAsDate = new Date(timeDate);
  const now = new Date().getTime();
  const timeDifference = now - timeDateAsDate.getTime();

  const fifteenMinutesInMilliseconds = 15 * 60 * 1000;

  return timeDifference <= fifteenMinutesInMilliseconds;
}

export const isLessOrEq31Days = (timeDate: Date | undefined): boolean => {
  if (!timeDate) {
    return false;
  }

  const timeDateAsDate = new Date(timeDate);
  const now = new Date().getTime();
  const timeDifference = now - timeDateAsDate.getTime();

  const fifteenMinutesInMilliseconds = 31 * 24 * 60 * 60 * 1000;

  return timeDifference <= fifteenMinutesInMilliseconds;
}