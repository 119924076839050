import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { Step, StepperLayout } from "../common/stepper/StepperLayout";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { RedigerSteg } from "./RedigerSteg";
import { OppsummeringSteg } from "./OppsummeringSteg";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { redigerEierOgKjoretoyinfoSchema } from "./redigerEierOgKjoretoyinfoSchema";
import {
  eierfelterErTomme,
  FelterRedigerEierOgKjoretoyinfo,
  felterRedigerEierOgKjoretoyinfoToPutKjoretoyDto,
  getDefaultValues
} from "./redigerEierOgKjoretoyinfoUtils";
import { useRedigerKjoretoyMedEier } from "../../hooks/useKjoretoy";
import { AlertBox } from "../common/form/AlertBox";

interface RedigerEierOgKjoretoyinfoProps {
  inntauing: InntauingViewModel;
  handleLukkRedigering: () => void;
}

export const RedigerEierOgKjoretoyinfo = (
  props: RedigerEierOgKjoretoyinfoProps
) => {
  const { inntauing, handleLukkRedigering } = props;

  const methods = useForm<FelterRedigerEierOgKjoretoyinfo>({
    defaultValues: getDefaultValues(inntauing),
    resolver: yupResolver(redigerEierOgKjoretoyinfoSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUseNativeValidation: true,
    shouldFocusError: true
  });

  const leasingtaker = useWatch({
    control: methods.control,
    name: "leasingtaker"
  });

  const putEierOgKjoretoyinfo = useRedigerKjoretoyMedEier(inntauing.id);

  const handleRedigerEierOgKjoretoyinfo = (
    data: FelterRedigerEierOgKjoretoyinfo
  ) => {
    data.leasingtaker = eierfelterErTomme(leasingtaker)
      ? undefined
      : leasingtaker;
    putEierOgKjoretoyinfo.mutate(
      {
        kjoretoy: felterRedigerEierOgKjoretoyinfoToPutKjoretoyDto(
          data,
          inntauing
        )
      },
      { onSuccess: () => handleLukkRedigering() }
    );
  };

  const steps: Step[] = [
    {
      id: "rediger",
      label: "Rediger",
      component: RedigerSteg,
      cancelStepButton: () => AvbrytKnapp({ lukk: handleLukkRedigering }),
      nextStepButton: OppsummeringKnapp
    },
    {
      id: "oppsummering",
      label: "Oppsummering",
      component: () =>
        OppsummeringSteg({
          inntauing,
          feilmelding: Feilmelding({
            isError: putEierOgKjoretoyinfo.isError,
            errorMessage: putEierOgKjoretoyinfo.isError
              ? (putEierOgKjoretoyinfo.error as Error).message
              : ""
          })
        }),
      previousStepButton: TilbakeKnapp,
      confirmStepButton: BekreftKnapp
    }
  ];

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleRedigerEierOgKjoretoyinfo)}
        className="flex flex-col mx-10 py-10"
      >
        <StepperLayout steps={steps} />
      </form>
    </FormProvider>
  );
};

interface AvbrytKnappProps {
  lukk: () => void;
}

const AvbrytKnapp = (props: AvbrytKnappProps) => {
  const { lukk } = props;

  return (
    <button
      className="btn btn--flat btn--icon-left"
      type="button"
      onClick={lukk}
    >
      <ArrowDown className="fill-current inline-flex rotate-[90deg]" />
      <span>Avbryt</span>
    </button>
  );
};

const TilbakeKnapp = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button {...props} className="btn btn--flat btn--icon-left" type="button">
      <ArrowDown className="fill-current inline-flex rotate-[90deg]" />
      <span>Tilbake</span>
    </button>
  );
};

const OppsummeringKnapp = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  return (
    <button
      {...props}
      data-testid="oppsummering-knapp"
      className="btn btn--primary btn--icon-right"
      type="button"
    >
      <ArrowDown className="fill-current inline-flex rotate-[-90deg]" />
      <span>Oppsummering</span>
    </button>
  );
};

const BekreftKnapp = () => {
  return (
    <button
      data-testid="bekreft-knapp"
      className="btn btn--primary btn--icon-right"
      type="submit"
    >
      <ArrowDown className="fill-current inline-flex rotate-[-90deg]" />
      <span>Bekreft</span>
    </button>
  );
};

interface FeilmeldingProps {
  isError: boolean;
  errorMessage: string;
}

const Feilmelding = (props: FeilmeldingProps) => {
  const { isError, errorMessage } = props;

  if (!isError) return undefined;

  return (
    <div className="mt-5">
      <AlertBox alertLevel="error" heading={errorMessage} />
    </div>
  );
};
