import { useInfiniteQuery } from "react-query";
import { getSendteBrev } from "../api/apiServices/InntauingService";
import {
  CACHE_KEYS,
  REFRESH_INTERVAL,
  DEFAULT_PAGE_SIZE
} from "../utils/globalConstants";

const pageSize = DEFAULT_PAGE_SIZE;

export const useSendteBrev = (
  sortKey: string,
  sortDirection: "desc" | "asc",
  customPageSize?: number
) =>
  useInfiniteQuery(
    [CACHE_KEYS.Brevsporinger],
    ({ pageParam = 0 }) =>
      getSendteBrev(
        pageParam,
        customPageSize ?? pageSize,
        sortKey,
        sortDirection === "desc"
      ).then(r => r.data),
    {
      getNextPageParam: lastPage => lastPage.nextCursor ?? undefined,
      refetchInterval: REFRESH_INTERVAL
    }
  );
