import { useInntauingerMedTaksering } from "../../hooks/useTakst";
import { Notifikasjon } from "../common/notifikasjon/Notifikasjon";

export const TakseringNotifikasjon = () => {
  const { data } = useInntauingerMedTaksering();

  const antall = data?.length ?? 0;

  return (
    <>
      {antall > 0 && (
        <Notifikasjon
          title={`${antall} taksering${antall === 1 ? "" : "er"}`}
          lengde={antall.toString().length}
        >
          {antall}
        </Notifikasjon>
      )}
    </>
  );
};
