import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getFeiletEllerUnderBehandling,
  getIkkeGodkjente,
  putGodkjennFaktura,
  putRegistrerManueltFakturert
} from "../api/apiServices/FakturaService";
import { FakturaInputModel } from "../api/interfaces/Faktura";
import { CACHE_KEYS } from "../utils/globalConstants";

export const useIkkeGodkjente = () =>
  useQuery([CACHE_KEYS.Ikke_fakturerte], () =>
    getIkkeGodkjente().then(r => r.data)
  );

export const useFeiletEllerUnderBehandling = () =>
  useQuery(
    [CACHE_KEYS.Feilet_fakturaer],
    () => getFeiletEllerUnderBehandling().then(r => r.data),
    {
      refetchInterval: 50000,
      retry: 1
    }
  );

export const useGodkjennFaktura = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { fakturanummer: number; betaling: FakturaInputModel }) =>
      putGodkjennFaktura(variables.fakturanummer, variables.betaling),
    {
      onSuccess: async () => {
        return Promise.all([
          await queryClient.invalidateQueries([CACHE_KEYS.Ikke_fakturerte]),
          await queryClient.invalidateQueries([CACHE_KEYS.Feilet_fakturaer]),
          await queryClient.invalidateQueries([CACHE_KEYS.Inntekt_faktura])
        ]);
      }
    }
  );
};

export const useRegistrerManueltFakturert = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { fakturanummer: number; betaling: FakturaInputModel }) =>
      putRegistrerManueltFakturert(variables.fakturanummer, variables.betaling),
    {
      onSuccess: async () => {
        return Promise.all([
          await queryClient.invalidateQueries([CACHE_KEYS.Ikke_fakturerte]),
          await queryClient.invalidateQueries([CACHE_KEYS.Feilet_fakturaer]),
          await queryClient.invalidateQueries([CACHE_KEYS.Inntekt_faktura])
        ]);
      }
    }
  );
};
