import { FlaggType } from "../../api/interfaces/Flagg";
import {
  InntauingViewModel,
  StatuskodeMapper,
  StatuskodeType
} from "../../api/interfaces/Inntauing";
import { useInntauing } from "../../hooks/useInntauinger";

interface KunngjoringerUtskriftProps {
  inntauingsIder: string[];
  tilPrint?: boolean;
}

export const KunngjoringerUtskrift = (props: KunngjoringerUtskriftProps) => {
  const { inntauingsIder, tilPrint } = props;

  return (
    <table className="tbl">
      <thead>
        <tr>
          <th>Regnr</th>
          <th>Merke</th>
          <th>Modell</th>
          <th>Regår</th>
          <th>Type</th>
          <th>Inntauet fra</th>
          {!tilPrint && (
            <>
              <th>Status</th>
              <th>Tidspunkt opprettet kunngjøring</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {inntauingsIder.map(id => (
          <KunngjoringerUtskriftRad
            key={id}
            inntauingsId={id}
            tilPrint={tilPrint}
          />
        ))}
      </tbody>
    </table>
  );
};

interface KunngjoringUtskriftRadProps {
  inntauingsId: string;
  tilPrint?: boolean;
}

const KunngjoringerUtskriftRad = (props: KunngjoringUtskriftRadProps) => {
  const { inntauingsId, tilPrint } = props;

  const { data } = useInntauing(inntauingsId);

  return (
    <tr className={hentClassName(tilPrint, data)}>
      <td>{data?.kjoretoy.registreringsnummer}</td>
      <td>{data?.kjoretoy.merke}</td>
      <td>{data?.kjoretoy.modell}</td>
      <td>{data?.kjoretoy.registrertAar}</td>
      <td>{data?.kjoretoy.kjoretoystype.kode}</td>
      <td>{data?.inntauingsgrunnlag.lokasjon.gatenavn}</td>
      {!tilPrint && (
        <>
          <td>{data?.status ? StatuskodeMapper[data.status].navn : ""}</td>
          <td>{hentKunngjoringstidspunkt(data)}</td>
        </>
      )}
    </tr>
  );
};

const hentKunngjoringstidspunkt = (inntauing?: InntauingViewModel) => {
  if (inntauing?.auksjon?.kunngjoring?.opprettetKunngjoringTidspunkt)
    return new Date(
      inntauing.auksjon.kunngjoring.opprettetKunngjoringTidspunkt
    ).toLocaleString();

  if (inntauing?.vraking?.kunngjoring?.opprettetKunngjoringTidspunkt)
    return new Date(
      inntauing.vraking.kunngjoring.opprettetKunngjoringTidspunkt
    ).toLocaleString();

  return "";
};

const hentClassName = (tilPrint?: boolean, inntauing?: InntauingViewModel) => {
  const erFlaggetMedUnderArbeidEllerVilkaar = inntauing?.flagg?.filter(
    f =>
      !f.fjernet &&
      (f.type === FlaggType.UnderArbeid || f.type === FlaggType.Vilkaar)
  ).length;

  const erTilAuksjon = inntauing?.status === StatuskodeType.TilAuksjon;
  const erTilVrak = inntauing?.status === StatuskodeType.TilVrak;

  const harKunngjøringAuksjon =
    inntauing?.auksjon?.kunngjoring?.opprettetKunngjoringTidspunkt;
  const harKunngjøringVrak =
    inntauing?.vraking?.kunngjoring?.opprettetKunngjoringTidspunkt;

  const klarForKunngjoringAuksjon = erTilAuksjon && harKunngjøringAuksjon;
  const klarForKunngjoringVrak = erTilVrak && harKunngjøringVrak;

  if (
    klarForKunngjoringAuksjon ||
    klarForKunngjoringVrak ||
    erFlaggetMedUnderArbeidEllerVilkaar
  )
    return "";

  return tilPrint ? "hidden" : "text-errorred line-through";
};
