import * as yup from "yup";

export const registrerAnkommetSchema = yup.object({
  journalnummer: yup
    .number()
    .typeError("Journalnummer må være et tall")
    .nullable(true)
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (val ? Number(val) : null)),
  radnummer: yup.string().required("Radnummer må oppgis ved ankomst"),
  ordrenummer: yup
    .number()
    .typeError("Ordrenummer må være et tall")
    .required("Ordrenummer må oppgis ved ankomst"),
  brevtype: yup.number(),
  kommentar: yup
    .string()
    .when(["brevtype"], brevtype => brevtype === 2 ? yup.string().required("Oppgi kommentar ved manuell brevutsendelse") : yup.string().notRequired())
})
