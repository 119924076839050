import React from "react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { PktButton } from "@oslokommune/punkt-react";

interface InntauingTabellFilterKnappProps {
  visFilterIDom: boolean;
  toggleVisFilterIDomCallback: Dispatch<SetStateAction<boolean>>;
}

export const InntauingFilterKnapp = React.memo(
  ({
    visFilterIDom,
    toggleVisFilterIDomCallback
  }: InntauingTabellFilterKnappProps) => {
    const [currentFilterIcon, setCurrentFilterIcon] = useState<
      "filter" | "close"
    >("filter");

    useEffect(() => {
      if (visFilterIDom) {
        setCurrentFilterIcon("close");
      } else if (!visFilterIDom) {
        setCurrentFilterIcon("filter");
      }
    }, [visFilterIDom]);

    const handleOnClick = () => {
      toggleVisFilterIDomCallback(!visFilterIDom);
    };

    return (
      <PktButton
        variant="icon-left"
        iconName={currentFilterIcon}
        type="button"
        onClick={handleOnClick}
        data-testid="button-toggle-show-filter"
      >
        Filter
      </PktButton>
      // <button
      //   type="button"
      //   className="btn btn--primary btn--icon-left h-12 mt-5"
      //   onClick={handleOnClick}
      //   data-testid="button-toggle-show-filter"
      // >
      //   {currentFilterIcon}
      //   <span>Filter</span>
      // </button>
    );
  }
);
