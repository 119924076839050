import { HeadingView } from "../../common/typography/HeadingView";
import { useRef } from "react";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import { CustomError } from "../../common/typography/CustomError";
import { RequiredLabel } from "../../common/form/RequiredLabel";
import DatePicker from "../../common/form/DatePicker";
import { startOfDay } from "date-fns";
import { usePersonligeBetalinger } from "../../../hooks/useBetaling";
import { PersonligeBetalingerTabell } from "./PersonligeBetalingerTabell";
import { ReactComponent as Print } from "../../../assets/icons/print.svg";
import { useReactToPrint } from "react-to-print";
import { LoadingSpinner } from "../../common/loading/LoadingSpinner";
import { useStateParams } from "../../../hooks/useStateParams";
import { HeadingUtskrift } from "../../common/typography/HeadingUtskrift";

export const PersonligeUtleveringer = () => {
  const [tidspunktBetaling, setTidspunktBetaling] = useStateParams(
    startOfDay(new Date()),
    "tidspunkt",
    s => s.toISOString(),
    s => new Date(s)
  );

  const {
    data: betalinger,
    isLoading,
    isError,
    error
  } = usePersonligeBetalinger(tidspunktBetaling);

  const componentPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    pageStyle: "m-5"
  });

  const handleTidspunktBetaling = (dato: Date | null) =>
    setTidspunktBetaling(dato ?? new Date());

  return (
    <>
      <HeadingView>Mine utleveringer</HeadingView>
      <div className="ods-form-group mb-10">
        <label
          className="ods-form-label w-fit flex flex-wrap"
          htmlFor="tidspunktBetaling"
        >
          Velg dato
          <RequiredLabel />
        </label>
        <div className="flex relative items-center justify-start focus-within:fill-hoverblue">
          <DatePicker
            id="tidspunktBetaling"
            dateFormat="dd.MM.yyyy"
            onChange={handleTidspunktBetaling}
            selected={tidspunktBetaling}
            customInput={
              <input
                className="ods-form-input w-64"
                data-testid="tidspunkt-opptelling-datepicker"
              />
            }
          />
          <CalendarIcon className="pointer-events-none absolute ml-[14.25rem] h-5 w-5" />
        </div>
      </div>
      <div ref={componentPrintRef}>
        <HeadingUtskrift>Registrerte betalinger</HeadingUtskrift>
        {isLoading && <LoadingSpinner />}
        {isError && <CustomError error={(error as Error).message} />}
        {betalinger && (
          <PersonligeBetalingerTabell
            betalinger={betalinger.filter(b => b.faktura === null)}
            totalsum={betalinger
              .filter(b => b.faktura === null)
              .map(b => b.totalsum)
              .reduce((a, b) => a + b, 0)}
          />
        )}

        <HeadingUtskrift>Registrerte fakturaer</HeadingUtskrift>
        {betalinger && (
          <PersonligeBetalingerTabell
            betalinger={betalinger.filter(b => b.faktura !== null)}
            totalsum={betalinger
              .filter(b => b.faktura !== null)
              .map(b => b.totalsum)
              .reduce((a, b) => a + b, 0)}
          />
        )}
      </div>
      <button
        className="btn btn--primary-confirm btn--icon-left mb-5"
        onClick={handlePrint}
        disabled={betalinger?.length === 0}
      >
        <Print className="fill-current" />
        <span>Skriv ut</span>
      </button>
    </>
  );
};
