import { BetalingViewModel } from "../../api/interfaces/Betaling";
import {
  Betalingsgruppe,
  BetalingsgruppeMapper,
  HjemmelType,
  HjemmelTypeMapper,
  InntauingViewModel
} from "../../api/interfaces/Inntauing";
import { PrislisteViewModel } from "../../api/interfaces/Prisliste";
import { formaterNullableBelop } from "../../utils/formatUtils";
import { hentPrislisteForDato } from "../innstillinger/prislister/prislisteUtils";
import { BetalingsspesifikasjonLinje } from "./BetalingsspesifikasjonLinje";
import { beregnAntallDogn, totalIleggelsessum } from "./utleveringUtils";

interface KvitteringBetalingsspesifikasjonProps {
  inntauing?: InntauingViewModel;
  betaling?: BetalingViewModel;
  prislister?: PrislisteViewModel[];
  betalingsmetode: "Ingen betaling" | "Kasse" | "Faktura";
}

export const KvitteringBetalingsspesifikasjon = (
  props: KvitteringBetalingsspesifikasjonProps
) => {
  const { inntauing, betaling, prislister, betalingsmetode } = props;

  const sumIleggelse = totalIleggelsessum(
    betaling?.betalteGebyrer.ileggelsesgebyr ?? 0,
    betaling?.betalteGebyrer.ileggelsetilleggsgebyr ?? 0
  );
  const sumUtestaendeIleggelse = totalIleggelsessum(
    inntauing?.utestaaendeKostnader.ileggelsesgebyr?.pris ?? 0,
    inntauing?.utestaaendeKostnader.ileggelsestilleggsgebyr?.pris ?? 0
  );

  const tidPaaTomt =
    new Date(betaling?.betalingstidspunkt ?? "").getTime() -
    new Date(
      inntauing?.kjoretoyleveranse?.leveringstidspunkt ?? new Date()
    ).getTime();
  const dognPaTomt = beregnAntallDogn(tidPaaTomt);

  return (
    <>
      <BetalingsspesifikasjonLinje
        navn={
          BetalingsgruppeMapper[
            inntauing?.kjoretoy.betalingsgruppe ?? Betalingsgruppe.Ukjent
          ].navn
        }
        belop={betaling?.betalteGebyrer.inntauingsgebyr}
      />
      <BetalingsspesifikasjonLinje
        navn="Admin.gebyr"
        title="Administrasjonsgebyr"
        belop={betaling?.betalteGebyrer.administrasjonsgebyr}
      />
      <BetalingsspesifikasjonLinje
        navn={`Døgnleiepris (${formaterNullableBelop(
          hentPrislisteForDato(new Date(), prislister)?.dognleie.pris
        )} x ${dognPaTomt} døgn)`}
        title="Døgnleiepris"
        belop={betaling?.betalteGebyrer.dognleie ?? 0}
      />
      <BetalingsspesifikasjonLinje
        navn={
          HjemmelTypeMapper[
            inntauing?.inntauingsgrunnlag.hjemmel ?? HjemmelType.Ukjent
          ].ileggelseType
        }
        belop={sumUtestaendeIleggelse > 0 ? "Ikke betalt" : sumIleggelse}
        ekstrainfo={
          betalingsmetode === "Faktura" || betalingsmetode === "Ingen betaling"
            ? "Gul lapp må betales separat"
            : undefined
        }
      />
      <BetalingsspesifikasjonLinje
        navn="Totalsum"
        belop={betaling?.totalsum}
        understrek="dobbel"
      />
    </>
  );
};
