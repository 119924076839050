import { getUsername } from "../../api/client";

export const AuthAs = () => {
  return (
    <div className="tabletBig:hidden flex flex-row mr-5 items-center">
      <div>Innlogget:</div>
      <div className="font-semibold ml-2">{getUsername()}</div>
    </div>
  );
};
