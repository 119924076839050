import { useState } from "react";
import {
  InntauingViewModel,
  StatuskodeMapper
} from "../../api/interfaces/Inntauing";
import {
  useAvsluttetInntauinger,
  useFlyttetPaGategrunn,
  useInntauinger
} from "../../hooks/useInntauinger";
import { BREAKPOINTS } from "../../styles/sizeVariables";
import { ReactComponent as Pluss } from "../../assets/icons/pluss.svg";
import { ReactComponent as Minus } from "../../assets/icons/minus.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/globalConstants";

interface GeneralSearchResultProps {
  search?: string;
  handleClose?: () => void;
}

export const GeneralSearchResult = (props: GeneralSearchResultProps) => {
  const { search, handleClose } = props;

  return (
    <div className="flex flex-col px-8 pt-4 text-left text-sm pb-[4.5rem] tabletBig:pb-[3.5rem]">
      <AktiveTabell search={search} handleClose={handleClose} />
      <AvsluttetTabell search={search} handleClose={handleClose} />
      <FlyttetPaGategrunnTabell search={search} handleClose={handleClose} />
    </div>
  );
};

interface AktiveTabellProps {
  search?: string;
  handleClose?: () => void;
}

const AktiveTabell = (props: AktiveTabellProps) => {
  const { search, handleClose } = props;

  const [open, setOpen] = useState<boolean>(true);

  const isTablet =
    window.innerWidth > Number(BREAKPOINTS.tabletBig.replace("px", ""));
  const antallPerTabell = isTablet ? 20 : 10;

  const {
    data: aktive,
    isLoading: isLoadingAktive,
    isError: isErrorAktive,
    error: errorAktive
  } = useInntauinger(
    "bestillingsTidspunkt",
    "desc",
    { sok: search, visning: "aktive" },
    true,
    antallPerTabell + 1
  );

  if (isLoadingAktive) {
    return (
      <div className="flex flex-col px-8 py-4 text-left text-sm">
        Søker på aktive inntauinger...
      </div>
    );
  }

  if (isErrorAktive) {
    return (
      <div className="flex flex-col px-8 py-4 text-left text-sm">
        {`En feil har skjedd med aktive inntauinger: ${errorAktive}`}
      </div>
    );
  }

  const antallAktive = aktive?.pages.flatMap(x => x.data).length ?? 0;

  return (
    <>
      <div className="mb-3 flex items-center underline">
        <Link
          data-testid="generelt-sok-aktive"
          to={`${ROUTES.Aktive_inntauinger}?sok=${search}`}
          onClick={handleClose}
        >{`Aktive inntauinger (${
          antallAktive > antallPerTabell ? `${antallPerTabell}+` : antallAktive
        })`}</Link>
        <button className="bg-transparent ml-2" onClick={() => setOpen(!open)}>
          {open ? <Minus className="w-6" /> : <Pluss className="w-6" />}
        </button>
      </div>
      {open && (
        <div className="grid grid-cols-12 w-[80%]">
          <table className="col-span-5 tabletBig:col-span-12">
            <tbody>
              {aktive?.pages
                .flatMap(x => x.data)
                .slice(0, 10)
                .map(i => (
                  <GeneralSearchResultTable key={i.id} inntauing={i} />
                ))}
            </tbody>
          </table>
          <div className="col-span-2 tabletBig:hidden">
            <div className="left-[50%] relative border-l-2 border-darkblue h-full w-full" />
          </div>
          <table className="col-span-5 tabletBig:hidden mb-auto">
            <tbody>
              {aktive?.pages
                .flatMap(x => x.data)
                .slice(10, 20)
                .map(i => (
                  <GeneralSearchResultTable key={i.id} inntauing={i} />
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

interface AvsluttetTabellProps {
  search?: string;
  handleClose?: () => void;
}

const AvsluttetTabell = (props: AvsluttetTabellProps) => {
  const { search, handleClose } = props;

  const [open, setOpen] = useState<boolean>(false);

  const isTablet =
    window.innerWidth > Number(BREAKPOINTS.tabletBig.replace("px", ""));
  const antallPerTabell = isTablet ? 20 : 10;

  const {
    data: avsluttet,
    isLoading: isLoadingAvsluttet,
    isError: isErrorAvsluttet,
    error: errorAvsluttet
  } = useAvsluttetInntauinger(
    "bestillingsTidspunkt",
    "desc",
    { sok: search, visning: "avsluttet" },
    true,
    antallPerTabell + 1
  );

  if (isLoadingAvsluttet) {
    return (
      <div className="flex flex-col px-8 py-4 text-left text-sm">
        Søker på avsluttede inntauinger...
      </div>
    );
  }

  if (isErrorAvsluttet) {
    return (
      <div className="flex flex-col px-8 py-4 text-left text-sm">
        {`En feil har skjedd med avsluttede inntauinger: ${errorAvsluttet}`}
      </div>
    );
  }

  const antallAvsluttet = avsluttet?.pages.flatMap(x => x.data).length ?? 0;

  return (
    <>
      <div className="mt-5 mb-3 flex items-center underline">
        <Link
          data-testid="generelt-sok-avsluttet"
          to={`/avsluttet?sok=${search}`}
          onClick={handleClose}
        >{`Avsluttet inntauinger (${
          antallAvsluttet > antallPerTabell
            ? `${antallPerTabell}+`
            : antallAvsluttet
        })`}</Link>
        <button className="bg-transparent ml-2" onClick={() => setOpen(!open)}>
          {open ? <Minus className="w-6" /> : <Pluss className="w-6" />}
        </button>
      </div>
      {open && (
        <div className="grid grid-cols-12 w-[80%]">
          <table className="col-span-5 tabletBig:col-span-12">
            <tbody>
              {avsluttet?.pages
                .flatMap(x => x.data)
                .slice(0, 10)
                .map(i => (
                  <GeneralSearchResultTable key={i.id} inntauing={i} />
                ))}
            </tbody>
          </table>
          <div className="col-span-2 tabletBig:hidden">
            <div className="left-[50%] relative border-l-2 border-darkblue h-full w-full" />
          </div>
          <table className="col-span-5 tabletBig:hidden mb-auto">
            <tbody>
              {avsluttet?.pages
                .flatMap(x => x.data)
                .slice(10, 20)
                .map(i => (
                  <GeneralSearchResultTable key={i.id} inntauing={i} />
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

interface FlyttetPaGategrunnTabellProps {
  search?: string;
  handleClose?: () => void;
}

const FlyttetPaGategrunnTabell = (props: FlyttetPaGategrunnTabellProps) => {
  const { search, handleClose } = props;

  const [open, setOpen] = useState<boolean>(false);

  const isTablet =
    window.innerWidth > Number(BREAKPOINTS.tabletBig.replace("px", ""));
  const antallPerTabell = isTablet ? 20 : 10;

  const {
    data: flyttetPaGategrunn,
    isLoading: isLoadingFlyttetPaGategrunn,
    isError: isErrorFlyttetPaGategrunn,
    error: errorFlyttetPaGategrunn
  } = useFlyttetPaGategrunn(
    "bestillingsTidspunkt",
    "desc",
    { sok: search, visning: "flyttet" },
    true,
    antallPerTabell + 1
  );

  if (isLoadingFlyttetPaGategrunn) {
    return (
      <div className="flex flex-col px-8 py-4 text-left text-sm">
        Søker på flyttede kjøretøy...
      </div>
    );
  }

  if (isErrorFlyttetPaGategrunn) {
    return (
      <div className="flex flex-col px-8 py-4 text-left text-sm">
        {`En feil har skjedd med flyttede kjøretøy: ${errorFlyttetPaGategrunn}`}
      </div>
    );
  }

  const antallFlyttetPaGategrunn =
    flyttetPaGategrunn?.pages.flatMap(x => x.data).length ?? 0;

  return (
    <>
      <div className="mt-5 mb-3 flex items-center underline">
        <Link
          data-testid="generelt-sok-flyttet"
          to={`/flyttetpagategrunn?sok=${search}`}
          onClick={handleClose}
        >{`Flyttet på gategrunn (${
          antallFlyttetPaGategrunn > antallPerTabell
            ? `${antallPerTabell}+`
            : antallFlyttetPaGategrunn
        })`}</Link>
        <button className="bg-transparent ml-2" onClick={() => setOpen(!open)}>
          {open ? <Minus className="w-6" /> : <Pluss className="w-6" />}
        </button>
      </div>
      {open && (
        <div className="grid grid-cols-12 w-[80%]">
          <table className="col-span-5 tabletBig:col-span-12">
            <tbody>
              {flyttetPaGategrunn?.pages
                .flatMap(x => x.data)
                .slice(0, 10)
                .map(i => (
                  <GeneralSearchResultTable key={i.id} inntauing={i} />
                ))}
            </tbody>
          </table>
          <div className="col-span-2 tabletBig:hidden">
            <div className="left-[50%] relative border-l-2 border-darkblue h-full w-full" />
          </div>
          <table className="col-span-5 tabletBig:hidden mb-auto">
            <tbody>
              {flyttetPaGategrunn?.pages
                .flatMap(x => x.data)
                .slice(10, 20)
                .map(i => (
                  <GeneralSearchResultTable key={i.id} inntauing={i} />
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

interface GeneralSearchResultTableProps {
  inntauing: InntauingViewModel;
}

const GeneralSearchResultTable = (props: GeneralSearchResultTableProps) => {
  const { inntauing } = props;

  return (
    <tr className="truncate">
      <td className="w-[15%]">
        {inntauing.kjoretoy.registreringsnummer ??
          inntauing.kjoretoyleveranse?.journalnummer}
      </td>
      <td className="w-[20%] tabletBig:hidden">
        {StatuskodeMapper[inntauing.status].navn}
      </td>
      <td className="w-[25%]">{inntauing.kjoretoy.merke}</td>
      <td className="w-[15%]">{inntauing.kjoretoy.farge}</td>
      <td className="w-[25%]">
        {inntauing.inntauingsgrunnlag.lokasjon.gatenavn}
      </td>
    </tr>
  );
};
