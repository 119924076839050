import { RegistrerInntauingInputModel } from "../../api/interfaces/Inntauing";

export const INNTAUINGSSELSKAP = "RedGo";

export interface RegistrerInntauingFelter {
  inntauingsinfo: {
    ileggelsesnummer?: number;
  };
  bestillingsinfo: {
    betjentId: string;
    bestillingstidspunkt: Date;
    inntauingssted: string;
    gatenummer?: number;
    inntauingsfirma: string;
    hjemmel: number;
    overtredelse: string;
    kommentar?: string;
    skader?: string;
    eierkontakt?: string;
    utenskilt: boolean;
  };
  registreringsnummer: {
    nasjonkode: string;
    registreringsnummer?: string;
    rammenummer?: string;
  };
  kjoretoyinfo: {
    merke?: string;
    modell?: string;
    type: string;
    farge?: string;
    totalvekt?: number;
    registrertAar?: number;
  };
  juridiskEier?: {
    erBedrift: boolean;
    idNummer?: string;
    navn?: string;
    telefonnummer?: string;
    gateadresse?: string;
    postnummer?: string;
    poststed?: string;
  };
  medeier?: {
    erBedrift: boolean;
    idNummer?: string;
    navn?: string;
    telefonnummer?: string;
    gateadresse?: string;
    postnummer?: string;
    poststed?: string;
  };
  leasingtaker?: {
    erBedrift: boolean;
    idNummer?: string;
    navn?: string;
    telefonnummer?: string;
    gateadresse?: string;
    postnummer?: string;
    poststed?: string;
  };
}

export const mapFelterTilInputModel = (
  felter: RegistrerInntauingFelter
): RegistrerInntauingInputModel => {
  return {
    inntauingsgrunnlag: {
      bestillingstidspunkt: felter.bestillingsinfo.bestillingstidspunkt,
      betjentId: felter.bestillingsinfo.betjentId,
      hjemmel: felter.bestillingsinfo.hjemmel,
      inntauingsfirma: felter.bestillingsinfo.inntauingsfirma,
      lokasjon: {
        gatenavn: felter.bestillingsinfo.inntauingssted,
        gatenummer: felter.bestillingsinfo.gatenummer
      },
      overtredelse: felter.bestillingsinfo.overtredelse,
      skadenotat: felter.bestillingsinfo.skader,
      kommentar: felter.bestillingsinfo.kommentar,
      eierkontakt: felter.bestillingsinfo.eierkontakt,
      utenSkilter: felter.bestillingsinfo.utenskilt
    },
    kjoretoy: {
      rammenummer: felter.registreringsnummer.rammenummer,
      registreringsnummer: felter.registreringsnummer.registreringsnummer,
      nasjonalitet: felter.registreringsnummer.nasjonkode,
      merke: felter.kjoretoyinfo.merke,
      modell: felter.kjoretoyinfo.modell,
      farge: felter.kjoretoyinfo.farge,
      registrertAar: felter.kjoretoyinfo.registrertAar,
      totalvekt: felter.kjoretoyinfo.totalvekt,
      juridiskEier: felter.juridiskEier
        ? {
            erPerson: !felter.juridiskEier.erBedrift,
            idNummer: felter.juridiskEier.idNummer,
            navn: felter.juridiskEier.navn,
            telefonnummer: felter.juridiskEier.telefonnummer,
            gateadresse: felter.juridiskEier.gateadresse,
            postnummer: felter.juridiskEier.postnummer,
            poststed: felter.juridiskEier.poststed
          }
        : undefined,
      leasingtaker: felter.leasingtaker
        ? {
            erPerson: !felter.leasingtaker.erBedrift,
            idNummer: felter.leasingtaker.idNummer,
            navn: felter.leasingtaker.navn,
            telefonnummer: felter.leasingtaker.telefonnummer,
            gateadresse: felter.leasingtaker.gateadresse,
            postnummer: felter.leasingtaker.postnummer,
            poststed: felter.leasingtaker.poststed
          }
        : undefined,
      kjoretoystype: {
        navn: felter.kjoretoyinfo.type,
        kode: felter.kjoretoyinfo.type
      }
    },
    ileggelse: {
      ileggelsesnummer: felter.inntauingsinfo.ileggelsesnummer
    }
  };
};

export type EierId = "juridiskEier" | "medeier" | "leasingtaker";
export type EierIdIdNummer =
  | "juridiskEier.idNummer"
  | "medeier.idNummer"
  | "leasingtaker.idNummer";
export type EierIdNavn =
  | "juridiskEier.navn"
  | "medeier.navn"
  | "leasingtaker.navn";
export type EierIdTelefon =
  | "juridiskEier.telefonnummer"
  | "medeier.telefonnummer"
  | "leasingtaker.telefonnummer";
export type EierIdGateadresse =
  | "juridiskEier.gateadresse"
  | "medeier.gateadresse"
  | "leasingtaker.gateadresse";
export type EierIdPostnummer =
  | "juridiskEier.postnummer"
  | "medeier.postnummer"
  | "leasingtaker.postnummer";
export type EierIdPoststed =
  | "juridiskEier.poststed"
  | "medeier.poststed"
  | "leasingtaker.poststed";
export type EierIdErPerson =
  | "juridiskEier.erBedrift"
  | "medeier.erBedrift"
  | "leasingtaker.erBedrift";
