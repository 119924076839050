import { useFormContext } from "react-hook-form";
import { PrislisteViewModel } from "../../../api/interfaces/Prisliste";
import {
  formaterNullableBelop,
  formaterTomStrengVedNumber0
} from "../../../utils/formatUtils";
import { HeadingTopic } from "../../common/typography/HeadingTopic";
import { FelterPrisliste } from "./NyPrisliste";
import { BetalingsgruppeMapper } from "../../../api/interfaces/Inntauing";

interface NyPrislisteOppsummeringProps {
  prisliste?: PrislisteViewModel;
  feilmelding?: JSX.Element;
}

export const NyPrislisteOppsummering = (
  props: NyPrislisteOppsummeringProps
) => {
  const { prisliste, feilmelding } = props;

  const { getValues } = useFormContext<FelterPrisliste>();

  return (
    <>
      <HeadingTopic>Inntauing</HeadingTopic>
      <p className="text-sm mt-3 mb-8">Dato prisliste skal gjelde fra.</p>
      <div className="grid grid-cols-6 w-full">
        <div className="col-span-2" />
        <div className="text-end">Gammel pris</div>
        <div className="text-end">Ny pris</div>
        <div className="text-end">Gammelt varenummer</div>
        <div className="text-end">Nytt varenummer</div>
        <PrislisteDetaljerPrislinje
          label="Administrasjonsgebyr"
          gammelVerdi={prisliste?.administrasjonsgebyr}
          nyVerdi={getValues().administrasjonsgebyr}
        />
        <PrislisteDetaljerPrislinje
          label="Administrasjonsgebyr flytting"
          gammelVerdi={prisliste?.administrasjonsgebyrFlytting}
          nyVerdi={getValues().administrasjonsgebyrFlytting}
        />
        <PrislisteDetaljerPrislinje
          label="Døgnleie"
          gammelVerdi={prisliste?.dognleie}
          nyVerdi={getValues().dognleie}
        />
        <p className="col-span-6 mb-5">Inntauingskostnad</p>
        {prisliste?.inntauingskostnader.map((i, index) => (
          <PrislisteDetaljerPrislinje
            key={i.gebyrtype}
            label={BetalingsgruppeMapper[i.gebyrtype].navn}
            gammelVerdi={i}
            nyVerdi={getValues().inntauingskostnader.at(index)}
            innrykk
          />
        ))}
      </div>
      {feilmelding}
    </>
  );
};

interface PrislinjeVerdi {
  pris?: number;
  varenummer?: number;
  gebyrType?: number;
}

interface PrislisteDetaljerPrislinjeProps {
  label: string;
  gammelVerdi?: PrislinjeVerdi;
  nyVerdi?: PrislinjeVerdi;
  innrykk?: boolean;
}

const PrislisteDetaljerPrislinje = (props: PrislisteDetaljerPrislinjeProps) => {
  const { label, gammelVerdi, nyVerdi, innrykk } = props;

  return (
    <>
      <dt className={`${innrykk && "ml-4"} mb-4 col-span-2`}>{`${label}:`}</dt>
      <dd className="text-red-500 line-through text-end">
        {Number(gammelVerdi?.pris) !== Number(nyVerdi?.pris) &&
          formaterNullableBelop(gammelVerdi?.pris)}
      </dd>
      <dd className="text-end">{formaterNullableBelop(nyVerdi?.pris)}</dd>
      <dd className="text-red-500 line-through text-end">
        {Number(gammelVerdi?.varenummer) !== Number(nyVerdi?.varenummer) &&
          formaterTomStrengVedNumber0(gammelVerdi?.varenummer)}
      </dd>
      <dd className="text-end">
        {formaterTomStrengVedNumber0(nyVerdi?.varenummer)}
      </dd>
    </>
  );
};
