import { useState } from "react";
import { usePrislister } from "../../../hooks/usePris";
import { AlertBox } from "../../common/form/AlertBox";
import { LoadingSpinner } from "../../common/loading/LoadingSpinner";
import { CustomError } from "../../common/typography/CustomError";
import { HeadingView } from "../../common/typography/HeadingView";
import { PrislisteDetaljer } from "./PrislisteDetaljer";
import { ReactComponent as LeggTil } from "../../../assets/icons/plus-circle-fill.svg";
import { ROUTES } from "../../../utils/globalConstants";
import { HeadingTopic } from "../../common/typography/HeadingTopic";
import { formaterNullableBelop } from "../../../utils/formatUtils";
import { isSaksbehandler } from "../../../utils/userRolesUtils";
import { useNavigate } from "react-router";
import { PrislisteViewModel } from "../../../api/interfaces/Prisliste";
import { SALG_AV_VRAK } from "./prislisteUtils";
import {
  Kjoretoystype,
  KjoretoystypeMapper
} from "../../../api/interfaces/Inntauing";
import { mapEnumToNumberValues } from "../../../utils/objectMapping";

export const Prislister = () => {
  const { data, isLoading, isError, error } = usePrislister();

  return (
    <>
      <HeadingView>Prislister</HeadingView>
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      {data && <PrislisteDetaljerMedPeriodevalg prislister={data} />}
    </>
  );
};

interface PrislisteDetaljerMedPeriodevalgProps {
  prislister: PrislisteViewModel[];
}

const PrislisteDetaljerMedPeriodevalg = (
  props: PrislisteDetaljerMedPeriodevalgProps
) => {
  const { prislister } = props;

  const navigate = useNavigate();

  const [prisliste, setPrisliste] = useState<number | undefined>(
    prislister.at(0)?.id
  );

  return (
    <div className="flex flex-col mb-16">
      <HeadingTopic>Inntauing</HeadingTopic>
      <p className="text-sm mt-3 mb-5">Priser som blir betalt per inntauing.</p>
      <div className="flex flex-row gap-x-5 items-end">
        <div className="ods-form-group">
          <label htmlFor="gyldigIPeriode" className="ods-form-label">
            Gyldig i periode
          </label>
          <div className="ods-form-help">Fra og med - til og med</div>
          <select
            data-testid="select-gyldig-i-periode"
            id="gyldigIPeriode"
            className="ods-select ods-form-input w-fit"
            value={prislister?.find(p => p.id === prisliste)?.id ?? ""}
            onChange={e => setPrisliste(parseInt(e.currentTarget.value))}
          >
            <option value={undefined}></option>
            {prislister?.map(p => (
              <option key={p.id} value={p.id}>
                {formaterPeriode(p)}
              </option>
            ))}
          </select>
        </div>
        {isSaksbehandler() && (
          <button
            data-testid="ny-prisliste-knapp"
            className="btn btn--flat btn--icon-left mb-5"
            onClick={() => navigate(ROUTES.Ny_prisliste)}
          >
            <LeggTil className="fill-current inline-flex rotate-[90deg]" />
            <span>Ny prisliste</span>
          </button>
        )}
      </div>
      {!Number.isNaN(prisliste) ? (
        <PrislisteDetaljer
          prisliste={prislister?.find(p => p.id === prisliste)}
        />
      ) : (
        <AlertBox
          alertLevel="warning"
          heading="Velg prisliste fra nedtrekksmeny"
        />
      )}
      <div className="mt-10">
        <HeadingTopic>Vrakpant</HeadingTopic>
        <p className="text-sm mt-3 mb-5 max-w-lg">
          Opprett Kompassak ved planlagte endringer i vrakpant. Prislisten
          brukes til å foreslå vrakpant gitt kjøretøytype.
        </p>
        <div className="grid grid-cols-2 max-w-3xl">
          {mapEnumToNumberValues(
            Kjoretoystype,
            k => k !== Kjoretoystype.Ukjent
          ).map(k => (
            <PrislisteDetaljerPrislinje
              key={KjoretoystypeMapper[k as Kjoretoystype].kode}
              label={KjoretoystypeMapper[k as Kjoretoystype].navn}
              belop={KjoretoystypeMapper[k as Kjoretoystype].vrakpant}
              showVarenummer={false}
            />
          ))}
          <PrislisteDetaljerPrislinje
            key="nasjonalitet"
            label="Utenlandske Kjøretøy"
            belop={0}
            showVarenummer={false}
          />
        </div>
      </div>
      <div className="mt-10">
        <HeadingTopic>Salg av bilvrak</HeadingTopic>
        <p className="text-sm mt-3 mb-5 max-w-lg">
          Opprett Kompassak ved planlagte endringer i pris på salg av bilvrak.
        </p>
        <div className="grid grid-cols-2 max-w-3xl">
          <PrislisteDetaljerPrislinje
            label="Salgssum"
            belop={SALG_AV_VRAK}
            showVarenummer={false}
          />
        </div>
      </div>
    </div>
  );
};

interface PrislisteDetaljerPrislinjeProps {
  label: string;
  belop?: number;
  varenummer?: string;
  showVarenummer?: boolean;
  innrykk?: boolean;
  dataTestid?: string;
}

export const PrislisteDetaljerPrislinje = (
  props: PrislisteDetaljerPrislinjeProps
) => {
  const {
    label,
    belop,
    varenummer,
    showVarenummer = true,
    innrykk,
    dataTestid
  } = props;

  return (
    <>
      <dt className={`${innrykk && "ml-4"} mb-4`}>{`${label}:`}</dt>
      <dd data-testid={dataTestid} className="text-end">
        {formaterNullableBelop(belop)}
      </dd>
      {showVarenummer && <dd className="text-end">{varenummer ?? ""}</dd>}
    </>
  );
};

const formaterPeriode = (prisliste?: PrislisteViewModel) => {
  if (!prisliste) return "";
  return `${new Date(prisliste.gyldigFra).toLocaleDateString()} - ${
    prisliste.gyldigTil &&
    new Date(prisliste.gyldigTil).getTime() < new Date(9998, 12, 31).getTime()
      ? new Date(prisliste.gyldigTil).toLocaleDateString()
      : ""
  }`;
};
