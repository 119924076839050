import {
  InntauingViewModel,
  StatuskodeType
} from "../../api/interfaces/Inntauing";
import { formaterNullableBelop } from "../../utils/formatUtils";
import { KeyValuePair } from "../common/card/KeyValueKortInnhold";

export const getInntektsdata = (inntauing: InntauingViewModel) => {
  const betalinger = inntauing.betalinger.map(b => b.betalteGebyrer);
  const totalsum = inntauing.betalinger.reduce(
    (prev, curr) => prev + curr.totalsum,
    0
  );
  const totalDognleierabatt = inntauing.betalinger.reduce(
    (prev, curr) => prev + curr.dognleierabatt,
    0
  );

  if (inntauing.kjoretoy.registreringsnummer === "E2EAUTOMATISKFAKTURERT")
    console.log(
      totalDognleierabatt === 0 ? undefined : totalsum + totalDognleierabatt
    );

  return {
    hentet: {
      betalt: totalsum,
      opprinneligBelop:
        totalDognleierabatt === 0 ? undefined : totalsum + totalDognleierabatt,
      betaltDelInntauing: betalinger.reduce(
        (acc, el) =>
          acc + el.inntauingsgebyr + el.administrasjonsgebyr + el.dognleie,
        0
      ),
      betaltDelIleggelse: betalinger.reduce(
        (acc, el) => acc + el.ileggelsesgebyr + el.ileggelsetilleggsgebyr,
        0
      )
    },
    flyttet: {
      betalt:
        (inntauing.betalingskrav?.inntauingsgebyr ?? 0) +
        (inntauing.betalingskrav?.administrasjonsgebyr ?? 0)
    },
    vraking: {
      betalt:
        (inntauing?.vraking?.inntektSkrapsalg ?? 0) +
        (inntauing?.vraking?.vrakpant ?? 0),
      betaltDelVrakpant: inntauing?.vraking?.vrakpant ?? 0,
      betaltDelSkrapsalg: inntauing?.vraking?.inntektSkrapsalg ?? 0
    },
    auksjon: {
      betalt: inntauing?.auksjon?.salgsinntekt ?? 0
    }
  };
};

export interface InntektKortdata {
  opprinneligBelop?: string;
  opprinneligBelopTekst?: string;
  betaltTekst: string;
  betalt: string;
  keyValuePairsHeading?: string;
  keyValuePairs?: KeyValuePair[];
}

export const getKortdata = (
  inntauing: InntauingViewModel
): InntektKortdata | undefined => {
  const inntektsdata = getInntektsdata(inntauing);

  switch (inntauing.status) {
    case StatuskodeType.Hentet:
      return {
        opprinneligBelop: inntektsdata.hentet.opprinneligBelop
          ? formaterNullableBelop(inntektsdata.hentet.opprinneligBelop)
          : undefined,
        opprinneligBelopTekst: "Opprinnelig beløp",
        betaltTekst: "Betalt",
        betalt: formaterNullableBelop(inntektsdata.hentet.betalt),
        keyValuePairsHeading: "Betalt beløp",
        keyValuePairs: [
          {
            key: "Ileggelse",
            value: [
              formaterNullableBelop(inntektsdata.hentet.betaltDelIleggelse)
            ]
          },
          {
            key: "Inntauing",
            value: [
              formaterNullableBelop(inntektsdata.hentet.betaltDelInntauing)
            ]
          }
        ]
      };
    case StatuskodeType.Fremmote:
      return undefined;
    case StatuskodeType.FlyttetPaaGategrunn:
      return {
        betaltTekst: "Totalsum flytting",
        betalt: formaterNullableBelop(inntektsdata.flyttet.betalt),
        keyValuePairsHeading: "Betalt beløp",
        keyValuePairs: [
          {
            key: "Flytting",
            value: [formaterNullableBelop(inntektsdata.flyttet.betalt)]
          }
        ]
      };
    case StatuskodeType.Vraket:
      return {
        betaltTekst: "Totalsum vraking",
        betalt: formaterNullableBelop(inntektsdata.vraking.betalt),
        keyValuePairsHeading: "Vraksum fordelt",
        keyValuePairs: [
          {
            key: "Vrakpant",
            value: [
              formaterNullableBelop(inntektsdata.vraking.betaltDelVrakpant)
            ]
          },
          {
            key: "Skrapsalg",
            value: [
              formaterNullableBelop(inntektsdata.vraking.betaltDelSkrapsalg)
            ]
          }
        ]
      };
    case StatuskodeType.Auksjonert:
      return {
        betaltTekst: "Totalsum auksjon",
        betalt: formaterNullableBelop(inntektsdata.auksjon.betalt)
      };
    default:
      return undefined;
  }
};
