import React, { useCallback, useState } from "react";
import { useInntauing } from "../../hooks/useInntauinger";
import { checkDateBiggerThanOrEqual } from "../../utils/compareUtils";
import { Kort } from "../common/card/Kort";
import DatePicker from "../common/form/DatePicker";
import { RequiredLabel } from "../common/form/RequiredLabel";
import { useToast } from "../common/toast/ToastProvider";
import { FilOpplasterRegistrerAuksjonertSkjema } from "./FilOpplasterAttestSkjema";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { ReactComponent as Dokument } from "../../assets/icons/file.svg";
import { HeadingView } from "../common/typography/HeadingView";
import { CustomError } from "../common/typography/CustomError";
import { AlertBox } from "../common/form/AlertBox";
import { handleNumberOrEmptyValueInput } from "../../utils/keyboardUtils";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../utils/globalConstants";
import { useAuksjonert } from "../../hooks/useAuksjon";

interface SkjemaData {
  salgsdato: Date;
  salgssum?: string;
}

export const RegistrerAuksjonert = () => {
  const { id } = useParams();

  const [valideringAktiv, setValideringAktiv] = useState<boolean>(false);
  const [filer, setFiler] = useState<File[]>([]);
  const [skjemadata, setSkjemadata] = useState<SkjemaData>({
    salgsdato: new Date()
  });

  const navigate = useNavigate();

  const putAuksjonert = useAuksjonert();
  const { addToast } = useToast();
  const { data, isLoading, isError, error } = useInntauing(id ?? "");

  const handleLastOppDokumenter = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (feilVedSkjema()) {
      setValideringAktiv(true);
      return;
    }

    const formData = new FormData();

    if (!skjemadata.salgsdato) {
      addToast("Velg salgsdato i skjemaet over", "error");
      return;
    }

    formData.append("Salgsdato", skjemadata.salgsdato.toISOString());
    formData.append("Salgsinntekt", skjemadata.salgssum?.toString() ?? "");
    for (const fil of filer) {
      formData.append(`Dokumenter`, fil);
    }

    id &&
      putAuksjonert.mutate(
        {
          inntauingsId: id,
          dokumenter: formData
        },
        {
          onSuccess: () => {
            addToast("Kjøretøy bekreftet auksjonert");
            navigate(ROUTES.Auksjon + "/gjennomforte");
          },
          onError: () => {
            addToast("Kunne ikke arkivere auksjonering", "error");
          }
        }
      );
  };

  const feilVedSalgsdato = (): boolean => {
    if (
      !(
        skjemadata.salgsdato &&
        data?.auksjon?.sendtTilAuksjon &&
        checkDateBiggerThanOrEqual(
          skjemadata.salgsdato,
          new Date(data.auksjon.sendtTilAuksjon.toString())
        )
      )
    )
      return true;

    return false;
  };

  const feilVedSalgssum = (): boolean =>
    isNaN(parseInt(skjemadata.salgssum ?? ""));

  const feilVedFiler = (): boolean => filer.length === 0;

  const feilVedSkjema = (): boolean =>
    feilVedSalgsdato() || feilVedSalgssum() || feilVedFiler();

  const handleSalgsdato = useCallback(
    (dato: Date | null) =>
      setSkjemadata({ ...skjemadata, salgsdato: dato ?? new Date() }),
    [skjemadata]
  );

  const handleSalgssum = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSkjemadata({
      ...skjemadata,
      salgssum: e.currentTarget.value
    });

  return (
    <div className="flex flex-col mx-5 mb-10">
      <HeadingView>Registrer auksjonert</HeadingView>
      <Kort
        headerText="Relevante dokumenter"
        fullWidth
        headerIcon={<Dokument className="fill-darkblue" />}
        innhold={
          <>
            <div className="ods-form-group w-52">
              <label className="ods-form-label flex flex-row flex-wrap">
                Salgsdato
                <RequiredLabel />
              </label>
              <div className="flex relative items-center justify-end focus-within:fill-hoverblue">
                <DatePicker
                  dateFormat={["dd.MM.yyyy", "d.MM.yyyy", "ddMMyyyy"]}
                  onChange={handleSalgsdato}
                  selected={skjemadata.salgsdato}
                  customInput={
                    <input
                      data-testid="datepicker-salgsdato"
                      className={`ods-form-input ${
                        valideringAktiv &&
                        feilVedSalgsdato() &&
                        "border-errorred focus:border-errorred"
                      }`}
                    />
                  }
                />
                <CalendarIcon className="pointer-events-none absolute mr-2 h-5 w-5" />
              </div>
            </div>
            <div className="ods-form-group w-52">
              <label className="ods-form-label flex flex-row flex-wrap">
                Salgssum
                <RequiredLabel />
              </label>
              <input
                data-testid="input-salgssum"
                className={`ods-form-input ${
                  valideringAktiv &&
                  feilVedSalgssum() &&
                  "border-errorred focus:border-errorred"
                }`}
                onKeyPress={handleNumberOrEmptyValueInput}
                value={skjemadata.salgssum}
                onChange={handleSalgssum}
              />
            </div>
            <FilOpplasterRegistrerAuksjonertSkjema
              feilPaaFiler={valideringAktiv && feilVedFiler()}
              filer={filer}
              setFiler={setFiler}
            />

            {valideringAktiv &&
              (feilVedSalgsdato() || feilVedSalgssum() || feilVedFiler()) && (
                <div className="mb-5 max-w-lg">
                  <AlertBox
                    alertLevel="error"
                    heading={
                      <h3>{`${
                        (feilVedSalgsdato() ? 1 : 0) +
                        (feilVedSalgssum() ? 1 : 0) +
                        (feilVedFiler() ? 1 : 0)
                      } feil`}</h3>
                    }
                    paragraph={
                      <div className="flex flex-col gap-y-2">
                        {feilVedSalgsdato() && (
                          <p>{`Salgsdato (${skjemadata.salgsdato?.toLocaleDateString()}) kan ikke være på et tidligere tidspunkt enn tidspunktet kjøretøyet ble sendt til auksjon (${new Date(
                            data?.auksjon?.sendtTilAuksjon?.toString() ?? ""
                          ).toLocaleDateString()})`}</p>
                        )}
                        {feilVedSalgssum() && (
                          <p>Salgssum må oppgis som et tall</p>
                        )}
                        {feilVedFiler() && <p>Filer må lastes opp</p>}
                      </div>
                    }
                  />
                </div>
              )}

            {isLoading && <LoadingSpinner />}
            {isError && <CustomError error={(error as Error).message} />}
            <div className="flex flex-wrap flex-row">
              <button
                className="btn btn--flat btn--icon-left mr-5"
                onClick={() => navigate(ROUTES.Auksjon)}
              >
                <ArrowDown className="fill-current inline-flex rotate-[90deg]" />
                <span>Avbryt</span>
              </button>
              <button
                data-testid="auksjonert-last-opp-og-bekreft"
                className="btn btn--primary btn--icon-right"
                onClick={handleLastOppDokumenter}
                disabled={valideringAktiv && feilVedSkjema()}
              >
                <ArrowDown className="fill-current w-5 inline-flex rotate-[-90deg]" />
                <span>Last opp og bekreft</span>
              </button>
            </div>
          </>
        }
      />
    </div>
  );
};
