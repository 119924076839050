import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Print } from "../../assets/icons/print.svg";
import { ReactComponent as Slett } from "../../assets/icons/slett.svg";
import {
  useAngreOpprettAuksjon,
  useSendTilAuksjon
} from "../../hooks/useAuksjon";
import { download_table_as_csv } from "../../utils/csvTableGenerator";
import { useToast } from "../common/toast/ToastProvider";
import { Inntauingsjournal } from "../inntauingsjournal/Inntauingsjournal";
import { AuksjonslisteRegneark } from "./AuksjonslisteRegneark";
import { KolonneNasjonskodeTd } from "../common/table/KolonneNasjonskode";
import { formaterSisteTakstDato } from "../taksering/takseringUtils";
import { isSaksbehandler } from "../../utils/userRolesUtils";
import { HeadingUtskrift } from "../common/typography/HeadingUtskrift";

interface TilAuksjonTabellProps {
  auksjonsliste?: InntauingViewModel[];
}

export const TilAuksjonTabell = (props: TilAuksjonTabellProps) => {
  const { auksjonsliste } = props;

  const [valgteIder, setValgteIder] = useState<string[]>([]);

  const putSendTilAuksjon = useSendTilAuksjon();
  const deleteAngreOpprettAuksjon = useAngreOpprettAuksjon();

  const componentPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    pageStyle: "m-5"
  });
  const { addToast } = useToast();

  const handleAngreOpprettAuksjon = () => {
    deleteAngreOpprettAuksjon.mutateAsync(
      {
        inntauingsIder: valgteIder
      },
      {
        onSuccess: response => {
          setValgteIder([]);
          if ((response.data.inntauingerIkkeFunnet?.length ?? 0) > 0) {
            const msg = `Angret klargjøring av auksjonering for ${
              response.data.inntauingerFunnet?.length ?? 0
            } kjøretøy, kunne ikke angre klargjøring av auksjonering for ${
              response.data.inntauingerIkkeFunnet?.length ?? 0
            } kjøretøy`;
            addToast(msg, "error");
          } else {
            const msg = `Angret klargjøring av auksjonering for ${
              response.data.inntauingerFunnet?.length ?? 0
            } kjøretøy`;
            addToast(msg, "success");
          }
        },
        onError: () => {
          setValgteIder([]);
          addToast(
            "En feil skjedde ved angring av klargjøring av auksjonering av kjøretøy",
            "error"
          );
        }
      }
    );
  };

  const handleSendTilVraking = () => {
    putSendTilAuksjon.mutateAsync(
      {
        inntauingsIder: valgteIder
      },
      {
        onSuccess: response => {
          setValgteIder([]);
          if ((response.data.inntauingerIkkeFunnet?.length ?? 0) > 0) {
            const msg = `${
              response.data.inntauingerFunnet?.length ?? 0
            } kjøretøy sendt til auksjon, ${
              response.data.inntauingerIkkeFunnet?.length ?? 0
            } kjøretøy kunne ikke sendes til auksjon`;
            addToast(msg, "error");
          } else {
            const msg = `${
              response.data.inntauingerFunnet?.length ?? 0
            } kjøretøy sendt til auksjon`;

            addToast(msg, "success");
          }
        },
        onError: () => {
          setValgteIder([]);
          addToast(
            "En feil skjedde ved sending av kjøretøy til auksjon",
            "error"
          );
        }
      }
    );
  };

  const handleCheck = (id: string) => {
    if (valgteIder.some(i => i === id))
      setValgteIder(prev => prev.filter(i => i !== id));
    else setValgteIder(prev => [...prev, id]);
  };

  const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) =>
    e.currentTarget.checked && auksjonsliste
      ? setValgteIder(auksjonsliste.map(i => i.id))
      : setValgteIder([]);

  if (!auksjonsliste) return null;

  return (
    <>
      <table className="tbl">
        <thead>
          <tr>
            <th>
              <input
                title="Velg alle"
                type="checkbox"
                className="ods-form-check-input"
                checked={
                  auksjonsliste.length === valgteIder.length &&
                  valgteIder.length > 0
                }
                onChange={handleCheckBox}
              />
            </th>
            <th>Rad</th>
            <th>Nasjon</th>
            <th>ID</th>
            <th>Type</th>
            <th>Merke</th>
            <th>Siste takstdato</th>
          </tr>
        </thead>
        <tbody>
          {auksjonsliste.map(i => (
            <tr key={i.id}>
              <td>
                <input
                  data-testid={`til-auksjon-checkbox-${i.kjoretoy.registreringsnummer}`}
                  type="checkbox"
                  className="ods-form-check-input"
                  checked={valgteIder.includes(i.id)}
                  onChange={() => handleCheck(i.id)}
                />
              </td>
              <td>{i.kjoretoyleveranse?.plassering}</td>
              <KolonneNasjonskodeTd nasjonskode={i.kjoretoy.nasjonalitet} />
              <td>
                {i.kjoretoyleveranse?.journalnummer
                  ? "#" + i.kjoretoyleveranse?.journalnummer
                  : i.kjoretoy.registreringsnummer}
              </td>
              <td>{i.kjoretoy.kjoretoystype.kode}</td>
              <td>{i.kjoretoy.merke}</td>
              <td>{formaterSisteTakstDato(i.takseringer)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex flex-row flex-wrap mt-5 gap-3">
        {isSaksbehandler() && (
          <button
            className="btn btn--flat btn--icon-left"
            disabled={valgteIder.length === 0}
            onClick={handleAngreOpprettAuksjon}
          >
            <Slett className="fill-current" />
            <span>Kanseller auksjonering</span>
          </button>
        )}
        <button
          type="button"
          className="btn btn--primary-confirm btn--icon-left"
          disabled={valgteIder.length === 0}
          onClick={() => {
            return download_table_as_csv(
              "auksjonsliste",
              `Auksjonsliste_generert_${new Date().toLocaleString()}`
            );
          }}
        >
          <Download className="fill-current" />
          <span>Last ned valgte</span>
        </button>
        <button
          className="btn btn--primary-confirm btn--icon-left"
          disabled={valgteIder.length === 0}
          onClick={handlePrint}
        >
          <Print className="fill-current" />
          <span>Skriv ut valgte</span>
        </button>
        {isSaksbehandler() && (
          <button
            data-testid="bekreft-sendt-til-auksjon"
            className="btn btn--primary"
            disabled={valgteIder.length === 0}
            onClick={handleSendTilVraking}
          >
            Bekreft sendt til auksjon
          </button>
        )}
      </div>
      <div className="hidden">
        <AuksjonslisteRegneark
          auksjoner={auksjonsliste.filter(i => valgteIder.includes(i.id))}
        />
        <div ref={componentPrintRef}>
          <HeadingUtskrift>Liste over kjøretøy</HeadingUtskrift>
          <table className="tbl">
            <thead>
              <tr>
                <th>Rad</th>
                <th>Nasjonskode</th>
                <th>Regnr</th>
                <th>Journalnr</th>
                <th>Type</th>
                <th>Merke</th>
                <th className="sr-only">Avhuking av kjøretøy</th>
              </tr>
            </thead>
            <tbody>
              {auksjonsliste
                .filter(i => valgteIder.includes(i.id))
                .map(i => (
                  <tr key={i.id}>
                    <td>{i.kjoretoyleveranse?.plassering}</td>
                    <td>{i.kjoretoy.nasjonalitet}</td>
                    <td>{i.kjoretoy.registreringsnummer}</td>
                    <td>{i.kjoretoyleveranse?.journalnummer ?? ""}</td>
                    <td>{i.kjoretoy.kjoretoystype.kode}</td>
                    <td>{i.kjoretoy.merke}</td>
                    <td>
                      <input type="checkbox" className="ods-form-check-input" />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {auksjonsliste
            .filter(i => valgteIder.includes(i.id))
            .map(i => (
              <Inntauingsjournal key={i.id} inntauing={i} />
            ))}
        </div>
      </div>
    </>
  );
};
