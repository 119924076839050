import { useMutation, useQuery, useQueryClient } from "react-query";
import { getPrislister, postPrisliste } from "../api/apiServices/PrisService";
import { PrislisteInputModel } from "../api/interfaces/Prisliste";
import { CACHE_KEYS } from "../utils/globalConstants";

export const usePrislister = () =>
  useQuery([CACHE_KEYS.Prislister], () => getPrislister().then(r => r.data));

export const useOpprettPrisliste = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (variables: { prisliste: PrislisteInputModel }) => {
      const r = await postPrisliste(variables.prisliste);
      return r.data;
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([CACHE_KEYS.Prislister]);
      }
    }
  );
};
