export const formaterNullableBelop = (value?: number) => {
  if (value) {
    return value.toLocaleString() + ",-";
  }

  return "0,-";
};

export const formaterTomStrengVedNumber0 = (value?: number) => {
  if (value === 0) return "";
  if (value === undefined) return "";
  return value.toString();
};

export const formaterOppramsing = (list: string[]): string => {
  if (list.length === 0) {
    return "";
  } else if (list.length === 1) {
    return list[0];
  } else if (list.length === 2) {
    return list.join(" og ");
  } else {
    const lastTwo = list.slice(-2).join(" og ");
    const rest = list.slice(0, -2).join(", ");
    return `${rest}, ${lastTwo}`;
  }
};

export const formaterFodselnummerEllerOrgnummer = (nummer: string): string => {
  if (nummer.length === 11) {
    return `${nummer.slice(0, 6)} ${nummer.slice(6)}`;
  }
  if (nummer.length === 9) {
    return `${nummer.slice(0, 3)} ${nummer.slice(3, 6)} ${nummer.slice(6)}`;
  }
  return nummer;
};
