import { useCallback } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import {
  PrisForInntauingViewModel,
  InntauingViewModel
} from "../../api/interfaces/Inntauing";
import { usePrislister } from "../../hooks/usePris";
import { formaterNullableBelop } from "../../utils/formatUtils";
import { handleNumberOrEmptyValueInput } from "../../utils/keyboardUtils";
import { Toggle } from "../common/form/Toggle";
import { hentPrislisteForDato } from "../innstillinger/prislister/prislisteUtils";
import { BetalingsspesifikasjonLinje } from "./BetalingsspesifikasjonLinje";
import {
  beregnAntallDogn,
  Felter,
  mapBetalingsmetode
} from "./utleveringUtils";
import { Betalingstype } from "../../api/interfaces/Betaling";

interface RegistrerUtleveringDognleieProps {
  inntauing: InntauingViewModel;
  kostnader: PrisForInntauingViewModel;
}

export const RegistrerUtleveringDognleie = (
  props: RegistrerUtleveringDognleieProps
) => {
  const { inntauing, kostnader } = props;

  const { data: prislister } = usePrislister();

  const {
    formState: { errors },
    control,
    setValue
  } = useFormContext<Felter>();

  const justerDognleie = useWatch({
    control,
    name: "justerDognleie"
  });

  const tidspunkt = useWatch({
    control,
    name: "tidspunkt"
  });

  const betalingsmetode = useWatch({
    control,
    name: "betalingsmetode"
  });

  const handleToggleJusterDognleie = useCallback(() => {
    setValue("justerDognleie", !justerDognleie);
    setValue("nyDognleie", "", {
      shouldValidate: Object.entries(errors).length > 0
    });
  }, [errors, justerDognleie, setValue]);

  const tidPaaTomt =
    new Date(tidspunkt ?? new Date()).getTime() -
    new Date(
      inntauing.kjoretoyleveranse?.leveringstidspunkt ?? new Date()
    ).getTime();
  const dognPaTomt = beregnAntallDogn(tidPaaTomt);
  const ingenBetaling =
    mapBetalingsmetode(betalingsmetode) === Betalingstype.Frafalt;

  return (
    <>
      <div className="ods-form-group mt-5 mb-2">
        <Toggle
          id="justerDognleie"
          leftText="Ingen justering av døgnleie"
          rightText="Juster døgnleie"
          value={justerDognleie}
          toggleFunction={handleToggleJusterDognleie}
          disabled={inntauing.kanUtleveresMedRedusertDognleie === false}
        />
      </div>
      <BetalingsspesifikasjonLinje
        navn={`Døgnleiepris (${formaterNullableBelop(
          hentPrislisteForDato(new Date(), prislister)?.dognleie.pris
        )} x ${dognPaTomt} døgn)`}
        title="Døgnleiepris"
        belop={kostnader?.dognleiekostnad?.pris ?? 0}
        lineThrough={justerDognleie || ingenBetaling}
      />
      {justerDognleie && (
        <div className="ods-form-group grid grid-cols-2 max-w-xl mt-2 mb-2 pb-2 border-b-2 border-gray-300">
          <label
            htmlFor="nyDognleie"
            className="ods-form-label flex self-center"
          >
            Ny døgnleie
          </label>
          <Controller
            control={control}
            name="nyDognleie"
            render={({ field, fieldState }) => (
              <input
                id="nyDognleie"
                className={`ods-form-input w-52 ml-auto mr-0 text-right ${
                  fieldState.error && "border-errorred focus:border-errorred"
                }`}
                onKeyDown={handleNumberOrEmptyValueInput}
                autoComplete="off"
                {...field}
              />
            )}
          />
        </div>
      )}
    </>
  );
};
