import { endOfDay, startOfDay, startOfMonth } from "date-fns";
import { useStateParams } from "../../../hooks/useStateParams";
import { HeadingView } from "../../common/typography/HeadingView";
import { FraTilDato } from "../../common/form/FraTilDato";
import { HeadingTopic } from "../../common/typography/HeadingTopic";
import { LoadingSpinner } from "../../common/loading/LoadingSpinner";
import { CustomError } from "../../common/typography/CustomError";
import { AlertBox } from "../../common/form/AlertBox";
import { useHentedeInntauingerUtenBetalinger } from "../../../hooks/useHenting";

export const UtleveringerUtenBetaling = () => {
  const forsteDagDenneManeden = startOfMonth(startOfDay(new Date()));
  const iDag = endOfDay(new Date());

  const [fraDato, setFraDato] = useStateParams(
    forsteDagDenneManeden,
    "fra",
    s => s.toISOString(),
    s => new Date(s)
  );
  const [tilDato, setTilDato] = useStateParams(
    iDag,
    "til",
    s => s.toISOString(),
    s => new Date(s)
  );

  const { data, isLoading, isError, error } =
    useHentedeInntauingerUtenBetalinger(fraDato, tilDato);

  return (
    <>
      <HeadingView>Utlevering uten betalinger</HeadingView>
      <FraTilDato
        fraDato={fraDato}
        tilDato={tilDato}
        handleChangeFraDato={setFraDato}
        handleChangeTilDato={setTilDato}
      />
      <HeadingTopic>
        Registrerte utleveringer uten betaling for gitt periode
      </HeadingTopic>
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      {data && (
        <>
          {data.length === 0 ? (
            <div className="mt-5">
              <AlertBox
                alertLevel="warning"
                heading="Fant ingen utleverte kjøretøy uten betalinger for angitt periode"
              />
            </div>
          ) : (
            <>
              <table className="tbl mt-3 mb-10">
                <thead>
                  <tr>
                    <th title="Ileggelsesnummer">Ileggelsesnr</th>
                    <th title="Registreringsnummer">Regnr</th>
                    <th title="Journalnummer">Journalnr</th>
                    <th>Merke</th>
                    <th>Modell</th>
                    <th title="Tidspunkt for bestilling av inntauing">
                      Innt. tidspunkt
                    </th>
                    <th title="Tidspunkt for henting fra tomt">
                      Hentetidspunkt
                    </th>
                    <th>Kommentar (kommer)</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map(b => (
                    <tr key={b.id}>
                      <td>{b.ileggelse?.ileggelsesnummer ?? ""}</td>
                      <td>{b.kjoretoy.registreringsnummer ?? ""}</td>
                      <td>
                        {b.kjoretoyleveranse?.journalnummer
                          ? `#${b.kjoretoyleveranse.journalnummer}`
                          : ""}
                      </td>
                      <td>{b.kjoretoy.merke ?? ""}</td>
                      <td>{b.kjoretoy.modell ?? ""}</td>
                      <td>
                        {b.inntauingsgrunnlag?.bestillingstidspunkt
                          ? new Date(
                              b.inntauingsgrunnlag.bestillingstidspunkt
                            ).toLocaleString()
                          : ""}
                      </td>
                      <td>
                        {b.henting?.hentetidspunkt
                          ? new Date(b.henting.hentetidspunkt).toLocaleString()
                          : ""}
                      </td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </>
      )}
    </>
  );
};
