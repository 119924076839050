import React from "react";

interface KeyValueKortInnholdProps {
  id?: string;
  keyValuePair: KeyValuePair[];
  keyWidth?: string;
  valueContainerClassName?: string;
}

export interface KeyValuePair {
  key: string;
  value: string[] | JSX.Element[];
  isKeyChild?: boolean;
  dataTestid?: string;
  title?: string;
}

export const KeyValueKortInnhold = (props: KeyValueKortInnholdProps) => {
  const { keyValuePair, keyWidth, valueContainerClassName } = props;

  return (
    <dl>
      {keyValuePair.map(k => {
        return (
          <div key={k.key} className="flex">
            <div>
              <dt
                title={k.title}
                className={`${
                  k.isKeyChild && "ml-4"
                } osg-sans-bold mb-4 ${keyWidth}`}
              >
                {k.key}:
              </dt>
            </div>
            <div
              className={
                valueContainerClassName ? `${valueContainerClassName}` : ""
              }
            >
              <dd className={"mb-4 break-all"} data-testid={k.dataTestid}>
                {k.value.map(v =>
                  typeof v === "string" ? (
                    <p key={`${k}${v}`}>{v}</p>
                  ) : (
                    <span key={`${k}${v}`}>{v}</span>
                  )
                )}
              </dd>
            </div>
          </div>
        );
      })}
    </dl>
  );
};
