import { FakturaViewModel } from "./Faktura";

export enum Betalingstype {
  Kasse = 1,
  Faktura = 2,
  Frafalt = 3,
  Ukjent = 99
}

export const BetalingstypeNavn: Record<Betalingstype, string> = {
  [Betalingstype.Kasse]: "Kassebetaling",
  [Betalingstype.Faktura]: "Faktura",
  [Betalingstype.Frafalt]: "Uten betaling",
  [Betalingstype.Ukjent]: "Ukjent"
};

export interface BetalingViewModel {
  betalingsnummer: number;
  betaltAvNavn: string;
  betaltAvFodselsdato: string;
  utfortAv: string;
  kjennemerke: string;
  betalingstidspunkt: Date;
  betalteGebyrer: BetalteGebyrerViewModel;
  totalsum: number;
  dognleierabatt: number;
  faktura?: FakturaViewModel;
}

export interface BetalingInputModel {
  betalingstidspunkt?: Date;
  betaler: BetalerViewModel;
  betalteGebyrer: BetalteGebyrerInputModel;
  betalingsmiddel: Betalingstype;
  dognleierabatt: number;
}

export interface BetalerViewModel {
  navn: string;
  fodselsdato?: Date;
}

export interface BetalteGebyrerInputModel {
  ileggelsesgebyr?: number;
  ileggelsestilleggsgebyr?: number;
  inntauingsgebyr?: number;
  administrasjonsgebyr?: number;
  dognleie: number;
}

export interface BetalteGebyrerViewModel {
  ileggelsesgebyr: number;
  ileggelsetilleggsgebyr: number;
  inntauingsgebyr: number;
  administrasjonsgebyr: number;
  dognleie: number;
}

export interface BetaltIleggelseViewModel {
  ileggelsesnummer: number;
  inntauetDato: Date;
  registreringsnummer: string;
  betjent: string;
  betaltAvNavn: string;
  betalingsnummer: number;
  betalingsdato: Date;
  belop: number;
}

export interface KvitteringViewModel {
  registreringsnummer: string;
  regningsnummer: string;
  ekspeditor: string;
  dato?: Date;
  eier: string;
  eierFodselsdato?: Date;
  ileggelsesgebyr: number;
  inntauingsgebyr: number;
  admingebyr: number;
  dognleie: number;
  betalingsmetode1: string;
  belop1: number;
  betalingsmetode2: string;
  belop2: number;
}
