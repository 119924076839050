import {
  InntauingViewModel,
  KjoretoyleveranseViewModel
} from "../../api/interfaces/Inntauing";

export const getInntauingsinfo = (inntauing: InntauingViewModel) => {
  return {
    radnummer: inntauing.kjoretoyleveranse?.plassering ?? "",
    ordrenummer: inntauing.kjoretoyleveranse?.ordrenummer
      ? inntauing.kjoretoyleveranse.ordrenummer.toString()
      : "",
    journalnummer: inntauing.kjoretoyleveranse?.journalnummer
      ? inntauing.kjoretoyleveranse.journalnummer.toString()
      : "",
    ileggelsesnummer: inntauing.ileggelse?.ileggelsesnummer?.toString() ?? "",
    antallBetalinger: inntauing.betalinger.length,
    flyttetTil: getFlyttetTilLokasjon(inntauing.kjoretoyleveranse),
    sumBetaltIleggelsesgebyr: inntauing.betalinger.reduce(
      (acc, el) =>
        acc +
        el.betalteGebyrer.ileggelsesgebyr +
        el.betalteGebyrer.ileggelsetilleggsgebyr,
      0
    )
  };
};

export const getFlyttetTilLokasjon = (
  kjoretoyleveranse?: KjoretoyleveranseViewModel
) => {
  if (!kjoretoyleveranse) return [""];
  if (!kjoretoyleveranse.flyttetTilGatenavn) return ["Ukjent gate"];

  let flyttetTilLokasjon = 
    [`${kjoretoyleveranse.flyttetTilGatenavn}${kjoretoyleveranse.flyttetTilGatenummer ? ` ${kjoretoyleveranse.flyttetTilGatenummer}`: ""}${(kjoretoyleveranse.flyttetTilOppgang || kjoretoyleveranse.flyttetTilDetaljer) ? "," : ""}`];

  if (kjoretoyleveranse.flyttetTilOppgang) {
    flyttetTilLokasjon = flyttetTilLokasjon.concat(`oppgang ${kjoretoyleveranse.flyttetTilOppgang}${kjoretoyleveranse.flyttetTilDetaljer ? "," : ""}`);
  }

  if (kjoretoyleveranse.flyttetTilDetaljer) {
    flyttetTilLokasjon = flyttetTilLokasjon.concat(kjoretoyleveranse.flyttetTilDetaljer);
  }

  return flyttetTilLokasjon;
};
