import { RegnskapFakturaViewModel } from "../../../api/interfaces/Regnskap";
import { formaterNullableBelop } from "../../../utils/formatUtils";
import { AlertBox } from "../../common/form/AlertBox";
import { HeadingTopic } from "../../common/typography/HeadingTopic";

interface OversendteFakturaerProps {
  fakturaer: RegnskapFakturaViewModel[];
}

export const OversendteFakturaer = (props: OversendteFakturaerProps) => {
  const { fakturaer } = props;

  return (
    <>
      <HeadingTopic>Oversendte fakturaer</HeadingTopic>
      {fakturaer.length === 0 ? (
        <div className="mt-2">
          <AlertBox
            alertLevel="warning"
            heading="Ingen oversendte fakturaer for angitt periode"
          />
        </div>
      ) : (
        <table id="fakturainntekt" className="tbl max-w-6xl mt-2">
          <thead>
            <tr>
              <th title="Tidspunkt for fakturering">Tidspunkt</th>
              <th title="Organisasjons- eller fødselsnummer">
                Org- eller fødselsnr
              </th>
              <th title="Fakturanummer">Fakturanr</th>
              <th title="Referanse fra fakturamottaker">Referanse</th>
              <th className="text-right">Beløp</th>
            </tr>
          </thead>
          <tbody>
            {fakturaer.map(f => (
              <tr key={f.fakturanummer}>
                <td>{new Date(f.faktureringstidspunkt).toLocaleString()}</td>
                <td>{f.mottakerIdentifikator}</td>
                <td>{f.fakturanummer}</td>
                <td>{f.mottakerReferanse}</td>
                <td className="text-right">{formaterNullableBelop(f.total)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};
