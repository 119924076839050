import { BetalteIleggelser } from "./betalteIleggelser/BetalteIleggelser";
import { ArkivAvstemming } from "./arkivAvstemming/ArkivAvstemming";
import { Kasseavstemming } from "./kasseavstemming/Kasseavstemming";
import { Kranbilordre } from "./kranbilordre/Kranbilordre";
import { Fakturagodkjennelse } from "./fakturagodkjennelse/Fakturagodkjennelse";
import {
  isEkspeditor,
  isSaksbehandler,
  isUtvikler
} from "../../utils/userRolesUtils";
import { PersonligeUtleveringer } from "./personligeUtleveringer/PersonligeUtleveringer";
import { Flytteinntekter } from "./flytteinntekter/Flytteinntekter";
import { UtleveringerUtenBetaling } from "./utleveringerUtenBetaling/UtleveringerUtenBetaling";
import { DeaktiverteInntauinger } from "./deaktiverteInntauinger/DeaktiverteInntauinger";
import { TabLayout } from "../common/tabs/TabLayout";

const populateTabs = (parentUrl: string, tabId: string) => {
  let tabs = [];
  let content = [];
  let hasAccess = [];

  tabs.push({
    text: "Mine utleveringer",
    href: `${parentUrl}/mineutleveringer`,
    active: tabId === "mineutleveringer"
  });
  content.push(<PersonligeUtleveringer />);
  hasAccess.push(isEkspeditor());

  tabs.push({
    text: "Kasseavstemming",
    href: `${parentUrl}/kasseavstemming`,
    active: tabId === "kasseavstemming"
  });
  content.push(<Kasseavstemming />);
  hasAccess.push(isSaksbehandler() || isUtvikler());

  tabs.push({
    text: "Faktura",
    href: `${parentUrl}/faktura`,
    active: tabId === "faktura"
  });
  content.push(<Fakturagodkjennelse />);
  hasAccess.push(isSaksbehandler() || isUtvikler());

  tabs.push({
    text: "Kranbilordre",
    href: `${parentUrl}/kranbilordre`,
    active: tabId === "kranbilordre"
  });
  content.push(<Kranbilordre />);
  hasAccess.push(isSaksbehandler() || isUtvikler());

  tabs.push({
    text: "Betalte ileggelser",
    href: `${parentUrl}/betalteileggelser`,
    active: tabId === "betalteileggelser"
  });
  content.push(<BetalteIleggelser />);
  hasAccess.push(isSaksbehandler() || isUtvikler());

  tabs.push({
    text: "Gjennomførte kasseavstemminger",
    href: `${parentUrl}/gjennomforteavstemminger`,
    active: tabId === "gjennomforteavstemminger"
  });
  content.push(<ArkivAvstemming />);
  hasAccess.push(isSaksbehandler() || isUtvikler());

  tabs.push({
    text: "Flytteinntekter",
    href: `${parentUrl}/flytteinntekter`,
    active: tabId === "flytteinntekter"
  });
  content.push(<Flytteinntekter />);
  hasAccess.push(isSaksbehandler() || isUtvikler());

  tabs.push({
    text: "Utleveringer uten betaling",
    href: `${parentUrl}/utleveringerutenbetaling`,
    active: tabId === "utleveringerutenbetaling"
  });
  content.push(<UtleveringerUtenBetaling />);
  hasAccess.push(isSaksbehandler() || isUtvikler());

  tabs.push({
    text: "Deaktiverte inntauinger",
    href: `${parentUrl}/deaktiverteinntauinger`,
    active: tabId === "deaktiverteinntauinger"
  });
  content.push(<DeaktiverteInntauinger />);
  hasAccess.push(isSaksbehandler() || isUtvikler());

  return { tabs, content, hasAccess };
};

export const Oppgjor = () => {
  const { tabs, content, hasAccess } = populateTabs(
    window.location.origin + "/oppgjor",
    window.location.pathname.split("/").pop() ?? ""
  );

  return <TabLayout tabs={tabs} content={content} hasAccess={hasAccess} />;
};
