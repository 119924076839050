import { useState } from "react";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { useDeaktiverInntauing } from "../../hooks/useInntauinger";
import { RequiredLabel } from "../common/form/RequiredLabel";
import { useToast } from "../common/toast/ToastProvider";
import { HeadingView } from "../common/typography/HeadingView";

interface DeaktiverInntauingProps {
  inntauing: InntauingViewModel;
  close: () => void;
}

export const DeaktiverInntauing = (props: DeaktiverInntauingProps) => {
  const { inntauing, close } = props;

  const [kommentar, setKommentar] = useState<string>("");

  const putDeaktiverInntauing = useDeaktiverInntauing();
  const { addToast } = useToast();

  const handleDeaktiverInntauing = () => {
    putDeaktiverInntauing.mutate(
      { inntauingsId: inntauing.id, aarsak: kommentar },
      {
        onSuccess: () => {
          addToast(
            `Inntauing med registreringsnummer "${inntauing?.kjoretoy?.registreringsnummer}" ble deaktivert`
          );
          close();
        },
        onError: () => {
          addToast(
            `Inntauing med registreringsnummer "${inntauing?.kjoretoy?.registreringsnummer}" ble ikke deaktivert`,
            "error"
          );
        }
      }
    );
  };

  const handleBokstaverEllerTab = (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (!/[a-zA-Z1-90åæøÅÆØ@%\s.,?!]/g.test(e.key) && e.key !== "Tab") e.preventDefault();
  };

  return (
    <>
      <HeadingView>Deaktiver Inntauing</HeadingView>
      <>
        <div className="ods-form-group">
          <label htmlFor="kommentar" className="ods-form-label flex flex-row">
            Deaktiver inntauing
            <RequiredLabel />
          </label>
          <div id="kommentarHelp" className="ods-form-help">
            Forklar hvorfor inntauingen skal deaktiveres
          </div>
          <textarea
            data-testid="deaktiver-inntauing-kommentar"
            className="ods-form-input"
            id="kommentar"
            value={kommentar}
            onChange={e => setKommentar(e.currentTarget.value)}
            autoComplete="off"
            autoFocus
            maxLength={250}
            onKeyDown={handleBokstaverEllerTab}
          />
          <small>{`${kommentar.length}/250 bokstaver`}</small>
        </div>
        <div className="flex flex-row flex-wrap gap-5">
          <button className="btn btn--secondary" onClick={close}>
            Avbryt
          </button>
          <button
            data-testid="deaktiver-inntauing-bekreft"
            className="btn btn--primary"
            disabled={kommentar.length === 0}
            onClick={handleDeaktiverInntauing}
          >
            Bekreft
          </button>
        </div>
      </>
    </>
  );
};
