import { InntektViewModel } from "../../../api/interfaces/Avstemming";
import { formaterNullableBelop } from "../../../utils/formatUtils";
import { HeadingTopic } from "../../common/typography/HeadingTopic";

interface OppbruttAvstemmingProps {
  inntektSystem?: InntektViewModel;
  inntektKasse?: InntektViewModel;
  inntektDifferanse?: InntektViewModel;
}

export const OppbruttAvstemming = (props: OppbruttAvstemmingProps) => {
  const { inntektSystem, inntektKasse, inntektDifferanse } = props;

  if (!inntektSystem || !inntektKasse || !inntektDifferanse) return null;

  return (
    <>
      <HeadingTopic>Avstemming</HeadingTopic>
      <table className="tbl mb-10 mt-5 max-w-7xl table-fixed">
        <thead>
          <tr>
            <th />
            <th>Innt.kostnad</th>
            <th>Admin.gebyr</th>
            <th>Døgnleie</th>
            <th>Ileggelse</th>
            <th>Totalt</th>
          </tr>
        </thead>
        <tbody>
          <OppbruttAvstemmingRad type="Kasse:" inntekt={inntektKasse} />
          <OppbruttAvstemmingRad
            type="Inntauingsystem:"
            inntekt={inntektSystem}
          />
        </tbody>
        <tfoot>
          <OppbruttAvstemmingRad
            type="Differanse:"
            inntekt={inntektDifferanse}
          />
        </tfoot>
      </table>
    </>
  );
};

interface OppbruttAvstemmingRadProps {
  type: string;
  inntekt: InntektViewModel;
}

const OppbruttAvstemmingRad = (props: OppbruttAvstemmingRadProps) => {
  const { type, inntekt } = props;

  return (
    <tr>
      <td className="font-semibold">{type}</td>
      <td>{formaterNullableBelop(inntekt.inntauing)}</td>
      <td>{formaterNullableBelop(inntekt.administrasjon)}</td>
      <td>{formaterNullableBelop(inntekt.dognleie)}</td>
      <td>{formaterNullableBelop(inntekt.ileggelse)}</td>
      <td>{formaterNullableBelop(inntekt.total)}</td>
    </tr>
  );
};
