import { AlertBox } from "./AlertBox";
import DatePicker from "./DatePicker";
import { RequiredLabel } from "./RequiredLabel";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";

interface FraTilDatoProps {
  tilDato: Date;
  tilDatoLabel?: string;
  fraDato: Date;
  fraDatoLabel?: string;
  actionButtons?: React.ReactNode;
  handleChangeTilDato: (tilDato: Date) => void;
  handleChangeFraDato: (fraDato: Date) => void;
}

export const FraTilDato = (props: FraTilDatoProps) => {
  const {
    tilDato,
    tilDatoLabel,
    fraDato,
    fraDatoLabel,
    actionButtons,
    handleChangeTilDato,
    handleChangeFraDato
  } = props;

  return (
    <>
      <div className="flex flex-row flex-wrap mb-5 gap-x-5">
        <div className="ods-form-group w-56">
          <label className="ods-form-label flex flex-wrap" htmlFor="fraDato">
            {fraDatoLabel ?? "Fra dato"}
            <RequiredLabel />
          </label>
          <div className="flex relative items-center justify-end focus-within:fill-hoverblue">
            <DatePicker
              id="fraDato"
              dateFormat={["dd.MM.yyyy", "d.MM.yyyy", "ddMMyyyy"]}
              onChange={handleChangeFraDato}
              selected={fraDato}
              customInput={
                <input
                  data-testid="fradato"
                  className="ods-form-input w-full"
                />
              }
            />
            <CalendarIcon className="pointer-events-none absolute mr-2 h-5 w-5" />
          </div>
        </div>
        <div className="ods-form-group w-56">
          <label className="ods-form-label flex flex-wrap" htmlFor="tilDato">
            {tilDatoLabel ?? "Til dato"}
            <RequiredLabel />
          </label>
          <div className="flex relative items-center justify-end focus-within:fill-hoverblue">
            <DatePicker
              id="tilDato"
              dateFormat={["dd.MM.yyyy", "d.MM.yyyy", "ddMMyyyy"]}
              onChange={handleChangeTilDato}
              selected={tilDato}
              customInput={
                <input
                  data-testid="tildato"
                  className="ods-form-input w-full"
                />
              }
            />
            <CalendarIcon className="pointer-events-none absolute mr-2 h-5 w-5" />
          </div>
        </div>
        {actionButtons}
      </div>
      {tilDato < fraDato && (
        <div className="mb-5">
          <AlertBox
            alertLevel="warning"
            heading="Til dato er satt til et tidligere tidspunkt enn fra dato"
          />
        </div>
      )}
    </>
  );
};
