import { RegistrerInntauingInntauingInfo } from "./RegistrerInntauingInntauingInfo";
import { RegistrerInntauingKjoretoyInfo } from "./RegistrerInntauingKjoretoyinfo";
import { RegistrerInntauingGebyrInfo } from "./RegistrerInntauingGebyrinfo";
import { RegistrerInntauingRegistreringsnummer } from "./RegistrerInntauingRegistreringsnummer";
import { RegistrerInntauingEierinfo } from "./RegistrerInntauingEierinfo";
import { Kort } from "../common/card/Kort";
import { ReactComponent as Personbil } from "../../assets/icons/personbil.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Pluss } from "../../assets/icons/pluss.svg";
import { ReactComponent as Minus } from "../../assets/icons/minus.svg";
import { RegistrerInntauingSVVButton } from "./RegistrerInntauingSVVButton";
import { HeadingView } from "../common/typography/HeadingView";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useToast } from "../common/toast/ToastProvider";
import { useRegistrerInntauing } from "../../hooks/useInntauinger";
import { ROUTES } from "../../utils/globalConstants";
import { registrerInntauingSchema } from "./registrerInntauingSchema";
import { RegistrerInntauingFeilmelding } from "./RegistrerInntauingFeilmelding";
import {
  INNTAUINGSSELSKAP,
  mapFelterTilInputModel,
  RegistrerInntauingFelter
} from "./registrerInntauingUtils";

export const RegistrerInntauing = () => {
  const methods = useForm<RegistrerInntauingFelter>({
    defaultValues: {
      registreringsnummer: {
        nasjonkode: "N"
      },
      bestillingsinfo: {
        inntauingsfirma: INNTAUINGSSELSKAP
      }
    },
    resolver: yupResolver(registrerInntauingSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUseNativeValidation: true,
    shouldFocusError: true
  });

  const juridiskEier = useWatch({
    control: methods.control,
    name: "juridiskEier"
  });

  const leasingtaker = useWatch({
    control: methods.control,
    name: "leasingtaker"
  });

  const navigate = useNavigate();
  const { addToast } = useToast();

  const postInntauing = useRegistrerInntauing();

  const handleClickBekreftRegistrering = (data: RegistrerInntauingFelter) => {
    postInntauing.mutate(
      {
        inntauing: mapFelterTilInputModel(data)
      },
      {
        onSuccess: () => {
          addToast("Ny inntauing registrert");
          navigate(ROUTES.Aktive_inntauinger, { replace: true });
        },
        onError: e => {
          addToast("Inntauing ble ikke registrert", "error");
        }
      }
    );
  };

  return (
    <FormProvider {...methods}>
      <form
        className="ml-5"
        onSubmit={methods.handleSubmit(handleClickBekreftRegistrering)}
      >
        <div>
          <HeadingView>Manuell registrering</HeadingView>
          <div className="b-10 w-[50%] tabletBig:w-full">
            Ved manuell registrering vil inntauingen få status Bestilt kranbil.
            For å registrere ankomst, fremmøte eller flytting på gategrunn, velg
            fra handlingsmenyen etter å ha sendt inn skjemaet.
          </div>
          <div className="my-5">
            <button
              className="btn btn--flat btn--icon-left"
              style={{ marginRight: "20px" }}
              onClick={() => navigate("/", { replace: true })}
              type="button"
            >
              <Close className="fill-current w-5" />
              <span>Avbryt</span>
            </button>
            <button
              className="btn btn--primary btn--icon-right"
              data-testid="bekreftRegistreringButton"
              type="submit"
            >
              <ArrowDown className="fill-current w-5 inline-flex rotate-[-90deg]" />
              <span>Bekreft registrering</span>
            </button>
          </div>
        </div>
        <RegistrerInntauingFeilmelding />
        <div className="mr-5 grid grid-cols-2 gap-10 tabletBig:grid-cols-1">
          <RegistrerInntauingInntauingInfo />
          <Kort
            headerText="Registreringsnummer"
            headerIcon={<Personbil className="fill-darkblue" />}
            fullWidth={true}
            innhold={
              <>
                <RegistrerInntauingRegistreringsnummer />
                <RegistrerInntauingSVVButton dataTestId="SVVEierInfo" />
              </>
            }
          />
          <RegistrerInntauingGebyrInfo />
          <div>
            <div className="pb-10">
              <Kort
                headerText="Kjøretøyinfo"
                headerIcon={<Personbil className="fill-darkblue" />}
                fullWidth={true}
                innhold={<RegistrerInntauingKjoretoyInfo />}
              />
            </div>

            <Kort
              headerText="Eierinfo"
              headerIcon={<Personbil className="fill-darkblue" />}
              fullWidth={true}
              innhold={
                <div className="flex flex-col">
                  <button
                    className="btn btn--flat btn--icon-left"
                    type="button"
                    onClick={() =>
                      methods.setValue(
                        "juridiskEier",
                        juridiskEier ? undefined : { erBedrift: false }
                      )
                    }
                  >
                    {juridiskEier ? (
                      <Minus className="fill-current" />
                    ) : (
                      <Pluss className="fill-current" />
                    )}
                    <span>Juridisk eier</span>
                  </button>
                  {juridiskEier && (
                    <>
                      <h2 className="text-xl osg-sans-bold pt-5">
                        Juridisk eier
                      </h2>
                      <RegistrerInntauingEierinfo id="juridiskEier" />
                    </>
                  )}
                  <button
                    className="btn btn--flat btn--icon-left"
                    type="button"
                    onClick={() =>
                      methods.setValue(
                        "leasingtaker",
                        leasingtaker ? undefined : { erBedrift: false }
                      )
                    }
                  >
                    {leasingtaker ? (
                      <Minus className="fill-current" />
                    ) : (
                      <Pluss className="fill-current" />
                    )}
                    <span>Leasingtaker</span>
                  </button>
                  {leasingtaker && (
                    <>
                      <h2 className="text-xl osg-sans-bold pt-5">
                        Leasingtaker
                      </h2>
                      <RegistrerInntauingEierinfo id="leasingtaker" />
                    </>
                  )}
                </div>
              }
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
