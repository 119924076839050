import "./GeneralSearch.scss";
import { ReactComponent as Search } from "../../../assets/icons/sok.svg";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { ReactComponent as Info } from "../../../assets/icons/info.svg";
import { useState } from "react";
import { TEKST_SOK } from "../../../utils/globalConstants";
import { handleTrykkEnter } from "../../../utils/keyboardUtils";

interface GeneralSearchProps {
  search: string;
  setSearch: (text: string) => void;
  handleSearch?: () => void;
  handleClose?: () => void;
  resultViewOpen: boolean;
}

export const GeneralSearch = (props: GeneralSearchProps) => {
  const { search, setSearch, handleSearch, handleClose, resultViewOpen } =
    props;

  const [showInfo, setShowInfo] = useState<boolean>(false);

  const handleSearchWithoutRerender = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (handleSearch) handleSearch();
  };

  const handleClosePreventDefault = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (handleClose) {
      setSearch("");
      handleClose();
    }
  };

  const handleCloseOnEnterKey = () => {
    if (resultViewOpen && handleClose) handleClose();
  };

  const handleSearchWithRender = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(e.currentTarget.value);

  const handleShowInfo = () => setShowInfo(true);

  const handleHideInfo = () => setShowInfo(false);

  const handleToggleInfo = () => setShowInfo(prev => !prev);

  return (
    <>
      <div
        className={`flex items-center h-full ${
          resultViewOpen && "bg-rusticyellow"
        }`}
      >
        <form
          onSubmit={handleSearchWithoutRerender}
          className="oslo-search-header desktop:w-96 w-52 h-12 tabletBig:h-8"
        >
          <input
            data-testid="input-generelt-sok"
            type="search"
            className="oslo-search-header-input"
            value={search}
            spellCheck={false}
            onKeyDown={e =>
              handleTrykkEnter(e, handleCloseOnEnterKey, resultViewOpen)
            }
            onChange={handleSearchWithRender}
          />
          {resultViewOpen ? (
            <button
              title="Trykk for å skjule søkeresultat"
              className="fill-darkblue hover:fill-white bg-white oslo-search-header-icon w-12 rounded-full tabletBig:w-8"
              type="button"
              onClick={handleClosePreventDefault}
            >
              <Close className="fill-inherit h-full w-full" />
            </button>
          ) : (
            <button
              title="Trykk for å søke"
              data-testid="submit-generelt-sok"
              type="submit"
              className="fill-darkblue hover:fill-white bg-rusticyellow oslo-search-header-icon w-14 rounded-full tabletBig:w-10"
              disabled={search.length === 0}
            >
              <Search className="fill-inherit h-full w-full" />
            </button>
          )}
        </form>
        <div className="flex mr-1">
          <button
            aria-label={TEKST_SOK}
            className="bg-transparent my-auto"
            onKeyDown={e => handleTrykkEnter(e, handleToggleInfo)}
            onMouseEnter={handleShowInfo}
            onMouseLeave={handleHideInfo}
          >
            <Info className="fill-current hover:fill-hoverblue w-8" />
          </button>
          {showInfo && (
            <>
              {resultViewOpen ? (
                <>
                  <div className="absolute z-50 desktop:left-[31rem] top-[4.2rem] w-48 text-left px-2 py-1 bg-white">
                    {TEKST_SOK}
                  </div>
                  <div className="absolute z-50 desktop:left-[39.75rem] top-[3.2rem] oslo-search-header-comment-arrow-white" />
                </>
              ) : (
                <>
                  <div className="absolute z-50 desktop:left-[31rem] top-[4.2rem] w-48 text-left px-2 py-1 bg-darkblue text-white">
                    {TEKST_SOK}
                  </div>
                  <div className="absolute z-50 desktop:left-[39.75rem] top-[3.2rem] oslo-search-header-comment-arrow-blue" />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
