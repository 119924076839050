import { useAuth } from "react-oidc-context";
import { Navigate, Outlet } from "react-router-dom";

export const PublicRoutes = () => {
  const auth = useAuth();
  
  return !auth.isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/"  />
  )
};
