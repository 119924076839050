import { Controller, useFormContext } from "react-hook-form";
import { useFarger, useMerker } from "../../hooks/useGrunndata";
import { handleNumberOrEmptyValueInput } from "../../utils/keyboardUtils";
import { RegistrerInntauingFelter } from "./registrerInntauingUtils";
import {
  Kjoretoystype,
  KjoretoystypeMapper
} from "../../api/interfaces/Inntauing";
import { mapEnumToNumberValues } from "../../utils/objectMapping";

export const RegistrerInntauingKjoretoyInfo = () => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext<RegistrerInntauingFelter>();

  const { data: merker, isLoading: isLoadingMerker } = useMerker();
  const { data: farger, isLoading: isLoadingFarger } = useFarger();

  return (
    <div className="grid grid-cols-2 gap-x-5">
      <div className="ods-form-group">
        <label className="ods-form-label" htmlFor="merke">
          Merke
        </label>
        <Controller
          name="kjoretoyinfo.merke"
          control={control}
          render={({ fieldState, field: { value } }) => (
            <select
              id="merke"
              disabled={isLoadingMerker}
              className={`ods-form-input ods-select ${
                fieldState.error ? "border-errorred focus:border-errorred" : ""
              }`}
              value={(merker ?? []).find(
                m => m.toLowerCase() === value?.toLowerCase()
              )}
              {...register("kjoretoyinfo.merke")}
            >
              <>
                <option value={undefined}></option>
                {(merker ?? []).map(merke => (
                  <option key={merke} value={merke}>
                    {merke}
                  </option>
                ))}
              </>
            </select>
          )}
        />
      </div>

      <div className="ods-form-group">
        <label className="ods-form-label" htmlFor="modell">
          Modell
        </label>
        <input
          className={`ods-form-input ${
            errors.kjoretoyinfo?.modell
              ? "border-errorred focus:border-errorred"
              : ""
          }`}
          id="modell"
          {...register("kjoretoyinfo.modell")}
          autoComplete="off"
        />
      </div>

      <div className="grid grid-cols-5 gap-x-5 w-full">
        <div className="ods-form-group col-span-2">
          <label className="ods-form-label" htmlFor="type">
            Kjøretøystype
          </label>
          <select
            id="type"
            className={`ods-form-input ods-select ${
              errors.kjoretoyinfo?.type
                ? "border-errorred focus:border-errorred"
                : ""
            }`}
            {...register("kjoretoyinfo.type")}
          >
            <>
              <option value={undefined}></option>
              {mapEnumToNumberValues(Kjoretoystype).map(k => (
                <option
                  key={k}
                  value={KjoretoystypeMapper[k as Kjoretoystype].kode}
                >
                  {KjoretoystypeMapper[k as Kjoretoystype].navn}
                </option>
              ))}
            </>
          </select>
        </div>

        <div className="ods-form-group col-span-3">
          <label className="ods-form-label" htmlFor="farge">
            Farge
          </label>
          <select
            id="farge"
            disabled={isLoadingFarger}
            className={`ods-form-input ods-select ${
              errors.kjoretoyinfo?.farge
                ? "border-errorred focus:border-errorred"
                : ""
            }`}
            {...register("kjoretoyinfo.farge")}
          >
            <>
              <option value={undefined}></option>
              {(farger ?? []).map(farge => (
                <option key={farge} value={farge}>
                  {farge}
                </option>
              ))}
            </>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-x-5 w-full">
        <div className="ods-form-group">
          <label className="ods-form-label" htmlFor="totalvekt">
            Totalvekt
          </label>
          <input
            className={`ods-form-input ${
              errors.kjoretoyinfo?.totalvekt
                ? "border-errorred focus:border-errorred"
                : ""
            }`}
            id="totalvekt"
            onKeyDown={handleNumberOrEmptyValueInput}
            {...register("kjoretoyinfo.totalvekt")}
            autoComplete="off"
          />
        </div>

        <div className="ods-form-group">
          <label className="ods-form-label" htmlFor="registrertAar">
            Registrert år
          </label>
          <input
            className={`ods-form-input ${
              errors.kjoretoyinfo?.registrertAar
                ? "border-errorred focus:border-errorred"
                : ""
            }`}
            id="registrertAar"
            {...register("kjoretoyinfo.registrertAar")}
            onKeyDown={handleNumberOrEmptyValueInput}
            autoComplete="off"
          />
        </div>
      </div>
    </div>
  );
};
