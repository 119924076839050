import { ArkiverteVrak } from "./ArkiverteVrak";
import { SendtTilVrak } from "./SendtTilVrak";
import { TilVrak } from "./TilVrak";
import { TabLayout } from "../common/tabs/TabLayout";

const Vrakprosess = () => {
  return (
    <>
      <TilVrak />
      <SendtTilVrak />
    </>
  );
};

const populateTabs = (parentUrl: string, tabId: string) => {
  let tabs = [];
  let content = [];
  let hasAccess = [];

  tabs.push({
    text: "Vrakprosess",
    href: `${parentUrl}/prosess`,
    active: tabId === "prosess"
  });
  content.push(<Vrakprosess />);
  hasAccess.push(true);

  tabs.push({
    text: "Gjennomførte",
    href: `${parentUrl}/gjennomforte`,
    active: tabId === "gjennomforte"
  });
  content.push(<ArkiverteVrak />);
  hasAccess.push(true);

  return { tabs, content, hasAccess };
};

export const Vraking = () => {
  const { tabs, content, hasAccess } = populateTabs(
    window.location.origin + "/vraking",
    window.location.pathname.split("/").pop() ?? ""
  );

  return <TabLayout tabs={tabs} content={content} hasAccess={hasAccess} />;
};
