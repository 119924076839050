import { Rad } from "./Table";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-thin.svg";

interface TableBodyRowProps {
  rad: Rad;
  radIndex: number;
  valgtRad: string;
  showCheckboxes?: boolean;
  antallKolonner: number;
  onRadClick: (radId: string | undefined) => void;
}

export const TableBodyRow = (props: TableBodyRowProps) => {
  const {
    rad,
    radIndex,
    valgtRad,
    showCheckboxes,
    antallKolonner,
    onRadClick
  } = props;

  const handleRadClick = () => {
    if (!!rad.detaljer) onRadClick(rad.id);
  };

  const handleRadEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !!rad.detaljer) onRadClick(rad.id);
  };

  return (
    <tr
      title={rad.id ?? "Mangler ID"}
      tabIndex={0}
      data-testid={`inntauinger-rad-${radIndex?.toString()}`}
      className={`w-full hover:bg-gray-100 ${
        rad.id === valgtRad ? "bg-gray-100" : ""
      } h-14 oslo-table-row-${antallKolonner + 1} clickable ${
        rad.cssClass ?? ""
      }`}
      onClick={handleRadClick}
      onKeyDown={handleRadEnterKey}
    >
      {!!rad.detaljer && (
        <td>
          <ArrowDown
            className={`w-6 h-6 ${
              valgtRad === rad.id
                ? "animate-accordion-arrow-open"
                : "animate-accordion-arrow-close"
            }`}
          />
        </td>
      )}
      {showCheckboxes && rad.toggleCheckbox && (
        <td title="Velg inntauing" onClick={e => e.stopPropagation()}>
          <input
            type="checkbox"
            className="ods-form-check-input"
            checked={rad.checked ?? false}
            onChange={rad.toggleCheckbox}
          />
        </td>
      )}
      {rad.kolonner.map(({ truncate = true, ...kolonne }, kolonneIndex) => (
        <td
          data-testid={`table-row-cell-${kolonne.verdi}`}
          className={
            (truncate ? "truncate " : " ") +
            "my-auto " +
            (kolonne.cssClass ?? "")
          }
          key={kolonne.id ?? kolonneIndex}
          title={kolonne.tittel ?? kolonne.verdi?.toString()}
        >
          {kolonne.verdi}
        </td>
      ))}
    </tr>
  );
};
