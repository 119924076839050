import { useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { KunngjoringerUtskrift } from "./KunngjoringerUtskrift";
import { HeadingView } from "../common/typography/HeadingView";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { ReactComponent as Print } from "../../assets/icons/print.svg";
import { ROUTES } from "../../utils/globalConstants";
import { useKunngjorVrak } from "../../hooks/useVrak";
import { useKunngjorAuksjon } from "../../hooks/useAuksjon";
import { useToast } from "../common/toast/ToastProvider";
import { AlertBox } from "../common/form/AlertBox";
import { HeadingUtskrift } from "../common/typography/HeadingUtskrift";

export const Kunngjoringsliste = () => {
  const [search] = useSearchParams();

  const [bekreftet, setBekreftet] = useState(false);

  const componentPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    pageStyle: "m-5"
  });
  const navigate = useNavigate();
  const { addToast } = useToast();

  const putKunngjorAuksjon = useKunngjorAuksjon();
  const putKunngjorVrak = useKunngjorVrak();

  const inntauingsIder =
    search
      .get("inntauingsIder")
      ?.split(",")
      .filter(id => id) ?? [];

  const handleBekreftOpprettetKunngjoringsliste = () => {
    if (inntauingsIder) {
      let antallSuksess = 0;
      let antallTotalt = inntauingsIder.length;

      putKunngjorAuksjon.mutate(
        {
          inntauingsIder: inntauingsIder
        },
        {
          onSuccess: ra => {
            putKunngjorVrak.mutate(
              {
                inntauingsIder: ra.data.inntauingerIkkeFunnet
              },
              {
                onSuccess: rv => {
                  antallSuksess =
                    ra.data.inntauingerFunnet.length +
                    rv.data.inntauingerFunnet.length;

                  if (antallSuksess > 0) {
                    addToast(
                      `${antallSuksess} inntauinger ble bekreftet kunngjort, ${
                        antallTotalt - antallSuksess
                      } inntauinger kunne ikke bekreftes kunngjort`
                    );
                  } else {
                    addToast(
                      "Ingen inntauinger ble bekreftet kunngjort",
                      "error"
                    );
                  }
                }
              }
            );
          }
        }
      );

      setBekreftet(true);
    }
  };

  if (inntauingsIder.length === 0) {
    return (
      <div className="flex flex-col mx-5 mb-10">
        <HeadingView>Opprett kunngjoringsliste</HeadingView>
        <AlertBox
          alertLevel="warning"
          heading="Ingen inntauinger valgt"
          paragraph={
            <p>
              Du må velge minst én inntauing ved å krysse av i Aktive
              inntauinger for å kunne opprette kunngjøringsliste. Du må også
              sørge for at minst én ID kommer med i adressen til nettsiden.
            </p>
          }
        />
        <div className="flex flex-row flex-wrap gap-5 mt-10">
          <button
            className="btn btn--flat btn--icon-left"
            onClick={() => navigate(ROUTES.Aktive_inntauinger)}
          >
            <ArrowDown className="fill-current inline-flex rotate-[90deg]" />
            <span>Tilbake</span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col mx-5 mb-10">
      <HeadingView>Opprett kunngjoringsliste</HeadingView>
      <KunngjoringerUtskrift inntauingsIder={inntauingsIder} />
      <div className="flex flex-row flex-wrap gap-5 mt-10">
        <button
          className="btn btn--flat btn--icon-left"
          onClick={() => navigate(ROUTES.Aktive_inntauinger)}
        >
          <ArrowDown className="fill-current inline-flex rotate-[90deg]" />
          <span>Tilbake</span>
        </button>
        <button
          className="btn btn--primary-confirm btn--icon-left"
          onClick={handlePrint}
        >
          <Print className="fill-current" />
          <span>Skriv ut</span>
        </button>
        <button
          className="btn btn--primary btn--icon-right"
          onClick={handleBekreftOpprettetKunngjoringsliste}
          disabled={inntauingsIder.length === 0 || bekreftet}
        >
          <ArrowDown className="fill-current inline-flex rotate-[-90deg]" />
          <span>Bekreft opprettet</span>
        </button>
        {bekreftet && (
          <button
            className="btn btn--primary btn--icon-right"
            onClick={() => navigate(ROUTES.Aktive_inntauinger)}
          >
            <ArrowDown className="fill-current inline-flex rotate-[-90deg]" />
            <span>Gå til hovedsiden</span>
          </button>
        )}
      </div>
      <div className="hidden">
        <div className="text-xs" ref={componentPrintRef}>
          <HeadingUtskrift>
            Kunngjøring av kjøretøy til vrak/auksjon
          </HeadingUtskrift>
          <KunngjoringerUtskrift inntauingsIder={inntauingsIder} tilPrint />
        </div>
      </div>
    </div>
  );
};
