import { useState } from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { ReactComponent as Handling } from "../../../assets/icons/handling.svg";
import {
  HjemmelType,
  InntauingViewModel
} from "../../../api/interfaces/Inntauing";
import { ROUTES } from "../../../utils/globalConstants";
import { AxiosError } from "axios";
import {
  useAngreAnkomst,
  useAngreFlyttetPaaGategrunn,
  useAvbrytFremmote,
  useOpprettKjoretoyleveranse
} from "../../../hooks/useKjoretoyleveranse";
import { useNavigate } from "react-router-dom";
import { useTillatFakturering } from "../../../hooks/useInntauinger";

import {
  useAngreSendtTilAuksjon,
  useOpprettAuksjon
} from "../../../hooks/useAuksjon";

import {
  isInnsyn,
  isSaksbehandler,
  isUtvikler
} from "../../../utils/userRolesUtils";
import {
  erUnderTauing,
  erPaaTomta,
  kanSettesTilVrakEllerAuksjon,
  erHentetPaTomt,
  HANDLINGER,
  erAktiv,
  erStatusFremmote,
  harIngenMuligeHandlinger,
  erStatusSendtTilAuksjon,
  erStatusSendtTilVrak,
  erStatusAnkommetTomt,
  erStatusFlyttetPaaGategrunn,
  harUferdigeTakseringer
} from "./handlingsknappUtils";
import { useToast } from "../toast/ToastProvider";
import { useAvbrytHenting } from "../../../hooks/useHenting";
import {
  useAngreSendtTilVraking,
  useOpprettVrak
} from "../../../hooks/useVrak";
import { useOpprettTaksering } from "../../../hooks/useTakst";
import { BetalingskravStatusType } from "../../../api/interfaces/Betalingskrav";
import { useGjenopprettBetalingskrav } from "../../../hooks/useBetalingskrav";
import { useFjernFlaggs } from "../../../hooks/useFlagg";
import { LEVERANSEMETODER } from "../../registrerFlyttetPaaGategrunn/registrerFlyttetPaaGategrunnUtils";

interface HandlingsknappProps {
  inntauing?: InntauingViewModel;
  inntauingsstatus?: number;
  visMeny?: string;
  setVisMeny?: (inntauingsId?: string) => void;
  visHandling?: (inntauing?: InntauingViewModel, handling?: string) => void;
}

export const Handlingsknapp = (props: HandlingsknappProps) => {
  const {
    inntauing,
    inntauingsstatus = -1,
    visMeny,
    setVisMeny,
    visHandling
  } = props;

  const breddeMeny = 250;
  const [anchorPoint, setAnchorPoint] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0
  });

  const navigate = useNavigate();

  const ref = useOnClickOutside<HTMLDivElement>(() => {
    if (setVisMeny && visMeny) setVisMeny(undefined);
  });
  const { addToast } = useToast();

  const postLeveranse = useOpprettKjoretoyleveranse();
  const putAvbrytHenting = useAvbrytHenting(inntauing?.id ?? "");
  const postOpprettTaksering = useOpprettTaksering();
  const putOpprettVrak = useOpprettVrak();
  const putOpprettAuksjon = useOpprettAuksjon(inntauing?.id ?? "");
  const putTillatFakturering = useTillatFakturering(inntauing?.id ?? "");
  const putAvbrytFremmote = useAvbrytFremmote();
  const putAngreAnkomst = useAngreAnkomst();
  const putAngreFlyttet = useAngreFlyttetPaaGategrunn();
  const putAngreSendtTilAuksjon = useAngreSendtTilAuksjon();
  const putAngreSendtTilVraking = useAngreSendtTilVraking();
  const putGjenopprettBetalingskrav = useGjenopprettBetalingskrav();
  const putFjernFlagg = useFjernFlaggs();

  const handleVisMeny = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setAnchorPoint({ x: e.pageX, y: e.pageY });
    if (setVisMeny) setVisMeny(visMeny ? undefined : inntauing?.id);
  };

  const handleVisHandling = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    handling: string
  ) => {
    e.stopPropagation();
    if (visHandling) visHandling(inntauing, handling);
    if (setVisMeny) setVisMeny(undefined);
  };

  const handleRegistrerAnkommet = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => handleVisHandling(e, HANDLINGER.Registrer_ankommet);

  const handleFlyttetPaaGategrunn = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => handleVisHandling(e, HANDLINGER.Registrer_flyttet_på_gategrunn);

  const handleOpprettFlagg = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => handleVisHandling(e, HANDLINGER.Opprett_flagg);

  const handleFjernFlagg = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const opprettedeFlagg = inntauing?.flagg?.filter(f => !f.fjernet);
    if (inntauing && opprettedeFlagg?.length === 1) {
      e.stopPropagation();
      if (setVisMeny) setVisMeny(undefined);
      putFjernFlagg.mutate(
        { inntauingsId: inntauing.id, flagg: [opprettedeFlagg[0]] },
        {
          onSuccess: statusFlaggTyper => {
            addToast(
              `Flagg med flaggtyper som ble fjernet: ${statusFlaggTyper.okFlaggTyper}`
            );
          },
          onError: statusFlaggTyper => {
            addToast(
              `Flagg med flaggtyper som ble fjernet: ${statusFlaggTyper.okFlaggTyper}, 
              flagg som ikke ble fjernet: ${statusFlaggTyper.feiletFlaggTyper}`,
              "error"
            );
          }
        }
      );
    } else {
      handleVisHandling(e, HANDLINGER.Fjern_flagg);
    }
  };

  const handleDeaktiverInntauing = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => handleVisHandling(e, HANDLINGER.Deaktiver_inntauing);

  const handleKansellerBetalingskrav = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => handleVisHandling(e, HANDLINGER.Kanseller_betalingskrav);

  const handleOpprettVrak = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (setVisMeny) setVisMeny(undefined);
    if (inntauing) {
      putOpprettVrak.mutate(
        { inntauingsId: inntauing.id },
        {
          onSuccess: () => {
            addToast("Vrak opprettet", "success");
          },
          onError: () => {
            addToast("Feil ved opprettelse av vrak", "error");
          }
        }
      );
    }
  };

  const handleOpprettAuksjon = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (setVisMeny) setVisMeny(undefined);
    if (inntauing) {
      putOpprettAuksjon.mutate(undefined, {
        onSuccess: () => {
          addToast("Auksjon opprettet", "success");
        },
        onError: () => {
          addToast("Feil ved opprettelse av auksjon", "error");
        }
      });
    }
  };

  const handleAngreSendtTilAuksjon = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (setVisMeny) setVisMeny(undefined);
    if (inntauing) {
      putAngreSendtTilAuksjon.mutate(
        { inntauingsId: inntauing.id },
        {
          onSuccess: () => {
            addToast("Angret sendt til auksjon");
          },
          onError: () => {
            addToast("Feil ved angring av sendt til auksjon", "error");
          }
        }
      );
    }
  };

  const handleAngreSendtTilVrak = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (setVisMeny) setVisMeny(undefined);
    if (inntauing) {
      putAngreSendtTilVraking.mutate(
        { inntauingsId: inntauing.id },
        {
          onSuccess: () => {
            addToast("Angret sendt til vrak");
          },
          onError: () => {
            addToast("Feil ved angring av sendt til vrak", "error");
          }
        }
      );
    }
  };

  const handleOpprettTaksering = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (setVisMeny) setVisMeny(undefined);
    if (inntauing) {
      postOpprettTaksering.mutate(
        {
          inntauingsIder: [{ id: inntauing.id }]
        },
        {
          onSuccess: () => {
            addToast("Taksering opprettet", "success");
          },
          onError: () => {
            addToast("Feil ved opprettelse av taksering", "error");
          }
        }
      );
    }
  };

  const handleUtleverInntauing = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    navigate(`${ROUTES.Utlevering_av_inntauing + "/" + inntauing?.id ?? ""}`);
  };

  const handleBrevutsending = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    navigate(`${ROUTES.ManuellBrevutsendelse}/${inntauing?.id ?? ""}`);
  };

  const handleAvbrytHenting = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (setVisMeny) setVisMeny(undefined);
    putAvbrytHenting.mutate(undefined, {
      onSuccess: () => {
        addToast("Inntauing reaktivert");
        navigate(ROUTES.Aktive_inntauinger);
      },
      onError: response =>
        addToast(
          "Feil ved reaktivering av inntauing:\n\n" +
            (response as AxiosError).message,
          "error"
        )
    });
  };

  const handleTillatFakturering = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (setVisMeny) setVisMeny(undefined);
    if (inntauing) {
      putTillatFakturering.mutate(undefined, {
        onSuccess: () => {
          addToast("Tillatelse til fakturering registrert");
        },
        onError: () => {
          addToast(
            "Feil ved registrering av tillatelse til fakturering",
            "error"
          );
        }
      });
    }
  };

  const handleTillatUtleveringUtenBetaling = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => handleVisHandling(e, HANDLINGER.Tillat_utlevering_uten_betaling);

  const handleTillatUtleveringMedRedusertDognleie = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => handleVisHandling(e, HANDLINGER.Tillat_utlevering_med_redusert_dognleie);

  const handleRegistrerFremmote = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (setVisMeny) setVisMeny(undefined);
    if (inntauing?.id && !inntauing.kjoretoyleveranse) {
      postLeveranse.mutate(
        {
          inntauingsId: inntauing.id,
          kjoretoyleveranse: {
            inntauingsfirma: inntauing.inntauingsgrunnlag.inntauingsfirma ?? "",
            leveringstidspunkt: new Date(),
            type: LEVERANSEMETODER.Frammøte.kode
          }
        },
        {
          onSuccess: () => {
            addToast("Registrert fremmøte");
          },
          onError: response => {
            addToast(
              "Feil ved registrering av fremmøte:\n\n" +
                (response as AxiosError).message,
              "error"
            );
          }
        }
      );
    }
  };

  const handleAngreFremmote = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (setVisMeny) setVisMeny(undefined);
    if (inntauing?.id) {
      putAvbrytFremmote.mutate(
        {
          inntauingId: inntauing.id
        },
        {
          onSuccess: () => {
            addToast("Angret registrering av fremmøte");
          },
          onError: () => {
            addToast("Feil ved angring av registrering av fremmøte", "error");
          }
        }
      );
    }
  };

  const handleAngreAnkomst = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (setVisMeny) setVisMeny(undefined);
    if (inntauing?.id) {
      putAngreAnkomst.mutate(
        {
          inntauingId: inntauing.id
        },
        {
          onSuccess: () => {
            addToast("Angret registrering av ankomst");
          },
          onError: () => {
            addToast("Feil ved angring av registrering av ankomst", "error");
          }
        }
      );
    }
  };

  const handleAngreFlyttet = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (setVisMeny) setVisMeny(undefined);
    if (inntauing?.id) {
      putAngreFlyttet.mutate(
        {
          inntauingId: inntauing.id
        },
        {
          onSuccess: () => {
            addToast("Angret registrering av flytting på gategrunn");
            navigate(ROUTES.Aktive_inntauinger);
          },
          onError: () => {
            addToast(
              "Feil ved angring av registrering av flytting på gategrunn",
              "error"
            );
          }
        }
      );
    }
  };

  const handleGjenopprettBetalingskrav = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (setVisMeny) setVisMeny(undefined);
    if (inntauing?.id) {
      putGjenopprettBetalingskrav.mutate(
        {
          betalingskravId: inntauing.betalingskrav!.id
        },
        {
          onSuccess: () => {
            addToast("Betalingskrav ble gjenopprettet!");
            navigate(ROUTES.Aktive_inntauinger);
          },
          onError: () => {
            addToast("Feil ved gjenopprettelse av betalingskrav!", "error");
          }
        }
      );
    }
  };

  return (
    <>
      <button
        title="Handlingsknapp"
        data-testid={`handlingsknapp-${inntauing?.kjoretoy.registreringsnummer}`}
        className={`btn btn--flat btn--icon-only focus:outline-2 ${
          (isInnsyn() || isUtvikler()) && "hidden"
        }`}
        onClick={handleVisMeny}
        disabled={harIngenMuligeHandlinger(
          inntauingsstatus,
          !!inntauing?.betalingskrav?.generert
        )}
      >
        <Handling className="fill-current" />
      </button>
      {visMeny && visMeny === inntauing?.id && (
        <div ref={ref}>
          <ul
            data-testid={`handlingsmeny-${inntauing?.kjoretoy.registreringsnummer}`}
            style={{
              top: anchorPoint.y,
              left: anchorPoint.x - breddeMeny,
              width: `${breddeMeny}px`
            }}
            className="absolute flex flex-col z-50 border text-left shadow-2xl"
          >
            {erUnderTauing(inntauingsstatus) && (
              <>
                <li>
                  <button
                    title={HANDLINGER.Registrer_ankommet}
                    data-testid={`handlingsmeny-registrer-ankommet-${inntauing?.kjoretoy.registreringsnummer}`}
                    className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                    onClick={handleRegistrerAnkommet}
                  >
                    {HANDLINGER.Registrer_ankommet}
                  </button>
                </li>
                {inntauing.inntauingsgrunnlag.hjemmel !==
                  HjemmelType.ParkeringsforskriftenFjerdeLedd && (
                  <li>
                    <button
                      title={HANDLINGER.Registrer_flyttet_på_gategrunn}
                      data-testid={`handlingsmeny-registrer-flyttet-${inntauing?.kjoretoy.registreringsnummer}`}
                      className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                      onClick={handleFlyttetPaaGategrunn}
                    >
                      {HANDLINGER.Registrer_flyttet_på_gategrunn}
                    </button>
                  </li>
                )}
                <li>
                  <button
                    title={HANDLINGER.Registrer_fremmøte}
                    data-testid={`handlingsmeny-registrer-fremmøte-${inntauing?.kjoretoy.registreringsnummer}`}
                    className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                    onClick={handleRegistrerFremmote}
                  >
                    {HANDLINGER.Registrer_fremmøte}
                  </button>
                </li>
              </>
            )}
            {erPaaTomta(inntauingsstatus ?? "") && (
              <>
                <li>
                  <button
                    title={HANDLINGER.Utlever_inntauing}
                    data-testid={`handlingsmeny-utlever-inntauing-${inntauing?.kjoretoy.registreringsnummer}`}
                    className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                    onClick={handleUtleverInntauing}
                  >
                    {HANDLINGER.Utlever_inntauing}
                  </button>
                </li>
                {isSaksbehandler() &&
                  harUferdigeTakseringer(inntauing?.takseringer) === false && (
                    <li>
                      <button
                        title={HANDLINGER.Opprett_taksering}
                        data-testid={`handlingsmeny-opprett-taksering-${inntauing?.kjoretoy.registreringsnummer}`}
                        className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                        onClick={handleOpprettTaksering}
                      >
                        {HANDLINGER.Opprett_taksering}
                      </button>
                    </li>
                  )}
              </>
            )}
            {kanSettesTilVrakEllerAuksjon(inntauingsstatus ?? "") &&
              isSaksbehandler() && (
                <>
                  <li>
                    <button
                      title={HANDLINGER.Opprett_vrak}
                      data-testid={`handlingsmeny-opprett-vrak-${inntauing?.kjoretoy.registreringsnummer}`}
                      className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                      onClick={handleOpprettVrak}
                    >
                      {HANDLINGER.Opprett_vrak}
                    </button>
                  </li>
                  <li>
                    <button
                      title={HANDLINGER.Opprett_auksjon}
                      data-testid={`handlingsmeny-opprett-auksjon-${inntauing?.kjoretoy.registreringsnummer}`}
                      className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                      onClick={handleOpprettAuksjon}
                    >
                      {HANDLINGER.Opprett_auksjon}
                    </button>
                  </li>
                </>
              )}
            {erHentetPaTomt(inntauingsstatus ?? "") && (
              <li>
                <button
                  title={HANDLINGER.Avbryt_henting}
                  data-testid={`handlingsmeny-avbryt-henting-${inntauing?.kjoretoy.registreringsnummer}`}
                  className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                  onClick={handleAvbrytHenting}
                >
                  {HANDLINGER.Avbryt_henting}
                </button>
              </li>
            )}
            {erAktiv(inntauingsstatus ?? "") && !inntauing.kanFaktureres && (
              <>
                {isSaksbehandler() && (
                  <li>
                    <button
                      title={HANDLINGER.Tillat_fakturering}
                      data-testid={`handlingsmeny-tillat-fakturering-${inntauing?.kjoretoy.registreringsnummer}`}
                      className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                      onClick={handleTillatFakturering}
                    >
                      {HANDLINGER.Tillat_fakturering}
                    </button>
                  </li>
                )}
              </>
            )}
            {erAktiv(inntauingsstatus ?? "") &&
              !inntauing.kanUtleveresUtenBetaling && (
                <>
                  {isSaksbehandler() && (
                    <li>
                      <button
                        title={HANDLINGER.Tillat_utlevering_uten_betaling}
                        data-testid={`handlingsmeny-tillat-utlevering-uten-betaling-${inntauing?.kjoretoy.registreringsnummer}`}
                        className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                        onClick={handleTillatUtleveringUtenBetaling}
                      >
                        {HANDLINGER.Tillat_utlevering_uten_betaling}
                      </button>
                    </li>
                  )}
                </>
              )}
            {erAktiv(inntauingsstatus ?? "") &&
              !inntauing.kanUtleveresMedRedusertDognleie && (
                <>
                  {isSaksbehandler() && (
                    <li>
                      <button
                        title={
                          HANDLINGER.Tillat_utlevering_med_redusert_dognleie
                        }
                        data-testid={`handlingsmeny-tillat-utlevering-med-redusert-dognleie-${inntauing?.kjoretoy.registreringsnummer}`}
                        className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                        onClick={handleTillatUtleveringMedRedusertDognleie}
                      >
                        {HANDLINGER.Tillat_utlevering_med_redusert_dognleie}
                      </button>
                    </li>
                  )}
                </>
              )}
            {erAktiv(inntauingsstatus ?? "") && isSaksbehandler() && (
              <>
                {inntauing.flagg &&
                  inntauing.flagg.filter(f => !f.fjernet).length > 0 && (
                    <li>
                      <button
                        title={HANDLINGER.Fjern_flagg}
                        data-testid={`handlingsmeny-fjern-flagg-${inntauing?.kjoretoy.registreringsnummer}`}
                        className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                        onClick={handleFjernFlagg}
                      >
                        {HANDLINGER.Fjern_flagg}
                      </button>
                    </li>
                  )}
              </>
            )}
            {erAktiv(inntauingsstatus ?? "") && isSaksbehandler() && (
              <li>
                <button
                  title={HANDLINGER.Opprett_flagg}
                  data-testid={`handlingsmeny-opprett-flagg-${inntauing?.kjoretoy.registreringsnummer}`}
                  className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                  onClick={handleOpprettFlagg}
                >
                  {HANDLINGER.Opprett_flagg}
                </button>
              </li>
            )}
            {(erUnderTauing(inntauingsstatus ?? "") ||
              erStatusAnkommetTomt(inntauingsstatus ?? "")) &&
              isSaksbehandler() && (
                <li>
                  <button
                    title={HANDLINGER.Deaktiver_inntauing}
                    data-testid={`handlingsmeny-deaktiver-inntauing-${inntauing?.kjoretoy.registreringsnummer}`}
                    className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                    onClick={handleDeaktiverInntauing}
                  >
                    {HANDLINGER.Deaktiver_inntauing}
                  </button>
                </li>
              )}
            {erPaaTomta(inntauingsstatus ?? "") &&
              inntauing.inntauingsgrunnlag.hjemmel !== HjemmelType.Ukjent && (
                <>
                  {isSaksbehandler() && (
                    <li>
                      <button
                        title={HANDLINGER.Send_brev}
                        data-testid={`handlingsmeny-send-brev-${inntauing?.kjoretoy.registreringsnummer}`}
                        className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                        onClick={handleBrevutsending}
                      >
                        {HANDLINGER.Send_brev}
                      </button>
                    </li>
                  )}
                </>
              )}
            {erStatusFremmote(inntauingsstatus ?? "") && (
              <li>
                <button
                  title={HANDLINGER.Angre_fremmote}
                  data-testid={`handlingsmeny-angre-fremmote-${inntauing?.kjoretoy.registreringsnummer}`}
                  className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                  onClick={handleAngreFremmote}
                >
                  {HANDLINGER.Angre_fremmote}
                </button>
              </li>
            )}
            {erStatusAnkommetTomt(inntauingsstatus ?? "") && (
              <li>
                <button
                  title={HANDLINGER.Angre_ankomst}
                  data-testid={`handlingsmeny-angre-ankomst-${inntauing?.kjoretoy.registreringsnummer}`}
                  className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                  onClick={handleAngreAnkomst}
                >
                  {HANDLINGER.Angre_ankomst}
                </button>
              </li>
            )}
            {erStatusFlyttetPaaGategrunn(inntauingsstatus ?? "") &&
              !inntauing.betalingskrav?.generert && (
                <li>
                  <button
                    title={HANDLINGER.Angre_flyttet}
                    data-testid={`handlingsmeny-angre-flyttet-${inntauing?.kjoretoy.registreringsnummer}`}
                    className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                    onClick={handleAngreFlyttet}
                  >
                    {HANDLINGER.Angre_flyttet}
                  </button>
                </li>
              )}
            {erStatusFlyttetPaaGategrunn(inntauingsstatus ?? "") &&
              inntauing.betalingskrav &&
              (inntauing.betalingskrav.status <=
                BetalingskravStatusType.Opprettet ||
                inntauing.betalingskrav.status ===
                  BetalingskravStatusType.Feilet) &&
              isSaksbehandler() && (
                <li>
                  <button
                    title={HANDLINGER.Kanseller_betalingskrav}
                    data-testid={`handlingsmeny-kanseller-betalingskrav-${inntauing?.kjoretoy.registreringsnummer}`}
                    className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                    onClick={handleKansellerBetalingskrav}
                  >
                    {HANDLINGER.Kanseller_betalingskrav}
                  </button>
                </li>
              )}
            {erStatusFlyttetPaaGategrunn(inntauingsstatus ?? "") &&
              inntauing.betalingskrav &&
              inntauing.betalingskrav.status ===
                BetalingskravStatusType.Feilet &&
              isSaksbehandler() && (
                <li>
                  <button
                    title={HANDLINGER.Gjenopprett_betalingskrav}
                    data-testid={`handlingsmeny-gjenopprett-betalingskrav-${inntauing?.kjoretoy.registreringsnummer}`}
                    className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                    onClick={handleGjenopprettBetalingskrav}
                  >
                    {HANDLINGER.Gjenopprett_betalingskrav}
                  </button>
                </li>
              )}
            {erStatusSendtTilAuksjon(inntauingsstatus ?? "") && (
              <>
                {isSaksbehandler() && (
                  <li>
                    <button
                      title={HANDLINGER.Angre_sendt_til_auksjon}
                      data-testid={`handlingsmeny-angre-sendt-til-auksjon-${inntauing?.kjoretoy.registreringsnummer}`}
                      className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                      onClick={handleAngreSendtTilAuksjon}
                    >
                      {HANDLINGER.Angre_sendt_til_auksjon}
                    </button>
                  </li>
                )}
              </>
            )}
            {erStatusSendtTilVrak(inntauingsstatus ?? "") && (
              <>
                {isSaksbehandler() && (
                  <li>
                    <button
                      title={HANDLINGER.Angre_sendt_til_vrak}
                      data-testid={`handlingsmeny-angre-sendt-til-vrak-${inntauing?.kjoretoy.registreringsnummer}`}
                      className={`bg-white px-3 py-1 w-full text-left w-[${breddeMeny.toString()}px]`}
                      onClick={handleAngreSendtTilVrak}
                    >
                      {HANDLINGER.Angre_sendt_til_vrak}
                    </button>
                  </li>
                )}
              </>
            )}
          </ul>
        </div>
      )}
    </>
  );
};
