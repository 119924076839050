import React, { useCallback, useContext, useMemo, useState } from "react";
import Toast from "./Toast";

type UseToastProps = {
  addToast: (content: string, state?: ToastState) => void;
  removeToast: (id: number) => void;
  toasts: ToastDefinition[];
};

type ToastState = "success" | "warning" | "error";

export interface ToastDefinition {
  id: number;
  content: string;
  state?: ToastState;
}

const ToastContext = React.createContext({} as UseToastProps);

const ToastProvider = ({
  children
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [toasts, setToasts] = useState<ToastDefinition[]>([]);

  const addToast = useCallback(
    (content: string, state: ToastState = "success"): void => {
      const id = toasts.length + 1;
      setToasts([...toasts, { id: id, content, state }]);
    },
    [toasts]
  );

  const removeToast = useCallback(
    (id: number) => {
      setToasts(toasts => toasts.filter(t => t.id !== id));
    },
    [setToasts]
  );

  const contextValues = useMemo(() => {
    return { addToast, removeToast, toasts };
  }, [addToast, removeToast, toasts]);

  return (
    <ToastContext.Provider value={contextValues}>
      <>
        {children}
        <div className="fixed top-16 z-[125] h-0 w-full">
          {toasts.map(toast => (
            <Toast toast={toast} key={toast.id} />
          ))}
        </div>
      </>
    </ToastContext.Provider>
  );
};

export const useToast = (): UseToastProps => useContext(ToastContext);

export default ToastProvider;
