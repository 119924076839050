import { ReactComponent as NisseOsloLogo } from "../../assets/icons/GodJul_logo.svg";
import { ReactComponent as HalloweenOsloLogo } from "../../assets/icons/Halloween_logo.svg";
import { ReactComponent as PaskeOsloLogo } from "../../assets/icons/GodPaske_logo.svg";
import { ReactComponent as OsloLogo } from "../../assets/icons/oslo-logo.svg";
import { useNavigate } from "react-router-dom";
import { GeneralSearch } from "../common/generalSearch/GeneralSearch";
import { useState } from "react";
import { GeneralSearchResult } from "./GeneralSearchResult";
import { useAuth } from "react-oidc-context";
import { AuthAs } from "./AuthAs";
import { MenuButton } from "./MenuButton";
import {
  isEkspeditor,
  isInnsyn,
  isSaksbehandler,
  isUtvikler
} from "../../utils/userRolesUtils";
import { getHostFromEnv, getUser } from "../../api/client";
import { addDays, subDays } from "date-fns";

const getEaster = (year: number) => {
  const f = Math.floor,
    // Golden Number - 1
    G = year % 19,
    C = f(year / 100),
    // related to Epact
    H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
    // number of days from 21 March to the Paschal full moon
    I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
    // weekday for the Paschal full moon
    J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
    // number of days from 21 March to the Sunday on or before the Paschal full moon
    L = I - J,
    month = 3 + f((L + 40) / 44),
    day = L + 28 - 31 * f(month / 4);

  return new Date(year, month - 1, day);
};

const Logo = () => {
  const iDag = new Date();
  const halloween = new Date(iDag.getFullYear(), 9, 31);
  const paskesondag = getEaster(iDag.getFullYear());
  const className = "fill-current h-14 tabletBig:h-8";

  if (iDag.getMonth() === 11) return <NisseOsloLogo className={className} />;

  if (
    iDag.getTime() > subDays(halloween, 5).getTime() &&
    iDag.getTime() < addDays(halloween, 5).getTime()
  )
    return <HalloweenOsloLogo className={className} />;

  if (
    iDag.getTime() > subDays(paskesondag, 9).getTime() &&
    iDag.getTime() < addDays(paskesondag, 2).getTime()
  )
    return <PaskeOsloLogo className={className} />;

  return <OsloLogo className={className} />;
};

const getHeaderBackgroundColor = () => {
  const backendUrl = getHostFromEnv("URL_BACKEND");
  const isLocalhost = window.origin.includes("localhost");
  if (backendUrl.includes("dev"))
    return `bg-red-300${
      isLocalhost ? " bg-gradient-to-l from-yellow-500" : ""
    }`;
  if (backendUrl.includes("test"))
    return `bg-blue-300${
      isLocalhost ? " bg-gradient-to-l from-yellow-500" : ""
    }`;
  return `bg-white${isLocalhost ? " bg-gradient-to-l from-yellow-500" : ""}`;
};

export const Header = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [showResult, setShowResult] = useState<boolean>(false);

  const handleClose = () => {
    setShowResult(false);
    setSearch("");
  };

  return (
    <header
      className={`
      fixed w-full top-0 z-50
      flex flex-row 
      items-center flex-nowrap justify-between 
      app-bar
      tabletBig:px-2 px-5
      ${getHeaderBackgroundColor()}
  `}
    >
      <div className="flex flex-row bg-transparent">
        <button
          className="flex flex-row bg-transparent py-2"
          onClick={() => navigate("/", { replace: true })}
        >
          <Logo />
          <span className="self-center pt-[0.313rem] laptop:hidden">|</span>
          <span className="self-center pt-[0.5rem] pl-3 laptop:hidden">
            Inntauing
          </span>
        </button>
        {auth.isAuthenticated &&
          (isSaksbehandler() ||
            isEkspeditor() ||
            isInnsyn() ||
            isUtvikler()) && (
            <div className="flex flex-row items-center ml-4 tabletBig:hidden">
              <GeneralSearch
                search={search}
                setSearch={setSearch}
                resultViewOpen={showResult}
                handleClose={handleClose}
                handleSearch={() => setShowResult(true)}
              />
              {showResult && (
                <div className="bg-rusticyellow absolute top-[4.5rem] tabletBig:top-[3rem] left-0 h-screen w-screen overflow-y-scroll pl-16">
                  <GeneralSearchResult
                    search={search}
                    handleClose={handleClose}
                  />
                </div>
              )}
            </div>
          )}
      </div>
      {auth.isAuthenticated && !!getUser() && (
        <div className="flex flex-row">
          <AuthAs />
          <MenuButton />
        </div>
      )}
    </header>
  );
};
