import {
  BetalingskravStatusMapper,
  BetalingskravStatusType
} from "../../../api/interfaces/Betalingskrav";

interface BetalingskravStatusMedFargerProps {
  status?: BetalingskravStatusType;
}

export const BetalingskravStatusMedFarger = (
  props: BetalingskravStatusMedFargerProps
) => {
  const { status = BetalingskravStatusType.Ukjent } = props;

  const statusTekst = BetalingskravStatusMapper[status].navn;

  return (
    <div
      title={statusTekst}
      style={{ backgroundColor: BetalingskravStatusMapper[status].farge }}
      className="px-3 py-1 w-44 rounded-2xl"
    >
      {statusTekst}
    </div>
  );
};
