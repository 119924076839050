import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
  setDefaultLocale
} from "react-datepicker";
import nb from "date-fns/locale/nb";
import { useRef } from "react";

registerLocale("nb", nb);
setDefaultLocale("nb");

const DatePicker = (props: ReactDatePickerProps) => {
  const ref = useRef<ReactDatePicker<never, undefined> | null>(null);

  const handleTabForbiDatovelger = (e: React.KeyboardEvent) => {
    if (e.key === "Tab") ref.current?.setOpen(false);
  };

  return (
    <ReactDatePicker
      ref={ref}
      onKeyDown={handleTabForbiDatovelger}
      {...props}
    />
  );
};

export default DatePicker;
