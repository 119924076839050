import { ReactComponent as OsloLogo } from "../../assets/icons/oslo-logo.svg";
import { ReactComponent as ArrowLeftThick } from "../../assets/icons/arrow-left.svg";
import { AttestskjemaData } from "./Attestskjema";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";

interface AttestskjemaUtskriftProps {
  inntauing: InntauingViewModel;
  hjemmel: string;
  skjemadata: AttestskjemaData;
}

export const AttestskjemaUtskrift = (props: AttestskjemaUtskriftProps) => {
  const { inntauing, hjemmel, skjemadata } = props;

  return (
    <div className="text-gray-800 w-screen h-screen grid grid-cols-3 grid-rows-12 gap-x-32">
      <div className="col-span-2 row-span-2">
        <div className="text-darkblue flex flex-col w-full h-full text-2xl">
          <span className="w-full">Oslo&nbsp;kommune</span>
          <span>Bymiljøetaten</span>
        </div>
      </div>
      <div className="col-span-1 row-span-2">
        <div className="block h-full">
          <OsloLogo className="fill-darkblue h-14 mt-auto" />
        </div>
      </div>
      <div className="col-span-1 row-span-2">
        <div className="w-full flex flex-col">
          <span>Vår&nbsp;ref</span>
          <span>{inntauing.arkivReferansenummer ?? ""}</span>
        </div>
      </div>
      <div className="col-span-1 row-span-2">
        <div className="w-full flex flex-col">
          <span>Saksbehandler</span>
          <span>Morten&nbsp;Dahl</span>
        </div>
      </div>
      <div className="col-span-1 row-span-2">
        <div className="w-full flex flex-col">
          <span>Dato</span>
          <span>{new Date().toLocaleDateString()}</span>
        </div>
      </div>
      <div className="col-span-3 row-span-2">
        <div className="flex flex-col w-full whitespace-nowrap">
          <h1 className="text-2xl font-semibold mb-5">Auksjonsattest</h1>
          <p className="mb-5">{`Den ${skjemadata.salgsdato?.toLocaleDateString()} ble kjøretøyet med kjennemerke ${
            inntauing.kjoretoy.registreringsnummer ?? "MANGLER REGNR"
          }, ${inntauing.kjoretoy.merke ?? "MANGLER MERKE"} solgt.`}</p>
          <p className="mb-5">{`Kjøretøyet ble solgt med hjemmel i ${hjemmel}.`}</p>
          <p className="mb-5">
            Auksjonsattesten benyttes som registreringsdokument.
          </p>
        </div>
      </div>
      <div className="col-span-1 flex flex-col whitespace-nowrap mt-[-3rem]">
        <p>
          {skjemadata.erPerson
            ? "Kjøpers personnummer:"
            : "Kjøpers org.nummer:"}
        </p>
        <p>Kjøpers navn:</p>
        <p>Kjøpers adresse:</p>
      </div>
      <div className="col-span-2 flex flex-col whitespace-nowrap mt-[-3rem]">
        <p>
          {skjemadata.idNummer ??
            (skjemadata.erPerson ? "MANGLER PERSONNR" : "MANGLER ORGNR")}
        </p>
        <p>{skjemadata.navn ?? "MANGLER NAVN"}</p>
        <p>{`${skjemadata.gateadresse ?? "MANGLER GATEADRESSE"}`}</p>
        <p>{`${skjemadata.postnummer ?? "MANGLER POSTNUMMER"} ${
          skjemadata.poststed ?? "MANGLER POSTSTED"
        }`}</p>
      </div>
      <div className="col-span-3 row-span-2">
        <div className="flex flex-col w-full whitespace-nowrap">
          <div className="mb-32">
            <p>{`Oslo, den ${new Date().toLocaleDateString()}`}</p>
          </div>
          <p>Morten Dahl</p>
          <p>Førstekonsulent</p>
        </div>
      </div>
      <div className="col-span-1 row-span-2">
        <div className="flex flex-row w-full text-darkblue text-xs mt-12 whitespace-nowrap">
          <div className="flex mr-8">
            <div className="block">
              <ArrowLeftThick className="fill-cyan-500 rotate-180" />
            </div>
          </div>
          <div className="flex flex-col w-full font-semibold">
            <span>Oslo kommune</span>
            <span>Bymiljøetaten</span>
          </div>
        </div>
      </div>
      <div className="col-span-1 row-span-2">
        <div className="flex flex-col w-full text-darkblue text-xs ml-[-3rem] mt-12 whitespace-nowrap">
          <span>Besøksadresse:</span>
          <span>Karvesvingen 3, 0579 Oslo</span>
          <span>Postadresse:</span>
          <span>Postboks&nbsp;636,&nbsp;Løren,&nbsp;0507&nbsp;Oslo</span>
        </div>
      </div>
      <div className="col-span-1 row-span-2">
        <div className="flex flex-col w-full text-darkblue text-xs ml-[-4rem] mt-12 whitespace-nowrap">
          <span>postmottak@bym.oslo.kommune.no</span>
          <span>Org. nr.: 996 922 766</span>
          <span>www.oslo.kommune.no</span>
        </div>
      </div>
    </div>
  );
};
