import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from "react-query";

import {
  deleteTilAuksjon,
  getArkiverteAuksjoner,
  getSendtTilAuksjon,
  getTilAuksjon,
  putAngreSendtTilAuksjon,
  putAuksjonert,
  putKlargjorAuksjon,
  putKunngjorAuksjon,
  putSendTilAuksjon
} from "../api/apiServices/AuksjonService";

import { CACHE_KEYS, REFRESH_INTERVAL } from "../utils/globalConstants";

export const useOpprettAuksjon = (inntauingsId: string) => {
  const queryClient = useQueryClient();

  return useMutation(() => putKlargjorAuksjon(inntauingsId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([CACHE_KEYS.Til_auksjon]);
      return await queryClient.invalidateQueries([
        CACHE_KEYS.Aktive_inntauinger
      ]);
    }
  });
};

export const useTilAuksjon = () =>
  useQuery([CACHE_KEYS.Til_auksjon], () => getTilAuksjon().then(r => r.data), {
    refetchInterval: REFRESH_INTERVAL
  });

export const useSendtTilAuksjon = () =>
  useQuery(
    [CACHE_KEYS.Sendt_til_auksjon],
    () => getSendtTilAuksjon().then(r => r.data),
    {
      refetchInterval: REFRESH_INTERVAL
    }
  );

export const useSendTilAuksjon = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsIder: string[] }) =>
      putSendTilAuksjon(variables.inntauingsIder),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Aktive_inntauinger]);
        await queryClient.invalidateQueries([CACHE_KEYS.Avsluttet_inntauinger]);
        await queryClient.invalidateQueries([CACHE_KEYS.Sendt_til_auksjon]);
        return await queryClient.invalidateQueries([CACHE_KEYS.Til_auksjon]);
      }
    }
  );
};

export const useAuksjonert = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsId: string; dokumenter: FormData }) =>
      putAuksjonert(variables.inntauingsId, variables.dokumenter),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Aktive_inntauinger]);
        await queryClient.invalidateQueries([CACHE_KEYS.Avsluttet_inntauinger]);
        await queryClient.invalidateQueries([CACHE_KEYS.Arkiverte_auksjoner]);
        return await queryClient.invalidateQueries([
          CACHE_KEYS.Sendt_til_auksjon
        ]);
      }
    }
  );
};

export const useAngreOpprettAuksjon = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsIder: string[] }) =>
      deleteTilAuksjon(variables.inntauingsIder),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Aktive_inntauinger]);
        return await queryClient.invalidateQueries([CACHE_KEYS.Til_auksjon]);
      }
    }
  );
};

export const useAngreSendtTilAuksjon = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsId: string }) =>
      putAngreSendtTilAuksjon(variables.inntauingsId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([CACHE_KEYS.Aktive_inntauinger]);
        return await queryClient.invalidateQueries([CACHE_KEYS.Til_auksjon]);
      }
    }
  );
};

export const useArkiverteAuksjoner = (fraDato: Date, tilDato: Date) =>
  useInfiniteQuery(
    [CACHE_KEYS.Arkiverte_auksjoner, fraDato, tilDato],
    ({ pageParam = 0 }) =>
      getArkiverteAuksjoner(pageParam, 1000, fraDato, tilDato).then(
        r => r.data
      ),
    {
      getNextPageParam: lastPage => lastPage.nextCursor ?? undefined,
      refetchInterval: REFRESH_INTERVAL
    }
  );

export const useKunngjorAuksjon = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { inntauingsIder: string[] }) =>
      putKunngjorAuksjon(variables.inntauingsIder),
    {
      onSuccess: async () => {
        return await Promise.all([
          queryClient.invalidateQueries([
            CACHE_KEYS.Aktive_inntauinger_beholdingskontroll
          ]),
          queryClient.invalidateQueries([CACHE_KEYS.Til_auksjon])
        ]);
      }
    }
  );
};
