import { useEffect, useRef } from 'react'

type Handler = (event: Event) => void;

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(handler: Handler) => {
    const ref = useRef<T>(null);

    useEffect(
      () => {
        const listener = (event: any) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) return;
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      [ref, handler]
    );

    return ref;
  }