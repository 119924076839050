import DatePicker from "../common/form/DatePicker";
import { RequiredLabel } from "../common/form/RequiredLabel";
import { HeadingView } from "../common/typography/HeadingView";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { startOfDay } from "date-fns";
import { useState } from "react";
import { TilTakstTabell } from "./TilTakstTabell";

export const TilTakst = () => {
  const [takseringsdato, setTakseringsdato] = useState(startOfDay(new Date()));

  return (
    <>
      <HeadingView>Til takst</HeadingView>
      <div className="ods-form-group w-52 mb-5">
        <label
          className="ods-form-label flex flex-wrap"
          htmlFor="takseringsdato"
        >
          Takseringsdato
          <RequiredLabel />
        </label>
        <div className="flex relative items-center justify-end focus-within:fill-hoverblue">
          <DatePicker
            id="takseringsdato"
            dateFormat={["dd.MM.yyyy", "d.MM.yyyy", "ddMMyyyy"]}
            onChange={(dato: Date) => setTakseringsdato(dato)}
            selected={takseringsdato}
            customInput={
              <input
                data-testid="datepicker-takseringsdato"
                className="ods-form-input"
              />
            }
          />
          <CalendarIcon className="pointer-events-none absolute mr-2 h-5 w-5" />
        </div>
        <div
          className={`w-fit ods-form-validation ods-form-validation--error ${
            takseringsdato && "hidden"
          }`}
        >
          Velg dato for taksering
        </div>
      </div>
      <TilTakstTabell takseringsdato={takseringsdato} />
    </>
  );
};
