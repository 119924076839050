import {
  isEkspeditor,
  isSaksbehandler,
  isUtvikler
} from "../../utils/userRolesUtils";
import { Prislister } from "./prislister/Prislister";
import { TabLayout } from "../common/tabs/TabLayout";

const populateTabs = (parentUrl: string, tabId: string) => {
  let tabs = [];
  let content = [];
  let hasAccess = [];

  tabs.push({
    text: "Prislister",
    href: `${parentUrl}/prislister`,
    active: tabId === "prislister"
  });
  content.push(<Prislister />);
  hasAccess.push(isEkspeditor() || isSaksbehandler() || isUtvikler());

  return { tabs, content, hasAccess };
};

export const Innstillinger = () => {
  const { tabs, content, hasAccess } = populateTabs(
    window.location.origin + "/innstillinger",
    window.location.pathname.split("/").pop() ?? ""
  );

  return <TabLayout tabs={tabs} content={content} hasAccess={hasAccess} />;
};
