import { ReactNode, useState } from "react";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-thin.svg";
import { handleTrykk } from "../../../utils/keyboardUtils";

interface CollapsibleProps {
  id: string;
  title: ReactNode;
  children: ReactNode;
}

function Collapsible(props: CollapsibleProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { id, title, children } = props;

  const handleKeyDownCallback = (e: React.KeyboardEvent<HTMLElement>) => {
    if (document.activeElement?.id === `collapsible-${id}`) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  const keyCallbackDict = {
    " ": handleKeyDownCallback,
    Enter: handleKeyDownCallback
  };

  return (
    <div>
      <div
        id={`collapsible-${id}`}
        data-testid={`collapsible-${id}`}
        className="flex justify-between"
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={e => handleTrykk(e, keyCallbackDict)}
        tabIndex={0}
      >
        <div>{title}</div>
        <div>
          <ArrowDown className="w-7" transform={isOpen ? "rotate(180)" : ""} />
        </div>
      </div>
      <div className="border-b-2 border-gray-500 mb-2" />
      {isOpen && children}
    </div>
  );
}

export default Collapsible;
