import {
  StatuskodeMapper,
  StatuskodeType
} from "../../../api/interfaces/Inntauing";

interface StatusMedFargerProps {
  statuskode: StatuskodeType;
}

export const StatusMedFarger = (props: StatusMedFargerProps) => {
  const { statuskode } = props;

  const status = StatuskodeMapper[statuskode];

  return (
    <div
      title={status.navn}
      style={{
        backgroundColor: status.farge
      }}
      className="px-3 py-1 w-44 rounded-2xl"
    >
      {status.navn}
    </div>
  );
};
