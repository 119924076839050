import { useState } from "react";
import "./FodselsnummerInput.scss";

export const checkIfFodselsnummerShouldBeMasked = (
  personHosSVV: boolean,
  personIDB?: boolean
) => {
  if (personHosSVV) return true;
  return personIDB;
};

export const maskFodselsnummer = (pnr: string) => {
  const length = pnr.length;

  if (length > 6) return pnr.substring(0, 6) + " " + "*".repeat(length - 6);

  return pnr;
};

export const OsloFodselsnummerInput = (
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <div className="relative">
      <input
        {...props}
        className={`ods-form-input ${!show && "fodselsnummer-input-masked"} ${
          props.className ?? ""
        }`}
      />
      <button
        className="btn btn--flat btn--small absolute top-[6px] right-1"
        type="button"
        onClick={() => setShow(!show)}
      >
        {show ? "skjul" : "vis"}
      </button>
    </div>
  );
};
