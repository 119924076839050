import { HeadingView } from "../common/typography/HeadingView";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  HjemmelType,
  HjemmelTypeMapper,
  InntauingViewModel
} from "../../api/interfaces/Inntauing";
import { AlertBox } from "../common/form/AlertBox";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import { EierOgKjoretoysinformasjon } from "../eierOgKjoretoysinformasjon/EierOgKjoretoysinformasjon";
import { PocketControl } from "../pocketControl/PocketControl";
import { RequiredLabel } from "../common/form/RequiredLabel";
import { registrerAnkommetSchema } from "./registrerAnkommetSchema";
import { HeadingTopic } from "../common/typography/HeadingTopic";
import { useToast } from "../common/toast/ToastProvider";
import { useOpprettKjoretoyleveranse } from "../../hooks/useKjoretoyleveranse";
import {
  usePostDigitaltVarselbrev,
  usePostFysiskVarselbrev
} from "../../hooks/useBrev";
import { useInntauing } from "../../hooks/useInntauinger";
import { LEVERANSEMETODER } from "../registrerFlyttetPaaGategrunn/registrerFlyttetPaaGategrunnUtils";
import { FeilmeldingerSkjema } from "../common/form/FeilmeldingerSkjema";

const BREVTYPER_SELECT = {
  "Ikke send brev": 0,
  "Digitalt brev": 1,
  "Manuelt brev": 2
};

interface RegistrerAnkommetProps {
  inntauing: InntauingViewModel;
  close: () => void;
}

export const RegistrerAnkommet = (props: RegistrerAnkommetProps) => {
  const { inntauing, close } = props;

  const postLeveranse = useOpprettKjoretoyleveranse();
  const { data, isLoading } = useInntauing(inntauing.id);
  const postDigitaltVarselbrev = usePostDigitaltVarselbrev();
  const postFysiskVarselbrev = usePostFysiskVarselbrev();
  const { addToast } = useToast();

  const initialValues = {
    radnummer: inntauing.kjoretoyleveranse?.plassering,
    ordrenummer: inntauing.kjoretoyleveranse?.ordrenummer,
    journalnummer: inntauing.kjoretoyleveranse?.journalnummer,
    brevtype: BREVTYPER_SELECT["Ikke send brev"],
    kommentar: undefined
  };

  const {
    formState: { errors },
    control,
    trigger,
    register,
    handleSubmit
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(registrerAnkommetSchema),
    reValidateMode: "onChange",
    defaultValues: initialValues,
    shouldFocusError: true,
    shouldUseNativeValidation: true
  });

  const brevtype = useWatch({
    control: control,
    name: "brevtype"
  });

  // TODO: Interface for skjema
  const registrerKjoretoysleveranse = (data: any) => {
    postLeveranse.mutate(
      {
        inntauingsId: inntauing.id,
        kjoretoyleveranse: {
          inntauingsfirma: inntauing.inntauingsgrunnlag.inntauingsfirma ?? "",
          leveringstidspunkt: new Date(),
          type: LEVERANSEMETODER.Inntauet.kode,
          journalnummer: parseInt(data.journalnummer),
          ordrenummer: parseInt(data.ordrenummer),
          flyttetTilGatenavn: inntauing.kjoretoyleveranse?.flyttetTilGatenavn,
          plassering: data.radnummer
        }
      },
      {
        onSuccess: () => {
          if (data.brevtype !== BREVTYPER_SELECT["Ikke send brev"]) {
            if (data.brevtype === 1) {
              postDigitaltVarselbrev.mutate(
                {
                  brevliste: [{ inntauingsId: inntauing.id }]
                },
                {
                  onSuccess: response => {
                    if (response.length === 1) {
                      addToast(
                        "Ankomst er registrert og brev er sendt digitalt"
                      );
                    } else {
                      addToast(
                        "Ankomst er registrert, men sending av brev feilet",
                        "error"
                      );
                    }
                  }
                }
              );
            } else {
              postFysiskVarselbrev.mutate(
                {
                  brevliste: [{ inntauingsId: inntauing.id }]
                },
                {
                  onSuccess: response => {
                    if (response.length === 1) {
                      addToast(
                        "Ankomst er registrert og brev behandles manuelt"
                      );
                    } else {
                      addToast(
                        "Ankomst er registrert, men sending av brev feilet",
                        "error"
                      );
                    }
                  }
                }
              );
            }
          } else {
            addToast("Ankomst er registrert og kjøretøy har fått plassering");
          }
          close();
        }
      }
    );
  };

  return (
    <>
      <HeadingView
        data-testid="tittel-registrer-ankommet"
        className="pl-8 w-fit"
      >
        Registrer ankommet
      </HeadingView>
      <form
        data-testid="skjema-registrer-ankommet"
        className="pl-8 w-fit"
        onSubmit={handleSubmit(registrerKjoretoysleveranse)}
      >
        <FeilmeldingerSkjema className="mb-5 w-fit" errors={errors} />
        {postLeveranse.isError && (
          <div className="mb-5 w-fit">
            <AlertBox
              alertLevel="error"
              heading="Feil skjedde ved registrering"
              paragraph={<div>{(postLeveranse.error as Error).message}</div>}
            />
          </div>
        )}
        <div className="flex flex-wrap mb-5">
          <div className="ods-form-group w-48 mr-5">
            <label
              htmlFor="radnummer"
              className="ods-form-label flex flex-wrap"
            >
              Radnr
              <RequiredLabel />
            </label>
            <div id="radnummerHelp" className="ods-form-help">
              Oppgi plassering av kjøretøyet.
            </div>
            <input
              id="radnummer"
              className="ods-form-input"
              {...register("radnummer")}
              autoFocus
              autoComplete="off"
            />
          </div>
          <div className="ods-form-group w-48 mr-5">
            <label
              htmlFor="ordrenummer"
              className="ods-form-label flex flex-wrap"
            >
              Ordrenr
              <RequiredLabel />
            </label>
            <div id="ordrenummerHelp" className="ods-form-help">
              Oppgi ordrenummer fra RedGo.
            </div>
            <input
              id="ordrenummer"
              className="ods-form-input"
              {...register("ordrenummer")}
              autoComplete="off"
            />
          </div>
          <div className="ods-form-group w-48">
            <label
              htmlFor="journalnummer"
              className="ods-form-label flex flex-wrap"
            >
              Journalnr
              <RequiredLabel notRequired />
            </label>
            <div id="journalnummerHelp" className="ods-form-help">
              Oppgi journalnummer hvis annet kjennemerke mangler.
            </div>
            <input
              id="journalnummer"
              className="ods-form-input"
              {...register("journalnummer")}
              autoComplete="off"
            />
          </div>
        </div>
        <HeadingTopic>Brevutsendelse:</HeadingTopic>
        {data?.kanVarsles === false && !isLoading && (
          <div className="mt-3">
            <AlertBox
              alertLevel="warning"
              heading={<h3>Mangler info for å kunne sende brev</h3>}
            />
          </div>
        )}
        {isLoading && (
          <div className="mt-3">
            <AlertBox
              alertLevel="warning"
              heading={<h3>Sjekker om brevsending er mulig...</h3>}
            />
          </div>
        )}
        <div className="flex flex-wrap mt-3">
          <div className="ods-form-group w-48 mr-5">
            <label htmlFor="brevtype" className="ods-form-label flex flex-wrap">
              Brevtype
              <RequiredLabel />
            </label>
            <div id="brevtypeHelp" className="ods-form-help">
              Oppgi brevtype ved innsending.
            </div>
            <Controller
              name="brevtype"
              control={control}
              render={({ field: { value } }) => (
                <select
                  id="brevtype"
                  disabled={
                    data?.kanVarsles === false
                  }
                  className="ods-select ods-form-input"
                  value={value}
                  {...register("brevtype", {
                    onChange: async () => {
                      if (Object.entries(errors).length) {
                        await trigger("kommentar");
                      }
                    }
                  })}
                >
                  {Object.entries(BREVTYPER_SELECT).map(b => (
                    <option
                      key={b[0]}
                      value={b[1]}
                      defaultChecked={
                        b[1] === BREVTYPER_SELECT["Ikke send brev"]
                      }
                    >
                      {b[0]}
                    </option>
                  ))}
                </select>
              )}
            />
          </div>
          <div className="ods-form-group w-48">
            <label
              htmlFor="kommentar"
              className="ods-form-label flex flex-wrap"
            >
              Kommentar
              {brevtype >= 2 && <RequiredLabel />}
            </label>
            <div id="kommentarHelp" className="ods-form-help">
              Legg til kommentar på handlingen.
            </div>
            <input
              id="kommentar"
              disabled={data?.kanVarsles !== true || brevtype < 2}
              className="ods-form-input"
              {...register("kommentar")}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="flex flex-row flex-wrap">
          <div className="mr-5">
            <button
              className="btn btn--flat btn--icon-left"
              onClick={() => close()}
              type="button"
            >
              <ArrowDown className="fill-current rotate-[90deg]" />
              <span>Tilbake</span>
            </button>
          </div>
          <button type="reset" className="btn btn--secondary mr-5">
            Nullstill
          </button>
          <button
            data-testid="button-registrer-ankomst"
            className="btn btn--primary btn--icon-right"
            disabled={
              Object.entries(errors).some(e => e !== undefined) || isLoading
            }
            type="submit"
          >
            <ArrowDown className="fill-current rotate-[-90deg]" />
            <span>
              {brevtype <= 0
                ? "Bekreft registrering"
                : "Bekreft registrering og send brev"}
            </span>
          </button>
        </div>
      </form>
      <div className="p-4 mt-5 grid tabletBig:grid-cols-1 grid-cols-2 gap-2">
        <div>
          <EierOgKjoretoysinformasjon
            inntauingsId={inntauing.id}
            kjoretoy={inntauing.kjoretoy}
          />
        </div>
        <div>
          <PocketControl inntauing={inntauing} />
        </div>
      </div>
    </>
  );
};
