import { useMemo } from "react";
import { ReactComponent as Inntauing } from "../../assets/icons/inntauing.svg";
import { Kort } from "../common/card/Kort";
import { KeyValueKortInnhold } from "../common/card/KeyValueKortInnhold";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { InntektKortdata, getKortdata } from "./inntektUtils";

interface InntektProps {
  inntauing: InntauingViewModel;
}

export const Inntekt = (props: InntektProps) => {
  const { inntauing } = props;

  const kortdata: InntektKortdata | undefined = useMemo(() => {
    return getKortdata(inntauing);
  }, [inntauing]);

  if (!inntauing || !kortdata) return null;

  return (
    <Kort
      headerText="Inntekt"
      headerIcon={<Inntauing className="fill-darkblue" />}
      innhold={
        <>
          <div className="flex flex-row gap-3">
            {kortdata.opprinneligBelop && (
              <div className="bg-darkblue border-2 border-white text-white mb-4 p-3 w-1/2 min-w-fit">
                <div>{kortdata.opprinneligBelop}</div>
                <div className="text-xs">{kortdata.opprinneligBelopTekst}</div>
              </div>
            )}
            <div className="bg-white border-2 border-darkblue mb-4 p-3 w-1/2 min-w-fit">
              <div>{kortdata.betalt}</div>
              <div className="text-xs">{kortdata.betaltTekst}</div>
            </div>
          </div>
          <div className="pb-4">
            {kortdata?.keyValuePairsHeading && kortdata?.keyValuePairs && (
              <>
                <span className="osg-sans-bold">
                  {kortdata?.keyValuePairsHeading}
                </span>
                <div className="border-t-2 border-b-2 pt-4">
                  <KeyValueKortInnhold
                    keyValuePair={kortdata?.keyValuePairs}
                    keyWidth="w-40"
                    valueContainerClassName="text-right w-20"
                  />
                </div>
              </>
            )}
          </div>
        </>
      }
    />
  );
};
