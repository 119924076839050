import { useVedtakMedGrunnlagForTilbakebetaling } from "../../hooks/useKlage";
import { Notifikasjon } from "../common/notifikasjon/Notifikasjon";

export const TilbakebetalingerNotifikasjon = () => {
  const { data } = useVedtakMedGrunnlagForTilbakebetaling();

  const antall =
    data?.klagevedtakInntauinger?.filter(kv => !kv.erTilbakebetalt)?.length ??
    0;

  return (
    <>
      {antall > 0 && (
        <Notifikasjon
          title={`${antall} tilbakebetaling${
            antall === 1 ? "" : "er"
          } som ikke er registrert`}
          lengde={antall.toString().length}
        >
          {antall}
        </Notifikasjon>
      )}
    </>
  );
};
