import React, { useState } from "react";
import { HeadingView } from "../../common/typography/HeadingView";
import { FakturagodkjennelseRad } from "./FakturagodkjennelseRad";
import { CustomError } from "../../common/typography/CustomError";
import {
  useFeiletEllerUnderBehandling,
  useIkkeGodkjente
} from "../../../hooks/useFaktura";
import { AlertBox } from "../../common/form/AlertBox";
import { LoadingSpinner } from "../../common/loading/LoadingSpinner";
import { HeadingTopic } from "../../common/typography/HeadingTopic";
import { FakturaStatusMapper } from "../../../api/interfaces/Faktura";

export const Fakturagodkjennelse = () => {
  const [valgtRad, setValgtRad] = useState<string>("");

  const {
    data: ikkeGodkjente,
    isLoading: isLoadingIkkeGodkjente,
    isError: isErrorIkkeGodkjente,
    error: errorIkkeGodkjente
  } = useIkkeGodkjente();
  const {
    data: feilet,
    isLoading: isLoadingFeilet,
    isError: isErrorFeilet,
    error: errorFeilet,
    isFetchedAfterMount
  } = useFeiletEllerUnderBehandling();

  return (
    <div className="mb-16">
      <HeadingView>Faktura</HeadingView>
      <HeadingTopic>Fakturagodkjennelse</HeadingTopic>
      {isLoadingIkkeGodkjente && <LoadingSpinner />}
      {isErrorIkkeGodkjente && (
        <CustomError error={(errorIkkeGodkjente as Error).message} />
      )}
      {ikkeGodkjente && ikkeGodkjente.length === 0 && (
        <div className="mt-5">
          <AlertBox
            alertLevel="warning"
            heading="Fant ingen fakturaer å godkjenne"
          />
        </div>
      )}
      {ikkeGodkjente && ikkeGodkjente.length > 0 && (
        <table className="tbl mt-5">
          <thead>
            <tr>
              <th className="sr-only">Knapp for utvidelse av rad</th>
              <th>Betalingsnr</th>
              <th>ID</th>
              <th>Beløp</th>
              <th>Faktureringsmetode</th>
              <th>Mottaker</th>
              <th>Referanse</th>
              <th className="sr-only">Knapp for godkjennelse av faktura</th>
            </tr>
          </thead>
          <tbody>
            {ikkeGodkjente.map(i => (
              <React.Fragment key={i.betaling.betalingsnummer}>
                <FakturagodkjennelseRad
                  fakturagodkjenning={i}
                  valgtRad={valgtRad}
                  velgRad={setValgtRad}
                />
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
      <HeadingTopic className="mt-16 mb-5">Feilede fakturaer</HeadingTopic>
      <p>
        Fakturaer som har feilet ved eller er under overføring til regnskap.
      </p>
      {isLoadingFeilet && !isFetchedAfterMount && (
        <div className="mt-16">
          <LoadingSpinner />
        </div>
      )}
      {isErrorFeilet && <CustomError error={(errorFeilet as Error).message} />}
      {feilet && feilet.length === 0 && (
        <div className="mt-5">
          <AlertBox
            alertLevel="warning"
            heading="Fant ingen fakturaer til behandling hos regnskap"
          />
        </div>
      )}
      {feilet && feilet.length > 0 && (
        <table className="tbl mt-10 max-w-6xl table-fixed">
          <thead>
            <tr>
              <th>Tidspunkt godkjent</th>
              <th>Status</th>
              <th title="Fakturanummer">Fakturanr</th>
              <th title="Betalingsnummer">Betalingsnr</th>
            </tr>
          </thead>
          <tbody>
            {feilet.map(i => (
              <tr key={i.fakturanummer}>
                <td>
                  {i.godkjentDato
                    ? new Date(i.godkjentDato).toLocaleString()
                    : ""}
                </td>
                <td>
                  <div
                    className="px-3 py-1 w-44 rounded-2xl"
                    style={{
                      backgroundColor: FakturaStatusMapper[i.status].farge
                    }}
                  >
                    {FakturaStatusMapper[i.status].navn}
                  </div>
                </td>
                <td>{i.fakturanummer}</td>
                <td>{i.betalingsnummer}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
