import * as yup from "yup";
import { SchemaOf } from "yup";
import { Felter } from "./utleveringUtils";

export const utleveringSchema: SchemaOf<Felter> = yup.object().shape({
  betalingsmetode: yup.string().required("Velg en betalingsmetode"),
  lestHandlingslogg: yup
    .boolean()
    .required("Du må bekrefte at du har lest handlingsloggen")
    .isTrue("Du må bekrefte at du har lest handlingsloggen"),
  navn: yup.string().required("Angi navn på person som henter kjøretøy"),
  fodselsdato: yup
    .date()
    .transform((curr, orig) => (orig === "" ? undefined : curr))
    .required("Angi fødselsdato på person som henter kjøretøy"),
  eierHenter: yup.boolean(),
  originalDognleie: yup.number(),
  nyDognleie: yup
    .number()
    .when("justerDognleie", {
      is: false,
      then: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : Number(value)))
        .notRequired()
    })
    .when("justerDognleie", {
      is: true,
      then: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : Number(value)))
        .when(["originalDognleie"], originalDognleie =>
          yup
            .number()
            .required("Angi ny døgnleie")
            .transform(value => (isNaN(value) ? undefined : Number(value)))
            .max(
              originalDognleie,
              "Rabatt på døgnleie kan ikke overgå selve døgnleien"
            )
        )
    }),
  justerDognleie: yup.boolean(),
  betalIleggelse: yup.boolean(),
  tidspunkt: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? undefined : curr))
    .required("Angi tidspunkt for reell utlevering")
});
