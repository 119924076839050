import { AxiosResponse } from "axios";
import { apiClient } from "../client";
import {
  FakturagodkjennelseViewModel,
  FakturaInputModel,
  FakturaViewModel
} from "../interfaces/Faktura";
import { config } from "./Config";

const client = apiClient();

export const getIkkeGodkjente = () =>
  client.get<FakturagodkjennelseViewModel[]>("faktura/ikkegodkjente", config());

export const getFeiletEllerUnderBehandling = () =>
  client.get<FakturaViewModel[]>(
    "faktura/feiletellerunderbehandling",
    config()
  );

export const putGodkjennFaktura = (
  fakturanummer: number,
  betaling: FakturaInputModel
) =>
  client.put<FakturaInputModel, AxiosResponse>(
    `faktura/${fakturanummer}/godkjennFaktura`,
    betaling,
    config()
  );

export const putRegistrerManueltFakturert = (
  fakturanummer: number,
  betaling: FakturaInputModel
) =>
  client.put<FakturaInputModel, AxiosResponse>(
    `faktura/${fakturanummer}/manueltfakturert`,
    betaling,
    config()
  );
