import { useCallback, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  PrisForInntauingViewModel,
  InntauingViewModel,
  HjemmelTypeMapper
} from "../../api/interfaces/Inntauing";
import { Toggle } from "../common/form/Toggle";
import { BetalingsspesifikasjonLinje } from "./BetalingsspesifikasjonLinje";
import { Felter } from "./utleveringUtils";

interface RegistrerUtleveringIleggelseProps {
  inntauing: InntauingViewModel;
  kostnader: PrisForInntauingViewModel;
}

export const RegistrerUtleveringIleggelse = (
  props: RegistrerUtleveringIleggelseProps
) => {
  const { inntauing, kostnader } = props;

  const isLiteElektriskKjoretoy =
    inntauing?.kjoretoy?.kjoretoystype?.navn === "LiteElektriskKjoretoy";

  const { control, setValue } = useFormContext<Felter>();

  const betalIleggelse = useWatch({
    control,
    name: "betalIleggelse"
  });

  const betalingsmetode = useWatch({
    control,
    name: "betalingsmetode"
  });

  useEffect(() => {
    if (
      betalingsmetode === "Faktura" ||
      betalingsmetode === "Ingen betaling" ||
      isLiteElektriskKjoretoy
    )
      setValue("betalIleggelse", false);
  }, [betalingsmetode, setValue, isLiteElektriskKjoretoy]);

  const handleToggleIleggelse = useCallback(() => {
    setValue("betalIleggelse", !betalIleggelse);
  }, [betalIleggelse, setValue]);

  const ingenBetaling = betalingsmetode === "Ingen betaling";

  return (
    <>
      {!ingenBetaling && (
        <div className="ods-form-group mt-5 mb-2">
          <Toggle
            id="ileggelse"
            leftText="Betal ileggelse"
            rightText="Ikke betal ileggelse"
            value={!betalIleggelse}
            toggleFunction={handleToggleIleggelse}
            disabled={betalingsmetode === "Faktura" || isLiteElektriskKjoretoy}
          />
        </div>
      )}
      <BetalingsspesifikasjonLinje
        navn={
          HjemmelTypeMapper[inntauing.inntauingsgrunnlag.hjemmel].ileggelseType
        }
        belop={
          (kostnader?.ileggelsesgebyr?.pris ?? 0) +
          (kostnader?.ileggelsestilleggsgebyr?.pris ?? 0)
        }
        lineThrough={!betalIleggelse || ingenBetaling}
      />
    </>
  );
};
