import { getHostFromEnv } from "../../../api/client";
import {
  KlagevedtakType,
  KlagevedtakTypeMapper,
  KlagevedtakViewModel
} from "../../../api/interfaces/Klage";
import {
  formaterFodselnummerEllerOrgnummer,
  formaterNullableBelop
} from "../../../utils/formatUtils";
import { TilbakebetalingerCheckbox } from "./TilbakebetalingerCheckbox";

type TilbakebetalingerTabellProps = {
  id: string;
  klagevedtak: KlagevedtakViewModel[];
  queryCashkey: {
    vedtakstyper?: KlagevedtakType[];
    vedtakstidspunktFra?: Date;
    vedtakstidspunktTil?: Date;
  };
};

export const TilbakebetalingerTabell = (
  props: TilbakebetalingerTabellProps
) => {
  const { id, klagevedtak, queryCashkey } = props;
  return (
    <table className="tbl mt-3 mb-10">
      <thead>
        <tr>
          <th>Tidspunkt</th>
          <th>Vedtak</th>
          <th>Begrunnelse</th>
          <th title="WebSak-nummer til system for klagebehandling">
            WebSak-nummer
          </th>
          <th title="Registreringsnummer eller journalnummer">ID</th>
          <th>Klageinnmelder</th>
          <th title="Fødselsnummer eller organisasjonsnummer">Innmelders ID</th>
          <th>Betalt beløp</th>
          <th>Tilbakebetales</th>
          <td />
        </tr>
      </thead>
      <tbody>
        {klagevedtak.map(kv => (
          <tr key={id + "-" + kv.klagevedtakId}>
            <td>{new Date(kv.vedtakstidspunkt).toLocaleString()}</td>
            <td>{KlagevedtakTypeMapper[kv.vedtakstype]}</td>
            <td>{kv.begrunnelse ?? ""}</td>
            <td>
              {kv.klageWebsaksystemId && kv.klageWebsaknummer && (
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`${getHostFromEnv("WEBSAK")}/${kv.klageWebsaksystemId}`}
                >
                  {kv.klageWebsaknummer}
                </a>
              )}
            </td>
            <td>{kv.registreringsnummer ?? kv.journalpostnummer ?? ""}</td>
            <td>{kv.klageinnmelderNavn ?? ""}</td>
            <td>
              {formaterFodselnummerEllerOrgnummer(
                kv.klageinnmelderIdentifikator ?? ""
              )}
            </td>
            <td>{formaterNullableBelop(kv.betaltBelop)}</td>
            <td>{formaterNullableBelop(kv.betaltBelop - kv.nyttBelop)}</td>
            <td>
              <TilbakebetalingerCheckbox
                klagevedtakId={kv.klagevedtakId}
                erTilbakebetalt={kv.erTilbakebetalt}
                tilbakebetalingUtfortAv={kv.tilbakebetalingUtfortAv}
                tilbakebetalingStartet={kv.tilbakebetalingStartet}
                queryCashkey={queryCashkey}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
