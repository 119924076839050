import { BetalingViewModel } from "../../../api/interfaces/Betaling";
import { formaterNullableBelop } from "../../../utils/formatUtils";

interface PersonligeBetalingerTabellProps {
  betalinger: BetalingViewModel[];
  totalsum: number;
}

export const PersonligeBetalingerTabell = (
  props: PersonligeBetalingerTabellProps
) => {
  const { betalinger, totalsum } = props;
  return (
    <table className="tbl max-w-6xl mb-10 mt-5">
      <thead>
        <tr>
          <th>Betalingsnr</th>
          <th>ID</th>
          <th>Dato</th>
          <th>Ekspeditør</th>
          <th>Beløp</th>
        </tr>
      </thead>
      <tbody>
        {betalinger.map(betaling => (
          <tr
            key={betaling.betalingsnummer}
            data-testid={`minebetalinger-${betaling.kjennemerke}`}
          >
            <td>{betaling.betalingsnummer}</td>
            <td>{betaling.kjennemerke}</td>
            <td>{new Date(betaling.betalingstidspunkt).toLocaleString()}</td>
            <td>{betaling.utfortAv}</td>
            <td>{formaterNullableBelop(betaling.totalsum)}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={3} />
          <td>{`Totalsum (${betalinger.length} stk):`}</td>
          <td>{formaterNullableBelop(totalsum)}</td>
        </tr>
      </tfoot>
    </table>
  );
};
