import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import {
  BetalingsgruppeMapper,
  InntauingViewModel
} from "../../api/interfaces/Inntauing";
import { UtleveringChip } from "./UtleveringChip";
import { RequiredLabel } from "../common/form/RequiredLabel";
import DatePicker from "../common/form/DatePicker";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-thin.svg";
import {
  Felter,
  getInitialValues,
  mapBetalingsmetode,
  mapFelterToBetaling,
  mapFelterToHentet,
  nyDognleieSomBelop
} from "./utleveringUtils";
import { utleveringSchema } from "./utleveringSchema";
import { useKostnaderVedHenting } from "../../hooks/useInntauinger";
import { RegistrerUtleveringHenter } from "./RegistrerUtleveringHenter";
import { RegistrerUtleveringDognleie } from "./RegistrerUtleveringDognleie";
import { RegistrerUtleveringIleggelse } from "./RegistrerUtleveringIleggelse";
import { BetalingsspesifikasjonLinje } from "./BetalingsspesifikasjonLinje";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { CustomError } from "../common/typography/CustomError";
import { RegistrerUtleveringTotalsum } from "./RegistrerUtleveringTotalsum";
import { RegistrerUtleveringFeilmeldingSkjema } from "./RegistrerUtleveringFeilmeldingSkjema";
import { ROUTES } from "../../utils/globalConstants";
import { AxiosError } from "axios";
import { useNavigate, useLocation } from "react-router";
import { useToast } from "../common/toast/ToastProvider";
import { useBetaling } from "../../hooks/useBetaling";
import { RegistrerUtleveringBetalingsmetode } from "./RegistrerUtleveringBetalingsmetode";
import { useStatusHentet } from "../../hooks/useHenting";
import { usePrompt } from "../../hooks/usePrompt";
import { useState } from "react";
import { RegistrerUtleveringQrKode } from "./RegistrerUtleveringQrKode";
import { AlertBox } from "../common/form/AlertBox";
import { Betalingstype } from "../../api/interfaces/Betaling";
import { VarselHandlingslogg } from "./VarselHandlingslogg";

interface RegistrerUtleveringProps {
  inntauing: InntauingViewModel;
  refetchInntauing: () => void;
}

export const RegistrerUtlevering = (props: RegistrerUtleveringProps) => {
  const { inntauing, refetchInntauing } = props;
  const [shouldBlock, setShouldBlock] = useState(true);
  const [registrerBetalingDisabled, setRegistrerBetalingDisabled] =
    useState(false);

  usePrompt("Er du sikker på at du vil abryte utleveringen?", shouldBlock);

  // Setter base font size fra 16px til 20px
  document
    .getElementById("registrer-utlevering-skjema")
    ?.style.setProperty("font-size", "20px");

  const navigate = useNavigate();
  const location = useLocation();
  const { addToast } = useToast();

  const methods = useForm<Felter>({
    mode: "onSubmit",
    resolver: yupResolver(utleveringSchema),
    defaultValues: getInitialValues(inntauing),
    shouldUseNativeValidation: true,
    reValidateMode: "onChange"
  });

  const tidspunkt = useWatch({
    control: methods.control,
    name: "tidspunkt"
  });

  const betalingsmetode = useWatch({
    control: methods.control,
    name: "betalingsmetode"
  });

  const nyDognleie = useWatch({
    control: methods.control,
    name: "nyDognleie"
  });

  const handleNavigerTilbake = () => {
    setShouldBlock(false);
    navigate(-1);
  };

  const {
    data: kostnader,
    isLoading: isLoadingKostnader,
    isError: isErrorKostnader,
    error: errorKostnader
  } = useKostnaderVedHenting(inntauing.id, tidspunkt, dognleie =>
    methods.setValue("originalDognleie", dognleie)
  );

  const putStatusHentet = useStatusHentet();
  const putBetaling = useBetaling(inntauing.id);

  const handleClickBekreftBetaling = (felter: Felter) => {
    setShouldBlock(false);
    setRegistrerBetalingDisabled(true);
    if (mapBetalingsmetode(felter.betalingsmetode) === Betalingstype.Frafalt) {
      if (nyDognleieSomBelop(nyDognleie) > 0) {
        putBetaling.mutate(
          mapFelterToBetaling(felter, {
            dognleie: nyDognleie as number
          }),
          {
            onSuccess: response => {
              putStatusHentet.mutate(mapFelterToHentet(inntauing.id, felter), {
                onSuccess: () => {
                  addToast("Kjøretøy hentet med betaling av døgnleie");
                },
                onError: (response: any) =>
                  addToast(
                    "Feil ved henting av kjøretøy:\n\n" +
                      (response as AxiosError).message,
                    "error"
                  )
              });
              navigate(
                `${location.pathname}/kvittering/${response.betalingsnummer}`
              );
            },
            onError: () => {
              addToast("Betaling og henting ble ikke registrert", "error");
              refetchInntauing();
              setRegistrerBetalingDisabled(false);
            }
          }
        );
        return;
      }

      putStatusHentet.mutate(mapFelterToHentet(inntauing.id, felter), {
        onSuccess: () => {
          addToast("Kjøretøy hentet uten betaling");
          navigate(ROUTES.Avsluttede_inntauinger);
        },
        onError: (response: any) => {
          addToast(
            "Feil ved henting av kjøretøy:\n\n" +
              (response as AxiosError).message,
            "error"
          );
        }
      });

      return;
    }

    if (!kostnader) return;

    putBetaling.mutate(
      mapFelterToBetaling(felter, {
        dognleie: kostnader.dognleiekostnad?.pris ?? 0,
        administrasjonsgebyr: kostnader.administrasjonskostnad?.pris,
        inntauingsgebyr: kostnader.inntauingskostnad?.pris,
        ileggelsesgebyr: kostnader.ileggelsesgebyr?.pris,
        ileggelsestilleggsgebyr: kostnader.ileggelsestilleggsgebyr?.pris
      }),
      {
        onSuccess: (response: any) => {
          putStatusHentet.mutate(mapFelterToHentet(inntauing.id, felter), {
            onSuccess: () => {
              addToast("Registrert betaling og henting av kjøretøy");
            },
            onError: () => {
              addToast(
                "Kjøretøy ble registrert betalt, men ikke registrert hentet",
                "error"
              );
            }
          });
          navigate(
            `${location.pathname}/kvittering/${response.betalingsnummer}`
          );
        },
        onError: () => {
          addToast("Betaling og henting ble ikke registrert", "error");
          refetchInntauing();
          setRegistrerBetalingDisabled(false);
        }
      }
    );
  };

  const ingenBetaling =
    mapBetalingsmetode(betalingsmetode) === Betalingstype.Frafalt;

  if (inntauing?.henting) {
    return (
      <div className="mt-5">
        <AlertBox
          alertLevel="warning"
          heading={`Kjøretøy ble hentet ${new Date(
            inntauing.henting?.hentetidspunkt
          ).toLocaleString()} av ${
            inntauing.henting.hentetAv ?? "ukjent"
          } (${new Date(
            inntauing.henting.hentetAvFodselsdato
          ).toLocaleDateString()})`}
        />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        id="registrer-utlevering-skjema"
        className="flex flex-col"
        onSubmit={methods.handleSubmit(handleClickBekreftBetaling)}
      >
        <h2 hidden>Skjema for registrering av utlevering</h2>
        <div className="flex flex-row flex-wrap gap-5 mb-5">
          <UtleveringChip>{`Registrert ankommet: ${new Date(
            inntauing.kjoretoyleveranse?.leveringstidspunkt ?? ""
          ).toLocaleString()}`}</UtleveringChip>
          <UtleveringChip
            className={inntauing.kanFaktureres ? "" : "bg-errorred"}
          >{`Faktura tillatt: ${
            inntauing.kanFaktureres ? "Ja" : "Nei"
          }`}</UtleveringChip>
          <UtleveringChip
            className={
              inntauing.kanUtleveresMedRedusertDognleie ? "" : "bg-errorred"
            }
          >{`Redusert døgnleie tillatt: ${
            inntauing.kanUtleveresMedRedusertDognleie ? "Ja" : "Nei"
          }`}</UtleveringChip>
        </div>
        <VarselHandlingslogg inntauing={inntauing} />
        <div className="flex flex-row flex-wrap gap-5">
          <div className="ods-form-group">
            <label htmlFor="tidspunkt" className="ods-form-label flex flex-row">
              Tidspunkt
              <RequiredLabel />
            </label>
            <div className="flex relative items-center justify-end focus-within:fill-hoverblue w-72">
              <Controller
                name="tidspunkt"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <DatePicker
                    id={field.name}
                    name={field.name}
                    className={`ods-form-input ${
                      fieldState.error &&
                      "border-errorred focus:border-errorred"
                    }`}
                    dateFormat="dd.MM.yyyy, HH:mm:ss"
                    showTimeInput
                    minDate={
                      new Date(
                        inntauing.kjoretoyleveranse?.leveringstidspunkt ?? ""
                      )
                    }
                    maxDate={new Date()}
                    selected={field.value}
                    onChange={field.onChange}
                    autoComplete="off"
                  />
                )}
              />
              <CalendarIcon className="pointer-events-none absolute mr-2 h-5 w-5" />
            </div>
          </div>
          <RegistrerUtleveringBetalingsmetode inntauing={inntauing} />
        </div>
        {isLoadingKostnader && <LoadingSpinner />}
        {isErrorKostnader && (
          <div className="mb-10">
            <CustomError error={(errorKostnader as Error).message} />
          </div>
        )}
        {kostnader && (
          <>
            <RegistrerUtleveringHenter inntauing={inntauing} />
            <div className="flex flex-row flex-wrap">
              <div className="max-w-xl w-full">
                <h3 className="text-4xl leading-8 mb-3">
                  Betalingsspesifikasjon
                </h3>
                <BetalingsspesifikasjonLinje
                  navn={
                    BetalingsgruppeMapper[inntauing.kjoretoy.betalingsgruppe]
                      .navn
                  }
                  belop={kostnader?.inntauingskostnad?.pris}
                  lineThrough={ingenBetaling}
                />
                <BetalingsspesifikasjonLinje
                  navn="Admin.gebyr"
                  title="Administrasjonsgebyr"
                  belop={kostnader?.administrasjonskostnad?.pris}
                  lineThrough={ingenBetaling}
                />
                <RegistrerUtleveringDognleie
                  inntauing={inntauing}
                  kostnader={kostnader}
                />
                <RegistrerUtleveringIleggelse
                  inntauing={inntauing}
                  kostnader={kostnader}
                />
                <div className="flex flex-row flex-wrap max-w-xl">
                  <RegistrerUtleveringQrKode
                    inntauing={inntauing}
                    kostnader={inntauing.utestaaendeKostnader}
                  />
                  <div className="grow">
                    <RegistrerUtleveringTotalsum kostnader={kostnader} />
                    <RegistrerUtleveringFeilmeldingSkjema />
                    <div className="flex flex-row flex-wrap gap-5 mt-5">
                      <button
                        className="btn btn--flat btn--icon-left"
                        type="button"
                        onClick={handleNavigerTilbake}
                      >
                        <ArrowDown className="fill-current inline-flex rotate-[90deg]" />
                        <span>Tilbake</span>
                      </button>
                      <button
                        data-testid="button-bekreft-betaling"
                        type="submit"
                        className="btn btn--primary btn--icon-right"
                        disabled={
                          Object.entries(methods.formState.errors).length > 0 ||
                          registrerBetalingDisabled
                        }
                      >
                        <ArrowDown className="fill-current rotate-[-90deg]" />
                        <span>Registrer betaling</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </form>
    </FormProvider>
  );
};
