import { FieldError, useFormContext } from "react-hook-form";
import { AlertBox } from "../common/form/AlertBox";
import { RegistrerInntauingFelter } from "./registrerInntauingUtils";

export const RegistrerInntauingFeilmelding = () => {
  const {
    formState: { errors }
  } = useFormContext<RegistrerInntauingFelter>();

  const feilISkjema = hentFeilISkjema(errors);

  if (feilISkjema.length === 0) return null;

  return (
    <div className="my-5">
      <AlertBox
        alertLevel="error"
        heading={`${feilISkjema.length} feil i skjemaet`}
        paragraph={
          <>
            {feilISkjema.map((feil: FieldError) => (
              <p key={feil.message}>{feil.message}</p>
            ))}
          </>
        }
      />
    </div>
  );
};

const isFieldError = (error: any): error is FieldError => {
  return error && error.message !== undefined;
};

export const hentFeilISkjema = (errors: any) => {
  let feilISkjema: FieldError[] = [];

  Object.entries(errors.inntauingsinfo ?? {}).forEach(([_, error]) => {
    if (isFieldError(error)) {
      feilISkjema.push(error);
    }
  });

  Object.entries(errors.bestillingsinfo ?? {}).forEach(([_, error]) => {
    if (isFieldError(error)) {
      feilISkjema.push(error);
    }
  });

  Object.entries(errors.registreringsnummer ?? {}).forEach(([_, error]) => {
    if (isFieldError(error)) {
      feilISkjema.push(error);
    }
  });

  Object.entries(errors.kjoretoyinfo ?? {}).forEach(([_, error]) => {
    if (isFieldError(error)) {
      feilISkjema.push(error);
    }
  });

  Object.entries(errors.juridiskEier ?? {}).forEach(([_, error]) => {
    if (isFieldError(error)) {
      feilISkjema.push(error);
    }
  });

  Object.entries(errors.leasingtaker ?? {}).forEach(([_, error]) => {
    if (isFieldError(error)) {
      feilISkjema.push(error);
    }
  });

  return feilISkjema;
};
