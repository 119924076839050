import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBrevPDF } from "../../hooks/useBrev";
import { useInntauing } from "../../hooks/useInntauinger";
import { ROUTES } from "../../utils/globalConstants";
import { AlertBox } from "../common/form/AlertBox";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { HeadingView } from "../common/typography/HeadingView";
import { ManuelleBrevAvhendingsbrev } from "./ManuelleBrevAvhendingsbrev";
import { ManuelleBrevDigitaltVarselbrev } from "./ManuelleBrevDigitaltVarselbrev";
import { ManuelleBrevFysiskVarselbrev } from "./ManuelleBrevFysiskVarselbrev";
import { ManuelleBrevPdf } from "./ManuelleBrevPdf";
import { HjemmelType, HjemmelTypeMapper } from "../../api/interfaces/Inntauing";

export const ManuelleBrev = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();

  const {
    data: inntauing,
    isLoading: isLoadingInntauing,
    isError: isErrorInntauing
  } = useInntauing(id);

  const [brevtype, setBrevtype] = useState<"varselbrev" | "avhendingsbrev">();
  const [brevId, setBrevId] = useState<string>();

  const [skalSendesFysisk, setSkalSendesFysisk] = useState(false);

  useEffect(() => {
    const kanVarsles = inntauing && inntauing.kanVarsles ? true : false;
    setSkalSendesFysisk(kanVarsles ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInntauing]);

  const [generertPdf, setGenerertPdf] = useState<string>();

  const {
    data: pdfData,
    isError: isErrorPdf,
    isLoading,
    isSuccess
  } = useBrevPDF(brevId ?? "", brevtype ?? "varselbrev");

  useEffect(() => {
    setGenerertPdf(pdfData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const isLoadingPdf = () => {
    if (isLoading && brevId) return true;
    if (isLoading && !brevId) return false;
    return false;
  };

  const handleBrevtypeVarselbrev = () => {
    setGenerertPdf(undefined);
    setBrevtype("varselbrev");
  };

  const handleBrevtypeAvhendingsbrev = () => {
    setBrevtype("avhendingsbrev");
    setGenerertPdf(undefined);
    setSkalSendesFysisk(false);
  };

  const handleSkalSendesDigitalt = () => {
    setGenerertPdf(undefined);
    inntauing && inntauing.kanVarsles && setSkalSendesFysisk(false);
  };

  const handleSkalSendesFysisk = () => {
    setGenerertPdf(undefined);
    inntauing && inntauing.kanVarsles && setSkalSendesFysisk(true);
  };

  const handleOppdaterBrevId = (brevId: string) => setBrevId(brevId);

  const handleAvbrytBrevsending = () => navigate(ROUTES.Aktive_inntauinger);

  if (isLoadingInntauing) return <LoadingSpinner />;

  if (isErrorInntauing)
    return (
      <div className="ml-5 mt-10">
        <AlertBox alertLevel="error" heading="Feil ved lasting av brev" />
      </div>
    );

  return (
    <div className="ml-5 grid grid-cols-[43.5rem_1fr] tabletBig:grid-cols-1">
      <div>
        {inntauing && (
          <>
            <HeadingView>Send brev</HeadingView>
            <p className="text-2xl mb-4">Velg brevtype</p>
            <div className="ods-form-group" onChange={handleBrevtypeVarselbrev}>
              <input
                data-testid="varselbrev-button"
                type="radio"
                className="ods-form-check-input"
                id="varselbrev"
                name="brevtype-radio-group"
              />
              <label className="ods-form-label" htmlFor="varselbrev">
                Varselbrev
              </label>
            </div>
            <div
              className="ods-form-group"
              onChange={handleBrevtypeAvhendingsbrev}
            >
              <input
                data-testid="avhendingsbrev-button"
                type="radio"
                disabled={inntauing && inntauing?.brevutsendinger.length === 0}
                className="ods-form-check-input"
                id="avhendingsbrev"
                name="brevtype-radio-group"
              />
              <label className="ods-form-label" htmlFor="avhendingsbrev">
                Avhendingsbrev
              </label>
            </div>
          </>
        )}
        {brevtype === "avhendingsbrev" && (
          <ManuelleBrevAvhendingsbrev
            handleOppdaterBrevId={handleOppdaterBrevId}
            handleAvbrytBrevsending={handleAvbrytBrevsending}
            inntauing={inntauing}
            isLoadingAvhendingsbrev={isLoadingPdf()}
            isErrorAvhendingsbrev={isErrorPdf}
            avhendingsbrevPdf={generertPdf ?? ""}
          />
        )}
        {brevtype === "varselbrev" && (
          <div>
            <p className="text-2xl mb-4">Velg hvordan brevet skal sendes</p>
            <div className="ods-form-group">
              <input
                disabled={!inntauing?.kanVarsles}
                onChange={handleSkalSendesDigitalt}
                data-testid="digitalt-varselbrev-button"
                type="radio"
                className="ods-form-check-input"
                id="digitalt"
                defaultChecked
                name="velg-brevtype-sending-radio-group"
              />
              <label className="ods-form-label" htmlFor="digitalt">
                Digitalt
              </label>
            </div>
            <div className="ods-form-group">
              <input
                checked={skalSendesFysisk}
                onChange={handleSkalSendesFysisk}
                data-testid="fysisk-varselbrev-button"
                type="radio"
                className="ods-form-check-input"
                id="fysisk"
                name="velg-brevtype-sending-radio-group"
              />
              <label className="ods-form-label" htmlFor="fysisk">
                Fysisk
              </label>
            </div>
          </div>
        )}

        {skalSendesFysisk && brevtype === "varselbrev" && (
          <ManuelleBrevFysiskVarselbrev
            handleOppdaterBrevId={handleOppdaterBrevId}
            handleAvbrytBrevsending={handleAvbrytBrevsending}
            inntauing={inntauing}
            pdfLoading={isLoadingPdf()}
            pdfError={isErrorPdf}
          />
        )}

        {!skalSendesFysisk && brevtype === "varselbrev" && (
          <ManuelleBrevDigitaltVarselbrev
            handleOppdaterBrevId={handleOppdaterBrevId}
            handleAvbrytBrevsending={handleAvbrytBrevsending}
            inntauing={inntauing}
            pdfLoading={isLoadingPdf()}
            pdfError={isErrorPdf}
          />
        )}
      </div>
      <div>
        {brevtype && (
          <div className="tabletBig:h-[50rem] h-[80rem] text-center">
            <HeadingView>Forhåndsvisning av brev</HeadingView>
            {brevId && (
              <ManuelleBrevPdf
                data={generertPdf ?? ""}
                pdfIsError={isErrorPdf}
                pdfIsLoading={isLoadingPdf()}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
