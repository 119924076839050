import { useSendtTilAuksjon } from "../../hooks/useAuksjon";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { CustomError } from "../common/typography/CustomError";
import { HeadingView } from "../common/typography/HeadingView";
import { SendtTilAuksjonTabell } from "./SendtTilAuksjonTabell";

export const SendtTilAuksjon = () => {
  const { data, isLoading, isError, error } = useSendtTilAuksjon();

  return (
    <div className="mb-16">
      <HeadingView>Sendt til auksjon</HeadingView>
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      <SendtTilAuksjonTabell auksjonsliste={data} />
    </div>
  );
};
