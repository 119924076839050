exports.LOGO_HEIGHT_SMALL = 45;
exports.LOGO_HEIGHT_LARGE = 50;
exports.BREAKPOINTS = {
  mobile: "554px",
  phablet: "555px",
  tablet: "768px",
  tabletBig: "1024px",
  laptop: "1236px",
  desktop: "1600px",
};
