export const toLocaleString = (date: Date | undefined): string => {
  if (date instanceof Date) {
    return date.toLocaleString("nb-NO", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    });
  }
  return "";
};
