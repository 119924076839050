import { ReactComponent as Inntauing } from "../../assets/icons/inntauing.svg";
import { Kort } from "../common/card/Kort";
import {
  KeyValueKortInnhold,
  KeyValuePair
} from "../common/card/KeyValueKortInnhold";
import { useState } from "react";
import { RedigerInntauingsinformasjon } from "./RedigerInntauingsinformasjon";
import {
  InntauingViewModel,
  StatuskodeType
} from "../../api/interfaces/Inntauing";
import { isInnsyn, isUtvikler } from "../../utils/userRolesUtils";
import { getInntauingsinfo } from "./inntauingsinformasjonUtils";

interface InntauingsinformasjonProps {
  inntauing: InntauingViewModel;
  isEditable: boolean;
}

export const Inntauingsinformasjon = (props: InntauingsinformasjonProps) => {
  const { inntauing, isEditable } = props;
  const [editMode, setEditMode] = useState(false);

  if (!inntauing) return null;
  const inntauingsinfo = getInntauingsinfo(inntauing);
  const isFlytting = inntauing.status === StatuskodeType.FlyttetPaaGategrunn;

  const keyValuePairs: KeyValuePair[] = [
    {
      key: "Arkivsaksnr",
      value: [inntauing.arkivReferansenummer ?? ""]
    },
    {
      key: "Radnr",
      value: [inntauingsinfo.radnummer],
      dataTestid: "inntauingsinformasjon-radnummer"
    },
    {
      key: "Ordrenr",
      value: [inntauingsinfo.ordrenummer],
      dataTestid: "inntauingsinformasjon-ordrenummer"
    },
    {
      key: "Journalnr",
      value: [inntauingsinfo.journalnummer],
      dataTestid: "inntauingsinformasjon-journalnummer"
    },
    {
      key: "Ileggelsesnr",
      value: [inntauingsinfo.ileggelsesnummer],
      dataTestid: "inntauingsinformasjon-ileggelsenr"
    }
  ];

  const flyttetKeyValuePairs: KeyValuePair[] = [
    {
      key: "Arkivsaksnr",
      value: [inntauing.arkivReferansenummer ?? ""]
    },
    {
      key: "Ileggelsesnr",
      value: [inntauingsinfo.ileggelsesnummer],
      dataTestid: "inntauingsinformasjon-ileggelsenr"
    },
    {
      key: "Ordrenr",
      value: [inntauingsinfo.ordrenummer],
      dataTestid: "inntauingsinformasjon-ordrenummer"
    },
    {
      key: "Flyttet til",
      value: inntauingsinfo.flyttetTil
    }
  ];

  return (
    <Kort
      headerText="Inntauingsinfo"
      headerIcon={<Inntauing className="fill-darkblue" />}
      innhold={
        <>
          {!editMode ? (
            <>
              <KeyValueKortInnhold
                keyValuePair={isFlytting ? flyttetKeyValuePairs : keyValuePairs}
                keyWidth="w-40"
              />
              {isEditable && !isInnsyn() && !isUtvikler() && (
                <div className="mt-5 py-2">
                  <button
                    className="btn btn--secondary"
                    disabled={!inntauing.kjoretoyleveranse}
                    data-testid="rediger-inntauingsinformasjon-button"
                    onClick={() => setEditMode(true)}
                  >
                    Rediger
                  </button>
                </div>
              )}
            </>
          ) : (
            <RedigerInntauingsinformasjon
              inntauing={inntauing}
              isFlytting={isFlytting}
              setEditMode={setEditMode}
            />
          )}
        </>
      }
    />
  );
};
