import { BrevViewModel } from "./Inntauing";

export enum BetalingskravStatusType {
  Ukjent = 0,
  Opprettet = 1,
  Generert = 2,
  SendtTilHgk = 3,
  MottattIne = 4,
  Betalt = 5,
  Feilet = 99,
  SkalIkkeBetales = 100
}

export const BetalingskravStatusMapper: Record<
  BetalingskravStatusType,
  { navn: string; farge: string }
> = {
  [BetalingskravStatusType.Ukjent]: {
    navn: "Ukjent",
    farge: "#FFFFFF"
  },
  [BetalingskravStatusType.Opprettet]: {
    navn: "Opprettet",
    farge: "#B3F5FF"
  },
  [BetalingskravStatusType.Generert]: {
    navn: "Generert",
    farge: "#B3F5FF"
  },
  [BetalingskravStatusType.SendtTilHgk]: {
    navn: "Sendt HGK",
    farge: "#6FE9FF"
  },
  [BetalingskravStatusType.MottattIne]: {
    navn: "Mottatt INE",
    farge: "#6FE9FF"
  },
  [BetalingskravStatusType.Betalt]: {
    navn: "Betalt",
    farge: "#C7F6C9"
  },
  [BetalingskravStatusType.Feilet]: {
    navn: "Feilet",
    farge: "#FF8274"
  },
  [BetalingskravStatusType.SkalIkkeBetales]: {
    navn: "Kansellert",
    farge: "#CCCCCC"
  }
};

export interface BetalingskravViewModel {
  id: string;
  inntauingsId: string;
  status: BetalingskravStatusType;
  opprettet: Date;
  generert?: Date;
  sendt?: Date;
  brev?: BrevViewModel;
  administrasjonsgebyr?: number;
  inntauingsgebyr?: number;
}
