import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { CustomError } from "../common/typography/CustomError";

interface ManuelleBrevPdfProps {
  data: string;
  pdfIsLoading: boolean;
  pdfIsError: boolean
}

export const ManuelleBrevPdf = (props: ManuelleBrevPdfProps) => {
  const { data, pdfIsLoading, pdfIsError } = props;

  if (pdfIsLoading) {
    return (
      <div className="relative">
        <LoadingSpinner />
      </div>
    );
  }

  if (pdfIsError) {
    return <CustomError error="Kunne ikke laste pdf" />;
  }

  return (
    <object
      title="Forhåndsvisning av generert brev"
      data={data}
      type="application/pdf"
      width="100%"
      height="100%"
      className="pr-5"
    />
  );
};
