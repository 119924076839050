import { useBetalteileggelser } from "../../../hooks/useBetaling";
import { HeadingView } from "../../common/typography/HeadingView";
import { ReactComponent as Download } from "../../../assets/icons/download.svg";
import { download_table_as_csv } from "../../../utils/csvTableGenerator";
import { AlertBox } from "../../common/form/AlertBox";
import { HeadingTopic } from "../../common/typography/HeadingTopic";
import { CustomError } from "../../common/typography/CustomError";
import { endOfDay, previousMonday, previousSunday, startOfDay } from "date-fns";
import { LoadingSpinner } from "../../common/loading/LoadingSpinner";
import { FraTilDato } from "../../common/form/FraTilDato";
import { formaterNullableBelop } from "../../../utils/formatUtils";
import { useStateParams } from "../../../hooks/useStateParams";

export const BetalteIleggelser = () => {
  const forrigeMandag = previousMonday(previousSunday(startOfDay(new Date())));
  const sistSondag = previousSunday(endOfDay(new Date()));

  const [fraDato, setFraDato] = useStateParams(
    forrigeMandag,
    "fra",
    s => s.toISOString(),
    s => new Date(s)
  );
  const [tilDato, setTilDato] = useStateParams(
    sistSondag,
    "til",
    s => s.toISOString(),
    s => new Date(s)
  );

  const { data, isLoading, isError, error } = useBetalteileggelser(
    fraDato,
    tilDato
  );

  return (
    <>
      <HeadingView>Betalte ileggelser</HeadingView>
      <FraTilDato
        actionButtons={
          <button
            type="button"
            className="btn btn--primary-confirm btn--icon-left mb-5 mt-auto"
            onClick={() =>
              download_table_as_csv(
                "betalteIleggelser",
                `Betalte_ileggelser_i_perioden_${fraDato.toLocaleDateString()}_${tilDato.toLocaleDateString()}`.replaceAll(
                  ".",
                  "-"
                )
              )
            }
          >
            <Download className="fill-current" />
            <span>Last ned</span>
          </button>
        }
        fraDato={fraDato}
        tilDato={tilDato}
        handleChangeFraDato={setFraDato}
        handleChangeTilDato={setTilDato}
      />
      <HeadingTopic>Betalte ileggelser for gitt periode</HeadingTopic>
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      {data && (
        <>
          {data.length === 0 ? (
            <div className="mt-5">
              <AlertBox
                alertLevel="warning"
                heading="Fant ingen betalte ileggelser for angitte periode"
              />
            </div>
          ) : (
            <>
              <table className="tbl mt-3 mb-10" id="betalteIleggelser">
                <thead>
                  <tr>
                    <th>Ileggelsesnr.</th>
                    <th>Inntauet dato</th>
                    <th>Regnr</th>
                    <th>Betjentnr.</th>
                    <th>Betalerens navn</th>
                    <th>Betalingsnr.</th>
                    <th>Betalingsdato</th>
                    <th>Beløp</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map(b => (
                    <tr key={b.betalingsnummer}>
                      <td>{b.ileggelsesnummer}</td>
                      <td>{new Date(b.inntauetDato).toLocaleDateString()}</td>
                      <td>{b.registreringsnummer}</td>
                      <td>{b.betjent}</td>
                      <td>{b.betaltAvNavn}</td>
                      <td>{b.betalingsnummer}</td>
                      <td>{new Date(b.betalingsdato).toLocaleDateString()}</td>
                      <td>{formaterNullableBelop(b.belop)}</td>
                    </tr>
                  ))}
                  <tr className="tbl-row-border">
                    {/* På grunn av excel-formattering. Derfor det ikke brukes colspan-5 */}
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Sum:</td>
                    <td>
                      {formaterNullableBelop(
                        data?.reduce((total, curr) => {
                          return total + curr.belop;
                        }, 0)
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </>
      )}
    </>
  );
};
