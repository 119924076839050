import * as yup from 'yup'

export const inntauingInputSchema = yup
  .object({
    journalnummer: yup.string(),
    inntauetFra: yup.string(),
    statuskode: yup.string(),
    radnummer: yup.string(),
    hjemmel: yup.string(),
    ordrenr: yup.string().transform((o : string) => {
      if(o.length === 0){
        return "0"
      }
    })
  })
