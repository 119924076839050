import { useFormContext } from "react-hook-form";
import { AlertBox } from "../common/form/AlertBox";
import { Felter } from "./utleveringUtils";

export const RegistrerUtleveringFeilmeldingSkjema = () => {
  const {
    formState: { errors }
  } = useFormContext<Felter>();

  return (
    <>
      {Object.entries(errors).length > 0 && (
        <div className="mt-5">
          <AlertBox
            alertLevel="error"
            heading={`${Object.entries(errors).length} feil i skjema`}
            paragraph={
              <>
                {Object.values(errors).map(e => (
                  <div key={e.message}>{e.message}</div>
                ))}
              </>
            }
          />
        </div>
      )}
    </>
  );
};
