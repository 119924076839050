import { apiClient } from "../client";
import { OpprettNotatInputModel } from "../interfaces/Notat";
import { config } from "./Config";

const client = apiClient();

export const postNotat = (
  inntauingsId: string,
  notat: OpprettNotatInputModel
) =>
  client.post<OpprettNotatInputModel, null>(
    `notat/${inntauingsId}/notat`,
    notat,
    config()
  );
