import React, { useEffect, useState, useRef } from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { handleTrykk } from "../../../utils/keyboardUtils";
import "./SearchDropdown.scss";

interface SearchDropdownProps {
  id?: string;
  disabled?: boolean;
  options: string[];
  isloading?: boolean;
  name?: string;
  value?: string;
  emptyvalue?: string;
  classname?: string;
  classnameDropdown?: string;
  onchange?: (value: string) => void;
}

export const SearchDropdown = (props: SearchDropdownProps) => {
  const {
    id,
    isloading,
    disabled,
    options,
    name,
    value,
    emptyvalue,
    classname,
    classnameDropdown,
    onchange
  } = props;

  const [choice, setChoice] = useState<string>(
    !value || value === "" ? emptyvalue ?? "" : value
  );
  const [search, setSearch] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const parentInputRef = useRef<HTMLInputElement>(null);

  const handleClickOutside = () => {
    setOpen(false);
    setSearch("");
  };

  const handleChooseOption = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    setChoice(e.currentTarget.value);
    setOpen(false);
    setSearch("");
    if (onchange)
      onchange(
        e.currentTarget.value === emptyvalue ? "" : e.currentTarget.value
      );
    if (parentInputRef.current) {
      parentInputRef.current.focus();
    }
  };

  const toggleOpen = () => setOpen(!open);

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) =>
    setSearch(e.currentTarget.value);

  const innerRef = useOnClickOutside<HTMLDivElement>(handleClickOutside);

  useEffect(() => {
    setChoice(!value || value === "" ? emptyvalue ?? "" : value);
  }, [emptyvalue, value]);

  const handleKeyDownCallback = () => {
    setSearch("");
    setOpen(false);
    if (parentInputRef.current) {
      parentInputRef.current.focus();
    }
  };

  const keyCallbackDict = {
    Escape: handleKeyDownCallback
  };

  return (
    <>
      <input
        id={id}
        type="button"
        disabled={isloading || disabled}
        className={`search-dropdown-select ${classname ?? ""}`}
        onClick={toggleOpen}
        value={isloading ? "Laster..." : choice}
        name={name}
        ref={parentInputRef}
      />
      {open && (
        <div
          className={`search-dropdown-list ${classnameDropdown}`}
          ref={innerRef}
        >
          <input
            id={`${id}-search`}
            type="search"
            className="search-dropdown-search search-dropdown-list-item"
            placeholder="Søk i listen"
            value={search}
            onInput={handleSearch}
            autoFocus={open}
            onKeyDown={e => handleTrykk(e, keyCallbackDict)}
          />
          {(emptyvalue ? [emptyvalue, ...options] : options)
            .filter(option =>
              option.toLowerCase().startsWith(search.toLowerCase())
            )
            .map(option => (
              <input
                id={`${id}-option-${option}`}
                className="search-dropdown-list-item hover:text-white hover:bg-hoverblue"
                type="button"
                key={option}
                onClick={handleChooseOption}
                value={option}
                onKeyDown={e => handleTrykk(e, keyCallbackDict)}
              />
            ))}
        </div>
      )}
    </>
  );
};
