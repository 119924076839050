import { apiClient } from "../client";
import {
  BetalingskravRegnskapViewModel,
  FakturaRegnskapViewModel,
  KasseRegnskapViewModel,
  RegnskapViewModel
} from "../interfaces/Regnskap";
import { config } from "./Config";

const client = apiClient();

export const getRegnskaprapport = (fra: Date, til: Date) =>
  client.get<RegnskapViewModel>(
    `regnskap?fra=${fra.toISOString()}&til=${til.toISOString()}`,
    config()
  );

export const getKasseRegnskap = (fra: Date, til: Date) =>
  client.get<KasseRegnskapViewModel>(
    `regnskap/kasse?fra=${fra.toISOString()}&til=${til.toISOString()}`,
    config()
  );

export const getFakturaRegnskap = (fra: Date, til: Date) =>
  client.get<FakturaRegnskapViewModel>(
    `regnskap/faktura?fra=${fra.toISOString()}&til=${til.toISOString()}`,
    config()
  );

export const getFlyttingRegnskap = (
  fra: Date,
  til: Date,
  filternokkel: string
) =>
  client.get<BetalingskravRegnskapViewModel>(
    `regnskap/betalingskrav?fra=${fra.toISOString()}&til=${til.toISOString()}&filternokkel=${filternokkel}`,
    config()
  );
