import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { SearchDropdown } from "../common/form/SearchDropdown";
import { SelectArray, SelectArrayObject } from "../common/form/SelectArray";

import { useLokasjoner } from "../../hooks/useGrunndata";

import { ReactComponent as Nullstill } from "../../assets/icons/arrow-circle.svg";
import { ReactComponent as Sok } from "../../assets/icons/sok.svg";

import DatePicker from "../common/form/DatePicker";

import { Filter, FilterVisning, VisFilter } from "../../api/interfaces/Filter";
import { inntauingfilterSchema } from "../filter/inntauingfilterSchema";

import { TEKST_SOK } from "../../utils/globalConstants";
import { deepEqual } from "../../utils/compareUtils";

import "react-datepicker/dist/react-datepicker.css";
import {
  GetFilterAsQueryParams,
  GetQueryParamsAsFilter
} from "../../utils/filterUtils";
import { BetalingskravStatusType } from "../../api/interfaces/Betalingskrav";
import { FlaggType, FlaggtypeMapper } from "../../api/interfaces/Flagg";
import {
  Betalingstype,
  BetalingstypeNavn
} from "../../api/interfaces/Betaling";
import {
  Kjoretoystype,
  KjoretoystypeMapper,
  StatuskodeMapper,
  StatuskodeType
} from "../../api/interfaces/Inntauing";
import {
  BrevtypeFilterNavn,
  BrevtypeFilterType
} from "../../api/interfaces/InntauingQueryParametersViewModel";
import {
  mapEnumToNumberValues,
  mapValueToName
} from "../../utils/objectMapping";

interface InntauingFilterProps {
  visning: FilterVisning;
  visFilter: VisFilter;
  visFilterIDom: boolean;
  setVisFilterIDom: (visFilterIDom: boolean) => void;
  setFilter: (filter: Filter) => void;
}

export const InntauingFilter = React.memo(
  (props: InntauingFilterProps) => {
    const { visning, visFilter, visFilterIDom, setVisFilterIDom, setFilter } =
      props;

    const [searchParams, setSearchParams] = useSearchParams();

    const { data: lokasjoner, isLoading: isLoadingLokasjoner } =
      useLokasjoner();

    useEffect(() => {
      setFilter(GetQueryParamsAsFilter(searchParams));
      setVisFilterIDom(false);
    }, [searchParams, setFilter, setVisFilterIDom]);

    const onFilterSubmit = (data: Filter) => {
      setSearchParams(GetFilterAsQueryParams(data));
    };

    return (
      <div
        className={`p-4 border-2 border-solid border-darkblue${
          visFilterIDom ? "" : " hidden"
        }`}
      >
        <InntauingFilterWithSearchParams
          lokasjoner={lokasjoner}
          isLoadingLokasjoner={isLoadingLokasjoner}
          visning={visning}
          visFilter={visFilter}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onFilterSubmit={onFilterSubmit}
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      deepEqual(prevProps.visFilter, nextProps.visFilter) &&
      prevProps.visning === nextProps.visning &&
      prevProps.setFilter === nextProps.setFilter &&
      prevProps.visFilterIDom === nextProps.visFilterIDom
    );
  }
);

interface InntauingFilterWithSearchParamsProps {
  lokasjoner?: string[];
  isLoadingLokasjoner: boolean;
  visning: FilterVisning;
  visFilter: VisFilter;
  searchParams: URLSearchParams;
  setSearchParams: (searchParams: any) => void;
  onFilterSubmit: (data: Filter) => void;
}

const InntauingFilterWithSearchParams = (
  props: InntauingFilterWithSearchParamsProps
) => {
  const {
    lokasjoner,
    isLoadingLokasjoner,
    visning,
    visFilter,
    searchParams,
    setSearchParams,
    onFilterSubmit
  } = props;

  const { register, handleSubmit, reset, control, watch } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(inntauingfilterSchema),
    shouldUseNativeValidation: false,
    shouldFocusError: false,
    defaultValues: GetQueryParamsAsFilter(searchParams)
  });

  const datoFra = useWatch({
    control,
    name: "datoFra"
  });

  const datoTil = useWatch({
    control,
    name: "datoTil"
  });

  const currentFormState = watch();

  useEffect(() => {
    reset(GetQueryParamsAsFilter(searchParams));
  }, [reset, searchParams]);

  const searchButtonDisabled = (): boolean => {
    return deepEqual(currentFormState, GetQueryParamsAsFilter(searchParams));
  };

  const nullstillButtonDisabled = (): boolean => {
    return Object.values(GetQueryParamsAsFilter(searchParams)).every(
      v => v === undefined || v === null || v?.length === 0
    );
  };

  return (
    <form
      className="ods-form mt-5 pb-5 text-left"
      onSubmit={handleSubmit(onFilterSubmit)}
    >
      <div className="flex items-end flex-wrap gap-x-4">
        {visFilter?.sok && (
          <div className="ods-form-group w-96">
            <label htmlFor="sok" className="ods-form-label">
              Søk etter inntauing
            </label>
            <div id="sokHelp" className="ods-form-help">
              {TEKST_SOK}
            </div>
            <input
              data-testid="input-avansert-sok"
              id="sok"
              className="ods-form-input"
              type="search"
              {...register("sok")}
            />
          </div>
        )}
        {visFilter?.datoFra && (
          <div className="ods-form-group w-64">
            <label htmlFor="datoFra" className="ods-form-label">
              Inntauet dato (fra)
            </label>
            <div className="flex relative items-center justify-end focus-within:fill-hoverblue">
              <Controller
                name="datoFra"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    name={field.name}
                    className="ods-form-input"
                    onChange={field.onChange}
                    selectsStart
                    startDate={field.value}
                    endDate={datoTil}
                    selected={field.value}
                    dateFormat={["dd.MM.yyyy", "d.MM.yyyy", "ddMMyyyy"]}
                    autoComplete="off"
                  />
                )}
              />
              <CalendarIcon className="pointer-events-none absolute mr-2 h-5 w-5" />
            </div>
          </div>
        )}
        {visFilter?.datoTil && (
          <div className="ods-form-group w-64">
            <label htmlFor="datoTil" className="ods-form-label">
              Inntauet dato (til)
            </label>
            <div className="flex relative items-center justify-end focus-within:fill-hoverblue">
              <Controller
                name="datoTil"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    name={field.name}
                    className="ods-form-input"
                    onChange={field.onChange}
                    selectsEnd
                    startDate={datoFra}
                    endDate={field.value}
                    minDate={datoFra}
                    selected={field.value}
                    dateFormat={["dd.MM.yyyy", "d.MM.yyyy", "ddMMyyyy"]}
                    autoComplete="off"
                  />
                )}
              />
              <CalendarIcon className="pointer-events-none absolute mr-2 h-5 w-5" />
            </div>
          </div>
        )}
      </div>
      {visFilter?.inntauetFra && (
        <div className="ods-form-group w-64">
          <label htmlFor="inntauetFra" className="ods-form-label">
            Inntauet fra
          </label>
          <Controller
            name="inntauetFra"
            control={control}
            render={({ field: { value, name, onChange } }) => (
              <SearchDropdown
                value={value}
                name={name}
                id="inntauetFra"
                classnameDropdown="max-w-[18.6rem]"
                emptyvalue="Alle"
                options={lokasjoner ?? []}
                disabled={isLoadingLokasjoner}
                isloading={isLoadingLokasjoner}
                onchange={onChange}
              />
            )}
          />
        </div>
      )}
      {visFilter?.flyttetTil && (
        <div className="ods-form-group">
          <label htmlFor="flyttetTil" className="ods-form-label">
            Flyttet til
          </label>
          <input
            id="flyttetTil"
            className="ods-form-input"
            {...register("flyttetTil")}
          />
        </div>
      )}
      {visFilter?.brevtyper && (
        <div className="ods-form-group">
          <label htmlFor="brevtyper" className="ods-form-label">
            Brevtyper
          </label>
          <Controller
            name="brevtyper"
            control={control}
            render={({ field }) => (
              <SelectArray
                id={field.name}
                value={field.value}
                alternatives={selectBrevtyperForVisning(visning)}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      )}
      {visFilter?.flagg && (
        <div className="ods-form-group">
          <label htmlFor="flaggTyper" className="ods-form-label">
            Flagg
          </label>
          <Controller
            name="flaggTyper"
            control={control}
            render={({ field }) => (
              <SelectArray
                id={field.name}
                value={field.value}
                alternatives={selectFlaggForVisning(visning)}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      )}
      {visFilter?.betalingsmetoder && (
        <div className="ods-form-group">
          <label htmlFor="betalingsmetoder" className="ods-form-label">
            Betalingsmetoder
          </label>
          <Controller
            name="betalingsmetoder"
            control={control}
            render={({ field }) => (
              <SelectArray
                id={field.name}
                value={field.value}
                alternatives={selectBetalingsmetoderForVisning(visning)}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      )}
      {visFilter?.statuskoder && (
        <div className="ods-form-group">
          <label htmlFor="statuskoder" className="ods-form-label">
            Statuskoder
          </label>
          <Controller
            name="statuskoder"
            control={control}
            render={({ field }) => (
              <SelectArray
                id={field.name}
                value={field.value}
                alternatives={selectStatuserForVisning(visning)}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      )}
      {visFilter?.betalingskravStatuser && (
        <div className="ods-form-group">
          <label htmlFor="betalingskravStatuser" className="ods-form-label">
            Betalingskrav
          </label>
          <Controller
            name="betalingskravStatuser"
            control={control}
            render={({ field }) => (
              <SelectArray
                id={field.name}
                value={field.value}
                alternatives={selectBetalingskravStatuserForVisning(visning)}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      )}
      {visFilter?.kjoretoyNasjoner && (
        <div className="ods-form-group">
          <label htmlFor="kjoretoyNasjoner" className="ods-form-label">
            Nasjon
          </label>
          <Controller
            name="kjoretoyNasjoner"
            control={control}
            render={({ field }) => (
              <SelectArray
                id={field.name}
                value={field.value}
                alternatives={selectKjoretoyNasjonNorgeForVisning(visning)}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      )}
      {visFilter?.kjoretoystyper && (
        <div className="ods-form-group">
          <label htmlFor="kjoretoystyper" className="ods-form-label">
            Kjøretøystype
          </label>
          <Controller
            name="kjoretoystyper"
            control={control}
            render={({ field }) => (
              <SelectArray
                id={field.name}
                value={field.value}
                alternatives={selectKjoretoystyperForVisning(visning)}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      )}
      <div className="flex flex-row flex-wrap">
        <button
          type="reset"
          className="btn btn--secondary btn--icon-left mr-5"
          onClick={() => {
            setSearchParams({});
          }}
          disabled={nullstillButtonDisabled()}
        >
          <Nullstill className="fill-current" />
          <span>Nullstill</span>
        </button>
        <button
          data-testid="button-avansert-sok"
          type="submit"
          className="btn btn--primary btn--icon-right"
          disabled={searchButtonDisabled()}
        >
          <Sok className="fill-current" />
          <span>Søk</span>
        </button>
      </div>
    </form>
  );
};

export const BetalingskravStatusFilterType: {
  [key: string]: number | number[];
} = {
  Opprettet: [
    BetalingskravStatusType.Opprettet,
    BetalingskravStatusType.Generert
  ],
  Sendt: [
    BetalingskravStatusType.SendtTilHgk,
    BetalingskravStatusType.MottattIne
  ],
  Betalt: BetalingskravStatusType.Betalt,
  Mangler_Info: BetalingskravStatusType.Feilet,
  Kansellert: BetalingskravStatusType.SkalIkkeBetales
};

const selectBetalingskravStatuserForVisning = (
  visning?: FilterVisning
): SelectArrayObject[] => {
  switch (visning) {
    case "flyttet":
      return [
        BetalingskravStatusFilterType.Opprettet,
        BetalingskravStatusFilterType.Sendt,
        BetalingskravStatusFilterType.Betalt,
        BetalingskravStatusFilterType.Mangler_Info,
        BetalingskravStatusFilterType.Kansellert
      ].map(b => {
        return {
          id: b,
          name: mapValueToName(BetalingskravStatusFilterType, b)
        };
      });

    default:
      return [];
  }
};

export const KJORETOY_NASJONSKODER_FILTRERING: { [key: string]: number } = {
  Norge: 1,
  Andre_land: 2
};

const selectKjoretoyNasjonNorgeForVisning = (
  visning?: FilterVisning
): SelectArrayObject[] => {
  switch (visning) {
    case "aktive":
      return [
        KJORETOY_NASJONSKODER_FILTRERING.Norge,
        KJORETOY_NASJONSKODER_FILTRERING.Andre_land
      ].map(b => {
        return {
          id: b,
          name: mapValueToName(KJORETOY_NASJONSKODER_FILTRERING, b)
        };
      });

    default:
      return [];
  }
};

const selectBetalingsmetoderForVisning = (
  visning?: FilterVisning
): SelectArrayObject[] => {
  switch (visning) {
    case "avsluttet":
      return [Betalingstype.Faktura, Betalingstype.Kasse].map(b => {
        return {
          id: b,
          name: BetalingstypeNavn[b]
        };
      });
    default:
      return [];
  }
};

const selectStatuserForVisning = (
  visning?: FilterVisning
): SelectArrayObject[] => {
  switch (visning) {
    case "aktive":
      return [
        StatuskodeType.AnkommetTomt,
        StatuskodeType.KranbilBestilt,
        StatuskodeType.TilVrak,
        StatuskodeType.TilAuksjon,
        StatuskodeType.SendtTilAuksjon,
        StatuskodeType.SendtTilVrak
      ].map(i => {
        return {
          id: i,
          name: StatuskodeMapper[i].navn
        };
      });
    case "avsluttet":
      return [
        StatuskodeType.FlyttetPaaGategrunn,
        StatuskodeType.Fremmote,
        StatuskodeType.Hentet,
        StatuskodeType.Vraket,
        StatuskodeType.Auksjonert
      ].map(i => {
        return {
          id: i,
          name: StatuskodeMapper[i].navn
        };
      });
    case "flyttet":
      return [StatuskodeType.FlyttetPaaGategrunn].map(i => {
        return {
          id: i,
          name: StatuskodeMapper[i].navn
        };
      });
    case "siste24t":
      return [
        StatuskodeType.AnkommetTomt,
        StatuskodeType.KranbilBestilt,
        StatuskodeType.FlyttetPaaGategrunn,
        StatuskodeType.Fremmote,
        StatuskodeType.Hentet,
        StatuskodeType.TilVrak,
        StatuskodeType.SendtTilVrak,
        StatuskodeType.TilAuksjon,
        StatuskodeType.SendtTilAuksjon,
        StatuskodeType.Vraket,
        StatuskodeType.Auksjonert
      ].map(i => {
        return {
          id: i,
          name: StatuskodeMapper[i].navn
        };
      });
    default:
      return [];
  }
};

const selectBrevtyperForVisning = (
  visning?: FilterVisning
): SelectArrayObject[] => {
  switch (visning) {
    case "aktive":
    case "avsluttet":
      return [
        BrevtypeFilterType.MeldingOmInntauetKjoretoy,
        BrevtypeFilterType.Avhendingsbrev,
        BrevtypeFilterType.IngenBrevsending
      ].map(b => {
        return {
          id: b,
          name: BrevtypeFilterNavn[b]
        };
      });

    default:
      return [];
  }
};

const selectFlaggForVisning = (visning?: FilterVisning) => {
  switch (visning) {
    case "aktive":
      return [
        FlaggType.Mottakskontroll,
        FlaggType.Klage,
        FlaggType.Varsel,
        FlaggType.Vilkaar,
        FlaggType.UnderArbeid
      ].map(f => {
        return {
          id: f,
          name: FlaggtypeMapper[f].statusnavn
        };
      });

    default:
      break;
  }
};

const selectKjoretoystyperForVisning = (
  visning?: FilterVisning
): SelectArrayObject[] => {
  switch (visning) {
    case "aktive":
    case "flyttet":
    case "avsluttet":
      return mapEnumToNumberValues(Kjoretoystype).map(b => {
        return {
          id: b,
          name: KjoretoystypeMapper[b as Kjoretoystype].navn
        };
      });

    default:
      return [];
  }
};
