import React, { useRef, useState } from "react";
import {
  InntauingViewModel,
  Kjoretoystype,
  KjoretoystypeMapper
} from "../../api/interfaces/Inntauing";
import { useBekreftVraking } from "../../hooks/useVrak";
import { useToast } from "../common/toast/ToastProvider";
import { ReactComponent as File } from "../../assets/icons/file.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { Toggle } from "../common/form/Toggle";
import DatePicker from "../common/form/DatePicker";
import { formaterNullableBelop } from "../../utils/formatUtils";
import { mapEnumToNumberValues } from "../../utils/objectMapping";
import { isSaksbehandler } from "../../utils/userRolesUtils";

interface SendtTilVrakTabellRadProps {
  inntauing: InntauingViewModel;
}

export const SendtTilVrakTabellRad = (props: SendtTilVrakTabellRadProps) => {
  const bestemVrakpant = (inntauing: InntauingViewModel) => {
    if (inntauing.kjoretoy.nasjonalitet !== "N") {
      return 0;
    }

    return KjoretoystypeMapper[
      mapEnumToNumberValues(Kjoretoystype).find(
        k =>
          KjoretoystypeMapper[k as Kjoretoystype].kode ===
          inntauing.kjoretoy.kjoretoystype.kode
      ) as Kjoretoystype
    ].vrakpant;
  };

  const { inntauing } = props;

  const [vrakdato, setVrakdato] = useState<Date>();
  const [vrakdokument, setVrakdokument] = useState<File | null>();
  const [salgAvVrak, setSalgAvVrak] = useState<boolean>(true);
  const [vrakpant, setVrakpant] = useState<number | undefined>(
    bestemVrakpant(inntauing)
  );

  const putBekreftVraking = useBekreftVraking();

  const fileInput = useRef<HTMLInputElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { addToast } = useToast();

  const selectFile = () => {
    fileInput.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const temp = e.target.files?.item(0);
    e.target.value = "";
    setVrakdokument(temp);
  };

  const handleRemoveFile = () => {
    setVrakdokument(undefined);
  };

  const handleArkiver = (e: any) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("Vrakpant", vrakpant ? vrakpant.toString() : "0");
    formData.append("Vraksalg", salgAvVrak.toString());

    if (vrakdokument) formData.append("Vrakdokument", vrakdokument);
    if (vrakdato) formData.append("VraketTidspunkt", vrakdato.toISOString());

    putBekreftVraking.mutate(
      {
        inntauingsId: inntauing.id,
        registrerVraking: formData
      },
      {
        onError: () => {
          addToast("Kunne ikke arkivere vraking", "error");
        }
      }
    );
  };

  const stopReload = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    stopReload(e);

    const file = e.dataTransfer.files?.item(0);
    if (file) {
      setVrakdokument(file);
    } else {
      addToast("Kunne ikke laste opp dokument ved drag-n-drop", "error");
    }
  };

  const handleEnter = () => {
    buttonRef.current?.classList.add("bg-gray-200");
  };
  const handleExit = () => {
    buttonRef.current?.classList.remove("bg-gray-200");
  };

  const handleVrakdato = (dato: Date) => setVrakdato(dato);

  const handleVrakpant = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setVrakpant(parseInt(e.currentTarget.value));

  const toggleSalgAvVrak = () => setSalgAvVrak(prev => !prev);

  return (
    <tr className="h-10">
      <td>
        {new Date(
          inntauing.inntauingsgrunnlag.bestillingstidspunkt.toString() ?? ""
        ).toLocaleDateString()}
      </td>
      <td>{inntauing.kjoretoy.nasjonalitet}</td>
      <td>{inntauing.kjoretoy.registreringsnummer}</td>
      <td>{inntauing.kjoretoyleveranse?.journalnummer}</td>
      <td>{inntauing.kjoretoy.kjoretoystype.kode}</td>
      <td>{inntauing.kjoretoy.merke}</td>
      <td>
        {vrakdokument ? (
          <div className="flex my-1">
            <input
              hidden
              id={inntauing.id}
              ref={el => (fileInput.current = el)}
              type="file"
              onChange={handleFileChange}
            />
            <div
              className="underline text-hoverblue cursor-pointer col-span-2"
              onClick={selectFile}
            >
              {vrakdokument?.name}
            </div>
            <div
              className="cursor-pointer underline ml-4 "
              onClick={handleRemoveFile}
            >
              Slett
            </div>
          </div>
        ) : (
          <div
            onDragEnter={handleEnter}
            onDragExit={handleExit}
            onDragOver={stopReload}
            onDrop={handleDrop}
          >
            <input
              hidden
              id={inntauing.id}
              ref={fileInput}
              type="file"
              onChange={handleFileChange}
            />
            <button
              id={"velg-fil-knapp-" + inntauing.id}
              ref={buttonRef}
              className="btn btn--tertiary btn--icon-left btn--small border-dotted border-4"
              onClick={selectFile}
              data-testid={`vraking-opplast-${inntauing.kjoretoy.registreringsnummer}`}
            >
              <File className="fill-current" />
              <span>Velg fil eller dra fil</span>
            </button>
          </div>
        )}
      </td>
      <td>
        <div className="flex relative items-center justify-end focus-within:fill-hoverblue w-fit my-1">
          <DatePicker
            id={"vrakdato-" + inntauing.id}
            title="Fyll inn reell vrakdato for regnskap (på format dd.mm.yyyy, d.m.yyyy eller ddmmyyyy)"
            dateFormat={["dd.MM.yyyy", "d.MM.yyyy", "ddMMyyyy"]}
            onChange={handleVrakdato}
            autoComplete="off"
            selected={vrakdato}
            customInput={
              <input
                data-testid={`velg-vrakdato-${inntauing.kjoretoy.registreringsnummer}`}
                className="ods-form-input"
              />
            }
          />
          <CalendarIcon className="pointer-events-none absolute mr-2 h-5 w-5" />
        </div>
      </td>
      <td>
        <select
          title="Velg vrakpant"
          className="ods-form-input ods-select w-fit my-1"
          value={vrakpant}
          onChange={handleVrakpant}
        >
          <option value={undefined} />
          {Object.values(Kjoretoystype)
            .filter(k => typeof k !== "string")
            .map(k => KjoretoystypeMapper[k as Kjoretoystype].vrakpant)
            .filter(
              (value, index, self) => self.indexOf(value) === index && value
            )
            .sort((a, b) => (a ?? 0) - (b ?? 0))
            .map(v => (
              <option key={v} value={v}>
                {formaterNullableBelop(v)}
              </option>
            ))}
        </select>
      </td>
      <td>
        <Toggle
          title="Velg om bilvrak ble solgt eller ikke"
          leftText="Ja"
          rightText="Nei"
          value={!salgAvVrak}
          toggleFunction={toggleSalgAvVrak}
        />
      </td>
      <td>
        <div className="flex flex-row flex-wrap">
          {isSaksbehandler() && (
            <button
              title={
                vrakdato
                  ? "Registrer kjøretøy som vraket"
                  : "Oppgi vrakdato før du kan registrere kjøretøy som vraket"
              }
              data-testid={`arkiver-vrak-${inntauing.kjoretoy.registreringsnummer}`}
              className="btn btn--primary btn--small my-1 mr-5"
              onClick={handleArkiver}
              disabled={!vrakdato}
            >
              Registrer vraket
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};
