import { useVedtakMedGrunnlagForTilbakebetaling } from "../../hooks/useKlage";
import {
  isRegnskap,
  isSaksbehandler,
  isUtvikler
} from "../../utils/userRolesUtils";
import { ArkiverteAuksjoner } from "../auksjon/ArkiverteAuksjoner";
import { TabLayout } from "../common/tabs/TabLayout";
import { ArkiverteVrak } from "../vraking/ArkiverteVrak";
import { ArkivAvstemming } from "./arkivAvstemming/ArkivAvstemming";
import { BetalteIleggelser } from "./betalteIleggelser/BetalteIleggelser";
import { Fakturainntekter } from "./fakturainntekter/Fakturainntekter";
import { Flytteinntekter } from "./flytteinntekter/Flytteinntekter";
import { Kasseinntekter } from "./kasseinntekter/Kasseinntekter";
import { Kranbilordre } from "./kranbilordre/Kranbilordre";
import { Tilbakebetalinger } from "./tilbakebetalinger/Tilbakebetalinger";

const populateTabs = (
  parentUrl: string,
  tabId: string,
  antallIkkeTilbakebetalt: number
) => {
  let tabs = [];
  let content = [];
  let hasAccess = [];

  tabs.push({
    text: "Kasseinntekter",
    href: `${parentUrl}/kasseinntekter`,
    active: tabId === "kasseinntekter"
  });
  content.push(<Kasseinntekter />);
  hasAccess.push(isSaksbehandler() || isRegnskap() || isUtvikler());

  tabs.push({
    text: "Kasseavstemminger",
    href: `${parentUrl}/kasseavstemminger`,
    active: tabId === "kasseavstemminger"
  });
  content.push(<ArkivAvstemming />);
  hasAccess.push(isSaksbehandler() || isRegnskap() || isUtvikler());

  tabs.push({
    text: "Fakturainntekter",
    href: `${parentUrl}/fakturainntekter`,
    active: tabId === "fakturainntekter"
  });
  content.push(<Fakturainntekter />);
  hasAccess.push(isSaksbehandler() || isRegnskap() || isUtvikler());

  tabs.push({
    text: "Flytteinntekter",
    href: `${parentUrl}/flytteinntekter`,
    active: tabId === "flytteinntekter"
  });
  content.push(<Flytteinntekter />);
  hasAccess.push(isSaksbehandler() || isRegnskap() || isUtvikler());

  tabs.push({
    text: "Betalte ileggelser",
    href: `${parentUrl}/betalteileggelser`,
    active: tabId === "betalteileggelser"
  });
  content.push(<BetalteIleggelser />);
  hasAccess.push(isSaksbehandler() || isRegnskap() || isUtvikler());

  tabs.push({
    text: "Kranbilordre",
    href: `${parentUrl}/kranbilordre`,
    active: tabId === "kranbilordre"
  });
  content.push(<Kranbilordre />);
  hasAccess.push(isSaksbehandler() || isRegnskap() || isUtvikler());

  tabs.push({
    text: "Auksjoner",
    href: `${parentUrl}/auksjoner`,
    active: tabId === "auksjoner"
  });
  content.push(<ArkiverteAuksjoner />);
  hasAccess.push(isSaksbehandler() || isRegnskap() || isUtvikler());

  tabs.push({
    text: "Vrakinger",
    href: `${parentUrl}/vrakinger`,
    active: tabId === "vrakinger"
  });
  content.push(<ArkiverteVrak />);
  hasAccess.push(isSaksbehandler() || isRegnskap() || isUtvikler());

  tabs.push({
    text:
      "Tilbakebetalinger" +
      (antallIkkeTilbakebetalt > 0 ? ` (${antallIkkeTilbakebetalt})` : ""),
    href: `${parentUrl}/tilbakebetalinger`,
    active: tabId === "tilbakebetalinger"
  });
  content.push(<Tilbakebetalinger />);
  hasAccess.push(isSaksbehandler() || isRegnskap() || isUtvikler());

  return { tabs, content, hasAccess };
};

export const Regnskap = () => {
  const { data } = useVedtakMedGrunnlagForTilbakebetaling();

  const antallIkkeTilbakebetalt =
    data?.klagevedtakInntauinger?.filter(kv => !kv.erTilbakebetalt)?.length ??
    0;

  const { tabs, content, hasAccess } = populateTabs(
    window.location.origin + "/regnskap",
    window.location.pathname.split("/").pop() ?? "",
    antallIkkeTilbakebetalt
  );

  return <TabLayout tabs={tabs} content={content} hasAccess={hasAccess} />;
};
