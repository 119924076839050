import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { KeyValueKortInnhold } from "../common/card/KeyValueKortInnhold";
import { Kort } from "../common/card/Kort";
import { ReactComponent as Kranbil } from "../../assets/icons/inntauing.svg";

interface InntauingsjournalProps {
  inntauing: InntauingViewModel;
}

export const Inntauingsjournal = (props: InntauingsjournalProps) => {
  const { inntauing } = props;

  return (
    <div key={inntauing.id} className="break-before-page">
      <Kort
        headerText="Inntauingsjournal"
        headerIcon={<Kranbil className="fill-darkblue" />}
        fullWidth
        innhold={
          <KeyValueKortInnhold
            keyValuePair={[
              {
                key: "Radnr",
                value: [inntauing.kjoretoyleveranse?.plassering ?? ""]
              },
              {
                key: "Regnr",
                value: [inntauing.kjoretoy?.registreringsnummer ?? ""]
              },
              {
                key: "Chassisnr",
                value: [inntauing.kjoretoy?.rammenummer ?? ""]
              },
              {
                key: "Journalnr",
                value: [
                  inntauing.kjoretoyleveranse?.journalnummer?.toString() ?? ""
                ]
              },
              { key: "Merke", value: [inntauing.kjoretoy?.merke ?? ""] },
              { key: "Modell", value: [inntauing.kjoretoy?.modell ?? ""] },
              { key: "Farge", value: [inntauing.kjoretoy?.farge ?? ""] },
              {
                key: "Nasjon",
                value: [inntauing.kjoretoy?.nasjonalitet ?? ""]
              },
              {
                key: "Kjøretøytype",
                value: [inntauing.kjoretoy?.kjoretoystype.kode ?? ""]
              }
            ]}
            keyWidth="w-40"
          />
        }
      />
    </div>
  );
};
