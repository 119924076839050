import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Brev } from "../../assets/icons/stolpemeny_brevutsendelse.svg";
import { ReactComponent as Siste24 } from "../../assets/icons/stolpemeny_24t.svg";
import { ReactComponent as Inntauing } from "../../assets/icons/stolpemeny_inntauing.svg";
import { ReactComponent as Flyttet } from "../../assets/icons/stolpemeny_flyttetgategrunn.svg";
import { ReactComponent as Avsluttede } from "../../assets/icons/stolpemeny_historikk.svg";
import { ReactComponent as Vrak } from "../../assets/icons/stolpemeny_vraking.svg";
import { ReactComponent as Auksjon } from "../../assets/icons/stolpemeny_auksjon.svg";
import { ReactComponent as RegistrerNy } from "../../assets/icons/stolpemeny_registrer.svg";
import { ReactComponent as Innstillinger } from "../../assets/icons/stolpemeny_innstillinger.svg";
import { ReactComponent as Taksering } from "../../assets/icons/stolpemeny_taksering.svg";
import { ReactComponent as Faktura } from "../../assets/icons/stolpemeny_faktura.svg";
import { useAuth } from "react-oidc-context";
import {
  isEkspeditor,
  isInnsyn,
  isRegnskap,
  isSaksbehandler,
  isUtvikler
} from "../../utils/userRolesUtils";
import { TakseringNotifikasjon } from "./TakseringNotifikasjon";
import { ROUTES } from "../../utils/globalConstants";
import { FlyttingerNotifikasjon } from "./FlyttingerNotifikasjon";
import { TilbakebetalingerNotifikasjon } from "./TilbakebetalingerNotifikasjon";

export const Stolpemeny = () => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.isAuthenticated) return null;

  const initTabOppgjor =
    isSaksbehandler() || isUtvikler()
      ? "/kasseavstemming"
      : isEkspeditor()
      ? "/mineutleveringer"
      : "/kranbilordre";

  const buttonStyle = (pathname: string) => {
    const isActive = location.pathname.includes(pathname);

    return `btn btn--flat btn--large btn--icon-only focus:bg-white hover:bg-white focus:border-white hover:border-white ${
      isActive ? "bg-white" : ""
    }`;
  };

  return (
    <div className="fixed max-w-[5.5rem] top-[4.5rem] tabletBig:top-[3rem] bottom-0 overflow-y-auto overflow-x-hidden bg-rusticyellow z-50">
      <nav className="flex flex-col">
        {(isSaksbehandler() ||
          isEkspeditor() ||
          isInnsyn() ||
          isUtvikler()) && (
          <>
            <NavLink
              title="Aktive inntauinger"
              className={buttonStyle(ROUTES.Aktive_inntauinger)}
              to={ROUTES.Aktive_inntauinger}
            >
              <Inntauing className="fill-current" />
            </NavLink>

            <NavLink
              title="Alle inntauinger siste 24 timer"
              className={buttonStyle(ROUTES.Inntauinger_siste_24t)}
              to={ROUTES.Inntauinger_siste_24t}
            >
              <Siste24 className="fill-current" />
            </NavLink>

            <NavLink
              title="Flyttet på gategrunn"
              data-testid="link-flyttet-pa-gategrunn"
              className={buttonStyle(ROUTES.Flyttet_pa_gategrunn)}
              to={ROUTES.Flyttet_pa_gategrunn}
            >
              <div className="relative inline-flex">
                <Flyttet className="fill-current stroke-current btn__icon" />
                <FlyttingerNotifikasjon />
              </div>
            </NavLink>
            <NavLink
              title="Avsluttede inntauinger"
              data-testid="link-avsluttet"
              className={buttonStyle(ROUTES.Avsluttede_inntauinger)}
              to={ROUTES.Avsluttede_inntauinger}
            >
              <Avsluttede className="fill-current" />
            </NavLink>
          </>
        )}

        {(isSaksbehandler() || isEkspeditor() || isUtvikler()) && (
          <NavLink
            title="Oppgjør"
            id="utleverLenke"
            data-testid="link-oppgjor"
            className={buttonStyle(ROUTES.Oppgjor)}
            to={ROUTES.Oppgjor + initTabOppgjor}
          >
            <Faktura className="fill-current" />
          </NavLink>
        )}
        {(isRegnskap() || isSaksbehandler() || isUtvikler()) && (
          <NavLink
            title="Regnskap"
            data-testid="link-regnskap"
            className={buttonStyle(ROUTES.Regnskap)}
            to={ROUTES.Regnskap + "/kasseinntekter"}
          >
            <div className="relative inline-flex">
              <TilbakebetalingerNotifikasjon />
              <Faktura className="fill-current" />
            </div>
          </NavLink>
        )}

        {(isSaksbehandler() || isUtvikler()) && (
          <NavLink
            title="Brevsending"
            data-testid="link-brevsending"
            className={buttonStyle(ROUTES.Brevutsendelse)}
            to={ROUTES.Brevutsendelse}
          >
            <div className="relative inline-flex">
              <Brev className="fill-current" />
            </div>
          </NavLink>
        )}

        {(isSaksbehandler() || isUtvikler()) && (
          <NavLink
            title="Taksering"
            className={buttonStyle(ROUTES.Taksering)}
            to={ROUTES.Taksering + "/prosess"}
          >
            <div className="relative inline-flex">
              <Taksering className="fill-current" />
              <TakseringNotifikasjon />
            </div>
          </NavLink>
        )}

        {(isSaksbehandler() || isUtvikler()) && (
          <NavLink
            title="Vraking"
            data-testid="link-vraking"
            className={buttonStyle(ROUTES.Vraking)}
            to={ROUTES.Vraking + "/prosess"}
          >
            <Vrak className="fill-current" />
          </NavLink>
        )}

        {(isSaksbehandler() || isUtvikler()) && (
          <NavLink
            title="Auksjon"
            data-testid="link-auksjon"
            className={buttonStyle(ROUTES.Auksjon)}
            to={ROUTES.Auksjon + "/prosess"}
          >
            <Auksjon className="fill-current" />
          </NavLink>
        )}

        {(isEkspeditor() || isSaksbehandler()) && (
          <NavLink
            title="Registrer ny inntauing manuelt"
            className={buttonStyle(ROUTES.Manuell_registrering)}
            to={ROUTES.Manuell_registrering}
          >
            <RegistrerNy />
          </NavLink>
        )}

        {(isEkspeditor() || isSaksbehandler() || isUtvikler()) && (
          <NavLink
            title="Innstillinger"
            data-testid="link-innstillinger"
            className={buttonStyle(ROUTES.Innstillinger)}
            to={ROUTES.Innstillinger + "/prislister"}
          >
            <Innstillinger className="fill-current" />
          </NavLink>
        )}
      </nav>
    </div>
  );
};
