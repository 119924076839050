import { Siste24tTabell } from "./Siste24tTabell";

export const Siste24t = () => {
  return (
    <div className="flex flex-row h-full">
      <div className="mr-10 oslo-inntauing-visning ml-[3rem]">
        <Siste24tTabell />
      </div>
    </div>
  );
};
