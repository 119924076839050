import { useAuth } from "react-oidc-context";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getUser } from "../api/client";
import { LoadingSpinner } from "../components/common/loading/LoadingSpinner";
import { ROUTES } from "../utils/globalConstants";
import { Role } from "../utils/userRolesUtils";

interface PrivateRoutesProps {
  hasAccess: Role[];
}

export const PrivateRoutes = (props: PrivateRoutesProps) => {
  const { hasAccess } = props;
  const auth = useAuth();

  const location = useLocation();

  if (auth.isLoading) {
    return <LoadingSpinner />;
  }

  if (auth.error) {
    auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
  }

  if (!auth.isAuthenticated) {
    return <Navigate to={ROUTES.Login} replace state={{ from: location }} />;
  }

  const userRoles = (getUser()?.profile.groups as string[]) ?? [];
  const userHasAccess = userRoles.some(ur => hasAccess.toString().includes(ur));

  if (auth.isAuthenticated && !userHasAccess) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};
