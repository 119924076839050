import "./KranbilordreTabell.scss";
import { formaterNullableBelop } from "../../../utils/formatUtils";
import { KategoriFaktureringsgrunnlagViewModel } from "../../../api/interfaces/Kranbilselskap";
import { BetalingsgruppeMapper } from "../../../api/interfaces/Inntauing";

interface KranbilordreTabellProps {
  kategorigrunnlag: KategoriFaktureringsgrunnlagViewModel;
}

export const KranbilordreTabell = (props: KranbilordreTabellProps) => {
  const { kategorigrunnlag } = props;

  return (
    <>
      <div className="font-semibold w-fit h-fit">{`${
        BetalingsgruppeMapper[kategorigrunnlag.kategoriType].navn
      }: ${kategorigrunnlag.antall}`}</div>
      <table className="tbl mt-3 mb-10">
        <thead>
          <tr>
            <th>Ordrenr</th>
            <th>Regnr</th>
            <th>Journalnr</th>
            <th>Merke</th>
            <th>Modell</th>
            <th>Inntauingstidspunkt</th>
            <th>Betjentnr</th>
            <th>Beløp</th>
          </tr>
        </thead>
        <tbody>
          {kategorigrunnlag.enkeltgrunnlag.map(i => (
            <tr key={i.inntauingskategori + i.ordrenummer}>
              <td>{i.ordrenummer ?? ""}</td>
              <td>{i.registreringsnummer ?? ""}</td>
              <td>{i.journalnummer ?? ""}</td>
              <td>{i.merke ?? ""}</td>
              <td>{i.modell}</td>
              <td>{new Date(i.inntauingstidspunkt).toLocaleString()}</td>
              <td>{i.betjentnummer ?? ""}</td>
              <td>{formaterNullableBelop(i.belop)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={7} className="text-right pr-5">
              {`Totalsum for ${BetalingsgruppeMapper[
                kategorigrunnlag.kategoriType
              ].navn.toLowerCase()}:`}
            </td>
            <td>{formaterNullableBelop(kategorigrunnlag.totalbelop)}</td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};
