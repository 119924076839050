import { useCallback } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { finnEier } from "../../utils/objectMapping";
import DatePicker from "../common/form/DatePicker";
import { RequiredLabel } from "../common/form/RequiredLabel";
import { Toggle } from "../common/form/Toggle";
import { Felter } from "./utleveringUtils";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";

interface RegistrerUtleveringHenterProps {
  inntauing: InntauingViewModel;
}

export const RegistrerUtleveringHenter = (
  props: RegistrerUtleveringHenterProps
) => {
  const { inntauing } = props;

  const {
    control,
    formState: { errors },
    register,
    setValue
  } = useFormContext<Felter>();

  const eierHenter = useWatch({
    control,
    name: "eierHenter"
  });

  const eier = finnEier(
    inntauing?.kjoretoy.juridiskEier,
    inntauing?.kjoretoy.medeier,
    inntauing?.kjoretoy.leasingtaker
  );

  const handleToggleHenter = useCallback(() => {
    if (!eierHenter) {
      setValue("navn", eier?.navn ?? "", {
        shouldValidate: Object.entries(errors).length > 0
      });
      setValue(
        "fodselsdato",
        eier?.fodselsdato ? new Date(eier.fodselsdato) : undefined,
        {
          shouldValidate: Object.entries(errors).length > 0
        }
      );
    } else {
      setValue("navn", "", {
        shouldValidate: Object.entries(errors).length > 0
      });
      setValue("fodselsdato", undefined, {
        shouldValidate: Object.entries(errors).length > 0
      });
    }

    setValue("eierHenter", !eierHenter);
  }, [eier?.fodselsdato, eier?.navn, eierHenter, errors, setValue]);

  return (
    <>
      <div className="flex flex-row flex-wrap gap-x-5">
        <div className="ods-form-group">
          <label htmlFor="navn" className="ods-form-label flex flex-row">
            Hentet av
            <RequiredLabel />
          </label>
          <div className="ods-form-help">Fornavn Etternavn</div>
          <input
            id="navn"
            className="ods-form-input w-96"
            {...register("navn")}
            disabled={eierHenter}
            autoComplete="off"
          />
        </div>
        <div className="ods-form-group">
          <label htmlFor="fodselsdato" className="ods-form-label flex flex-row">
            Fødselsdato
            <RequiredLabel />
          </label>
          <div className="ods-form-help">DD.MM.ÅÅÅÅ eller DDMMÅÅÅÅ</div>
          <Controller
            control={control}
            name="fodselsdato"
            render={({ field: { onChange, name, value }, fieldState }) => (
              <div className="flex relative items-center justify-end focus-within:fill-hoverblue">
                <DatePicker
                  id={name}
                  className={`ods-form-input w-56 ${
                    fieldState.error && "border-errorred focus:border-errorred"
                  }`}
                  name={name}
                  onChange={onChange}
                  disabled={eierHenter}
                  selected={value}
                  dateFormat={["dd.MM.yyyy", "d.MM.yyyy", "ddMMyyyy"]}
                  isClearable
                  clearButtonClassName="invisible"
                  open={false}
                  autoComplete="off"
                />
                <CalendarIcon className="pointer-events-none absolute mr-5 h-5 w-5" />
              </div>
            )}
          />
        </div>
      </div>
      {eier?.erPerson && (
        <div className="ods-form-group" data-testid="toggle-eier-henter">
          <Toggle
            id="henter"
            leftText="Annen henter"
            rightText="Eier henter"
            value={eierHenter}
            toggleFunction={handleToggleHenter}
          />
        </div>
      )}
    </>
  );
};
