import { ArkiverteTakseringer } from "./ArkiverteTakseringer";
import { TilTakst } from "./TilTakst";
import { TabLayout } from "../common/tabs/TabLayout";

const populateTabs = (parentUrl: string, tabId: string) => {
  let tabs = [];
  let content = [];
  let hasAccess = [];

  tabs.push({
    text: "Takstprosess",
    href: `${parentUrl}/prosess`,
    active: tabId === "prosess"
  });
  content.push(<TilTakst />);
  hasAccess.push(true);

  tabs.push({
    text: "Gjennomførte",
    href: `${parentUrl}/gjennomforte`,
    active: tabId === "gjennomforte"
  });
  content.push(<ArkiverteTakseringer />);
  hasAccess.push(true);

  return { tabs, content, hasAccess };
};

export const Taksering = () => {
  const { tabs, content, hasAccess } = populateTabs(
    window.location.origin + "/taksering",
    window.location.pathname.split("/").pop() ?? ""
  );

  return <TabLayout tabs={tabs} content={content} hasAccess={hasAccess} />;
};
