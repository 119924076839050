import { BetalingViewModel } from "./Betaling";
import { HenterViewModel, KjoretoyViewModel } from "./Inntauing";

export enum FakturaStatusType {
  AvventerGodkjenning = 0,
  KlarForFakturering = 1,
  UnderBehandling = 2,
  Fakturert = 3,
  ManueltFakturert = 4,
  Feilet = 9
}

export const FakturaStatusMapper: Record<
  FakturaStatusType,
  { navn: string; farge: string }
> = {
  [FakturaStatusType.AvventerGodkjenning]: {
    navn: "Avventer godkjenning",
    farge: "#fae8c8"
  },
  [FakturaStatusType.KlarForFakturering]: {
    navn: "Klar for fakturering",
    farge: "#fae8c8"
  },
  [FakturaStatusType.UnderBehandling]: {
    navn: "Under behandling",
    farge: "#fae8c8"
  },
  [FakturaStatusType.Fakturert]: {
    navn: "Fakturert",
    farge: "#ccffce"
  },
  [FakturaStatusType.ManueltFakturert]: {
    navn: "Manuelt fakturert",
    farge: "#ffffff"
  },
  [FakturaStatusType.Feilet]: {
    navn: "Feilet",
    farge: "#ff8274"
  }
};

export interface FakturaViewModel {
  fakturanummer: number;
  betalingsnummer: number;
  status: FakturaStatusType;
  godkjentDato?: Date;
}

export interface FakturaInputModel {
  mottakerReferanse: string;
  mottakeridentifikator: string;
}

export interface FakturagodkjennelseViewModel {
  inntauingsId: string;
  henter?: HenterViewModel;
  betaling: BetalingViewModel;
  kjoretoy: KjoretoyViewModel;
}
