import { apiClient } from "../client";
import { FlaggInputModel, FlaggViewModel } from "../interfaces/Flagg";
import { config } from "./Config";
import { AxiosResponse } from "axios";

const client = apiClient();

export const postOpprettFlagg = (
  inntauingsId: string,
  flaggInputModel: FlaggInputModel
) =>
  client.post<FlaggViewModel>(
    `flagg/${inntauingsId}/opprett`,
    flaggInputModel,
    config()
  );

export const putFjernFlagg = (inntauingsId: string, flaggId: string) =>
  client.put<AxiosResponse>(
    `flagg/${inntauingsId}/fjern/${flaggId}`,
    undefined,
    config()
  );
