import * as yup from "yup";

export const nyPrislisteSchema = 
    yup.object().shape({
        gyldigFra: yup
            .date()
            .typeError("Oppgi dato prisliste skal gjelde fra")
            .required("Oppgi dato prisliste skal gjelde fra"),
        administrasjonsgebyr: yup.object().shape({
            pris: yup
                .number()
                .typeError("Oppgi pris for administrasjonsgebyr")
                .required("Oppgi pris for administrasjonsgebyr"),
            varenummer: yup
                .number()
                .typeError("Oppgi varenummer for administrasjonsgebyr")
                .required("Oppgi varenummer for administrasjonsgebyr")
        }),
        administrasjonsgebyrFlytting: yup.object().shape({
            pris: yup
                .number()
                .typeError("Oppgi pris for administrasjonsgebyr for flytting")
                .required("Oppgi pris for administrasjonsgebyr for flytting"),
            varenummer: yup
                .number()
                .typeError("Oppgi varenummer for administrasjonsgebyr for flytting")
                .required("Oppgi varenummer for administrasjonsgebyr for flytting")
        }),
        dognleie: yup.object().shape({
            pris: yup
                .number()
                .typeError("Oppgi pris for døgnleie")
                .required("Oppgi pris for døgnleie"),
            varenummer: yup
                .number()
                .typeError("Oppgi varenummer for døgnleie")
                .required("Oppgi varenummer for døgnleie")
        }),
        inntauingskostnader: yup.array().of(
            yup.object().shape({
            pris: yup
                .number()
                .typeError("Oppgi pris for alle inntauingskostnader")
                .required("Oppgi pris for alle inntauingskostnader"),
            gebyrtype: yup
                .number()
                .typeError("Feil ved gebyrtype, kontakt utviklere")
                .required("Feil ved gebyrtype, kontakt utviklere"),
            varenummer: yup
                .number()
                .typeError("Oppgi varenummer for alle inntauingskostnader")
                .required("Oppgi varenummer for alle inntauingskostnader")
            }),
        )
    })