import { AxiosResponse } from "axios";
import { subHours } from "date-fns";
import { apiClient } from "../client";
import { Filter } from "../interfaces/Filter";
import {
  PrisForInntauingViewModel,
  InntauingsGrunnlagViewModel,
  InntauingViewModel,
  RegistrerInntauingInputModel,
  StatuskodeType
} from "../interfaces/Inntauing";
import { HandlingsloggEntryViewModel } from "../interfaces/Notat";
import { PaginationViewModel } from "../interfaces/PaginationViewModel";
import { config } from "./Config";
import { queryDate, queryString, queryNumberArray } from "./serviceUtils";

const client = apiClient();

export const getSendteBrev = (
  cursor: number,
  pageSize: number,
  sortKey: string,
  isDescending: boolean
) =>
  client.get<PaginationViewModel<InntauingViewModel>>(
    `inntauing?page=${cursor}&pageSize=${pageSize}&sortBy=${sortKey}&orderByDesc=${isDescending}` +
      "&erVarslet=true",
    config()
  );

export const getAktiveInntauinger = (
  cursor: number,
  pageSize: number,
  sortKey: string,
  isDescending: boolean,
  filter: Filter
) => {
  return client.get<PaginationViewModel<InntauingViewModel>>(
    `inntauing?page=${cursor}&pageSize=${pageSize}&sortBy=${sortKey}&orderByDesc=${isDescending}&erAktiv=${true}` +
      queryDate("from", filter.datoFra) +
      queryDate("to", filter.datoTil) +
      queryString("inntauetFra", filter.inntauetFra) +
      queryString("sok", filter.sok) +
      queryNumberArray("brevtyper", filter.brevtyper) +
      queryNumberArray("flaggTyper", filter.flaggTyper) +
      queryNumberArray("statuser", filter.statuskoder) +
      queryNumberArray("kjoretoyNasjoner", filter.kjoretoyNasjoner) +
      queryNumberArray("kjoretoystyper", filter.kjoretoystyper),
    config()
  );
};

export const getAlleInntauinger = (
  cursor: number,
  pageSize: number,
  sortKey: string,
  isDescending: boolean,
  filter: Filter
) => {
  return client.get<PaginationViewModel<InntauingViewModel>>(
    `inntauing?page=${cursor}&pageSize=${pageSize}&sortBy=${sortKey}&orderByDesc=${isDescending}` +
      queryDate("from", filter.datoFra) +
      queryDate("to", filter.datoTil) +
      queryString("inntauetFra", filter.inntauetFra) +
      queryString("sok", filter.sok) +
      queryNumberArray("brevtyper", filter.brevtyper) +
      queryNumberArray("flaggTyper", filter.flaggTyper) +
      queryNumberArray("statuser", filter.statuskoder) +
      queryNumberArray("kjoretoyNasjoner", filter.kjoretoyNasjoner) +
      queryNumberArray("kjoretoystyper", filter.kjoretoystyper),
    config()
  );
};

export const getAvsluttetInntauinger = (
  cursor: number,
  pageSize: number,
  sortKey: string,
  isDescending: boolean,
  filter: Filter
) =>
  client.get<PaginationViewModel<InntauingViewModel>>(
    `inntauing?erAktiv=false&page=${cursor}&pageSize=${pageSize}&sortBy=${sortKey}&orderByDesc=${isDescending}` +
      queryDate("from", filter.datoFra) +
      queryDate("to", filter.datoTil) +
      queryString("inntauetFra", filter.inntauetFra) +
      queryString("sok", filter.sok) +
      queryNumberArray("betalingsmetoder", filter.betalingsmetoder) +
      queryNumberArray("statuser", filter.statuskoder) +
      queryNumberArray("brevtyper", filter.brevtyper) +
      queryNumberArray("kjoretoystyper", filter.kjoretoystyper),
    config()
  );

export const getFlyttetPaGategrunn = (
  cursor: number,
  pageSize: number,
  sortKey: string,
  isDescending: boolean,
  filter: Filter
) =>
  client.get<PaginationViewModel<InntauingViewModel>>(
    `inntauing?page=${cursor}&pageSize=${pageSize}&sortBy=${sortKey}&orderByDesc=${isDescending}&statuser=${StatuskodeType.FlyttetPaaGategrunn}` +
      queryDate("from", filter.datoFra) +
      queryDate("to", filter.datoTil) +
      queryString("inntauetFra", filter.inntauetFra) +
      queryString("flyttetTil", filter.flyttetTil) +
      queryNumberArray("betalingskravStatuser", filter.betalingskravStatuser) +
      queryString("sok", filter.sok) +
      queryNumberArray("kjoretoystyper", filter.kjoretoystyper),
    config()
  );

export const getFlyttingerMedFeiletBetalingskrav = () =>
  client.get<PaginationViewModel<InntauingViewModel>>(
    `inntauing?statuser=${StatuskodeType.FlyttetPaaGategrunn}&betalingskravstatuser=99`,
    config()
  );

export const getInntauingerSiste24t = (
  cursor: number,
  pageSize: number,
  sortKey: string,
  isDescending: boolean,
  filter: Filter
) => {
  const ettDognSiden = subHours(new Date(), 24);

  return client.get<PaginationViewModel<InntauingViewModel>>(
    `inntauing?page=${cursor}&pageSize=${pageSize}&sortBy=${sortKey}&orderByDesc=${isDescending}` +
      `&to=${new Date().toISOString()}` +
      `&from=${ettDognSiden.toISOString()}` +
      queryString("inntauetFra", filter.inntauetFra) +
      queryString("sok", filter.sok) +
      queryNumberArray("statuser", filter.statuskoder),
    config()
  );
};

export const getInntauing = (id: string) =>
  client.get<InntauingViewModel>(`inntauing/${id}`, config());

export const getInntauingUtlevering = (sok: string) =>
  client.get<InntauingViewModel[]>(
    `inntauing/utleveringsok?sokestreng=${sok}`,
    config()
  );

export const postInntauing = (inntauing: RegistrerInntauingInputModel) =>
  client.post<RegistrerInntauingInputModel, InntauingViewModel>(
    `inntauing`,
    inntauing,
    config()
  );

export const putInntauingsGrunnlag = (
  inntauingId: string,
  grunnlag: InntauingsGrunnlagViewModel
) =>
  client.put<InntauingsGrunnlagViewModel>(
    `inntauing/${inntauingId}/grunnlag`,
    grunnlag,
    config()
  );

export const getHandlingslogg = (inntauingId: string) =>
  client.get<HandlingsloggEntryViewModel[]>(
    `inntauing/${inntauingId}/handlingslogg`,
    config()
  );

export const putTillatFakturering = (inntauingId: string) =>
  client.put<AxiosResponse>(
    `inntauing/${inntauingId}/tillatfakturering`,
    undefined,
    config()
  );

export const putTillatUtleveringUtenBetaling = (
  inntauingId: string,
  aarsak: string
) =>
  client.put<AxiosResponse>(
    `inntauing/${inntauingId}/tillatUtleveringUtenBetaling`,
    {
      value: aarsak
    },
    config()
  );

export const putTillatUtleveringMedRedusertDognleie = (
  inntauingId: string,
  aarsak: string
) =>
  client.put<AxiosResponse>(
    `inntauing/${inntauingId}/tillatRedusertDognleie`,
    {
      value: aarsak
    },
    config()
  );

export const getKostnaderVedHenting = (
  inntauingsId: string,
  hentettidspunkt?: Date
) =>
  client.get<PrisForInntauingViewModel>(
    `inntauing/${inntauingsId}/utestaaendekostnader?beregnfortidspunkt=${hentettidspunkt?.toISOString()}`,
    config()
  );

export const getDeaktiverteInntauinger = (fraDato: Date, tilDato: Date) =>
  client.get<InntauingViewModel[]>(
    `inntauing/deaktiverte?fraBestillingstidspunkt=${fraDato.toISOString()}&tilBestillingstidspunkt=${tilDato.toISOString()}`,
    config()
  );

export const putDeaktiverInntauing = (inntauingsId: string, aarsak: string) =>
  client.put<AxiosResponse>(
    `inntauing/${inntauingsId}/deaktiver`,
    {
      value: aarsak
    },
    config()
  );

export const putKansellerBetalingskrav = (
  inntauingsId: string,
  aarsak: string
) =>
  client.put<AxiosResponse>(
    `inntauing/${inntauingsId}/kansellerbetalingskrav`,
    {
      value: aarsak
    },
    config()
  );
