import { useState, useEffect } from "react";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { useFjernFlaggs } from "../../hooks/useFlagg";
import { useToast } from "../common/toast/ToastProvider";
import { HeadingView } from "../common/typography/HeadingView";
import { FlaggType, FlaggtypeMapper } from "../../api/interfaces/Flagg";

import { ReactComponent as FlaggRod } from "../../assets/icons/alert-error-rod.svg";
import { ReactComponent as FlaggGul } from "../../assets/icons/alert-error-gul.svg";
import { ReactComponent as FlaggBlaa } from "../../assets/icons/alert-error-blaa.svg";

interface FjernFlaggProps {
  inntauing: InntauingViewModel;
  close: () => void;
}

export const FjernFlagg = (props: FjernFlaggProps) => {
  const { inntauing, close } = props;

  const [fjernVarselflaggId, setFjernVarselflaggId] = useState<string>("");
  const [fjernKlageflaggId, setFjernKlageflaggId] = useState<string>("");
  const [fjernUnderArbeidesflaggId, setFjernUnderArbeidesflaggId] =
    useState<string>("");
  const [fjernVilkaarsflaggId, setFjernVilkaarsflaggId] = useState<string>("");
  const [fjernMottakskontrollsflaggId, setFjernMottakskontrollsflaggId] =
    useState<string>("");
  const [bekreftDisabled, setBekreftDisabled] = useState<boolean>(false);

  const putFjernFlagg = useFjernFlaggs();
  const { addToast } = useToast();

  useEffect(() => {
    if (
      !fjernVarselflaggId &&
      !fjernKlageflaggId &&
      !fjernUnderArbeidesflaggId &&
      !fjernVilkaarsflaggId &&
      !fjernMottakskontrollsflaggId
    ) {
      setBekreftDisabled(true);
    } else {
      setBekreftDisabled(false);
    }
  }, [
    fjernVarselflaggId,
    fjernKlageflaggId,
    fjernUnderArbeidesflaggId,
    fjernVilkaarsflaggId,
    fjernMottakskontrollsflaggId
  ]);

  const handleFjernFlagg = () => {
    const flagg = [];
    fjernVarselflaggId &&
      flagg.push({ id: fjernVarselflaggId, type: FlaggType.Varsel });
    fjernKlageflaggId &&
      flagg.push({ id: fjernKlageflaggId, type: FlaggType.Klage });
    fjernUnderArbeidesflaggId &&
      flagg.push({
        id: fjernUnderArbeidesflaggId,
        type: FlaggType.UnderArbeid
      });
    fjernVilkaarsflaggId &&
      flagg.push({ id: fjernVilkaarsflaggId, type: FlaggType.Vilkaar });
    fjernMottakskontrollsflaggId &&
      flagg.push({
        id: fjernMottakskontrollsflaggId,
        type: FlaggType.Mottakskontroll
      });

    putFjernFlagg.mutate(
      { inntauingsId: inntauing.id, flagg: flagg },
      {
        onSuccess: statusFlaggTyper => {
          addToast(
            `Flagg med flaggtyper som ble fjernet: ${statusFlaggTyper.okFlaggTyper}`
          );
          close();
        },
        onError: statusFlaggTyper => {
          addToast(
            `Flagg med flaggtyper som ble fjernet: ${statusFlaggTyper.okFlaggTyper}, 
            flagg som ikke ble fjernet: ${statusFlaggTyper.feiletFlaggTyper}`,
            "error"
          );
        }
      }
    );
  };

  const sortedAndFilteredInntauingsflagg = inntauing.flagg
    ?.sort(
      (a, b) => FlaggtypeMapper[a.type].order - FlaggtypeMapper[b.type].order
    )
    .filter(sf => !sf.fjernet);

  const handleCheckboxChange = (id: string, type: number) => {
    switch (type) {
      case FlaggType.Varsel:
        fjernVarselflaggId
          ? setFjernVarselflaggId("")
          : setFjernVarselflaggId(id);
        return;
      case FlaggType.Klage:
        fjernKlageflaggId ? setFjernKlageflaggId("") : setFjernKlageflaggId(id);
        return;
      case FlaggType.UnderArbeid:
        fjernUnderArbeidesflaggId
          ? setFjernUnderArbeidesflaggId("")
          : setFjernUnderArbeidesflaggId(id);
        return;
      case FlaggType.Vilkaar:
        fjernVilkaarsflaggId
          ? setFjernVilkaarsflaggId("")
          : setFjernVilkaarsflaggId(id);
        return;
      case FlaggType.Mottakskontroll:
        fjernMottakskontrollsflaggId
          ? setFjernMottakskontrollsflaggId("")
          : setFjernMottakskontrollsflaggId(id);
        return;
      default:
        return;
    }
  };

  const checked = (type: number): boolean | undefined => {
    switch (type) {
      case FlaggType.Varsel:
        return fjernVarselflaggId ? true : false;
      case FlaggType.Klage:
        return fjernKlageflaggId ? true : false;
      case FlaggType.UnderArbeid:
        return fjernUnderArbeidesflaggId ? true : false;
      case FlaggType.Vilkaar:
        return fjernVilkaarsflaggId ? true : false;
      case FlaggType.Mottakskontroll:
        return fjernMottakskontrollsflaggId ? true : false;
      default:
        return undefined;
    }
  };

  const getFlaggIkone = (type: FlaggType) => {
    switch (type) {
      case FlaggType.Varsel:
        return <FlaggRod title={FlaggtypeMapper[type].navn} />;
      case FlaggType.Klage:
        return <FlaggGul title={FlaggtypeMapper[type].navn} />;
      case FlaggType.UnderArbeid:
        return <FlaggGul title={FlaggtypeMapper[type].navn} />;
      case FlaggType.Vilkaar:
        return <FlaggGul title={FlaggtypeMapper[type].navn} />;
      case FlaggType.Mottakskontroll:
        return <FlaggBlaa title={FlaggtypeMapper[type].navn} />;
      default:
        return undefined;
    }
  };

  return (
    <>
      <HeadingView>Fjern flagg</HeadingView>
      <>
        <div className="mb-4 w-80">
          <p className="text-2xl mb-4">Velg flagg som skal fjernes</p>
          {sortedAndFilteredInntauingsflagg?.map(f => {
            return (
              <div className="ods-form-group" key={`flagg-${f.id}`}>
                <label htmlFor={f.id}>
                  <div className="flex gap-4">
                    <input
                      id={f.id}
                      data-testid={`${FlaggtypeMapper[
                        f.type
                      ].statusnavn.toLocaleLowerCase()}flagg-checkbox`}
                      type="checkbox"
                      className="ods-form-check-input"
                      checked={checked(f.type)}
                      onChange={() => handleCheckboxChange(f.id, f.type)}
                    />
                    <div>{getFlaggIkone(f.type)}</div>
                    <div>{FlaggtypeMapper[f.type].statusnavn}</div>
                  </div>
                </label>
              </div>
            );
          })}
        </div>
        <div className="flex flex-row flex-wrap gap-5">
          <button className="btn btn--secondary" onClick={close}>
            Avbryt
          </button>
          <button
            data-testid="fjern-flagg-bekreft"
            className="btn btn--primary"
            disabled={bekreftDisabled}
            onClick={handleFjernFlagg}
          >
            Bekreft
          </button>
        </div>
      </>
    </>
  );
};
