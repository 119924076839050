import React from "react";
import {
  AvstemmingForEkspeditorViewModel,
  InntektViewModel
} from "../../../api/interfaces/Avstemming";
import { formaterNullableBelop } from "../../../utils/formatUtils";

interface ArkivAvstemmingDetaljvisningTabellProps {
  ekspeditorbetalinger: AvstemmingForEkspeditorViewModel[];
  inntektSystem?: InntektViewModel;
  inntektKasse?: InntektViewModel;
  inntektDifferanse?: InntektViewModel;
  erRegneark?: boolean;
}

export const ArkivAvstemmingDetaljvisningTabell = (
  props: ArkivAvstemmingDetaljvisningTabellProps
) => {
  const {
    ekspeditorbetalinger,
    inntektSystem,
    inntektKasse,
    inntektDifferanse,
    erRegneark
  } = props;

  return (
    <table
      id={erRegneark ? "avstemming" : undefined}
      className="tbl mb-10 mt-5"
    >
      <thead>
        <tr>
          <th title="Betalingsnummer">Betalingsnr</th>
          <th title="Registreringsnummer eller journalnummer">ID</th>
          <th title="Tidspunkt for betaling">Tidspunkt</th>
          <th>Bym-ID</th>
          <th title="Inntauingskostnad">Innt.kostnad</th>
          <th title="Administrasjonsgebyr">Admin.gebyr</th>
          <th>Døgnleie</th>
          <th title="Ileggelsesgebyr">Ileggelse</th>
          <th>Betalt</th>
        </tr>
      </thead>
      <tbody>
        {ekspeditorbetalinger.map(ekspeditorBetaling => (
          <React.Fragment key={ekspeditorBetaling.bymId}>
            {ekspeditorBetaling.betalinger.map(betaling => (
              <tr
                key={betaling.betalingsnummer}
                data-testid={`kassaavstemming-ekspeditorbetaling-${betaling.kjennemerke}`}
              >
                <td>{betaling.betalingsnummer}</td>
                <td>{betaling.kjennemerke}</td>
                <td>
                  {new Date(betaling.betalingstidspunkt).toLocaleString()}
                </td>
                <td>{betaling.utfortAv}</td>
                <td>
                  {formaterNullableBelop(
                    betaling.betalteGebyrer.inntauingsgebyr
                  )}
                </td>
                <td>
                  {formaterNullableBelop(
                    betaling.betalteGebyrer.administrasjonsgebyr
                  )}
                </td>
                <td>
                  {betaling.dognleierabatt > 0 ? (
                    <>
                      <div className="text-errorred line-through">
                        {formaterNullableBelop(
                          betaling.betalteGebyrer.dognleie +
                            betaling.dognleierabatt
                        )}
                      </div>
                      <div>
                        {formaterNullableBelop(
                          betaling.betalteGebyrer.dognleie
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {formaterNullableBelop(betaling.betalteGebyrer.dognleie)}
                    </>
                  )}
                </td>
                <td>
                  {formaterNullableBelop(
                    betaling.betalteGebyrer.ileggelsesgebyr +
                      betaling.betalteGebyrer.ileggelsetilleggsgebyr
                  )}
                </td>
                <td>{formaterNullableBelop(betaling.totalsum)}</td>
              </tr>
            ))}
            <tr className="tbl-row-border">
              <td />
              <td />
              {erRegneark && <td />}
              <td
                colSpan={erRegneark ? 1 : 2}
              >{`Sum ${ekspeditorBetaling.bymId} (${ekspeditorBetaling.betalinger.length} stk):`}</td>
              <td>
                {formaterNullableBelop(
                  ekspeditorBetaling.betalinger
                    .map(i => i.betalteGebyrer.inntauingsgebyr)
                    .reduce((prev, curr) => prev + curr, 0)
                )}
              </td>
              <td>
                {formaterNullableBelop(
                  ekspeditorBetaling.betalinger
                    .map(i => i.betalteGebyrer.administrasjonsgebyr)
                    .reduce((prev, curr) => prev + curr, 0)
                )}
              </td>
              <td>
                {formaterNullableBelop(
                  ekspeditorBetaling.betalinger
                    .map(i => i.betalteGebyrer.dognleie)
                    .reduce((prev, curr) => prev + curr, 0)
                )}
              </td>
              <td>
                {formaterNullableBelop(
                  ekspeditorBetaling.betalinger
                    .map(
                      i =>
                        i.betalteGebyrer.ileggelsesgebyr +
                        i.betalteGebyrer.ileggelsetilleggsgebyr
                    )
                    .reduce((prev, curr) => prev + curr, 0)
                )}
              </td>
              <td>{formaterNullableBelop(ekspeditorBetaling.totalsum)}</td>
            </tr>
            <tr className="h-4" />
          </React.Fragment>
        ))}
        <tr className="tbl-row-border">
          <td />
          <td />
          {erRegneark && <td />}
          <td colSpan={erRegneark ? 1 : 2}>Totalsum inntauingsystem:</td>
          <td>{formaterNullableBelop(inntektSystem?.inntauing)}</td>
          <td>{formaterNullableBelop(inntektSystem?.administrasjon)}</td>
          <td>{formaterNullableBelop(inntektSystem?.dognleie)}</td>
          <td>{formaterNullableBelop(inntektSystem?.ileggelse)}</td>
          <td>{formaterNullableBelop(inntektSystem?.total)}</td>
        </tr>
        <tr className="h-4" />
        <tr className="tbl-row-border">
          <td />
          <td />
          {erRegneark && <td />}
          <td colSpan={erRegneark ? 1 : 2}>Totalsum kasse/agresso:</td>
          <td>{formaterNullableBelop(inntektKasse?.inntauing)}</td>
          <td>{formaterNullableBelop(inntektKasse?.administrasjon)}</td>
          <td>{formaterNullableBelop(inntektKasse?.dognleie)}</td>
          <td>{formaterNullableBelop(inntektKasse?.ileggelse)}</td>
          <td>{formaterNullableBelop(inntektKasse?.total)}</td>
        </tr>
        <tr className="h-4" />
        <tr className="tbl-row-border">
          <td />
          <td />
          {erRegneark && <td />}
          <td colSpan={erRegneark ? 1 : 2}>Totalsum differanse:</td>
          <td>{formaterNullableBelop(inntektDifferanse?.inntauing)}</td>
          <td>{formaterNullableBelop(inntektDifferanse?.administrasjon)}</td>
          <td>{formaterNullableBelop(inntektDifferanse?.dognleie)}</td>
          <td>{formaterNullableBelop(inntektDifferanse?.ileggelse)}</td>
          <td>{formaterNullableBelop(inntektDifferanse?.total)}</td>
        </tr>
      </tbody>
    </table>
  );
};
