import { useAngreOpprettVrak, useSendTilVraking } from "../../hooks/useVrak";
import { ReactComponent as Print } from "../../assets/icons/print.svg";
import { ReactComponent as Slett } from "../../assets/icons/slett.svg";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useToast } from "../common/toast/ToastProvider";
import { Inntauingsjournal } from "../inntauingsjournal/Inntauingsjournal";
import { KolonneNasjonskodeTd } from "../common/table/KolonneNasjonskode";
import { formaterSisteTakstDato } from "../taksering/takseringUtils";
import { isSaksbehandler } from "../../utils/userRolesUtils";
import { HeadingUtskrift } from "../common/typography/HeadingUtskrift";

interface TilVrakTabellProps {
  vrakliste?: InntauingViewModel[];
}

export const TilVrakTabell = (props: TilVrakTabellProps) => {
  const { vrakliste } = props;

  const [valgteIder, setValgteIder] = useState<string[]>([]);

  const putSendTilVrak = useSendTilVraking();
  const deleteAngreOpprettVrak = useAngreOpprettVrak();

  const componentPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    pageStyle: "m-5"
  });
  const { addToast } = useToast();

  const handleAngreOpprettVrak = () => {
    deleteAngreOpprettVrak.mutateAsync(
      {
        inntauingsIder: valgteIder
      },
      {
        onSuccess: response => {
          setValgteIder([]);
          if ((response.data.inntauingerIkkeFunnet?.length ?? 0) > 0) {
            const msg = `Angret klargjøring av vraking for ${
              response.data.inntauingerFunnet?.length ?? 0
            } kjøretøy, kunne ikke angre klargjøring av vraking for ${
              response.data.inntauingerIkkeFunnet?.length ?? 0
            } kjøretøy`;
            addToast(msg, "error");
          } else {
            const msg = `Angret klargjøring av vraking for ${
              response.data.inntauingerFunnet?.length ?? 0
            } kjøretøy`;
            addToast(msg);
          }
        },
        onError: () => {
          setValgteIder([]);
          addToast(
            "En feil skjedde ved angring av klargjøring av vraking av kjøretøy",
            "error"
          );
        }
      }
    );
  };

  const handleSendTilVraking = () => {
    putSendTilVrak.mutateAsync(
      {
        inntauingsIder: valgteIder
      },
      {
        onSuccess: response => {
          setValgteIder([]);
          if ((response.data.inntauingerIkkeFunnet?.length ?? 0) > 0) {
            const msg = `${
              response.data.inntauingerFunnet?.length ?? 0
            } kjøretøy sendt til vraking, ${
              response.data.inntauingerIkkeFunnet?.length ?? 0
            } kjøretøy kunne ikke sendes til vraking`;
            addToast(msg, "error");
          } else {
            const msg = `${
              response.data.inntauingerFunnet?.length ?? 0
            } kjøretøy sendt til vraking`;

            addToast(msg);
          }
        },
        onError: () => {
          setValgteIder([]);
          addToast("En feil skjedde ved vraking av kjøretøy", "error");
        }
      }
    );
  };

  const handleCheck = (id: string) => {
    if (valgteIder.some(i => i === id))
      setValgteIder(prev => prev.filter(i => i !== id));
    else setValgteIder(prev => [...prev, id]);
  };

  const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) =>
    e.currentTarget.checked && vrakliste
      ? setValgteIder(vrakliste.map(i => i.id))
      : setValgteIder([]);

  if (!vrakliste) return null;

  return (
    <>
      <table className="tbl">
        <thead>
          <tr>
            <th>
              <input
                title="Velg alle"
                type="checkbox"
                className="ods-form-check-input"
                checked={
                  vrakliste.length === valgteIder.length &&
                  valgteIder.length > 0
                }
                onChange={handleCheckBox}
              />
            </th>
            <th>Rad</th>
            <th>Nasjon</th>
            <th>ID</th>
            <th>Type</th>
            <th>Merke</th>
            <th>Siste takstdato</th>
          </tr>
        </thead>
        <tbody>
          {vrakliste.map(i => (
            <tr key={i.id}>
              <td>
                <input
                  data-testid={`til-vrak-checkbox-${i.kjoretoy.registreringsnummer}`}
                  type="checkbox"
                  className="ods-form-check-input"
                  checked={valgteIder.includes(i.id)}
                  onChange={() => handleCheck(i.id)}
                />
              </td>
              <td>{i.kjoretoyleveranse?.plassering}</td>
              <KolonneNasjonskodeTd nasjonskode={i.kjoretoy.nasjonalitet} />
              <td>
                {i.kjoretoyleveranse?.journalnummer
                  ? "#" + i.kjoretoyleveranse?.journalnummer
                  : i.kjoretoy.registreringsnummer}
              </td>
              <td>{i.kjoretoy.kjoretoystype.kode}</td>
              <td>{i.kjoretoy.merke}</td>
              <td>{formaterSisteTakstDato(i.takseringer)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex flex-row flex-wrap mt-5">
        {isSaksbehandler() && (
          <button
            className="btn btn--flat btn--icon-left mr-3"
            disabled={valgteIder.length === 0}
            onClick={handleAngreOpprettVrak}
          >
            <Slett className="fill-current" />
            <span>Kanseller vraking</span>
          </button>
        )}
        <button
          className="btn btn--primary-confirm btn--icon-left mr-3"
          disabled={valgteIder.length === 0}
          onClick={handlePrint}
        >
          <Print className="fill-current" />
          <span>Skriv ut valgte</span>
        </button>
        {isSaksbehandler() && (
          <button
            data-testid="bekreft-sendt-til-vrak"
            className="btn btn--primary mr-3"
            disabled={valgteIder.length === 0}
            onClick={handleSendTilVraking}
          >
            Bekreft sendt til vrak
          </button>
        )}
      </div>
      <div className="hidden">
        <div ref={componentPrintRef}>
          <HeadingUtskrift>Liste over kjøretøy</HeadingUtskrift>
          <table className="tbl">
            <thead>
              <tr>
                <th>Rad</th>
                <th>Nasjonskode</th>
                <th>Regnr</th>
                <th>Journalnr</th>
                <th>Type</th>
                <th>Merke</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {vrakliste
                .filter(i => valgteIder.includes(i.id))
                .map(i => (
                  <tr key={i.id}>
                    <td>{i.kjoretoyleveranse?.plassering}</td>
                    <td>{i.kjoretoy.nasjonalitet}</td>
                    <td>{i.kjoretoy.registreringsnummer}</td>
                    <td>{i.kjoretoyleveranse?.journalnummer}</td>
                    <td>{i.kjoretoy.kjoretoystype.kode}</td>
                    <td>{i.kjoretoy.merke}</td>
                    <td>
                      <input type="checkbox" className="ods-form-check-input" />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {vrakliste
            .filter(i => valgteIder.includes(i.id))
            .map(i => (
              <Inntauingsjournal key={i.id} inntauing={i} />
            ))}
        </div>
      </div>
    </>
  );
};
