import { AxiosResponse } from "axios";
import { apiClient } from "../client";
import {
  InntauingsIdViewModel,
  InntauingViewModel
} from "../interfaces/Inntauing";
import { InntauingBulkRequestResponseModel } from "../interfaces/InntauingBulkRequestResponseModel";
import { PaginationViewModel } from "../interfaces/PaginationViewModel";
import { config } from "./Config";
import { queryDate } from "./serviceUtils";

const client = apiClient();

export const getTilVrak = () =>
  client.get<InntauingViewModel[]>("vrak/klare", config());

export const getSendtTilVrak = () =>
  client.get<InntauingViewModel[]>("vrak/sendt", config());

export const getArkiverteVrak = (
  cursor: number,
  pageSize: number,
  fraDato?: Date,
  tilDato?: Date
) =>
  client.get<PaginationViewModel<InntauingViewModel>>(
    `vrak/vraket?page=${cursor}&pageSize=${pageSize}` +
      queryDate("fra", fraDato) +
      queryDate("til", tilDato),
    config()
  );

export const putSendTilVraking = (inntauingsIder: string[]) =>
  client.put<
    InntauingsIdViewModel[],
    AxiosResponse<InntauingBulkRequestResponseModel>
  >(
    "vrak",
    inntauingsIder.map(i => {
      return { id: i };
    }),
    config()
  );

export const putKlargjorVrak = (inntauingsId: string) =>
  client.put("vrak/klare", { id: inntauingsId }, config());

export const deleteTilVrak = (inntauingsIder: string[]) =>
  client.delete<
    InntauingsIdViewModel[],
    AxiosResponse<InntauingBulkRequestResponseModel>
  >("vrak", {
    ...config(),
    data: inntauingsIder.map(i => {
      return { id: i };
    })
  });

export const putBekreftVraking = (
  inntauingsId: string,
  registrerVraking: FormData
) => client.put(`vrak/${inntauingsId}/vraket`, registrerVraking, config());

export const putAngreSendtTilVraking = (inntauingsId: string) =>
  client.put(`vrak/sendttilvrak/${inntauingsId}/angre`, undefined, config());

export const putKunngjorVrak = (inntauingsIder: string[]) =>
  client.put<
    InntauingsIdViewModel[],
    AxiosResponse<InntauingBulkRequestResponseModel>
  >(
    `vrak/kunngjor?opprettetKunngjoringTidspunkt=${new Date().toISOString()}`,
    inntauingsIder.map(i => {
      return { id: i };
    }),
    config()
  );
