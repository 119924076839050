import React, { useCallback } from "react";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { Toggle } from "../common/form/Toggle";
import { ReactComponent as File } from "../../assets/icons/file.svg";
import { Taksering } from "./TilTakstTabell";
import { harVraking } from "./takseringUtils";
import { isSaksbehandler } from "../../utils/userRolesUtils";

interface TakseringTabellRadProps {
  inntauing: InntauingViewModel;
  selectedInntauinger: InntauingViewModel[];
  index: number;
  takseringer: Taksering[];
  takseringstidspunkt: Date;
  fileInput: React.MutableRefObject<(HTMLInputElement | null)[]>;
  buttonRef: React.MutableRefObject<(HTMLButtonElement | null)[]>;
  toggleInntauingerToPrint: (inntauing: InntauingViewModel) => void;
  selectFile: (id: string) => void;
  changeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  removeFile: (id: string) => void;
  handleEnter: (id: string) => void;
  handleExit: (id: string) => void;
  handleDrop: (
    e: React.DragEvent<HTMLDivElement>,
    inntauingsId: string
  ) => void;
  opprettVrak: (e: boolean, id: string) => void;
  arkiver: (e: any, inntauingsId: string) => void;
}

export const TakseringTabellRad = (props: TakseringTabellRadProps) => {
  const {
    inntauing,
    selectedInntauinger,
    index,
    takseringer,
    takseringstidspunkt,
    fileInput,
    buttonRef,
    toggleInntauingerToPrint,
    selectFile,
    changeFile,
    removeFile,
    handleEnter,
    handleExit,
    handleDrop,
    opprettVrak,
    arkiver
  } = props;

  const handleToggleToPrint = () => toggleInntauingerToPrint(inntauing);

  const handleStopReload = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSelectFile = () => selectFile(inntauing.id);

  const handleRemoveFile = () => removeFile(inntauing.id);

  const handleEnterDropzone = () => handleEnter(inntauing.id);

  const handleExitDropzone = () => handleExit(inntauing.id);

  const handleDropFile = (e: React.DragEvent<HTMLDivElement>) =>
    handleDrop(e, inntauing.id);

  const handleOpprettVrak = useCallback(
    (e: boolean) => opprettVrak(e, inntauing.id),
    [inntauing.id, opprettVrak]
  );

  const handleArkiver = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    arkiver(e, inntauing.id);

  return (
    <tr key={inntauing.id} className="h-10">
      <td>
        <input
          data-testid={`checkbox-taksering-${inntauing.kjoretoy.registreringsnummer}`}
          className="ods-form-check-input"
          type="checkbox"
          checked={selectedInntauinger.some(i => i === inntauing)}
          onChange={handleToggleToPrint}
        />
      </td>
      <td>
        {inntauing.kjoretoyleveranse?.leveringstidspunkt
          ? new Date(
              inntauing.kjoretoyleveranse.leveringstidspunkt
            ).toLocaleDateString()
          : ""}
      </td>
      <td>{inntauing.kjoretoyleveranse?.plassering}</td>
      <td>{inntauing.kjoretoy.nasjonalitet}</td>
      <td data-testid={`idnummer-${inntauing.kjoretoy.registreringsnummer}`}>
        {inntauing.kjoretoyleveranse?.journalnummer
          ? "#" + inntauing.kjoretoyleveranse?.journalnummer
          : inntauing.kjoretoy.registreringsnummer}
      </td>
      <td>{inntauing.kjoretoy.kjoretoystype.kode}</td>
      <td>{inntauing.kjoretoy.merke}</td>
      <td>
        {takseringer.some(t => {
          return t.inntauingId === inntauing.id;
        }) ? (
          <div className="flex">
            <input
              hidden
              id={inntauing.id}
              ref={el => (fileInput.current[index] = el)}
              type="file"
              onChange={changeFile}
            />
            <div
              className="underline text-hoverblue cursor-pointer col-span-2"
              onClick={handleSelectFile}
            >
              {
                takseringer.find(t => {
                  return t.inntauingId === inntauing.id;
                })?.fil?.name
              }
            </div>
            <div
              className="cursor-pointer underline ml-4 "
              onClick={handleRemoveFile}
            >
              Slett
            </div>
          </div>
        ) : (
          <div
            onDragEnter={handleEnterDropzone}
            onDragExit={handleExitDropzone}
            onDragOver={handleStopReload}
            onDrop={handleDropFile}
          >
            <input
              hidden
              id={inntauing.id}
              ref={el => (fileInput.current[index] = el)}
              type="file"
              onChange={changeFile}
            />
            <button
              id={inntauing.id}
              ref={el => (buttonRef.current[index] = el)}
              className="btn btn--tertiary btn--icon-left btn--small border-dotted border-4"
              onClick={handleSelectFile}
              data-testid={`taksering-opplast-${inntauing.kjoretoy.registreringsnummer}`}
            >
              <File className="fill-current" />
              <span>Velg fil eller dra fil</span>
            </button>
          </div>
        )}
      </td>
      <td>
        <Toggle
          id="opprettVrakToggle"
          leftText="Ja"
          rightText="Nei"
          toggleFunction={handleOpprettVrak}
          title={
            harVraking(inntauing)
              ? "Vraking allerede opprettet"
              : "Opprett vraking"
          }
          disabled={harVraking(inntauing)}
          value={harVraking(inntauing) ? true : undefined}
        />
      </td>
      <td>
        {isSaksbehandler() && <button
          data-testid={`taksering-button-${inntauing.kjoretoy.registreringsnummer}`}
          className="btn btn--primary btn--small my-1 mr-5"
          onClick={handleArkiver}
          disabled={
            takseringstidspunkt === null ||
            takseringer.find(t => t.inntauingId === inntauing.id)?.fil == null
          }
        >
          Registrer takst
        </button>
      }
      </td>
    </tr>
  );
};
