import { PropsWithChildren } from "react";

type TilgangProps = PropsWithChildren<{
  harTilgang: boolean;
}>;

export const Tilgang = (props: TilgangProps) => {
  const { harTilgang, children } = props;

  return harTilgang ? <>{children}</> : null;
};
