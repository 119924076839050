import { AxiosResponse } from "axios";
import { apiClient } from "../client";
import {
  HentingViewModel,
  InntauingViewModel,
  NyHentingInputModel
} from "../interfaces/Inntauing";
import { config } from "./Config";

const client = apiClient();

export const getHentedeInntauingerUtenBetalinger = (
  fraDato: Date,
  tilDato: Date
) =>
  client.get<InntauingViewModel[]>(
    `henting/utenbetalinger?fra=${fraDato.toISOString()}&til=${tilDato.toISOString()}`,
    config()
  );

export const postStatusHentet = (
  inntauingId: string,
  hentet: NyHentingInputModel
) =>
  client.post<NyHentingInputModel, AxiosResponse<HentingViewModel>>(
    `henting/${inntauingId}/hent`,
    hentet,
    config()
  );

export const putAvbrytHenting = (inntauingId: string) =>
  client.put<AxiosResponse>(
    `henting/${inntauingId}/avbrythenting`,
    undefined,
    config()
  );
