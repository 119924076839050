import { getHostFromEnv } from "../../api/client";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { formaterNullableBelop } from "../../utils/formatUtils";

interface ArkiverteVrakTabellUtskriftProps {
  vrakliste?: InntauingViewModel[];
}

export const ArkiverteVrakTabellUtskrift = (
  props: ArkiverteVrakTabellUtskriftProps
) => {
  const { vrakliste } = props;

  if (!vrakliste) return null;

  return (
    <table className="tbl">
      <thead>
        <tr>
          <th>Nasjon</th>
          <th>Regnr</th>
          <th>Merke</th>
          <th>Modell</th>
          <th>Vrakdato</th>
          <th>Salgssum</th>
          <th>Vrakpant</th>
          <th>Websak</th>
        </tr>
      </thead>
      <tbody>
        {vrakliste.map(i => (
          <tr key={i.id}>
            <td>{i.kjoretoy.nasjonalitet}</td>
            <td>{i.kjoretoy.registreringsnummer}</td>
            <td>{i.kjoretoy.merke}</td>
            <td>{i.kjoretoy.modell}</td>
            <td>
              {i.vraking?.vrakdato
                ? new Date(i.vraking.vrakdato.toString()).toLocaleDateString()
                : "Mangler dato"}
            </td>
            <td>{formaterNullableBelop(i.vraking?.inntektSkrapsalg)}</td>
            <td>{formaterNullableBelop(i.vraking?.vrakpant)}</td>
            <td className="underline">
              <a
                rel="noreferrer"
                target="_blank"
                href={`${getHostFromEnv("WEBSAK")}/${i.arkivReferanseId}`}
              >
                {i.arkivReferansenummer}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
