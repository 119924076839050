import { useEffect, useState } from "react";
import Table, { Rad } from "../common/table/Table";
import { CustomError } from "../common/typography/CustomError";
import { useFlyttetPaGategrunn } from "../../hooks/useInntauinger";
import { PocketControl } from "../pocketControl/PocketControl";
import { EierOgKjoretoysinformasjon } from "../eierOgKjoretoysinformasjon/EierOgKjoretoysinformasjon";
import { BREAKPOINTS } from "../../styles/sizeVariables";
import { HeadingView } from "../common/typography/HeadingView";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { Handlingsknapp } from "../common/handlingsknapp/Handlingsknapp";
import { Inntauingsinformasjon } from "../inntauingsinformasjon/Inntauingsinformasjon";
import { RedigerEierOgKjoretoyinfo } from "../redigerEierOgKjoretoyinfo/RedigerEierOgKjoretoyinfo";
import { Ileggelsesinformasjon } from "../ileggelsesinformasjon/Ileggelsesinformasjon";
import Notat from "../handlingslogg/Handlingslogg";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { Filter } from "../../api/interfaces/Filter";
import { InntauingFilter } from "../filter/InntauingFilter";
import { InntauingFilterKnapp } from "../filter/InntauingFilterKnapp";
import {
  isEkspeditor,
  isInnsyn,
  isSaksbehandler,
  isUtvikler
} from "../../utils/userRolesUtils";
import { InntauingChipFilter } from "../filter/InntauingChipFilter";
import { BetalingskravStatusMedFarger } from "../common/card/BetalingskravStatusMedFarger";
import { Betaling } from "../betaling/Betaling";
import { Brevsporing } from "../brevsporing/Brevsporing";

const initSortKey = "bestillingsTidspunkt";
const initSortDirection = "desc";

export interface FlyttetPaGategrunnTabellProps {
  setVisHandling: (inntauing?: InntauingViewModel, handling?: string) => void;
}

const isTablet =
  window.innerWidth < parseInt(BREAKPOINTS.tabletBig.replace("px", ""));

export const FlyttetPaGategrunnTabell = (
  props: FlyttetPaGategrunnTabellProps
) => {
  const { setVisHandling } = props;

  const [visMeny, setVisMeny] = useState<string>();
  const [inntauingToEdit, setInntauingToEdit] = useState<string>();
  const [sortKey, setSortKey] = useState<string>(initSortKey);
  const [sortDirection, setSortDirection] = useState<"desc" | "asc">(
    initSortDirection
  );
  const [visFilterIDom, setVisFilterIDom] = useState<boolean>(false);
  const [filter, setFilter] = useState<Filter>({
    betalingsmetoder: [],
    brevtyper: [],
    datoFra: null,
    datoTil: null,
    flyttetTil: "",
    inntauetFra: "",
    sok: "",
    statuskoder: [],
    betalingskravStatuser: [],
    kjoretoystyper: []
  });

  const {
    data,
    isLoading,
    error,
    isError,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useFlyttetPaGategrunn(sortKey, sortDirection, filter);

  useEffect(() => {
    const preventValidationBubble = (event: Event) => event.preventDefault();
    document.addEventListener("invalid", preventValidationBubble, true);
    return () =>
      document.removeEventListener("invalid", preventValidationBubble, true);
  }, []);

  const onSorterClick = (
    kolonneId: string | undefined,
    retning: "desc" | "asc"
  ) => {
    setSortKey(kolonneId ?? "");
    setSortDirection(retning);
  };

  const handleLukkRedigering = () => setInntauingToEdit(undefined);

  if (isError) return <CustomError error={(error as Error).message} />;

  const header: Rad = {
    id: "header_flyttet_pa_gategrunn",
    kolonner: isTablet
      ? [
          {
            id: "bestillingsTidspunkt",
            verdi: <span>Flyttet tidspunkt</span>
          },
          {
            id: "inntauetFra",
            verdi: <span>Fra gate</span>,
            isSortable: false
          },
          {
            id: "inntauetTil",
            verdi: <span>Til gate</span>,
            isSortable: false
          },
          {
            id: "id",
            verdi: <span>ID</span>,
            isSortable: false
          },
          {
            id: "betalingskrav",
            verdi: <span>Betalingskrav</span>,
            isSortable: false
          },
          { isSortable: false }
        ]
      : [
          {
            id: "bestillingsTidspunkt",
            verdi: <span>Flyttet tidspunkt</span>
          },
          {
            id: "inntauetFra",
            verdi: <span>Fra gate</span>,
            isSortable: false
          },
          {
            id: "inntauetTil",
            verdi: <span>Til gate</span>,
            isSortable: false
          },
          {
            id: "id",
            verdi: <span>ID</span>,
            isSortable: false
          },
          {
            id: "merke",
            verdi: <span>Merke</span>
          },
          {
            id: "modell",
            verdi: <span>Modell</span>
          },
          {
            id: "kjoretoystype",
            verdi: <span>Type</span>
          },
          {
            id: "betalingskrav",
            verdi: <span>Betalingskrav</span>,
            isSortable: false
          },
          { isSortable: false }
        ]
  };

  const rader: Rad[] =
    data?.pages
      .flatMap(x => x.data)
      .map<Rad>(i => {
        return {
          id: i.id,
          cssClassDetaljer: "bg-gray-100",
          detaljer:
            inntauingToEdit !== i.id
              ? [
                  <div
                    key={i.id}
                    className="grid laptop:grid-cols-4 grid-cols-12 gap-2"
                  >
                    <div className="bg-white col-span-6 laptop:col-span-4 shadow-md">
                      <EierOgKjoretoysinformasjon
                        inntauingsId={i.id}
                        kjoretoy={i.kjoretoy}
                        hasKjoretoyleveranse={!!i.kjoretoyleveranse}
                        setInntauingToEdit={setInntauingToEdit}
                        isEditable={true}
                      />
                    </div>
                    <div className="col-span-3 laptop:col-span-2 shadow-md">
                      <Inntauingsinformasjon inntauing={i} isEditable={true} />
                    </div>
                    <div className="col-span-3 laptop:col-span-2 shadow-md">
                      <PocketControl inntauing={i} />
                    </div>
                    <div className="col-span-3 laptop:col-span-2 grid grid-rows-2 gap-2">
                      <div className="row-span-1 shadow-md">
                        <Betaling inntauing={i} />
                      </div>
                      <div className="row-span-1 shadow-md">
                        <Ileggelsesinformasjon inntauing={i} />
                      </div>
                    </div>
                    <div className="col-span-3 laptop:col-span-2">
                      <Brevsporing inntauing={i} />
                    </div>
                    <div className="col-span-6 laptop:col-span-4 shadow-md">
                      <Notat inntauingsId={i.id} />
                    </div>
                  </div>
                ]
              : [
                  <div key={i.id} className="bg-white w-full">
                    <RedigerEierOgKjoretoyinfo
                      inntauing={i}
                      handleLukkRedigering={handleLukkRedigering}
                    />
                  </div>
                ],
          kolonner: isTablet
            ? [
                {
                  verdi:
                    new Date(
                      i.inntauingsgrunnlag.bestillingstidspunkt.toString() ?? ""
                    ).toLocaleDateString() +
                    " - " +
                    new Date(
                      i.inntauingsgrunnlag.bestillingstidspunkt.toString() ?? ""
                    ).toLocaleTimeString()
                },
                {
                  verdi: i.inntauingsgrunnlag.lokasjon.gatenavn ?? ""
                },
                {
                  verdi: i.kjoretoyleveranse?.flyttetTilGatenavn ?? ""
                },
                {
                  verdi: i.kjoretoyleveranse?.journalnummer
                    ? "#" + i.kjoretoyleveranse?.journalnummer
                    : i.kjoretoy.registreringsnummer
                },
                {
                  verdi: (
                    <BetalingskravStatusMedFarger
                      status={i.betalingskrav?.status}
                    />
                  )
                },
                {
                  verdi: (
                    <Handlingsknapp
                      inntauing={i}
                      inntauingsstatus={i.status}
                      visMeny={visMeny}
                      setVisMeny={setVisMeny}
                      visHandling={setVisHandling}
                    />
                  ),
                  tittel: "Handlingsknapp"
                }
              ]
            : [
                {
                  verdi:
                    new Date(
                      i.inntauingsgrunnlag.bestillingstidspunkt.toString() ?? ""
                    ).toLocaleDateString() +
                    " - " +
                    new Date(
                      i.inntauingsgrunnlag.bestillingstidspunkt.toString() ?? ""
                    ).toLocaleTimeString()
                },
                {
                  verdi: i.inntauingsgrunnlag.lokasjon.gatenavn ?? ""
                },
                {
                  verdi: i.kjoretoyleveranse?.flyttetTilGatenavn ?? ""
                },
                {
                  verdi: i.kjoretoyleveranse?.journalnummer
                    ? "#" + i.kjoretoyleveranse?.journalnummer
                    : i.kjoretoy.registreringsnummer
                },
                {
                  verdi: i.kjoretoy.merke
                },
                {
                  verdi: i.kjoretoy.modell
                },
                {
                  verdi: i.kjoretoy.kjoretoystype.kode,
                  tittel: i.kjoretoy.kjoretoystype.navn
                },
                {
                  verdi: (
                    <BetalingskravStatusMedFarger
                      status={i.betalingskrav?.status}
                    />
                  )
                },
                {
                  verdi: (
                    <Handlingsknapp
                      inntauing={i}
                      inntauingsstatus={i.status}
                      visMeny={visMeny}
                      setVisMeny={setVisMeny}
                      visHandling={setVisHandling}
                    />
                  ),
                  tittel: "Handlingsknapp"
                }
              ]
        };
      }) ?? [];

  const getTableOrIsLoadingElement = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    } else {
      return (
        <div className="oslo-inntauing-liste">
          <Table
            header={header}
            rader={rader}
            onSorterClick={onSorterClick}
            onAccordionRowClick={() => {}}
            sortKolonne={sortKey && initSortKey}
            sortRetning={sortDirection && initSortDirection}
            onLastInnFlereClick={() => fetchNextPage()}
            disableLastInnFlere={!hasNextPage || isFetchingNextPage}
          />
        </div>
      );
    }
  };

  const antall = data?.pages.find(el => el.totalCount)?.totalCount ?? 0;

  return (
    <>
      <div className="flex flex-row justify-between">
        <HeadingView>{`Flyttet på gategrunn - ${antall} stk`}</HeadingView>
        {(isSaksbehandler() ||
          isEkspeditor() ||
          isInnsyn() ||
          isUtvikler()) && (
          <div className="mt-5">
            <InntauingFilterKnapp
              visFilterIDom={visFilterIDom}
              toggleVisFilterIDomCallback={setVisFilterIDom}
            />
          </div>
        )}
      </div>
      <section className="mb-4">
        <InntauingFilter
          visFilter={{
            sok: true,
            datoFra: true,
            datoTil: true,
            inntauetFra: true,
            betalingskravStatuser: true,
            kjoretoystyper: true
          }}
          visFilterIDom={visFilterIDom}
          setVisFilterIDom={setVisFilterIDom}
          setFilter={setFilter}
          visning="flyttet"
        />
        <InntauingChipFilter
          setFilter={setFilter}
          visChipFilterIDom={!visFilterIDom}
        />
      </section>
      {getTableOrIsLoadingElement()}
    </>
  );
};
