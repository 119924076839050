import { useLokasjoner, useOvertredelser } from "../../hooks/useGrunndata";
import { ReactComponent as Mobil } from "../../assets/icons/mobil.svg";
import { Kort } from "../common/card/Kort";
import {
  KeyValueKortInnhold,
  KeyValuePair
} from "../common/card/KeyValueKortInnhold";
import { useState } from "react";
import { PocketControlRedigering } from "./PocketControlRedigering";
import { getUsername } from "../../api/client";
import {
  HjemmelTypeMapper,
  InntauingViewModel,
  StatuskodeType
} from "../../api/interfaces/Inntauing";

interface PocketControlProps {
  inntauing: InntauingViewModel;
}

export const PocketControl = (props: PocketControlProps) => {
  const { inntauing } = props;

  const [redigering, setRedigering] = useState<boolean>(false);

  const { data: gater } = useLokasjoner();
  const { data: overtredelser } = useOvertredelser();

  const bestillingstidspunkt = new Date(
    inntauing.inntauingsgrunnlag.bestillingstidspunkt
  );
  const formatBestillingstidspunkt = bestillingstidspunkt.toLocaleString();
  const lokasjon = inntauing.inntauingsgrunnlag.lokasjon;
  const inntauingsgrunnlag = inntauing.inntauingsgrunnlag;

  const gatenavn = lokasjon.gatenavn ?? "Ukjent gate";
  const gatenummer = lokasjon.gatenummer ? lokasjon.gatenummer : "";
  const inntauingssted = gatenavn + " " + gatenummer;

  const inntauingsfirma = inntauingsgrunnlag.inntauingsfirma ?? "";
  const bybetjent = inntauingsgrunnlag.betjentId ?? "";

  const kommentar = inntauing.inntauingsgrunnlag.kommentar ?? "";
  const overtredelse = inntauing.inntauingsgrunnlag.overtredelse ?? "";

  const hjemmel = HjemmelTypeMapper[inntauingsgrunnlag.hjemmel];
  const skadenotat = inntauingsgrunnlag.skadenotat ?? "";
  const eierkontakt =
    inntauingsgrunnlag.eierkontakt == null
      ? "Nei"
      : inntauingsgrunnlag.eierkontakt ?? "Ja";

  const keyValuePairs: KeyValuePair[] = [
    { key: "Betjent", value: [bybetjent], dataTestid: "gebyrinfo-betjent" },
    {
      key: "Innt. tidspunkt",
      title: "Inntauingstidspunkt",
      value: [formatBestillingstidspunkt],
      dataTestid: "gebyrinfo-tidspunkt"
    },
    {
      key: "Inntauingssted",
      value: [inntauingssted],
      dataTestid: "gebyrinfo-sted"
    },
    { key: "Kommentar", value: [kommentar], dataTestid: "gebyrinfo-kommentar" },
    {
      key: "Inntauer",
      value: [inntauingsfirma],
      dataTestid: "gebyrinfo-firma"
    },
    { key: "Hjemmel", value: [hjemmel.navn], dataTestid: "gebyrinfo-hjemmel" },
    {
      key: "Overtredelse",
      value: [overtredelse],
      dataTestid: "gebyrinfo-overtredelse"
    },
    { key: "Skader", value: [skadenotat], dataTestid: "gebyrinfo-skadenotat" },
    {
      key: "Kontaktet eier",
      value: [eierkontakt],
      dataTestid: "gebyrinfo-eierkontakt"
    },
    {
      key: "Uten skilter",
      value: [inntauing.inntauingsgrunnlag.utenSkilter ? "Ja" : "Nei"],
      dataTestid: "gebyrinfo-utenskilt"
    }
  ];

  return (
    <Kort
      headerText="Bestillingsinfo"
      headerIcon={<Mobil className="fill-darkblue" />}
      innhold={
        redigering ? (
          <PocketControlRedigering
            avbrytRedigering={() => setRedigering(false)}
            inntauing={inntauing}
            gater={gater ?? []}
            overtredelser={overtredelser ?? []}
          />
        ) : (
          <div className="flex flex-col">
            <KeyValueKortInnhold keyValuePair={keyValuePairs} keyWidth="w-40" />
            {inntauing.historikk?.find(
              i => i.status === StatuskodeType.KranbilBestilt
            )?.betjent === getUsername() &&
              getUsername() && (
                <button
                  data-testid="redigering-gebyrinfo-redigerknapp"
                  className="btn btn--secondary mt-5 mb-2"
                  onClick={() => setRedigering(true)}
                >
                  Rediger
                </button>
              )}
          </div>
        )
      }
    />
  );
};
