import { useEffect, useState } from "react";
import Table, { Rad } from "../common/table/Table";
import { CustomError } from "../common/typography/CustomError";
import { EierOgKjoretoysinformasjon } from "../eierOgKjoretoysinformasjon/EierOgKjoretoysinformasjon";
import { Ileggelsesinformasjon } from "../ileggelsesinformasjon/Ileggelsesinformasjon";
import { BREAKPOINTS } from "../../styles/sizeVariables.js";
import { useAvsluttetInntauinger } from "../../hooks/useInntauinger";
import { PocketControl } from "../pocketControl/PocketControl";
import Handlingslogg from "../handlingslogg/Handlingslogg";
import { Handlingsknapp } from "../common/handlingsknapp/Handlingsknapp";
import { AvsluttetInntauingInntauingsInfo } from "./AvsluttetInntauingInntauingsInfo";
import { StatusMedFarger } from "../common/card/StatusMedFarger";
import { HeadingView } from "../common/typography/HeadingView";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import {
  InntauingViewModel,
  StatuskodeMapper,
  StatuskodeType
} from "../../api/interfaces/Inntauing";
import { Filter } from "../../api/interfaces/Filter";
import { InntauingFilter } from "../filter/InntauingFilter";
import {
  isEkspeditor,
  isInnsyn,
  isSaksbehandler,
  isUtvikler
} from "../../utils/userRolesUtils";
import { InntauingFilterKnapp } from "../filter/InntauingFilterKnapp";
import { InntauingChipFilter } from "../filter/InntauingChipFilter";
import { Inntekt } from "../inntekt/Inntekt";
import { Brevsporing } from "../brevsporing/Brevsporing";
import { KolonneNasjonskode } from "../common/table/KolonneNasjonskode";

const initSortKey = "bestillingsTidspunkt";
const initSortDirection = "desc";

const isTablet =
  window.innerWidth < parseInt(BREAKPOINTS.tabletBig.replace("px", ""));

export const AvsluttetInntauingerTabell = () => {
  const [visMeny, setVisMeny] = useState<string | undefined>();
  const [sortKey, setSortKey] = useState<string>(initSortKey);
  const [sortDirection, setSortDirection] = useState<"desc" | "asc">(
    initSortDirection
  );
  const [visFilterIDom, setVisFilterIDom] = useState<boolean>(false);
  const [filter, setFilter] = useState<Filter>({
    betalingsmetoder: [],
    brevtyper: [],
    datoFra: null,
    datoTil: null,
    flyttetTil: "",
    inntauetFra: "",
    sok: "",
    statuskoder: [],
    kjoretoystyper: []
  });

  const {
    data,
    isLoading,
    error,
    isError,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useAvsluttetInntauinger(sortKey, sortDirection, filter);

  useEffect(() => {
    const preventValidationBubble = (event: Event) => event.preventDefault();
    document.addEventListener("invalid", preventValidationBubble, true);
    return () =>
      document.removeEventListener("invalid", preventValidationBubble, true);
  }, []);

  const onSorterClick = (
    kolonneId: string | undefined,
    retning: "desc" | "asc"
  ) => {
    setSortKey(kolonneId ?? "");
    setSortDirection(retning);
  };

  if (isError) return <CustomError error={(error as Error).message} />;

  const header: Rad = {
    id: "header_avsluttede_inntauinger",
    kolonner: isTablet
      ? [
          {
            id: "id",
            verdi: <span>ID</span>,
            isSortable: false
          },
          {
            id: "merke",
            verdi: <span>Merke</span>
          },
          {
            id: "nasjonskode",
            verdi: <span>Nasjon</span>
          },
          {
            id: "kjoretoystype",
            verdi: <span>Type</span>
          },
          { isSortable: false }
        ]
      : [
          {
            id: "bestillingsTidspunkt",
            verdi: <span>Innt. tidspunkt</span>
          },
          {
            id: "id",
            verdi: <span>ID</span>,
            isSortable: false
          },
          {
            id: "merke",
            verdi: <span>Merke</span>
          },
          {
            id: "modell",
            verdi: <span>Modell</span>
          },
          {
            id: "nasjonskode",
            verdi: <span>Nasjon</span>
          },
          {
            id: "kjoretoystype",
            verdi: <span>Type</span>
          },
          {
            id: "status",
            verdi: <span>Status</span>
          },
          { isSortable: false }
        ]
  };

  const rader: Rad[] =
    data?.pages
      .flatMap(x => x.data)
      .map<Rad>(i => ({
        id: i.id,
        cssClassDetaljer: "bg-gray-100",
        detaljer: [
          <AvsluttetInntauingerTabellDetaljer inntauing={i} key={i.id} />
        ],
        kolonner: isTablet
          ? [
              {
                verdi: i.kjoretoyleveranse?.journalnummer
                  ? "#" + i.kjoretoyleveranse?.journalnummer
                  : i.kjoretoy.registreringsnummer
              },
              {
                verdi: i.kjoretoy.merke
              },
              {
                verdi: (
                  <KolonneNasjonskode nasjonskode={i.kjoretoy.nasjonalitet} />
                )
              },
              {
                verdi: i.kjoretoy.kjoretoystype.kode,
                tittel: i.kjoretoy.kjoretoystype.navn
              },
              {
                verdi: (
                  <Handlingsknapp
                    inntauing={i}
                    inntauingsstatus={i.status}
                    visMeny={visMeny}
                    setVisMeny={setVisMeny}
                  />
                ),
                tittel: "Handlingsknapp"
              }
            ]
          : [
              {
                verdi:
                  new Date(
                    i.inntauingsgrunnlag.bestillingstidspunkt.toString() ?? ""
                  ).toLocaleDateString() +
                  " - " +
                  new Date(
                    i.inntauingsgrunnlag.bestillingstidspunkt.toString() ?? ""
                  ).toLocaleTimeString()
              },
              {
                verdi: i.kjoretoyleveranse?.journalnummer
                  ? "#" + i.kjoretoyleveranse?.journalnummer
                  : i.kjoretoy.registreringsnummer
              },
              {
                verdi: i.kjoretoy.merke
              },
              {
                verdi: i.kjoretoy.modell
              },
              {
                verdi: (
                  <KolonneNasjonskode nasjonskode={i.kjoretoy.nasjonalitet} />
                )
              },
              {
                verdi: i.kjoretoy.kjoretoystype.kode,
                tittel: i.kjoretoy.kjoretoystype.navn
              },
              {
                verdi: <StatusMedFarger statuskode={i.status} />,
                tittel: StatuskodeMapper[i.status].navn
              },
              {
                verdi: (
                  <Handlingsknapp
                    inntauing={i}
                    inntauingsstatus={i.status}
                    visMeny={visMeny}
                    setVisMeny={setVisMeny}
                  />
                ),
                tittel: "Handlingsknapp"
              }
            ]
      })) ?? [];

  const getTableOrIsLoadingElement = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    } else {
      return (
        <div className="oslo-inntauing-liste">
          <Table
            header={header}
            rader={rader}
            onSorterClick={onSorterClick}
            onAccordionRowClick={() => {}}
            sortKolonne={sortKey && initSortKey}
            sortRetning={sortDirection && initSortDirection}
            onLastInnFlereClick={() => fetchNextPage()}
            disableLastInnFlere={!hasNextPage || isFetchingNextPage}
          />
        </div>
      );
    }
  };

  const antall = data?.pages.find(el => el.totalCount)?.totalCount ?? 0;

  return (
    <>
      <div className="flex flex-row justify-between">
        <HeadingView>{`Avsluttede Inntauinger - ${antall} stk`}</HeadingView>
        {(isSaksbehandler() ||
          isEkspeditor() ||
          isInnsyn() ||
          isUtvikler()) && (
          <div className="mt-5">
            <InntauingFilterKnapp
              visFilterIDom={visFilterIDom}
              toggleVisFilterIDomCallback={setVisFilterIDom}
            />
          </div>
        )}
      </div>
      <section className="mb-4">
        <InntauingFilter
          visFilter={{
            sok: true,
            datoFra: true,
            datoTil: true,
            inntauetFra: true,
            betalingsmetoder: true,
            statuskoder: true,
            kjoretoystyper: true,
            brevtyper: true
          }}
          visFilterIDom={visFilterIDom}
          setVisFilterIDom={setVisFilterIDom}
          setFilter={setFilter}
          visning="avsluttet"
        />
        <InntauingChipFilter
          setFilter={setFilter}
          visChipFilterIDom={!visFilterIDom}
        />
      </section>
      {getTableOrIsLoadingElement()}
    </>
  );
};

interface AvsluttetInntauingerTabellDetaljerProps {
  inntauing: InntauingViewModel;
}

export const AvsluttetInntauingerTabellDetaljer = (
  props: AvsluttetInntauingerTabellDetaljerProps
) => {
  const { inntauing } = props;

  return (
    <div className="grid laptop:grid-cols-4 grid-cols-12 gap-2">
      <div className="bg-white col-span-6 laptop:col-span-4 shadow-md">
        <EierOgKjoretoysinformasjon
          inntauingsId={inntauing.id}
          kjoretoy={inntauing.kjoretoy}
        />
      </div>
      <div className="col-span-3 laptop:col-span-2 shadow-md">
        <AvsluttetInntauingInntauingsInfo inntauing={inntauing} />
      </div>
      <div className="col-span-3 laptop:col-span-2 shadow-md">
        <PocketControl inntauing={inntauing} />
      </div>
      <div className="col-span-3 laptop:col-span-2 grid grid-rows-2 gap-2">
        {inntauing.status !== StatuskodeType.Fremmote && (
          <div className="row-span-1 shadow-md">
            <Inntekt inntauing={inntauing} />
          </div>
        )}
        <div
          className={
            inntauing.status === StatuskodeType.Fremmote
              ? "row-span-2 shadow-md"
              : "row-span-1 shadow-md"
          }
        >
          <Ileggelsesinformasjon inntauing={inntauing} />
        </div>
      </div>
      <div className="col-span-3 laptop:col-span-2">
        <Brevsporing inntauing={inntauing} />
      </div>
      <div className="col-span-6 laptop:col-span-4 shadow-md">
        <Handlingslogg inntauingsId={inntauing.id} />
      </div>
    </div>
  );
};
