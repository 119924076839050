import { getUser, isBackendProd } from "../api/client";

export enum Role {
  Saksbehandler = "inntauing-saksbehandler",
  Ekspeditor = "inntauing-ekspeditor",
  Innsyn = "inntauing-innsyn",
  Regnskap = "inntauing-regnskap",
  Utvikler = "inntauing-utvikler"
}

export const getRoles = () => (getUser()?.profile.groups as string[]) ?? [];

export const hasRole = (role: string) => {
  const user = getRoles();
  const isProd = isBackendProd();
  const roleFromLocalStorage = window.localStorage.getItem("role");

  return isProd || !roleFromLocalStorage
    ? Boolean(user.includes(role))
    : roleFromLocalStorage === role;
};

export const isSaksbehandler = () => hasRole(Role.Saksbehandler);
export const isEkspeditor = () => hasRole(Role.Ekspeditor);
export const isInnsyn = () => hasRole(Role.Innsyn);
export const isRegnskap = () => hasRole(Role.Regnskap);
export const isUtvikler = () => hasRole(Role.Utvikler);
