export const download_table_as_csv = (table_id, filnavn) => {
  let data = "sep=,\n";
  const tableData = [];
  const rows = document.querySelectorAll('table#' + table_id + ' tr');
  for (const row of rows) {
    const rowData = [];
    for (const [, column] of row.querySelectorAll("th, td").entries()) {
      rowData.push('"' + column.innerText
                                  .replaceAll("æ", "ae")
                                  .replaceAll("ø", "oe")
                                  .replaceAll("å", "aa")
                                  .replaceAll("Æ", "Ae")
                                  .replaceAll("Ø", "Oe")
                                  .replaceAll("Å", "Aa")
                                  .replaceAll(" ", "")
                                  .replaceAll(",-", "")
                       + '"');
    }
    tableData.push(rowData.join(","));
  }
  data += tableData.join("\n");
  const a = document.createElement("a");
  a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
  a.setAttribute("download", filnavn + ".csv");
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
