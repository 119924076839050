export enum KlagevedtakType {
  InfoVedtak = 1,
  Avslag = 10,
  DelvisMedhold = 30,
  Medhold = 40
}

export const KlagevedtakTypeMapper: Record<KlagevedtakType, string> = {
  [KlagevedtakType.InfoVedtak]: "Info",
  [KlagevedtakType.Avslag]: "Avslag",
  [KlagevedtakType.DelvisMedhold]: "Delvis medhold",
  [KlagevedtakType.Medhold]: "Medhold"
};

export type KlagevedtakViewModel = {
  klagevedtakId: string;
  inntauingsId: string;
  erFlytting: boolean;
  registreringsnummer?: string;
  journalpostnummer?: number;
  vedtakstidspunkt: Date;
  vedtakstype: KlagevedtakType;
  begrunnelse?: string;
  klageWebsaksystemId?: string;
  klageWebsaknummer?: string;
  klageinnmelderNavn?: string;
  klageinnmelderIdentifikator?: string;
  betaltBelop: number;
  nyttBelop: number;
  erTilbakebetalt: boolean;
  tilbakebetalingStartet?: Date;
  tilbakebetalingUtfortAv?: string;
};

export type KlagevedtakSokReponseViewModel = {
  klagevedtakInntauinger: KlagevedtakViewModel[];
  klagevedtakFlyttinger: KlagevedtakViewModel[];
};
