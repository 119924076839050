import { useParams, useNavigate } from "react-router";
import { AvstemmingViewModel } from "../../../api/interfaces/Avstemming";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right-blue-box.svg";
import { formaterNullableBelop } from "../../../utils/formatUtils";
import { ROUTES } from "../../../utils/globalConstants";

interface ArkivAvstemmingTabellProps {
  avstemminger: AvstemmingViewModel[];
}

export const ArkivAvstemmingTabell = (props: ArkivAvstemmingTabellProps) => {
  const { avstemminger } = props;

  const { tabId } = useParams();
  const navigate = useNavigate();

  return (
    <table className="tbl mb-10 mt-5">
      <thead>
        <tr>
          <th>Avstemt for</th>
          <th>Avstemmingstidspunkt</th>
          <th>Avstemt av</th>
          <th>Kasse/Agresso</th>
          <th>Inntauingsystem</th>
          <th>Differanse</th>
          <th className="sr-only">Knapp til detaljside</th>
        </tr>
      </thead>
      <tbody>
        {avstemminger.map(i => (
          <tr key={i.avstemmingsnummer}>
            <td>{new Date(i.kasseDato).toLocaleDateString()}</td>
            <td>{new Date(i.avstemmingstidspunkt).toLocaleString()}</td>
            <td>{i.avstemtAv}</td>
            <td>{formaterNullableBelop(i.kassesum)}</td>
            <td>
              {formaterNullableBelop(
                (i.ekspeditorBetalinger ?? [])
                  .map(x => x.totalsum)
                  .reduce((prev, curr) => prev + curr, 0)
              )}
            </td>
            <td>{formaterNullableBelop(i.avviksum)}</td>
            <td>
              <button
                title="Vis detaljer for kasseavstemming"
                className="btn btn--flat btn--icon-only focus:outline-2"
                onClick={() =>
                  navigate(
                    tabId === "kasseavstemminger"
                      ? `${
                          ROUTES.Regnskap
                        }/kasseavstemminger?${getSearchParamsForDetaljvisning(
                          i.sesjonsnummer.split(", ")[0],
                          new Date(i.kasseDato).toISOString()
                        )}`
                      : `${
                          ROUTES.Oppgjor
                        }/gjennomforteavstemminger?${getSearchParamsForDetaljvisning(
                          i.sesjonsnummer.split(", ")[0],
                          new Date(i.kasseDato).toISOString()
                        )}`
                  )
                }
              >
                <ArrowRight className="fill-current" />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const getSearchParamsForDetaljvisning = (
  sesjonsnummer: string,
  kassedato: string
) => `sesjonsnummer=${sesjonsnummer}&kassedato=${kassedato}`;
