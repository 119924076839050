import { Kort } from "../common/card/Kort";
import { ReactComponent as MobilIcon } from "../../assets/icons/mobil.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { useLokasjoner, useOvertredelser } from "../../hooks/useGrunndata";
import { handleNumberOrEmptyValueInput } from "../../utils/keyboardUtils";
import { SearchDropdown } from "../common/form/SearchDropdown";
import { Toggle } from "../common/form/Toggle";
import DatePicker from "../common/form/DatePicker";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { RegistrerInntauingFelter } from "./registrerInntauingUtils";
import { HjemmelType, HjemmelTypeMapper } from "../../api/interfaces/Inntauing";
import { mapEnumToNumberValues } from "../../utils/objectMapping";

export const RegistrerInntauingGebyrInfo = () => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext<RegistrerInntauingFelter>();

  const eierkontakt = useWatch({
    control,
    name: "bestillingsinfo.eierkontakt"
  });

  const { data: gater, isLoading: isLoadingGater } = useLokasjoner();
  const { data: overtredelser, isLoading: isLoadingOvertredelser } =
    useOvertredelser();

  return (
    <Kort
      headerText="Bestillingsinfo"
      headerIcon={<MobilIcon className="fill-darkblue" />}
      fullWidth={true}
      innhold={
        <div className="flex flex-wrap">
          <div className="ods-form-group mr-5 w-96">
            <label className="ods-form-label" htmlFor="betjentId">
              Betjent-ID
            </label>
            <input
              className={`ods-form-input ${
                errors.bestillingsinfo?.betjentId
                  ? "border-errorred focus:border-errorred"
                  : ""
              }`}
              id="betjentId"
              {...register("bestillingsinfo.betjentId")}
              autoComplete="off"
            />
          </div>

          <div className="ods-form-group mr-5 w-56">
            <label className="ods-form-label" htmlFor="bestillingstidspunkt">
              Bestillingstidspunkt
            </label>
            <div className="flex relative items-center justify-end focus-within:fill-hoverblue w-72">
              <Controller
                name="bestillingsinfo.bestillingstidspunkt"
                control={control}
                render={({ field, fieldState }) => (
                  <DatePicker
                    id="bestillingstidspunkt"
                    name={field.name}
                    className={`ods-form-input ${
                      fieldState.error &&
                      "border-errorred focus:border-errorred"
                    }`}
                    dateFormat="dd.MM.yyyy, HH:mm:ss"
                    showTimeInput
                    maxDate={new Date()}
                    selected={field.value}
                    onChange={field.onChange}
                    autoComplete="off"
                  />
                )}
              />
              <CalendarIcon className="pointer-events-none absolute mr-2 h-5 w-5" />
            </div>
          </div>

          <div className="ods-form-group mr-5 w-96">
            <label className="ods-form-label" htmlFor="inntauingssted">
              Inntauingssted
            </label>
            <Controller
              name="bestillingsinfo.inntauingssted"
              control={control}
              render={({ field, fieldState }) => (
                <div>
                  <SearchDropdown
                    id="inntauingssted"
                    options={gater ?? []}
                    onchange={field.onChange}
                    value={field.value}
                    isloading={isLoadingGater}
                    classname={`${
                      fieldState.error && "search-dropdown-select-error"
                    }
                    `}
                  />
                </div>
              )}
            />
          </div>

          <div className="ods-form-group mr-5 w-32">
            <label className="ods-form-label" htmlFor="gatenummer">
              Gatenr.
            </label>
            <input
              id="gatenummer"
              className={`ods-form-input ${
                errors.bestillingsinfo?.gatenummer
                  ? "border-errorred focus:border-errorred"
                  : ""
              }`}
              {...register("bestillingsinfo.gatenummer")}
              onKeyDown={handleNumberOrEmptyValueInput}
            />
          </div>

          <div className="ods-form-group mr-5 w-96">
            <label className="ods-form-label" htmlFor="inntauingsfirma">
              Inntauingsfirma
            </label>
            <input
              className={`ods-form-input ${
                errors.bestillingsinfo?.inntauingsfirma
                  ? "border-errorred focus:border-errorred"
                  : ""
              }`}
              id="inntauingsfirma"
              {...register("bestillingsinfo.inntauingsfirma")}
            />
          </div>

          <div className="ods-form-group mr-5 w-72">
            <label className="ods-form-label" htmlFor="hjemmel">
              Hjemmel
            </label>
            <select
              id="hjemmel"
              className={`ods-form-input ods-select ${
                errors.bestillingsinfo?.hjemmel
                  ? "border-errorred focus:border-errorred"
                  : ""
              }`}
              {...register("bestillingsinfo.hjemmel")}
            >
              <>
                <option value={undefined}></option>
                {mapEnumToNumberValues(HjemmelType).map(hjemmel => (
                  <option key={hjemmel} value={hjemmel as HjemmelType}>
                    {HjemmelTypeMapper[hjemmel as HjemmelType].navn}
                  </option>
                ))}
              </>
            </select>
          </div>

          <div className="ods-form-group w-full">
            <label className="ods-form-label" htmlFor="overtredelse">
              Overtredelse
            </label>
            <select
              id="overtredelse"
              disabled={isLoadingOvertredelser}
              className={`ods-form-input ods-select ${
                errors.bestillingsinfo?.overtredelse
                  ? "border-errorred focus:border-errorred"
                  : ""
              }`}
              {...register("bestillingsinfo.overtredelse")}
            >
              <>
                <option value={undefined}></option>
                {Object.entries(overtredelser ?? {}).map(overtredelse => (
                  <option key={overtredelse[0]} value={overtredelse[1]}>
                    {overtredelse[1]}
                  </option>
                ))}
              </>
            </select>
          </div>

          <div className="ods-form-group w-full">
            <label className="ods-form-label" htmlFor="kommentar">
              Kommentar
            </label>
            <textarea
              className={`ods-form-input ${
                errors.bestillingsinfo?.kommentar
                  ? "border-errorred focus:border-errorred"
                  : ""
              }`}
              id="kommentar"
              {...register("bestillingsinfo.kommentar")}
              autoComplete="off"
            />
          </div>

          <div className="ods-form-group w-full">
            <label className="ods-form-label" htmlFor="skader">
              Skader
            </label>
            <textarea
              className={`ods-form-input ${
                errors.bestillingsinfo?.skader
                  ? "border-errorred focus:border-errorred"
                  : ""
              }`}
              id="skader"
              {...register("bestillingsinfo.skader")}
              autoComplete="off"
            />
          </div>

          <div className="ods-form-group w-full">
            <label className="ods-form-label" htmlFor="utenskilt">
              Uten Fysiske Skilter
            </label>
            <Controller
              name="bestillingsinfo.utenskilt"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Toggle
                  id="utenskilt"
                  leftText="Nei"
                  rightText="Ja"
                  value={field.value}
                  toggleFunction={() => field.onChange(!field.value)}
                  {...register("bestillingsinfo.utenskilt", {
                    value: field.value
                  })}
                />
              )}
            />
          </div>

          <div className="ods-form-group w-full mb-5">
            <label className="ods-form-label" htmlFor="eierKontaktet">
              Eier kontaktet
            </label>
            <Controller
              name="bestillingsinfo.eierkontakt"
              control={control}
              render={({ field }) => (
                <Toggle
                  id="eierKontaktet"
                  leftText="Nei"
                  rightText="Ja"
                  value={field.value !== undefined}
                  toggleFunction={() =>
                    field.onChange(field.value === undefined ? "" : undefined)
                  }
                />
              )}
            />
          </div>

          {eierkontakt !== undefined && (
            <div className="ods-form-group w-full">
              <label className="ods-form-label" htmlFor="eierkontakt">
                Eierkontakt
              </label>
              <textarea
                className={`ods-form-input ${
                  errors.bestillingsinfo?.eierkontakt
                    ? "border-errorred focus:border-errorred"
                    : ""
                }`}
                id="eierkontakt"
                {...register("bestillingsinfo.eierkontakt")}
                autoComplete="off"
              />
            </div>
          )}
        </div>
      }
    />
  );
};
