import { HeadingView } from "../../common/typography/HeadingView";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-thin.svg";
import { ReactComponent as Print } from "../../../assets/icons/print.svg";
import { ReactComponent as Download } from "../../../assets/icons/download.svg";
import { EkstrainfoKort } from "../../common/card/EkstrainfoKort";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { ArkivAvstemmingDetaljvisningTabell } from "./ArkivAvstemmingDetaljvisningTabell";
import { download_table_as_csv } from "../../../utils/csvTableGenerator";
import { formaterNullableBelop } from "../../../utils/formatUtils";
import { useNavigate } from "react-router";
import { AvstemmingViewModel } from "../../../api/interfaces/Avstemming";
import { HeadingUtskrift } from "../../common/typography/HeadingUtskrift";

interface ArkivAvstemmingDetaljvisningProps {
  avstemming: AvstemmingViewModel;
}

export const ArkivAvstemmingDetaljvisning = (
  props: ArkivAvstemmingDetaljvisningProps
) => {
  const { avstemming } = props;

  const navigate = useNavigate();

  const componentPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    pageStyle: "m-5"
  });

  return (
    <div className="mb-16">
      <HeadingView>Kasseavstemming</HeadingView>
      <div className="flex flex-row flex-wrap gap-x-5">
        <button
          className="btn btn--flat btn--icon-left"
          onClick={() => navigate(-1)}
        >
          <ArrowDown className="fill-current rotate-[90deg]" />
          <span>Tilbake</span>
        </button>
        <button
          className="btn btn--primary-confirm btn--icon-left"
          onClick={handlePrint}
        >
          <Print className="fill-current" />
          <span>Skriv ut</span>
        </button>
        <button
          type="button"
          className="btn btn--primary-confirm btn--icon-left"
          onClick={() =>
            download_table_as_csv(
              "avstemming",
              `Avstemt_for_${new Date(avstemming.avstemmingstidspunkt)
                .toLocaleDateString()
                .replaceAll(".", "-")}_${new Date(
                avstemming.avstemmingstidspunkt
              ).toLocaleTimeString()}`
            )
          }
        >
          <Download className="fill-current" />
          <span>Last ned</span>
        </button>
      </div>
      <Detaljer avstemming={avstemming} />
      <div className="flex flex-row flex-wrap">
        <EkstrainfoKort
          tittel="Kasse/Agresso"
          verdi={formaterNullableBelop(avstemming.kassesum)}
        />
        <EkstrainfoKort
          tittel="Inntauingssystem"
          verdi={formaterNullableBelop(avstemming.registrertSum)}
        />
        <EkstrainfoKort
          tittel="Differanse"
          verdi={formaterNullableBelop(avstemming.avviksum)}
        />
      </div>
      <ArkivAvstemmingDetaljvisningTabell
        ekspeditorbetalinger={avstemming.ekspeditorBetalinger ?? []}
        inntektSystem={avstemming.inntektSystem}
        inntektKasse={avstemming.inntektKasseapparat}
        inntektDifferanse={avstemming.inntektDifferanse}
      />
      <div className="hidden">
        <div className="text-xs" ref={componentPrintRef}>
          <HeadingUtskrift>{`Avstemt for ${new Date(
            avstemming.kasseDato
          ).toLocaleString()}`}</HeadingUtskrift>
          <Detaljer avstemming={avstemming} />
          <ArkivAvstemmingDetaljvisningTabell
            ekspeditorbetalinger={avstemming.ekspeditorBetalinger ?? []}
            inntektSystem={avstemming.inntektSystem}
            inntektKasse={avstemming.inntektKasseapparat}
            inntektDifferanse={avstemming.inntektDifferanse}
          />
        </div>
      </div>
      <div className="hidden">
        <ArkivAvstemmingDetaljvisningTabell
          ekspeditorbetalinger={avstemming.ekspeditorBetalinger ?? []}
          inntektSystem={avstemming.inntektSystem}
          inntektKasse={avstemming.inntektKasseapparat}
          inntektDifferanse={avstemming.inntektDifferanse}
          erRegneark
        />
      </div>
    </div>
  );
};

interface DetaljerProps {
  avstemming: AvstemmingViewModel;
}

const Detaljer = (props: DetaljerProps) => {
  const { avstemming } = props;

  return (
    <div className="grid grid-cols-2 gap-y-3 mt-10 mb-5 max-w-lg">
      <div
        className="font-semibold"
        title="Avstemming gjelder for betalinger fram til dette tidspunktet"
      >
        Avstemt fram til:
      </div>
      <div>{new Date(avstemming.kasseDato).toLocaleString()}</div>
      <div className="font-semibold">Avstemmingstidspunkt:</div>
      <div>{new Date(avstemming.avstemmingstidspunkt).toLocaleString()}</div>
      <div className="font-semibold">Avstemt av:</div>
      <div>{avstemming.avstemtAv}</div>
      <div className="font-semibold">Kommentar til avvik:</div>
      <div>{avstemming.avvikskommentar ?? ""}</div>
      <div className="font-semibold">Sesjonsnumre:</div>
      <div>
        {(avstemming.kasseInntekter ?? [])
          .map(ki => ki.sesjonsnummer)
          .join(", ")}
      </div>
      <div className="font-semibold">Z-rapport-numre:</div>
      <div>
        {(avstemming.kasseInntekter ?? [])
          .map(ki => ki.bilagsnummer)
          .join(", ")}
      </div>
    </div>
  );
};
