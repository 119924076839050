import { useQuery } from "react-query";
import { getKjoretoysinfo } from "../api/apiServices/KjoretoyService";
import { CACHE_KEYS } from "../utils/globalConstants";

export const useSvv = (regnr: string) =>
  useQuery(
    [CACHE_KEYS.Kjoretoysinformasjon, regnr],
    () => getKjoretoysinfo(regnr).then(r => r.data),
    {
      enabled: !!regnr,
      retry: 1
    }
  );
