import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-thin.svg";
import { Kolonne } from "./Table";

interface TableHeaderProps {
  kolonne: Kolonne;
  sortBy?: string;
  sortDirection?: string;
  onHeaderClick: (kolonneId?: string, isSortable?: boolean) => void;
  onSorterClick?: (
    kolonneId: string | undefined,
    retning: "asc" | "desc"
  ) => void;
}

export const TableHeader = (props: TableHeaderProps) => {
  const { kolonne, sortBy, sortDirection, onHeaderClick, onSorterClick } =
    props;

  const handleHeaderClick = () =>
    onHeaderClick(kolonne.id, kolonne.isSortable ?? true);

  const handleHeaderEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === "Enter")
      onHeaderClick(kolonne.id, kolonne.isSortable ?? true);
  };

  const getSortDirectionClassName = () =>
    sortBy && sortBy === kolonne.id ? sortDirection : "";

  return (
    <th
      tabIndex={kolonne.isSortable ?? true ? 0 : -1}
      className={
        (onSorterClick && kolonne.id && (kolonne.isSortable ?? true)
          ? "oslo-table-sortable-header " + getSortDirectionClassName()
          : "") +
        " " +
        (kolonne.cssClass ?? "")
      }
      onClick={handleHeaderClick}
      onKeyDown={handleHeaderEnterKey}
    >
      <div className="flex flex-row">
        {kolonne.verdi}
        {(kolonne.isSortable ?? true) && (
          <ArrowDown
            className={`oslo-table-sort-arrow ${
              sortBy && sortBy === kolonne.id ? "bg-darkblue fill-white" : ""
            } ${
              sortBy && sortBy === kolonne.id && sortDirection === "asc"
                ? "rotate-180"
                : ""
            }`}
          />
        )}
      </div>
    </th>
  );
};
