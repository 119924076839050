export interface Felter {
  erAutomatiskFakturering: boolean;
  identifikator: string;
  referanse: string;
}

export const feilLengdePaaIdentifikator = (felter: Felter) => {
  if (felter.erAutomatiskFakturering) {
    return {
      visFeil:
        felter.identifikator.length !== 9 && felter.identifikator.length !== 11,
      melding: "Må inneholde 9 eller 11 siffer"
    };
  }

  return {
    visFeil: felter.identifikator.length === 0,
    melding: "Fyll inn tekst som identifiserer mottaker"
  };
};

export const feilLengdePaaReferanse = (felt: string) => {
  return {
    visFeil: felt.length === 0 || felt.length > 200,
    melding: "Må inneholde en referanse på opptil 200 tegn"
  };
};

export const feilPaaIdentifikator = (
  valideringAktiv: boolean,
  felter: Felter
) => {
  return {
    visFeil: valideringAktiv && feilLengdePaaIdentifikator(felter).visFeil,
    melding: feilLengdePaaIdentifikator(felter).melding
  };
};

export const feilPaaReferanse = (
  valideringAktiv: boolean,
  referanse: string
) => {
  return {
    visFeil: valideringAktiv && feilLengdePaaReferanse(referanse).visFeil,
    melding: feilLengdePaaReferanse(referanse).melding
  };
};

export const visFeilPaaMinstEttFelt = (
  valideringAktiv: boolean,
  felter: Felter
) => {
  return (
    valideringAktiv &&
    (feilLengdePaaIdentifikator(felter).visFeil ||
      feilLengdePaaReferanse(felter.referanse).visFeil)
  );
};

export const formaterTekstSubmitKnapp = (erAutomatiskFakturering: boolean) =>
  erAutomatiskFakturering ? "Godkjenn faktura" : "Registrer fakturert";

export const formaterTittelIdentifikator = (erAutomatiskFakturering: boolean) =>
  erAutomatiskFakturering
    ? "Skriv inn organisasjons- eller fødselsnummer til mottaker"
    : "Skriv inn tekst som identifiserer mottaker for regnskapsavdeling";
