import { useEffect } from "react";

export const useOutsideEventListener = (
  ref: React.RefObject<HTMLElement>,
  handleOnOutsideEvent: () => void
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        ref.current && 
        !ref.current.contains(event.target as Node) && 
        event.target !== document &&
        !ref.current.contains(document.activeElement)
      ) {
        handleOnOutsideEvent()
      }
    }

      const handleFocusOutside = (event: FocusEvent): void => {
        if (ref.current && event.target !== document && !ref.current.contains(document.activeElement)) {
          handleOnOutsideEvent();
        }
      }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("focusin", handleFocusOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("focusin", handleFocusOutside)
    };
  }, [ref, handleOnOutsideEvent]);
}