import { useGetHandlingslogg } from "../../hooks/useHandlingslogg";
import { useInntauing } from "../../hooks/useInntauinger";
import { KeyValueKortInnhold } from "../common/card/KeyValueKortInnhold";
import { Kort } from "../common/card/Kort";
import { HeadingUtskrift } from "../common/typography/HeadingUtskrift";
import { EierOgKjoretoysinformasjon } from "../eierOgKjoretoysinformasjon/EierOgKjoretoysinformasjon";
import { Inntauingsinformasjon } from "../inntauingsinformasjon/Inntauingsinformasjon";
import { PocketControl } from "../pocketControl/PocketControl";
import { ReactComponent as Betaling } from "../../assets/icons/stolpemeny_faktura.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { ReactComponent as Brev } from "../../assets/icons/stolpemeny_brevutsendelse.svg";
import HandlingsloggList from "../handlingslogg/HandlingsloggList";
import { getBetalingsdata } from "../betaling/betalingUtils";
import { formaterNullableBelop } from "../../utils/formatUtils";
import {
  BrevType,
  BrevViewModel,
  PostkasseType
} from "../../api/interfaces/Inntauing";
import {
  isLessOrEq15Min,
  isLessOrEq31Days
} from "../brevsporing/brevsporingUtils";

interface InntauingUtskriftProps {
  inntauingsId: string;
}

export const InntauingUtskrift = (props: InntauingUtskriftProps) => {
  const { inntauingsId } = props;

  const { data: inntauing } = useInntauing(inntauingsId);
  const { data: handlingslogg } = useGetHandlingslogg(inntauingsId);

  const betaling = inntauing ? getBetalingsdata(inntauing) : undefined;

  return (
    <div className="break-after-page flex flex-col gap-y-5">
      <HeadingUtskrift>{`Inntauing - ${inntauing?.arkivReferansenummer}`}</HeadingUtskrift>
      {inntauing && handlingslogg && (
        <>
          <EierOgKjoretoysinformasjon
            inntauingsId={inntauingsId}
            kjoretoy={inntauing?.kjoretoy}
          />
          <Inntauingsinformasjon inntauing={inntauing} isEditable={false} />
          <PocketControl inntauing={inntauing} />
          <Kort
            headerText="Betaling"
            headerIcon={<Betaling className="fill-darkblue" />}
            innhold={
              <div className="flex flex-col">
                <KeyValueKortInnhold
                  keyWidth="w-64"
                  keyValuePair={[
                    {
                      key: "Betalt",
                      value: [formaterNullableBelop(betaling?.betalt)]
                    },
                    {
                      key: "Betalt flyttekostnad",
                      value: [
                        formaterNullableBelop(betaling?.betaltFlyttekostnad)
                      ]
                    },
                    {
                      key: "Utestående kostnad ileggelse",
                      value: [
                        formaterNullableBelop(
                          betaling?.utestaendeKostnaderIleggelsesgebyr
                        )
                      ]
                    },
                    {
                      key: "Utestående kostnad inntauing",
                      value: [
                        formaterNullableBelop(
                          betaling?.utestaendeKostnadInntauing
                        )
                      ]
                    },
                    {
                      key: "Utestående kostnad flytting",
                      value: [
                        formaterNullableBelop(betaling?.utestaendeFlyttekostnad)
                      ]
                    },
                    {
                      key: "Utestående kostnader",
                      value: [
                        formaterNullableBelop(betaling?.sumUtestaendeKostnader)
                      ]
                    }
                  ]}
                />
              </div>
            }
          />
          <Kort
            headerText="Brevsporing"
            headerIcon={<Brev className="fill-darkblue" />}
            innhold={
              <div className="flex flex-col">
                {inntauing.brevutsendinger.length > 0 ? (
                  <KeyValueKortInnhold
                    keyWidth="w-40"
                    keyValuePair={inntauing.brevutsendinger.map(brev => ({
                      key: getBrevtype(brev),
                      value: getBrevsporing(brev)
                    }))}
                  />
                ) : (
                  "Ingen brevutsendeler"
                )}
              </div>
            }
          />
          <Kort
            headerText="Handlingslogg"
            headerIcon={<User className="fill-darkblue" />}
            innhold={
              <HandlingsloggList
                handlingslogg={handlingslogg}
                disableMaxHeight
              />
            }
          />
        </>
      )}
    </div>
  );
};

const getBrevtype = (brev: BrevViewModel) => {
  switch (brev.brevtype) {
    case BrevType.MeldingOmInntauetKjoretoy:
    case BrevType.MeldingOmFlyttetKjoretoy:
      return brev.postkasse === PostkasseType.Fysisk
        ? "Fysisk varselbrev"
        : "Digitalt varselbrev";
    case BrevType.Avhendingsbrev:
      return "Rekommandert brev";
    case BrevType.BetalingskravFlytting:
      return "Betalingskrav";
    default:
      return "Ukjent brevtype";
  }
};

const getBrevsporing = (brev: BrevViewModel) => {
  let brevsporing = [];
  const postkasse = getPostkasse(brev.postkasse);

  if (brev.forespurtTidspunkt) {
    brevsporing.push(
      `Forespurt ${new Date(brev.forespurtTidspunkt).toLocaleString()}`
    );
  }

  if (brev.arkivertTidspunkt) {
    brevsporing.push(
      `Arkivert ${new Date(brev.arkivertTidspunkt).toLocaleString()}`
    );
  } else if (isLessOrEq15Min(brev.forespurtTidspunkt)) {
    brevsporing.push("Arkiverer");
  } else {
    brevsporing.push("Arkivering feilet");
  }

  if (brev.sendtTidspunkt) {
    brevsporing.push(
      `Sendt ${postkasse} ${new Date(brev.sendtTidspunkt).toLocaleString()}`
    );
  } else if (isLessOrEq15Min(brev.forespurtTidspunkt)) {
    brevsporing.push("Sender");
  } else {
    brevsporing.push("Sending feilet");
  }

  if (brev.postkasse === PostkasseType.Altinn) {
    if (brev.lestTidspunkt) {
      brevsporing.push(`Lest ${new Date(brev.lestTidspunkt).toLocaleString()}`);
    } else if (isLessOrEq31Days(brev.sendtTidspunkt)) {
      brevsporing.push("Venter på lesestatus");
    }
  }

  return brevsporing;
};

const getPostkasse = (postkasse: PostkasseType) => {
  switch (postkasse) {
    case PostkasseType.Altinn:
      return "Altinn";
    case PostkasseType.Digipost:
      return "Digipost";
    case PostkasseType.EBoks:
      return "EBoks";
    default:
      return "Ukjent";
  }
};
