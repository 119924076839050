import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSvv } from "../../hooks/useSvv";
import {
  getTextForSvvButton,
  HentFraSvvButton
} from "../common/form/SvvButton";
import { hentFeilISkjema } from "./RegistrerInntauingFeilmelding";
import { RegistrerInntauingFelter } from "./registrerInntauingUtils";

interface RegistrerInntauingSVVButtonProps {
  dataTestId?: string;
}

export const RegistrerInntauingSVVButton = (
  props: RegistrerInntauingSVVButtonProps
) => {
  const { dataTestId } = props;

  const [latestUpdatedRegnrOrRammenr, setLatestUpdatedRegnrOrRammenr] =
    useState<string | undefined>("");

  const {
    setValue,
    control,
    formState: { errors }
  } = useFormContext<RegistrerInntauingFelter>();

  const regnr = useWatch({
    control,
    name: "registreringsnummer.registreringsnummer"
  });

  const rammenr = useWatch({
    control,
    name: "registreringsnummer.rammenummer"
  });

  const nasjonalitet = useWatch({
    control,
    name: "registreringsnummer.nasjonkode"
  });

  useEffect(() => {
    setLatestUpdatedRegnrOrRammenr(regnr);
  }, [regnr]);

  useEffect(() => {
    setLatestUpdatedRegnrOrRammenr(rammenr);
  }, [rammenr]);

  const {
    data: kjoretoysinfo,
    isLoading: isLoadingKjoretoysinfo,
    isError: isErrorKjoretoysinfo,
    isIdle: isIdleKjoretoysinfo,
    error: errorKjoretoysinfo
  } = useSvv(latestUpdatedRegnrOrRammenr || "");

  const handleHentKjoretoysinfo = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (isErrorKjoretoysinfo || !kjoretoysinfo) return;
    setValue(
      "registreringsnummer",
      {
        nasjonkode: kjoretoysinfo.nasjonalitet,
        registreringsnummer: kjoretoysinfo.registreringsnummer,
        rammenummer: kjoretoysinfo.rammenummer
      },
      {
        shouldValidate: hentFeilISkjema(errors).length > 0
      }
    );
    setValue(
      "kjoretoyinfo",
      {
        merke: kjoretoysinfo.merke,
        modell: kjoretoysinfo.modell,
        type: kjoretoysinfo.kjoretoystype.kode,
        farge: kjoretoysinfo.farge,
        totalvekt: kjoretoysinfo.totalvekt,
        registrertAar: kjoretoysinfo.registrertAar
      },
      {
        shouldValidate: hentFeilISkjema(errors).length > 0
      }
    );
    setValue(
      "juridiskEier",
      kjoretoysinfo.juridiskEier
        ? {
            erBedrift: !kjoretoysinfo.juridiskEier.erPerson,
            navn: kjoretoysinfo.juridiskEier.navn,
            gateadresse: kjoretoysinfo.juridiskEier.gateadresse,
            postnummer: kjoretoysinfo.juridiskEier.postnummer,
            poststed: kjoretoysinfo.juridiskEier.poststed,
            idNummer: kjoretoysinfo.juridiskEier.idNummer,
            telefonnummer: kjoretoysinfo.juridiskEier.telefonnummer
          }
        : undefined,
      {
        shouldValidate: hentFeilISkjema(errors).length > 0
      }
    );
    setValue(
      "leasingtaker",
      kjoretoysinfo.leasingtaker
        ? {
            erBedrift: !kjoretoysinfo.leasingtaker.erPerson,
            navn: kjoretoysinfo.leasingtaker.navn,
            gateadresse: kjoretoysinfo.leasingtaker.gateadresse,
            postnummer: kjoretoysinfo.leasingtaker.postnummer,
            poststed: kjoretoysinfo.leasingtaker.poststed,
            idNummer: kjoretoysinfo.leasingtaker.idNummer,
            telefonnummer: kjoretoysinfo.leasingtaker.telefonnummer
          }
        : undefined,
      {
        shouldValidate: hentFeilISkjema(errors).length > 0
      }
    );
  };

  return (
    <HentFraSvvButton
      onClick={handleHentKjoretoysinfo}
      data-testid={dataTestId}
      isLoading={isLoadingKjoretoysinfo}
      disabled={
        isLoadingKjoretoysinfo ||
        isErrorKjoretoysinfo ||
        !kjoretoysinfo ||
        nasjonalitet !== "N"
      }
    >
      {getTextForSvvButton(
        !!kjoretoysinfo,
        nasjonalitet ?? "",
        isErrorKjoretoysinfo &&
          ((errorKjoretoysinfo as Error).message.includes("404") ||
          isIdleKjoretoysinfo
            ? true
            : false),
        isLoadingKjoretoysinfo
      )}
    </HentFraSvvButton>
  );
};
