import * as yup from "yup";

export const redigerEierOgKjoretoyinfoSchema = yup.object({
    nasjonalitet: yup.string().nullable(),
    registreringsnummer: yup.string().nullable(),
    rammenummer: yup.string().nullable(),
    type: yup.string().nullable(),
    merke: yup.string().nullable(),
    modell: yup.string().nullable(),
    registrertAar: yup.number().nullable().transform((_, val) => val ? Number(val) : null),
    totalvekt: yup.number().nullable().transform((_, val) => val ? Number(val) : null),
    farge: yup.string().nullable(),
    leaser: yup.boolean().nullable(),
    juridiskEier: yup.object().shape({
      idNummer: yup.string().nullable(),
      navn: yup.string().nullable(),
      gateadresse: yup.string().nullable(),
      poststed: yup.string().nullable(),
      postnummer: yup.string().nullable(),
      telefonnummer: yup.string().nullable(),
      bedrift: yup.boolean().nullable()
    }),
    leasingtaker: yup.object().shape({
      idNummer: yup.string().nullable(),
      navn: yup.string().nullable(),
      gateadresse: yup.string().nullable(),
      poststed: yup.string().nullable(),
      postnummer: yup.string().nullable(),
      telefonnummer: yup.string().nullable(),
      bedrift: yup.boolean().nullable()
    }).nullable().transform((_, val) => val.leaser ? val : null)
  });