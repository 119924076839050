import { PktButton } from "@oslokommune/punkt-react";
import { ReactComponent as PilHoyre } from "../../assets/icons/arrow-right.svg";

interface LastNedProps {
  visMeny: boolean;
  setVisMeny: (visMeny: boolean) => void;
}

export const LastNed = (props: LastNedProps) => {
  const { visMeny, setVisMeny } = props;

  return (
    <PktButton
      type="button"
      variant="icon-right"
      skin="secondary"
      iconName={visMeny ? "chevron-thin-up" : "chevron-thin-down"}
      onClick={() => setVisMeny(!visMeny)}
    >
      Last ned
    </PktButton>
  );
};

interface MenyElementFellesProps {
  tekst: string;
}

interface MenyElementKnappProps
  extends React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    MenyElementFellesProps {
  ikon: React.ReactNode;
}

export const MenyElementKnapp = (props: MenyElementKnappProps) => {
  const { ikon, tekst, ...rest } = props;

  return (
    <button
      {...rest}
      className="btn btn--flat btn--icon-right w-full text-left bg-rusticyellow hover:bg-white focus:bg-white active:bg-white"
    >
      {ikon}
      <span className="w-[11.5rem]">{tekst}</span>
    </button>
  );
};

interface MenyElementLenkeProps
  extends React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    MenyElementFellesProps {}

export const MenyElementLenke = (props: MenyElementLenkeProps) => {
  const { tekst, ...rest } = props;

  return (
    <a
      {...rest}
      target="_self"
      className="btn btn--flat btn--icon-right w-full text-left bg-rusticyellow hover:bg-white focus:bg-white active:bg-white"
    >
      <PilHoyre className="fill-current" />
      <span className="w-[11.5rem]">{tekst}</span>
    </a>
  );
};
