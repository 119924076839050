import { Header } from "../header/Header";
import { Stolpemeny } from "../stolpemeny/Stolpemeny";

interface LayoutProps {
  children: JSX.Element;
}

export const Layout = (props: LayoutProps) => {
  return (
    <div className="flex flex-col">
      <Header />
      <div className="flex flex-row pt-[4.3rem] tabletBig:pt-[3rem]">
        <Stolpemeny />
        <div className="w-full pl-[4.2rem]">{props.children}</div>
      </div>
    </div>
  );
};
