import { EierViewModel } from "../api/interfaces/Inntauing";

export const mapValueToName = (
  objectLiteral: { [key: string]: any },
  value?: any
): string => {
  const propName = Object.entries(objectLiteral).find(i => i[1] === value)?.[0];

  if (!propName) return "Ukjent";

  const propNameLowerCase = propName.replaceAll("_", " ").toLowerCase();

  return propNameLowerCase.charAt(0).toUpperCase() + propNameLowerCase.slice(1);
};

export const mapEnumToNumberValues = (
  object: { [key: string]: any },
  predicate?: (value: any) => unknown
) =>
  Object.values(object).filter(
    value => typeof value === "number" && (!predicate || predicate(value))
  );

export const finnEier = (
  juridiskEier?: EierViewModel,
  medeier?: EierViewModel,
  leasingtaker?: EierViewModel
): EierViewModel | undefined => {
  if (!(juridiskEier || medeier || leasingtaker)) return undefined;
  return (
    leasingtaker ??
    (!!juridiskEier?.gateadresse
      ? juridiskEier
      : {
          erPerson: juridiskEier?.erPerson ?? medeier?.erPerson ?? true,
          idNummer: juridiskEier?.idNummer,
          telefonnummer: juridiskEier?.telefonnummer,
          navn: juridiskEier?.navn,
          gateadresse: medeier?.gateadresse,
          postnummer: medeier?.postnummer,
          poststed: medeier?.poststed
        })
  );
};
