import { BrevTypeNavn } from "../../api/interfaces/Inntauing";
import { useSendteBrev } from "../../hooks/useSendteBrev";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import Table, { Rad } from "../common/table/Table";
import { CustomError } from "../common/typography/CustomError";

const initSortKey = "BrevSistSendt";
const initSortDirection = "desc";

export const BrevsporingTabell = () => {
  const { data, isLoading, isError, error } = useSendteBrev(
    initSortKey,
    initSortDirection,
    25
  );

  const header: Rad = {
    id: "header_brevsporing",
    kolonner: [
      {
        id: "brevtype",
        verdi: <span>Brevtype</span>,
        isSortable: false
      },
      {
        id: "mottakerNavn",
        verdi: <span>Navn mottaker</span>,
        isSortable: false
      },
      {
        id: "regnr",
        verdi: <span>Registreringsnummer</span>,
        isSortable: false
      },
      {
        id: "forespurtTidspunkt",
        verdi: <span>Forespurt tidspunkt</span>,
        isSortable: false
      },
      {
        id: "sendtTidspunkt",
        verdi: <span>Sendt tidspunkt</span>,
        isSortable: false
      },
      {
        id: "arkivertTidspunkt",
        verdi: <span>Arkivert tidspunkt</span>,
        isSortable: false
      }
    ]
  };
  const rader: Rad[] =
    data?.pages
      .flatMap(x => x.data)
      .map<Rad>(i => {
        const regnr = i.kjoretoy.registreringsnummer ?? "";
        const sisteBrevsporing =
          i.brevutsendinger?.[i.brevutsendinger.length - 1];
        return {
          id: i.id,
          cssClassDetaljer: "bg-gray-100",
          kolonner: [
            {
              verdi: BrevTypeNavn[sisteBrevsporing?.brevtype]
            },
            {
              verdi: sisteBrevsporing?.mottakerNavn
            },
            {
              verdi: regnr
            },
            {
              verdi: sisteBrevsporing?.forespurtTidspunkt
                ? new Date(
                    sisteBrevsporing?.forespurtTidspunkt
                  ).toLocaleString()
                : ""
            },
            {
              verdi: sisteBrevsporing?.sendtDigitaltTidspunkt
                ? new Date(
                    sisteBrevsporing?.sendtDigitaltTidspunkt
                  ).toLocaleString()
                : ""
            },
            {
              verdi: sisteBrevsporing?.arkivertTidspunkt
                ? new Date(sisteBrevsporing?.arkivertTidspunkt).toLocaleString()
                : ""
            }
          ]
        };
      }) ?? [];

  if (isLoading) return <LoadingSpinner />;

  if (isError) return <CustomError error={(error as Error).message} />;

  return (
    <div data-testid="brevsporing-tabell" className="oslo-inntauing-liste">
      <Table rader={rader} header={header} skjulLastInnFlere />
    </div>
  );
};
