import { endOfDay, startOfDay, sub } from "date-fns";
import { getHostFromEnv } from "../../api/client";
import { useInntauingerFerdigTaksert } from "../../hooks/useTakst";
import { CustomError } from "../common/typography/CustomError";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";
import { FraTilDato } from "../common/form/FraTilDato";
import { HeadingView } from "../common/typography/HeadingView";
import { useStateParams } from "../../hooks/useStateParams";

export const ArkiverteTakseringer = () => {
  const syvDagerSiden = startOfDay(sub(new Date(), { days: 7 }));
  const iDag = endOfDay(new Date());

  const [fraDato, setFraDato] = useStateParams(
    syvDagerSiden,
    "fra",
    s => s.toISOString(),
    s => new Date(s)
  );
  const [tilDato, setTilDato] = useStateParams(
    iDag,
    "til",
    s => s.toISOString(),
    s => new Date(s)
  );

  const {
    data,
    isLoading,
    error,
    isError,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useInntauingerFerdigTaksert(fraDato, tilDato);

  const handleNesteSide = () => fetchNextPage();

  return (
    <>
      <HeadingView>Gjennomførte takseringer</HeadingView>
      <FraTilDato
        fraDato={fraDato}
        tilDato={tilDato}
        handleChangeFraDato={setFraDato}
        handleChangeTilDato={setTilDato}
      />
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      {data && (
        <>
          <table className="tbl">
            <thead>
              <tr>
                <th>Nasjonskode</th>
                <th>ID</th>
                <th>Type</th>
                <th>Takstdato</th>
                <th>Websak</th>
              </tr>
            </thead>
            <tbody>
              {data?.pages
                .flatMap(x => x.data)
                .map(inntauing =>
                  inntauing.takseringer
                    .filter(t => t.tidspunkt)
                    .map(taksering => {
                      return (
                        <tr key={taksering.id}>
                          <td>{inntauing.kjoretoy.nasjonalitet}</td>
                          <td
                            data-testid={`arkivert-taksering-id-${inntauing.kjoretoy.registreringsnummer}`}
                          >
                            {inntauing.kjoretoyleveranse?.journalnummer
                              ? "#" + inntauing.kjoretoyleveranse?.journalnummer
                              : inntauing.kjoretoy.registreringsnummer}
                          </td>
                          <td>{inntauing.kjoretoy.kjoretoystype.kode}</td>
                          <td>
                            {taksering.tidspunkt
                              ? new Date(
                                  taksering.tidspunkt
                                ).toLocaleDateString()
                              : "Mangler dato"}
                          </td>
                          <td className="underline">
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={`${getHostFromEnv("WEBSAK")}/${
                                inntauing.arkivReferanseId
                              }`}
                            >
                              {inntauing.arkivReferansenummer}
                            </a>
                          </td>
                        </tr>
                      );
                    })
                )}
            </tbody>
          </table>
          <div className="flex w-full justify-center">
            <button
              className="btn btn--flat btn--small"
              disabled={!hasNextPage || isFetchingNextPage}
              onClick={handleNesteSide}
            >
              {isFetchingNextPage ? "Henter flere" : "Last inn flere"}
            </button>
          </div>
        </>
      )}
    </>
  );
};
