import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Kort } from "../common/card/Kort";
import { HeadingTopic } from "../common/typography/HeadingTopic";
import { HentFraRegister } from "./HentFraRegister";
import { FelterRedigerEierOgKjoretoyinfo } from "./redigerEierOgKjoretoyinfoUtils";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { OsloFodselsnummerInput } from "../common/form/FodselsnummerInput";
import { Toggle } from "../common/form/Toggle";
import { AlertBox } from "../common/form/AlertBox";
import { handleNumberOrEmptyValueInput } from "../../utils/keyboardUtils";

export const RedigerStegEierinfo = () => {
  const { control, register } =
    useFormContext<FelterRedigerEierOgKjoretoyinfo>();

  const juridiskEier = useWatch({
    control,
    name: "juridiskEier"
  });

  const leasingtaker = useWatch({
    control,
    name: "leasingtaker"
  });

  const leaset = useWatch({
    control,
    name: "leaser"
  });

  const feilLengdeIdNummerJuridiskEier = feilLengdeIdNummer(
    juridiskEier?.idNummer,
    juridiskEier?.bedrift,
    "juridisk eier"
  );
  const feilLengdeIdNummerLeasingtaker = feilLengdeIdNummer(
    leasingtaker?.idNummer,
    leasingtaker?.bedrift,
    "leasingtaker"
  );

  return (
    <Kort
      headerText="Eierinfo"
      headerIcon={<User className="fill-darkblue" />}
      innhold={
        <div className="my-5">
          <HentFraRegister infotype="eier" />
          <HeadingTopic>Juridisk eier:</HeadingTopic>
          <div className="ods-form-group w-[20rem]">
            <label
              title={juridiskEier?.bedrift ? "Org.nummer" : "Fødselsnummer"}
              htmlFor="juridiskEier.idNummer"
              className="ods-form-label"
            >
              {juridiskEier?.bedrift ? "Org.nummer" : "Fødselsnummer"}
            </label>
            <Controller
              control={control}
              name="juridiskEier.idNummer"
              render={({ field: { name, value, ref, onChange } }) =>
                juridiskEier?.bedrift ? (
                  <input
                    autoComplete="off"
                    className={`ods-form-input ${
                      feilLengdeIdNummerJuridiskEier.erFeil
                        ? "border-errorred focus:border-errorred"
                        : ""
                    }`}
                    name={name}
                    value={value ?? undefined}
                    ref={ref}
                    onChange={onChange}
                    onKeyDown={handleNumberOrEmptyValueInput}
                  />
                ) : (
                  <OsloFodselsnummerInput
                    autoComplete="off"
                    className={
                      feilLengdeIdNummerJuridiskEier.erFeil
                        ? "border-errorred focus:border-errorred"
                        : ""
                    }
                    name={name}
                    value={value ?? undefined}
                    ref={ref}
                    onChange={onChange}
                    onKeyDown={handleNumberOrEmptyValueInput}
                  />
                )
              }
            />
          </div>
          <div className="flex flex-row flex-wrap gap-x-5">
            <div className="ods-form-group w-[30rem]">
              <label htmlFor="juridiskEier.navn" className="ods-form-label">
                Navn
              </label>
              <input
                autoComplete="off"
                id="juridiskEier.navn"
                className="ods-form-input"
                {...register("juridiskEier.navn")}
              />
            </div>
            <div className="ods-form-group w-[20rem]">
              <label
                htmlFor="juridiskEier.telefonnummer"
                className="ods-form-label"
              >
                Telefonnummer
              </label>
              <input
                autoComplete="off"
                id="juridiskEier.telefonnummer"
                className="ods-form-input"
                {...register("juridiskEier.telefonnummer")}
              />
            </div>
          </div>
          <div className="ods-form-group w-[51.25rem]">
            <label
              htmlFor="juridiskEier.gateadresse"
              className="ods-form-label"
            >
              Adresse
            </label>
            <input
              autoComplete="off"
              id="juridiskEier.gateadresse"
              className="ods-form-input"
              {...register("juridiskEier.gateadresse")}
            />
          </div>
          <div className="flex flex-row flex-wrap gap-x-5">
            <div className="ods-form-group w-[20rem]">
              <label
                htmlFor="juridiskEier.postnummer"
                className="ods-form-label"
              >
                Postnummer
              </label>
              <input
                autoComplete="off"
                id="juridiskEier.postnummer"
                className="ods-form-input"
                {...register("juridiskEier.postnummer")}
              />
            </div>
            <div className="ods-form-group w-[30rem]">
              <label htmlFor="juridiskEier.poststed" className="ods-form-label">
                Poststed
              </label>
              <input
                autoComplete="off"
                id="juridiskEier.poststed"
                className="ods-form-input"
                {...register("juridiskEier.poststed")}
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap gap-x-5">
            <div className="ods-form-group">
              <label htmlFor="leaser" className="ods-form-label">
                Leaset
              </label>
              <Controller
                name="leaser"
                control={control}
                render={({ field }) => (
                  <Toggle
                    id={field.name}
                    leftText="Nei"
                    rightText="Ja"
                    value={field.value}
                    toggleFunction={() => field.onChange(!field.value)}
                  />
                )}
              />
            </div>
            <div className="ods-form-group">
              <label htmlFor="juridiskEier.bedrift" className="ods-form-label">
                Bedrift
              </label>
              <Controller
                name="juridiskEier.bedrift"
                control={control}
                render={({ field }) => (
                  <Toggle
                    id={field.name}
                    leftText="Nei"
                    rightText="Ja"
                    value={field.value}
                    toggleFunction={() => field.onChange(!field.value)}
                  />
                )}
              />
            </div>
          </div>
          {leaset && (
            <>
              <HeadingTopic>Leasingtaker:</HeadingTopic>
              <div className="ods-form-group w-[20rem]">
                <label
                  title={leasingtaker?.bedrift ? "Org.nummer" : "Fødselsnummer"}
                  htmlFor="leasingtaker.idNummer"
                  className="ods-form-label"
                >
                  {leasingtaker?.bedrift ? "Org.nummer" : "Fødselsnummer"}
                </label>
                <Controller
                  control={control}
                  name="leasingtaker.idNummer"
                  render={({ field: { name, value, ref, onChange } }) =>
                    leasingtaker?.bedrift ? (
                      <input
                        autoComplete="off"
                        className={`ods-form-input ${
                          feilLengdeIdNummerLeasingtaker.erFeil
                            ? "border-errorred focus:border-errorred"
                            : ""
                        }`}
                        name={name}
                        value={value ?? undefined}
                        ref={ref}
                        onChange={onChange}
                        onKeyDown={handleNumberOrEmptyValueInput}
                      />
                    ) : (
                      <OsloFodselsnummerInput
                        autoComplete="off"
                        className={
                          feilLengdeIdNummerLeasingtaker.erFeil
                            ? "border-errorred focus:border-errorred"
                            : ""
                        }
                        name={name}
                        value={value ?? undefined}
                        ref={ref}
                        onChange={onChange}
                        onKeyDown={handleNumberOrEmptyValueInput}
                      />
                    )
                  }
                />
              </div>
              <div className="flex flex-row flex-wrap gap-x-5">
                <div className="ods-form-group w-[30rem]">
                  <label htmlFor="leasingtaker.navn" className="ods-form-label">
                    Navn
                  </label>
                  <input
                    autoComplete="off"
                    id="leasingtaker.navn"
                    className="ods-form-input"
                    {...register("leasingtaker.navn")}
                  />
                </div>
                <div className="ods-form-group w-[20rem]">
                  <label
                    htmlFor="leasingtaker.telefonnummer"
                    className="ods-form-label"
                  >
                    Telefonnummer
                  </label>
                  <input
                    autoComplete="off"
                    id="leasingtaker.telefonnummer"
                    className="ods-form-input"
                    {...register("leasingtaker.telefonnummer")}
                  />
                </div>
              </div>
              <div className="ods-form-group w-[51.25rem]">
                <label
                  htmlFor="leasingtaker.gateadresse"
                  className="ods-form-label"
                >
                  Adresse
                </label>
                <input
                  autoComplete="off"
                  id="leasingtaker.gateadresse"
                  className="ods-form-input"
                  {...register("leasingtaker.gateadresse")}
                />
              </div>
              <div className="flex flex-row flex-wrap gap-x-5">
                <div className="ods-form-group w-[20rem]">
                  <label
                    htmlFor="leasingtaker.postnummer"
                    className="ods-form-label"
                  >
                    Postnummer
                  </label>
                  <input
                    autoComplete="off"
                    id="leasingtaker.postnummer"
                    className="ods-form-input"
                    {...register("leasingtaker.postnummer")}
                  />
                </div>
                <div className="ods-form-group w-[30rem]">
                  <label
                    htmlFor="leasingtaker.poststed"
                    className="ods-form-label"
                  >
                    Poststed
                  </label>
                  <input
                    autoComplete="off"
                    id="leasingtaker.poststed"
                    className="ods-form-input"
                    {...register("leasingtaker.poststed")}
                  />
                </div>
              </div>
              <div className="ods-form-group">
                <label
                  htmlFor="leasingtaker.bedrift"
                  className="ods-form-label"
                >
                  Bedrift
                </label>
                <Controller
                  name="leasingtaker.bedrift"
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      id={field.name}
                      leftText="Nei"
                      rightText="Ja"
                      value={field.value}
                      toggleFunction={() => field.onChange(!field.value)}
                    />
                  )}
                />
              </div>
            </>
          )}
          {(feilLengdeIdNummerJuridiskEier.erFeil ||
            feilLengdeIdNummerLeasingtaker.erFeil) && (
            <AlertBox
              alertLevel="error"
              heading={`${
                (feilLengdeIdNummerJuridiskEier.erFeil ? 1 : 0) +
                (feilLengdeIdNummerLeasingtaker.erFeil ? 1 : 0)
              } feil i skjema for eierinfo`}
              paragraph={
                <>
                  <p>{feilLengdeIdNummerJuridiskEier.message}</p>
                  <p>{feilLengdeIdNummerLeasingtaker.message}</p>
                </>
              }
            />
          )}
        </div>
      }
    />
  );
};

export const feilLengdeIdNummer = (
  idNummer?: string,
  erBedrift?: boolean,
  eierEllerLeaser?: "juridisk eier" | "leasingtaker"
) => {
  if (
    (idNummer ?? "").length !== 0 &&
    (idNummer ?? "").length !== (erBedrift ? 9 : 11)
  ) {
    return {
      erFeil: true,
      message: `${
        erBedrift ? "Org.nummer" : "Fødselsnummer"
      } for ${eierEllerLeaser} skal være ${
        erBedrift ? "9" : "11"
      } om det oppgis`
    };
  }

  return {
    erFeil: false,
    message: ""
  };
};
