import { useState } from "react";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { HANDLINGER } from "../common/handlingsknapp/handlingsknappUtils";
import { KansellerBetalingskrav } from "../kansellerBetalingskrav/KansellerBetalingskrav";
import { FlyttetPaGategrunnTabell } from "./FlyttetPaGategrunnTabell";

export const FlyttetPaGategrunn = () => {
  const [visHandling, setVisHandling] = useState<{
    inntauing?: InntauingViewModel;
    handling?: string;
  }>({});

  if (visHandling.inntauing) {
    return (
      <div className="flex flex-col h-full my-5 mx-5 text-left">
        {visHandling.handling === HANDLINGER.Kanseller_betalingskrav && (
          <KansellerBetalingskrav
            inntauing={visHandling.inntauing}
            close={() =>
              setVisHandling({ inntauing: undefined, handling: undefined })
            }
          />
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-row h-full">
      <div className="mr-10 oslo-inntauing-visning ml-[3rem]">
        <FlyttetPaGategrunnTabell
          setVisHandling={(x, y) =>
            setVisHandling({ inntauing: x, handling: y })
          }
        />
      </div>
    </div>
  );
};
