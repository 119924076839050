import {
  StatuskodeType,
  TakseringViewModel
} from "../../../api/interfaces/Inntauing";
import { mapEnumToNumberValues } from "../../../utils/objectMapping";

export const HANDLINGER = {
  Registrer_ankommet: "Registrer ankommet",
  Registrer_flyttet_på_gategrunn: "Registrer flyttet på gategrunn",
  Registrer_fremmøte: "Registrer fremmøte",
  Utlever_inntauing: "Utlever inntauing",
  Avbryt_henting: "Avbryt henting",
  Opprett_vrak: "Opprett vrak",
  Opprett_taksering: "Opprett taksering",
  Opprett_auksjon: "Opprett auksjon",
  Opprett_flagg: "Opprett flagg",
  Tillat_fakturering: "Tillat fakturering",
  Tillat_utlevering_uten_betaling: "Tillat utlevering uten betaling",
  Tillat_utlevering_med_redusert_dognleie: "Tillat redusert døgnleie",
  Angre_fremmote: "Angre fremmøte",
  Angre_ankomst: "Angre ankomst",
  Angre_flyttet: "Angre flyttet på gategrunn",
  Angre_sendt_til_auksjon: "Angre sendt til auksjon",
  Angre_sendt_til_vrak: "Angre sendt til vrak",
  Fjern_flagg: "Fjern flagg",
  Send_brev: "Send brev",
  Deaktiver_inntauing: "Deaktiver inntauing",
  Kanseller_betalingskrav: "Kanseller betalingskrav",
  Gjenopprett_betalingskrav: "Gjenopprett Betalingskrav"
};

const statuserPaaTomta = [
  StatuskodeType.AnkommetTomt,
  StatuskodeType.TilAuksjon,
  StatuskodeType.TilVrak
];
const statuserAktive = [
  StatuskodeType.AnkommetTomt,
  StatuskodeType.KranbilBestilt,
  StatuskodeType.SendtTilAuksjon,
  StatuskodeType.SendtTilVrak,
  StatuskodeType.TilAuksjon,
  StatuskodeType.TilVrak
];
const statuserKanSettesTilVrakEllerAuksjon = [StatuskodeType.AnkommetTomt];
const statuserIngenHandlingerMulige = [
  StatuskodeType.Auksjonert,
  StatuskodeType.Vraket
];

export const erPaaTomta = (status: StatuskodeType) =>
  statuserPaaTomta.some(i => i === status);

export const kanSettesTilVrakEllerAuksjon = (status: StatuskodeType) =>
  statuserKanSettesTilVrakEllerAuksjon.some(i => i === status);

export const erAktiv = (status: StatuskodeType) =>
  statuserAktive.some(i => i === status);

export const erUnderTauing = (status: StatuskodeType) =>
  status === StatuskodeType.KranbilBestilt;

export const erHentetPaTomt = (status: StatuskodeType) =>
  status === StatuskodeType.Hentet;

export const erUkjent = (status: StatuskodeType) =>
  !mapEnumToNumberValues(StatuskodeType).some(i => i === status);

export const erStatusFremmote = (status: StatuskodeType) =>
  status === StatuskodeType.Fremmote;

export const erStatusAnkommetTomt = (status: StatuskodeType) =>
  status === StatuskodeType.AnkommetTomt;

export const erStatusFlyttetPaaGategrunn = (status: StatuskodeType) =>
  status === StatuskodeType.FlyttetPaaGategrunn;

export const erStatusSendtTilAuksjon = (status: StatuskodeType) =>
  status === StatuskodeType.SendtTilAuksjon;

export const erStatusSendtTilVrak = (status: StatuskodeType) =>
  status === StatuskodeType.SendtTilVrak;

export const harIngenMuligeHandlinger = (
  status: StatuskodeType,
  kanIkkeAngreFlytting?: boolean
) =>
  statuserIngenHandlingerMulige.some(i => i === status) ||
  erUkjent(status) ||
  (status === StatuskodeType.FlyttetPaaGategrunn && kanIkkeAngreFlytting);

export const harUferdigeTakseringer = (takseringer?: TakseringViewModel[]) =>
  takseringer?.some(t => t.tidspunkt === undefined);
