import {
  BrevType,
  HjemmelType,
  HjemmelTypeMapper,
  InntauingViewModel
} from "../../api/interfaces/Inntauing";

interface AuksjonslisteRegnearkProps {
  auksjoner: InntauingViewModel[];
}

export const AuksjonslisteRegneark = (props: AuksjonslisteRegnearkProps) => {
  const { auksjoner } = props;

  return (
    <table id="auksjonsliste">
      <thead>
        <tr>
          <th>Innt. tidspunkt</th>
          <th>Regnr</th>
          <th>Journalnr</th>
          <th>Merke</th>
          <th>Modell</th>
          <th>Websak</th>
          <th>Tidspunkt varselbrev sendt</th>
          <th>Tidspunkt rek. brev sendt</th>
          <th>Tidspunkt reg. kunngjøring</th>
          <th>Ringt eier</th>
          <th>Hjemmel</th>
        </tr>
      </thead>
      <tbody>
        {auksjoner.map(i => (
          <tr key={i.id}>
            <td>
              {new Date(
                i.inntauingsgrunnlag.bestillingstidspunkt
              ).toLocaleString()}
            </td>
            <td>{i.kjoretoy.registreringsnummer ?? ""}</td>
            <td>{i.kjoretoyleveranse?.journalnummer ?? ""}</td>
            <td>{i.kjoretoy.merke ?? ""}</td>
            <td>{i.kjoretoy.modell ?? ""}</td>
            <td>{i.arkivReferansenummer ?? ""}</td>
            <td>
              {i.brevutsendinger.at(0)?.sendtTidspunkt
                ? new Date(
                    i.brevutsendinger.at(0)?.sendtTidspunkt ?? ""
                  ).toLocaleString()
                : ""}
            </td>
            <td>{hentRekommandertBrevTidspunkt(i)}</td>
            <td>{hentKunngjoringstidspunkt(i)}</td>
            <td />
            <td>
              {
                HjemmelTypeMapper[
                  i.inntauingsgrunnlag?.hjemmel ?? HjemmelType.Ukjent
                ].navn
              }
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const hentRekommandertBrevTidspunkt = (inntauing?: InntauingViewModel) => {
  const rekommandertBrev = inntauing?.brevutsendinger.find(
    b => b.brevtype === BrevType.Avhendingsbrev
  );

  if (rekommandertBrev?.arkivertTidspunkt)
    return new Date(rekommandertBrev.arkivertTidspunkt).toLocaleDateString();

  return "";
};

const hentKunngjoringstidspunkt = (inntauing?: InntauingViewModel) => {
  if (inntauing?.auksjon?.kunngjoring?.opprettetKunngjoringTidspunkt)
    return new Date(
      inntauing.auksjon.kunngjoring.opprettetKunngjoringTidspunkt
    ).toLocaleString();

  if (inntauing?.vraking?.kunngjoring?.opprettetKunngjoringTidspunkt)
    return new Date(
      inntauing.vraking.kunngjoring.opprettetKunngjoringTidspunkt
    ).toLocaleString();

  return "";
};
