import { useNavigate } from "react-router-dom";
import { InntauingViewModel } from "../../api/interfaces/Inntauing";
import { ROUTES } from "../../utils/globalConstants";
import { KolonneNasjonskodeTd } from "../common/table/KolonneNasjonskode";
import { isSaksbehandler } from "../../utils/userRolesUtils";

interface SendtTilAuksjonTabellProps {
  auksjonsliste?: InntauingViewModel[];
}

export const SendtTilAuksjonTabell = (props: SendtTilAuksjonTabellProps) => {
  const { auksjonsliste } = props;

  const navigate = useNavigate();

  if (!auksjonsliste) return null;

  return (
    <table className="tbl">
      <thead>
        <tr>
          <th>Nasjonskode</th>
          <th>ID</th>
          <th>Type</th>
          <th>Merke</th>
          <th colSpan={2} />
        </tr>
      </thead>
      <tbody>
        {auksjonsliste.map(i => (
          <tr key={i.id}>
            <KolonneNasjonskodeTd nasjonskode={i.kjoretoy.nasjonalitet} />
            <td>
              {i.kjoretoyleveranse?.journalnummer
                ? "#" + i.kjoretoyleveranse?.journalnummer
                : i.kjoretoy.registreringsnummer}
            </td>
            <td>{i.kjoretoy.kjoretoystype.kode}</td>
            <td>{i.kjoretoy.merke}</td>
            <td className="text-right">
              <button
                data-testid={`generer-attest-${i.kjoretoy.registreringsnummer}`}
                className="btn btn--secondary btn--small my-1 mr-5"
                onClick={() => navigate(`${ROUTES.Generer_attest}/${i.id}`)}
              >
                Generer auksjonsattest
              </button>
            </td>
            <td>
            {isSaksbehandler() && <button
                data-testid={`registrer-auksjonert-${i.kjoretoy.registreringsnummer}`}
                className="btn btn--primary btn--small my-1"
                onClick={() =>
                  navigate(`${ROUTES.Registrer_auksjonert}/${i.id}`)
                }
              >
                Registrer auksjonert
              </button>
            }
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
