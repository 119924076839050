import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as File } from "../../../../assets/icons/file.svg";

export interface DropZoneProps {
  dropZoneClassName?: string;
  onDragStateChange?: (isDragActive: boolean) => void;
  onDrag?: () => void;
  onDragIn?: () => void;
  onDragOut?: () => void;
  onDrop?: () => void;
  onFilesDrop?: (files: File[]) => void;
}

export const DropZone = memo((props: DropZoneProps) => {
  const {
    dropZoneClassName,
    onDragStateChange,
    onFilesDrop,
    onDrag,
    onDragIn,
    onDragOut,
    onDrop
  } = props;

  const [isDragActive, setIsDragActive] = useState<boolean>(false);

  const dropZoneRef = useRef<null | HTMLDivElement>(null);

  const mapFileListToArray = (files: FileList) => {
    const array: File[] = [];

    for (let i = 0; i < files.length; i++) {
      const temp = files.item(i);
      if (temp) {
        array.push(temp);
      }
    }

    return array;
  };

  const handleDragIn = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      onDragIn?.();

      if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
        setIsDragActive(true);
      }
    },
    [onDragIn]
  );

  const handleDragOut = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      onDragOut?.();

      setIsDragActive(false);
    },
    [onDragOut]
  );

  const handleDrag = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      onDrag?.();
      if (!isDragActive) {
        setIsDragActive(true);
      }
    },
    [isDragActive, onDrag]
  );

  const handleDrop = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      setIsDragActive(false);
      onDrop?.();

      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        const files = mapFileListToArray(event.dataTransfer.files);

        onFilesDrop?.(files);
        event.dataTransfer.clearData();
      }
    },
    [onDrop, onFilesDrop]
  );

  useEffect(() => {
    onDragStateChange?.(isDragActive);
  }, [isDragActive, onDragStateChange]);

  useEffect(() => {
    const tempZoneRef = dropZoneRef?.current;
    if (tempZoneRef) {
      tempZoneRef.addEventListener("dragenter", handleDragIn);
      tempZoneRef.addEventListener("dragleave", handleDragOut);
      tempZoneRef.addEventListener("dragover", handleDrag);
      tempZoneRef.addEventListener("drop", handleDrop);
    }

    return () => {
      tempZoneRef?.removeEventListener("dragenter", handleDragIn);
      tempZoneRef?.removeEventListener("dragleave", handleDragOut);
      tempZoneRef?.removeEventListener("dragover", handleDrag);
      tempZoneRef?.removeEventListener("drop", handleDrop);
    };
  }, [handleDrag, handleDragIn, handleDragOut, handleDrop]);

  return (
    <div
      ref={dropZoneRef}
      data-testid="filopplaster-drag-and-drop"
      className={`flex flex-row border-2 border-dashed border-darkblue px-24 py-16 w-fit ${dropZoneClassName}`}
    >
      <File className="h-6 mr-2 fill-darkblue" />
      <span>Dra en fil hit for å velge den</span>
    </div>
  );
});
