import { useTilAuksjon } from "../../hooks/useAuksjon";
import { HeadingView } from "../common/typography/HeadingView";
import { CustomError } from "../common/typography/CustomError";
import { TilAuksjonTabell } from "./TilAuksjonTabell";
import { LoadingSpinner } from "../common/loading/LoadingSpinner";

export const TilAuksjon = () => {
  const { data, isLoading, isError, error } = useTilAuksjon();

  return (
    <>
      <HeadingView>Til auksjon</HeadingView>
      {isLoading && <LoadingSpinner />}
      {isError && <CustomError error={(error as Error).message} />}
      <TilAuksjonTabell auksjonsliste={data} />
    </>
  );
};
